import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Link } from "react-router-dom";
import Avatar from "../../components/Avatar";
import { Button } from "../../components/Button";
import Layout from "../../components/Layout";
import { BaseLivlyApiResponse } from "../../types/Base";
import { BASE_API_URL } from "../../utils/constants";
import useLivlyUser from "../../context/UserProvider";

export type DelegateUser = {
  userId: number;
  auth0UserId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  avatarUrl: string;
  title: string;
  clientId: number;
  chatUserId: string;
};

export const getDelegateUsers = async (userId: number, leaseId: number) => {
  const result = await axios.get<BaseLivlyApiResponse<DelegateUser[]>>(
    `${BASE_API_URL}/livly/delegatedUsers/${userId}/leases/${leaseId}`
  );

  return result.data.Data;
};

export default function FriendsAndFamilyLoginsPage() {
  const { user } = useLivlyUser();
  const { data: delegateUsers = [], isLoading } = useQuery(
    ["delegate-users", user.userId, user.propertyUnitLeaseId],
    () => getDelegateUsers(user.userId, user.propertyUnitLeaseId)
  );

  return (
    <Layout
      title="Friends & Family Logins"
      back={{ to: "../my-lease", label: "My Lease" }}
    >
      {isLoading ? (
        <div>Loading friends and family logins. Please wait...</div>
      ) : (
        <div>
          {delegateUsers.length > 0 && (
            <div className="flex justify-end">
              <Link to="add-login/details">
                <Button color="primary" className="mt-4">
                  Add new logins
                </Button>
              </Link>
            </div>
          )}
          <h2 className="mt-2">
            These users can login to the Livly app on your behalf and use the
            features you select
          </h2>
          {delegateUsers?.length > 0 ? (
            <ul className="mt-4 space-y-4">
              {delegateUsers.map((user) => (
                <DelegateUser key={user.userId} user={user} />
              ))}
            </ul>
          ) : (
            <div className="flex flex-col items-center justify-center mt-6">
              <h3 className="text-xl">No logins added yet</h3>
              <p className="mt-2 text-sm text-gray-700">
                Your friends & family logins added to your lease will appear
                here.
              </p>
              <Link to="add-login/details">
                <Button color="primary" className="mt-4">
                  Add new logins
                </Button>
              </Link>
            </div>
          )}
        </div>
      )}
    </Layout>
  );
}

function DelegateUser({ user }: { user: DelegateUser }) {
  return (
    <li className="flex items-center gap-4">
      <Avatar
        src={user.avatarUrl}
        name={`${user.firstName} ${user.lastName}`}
      />
      <p className="flex-1">
        {user.firstName} {user.lastName}
      </p>
      <Link to={user.userId.toString()}>
        <Button outlined color="secondary">
          Edit
        </Button>
      </Link>
    </li>
  );
}
