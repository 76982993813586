import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { BaseLivlyApiResponse } from "../types/Base";
import { BASE_API_URL } from "../utils/constants";
import { identifyUser } from "../utils/analytics";
import { User } from "../types/User";
import {
  createContext,
  ErrorInfo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import rg4js from "raygun4js";
import { Spinner } from "@/components/Spinner";
import { ErrorBoundary } from "react-error-boundary";
import Oops from "@/components/Oops";
import { MOBILE_KEY } from "@/utils/localStorage";

rg4js("apiKey", import.meta.env.VITE_RAYGUN_KEY);
rg4js("enableCrashReporting", true);
rg4js("logContentsOfXhrCalls", true);
rg4js("withTags", [import.meta.env.VITE_ENVIRONMENT!]);

rg4js("options", {
  ignore3rdPartyErrors: true,
});

const getUserMe = async (leaseId: string) => {
  const result = await axios.get<BaseLivlyApiResponse<User>>(
    `${BASE_API_URL}/livly/users/me?v=202404&leaseId=${leaseId}`
  );

  return result.data.Data;
};

interface UserContextShape {
  user: User;
  isChatUserSet: boolean;
  isLoading: boolean;
  refreshUser: () => void;
  setIsChatUserSet: (isSet: boolean) => void;
  updateUser: (user: Partial<User>) => void;
}

const initialState: UserContextShape = {
  user: {} as User,
  isLoading: true,
  isChatUserSet: false,
  refreshUser: () => {},
  setIsChatUserSet: () => {},
  updateUser: () => {},
};

const UserContext = createContext<UserContextShape>(
  initialState as UserContextShape
);

function UserProvider() {
  const isMobile = localStorage.getItem(MOBILE_KEY) === "true";
  const params = useParams<{ leaseId: string }>();
  const navigate = useNavigate();
  const [isChatUserSet, setIsChatUserSet] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  const updateUser = (newUser: Partial<User>) => {
    if (!user) {
      return;
    }
    setUser({ ...user, ...newUser });
  };

  const { isLoading, refetch } = useQuery(
    ["user", params.leaseId],
    () => getUserMe(params.leaseId!),
    {
      onError: () => {
        navigate("/no-access");
      },
      onSuccess: (data) => {
        setUser(data);

        if (data.leases.length === 0) {
          return navigate("/no-access");
        }

        identifyUser(data.userId);
        rg4js("setUser", {
          identifier: data.email,
          isAnonymous: false,
          email: data.email,
          firstName: data.firstName,
          fullName: data.fullName,
        });
      },
    }
  );

  useEffect(() => {
    if (location?.pathname) {
      rg4js("trackEvent", {
        type: "pageView",
        path: "/" + window.location.pathname,
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isMobile) {
      rg4js("enablePulse", true);
    }
  }, [isMobile]);

  const refreshUser = () => {
    refetch();
  };

  const value = useMemo(
    () => ({
      user: user!,
      isLoading,
      refreshUser,
      isChatUserSet,
      setIsChatUserSet,
      updateUser,
    }),
    [user, isLoading, refreshUser, isChatUserSet, setIsChatUserSet, updateUser]
  );

  const logError = (error: Error, info: ErrorInfo) => {
    rg4js("send", {
      error,
      customData: [{ componentStack: info.componentStack }],
    });
  };

  return (
    <ErrorBoundary fallback={<Oops />}>
      <UserContext.Provider value={value}>
        {isLoading ? (
          <div className="h-screen flex justify-center items-center">
            <Spinner color="livly" size="xl" />
          </div>
        ) : user != null ? (
          <Outlet />
        ) : null}
      </UserContext.Provider>
    </ErrorBoundary>
  );
}

export { UserProvider };

export default function useLivlyUser() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error(`useLivlyUser must be used within a UserProvider`);
  }

  return context;
}
