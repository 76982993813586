import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { ChangeEvent, FocusEvent, FormEvent, useState } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { Button } from "../../components/Button";
import Layout from "../../components/Layout";
import { CartContext } from "../../context/CartProvider";
import { CartItem, CartItemEnum } from "../../types/Cart";
import { BASE_API_URL } from "../../utils/constants";
import formatCurrency from "../../utils/formatCurrency";
import payment from "payment";
import { ConfirmationModal, Modal } from "../../components/Dialog";
import { useGetStatusTypeDetails, usePostService } from "./landing";
import { OnboardingStatusEnum } from "../../types/User";
import { Spinner } from "../../components/Spinner";
import useLivlyUser from "../../context/UserProvider";
import {
  formatCVC,
  formatCreditCardNumber,
  formatCvc,
  formatExpirationDate,
  getCVCMaxLength,
  hasCVCReachedMaxLength,
  isExpiryInvalid,
  isHighlighted,
} from "@/utils/cardHelpers";
import { textInputClass } from "@/components/Form";
import CreditCard from "@/components/CreditCard";
import { isNativeAppVersion, returnToNative } from "@/utils/nativeAppHelpers";
import { planChanged } from "../insurance-proof";

export interface ExternalPaymentMethodDetail {
  creditCardInformation?: {
    type: string;
    number: string;
    cvc: string;
    expirationMonth: string;
    expirationYear: string;
  };
  bankInformation?: {
    accountNumber: string;
    routingNumber: string;
  };
}

async function makePayment(userId: string, leaseId: string, data: CartItem[]) {
  const result = await axios.post(
    `${BASE_API_URL}/livly/checkout/v2/${userId}/makepayment/${leaseId}`,
    data
  );

  return result.data;
}

interface CreditCardDetails {
  number: string;
  expiry: string;
  cvc: string;
}

export default function ChecklistCheckoutInsurancePage({
  isInsuranceOnly,
}: {
  isInsuranceOnly?: boolean;
}) {
  const { user } = useLivlyUser();
  const navigate = useNavigate();
  const params = useParams<{ leaseId: string; userId: string }>();
  const [paymentType, setPaymentType] = useState<"ach" | "cc">("cc");
  const [cardDetails, setCardDetails] = useState<CreditCardDetails>({
    number: "",
    expiry: "",
    cvc: "",
  });
  const [accountNumber, setAccountNumber] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [isPaymentSuccessModalOpen, setIsPaymentSuccessModalOpen] =
    useState(false);
  const [error, setError] = useState("");
  const queryClient = useQueryClient();

  const { cartItems } = useOutletContext<CartContext>();
  const rentCartItem = cartItems.find((ci) => ci.type === CartItemEnum.RENT);
  const insuranceCartItem = cartItems.find(
    (ci) => ci.type === CartItemEnum.INSURANCE
  );
  const details = useGetStatusTypeDetails(OnboardingStatusEnum.checkout, user);
  const { updateOnboardingStatus, isLoading: isCompletingStep } =
    usePostService(user.userId, params.leaseId!, details.onboardingStatus);
  const { mutate, isLoading } = useMutation((data: CartItem) =>
    makePayment(user.userId.toString(), user.propertyUnitLeaseId.toString(), [
      data,
    ])
  );

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!insuranceCartItem) {
      return;
    }

    const formData = new FormData(e.currentTarget);

    let externalPaymentMethodDetail: ExternalPaymentMethodDetail = {};

    if (paymentType === "ach") {
      const routingNumber = formData.get("routingNumber") as string;
      const accountNumber = formData.get("accountNumber") as string;
      externalPaymentMethodDetail = {
        bankInformation: {
          routingNumber,
          accountNumber,
        },
      };
    } else {
      const [expirationMonth, expirationYear] = cardDetails.expiry.split("/");
      const type = payment.fns.cardType(cardDetails.number);

      const isCreditCardInvalid =
        !payment.fns.validateCardNumber(cardDetails.number) ||
        !payment.fns.validateCardExpiry(cardDetails.expiry) ||
        !payment.fns.validateCardCVC(cardDetails.cvc, type);

      if (isCreditCardInvalid) {
        setError("Card is invalid");
        return;
      }

      externalPaymentMethodDetail = {
        creditCardInformation: {
          number: cardDetails.number.replace(/\s+/g, ""),
          type: formatCardType(type),
          cvc: cardDetails.cvc,
          expirationMonth: expirationMonth.replace(/\s+/g, "") as string,
          expirationYear: ("20" + expirationYear.replace(/\s+/g, "")) as string,
        },
      };
    }

    const requestModel: CartItem = {
      ...insuranceCartItem,
      externalPaymentMethodDetail,
    };

    mutate(requestModel, {
      onSuccess: async () => {
        if (isInsuranceOnly) {
          planChanged(true);
        } else {
          setIsPaymentSuccessModalOpen(true);
          queryClient.invalidateQueries({ queryKey: ["cart"] });
        }
      },
      onError: (e) => {
        const result = e as {
          data?: { Message: string; Errors: Record<number, string> };
        };
        const errors = result.data?.Errors as Record<number, string>;
        const insuranceError = errors[CartItemEnum.INSURANCE];
        setError(
          insuranceError ??
            "There was a problem submitting payment. Please try again."
        );
      },
    });
  };

  const onCompleteAndMoveToChecklist = async () => {
    try {
      await updateOnboardingStatus({
        ...details.onboardingStatus,
        isCompleted: true,
      });

      if (isNativeAppVersion()) {
        returnToNative();
        return;
      }

      navigate("..");
    } catch (e) {}
  };

  return (
    <Layout title="Insurance Checkout" back={{ label: "Cart", to: `../cart` }}>
      <form onSubmit={onSubmit} className="max-w-md mx-auto">
        {isLoading ? (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-75">
            <Spinner color="livly" size="xl" />
          </div>
        ) : null}
        <div className="flex justify-between gap-4">
          <Button
            type="button"
            color={paymentType === "cc" ? "primary" : "default"}
            className="flex-1"
            onClick={() => setPaymentType("cc")}
          >
            Credit Card
          </Button>
          <Button
            type="button"
            color={paymentType === "ach" ? "primary" : "default"}
            className="flex-1"
            onClick={() => setPaymentType("ach")}
          >
            Bank Account
          </Button>
        </div>
        <div>
          {paymentType === "ach" ? (
            <div>
              <div className="mt-6">
                <label
                  htmlFor="routingNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  Routing Number
                </label>
                <input
                  required
                  type="tel"
                  id="routingNumber"
                  name="routingNumber"
                  className="block w-full mt-2 border-0 border-b border-transparent bg-gray-50 focus:border-blue-600 focus:ring-0 sm:text-sm"
                  pattern="[0-9]+"
                  maxLength={9}
                  value={routingNumber}
                  onChange={(e) => setRoutingNumber(e.target.value)}
                />
              </div>
              <div className="mt-6">
                <label
                  htmlFor="accountNumber"
                  className="block text-sm font-medium text-gray-700"
                >
                  Account Number
                </label>
                <input
                  required
                  type="tel"
                  id="accountNumber"
                  name="accountNumber"
                  className="block w-full mt-2 border-0 border-b border-transparent bg-gray-50 focus:border-blue-600 focus:ring-0 sm:text-sm"
                  pattern="[0-9]+"
                  maxLength={17}
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                />
              </div>
            </div>
          ) : (
            <div className="p-4 mt-6 rounded-lg bg-gray-50 checkout-insurance-credit-card">
              <CreditCard
                cardDetails={cardDetails}
                setCardDetails={setCardDetails}
              />
            </div>
          )}
        </div>
        <div className="fixed bottom-0 left-0 right-0 flex flex-col items-stretch m-4 bg-white border border-gray-100 rounded-lg shadow-lg md:flex-row md:justify-end md:items-baseline md:left-64 drop-shadow-lg">
          <div className="flex items-center justify-between p-4 border-t border-gray-200 md:border-none">
            <div className="flex flex-col items-baseline mr-4 md:flex-row">
              <p className="text-sm font-light md:mr-2">today's total</p>
              <p className="text-xl">
                {formatCurrency(insuranceCartItem?.cost || 0)}
              </p>
            </div>
            <Button
              disabled={isLoading}
              color="primary"
              type="submit"
              className="flex items-center gap-2 bg-gray-900 hover:bg-gray-700"
            >
              {isLoading && <Spinner />}
              {rentCartItem ? "Pay" : "Pay and Complete"}
            </Button>
          </div>
        </div>
      </form>
      <Modal
        open={isPaymentSuccessModalOpen}
        onClose={() => setIsPaymentSuccessModalOpen(false)}
        title="Payment successful"
      >
        <div>
          <p className="mt-4 font-light">
            Renter's insurance has been purchased!
          </p>
          <div className="flex items-center justify-end gap-4 mt-4">
            <Button
              color="primary"
              className="bg-gray-900 hover:bg-gray-700"
              onClick={onCompleteAndMoveToChecklist}
            >
              Done
            </Button>
            {rentCartItem && (
              <Link to={"../cart/checkout"}>
                <Button color="primary">Pay Rent</Button>
              </Link>
            )}
          </div>
        </div>
      </Modal>
      <ConfirmationModal
        variant="warning"
        open={error !== ""}
        title="Sorry"
        body={error}
        onClose={() => setError("")}
        onConfirm={() => setError("")}
        buttonLabels={{ cancel: null, confirm: "Ok" }}
      />
    </Layout>
  );
}

export function capitalizeFirstLetter(word: string) {
  const [first, ...rest] = word.split("");
  return first.toUpperCase() + rest.join("");
}

export function formatCardType(type: string) {
  const cardType = capitalizeFirstLetter(type);

  return cardType.slice(0, 4);
}
