import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import CommunitySurvey, {
  CommunitySurveyQuestionRequest,
  postCommunitySurvey,
} from "../../components/CommunitySurveyForm";
import Layout from "../../components/Layout";
import { OnboardingStatus, OnboardingStatusEnum } from "../../types/User";
import { communitySurveyQuery } from "../community-survey";
import { usePostService, useGetStatusTypeDetails } from "./landing";
import { Spinner } from "../../components/Spinner";
import useLivlyUser from "../../context/UserProvider";
import { isNativeAppVersion, returnToNative } from "@/utils/nativeAppHelpers";

export default function ChecklistCommunitySurveyPage() {
  const queryClient = useQueryClient();
  const params = useParams<{
    leaseId: string;
  }>();
  const navigate = useNavigate();
  const { user } = useLivlyUser();
  const details = useGetStatusTypeDetails(
    OnboardingStatusEnum.communitySurvey,
    user
  );

  const { data, isLoading } = useQuery({
    ...communitySurveyQuery(
      params.leaseId!,
      user.propertyId.toString(),
      user.userId.toString()
    ),
  });
  const {
    updateOnboardingStatus,
    skipOnboardingStep,
    isLoading: isSkippingService,
  } = usePostService(user.userId, params.leaseId!, details.onboardingStatus);

  const onNext = async () => {
    await skipOnboardingStep();

    if (isNativeAppVersion()) {
      returnToNative();
      return;
    }

    navigate("..");
  };

  const { mutate, isLoading: isSubmitting } = useMutation(
    (questionsAndAnswers: CommunitySurveyQuestionRequest[]) =>
      postCommunitySurvey(
        user.propertyId,
        user.userId,
        params.leaseId!,
        questionsAndAnswers
      )
  );

  const onSubmit = (data: CommunitySurveyQuestionRequest[]) => {
    mutate(data, {
      onSuccess: async () => {
        const newOnboardingStatus: OnboardingStatus = {
          ...details.onboardingStatus,
          isCompleted: true,
        };
        await updateOnboardingStatus(newOnboardingStatus);
        queryClient.invalidateQueries(["community-survey"]);

        if (isNativeAppVersion()) {
          returnToNative();
          return;
        }

        navigate("..");
      },
    });
  };

  return (
    <Layout
      title="Community Survey"
      back={{ label: "Move-in Checklist", to: ".." }}
    >
      {isLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <>
          <div className="flex justify-end mb-4">
            <Button
              onClick={onNext}
              color="default"
              disabled={isSkippingService || isSubmitting}
            >
              {isSkippingService && <Spinner />}
              Skip
            </Button>
          </div>
          <CommunitySurvey
            communitySurvey={data!}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
          />
        </>
      )}
    </Layout>
  );
}
