import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/free-brands-svg-icons";
import { useLocation, useOutletContext } from "react-router-dom";
import { FeedbackExternalContext } from "./context";
import { ReviewLocation } from "@/components/Feedback/feedback";
import ThankYou, {
  ReviewLocationLinks,
} from "@/components/Feedback/FeedbackThankYou";

function FeedbackExternalThankYouPage() {
  const { reviewLocations = [] } =
    (useLocation().state as { reviewLocations: ReviewLocation[] }) || {};
  const { property } = useOutletContext<FeedbackExternalContext>();
  const { propertyName = "" } = property || {};

  let appLink = "";
  if (import.meta.env.VITE_ENVIRONMENT === "PRODUCTION") {
    appLink = "https://livly.app.link/vQbPtuiQX4";
  } else {
    appLink = "https://livly.test-app.link/28TYfYIQX4";
  }

  return (
    <div className="flex flex-col items-center max-w-lg p-4 mx-auto">
      <h2 className="text-lg font-bold text-center">Thank you!</h2>
      <p className="mt-2 text-center">
        Your living experience is important to us. Thanks for your feedback.
      </p>
      <img src="/thumbs_up.png" className="h-48 py-8" />
      {reviewLocations.length > 0 && (
        <>
          <p className="mt-2 text-center">
            Seems you are having a great time living at {propertyName}! Would
            you like to share this review on the following site? That’ll be
            really appreciated!
          </p>
          <div className="mt-4">
            <ReviewLocationLinks reviewLocations={reviewLocations} />
          </div>
        </>
      )}
    </div>
  );
}

export default FeedbackExternalThankYouPage;
