import moment from "moment-timezone";
import formatCurrency from "@/utils/formatCurrency";
import Avatar from "@/components/Avatar";
import { useRentAutoPay } from "@/routes/rent/autopay";

const OtherPayees = () => {
  const { autopayState } = useRentAutoPay();
  const { autopaySettings } = autopayState;
  const { roommateAutoPayments } = autopaySettings || {};

  if (!roommateAutoPayments || roommateAutoPayments?.length === 0) {
    return null;
  }

  return (
    <div className="pt-8 pb-40">
      <h4 className="text-sm uppercase">Other autopay on this lease</h4>
      <ul>
        {roommateAutoPayments.map((payee) => {
          const {
            userId,
            avatarUri,
            firstName,
            lastName,
            isFullStatementBalance,
            amount,
          } = payee;
          const autoPayDay = payee.autoPayDay || 1;

          return (
            <li key={userId} className="flex items-center py-2 gap-2">
              <Avatar
                size="xs"
                src={avatarUri}
                name={`${firstName} ${lastName}`}
              />
              <div className="flex-1">
                <p>
                  {firstName} {lastName}
                </p>
                <p className="text-sm text-gray-600">
                  {moment().month(0).date(autoPayDay).format("Do")} of each
                  month
                </p>
              </div>
              <div>
                <p>
                  {isFullStatementBalance
                    ? "Full Statement Balance"
                    : formatCurrency(amount)}
                </p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export { OtherPayees };
