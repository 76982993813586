import useLivlyUser from "@/context/UserProvider";
import React from "react";
import useGetPolls from "./hooks/useGetPolls";
import { getIsFeedbackTabsVisible } from "../feedback";
import { Link, Navigate, useParams } from "react-router-dom";
import { Spinner } from "@/components/Spinner";
import toLocalTime from "@/utils/toLocalTime";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const PollsContainer = () => {
  const params = useParams<{
    leaseId: string;
  }>();
  const { user } = useLivlyUser();
  const { data = [], isLoading } = useGetPolls(user.propertyId);
  const { pollsEnabled, feedbackEnabled } = getIsFeedbackTabsVisible(
    user.building.serviceTypes ?? []
  );

  if (!pollsEnabled && feedbackEnabled) {
    return <Navigate to="my-feedback" replace />;
  }

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : data.length === 0 ? (
        <div className="my-10 text-center">
          <h4 className="text-xl">No Polls yet</h4>
          <p className="mt-2 text-sm font-light">
            Any polls that was created will appear here
          </p>
        </div>
      ) : (
        <div className="mt-4">
          <ul className="text-sm divide-y divide-gray-200">
            {data?.map((poll) => (
              <Link to={`./${poll.residentPollId}`} key={poll.residentPollId}>
                <li className="py-4">
                  <div className="mb-2">
                    {toLocalTime(poll.expirationTimestamp).isBefore(
                      toLocalTime(new Date())
                    ) ? (
                      <span className="w-auto h-auto p-1 border-gray-500 bg-gray-100 text-gray-500 rounded border ">
                        Expired
                      </span>
                    ) : (
                      <span className="w-auto h-auto p-1 border-green-600 bg-green-100 text-green-600 rounded border ">
                        Active
                      </span>
                    )}
                  </div>
                  <div className="flex justify-between gap-4">
                    <div>
                      <h3 className="font-medium text-base">{poll.title}</h3>
                      <p className="text-sm text-gray-600">
                        {toLocalTime(poll.completedTimestamp).fromNow()}
                      </p>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        icon="chevron-right"
                        className="text-gray-400"
                      />
                    </div>
                  </div>

                  <p className="mt-2 text-sm text-gray-500 break-words">
                    {poll.description}
                  </p>
                </li>
              </Link>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};
