import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddTicketForm from "../../components/AddTicketForm";
import Drawer from "../../components/Drawer";
import Layout from "../../components/Layout";

export default function AddMaintenanceTicketMobilePage() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  const onClose = () => {
    setOpen(false);
    setTimeout(() => navigate(-1), 500);
  };

  return (
    <Drawer open={open} onClose={onClose} title="Add Ticket">
      <AddTicketForm />
    </Drawer>
  );
}
