import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { FormEvent, useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Layout from "../../components/Layout";
import { Spinner } from "../../components/Spinner";
import { BaseLivlyApiResponse } from "../../types/Base";
import {
  AssurantQuote,
  AssurantQuotePresentation,
  Plan,
} from "../../types/Insurance";

import { BASE_API_URL } from "../../utils/constants";
import formatCurrency from "../../utils/formatCurrency";
import { Button } from "../../components/Button";
import { OnboardingStatus, OnboardingStatusEnum, User } from "../../types/User";
import Avatar from "../../components/Avatar";
import {
  PHONE_FORMAT_ALTERNATE,
  formatPhone,
  isValidPhone,
  stripPhone,
} from "../../utils/phone";
import { Modal } from "../../components/Dialog";
import classNames from "classnames";
import { textInputClass } from "../../components/Form";
import moment from "moment-timezone";
import useLivlyUser from "../../context/UserProvider";
import { useGetStatusTypeDetails, usePostService } from "./landing";
import Alert from "@/components/Alert";
import { InsuranceQuoteContext } from "@/components/InsuranceQuoteLayout";
import { useGetInsuranceDetails } from "../insurance";
import { isNativeAppVersion, returnToNative } from "@/utils/nativeAppHelpers";

function InsuranceQuotePage({
  isInsuranceOnly = false,
}: {
  isInsuranceOnly?: boolean;
}) {
  const { user } = useLivlyUser();
  const { data } = useGetInsuranceDetails(user);
  const currentUserPlan = data?.plans.find(
    (p) => p.buyerUserId === user.userId
  );

  if (currentUserPlan) {
    return <PurchasedPlan plan={currentUserPlan} />;
  }

  return <InsuranceQuote isInsuranceOnly={isInsuranceOnly} />;
}

function PurchasedPlan({ plan }: { plan: Plan }) {
  return (
    <Layout title="Insurance" back={{ to: -1, label: "Move-in Checklist" }}>
      <div className="body">
        <div className="px-2">
          <h2 className="text-lg font-medium font-noe-display-bold">
            Your Renter’s Insurance
          </h2>
        </div>
        <div className="p-5 mb-4 bg-white border border-gray-200 rounded-lg">
          <div>
            <p className="mt-6 font-light">Provider</p>
            <p className="mt-1 text-sm font-medium">Assurant</p>

            <p className="mt-6 font-light">Policy number</p>
            <p className="mt-1 text-sm font-medium">{plan.policyNumber}</p>

            <p className="mt-6 font-light">
              {plan.numberOfPayments === 1 ? "Annual" : "Monthly"} Premium
            </p>
            <p className="mt-1 text-sm font-medium">
              {formatCurrency(
                plan.numberOfPayments === 1 ? plan.purchaseAmount : plan.premium
              )}
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

function InsuranceQuote({ isInsuranceOnly }: { isInsuranceOnly: boolean }) {
  const { user } = useLivlyUser();
  const [assurantQuote, setAssurantQuote] = useState<AssurantQuote | null>(
    null
  );

  const { data, isError } = useOutletContext<InsuranceQuoteContext>();

  useEffect(() => {
    if (data) {
      setAssurantQuote(data);
    }
  }, [data]);

  const onUpdateQuote = (newQuote: Partial<AssurantQuote>) => {
    if (!assurantQuote) {
      return;
    }

    setAssurantQuote({ ...assurantQuote, ...newQuote });
  };

  return (
    <Layout
      title="Insurance"
      back={{
        label: "Move-in Checklist",
        to: `..`,
      }}
    >
      {isError ? (
        <Alert
          variant="danger"
          message="Sorry, there was an issue loading your insurance quote."
        />
      ) : assurantQuote ? (
        assurantQuote.presentation.messageText ? (
          <InsuranceQuoteError
            user={user}
            errorMessage={assurantQuote.presentation.messageText}
          />
        ) : (
          <div className="max-w-4xl mx-auto">
            <h2 className="font-medium">Instant Quote</h2>
            <p>
              Here’s a personalized plan to protect you, your stuff, and your
              apartment. Coverage will renew automatically.
            </p>
            <QuoteDetails
              user={user}
              quote={assurantQuote}
              onUpdateQuote={onUpdateQuote}
            />
            <div className="mt-4 divide-y divide-gray-200 divide-solid">
              <Coverages presentation={assurantQuote.presentation} />
              <InsuredInformation
                user={user}
                presentation={assurantQuote.presentation}
                onUpdateQuote={onUpdateQuote}
              />
              <PolicyDelivery
                user={user}
                presentation={assurantQuote.presentation}
                onUpdateQuote={onUpdateQuote}
              />
            </div>

            <div className="mt-8">
              <p className="text-sm text-gray-700">
                Assurant is the holding company for various underwriting
                entities that provide Renters Insurance. In all states, unless
                otherwise noted, Renters Insurance and Residents Liability
                Insurance are underwritten by American Bankers Insurance Company
                of Florida with its home office in Miami, Florida. In Minnesota,
                the underwriter is American Security Insurance Company. In
                Georgia, Residents Liability is underwritten by Voyager
                Indemnity Insurance Company. In Utah, coverage is provided under
                policy form AJ8850PC-0307 and/or AJ9281CKK-0609.
              </p>
            </div>
            <UploadProof />
            <PlanActions
              quote={assurantQuote}
              isInsuranceOnly={isInsuranceOnly}
            />
          </div>
        )
      ) : null}
    </Layout>
  );
}

function InsuranceQuoteError({
  user,
  errorMessage,
}: {
  user: User;
  errorMessage: string;
}) {
  const navigate = useNavigate();
  const details = useGetStatusTypeDetails(OnboardingStatusEnum.insurance, user);
  const { skipOnboardingStep, isLoading, status } = usePostService(
    user.userId,
    user.propertyUnitLeaseId.toString(),
    details.onboardingStatus
  );

  const onSkip = async () => {
    await skipOnboardingStep();

    if (isNativeAppVersion()) {
      returnToNative();
      return;
    }

    navigate("..");
  };

  return (
    <div className="flex flex-col items-center justify-center gap-8">
      <p className="text-red-400">{errorMessage}</p>
      <div>
        <Button
          color="default"
          onClick={onSkip}
          className="flex items-center w-full gap-2 md:w-auto"
          disabled={isLoading}
        >
          {status === "skipping" && <Spinner />}
          Skip
        </Button>
      </div>
    </div>
  );
}

function QuoteDetails({
  user,
  quote,
  onUpdateQuote,
}: {
  user: User;
  quote: AssurantQuote;
  onUpdateQuote: (newQuote: Partial<AssurantQuote>) => void;
}) {
  const [open, setOpen] = useState(false);

  const updateAssurantQuote = useRequoteInsuranceQuote(user);

  const selectedPlan = getSelectedPlan(quote);

  const isFullPay = selectedPlan && selectedPlan.numberOfPayments === 1;
  const isQuarterlyPay = selectedPlan && selectedPlan.numberOfPayments === 4;
  const isEffectiveDateInPast =
    quote && moment().diff(quote.configuration.policyEffectiveDate, "days") > 0;

  const defaultStartDate = `${moment(
    quote.configuration.policyEffectiveDate
  ).format("yyyy-MM-DD")}`;
  const maximum = moment().add(90, "days");
  const maxDate = moment(user.leaseEndDate);
  const max = maximum.isBefore(maxDate) ? maximum : maxDate;

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const policyEffectiveDateString = formData.get(
      "policyEffectiveDate"
    ) as string;

    try {
      const policyEffectiveDate = moment(policyEffectiveDateString).format(
        "yyyy-MM-DDThh:mm:ss"
      );
      const result = await updateAssurantQuote.mutateAsync({
        coveragePersonalLiability: quote.presentation.coverageLiability,
        coveragePersonalProperty: quote.presentation.coveragePersonalProperty,
        deductable: quote.presentation.deductible,
        policyEffectiveDate,
      });

      onUpdateQuote(result);
      setOpen(false);
    } catch (e) {
      alert("error");
    }
  };

  return (
    <div className="p-5 mt-4 bg-white border-b-4 rounded-md shadow-lg border-b-red-400">
      <img src="/assurant.png" alt="" />
      <p className="mt-4 text-sm text-gray-500">
        {isFullPay
          ? "Annual plan"
          : isQuarterlyPay
          ? "Quarterly plan"
          : "Economic plan"}
      </p>
      <div className="flex justify-between">
        <div>
          <div className="flex items-baseline mt-2">
            <p className="text-4xl font-light">
              {formatCurrency(
                isFullPay
                  ? Number(selectedPlan.downPaymentAmount) || 0
                  : Number(selectedPlan?.installmentAmount) +
                      Number(selectedPlan?.serviceFee)
              )}
            </p>
            <p>{isFullPay ? "/yr" : isQuarterlyPay ? "/quarter" : "/month"}</p>
          </div>
          {!isFullPay && (
            <p className="mt-2 text-sm text-blue-600">
              {formatCurrency(Number(selectedPlan?.downPaymentAmount))} down
              payment due today
            </p>
          )}
        </div>
        {isFullPay ? <img src="/best_value.svg" alt="Best value" /> : null}
      </div>
      <div className="flex items-center justify-between pt-4 mt-4 border-t border-gray-200">
        <p>Payment plan</p>
        <Link to="payment-plan">
          <button className="flex items-center gap-2 text-sm underline">
            {isFullPay ? "Annual" : isQuarterlyPay ? "Quarterly" : "Economic"}
            <FontAwesomeIcon icon="chevron-right" />
          </button>
        </Link>
      </div>
      <div className="flex items-center justify-between mt-4">
        <p>Start date</p>
        <button
          className="flex items-center gap-2 text-sm underline"
          onClick={() => setOpen(true)}
        >
          {moment(quote.configuration.policyEffectiveDate).format(
            "MMMM D, YYYY"
          )}
          <FontAwesomeIcon icon="chevron-right" />
        </button>
      </div>
      {isEffectiveDateInPast && (
        <div className="mt-1">
          <p className="text-sm text-red-600">
            Policy effective date must be in the future
          </p>
        </div>
      )}
      <Modal
        title="Select start date"
        open={open}
        onClose={() => setOpen(false)}
      >
        <form onSubmit={onSubmit}>
          <div className="py-4">
            <input
              type="date"
              name="policyEffectiveDate"
              className={textInputClass}
              defaultValue={defaultStartDate}
              min={moment().add(1, "day").format("yyyy-MM-DD")}
              max={max.format("yyyy-MM-DD")}
            />
          </div>
          <div className="flex justify-end mt-4">
            <Button
              type="submit"
              color="primary"
              className="flex items-center gap-2"
              disabled={updateAssurantQuote.isLoading}
            >
              {updateAssurantQuote.isLoading && <Spinner />}
              Set date
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

function Coverages({
  presentation,
}: {
  presentation: AssurantQuotePresentation;
}) {
  return (
    <div className="py-4">
      <div className="flex items-center justify-between">
        <h3 className="font-medium">My Coverages</h3>
        <Link
          className="font-medium text-red-400 hover:underline"
          to="coverages"
        >
          Edit Coverage
        </Link>
      </div>
      <div className="mt-4">
        <p className="text-sm font-light text-gray-700">personal liability</p>
        <p className="mt-1">
          {formatCurrency(Number(presentation.coverageLiability))}
        </p>
      </div>
      <div className="mt-4">
        <p className="text-sm font-light text-gray-700">personal liability</p>
        <p className="mt-1">
          {formatCurrency(Number(presentation.coverageLiability))}
        </p>
      </div>
      <div className="mt-4">
        <p className="text-sm font-light text-gray-700">personal belongings</p>
        <p className="mt-1">
          {formatCurrency(Number(presentation.coveragePersonalProperty))}
        </p>
      </div>
      <div className="mt-4">
        <p className="text-sm font-light text-gray-700">
          deductible{" "}
          {presentation.hurricaneDeductible ? "(all other perils)" : ""}
        </p>
        <p className="mt-1">
          {formatCurrency(Number(presentation.deductible))}
        </p>
      </div>
      {presentation.hurricaneDeductible && (
        <div className="mt-4">
          <p className="text-sm font-light text-gray-700">
            hurricane deductible
          </p>
          <p className="mt-1">
            {formatCurrency(Number(presentation.hurricaneDeductible))}
          </p>
        </div>
      )}
      <div className="mt-4">
        <p className="text-sm font-light text-gray-700">replacement cost</p>
        <p className="mt-1">included</p>
      </div>
      {presentation.riskState === "NY" && (
        <div className="mt-4">
          <p className="text-sm font-light text-gray-700">flood</p>
          <p className="mt-1">included</p>
        </div>
      )}
    </div>
  );
}

function InsuredInformation({
  user,
  presentation,
  onUpdateQuote,
}: {
  user: User;
  presentation: AssurantQuotePresentation;
  onUpdateQuote: (newQuote: Partial<AssurantQuote>) => void;
}) {
  return (
    <div className="py-4">
      <h3 className="font-medium">Insured Information</h3>
      <div className="flex items-center justify-between mt-4">
        <div>
          <p className="text-sm font-light text-gray-700">insured name</p>
          <p className="mt-1">
            {user.firstName} {user.lastName}
          </p>
        </div>
        <Avatar
          size="sm"
          src={user.avatarURI}
          name={`${user.firstName} ${user.lastName}`}
        />
      </div>
      <AdditionallyInsured
        user={user}
        presentation={presentation}
        onUpdateQuote={onUpdateQuote}
      />
      <div className="mt-4">
        <p className="text-sm font-light text-gray-700">insured address</p>
        <p className="mt-1">
          {user.buildingName} {user.unit}
        </p>
        <p className="text-sm font-light">{user.address}</p>
      </div>
      <div className="mt-4">
        <p className="text-sm font-light text-gray-700">phone number</p>
        {user.unformattedPhone ? (
          <p className="mt-1">
            {formatPhone(user.unformattedPhone, PHONE_FORMAT_ALTERNATE)}
          </p>
        ) : (
          <AddPhoneNumber />
        )}
      </div>
      <div className="mt-4">
        <p className="text-sm font-light text-gray-700">email address</p>
        <p className="mt-1">{user.email}</p>
      </div>
    </div>
  );
}

function AdditionallyInsured({
  user,
  presentation,
  onUpdateQuote,
}: {
  user: User;
  presentation: AssurantQuotePresentation;
  onUpdateQuote: (newQuote: Partial<AssurantQuote>) => void;
}) {
  const [roommate] = user.roommates ?? [];

  const updateAssurantQuote = useMutation((additionalInsuredUserId: number) =>
    getInsuranceQuote(user.userId, user.propertyUnitLeaseId, {
      additionalInsuredUserId,
    })
  );

  const toggleAdditionalInsured = async (userId: number) => {
    try {
      const updatedQuote = await updateAssurantQuote.mutateAsync(userId);
      onUpdateQuote(updatedQuote);
    } catch (e) {}
  };

  return (
    <>
      {roommate && presentation.additionalInsuredUserId ? (
        <div className="mt-4">
          <p className="text-sm font-light text-gray-700">
            additionally insured
          </p>
          <div className="flex items-center gap-2">
            <p>{roommate.fullName}</p>
            <button
              type="button"
              className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-slate-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              onClick={() => toggleAdditionalInsured(-1)}
              disabled={updateAssurantQuote.isLoading}
            >
              <FontAwesomeIcon icon="times" />
            </button>
            {updateAssurantQuote.isLoading ? <Spinner color="livly" /> : null}
          </div>
        </div>
      ) : (user.roommates ?? []).length > 0 ? (
        <div className="mt-4">
          <p className="text-sm font-light text-gray-700">
            additionally insured
          </p>
          <div className="flex items-center gap-2">
            <button
              className="flex items-center gap-2 mt-2 text-sm text-red-300 underline"
              onClick={() => toggleAdditionalInsured(roommate.userId)}
              disabled={updateAssurantQuote.isLoading}
            >
              <FontAwesomeIcon icon={["fal", "plus-circle"]} />
              Add additionally insured
            </button>
            {updateAssurantQuote.isLoading ? <Spinner color="livly" /> : null}
          </div>
        </div>
      ) : null}
    </>
  );
}

function AddPhoneNumber() {
  const { user, updateUser } = useLivlyUser();
  const [open, setOpen] = useState(false);

  const updateUserMutation = useMutation((phoneNumber: string) =>
    updateLivlyUser(user.userId, user.status, phoneNumber, user.vybiconId)
  );

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const phoneNumber = formData.get("phone-number") as string;

    updateUserMutation.mutate(phoneNumber, {
      onSuccess: (data) => {
        setOpen(false);
        updateUser({
          phone: phoneNumber,
          unformattedPhone: data.phone,
        });
      },
    });
  };

  return (
    <div className="flex justify-between">
      <button
        className="flex items-center gap-2 mt-2 text-sm text-red-300 underline"
        onClick={() => setOpen(true)}
      >
        <FontAwesomeIcon icon={["fal", "plus-circle"]} />
        Add phone number
      </button>
      <FontAwesomeIcon icon="exclamation-circle" className="text-red-500" />
      <Modal
        title="Add Phone Number"
        open={open}
        hideClose
        onClose={() => setOpen(false)}
      >
        <form method="post" onSubmit={onSubmit}>
          <fieldset disabled={updateUserMutation.isLoading}>
            <div className="relative py-8 mt-1">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                +1
              </div>
              <InputMask mask="(999) 999-9999" autoFocus>
                {
                  //@ts-ignore
                  (inputProps: any) => {
                    return (
                      <input
                        {...inputProps}
                        autoFocus
                        type="text"
                        name="phone-number"
                        id="phone-number"
                        className={classNames(
                          textInputClass,
                          "pl-10  shadow-none"
                        )}
                      />
                    );
                  }
                }
              </InputMask>
            </div>
            <div className="flex justify-end mt-4">
              <Button
                color="secondary"
                type="submit"
                className="flex items-center gap-2"
              >
                {updateUserMutation.isLoading ? <Spinner /> : null}
                Done
              </Button>
            </div>
          </fieldset>
        </form>
      </Modal>
    </div>
  );
}

function PolicyDelivery({
  user,
  presentation,
  onUpdateQuote,
}: {
  user: User;
  presentation: AssurantQuotePresentation;
  onUpdateQuote: (newQuote: Partial<AssurantQuote>) => void;
}) {
  const [showElectronicConsent, setShowElectronicConsent] = useState(false);
  const updateAssurantQuoteMutation = useUpdateInsuranceQuote(user);

  const defaultLeaseAddress = user.addresses.find((a) => a.isLeaseAddress);
  let { billingAddress } = presentation;

  const isPaperless =
    presentation.isPaperless === undefined || presentation.isPaperless === null
      ? true
      : presentation.isPaperless;

  const mailingAddress = billingAddress ?? defaultLeaseAddress;

  const onSelectEmail = async () => {
    try {
      const result = await updateAssurantQuoteMutation.mutateAsync({
        isPaperless: true,
        billingAddress: { id: 0 },
      });
      onUpdateQuote(result);
    } catch (e) {}
  };

  const onSelectMail = async () => {
    if (!mailingAddress) {
      return;
    }

    try {
      const result = await updateAssurantQuoteMutation.mutateAsync({
        isPaperless: false,
        billingAddress: mailingAddress,
      });
      onUpdateQuote(result);
    } catch (e) {}
  };

  return (
    <div className="py-4">
      <h3 className="font-medium">Policy Delivery</h3>
      <p className="mt-2 text-sm">
        How would like to receive your policy and billing documents?
      </p>
      <div className="flex items-center gap-4 mt-4">
        <button
          className={classNames(
            { "border-red-400": isPaperless },
            "w-28 h-20 flex flex-col items-center justify-center rounded-lg border hover:bg-gray-50 transition duration-300 group"
          )}
          onClick={onSelectEmail}
          disabled={updateAssurantQuoteMutation.isLoading}
        >
          <FontAwesomeIcon
            icon={["fal", "envelope"]}
            className="w-6 h-6 text-red-400 duration-300 group-hover:scale-105"
          />
          <p className="mt-1 text-sm font-medium">Email</p>
        </button>
        {mailingAddress ? (
          <button
            className={classNames(
              { "border-red-400": !isPaperless },
              "w-28 h-20 flex flex-col items-center justify-center rounded-lg border hover:bg-gray-50 transition duration-300 group"
            )}
            onClick={onSelectMail}
            disabled={updateAssurantQuoteMutation.isLoading}
          >
            <FontAwesomeIcon
              icon={["fal", "mailbox"]}
              className="w-6 h-6 text-red-400 duration-300 group-hover:scale-105"
            />
            <p className="mt-1 text-sm font-medium">Mailbox</p>
          </button>
        ) : null}
        {updateAssurantQuoteMutation.isLoading ? (
          <Spinner color="livly" size="xl" />
        ) : null}
      </div>
      <div className="mt-4 text-sm">
        {isPaperless ? (
          <>
            <div className="mt-4">
              <p className="text-sm font-light text-gray-700">email address</p>
              <p className="mt-1">{user.email}</p>
            </div>
            <p className="mt-6 text-sm text-gray-700">
              By selecting this option, you agree to receive documents related
              to your policy electronically. These documents may include
              schedule of future payment notices, policy documents, among
              others. By clicking “Yes” I agree that I have read and accept the
              Electronic Business Consent.{" "}
              <button
                className="underline"
                onClick={() => setShowElectronicConsent(true)}
              >
                Electronic Business Consent.
              </button>
            </p>
          </>
        ) : (
          <Link
            className="block w-full p-4 text-left transition duration-300 border rounded-lg hover:bg-slate-50"
            to="address"
          >
            <div className="flex items-center justify-between">
              <div>
                <p>{mailingAddress?.address1}</p>

                {mailingAddress?.address2 && <p>{mailingAddress?.address2}</p>}

                <p>
                  {mailingAddress?.city}, {mailingAddress?.state}{" "}
                  {mailingAddress?.zipCode}
                </p>
              </div>
              <FontAwesomeIcon icon="chevron-right" />
            </div>
          </Link>
        )}
      </div>
      <Modal
        size="md"
        title=""
        open={showElectronicConsent}
        onClose={() => setShowElectronicConsent(false)}
      >
        <div className="mt-2 text-sm">
          <p className="font-medium text-center uppercase">
            CONSENT TO CONDUCT BUSINESS ELECTRONICALLY
          </p>
          <p className="font-medium text-center">
            Please print or download a copy of this Disclosure for your records
          </p>
          <p className="mt-4">
            We are required by law to obtain Your consent to enter into the
            Contract and deliver Communications to You electronically. This
            Disclosure applies to all Communications related to Your Renters
            policy. The words <strong>“We,” “Us,”</strong> and{" "}
            <strong>“Our”</strong> refer to the company that issues Your
            Contract or provides evidence of coverage to You, and all of its
            subsidiaries, affiliates and agents. These companies operate under
            the trade name, Assurant. The words <strong>“You”</strong> and{" "}
            <strong>“Your”</strong> mean You, the individual(s) or entity that
            owns the Contract or an authorized representative.{" "}
            <strong>
              Your consent to this Disclosure is not required to obtain or renew
              any product or service provided by Us.
            </strong>
            <br />
            <br />
            <strong>“Communications”</strong> means all information that We are
            required to provide You by law, or as reasonably necessary to
            administer Your Contract, which may include, but is not limited to:
            Your enrollment or application form, declarations page, Policy,
            certificate, terms and conditions, claims adjudication, notices,
            billing statements, retail installment contract, notice of
            cancellation, notice of non-renewal and changes in the terms of Your
            Contract. Communications also means any marketing offers We may send
            via email, text or through any other electronic medium allowed by
            law.
            <br />
            <br />
            <strong>“Contract”</strong> means a Policy/certificate, or any other
            product or service requested by You and provided by Us. <br />
            <br />
            <strong>“Policy”</strong> means a written contract of insurance, or
            written agreement effecting insurance, or the certificate thereof,
            and includes all clauses, riders or endorsements, and declarations
            page. <br />
            <br />
            <strong>
              Scope of Communications to Be Provided in Electronic Form.
            </strong>
            You agree that We may provide any Communication in electronic
            format, and that We may discontinue sending paper Communications to
            You. We reserve the right to modify the terms and conditions on
            which We provide electronic Communications at any time. <br />
            <br />{" "}
            <strong>
              Method of Providing Communications to You in Electronic Form.
            </strong>{" "}
            All Communications may be provided to You by one or more of the
            following methods: (1) via e-mail; (2) by access to a secure website
            that We will designate in advance for such purpose; (3) SMS text or
            iMessage (standard messaging rates apply) sent through an automatic
            telephone dialing system, or other means; and (4) any other
            electronic means of delivery permissible under applicable law.{" "}
            <br />
            <br />
            <strong>How to Withdraw Consent.</strong> You may withdraw Your
            consent to receive Communications electronically by contacting Us at
            <a href="mailto:rentersmail@assurant.com">
              rentersmail@assurant.com
            </a>{" "}
            Please allow a reasonable period of time to process Your request.{" "}
            <br />
            <br /> <strong>How to Update Your Records.</strong> It is Your
            responsibility to provide Us with true, accurate and up-to-date
            contact information. You can update Your information by contacting
            Us at{" "}
            <a href="mailto:rentersmail@assurant.com">
              rentersmail@assurant.com
            </a>{" "}
            Please do not send confidential information to Us via traditional
            e-mail, as We cannot guarantee that the transmission will be secure.{" "}
            <br />
            <br />
            <strong>Hardware and Software Requirements.</strong>
            In order to access, view and retain electronic Communications from
            Us, You must have: (i) A device suitable for connecting to the
            Internet; (ii) An up-to-date Internet browser and device software;
            (iii) A valid e-mail account and/or cell phone number or account
            number; (iv) Added the domain[s] @assurant.com to Your e-mail
            account’s list of “safe senders;” (v) Electronic storage capacity to
            retain Our Communications and/or a printer; and (vi) Software that
            enables You to view files in Portable Document Format. You may be
            able to download the most recent version of Adobe Reader by clicking
            here. If You cannot download the most recent version of Adobe
            Reader, please call Your manufacturer to find out how to download
            software that is functionally equivalent.
            <br />
            <br />
            <strong>Requesting Paper Copies.</strong> You may request a paper
            copy of any Communication by contacting Us at{" "}
            <a href="mailto:rentersmail@assurant.com">
              rentersmail@assurant.com
            </a>
            .
            <br />
            <br />
            <strong>Severability.</strong> If any court of law, having the
            jurisdiction to decide on this matter, rules that any provision of
            this Disclosure is invalid, then that provision will be removed, and
            the remaining provisions will continue to be valid and enforceable.
            <br />
            <br />
            <strong>Acceptance and Consent.</strong> You acknowledge that: (i)
            You are the applicant or owner of the Contract, or are validly
            authorized by the applicant or owner to act on his/her behalf; (ii)
            Your consent to enter into the Contract and receive Communications
            electronically does not automatically expire and is not limited as
            to duration; (iii) We will not be liable for any loss, liability,
            cost, expense, or claim arising from the services provided pursuant
            to this Disclosure; (iv) If You cannot access Your Communications,
            You must immediately notify Us so We can help identify the issue, or
            arrange to have the Communications delivered via alternative means.
          </p>
        </div>
      </Modal>
    </div>
  );
}

function UploadProof() {
  return (
    <div className="mt-8 mb-48 md:mb-28">
      <Link
        to={`../insurance/upload-proof`}
        state={{ label: "Insurance", returnTo: -1 }}
        className="flex items-start gap-4 md:p-4 md:rounded-lg md:hover:bg-gray-50"
      >
        <FontAwesomeIcon
          icon={["far", "arrow-up-to-line"]}
          className="text-2xl text-red-400"
        />
        <div className="flex-1">
          <p className="font-medium">Already have insurance?</p>
          <p className="mt-1 text-sm">Provide proof of insurance</p>
        </div>
        <FontAwesomeIcon icon="chevron-right" className="text-gray-400" />
      </Link>
    </div>
  );
}

function PlanActions({
  quote,
  isInsuranceOnly,
}: {
  quote: AssurantQuote;
  isInsuranceOnly: boolean;
}) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { user } = useLivlyUser();
  const details = useGetStatusTypeDetails(OnboardingStatusEnum.insurance, user);
  const { updateOnboardingStatus } = usePostService(
    user.userId,
    user.propertyUnitLeaseId.toString(),
    details.onboardingStatus
  );
  const [status, setStatus] = useState<
    "adding" | "removing" | "keeping" | "pending"
  >("pending");
  const updateAssurantQuoteMutation = useUpdateInsuranceQuote(user);
  const deleteAssuranceQuoteMutation = useDeleteInsuranceQuote(user);
  const selectedPlan = getSelectedPlan(quote);

  const onKeepPlan = async () => {
    try {
      setStatus("keeping");
      await updateAssurantQuoteMutation.mutateAsync({
        isPlanAdded: true,
      });

      const notes = `$${selectedPlan?.downPaymentAmount} will be added at checkout`;
      const newOnboardingStatus: OnboardingStatus = {
        ...details.onboardingStatus,
        isCompleted: true,
        notes,
      };
      await updateOnboardingStatus(newOnboardingStatus);

      if (isNativeAppVersion()) {
        returnToNative();
        return;
      }

      navigate("..");
    } catch (e) {
      setStatus("pending");
    }
  };

  const onCheckout = async () => {
    setStatus("adding");
    await updateAssurantQuoteMutation.mutateAsync({
      isPlanAdded: true,
      numberOfPayments: quote.presentation.numberOfPayments ?? "11",
    });

    queryClient.invalidateQueries(["insurance", "quote"]);
    //track analytics

    navigate("../insurance-quote/checkout");
  };

  const onAddPlan = async () => {
    try {
      setStatus("adding");
      await updateAssurantQuoteMutation.mutateAsync({
        isPlanAdded: true,
        numberOfPayments: quote.presentation.numberOfPayments ?? "11",
      });

      const notes = `$${selectedPlan?.downPaymentAmount} will be added at checkout`;
      const newOnboardingStatus: OnboardingStatus = {
        ...details.onboardingStatus,
        isCompleted: true,
        notes,
      };
      await updateOnboardingStatus(newOnboardingStatus);
      queryClient.invalidateQueries(["insurance", "quote"]);
      //track analytics

      if (isNativeAppVersion()) {
        returnToNative();
        return;
      }

      navigate("..");
    } catch (e) {
      setStatus("pending");
    }
  };

  const onRemovePlan = async () => {
    try {
      setStatus("removing");
      await updateAssurantQuoteMutation.mutateAsync({
        isPlanAdded: false,
      });

      const notes = "";
      const newOnboardingStatus: OnboardingStatus = {
        ...details.onboardingStatus,
        isCompleted: false,
        notes,
      };
      await updateOnboardingStatus(newOnboardingStatus);
      queryClient.invalidateQueries(["insurance", "quote"]);

      if (isNativeAppVersion()) {
        returnToNative();
        return;
      }

      navigate("..");
    } catch (e) {
      setStatus("pending");
    }
  };

  const onDisagree = async () => {
    await deleteAssuranceQuoteMutation.mutateAsync();
    navigate("..");
  };

  const isAddPlanDisabled =
    !quote.presentation.isPlanAdded &&
    (!user.phone || !isValidPhone(user.phone));

  const isLoading =
    updateAssurantQuoteMutation.isLoading || status !== "pending";

  return (
    <div className="max-w-4xl mx-auto fixed bottom-0 left-4 right-4 flex flex-col items-stretch m-4 bg-white border border-gray-100 rounded-lg shadow-lg md:flex-row md:justify-between md:items-baseline md:left-[272px] drop-shadow-lg">
      <div className="flex flex-col items-center justify-between w-full p-4 border-t border-gray-200 md:flex-row md:border-none">
        <div className="flex flex-col items-center justify-center mr-4 md:items-baseline">
          <p className="text-xs font-light text-center md:mr-2 md:text-left">
            I acknowledge that I have read and understand all applicable state
            disclosures, fraud notices, and notice of cancellation.
          </p>
        </div>
        <div className="flex items-center w-full gap-4 mt-6 md:mt-0 md:w-auto">
          {isInsuranceOnly ? (
            <Button
              color="default"
              className="flex-1 w-full md:flex-auto whitespace-nowrap"
              onClick={onCheckout}
            >
              Checkout
            </Button>
          ) : quote.presentation.isPlanAdded ? (
            <>
              <Button
                color="default"
                className="flex-1 w-full md:flex-auto whitespace-nowrap"
                onClick={onKeepPlan}
                disabled={isLoading}
              >
                Keep Plan
              </Button>
              <Button
                color="secondary"
                onClick={onRemovePlan}
                disabled={isLoading}
                className="flex items-center flex-1 w-full gap-2 text-white bg-red-600 md:w-auto whitespace-nowrap hover:bg-red-700"
              >
                {isLoading && <Spinner />}
                {status === "removing" ? "Removing Plan" : "Remove Plan"}
              </Button>
            </>
          ) : (
            <>
              <button
                className="flex-1 w-full p-2 text-sm border border-gray-400 rounded-md md:flex-auto whitespace-nowrap"
                onClick={onDisagree}
              >
                Disagree
              </button>
              <Button
                color="secondary"
                onClick={onAddPlan}
                disabled={isAddPlanDisabled || isLoading}
                className="flex items-center flex-1 w-full gap-2 md:w-auto whitespace-nowrap"
              >
                {isLoading && <Spinner />}
                {status === "adding" ? "Adding Plan" : "Add Plan"}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export { InsuranceQuotePage };

/* Hooks */

async function updateLivlyUser(
  userId: number,
  status: string,
  phoneNumber: string,
  vybiconId?: string | null
) {
  const result = await axios.post<
    BaseLivlyApiResponse<{
      phone: string;
      status: string;
      userId: number;
      vybiconId: string | null;
    }>
  >(`${BASE_API_URL}/livly/users/me/update`, {
    phone: stripPhone(phoneNumber),
    userId,
    status,
    vybiconId,
  });

  return result.data.Data;
}

async function deleteInsuranceQuote(userId: number, leaseId: number) {
  const result = await axios.delete(
    `${BASE_API_URL}/v2/rentersinsurance/Quote/${userId}/${leaseId}`,
    undefined
  );

  return result.data.Data;
}

async function getInsuranceQuote(
  userId: number,
  leaseId: number,
  additionalData?: Record<string, any>
) {
  const result = await axios.post<BaseLivlyApiResponse<AssurantQuote>>(
    `${BASE_API_URL}/v2/rentersinsurance/Quote/${userId}/${leaseId}`,
    additionalData
  );

  return result.data.Data;
}

export const insuranceQuoteQuery = (
  userId: number,
  leaseId: number,
  additionalData?: Record<string, any>
) => ({
  queryKey: ["insurance", "quote", userId, leaseId],
  queryFn: async () => getInsuranceQuote(userId, leaseId, additionalData),
});

export const useInsuranceQuote = (user: User) => {
  let additionalData: Record<string, string | number | number[]> = {};

  if (user.roommates && user.roommates.length === 1) {
    additionalData = { additionalUserId: user.roommates[0].userId };
  }

  return useQuery({
    ...insuranceQuoteQuery(
      user.userId,
      user.propertyUnitLeaseId,
      additionalData
    ),
  });
};

export const useUpdateInsuranceQuote = (user: User) => {
  return useMutation((data: any) =>
    getInsuranceQuote(user.userId, user.propertyUnitLeaseId, data)
  );
};

export const useDeleteInsuranceQuote = (user: User) => {
  return useMutation(() =>
    deleteInsuranceQuote(user.userId, user.propertyUnitLeaseId)
  );
};

export type RequoteRequestModel = {
  coveragePersonalLiability: string;
  coveragePersonalProperty: string;
  deductable: string;
  policyEffectiveDate: string;
};

async function requoteInsuranceQuote(
  userId: number,
  leaseId: number,
  additionalData?: RequoteRequestModel
) {
  const result = await axios.post<BaseLivlyApiResponse<AssurantQuote>>(
    `${BASE_API_URL}/v2/rentersinsurance/Requote/${userId}/${leaseId}`,
    additionalData
  );
  return result.data.Data;
}

export const useRequoteInsuranceQuote = (user: User) => {
  return useMutation((data: RequoteRequestModel) =>
    requoteInsuranceQuote(user.userId, user.propertyUnitLeaseId, data)
  );
};

const getSelectedPlan = (quote: AssurantQuote) => {
  const selectedNumberOfPayments = quote.presentation.numberOfPayments
    ? Number(quote.presentation.numberOfPayments)
    : 11;

  const selectedPlan =
    quote.purchase.schedules.find(
      (s) => s.numberOfPayments === selectedNumberOfPayments
    ) ||
    quote.purchase.schedules.find((s) => s.numberOfPayments === 1) ||
    quote.purchase.schedules.find((s) => s.numberOfPayments === 4);

  return selectedPlan;
};
