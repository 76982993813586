import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import ImagesLightbox from "./ImagesLightbox";

function PhotoList({
  images,
  onRemove,
}: {
  images: string[];
  onRemove: (uri: string) => void;
}) {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  return (
    <>
      <div className="flex overflow-auto">
        <div className="inline-flex py-4 space-x-2">
          {images.map((photo, index) => (
            <div key={photo} className="relative flex-shrink-0">
              <img
                className="w-[200px] h-[88px] object-cover rounded-lg"
                src={photo}
                alt={photo}
                onClick={() => {
                  setIsLightboxOpen(true);
                  setSelectedImageIndex(index);
                }}
              />
              <button
                aria-label="Remove image"
                className="absolute top-0 right-0 flex items-center justify-center w-8 h-8 bg-gray-200"
                onClick={() => onRemove(photo)}
              >
                <FontAwesomeIcon icon="times" />
              </button>
            </div>
          ))}
        </div>
      </div>

      {isLightboxOpen && (
        <ImagesLightbox
          images={images.map((photo) => photo)}
          onClose={() => setIsLightboxOpen(false)}
          selectedImageIndex={selectedImageIndex}
        />
      )}
    </>
  );
}

export { PhotoList };
