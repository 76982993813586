import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { Link, useParams } from "react-router-dom";
import Layout from "../components/Layout";
import { BuildingTypeEnum, UserType } from "../types/User";
import useLivlyUser from "../context/UserProvider";
import { ServiceTypeEnum } from "../types/Building";
import { useFeatureToggle } from "./features";

export default function MyLeasePage() {
  const params = useParams<{ leaseId: string }>();
  const { user } = useLivlyUser();
  const {
    building: { serviceTypes },
  } = user;
  const delegateUserCount = (
    user.roommates?.filter((r) => r.userType === UserType.Delegated) ?? []
  ).length;

  const petServiceEnabled =
    serviceTypes?.find(
      (service) => service.serviceType === ServiceTypeEnum.Pets
    )?.enabled ?? false;

  const vehicleServiceEnabled =
    serviceTypes?.find(
      (service) => service.serviceType === ServiceTypeEnum.Vehicles
    )?.enabled ?? false;

  const insuranceServiceEnabled =
    serviceTypes?.find(
      (service) => service.serviceType === ServiceTypeEnum.RentersInsurance
    )?.enabled ?? false;

  const utilityServiceEnabled =
    serviceTypes?.find(
      (service) => service.serviceType === ServiceTypeEnum.Utility
    )?.enabled ?? false;

  const guestFlag = useFeatureToggle("guests");

  return (
    <Layout title="My Lease">
      <div className="mt-4">
        <div className="flex items-center">
          <img
            src={user.building.logoUrl!}
            className="w-20 h-20 rounded-full"
            alt={user.building.name}
          />
          <div className="ml-4">
            <p className="text-xl font-semibold">Unit {user.unit}</p>
            <p className="text-4xl font-bold">{user.building.name}</p>
          </div>
        </div>
        <div className="flex pb-4 border-b border-gray-200 divide-x divide-gray-200">
          <div className="flex flex-col items-center justify-center flex-1 py-6">
            <p className="mt-2 text-2xl">
              {moment(user.leaseStartDate).format("MMM D, YYYY")}
            </p>
            <p className="font-light">Starts</p>
          </div>
          <div className="flex flex-col items-center justify-center flex-1 py-6">
            <p className="mt-2 text-2xl">
              {moment(user.leaseEndDate).format("MMM D, YYYY")}
            </p>
            <p className="font-light">Ends</p>
          </div>
        </div>

        {!guestFlag && (
          <div className="mt-4 md:px-8">
            <Link
              to="../family-logins"
              className="flex items-center justify-between p-2 rounded-lg hover:bg-gray-50"
            >
              <div>
                <p className="text-xl font-semibold">Friends & Family logins</p>
                <p className="mt-1 text-gray-500">
                  Share access to the Livly app with friends and family. You can
                  choose which features they will have access to.
                </p>
              </div>
              <div className="flex items-center">
                <span className="inline-flex items-center rounded-full bg-blue-100 px-3 py-0.5 text-sm font-medium text-blue-800">
                  {delegateUserCount}
                </span>
                <FontAwesomeIcon
                  icon="chevron-right"
                  className="ml-4 text-gray-400"
                />
              </div>
            </Link>
          </div>
        )}

        <div className="mt-4 md:px-8">
          <h2 className="ml-2 text-xl font-semibold">
            {user.presentation?.buildingType === BuildingTypeEnum.Condos
              ? "My Home"
              : "My Apartment"}
          </h2>
          <ul className="flex flex-col space-y-2 divide-y divide-gray-200">
            {user.status === "Current" && insuranceServiceEnabled && (
              <MyLink
                icon="home"
                label="Insurance"
                to={`/lease/${params.leaseId}/insurance`}
              />
            )}
            {vehicleServiceEnabled && (
              <MyLink
                icon="car"
                label="Vehicles"
                to={`/lease/${params.leaseId}/vehicles`}
              />
            )}
            {petServiceEnabled && (
              <MyLink
                icon="dog"
                label="Pets"
                to={`/lease/${params.leaseId}/pets`}
              />
            )}
            {utilityServiceEnabled && (
              <MyLink
                icon="file-alt"
                label="Utilities"
                to={`/lease/${params.leaseId}/utilities/${user.userId}`}
              />
            )}
            {!utilityServiceEnabled &&
              !petServiceEnabled &&
              !vehicleServiceEnabled &&
              !insuranceServiceEnabled && (
                <div className="p-2">
                  Apologies, it seems that the services are currently not
                  enabled for your account.
                </div>
              )}
          </ul>
        </div>
      </div>
    </Layout>
  );
}

function MyLink({
  icon,
  label,
  to,
}: {
  icon: IconName;
  label: string;
  to: string;
}) {
  return (
    <li>
      <Link
        to={to}
        className="flex items-center p-2 mt-2 rounded-lg hover:bg-gray-50"
      >
        <div className="flex items-center justify-center w-8 h-8 bg-red-100 rounded-md">
          <FontAwesomeIcon icon={icon as IconName} className="text-red-400" />
        </div>
        <p className="flex-1 ml-4">{label}</p>
        <FontAwesomeIcon icon="chevron-right" className="text-gray-200" />
      </Link>
    </li>
  );
}
