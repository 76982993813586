import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

export default function Avatar({
  src,
  name,
  size = "md",
}: {
  src?: string;
  name: string;
  size?: "xs" | "sm" | "md" | "lg";
}) {
  if (!src || !name) {
    return (
      <span
        className={classNames(
          "inline-flex items-center justify-center rounded-full bg-gray-200",
          {
            "h-16 w-16": size === "lg",
            "h-12 w-12": size === "md",
            "h-10 w-10": size === "sm",
            "h-8 w-8": size === "xs",
          }
        )}
      >
        <span
          className={classNames(
            "text-sm font-medium leading-none text-gray-700",
            {
              "text-lg": size === "lg",
            }
          )}
        >
          <FontAwesomeIcon icon="user" />
        </span>
      </span>
    );
  }

  if (!src) {
    const [firstName, lastName] = name.split(" ");

    return (
      <span
        className={classNames(
          "inline-flex items-center justify-center rounded-full bg-gray-200",
          {
            "h-16 w-16": size === "lg",
            "h-12 w-12": size === "md",
            "h-10 w-10": size === "sm",
            "h-8 w-8": size === "xs",
          }
        )}
      >
        <span
          className={classNames(
            "text-sm font-medium leading-none text-gray-700",
            {
              "text-lg": size === "lg",
            }
          )}
        >
          {firstName[0]}
          {lastName[0]}
        </span>
      </span>
    );
  }

  return (
    <img
      className={classNames("inline-block rounded-full", {
        "h-12 w-12": size === "md",
        "h-10 w-10": size === "sm",
        "h-8 w-8": size === "xs",
      })}
      src={src}
      alt={name}
    />
  );
}
