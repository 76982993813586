import React, { ReactElement, FC, useState, useRef, useEffect } from "react";

import FadeIn from "./FadeIn";
import useOnScreen from "./hook/useOnScreen";

type Props = {
  offset?: string;
  minHeight?: number;
  loader?: ReactElement;
  children?: React.ReactNode;
};

const OnScreen: FC<Props> = ({ children, offset, minHeight = 100, loader }) => {
  const container = useRef<HTMLDivElement | null>(null);
  const isVisible = useOnScreen(container, offset);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isVisible) {
      setIsLoaded(true);
    }
  }, [isVisible, isLoaded]);

  return (
    <div ref={container} style={{ minHeight }}>
      {isLoaded ? <FadeIn>{children}</FadeIn> : loader}
    </div>
  );
};

export default OnScreen;
