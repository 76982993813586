import { BookingContextProps } from "./add-booking";

import DailyAvailabilityContainer from "../../components/DailyBooking";
import HourlyAvailabilityContainer from "../../components/HourlyBooking";
import {
  useParams,
  useNavigate,
  useOutletContext,
  useLocation,
} from "react-router-dom";
import Layout from "../../components/Layout";
import { BookingAvailabilityType } from "../../types/Bookings";

export default function SelectDateTimePage() {
  const params = useParams<{
    leaseId: string;
    id: string;
    propertyId: string;
  }>();
  const navigate = useNavigate();
  const {
    booking,
    amenitySpace,
    setBooking,
    setSelectedHourlySlots,
    selectedHourlySlots,
  } = useOutletContext<BookingContextProps>();
  const location =
    (useLocation().state as {
      returnTo: string;
      label: string;
    }) || {};
  const handleNext = () => navigate(`confirm`, { state: { ...location } });

  return (
    <Layout
      title="Amenity Booking"
      back={{
        to: `/lease/${params.leaseId}/amenities/${params.propertyId}/${params.id}`,
        label: amenitySpace.name || "Amenity Space",
      }}
    >
      {amenitySpace.bookingAvailabilityType ===
      BookingAvailabilityType.Daily ? (
        <HourlyAvailabilityContainer
          booking={booking}
          amenitySpace={amenitySpace}
          onNext={handleNext}
          onUpdateBooking={setBooking}
          selectedHourlySlots={selectedHourlySlots}
          setSelectedHourlySlots={setSelectedHourlySlots}
        />
      ) : (
        <DailyAvailabilityContainer
          booking={booking}
          amenitySpace={amenitySpace}
          onNext={handleNext}
          onUpdateBooking={setBooking}
        />
      )}
    </Layout>
  );
}
