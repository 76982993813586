import axios from "axios";

import { PollResult } from "./types";
import { useQuery } from "@tanstack/react-query";
import { BaseLivlyApiResponse } from "@/types/Base";
import { BASE_API_URL } from "@/utils/constants";

const useGetPolls = (propertyId: number) => {
  return useQuery({
    queryKey: ["polls-list", propertyId],
    queryFn: () => getPolls(propertyId),
    refetchOnWindowFocus: false
  });
};

const getPolls = async (propertyId: number) => {
  const result = await axios.get<BaseLivlyApiResponse<PollResult[]>>(
    `${BASE_API_URL}/resident/poll/property/${propertyId}/answers?limit=200`
  );

  return result.data.Data;
};

export default useGetPolls;
