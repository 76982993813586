import { Formik, Field, Form, useField } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputMask from "react-input-mask";
import {
  GuestCategory,
  GuestResponse,
  GuestType
} from "../routes/guests/my-guests";
import { Button } from "./Button";
import { FormField, TextArea, textInputClass } from "./Form";
import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "./Spinner";
import { ServiceTypeEnum } from "../types/Building";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import useLivlyUser from "../context/UserProvider";
import ReactCropper from "react-cropper";
import { Modal } from "./Dialog";
import toast from "react-hot-toast";
import { isValidFormat, isValidSize } from "@/utils/image";
import useLivlyGuest, {
  DelegateServiceTypes,
  GuestRequest,
  GuestSchema,
  GuestWaiverMetaData,
  initialAccessControlSchedule,
  phonePlaceholder
} from "@/context/GuestProvider";
import flag from "../assets/us_flag.svg";
import { useFeatureToggle } from "@/routes/features";

export const businesses = [
  GuestCategory.DogWalker,
  GuestCategory.Cleaner,
  GuestCategory.Delivery,
  GuestCategory.OtherBusiness
];

export function getTypeName(type: GuestCategory) {
  switch (type) {
    case GuestCategory.Cleaner:
      return "Cleaner";
    case GuestCategory.Delivery:
      return "Delivery";
    case GuestCategory.DogWalker:
      return "Dog Walker";
    case GuestCategory.OtherBusiness:
      return "Other Business";
    default:
      return "Personal Guest";
  }
}

type Props = {
  onSubmit: (guest: GuestRequest) => Promise<void> | void;
  error: any;
  isLoading?: boolean;
  initialGuest?: GuestResponse;
  services?: DelegateServiceTypes[];
  redirect?: false;
};

export default function GuestForm({
  onSubmit,
  error,
  isLoading,
  initialGuest,
  services,
  redirect = false
}: Props) {
  const { user } = useLivlyUser();
  const { guest, updateGuest, resetGuest } = useLivlyGuest();
  const navigate = useNavigate();
  let waiverRequired = false;
  let waiverText = "";
  const guestService = user.building.serviceTypes?.find(
    (s) => s.serviceType === ServiceTypeEnum.MyGuests
  );

  const delgateService = user.building.serviceTypes?.find(
    (s) => s.serviceType === ServiceTypeEnum.DelegateUsers
  );

  const isDeligateUser = delgateService?.enabled ?? false;
  const guestFlag = useFeatureToggle("guests");

  const [isWaiverAgreed, setIsWaiverAgreed] = useState(false);

  if (guestService?.metaData) {
    const metaData = JSON.parse(guestService.metaData) as GuestWaiverMetaData;
    waiverRequired = metaData.waiverRequired ?? false;
    waiverText = metaData?.waiverText ?? "";
  }

  useEffect(() => {
    if (initialGuest && !redirect) {
      updateGuest({
        ...initialGuest,
        staffNotes: null,
        firstName: initialGuest.firstName || "",
        lastName: initialGuest.lastName || "",
        email: initialGuest.email || "",
        note: initialGuest.note || "",
        companyName: initialGuest.companyName,
        guestType: initialGuest.type.id.toString(),
        waiverAccepted: true,
        serviceTypes: services?.map((service) => ({
          ...service,
          enabled: initialGuest?.serviceTypes?.find(
            (s) => s.serviceType === service.serviceType
          )?.enabled
            ? true
            : false
        })),
        accessControl:
          initialGuest?.accessControl !== null
            ? initialGuest.accessControl
            : {
                allowUnitDoors: false,
                schedule: initialAccessControlSchedule
              }
      });
    }
  }, [initialGuest]);

  const onSubmitRefine = (guest: GuestRequest) => {
    const category = businesses.includes(
      guest.guestType as unknown as GuestCategory
    )
      ? GuestType.Business
      : GuestType.Personal;

    const guestWithCategory: GuestRequest = {
      ...guest,
      avatarBase64Image: guest?.avatarBase64Image
        ? guest?.avatarBase64Image?.replace("data:image/png;base64,", "")
        : null,
      type: {
        id: Number(guest.guestType),
        name: getTypeName(guest.guestType as unknown as GuestCategory),
        category,
        isDeleted: false
      }
    };

    onSubmit(guestWithCategory);
  };

  return (
    <Formik
      initialValues={guest}
      validationSchema={toFormikValidationSchema(GuestSchema)}
      onSubmit={onSubmitRefine}
      enableReinitialize={true}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form>
          {error?.data?.Errors && (
            <div className="p-4 my-2 bg-red-100 border-l-4 border-red-500 rounded-md">
              {JSON.stringify(error?.data?.Errors, null, 2)}
            </div>
          )}
          <div className="flex justify-center">
            <UserAvatar
              values={values}
              onSubmit={(value: string) =>
                setFieldValue("avatarBase64Image", value)
              }
            />
          </div>

          <FormField htmlFor="guestType" label="Guest Type">
            <Field
              as="select"
              id="guestType"
              name="guestType"
              className="block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            >
              <option disabled value={GuestCategory.None}>
                None
              </option>
              <option value={GuestCategory.PersonalGuest}>
                Personal Guest
              </option>
              <option value={GuestCategory.DogWalker}>Dog Walker</option>
              <option value={GuestCategory.Cleaner}>Cleaner</option>
              <option value={GuestCategory.Delivery}>Delivery</option>
              <option value={GuestCategory.OtherBusiness}>
                Other Business
              </option>
            </Field>
          </FormField>

          {values.guestType === GuestCategory.PersonalGuest.toString() ? (
            <>
              <FormField
                htmlFor="firstName"
                label="First Name"
                showErrorIcon={errors.firstName && touched.firstName}
                isRequired
              >
                <Field
                  id="firstName"
                  name="firstName"
                  type="text"
                  className={textInputClass}
                />
              </FormField>
              <FormField
                htmlFor="lastName"
                label="Last Name"
                showErrorIcon={errors.lastName && touched.lastName}
                isRequired
              >
                <Field
                  id="lastName"
                  name="lastName"
                  type="text"
                  className={textInputClass}
                />
              </FormField>
            </>
          ) : (
            <FormField
              htmlFor="companyName"
              label="Business Name"
              showErrorIcon={errors.companyName && touched.companyName}
              isRequired
            >
              <Field
                id="companyName"
                name="companyName"
                placeholder=""
                type="text"
                className={textInputClass}
              />
            </FormField>
          )}

          <p className="mt-6 font-medium">
            Please enter either guest's mobile number OR email address.{" "}
            <span className="text-red-400">*</span>
          </p>

          <FormField
            htmlFor="phone"
            label="Phone"
            showErrorIcon={errors.phone && touched.phone}
          >
            <div className="flex items-center">
              <div>
                <img src={flag} alt="US Flag" />
              </div>
              <p className="mx-2 text-sm">+1</p>
              <MyPhoneInput name="phone" id="phone" />
            </div>
          </FormField>

          <FormField
            htmlFor="email"
            label="Email"
            showErrorIcon={errors.email && touched.email}
          >
            <Field
              id="email"
              name="email"
              type="email"
              placeholder="Email Address"
              disabled={guest?.hadAppPermissionGranted}
              className={`block w-full border-0 border-b border-transparent bg-gray-50 focus:border-blue-600 focus:ring-0 sm:text-sm ${
                guest?.hadAppPermissionGranted && "cursor-not-allowed"
              }`}
            />
            {guestFlag && isDeligateUser && (
              <p className="text-xs font-light text-gray-500">
                The invite will be sent via the email address
              </p>
            )}
          </FormField>

          {values.guestType !== GuestCategory.PersonalGuest.toString() && (
            <>
              <FormField
                htmlFor="firstName"
                label="First Name"
                showErrorIcon={errors.firstName && touched.firstName}
                isRequired
              >
                <Field
                  id="firstName"
                  name="firstName"
                  type="text"
                  className={textInputClass}
                />
              </FormField>
              <FormField
                htmlFor="lastName"
                label="Last Name"
                showErrorIcon={errors.lastName && touched.lastName}
                isRequired
              >
                <Field
                  id="lastName"
                  name="lastName"
                  type="text"
                  className={textInputClass}
                />
              </FormField>
            </>
          )}

          {values.guestType === GuestCategory.PersonalGuest.toString() &&
          guestFlag ? (
            <FormField htmlFor="companyName" label="Company Name">
              <Field
                id="companyName"
                name="companyName"
                placeholder=""
                type="text"
                className={textInputClass}
              />
            </FormField>
          ) : (
            <></>
          )}

          <FormField htmlFor="note" label="Add guest notes">
            <p className="mb-2 text-xs font-light text-gray-500">
              These notes will be visible to property staff.
            </p>
            <TextArea id="note" name="note" />
          </FormField>

          {waiverRequired && (
            <div className="flex items-start gap-4 mt-4">
              <input
                className="mt-2"
                id="waiverAgreed"
                name="waiverAgreed"
                type="checkbox"
                checked={isWaiverAgreed}
                onChange={(e) => setIsWaiverAgreed(e.target.checked)}
              />
              <label htmlFor="waiverAgreed">{waiverText}</label>
            </div>
          )}
          <div className="flex justify-end gap-2 mt-4">
            <Link to="../guests">
              <Button size="small" color="default" onClick={() => resetGuest()}>
                Discard Changes
              </Button>
            </Link>

            <Button
              size="small"
              color="primary"
              type={isDeligateUser && guestFlag ? "button" : "submit"}
              onClick={() => {
                if (isDeligateUser && guestFlag) {
                  updateGuest(values);
                  navigate("./permission");
                } else {
                  onSubmitRefine(values);
                }
              }}
              disabled={
                isLoading ||
                (waiverRequired && !isWaiverAgreed) ||
                !values.firstName ||
                !values.lastName ||
                (!values.email && !values.phone) ||
                !!errors?.email ||
                !!errors?.phone ||
                values.phone == phonePlaceholder ||
                (values.guestType !== GuestCategory.PersonalGuest.toString() &&
                  !values.companyName)
              }
              className="flex items-center gap-2"
            >
              {isLoading && <Spinner />}
              {isDeligateUser && guestFlag
                ? "Next"
                : initialGuest?.guestId
                ? "Save"
                : "Add"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

function MyPhoneInput(props: JSX.IntrinsicElements["input"]) {
  const { guest } = useLivlyGuest();
  const [field] = useField(props.name!);
  return (
    <InputMask
      mask="(999) 999-9999"
      disabled={guest?.hadAppPermissionGranted}
      {...field}
    >
      {
        //@ts-ignore
        (inputProps: any) => {
          return (
            <input
              {...inputProps}
              {...props}
              type="tel"
              placeholder="Phone number"
              className={`block w-full border-0 border-b border-transparent bg-gray-50 focus:border-blue-600 focus:ring-0 sm:text-sm ${
                guest?.hadAppPermissionGranted && "cursor-not-allowed"
              }`}
            />
          );
        }
      }
    </InputMask>
  );
}

function UserAvatar({
  values,
  onSubmit: handleImgageUpload
}: {
  values: GuestRequest;
  onSubmit: (value: string) => void;
}) {
  const editor = useRef<HTMLImageElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [cropper, setCropper] = useState<Cropper | null>(null);
  const [image, setImage] = useState<string | null>(null);

  const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let hasError = false;
    const files = event.target.files;

    if (files && files.length) {
      const file = files[0] as File;

      if (!isValidSize(file.size, 5)) {
        hasError = true;
        toast.error("File must be less than 5mb");
      }

      if (!isValidFormat(file.name.split(".").pop(), ["png", "jpg", "jpeg"])) {
        hasError = true;
        toast.error("File must be in a valid format");
      }

      if (hasError) {
        return;
      }

      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const onSubmit = async () => {
    try {
      if (cropper === null) {
        return;
      }

      const image = cropper.getCroppedCanvas().toDataURL();
      handleImgageUpload(image);
      setImage(null);
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    } catch (error) {
      const mutationError = error as { data?: { Message: string } };
      const errorMessage = mutationError?.data?.Message;
      toast.error(errorMessage ?? "There was an error uploading avatar.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center mt-8 space-y-4">
      <label className="relative cursor-pointer">
        {values?.avatarUri || values?.avatarBase64Image ? (
          <img
            className="h-24 w-24 rounded-full"
            src={(values?.avatarBase64Image || values?.avatarUri) ?? ""}
          />
        ) : (
          <div className="flex items-center justify-center w-24 h-24 bg-gray-200 rounded-full">
            <FontAwesomeIcon
              icon={
                values.guestType === GuestCategory.PersonalGuest.toString()
                  ? "user"
                  : "building"
              }
              className="text-2xl"
            />
          </div>
        )}
        <span className="absolute bottom-1 right-0 rounded-full p-1 h-6 w-6 flex items-center justify-center bg-red-300">
          <FontAwesomeIcon icon="camera" className="text-xs text-white" />
        </span>
        <input
          type="file"
          className="hidden"
          accept="image/*,image/heif,image/heic"
          onChange={onFileInputChange}
          ref={inputRef}
        />
      </label>
      {image ? (
        <Modal
          open
          onClose={() => {
            if (inputRef.current) {
              inputRef.current.value = "";
            }
            setImage(null);
          }}
        >
          <div className="flex flex-col items-center justify-center p-8">
            <div className="max-w-lg mt-4">
              <ReactCropper
                ref={editor}
                aspectRatio={1}
                src={image}
                guides={false}
                dragMode="move"
                background={false}
                viewMode={1}
                responsive
                center={false}
                cropBoxMovable={false}
                cropBoxResizable={false}
                onInitialized={(c) => {
                  setCropper(c);
                }}
              />
            </div>

            <div className="flex gap-1 mt-2">
              <span>
                <button
                  className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100 disabled:hover:bg-white disabled:cursor-not-allowed group"
                  onClick={() => cropper?.zoom(-0.1)}
                >
                  <FontAwesomeIcon
                    icon="minus"
                    className="group-disabled:text-gray-300"
                  />
                </button>
              </span>
              <button
                className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100 disabled:hover:bg-white disabled:cursor-not-allowed group"
                onClick={() => cropper?.zoom(0.1)}
              >
                <FontAwesomeIcon
                  icon="plus"
                  className="group-disabled:text-gray-300"
                />
              </button>
            </div>
          </div>
          <div className="mt-4 flex justify-end gap-4">
            <Button
              color="default"
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.value = "";
                }
                setImage(null);
              }}
            >
              reset
            </Button>
            <Button onClick={onSubmit}>Update</Button>
          </div>
        </Modal>
      ) : null}
      <></>
    </div>
  );
}
