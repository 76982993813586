import { Button } from "@/components/Button";
import { Link } from "react-router-dom";

export default function NoAccessPage() {
  return (
    <div className="h-full flex flex-col justify-center items-center gap-6">
      <img src="/door.png" alt="Livly No Access" className="w-[250px]" />
      <h2 className="text-center">
        Ooops, looks like you don't have active access to any leases in Livly.{" "}
        <br />
        Please contact your property manager if you have an issue.
      </h2>
      <Link to="/log-out">
        <Button>Log Out</Button>
      </Link>
    </div>
  );
}
