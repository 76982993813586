import Linkify from "react-linkify";

function TextContent({ text }: { text: string }) {
  const renderLink = (
    decoratedHref: string,
    decoratedText: string,
    key: number
  ): React.ReactNode => {
    return (
      <a
        key={key}
        target="_blank"
        rel="noopener noreferrer"
        href={decoratedHref}
        className="text-blue-500 hover:underline"
      >
        {decoratedText}
      </a>
    );
  };

  return (
    <p className="whitespace-pre-wrap break-words text-base mb-2">
      <Linkify componentDecorator={renderLink}>{text}</Linkify>
    </p>
  );
}

export { TextContent };
