import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function AutoPayIcon({ isExpired = false }: { isExpired?: boolean }) {
  return (
    <span
      className={`fa-layers fa-fw ${
        isExpired ? "text-red-400" : "text-blue-500"
      }`}
    >
      <FontAwesomeIcon icon={"rotate-right"} />
      <FontAwesomeIcon icon={"dollar-sign"} transform={"shrink-8"} />
    </span>
  );
}

export { AutoPayIcon };
