import Analytics from "analytics";
import segmentPlugin from "@analytics/segment";
import { CartItem, CartItemEnum } from "../types/Cart";
import { PaymentAccount, RenewalSectionType } from "../types/User";
import numeral from "numeral";
import { TicketState } from "../types/Maintenance";
import { MOBILE_KEY } from "./localStorage";

const analytics = Analytics({
  app: "resident-web",
  plugins: [
    segmentPlugin({
      writeKey: import.meta.env.VITE_SEGMENT_KEY,
    }),
  ],
});

const trackEvent = (eventName: string, traits?: any) => {
  // do check to see if its coming from native app, from local storage or some other method
  const mobileFromLocalStorage = localStorage.getItem(MOBILE_KEY);
  const isWebOnly = mobileFromLocalStorage == null;

  analytics.track(eventName, { ...traits, IsWebOnly: isWebOnly });
};

export const identifyUser = (userId: number) => {
  analytics.identify(userId.toString());
};

// Terms Of Service

export const trackTermsOfServiceViewPrivacyPolicy = () => {
  trackEvent("View Privacy Policy");
};

export const trackTermsOfServiceViewToS = () => {
  trackEvent("View Terms Of Service");
};

export const trackAcceptTermsOfService = (isAccepted: boolean) => {
  trackEvent("View Terms Of Service", {
    IsWebOnly: true,
    "Terms Of Service Value": isAccepted,
  });
};

// Checklist Profile

export const trackConfirmProfileAndLeaseInfo = () => {
  trackEvent("Confirm Profile & Lease Information");
};

export const trackConfirmRoommates = (numberOfRoommates: number) => {
  trackEvent("Confirm Roommate Information", {
    "Number Of Roommates": numberOfRoommates,
  });
};

// CART

export const trackViewCheckoutPage = (cartItems: CartItem[]) => {
  const rentCartItem = cartItems.find((ci) => ci.type === CartItemEnum.RENT);
  const insuranceCartItem = cartItems.find(
    (ci) => ci.type === CartItemEnum.INSURANCE
  );

  const totalPaymentAmount = cartItems.reduce((accumulator, currentValue) => {
    if (currentValue.cost <= 0) {
      return accumulator;
    }
    accumulator = numeral(accumulator)
      .add(currentValue.feeData.totalAmount)
      .value()!;

    return accumulator;
  }, 0);

  trackEvent("View Checkout Page", {
    "Rent Balance": rentCartItem ? rentCartItem.cost : "",
    "Insurance Balance": insuranceCartItem ? insuranceCartItem.cost : "",
    "Total Payment Amount": totalPaymentAmount,
    Currency: "USD",
  });
};

export const trackViewCartSummary = (
  cartItems: CartItem[],
  paymentAccounts: PaymentAccount[] = []
) => {
  const insuranceCartItem = cartItems.find(
    (ci) => ci.type === CartItemEnum.INSURANCE
  );
  const defaultPaymentAccount = paymentAccounts.find((pa) => pa.isDefault);

  trackEvent("View Cart Summary", {
    Insurance: insuranceCartItem !== undefined,
    "Insurance Payment Plan":
      insuranceCartItem !== undefined
        ? insuranceCartItem.additionalData["number of payments"] === 1
          ? "full"
          : "monthly"
        : "",
    "Default Payment Method Type": defaultPaymentAccount?.paymentType || "",
  });
};

export const trackEditPaymentAmount = () => {
  trackEvent("Edit Rent Payment Amount");
};

export const trackChangeRentPaymentAccount = () => {
  trackEvent(`Edit Rent Payment Method`);
};

export const trackMakePayment = (
  cartItems: CartItem[],
  paymentAccounts: PaymentAccount[]
) => {
  const rentItem = cartItems.find((ci) => ci.type === 1);
  const rentersItem = cartItems.find((ci) => ci.type === 2);

  let userProperties: Record<string, boolean> = {};

  if (rentItem) {
    userProperties["First Month Rent Paid"] =
      rentItem.error?.length === 0 && rentItem.paymentAmount === rentItem.cost;
  }

  if (rentersItem) {
    userProperties["Insurance"] = rentersItem.error?.length === 0;
  }

  trackEvent("Make Payment", {
    "Rent Payment Amount":
      (rentItem?.paymentAmount ?? 0) > 0
        ? rentItem?.paymentAmount
        : rentItem?.cost || "",
    "Rent Payment Type": rentItem
      ? rentItem.paymentAmount === rentItem.cost
        ? "full"
        : "fixed"
      : "",
    "Rent Payment Method":
      paymentAccounts.find(
        (account) => account.id === rentItem?.paymentMethodId
      )?.paymentType || "",
    "Insurance Payment Amount":
      (rentersItem?.paymentAmount ?? 0) > 0
        ? rentersItem?.paymentAmount
        : rentersItem?.cost || "",
    "Insurance Payment Type": rentersItem
      ? rentersItem.additionalData["number of payments"] === "1"
        ? "full"
        : "first installment"
      : "",
    "Insurance Payment Method":
      paymentAccounts.find(
        (account) => account.id === rentersItem?.paymentMethodId
      )?.paymentType ?? "",
    "Rent Payment Status": rentItem
      ? rentItem.error?.length
        ? "failed"
        : "succeeded"
      : "",
    "Insurance Payment Status": rentersItem
      ? rentersItem.error?.length
        ? "failed"
        : "succeeded"
      : "",
    "Rent Payment Failure": rentItem ? rentItem.error : "",
    "Insurance Payment Failure": rentersItem ? rentersItem.error : "",
    "Checklist Status": "true",
    Currency: "USD",
    $revenue: cartItems.reduce((prev, curr) => {
      prev += curr.feeData?.totalAmount;

      return prev;
    }, 0),
    userProperties,
  });
};

// LOGIN

export const trackChangeEmailAddress = () => {
  trackEvent(`Enter Email Address`);
};

export const trackChangePassword = () => {
  trackEvent("Enter Password");
};

export const trackShowHidePassword = (isVisible: boolean) => {
  trackEvent("Show/Hide Password", { "Show/Hide Value": isVisible });
};

export const trackLoginSuccess = (isSuccess: boolean, emailAddress: string) => {
  trackEvent("Log In", {
    "Login Success": isSuccess,
    "Email Address Value": emailAddress,
  });
};

// PAYMENT

export const trackAddPaymentAccount = () => {
  trackEvent("Add Payment Method", { Source: "Other" });
};

export const trackViewPaymentAccounts = (paymentAccounts: PaymentAccount[]) => {
  trackEvent("User Views Their Payment Methods", {
    "Number Of Payment Methods": paymentAccounts.length,
  });
};

// RENT

export const trackMakeRentPaymentSuccess = (
  isFullAmount: boolean,
  paymentAmount: number,
  isCreditCard: boolean,
  fee: number,
  isSuccess: boolean
) => {
  trackEvent("Make Payment", {
    "Rent Payment Type": isFullAmount
      ? "Full Statement Balance"
      : "Fixed Amount",
    "Rent Payment Amount": paymentAmount,
    "Rent Payment Method": isCreditCard ? "Credit/Debit Card" : "Bank Account",
    "Card Fee": fee,
    "Rent Payment Status": isSuccess ? "Success" : "Failure",
  });
};

export const trackActivateAutopay = (paymentAccount: PaymentAccount) => {
  trackEvent("Activate Autopay", {
    "Autopay Payment Method":
      paymentAccount.paymentType === "Credit Card"
        ? "Credit/Debit Card"
        : "Bank Account",
  });
};

export const trackDeactivateAutopay = () => {
  trackEvent("Deactivate Autopay");
};

export const trackChangeAutopayPaymentAccount = () => {
  trackEvent("Edit Autopay Payment Method");
};

export const trackViewAutopayPage = (isActive: boolean) => {
  trackEvent("Open Autopay", { Autopay: isActive });
};

export const trackStartPayRent = () => {
  trackEvent("Start Pay Rent");
};

export const trackViewPaymentHistory = () => {
  trackEvent("View Payment History");
};

export const trackViewPaymentDetails = (isCreditCard: boolean) => {
  trackEvent("View Payment Details", {
    "Rent Payment Method": isCreditCard ? "Credit/Debit Card" : "Bank Account",
  });
};

// USER

export const trackUserClickMoveInSupport = () => {
  trackEvent("Move In Contact Support");
};

export const trackAddProfilePicture = () => {
  trackEvent("Add Profile Picture");
};

export const trackEditMoveInScheduleDate = () => {
  trackEvent("Edit Schedule Move-In Date");
};

export const trackFinishMoveInSchedule = (isSkipped: boolean) => {
  trackEvent("Finish Schedule Move-In Step", {
    Decision: isSkipped ? "Skip" : "Schedule Move-In",
  });
};

// CHAT

export const trackSendChatMessage = () => {
  trackEvent("Send Message");
};

// EVENTS

export const trackEventRegister = (eventId: string) => {
  trackEvent("Select Register", { "Event ID": eventId });
};

export const trackChangeEventStatus = (eventId: string) => {
  trackEvent("Select Change Status", { "Event ID": eventId });
};

export const trackUpdateResponse = (eventId: string) => {
  trackEvent("Select Update Response", { "Event ID": eventId });
};

// INSPECTION CHECKLIST

export const trackViewInspectionRoom = (roomName: string) => {
  trackEvent("View Inspection Room", { "Room Name": roomName });
};

export const trackIndicateIssue = (roomName: string, damagedItem: string) => {
  trackEvent("Indicate Issue", {
    "Room Name": roomName,
    "Damaged Item": damagedItem,
  });
};

export const trackUploadInspectionPhoto = (
  roomName: string,
  damagedItem: string
) => {
  trackEvent("Upload Inspection Photo", {
    "Room Name": roomName,
    "Damaged Item": damagedItem,
  });
};

export const trackCompleteInspectionChecklist = () => {
  trackEvent("Complete Apartment Inspection");
};

// MARKETPLACE

export const trackMarketplacePageView = () => {
  trackEvent("Marketplace Page Viewed");
};

export const trackMarketplaceOfferClick = (offerTitle: string) => {
  trackEvent("View Offer", { "Offer Name": offerTitle });
};

export const trackMarketplaceClaimOffer = (offerTitle: string) => {
  trackEvent("Claim Offer", { "Offer Name": offerTitle });
};

// MAINTENANCE

export const trackViewMaintenancePage = () => {
  trackEvent("Open Maintenance", { Source: "Web" });
};

export const trackViewMaintenanceTickets = (
  ticketState: TicketState,
  numberOfTickets: number
) => {
  let status = "";

  switch (ticketState) {
    case TicketState.NEW:
    case TicketState.OPEN:
      status = "Outstanding";
      break;
    case TicketState.CLOSED:
    case TicketState.REJECTED:
      status = "Closed";
      break;
    default:
      status = "Unknown";
      break;
  }

  trackEvent("View Tickets", {
    "Ticket Status": status,
    "Number of Tickets": numberOfTickets,
  });
};

export const trackAddMaintenanceTicketAttachment = () => {
  trackEvent("Add Ticket Attachment");
};

export const trackEditPermissionToEnter = () => {
  trackEvent("Edit Permission to Enter");
};

export const trackEditTicketDescription = () => {
  trackEvent("Edit Ticket Description");
};

export const trackAddMaintenanceTicket = (
  hasPermissionToEnter: boolean,
  hasAttachments: boolean
) => {
  trackEvent("Add Ticket", {
    "Permission to Enter": hasPermissionToEnter ? "True" : "False",
    Attachments: hasAttachments ? "True" : "False",
  });
};

export const trackOpenMaintenanceChat = () => {
  trackEvent("Open Chat");
};

export const trackMaintenanceFeedbackReview = (
  score: string,
  details: boolean
) => {
  trackEvent("Submit Feedback", {
    Score: score,
    Details: details,
    "Feedback Type": "Maintenance",
  });
};

export const trackReviewLocationClick = () => {
  trackEvent("Tap Review", {
    Source: "Feedback Prompt",
  });
};

// AI

export const trackOpenAIMaintenanceChat = () => {
  trackEvent("Open AI Maintenance Chat", { Source: "Ticket list" });
};

export const trackSendMessageToAI = () => {
  trackEvent("Send Message to AI");
};

export const trackSkipAIConversation = () => {
  trackEvent("Skip AI Conversation");
};

export const trackExitMaintenanceAIChat = () => {
  trackEvent("Exit Maintenance Chat (back button)");
};

export const trackViewTicketDetails = (source: "AI Chat" | "Ticket list") => {
  trackEvent("View Ticket Details", {
    Source: source,
  });
};

export const trackTicketCreatedByAI = () => {
  trackEvent("Ticket Created By AI");
};

// RENEWAL

export const trackStartRenewalFlow = (flow: RenewalSectionType) => {
  trackEvent(
    `Start ${flow === "Deciding" ? "Still Deciding" : flow} Renewal Flow`
  );
};

export const trackSubmitRenewalFlow = (
  flow: RenewalSectionType,
  eventProperties: Record<string, string | number>
) => {
  trackEvent(
    `Submit ${flow === "Deciding" ? "Still Deciding" : flow} for Renewal`,
    eventProperties
  );
};

// INSURANCE

export const trackInsuranceMarketplaceViewed = () => {
  trackEvent("View Insurance Marketplace");
};

export const trackInsuranceMarketplaceVendorClicked = (vendor: string) => {
  trackEvent("Select Insurance Vendor", { Vendor: vendor });
};

export const trackLemonadeInsuranceMarketplaceVendorClicked = (
  vendor: string
) => {
  trackEvent("Select Lemonade", { Vendor: vendor });
};

// MAINENTANCE
