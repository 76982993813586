import classNames from "classnames";
import { useTranslation } from "react-i18next";
import SelectPaymentType from "../PaymentType";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CurrencyInput from "react-currency-input-field";
import numeral from "numeral";
import Alert from "@/components/Alert";
import { useRentAutoPay } from "@/routes/rent/autopay";

const currencyFormat = "$0,0[.]00";

function PaymentAmount({
  isMaxLimitError,
  isDwollaLimitError,
  error,
}: {
  isMaxLimitError: boolean;
  isDwollaLimitError: boolean;
  error: boolean;
}) {
  const { t } = useTranslation();
  const {
    autopayState: {
      autopaySettings,
      setAutopaySettings,
      isFixedAmountFeeDataLoading,
      isFullBalanceFeeDataLoading,
      fixedAmountFeeData,
      isFixedPaymentAvailable,
      isPaymentTypeSelectionVisible,
      fullBalanceFeeDescription,
    },
  } = useRentAutoPay();
  const amount = autopaySettings?.fixedAmount;
  const isFullStatementBalance =
    autopaySettings?.isFullStatementBalance ?? null;

  return (
    <div>
      {isPaymentTypeSelectionVisible && (
        <SelectPaymentType
          isFullStatementBalance={isFullStatementBalance}
          setIsFullStatementBalance={(isFullBalance) =>
            setAutopaySettings({
              ...autopaySettings!,
              isFullStatementBalance: isFullBalance,
              fixedAmount: null,
              autoPayDay:
                isFullBalance !== autopaySettings?.isFullStatementBalance
                  ? null
                  : autopaySettings.autoPayDay,
              nextBillingDate: null,
              startDate: null,
              endDate: null,
            })
          }
        />
      )}
      {/** This should probably be based off of a setting */}
      {isFullStatementBalance === true && (
        <label
          className={classNames(
            "py-4 border-b border-gray-200 flex justify-between items-center gap-4"
          )}
          onClick={() => {
            setAutopaySettings({
              ...autopaySettings!,
              isFullStatementBalance: true,
            });
          }}
        >
          <div>
            <p className="text-xl font-light">Full statement balance</p>

            <p className="mt-1 text-gray-500 text-sm font-light">
              {isFullBalanceFeeDataLoading
                ? "Calculating fee"
                : fullBalanceFeeDescription}
            </p>
          </div>
          {isFullStatementBalance && (
            <FontAwesomeIcon icon="check" className="text-red-400" />
          )}
        </label>
      )}
      {isFullStatementBalance === false && (
        <label
          className="flex items-center justify-between gap-4 pt-1 pb-4 border-b border-gray-200"
          onClick={() => {
            setAutopaySettings({
              ...autopaySettings!,
              isFullStatementBalance: false,
            });
          }}
          htmlFor="rent-payment-amount"
        >
          <div className="flex-1">
            <div className="relative mt-1">
              <div className="absolute inset-y-0 left-0 flex items-center pl-0 pointer-events-none">
                <span
                  className={classNames("text-3xl", {
                    "text-red-400": error,
                  })}
                >
                  $
                </span>
              </div>
              <CurrencyInput
                name="rent-payment-amount"
                id="rent-payment-amount"
                className={classNames(
                  "peer border-none w-full outline-none focus:ring-0 pl-6 pr-6 text-3xl bg-white",
                  { "text-red-400": error }
                )}
                decimalsLimit={2}
                allowNegativeValue={false}
                placeholder="0.00"
                defaultValue={amount ?? ""}
                onValueChange={(value) => {
                  const fixedAmount = Number(value ?? 0);
                  setAutopaySettings({ ...autopaySettings!, fixedAmount });
                }}
              />
            </div>
            <p className="mt-1 text-xs text-gray-500">
              {isFixedAmountFeeDataLoading
                ? "Calculating fee"
                : amount != null &&
                  amount > 0 &&
                  fixedAmountFeeData?.calculatedFeeAmount
                ? `${t("general.processing-fee")}: ${numeral(
                    fixedAmountFeeData.calculatedFeeAmount
                  ).format(currencyFormat)}`
                : null}
            </p>

            <p className="mt-1 text-gray-500">
              {isFixedPaymentAvailable ? "Fixed Amount" : "Recurring Amount"}
            </p>
          </div>
          {!isFullStatementBalance && (
            <FontAwesomeIcon icon="check" className="text-red-400" />
          )}
        </label>
      )}
      {isMaxLimitError && (
        <div className="my-4">
          <Alert
            variant="danger"
            title={null}
            message="You have reached your maximum auto payment limit."
          />
        </div>
      )}
      {isDwollaLimitError && (
        <div className="my-4">
          <Alert
            variant="danger"
            title={null}
            message="ACH transactions are limited to $10,000 per week."
          />
        </div>
      )}
      {error && (
        <div className="my-4">
          <Alert
            variant="danger"
            title={null}
            message="Please enter a valid amount."
          />
        </div>
      )}
    </div>
  );
}

export default PaymentAmount;
