import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import { BaseLivlyApiResponse } from "../../types/Base";
import {
  LeaseUtilityProvider,
  OnboardingStatusEnum,
  UtilityTypeEnum,
} from "../../types/User";
import { BASE_API_URL } from "../../utils/constants";
import { useGetStatusTypeDetails, usePostService } from "./landing";
import { motion } from "framer-motion";
import { Button, ButtonWrapper } from "../../components/Button";
import { Spinner } from "../../components/Spinner";
import { useQuery } from "@tanstack/react-query";
import useLivlyUser from "../../context/UserProvider";
import { isNativeAppVersion, returnToNative } from "@/utils/nativeAppHelpers";

const getLeaseUtilityProviders = async (leaseId: number, userId: number) => {
  const { data } = await axios.get<
    BaseLivlyApiResponse<LeaseUtilityProvider[]>
  >(`${BASE_API_URL}/livly/users/${userId}/leaseUtilityProviders/${leaseId}`);

  return data.Data;
};

export const utilitiesQuery = (leaseId: number, userId: number) => ({
  queryKey: ["lease-utilities", leaseId],
  queryFn: async () => getLeaseUtilityProviders(leaseId, userId),
});

export default function ChecklistUtilitiesPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{ leaseId: string; userId: string }>();
  const { user } = useLivlyUser();
  const details = useGetStatusTypeDetails(OnboardingStatusEnum.utilities, user);
  const {
    updateOnboardingStatus,
    skipOnboardingStep,
    isLoading: isSaving,
  } = usePostService(user.userId, params.leaseId!, details.onboardingStatus);
  const { data, isLoading } = useQuery({
    ...utilitiesQuery(user.propertyUnitLeaseId, user.userId),
  });

  const onSkip = async () => {
    try {
      await skipOnboardingStep();

      if (isNativeAppVersion()) {
        returnToNative();
        return;
      }

      navigate("..");
    } catch (e) {
      const error = e as { data?: { Message: string } };
      alert(error ?? "There was an error skipping utilities");
    }
  };

  const onSubmit = async () => {
    try {
      await updateOnboardingStatus({
        ...details.onboardingStatus,
        isCompleted: true,
      });

      if (isNativeAppVersion()) {
        returnToNative();
        return;
      }

      navigate("..");
    } catch (e) {
      const error = e as { data?: { Message: string } };
      alert(error ?? "There was an error completing utilities");
    }
  };

  const filteredUtilities =
    data?.filter((lu) => lu.utilityProviders.length > 0) ?? [];

  return (
    <Layout title="Utilities" back={{ label: "Move-in Checklist", to: ".." }}>
      {isLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <>
          <div className="max-w-xl pb-24 mx-auto">
            <div className="flex justify-end">
              <Button
                color="default"
                onClick={onSkip}
                className="flex items-center gap-2 md:w-auto"
                disabled={isSaving}
              >
                {isSaving && <Spinner />}
                Skip
              </Button>
            </div>
            <div className="mt-4">
              <FontAwesomeIcon
                icon={details.icon}
                className={classNames("text-3xl text-red-300")}
              />
            </div>
            <h2 className={classNames("font-medium text-lg mt-2")}>
              {details.title}
            </h2>
            <p className={classNames("text-sm")}>
              {t("utilities.landing-description")}
            </p>
            <ul className="mt-6">
              {filteredUtilities.map((lu) => {
                const { icon } = getLeaseUtilityDetails(lu.utilityTypeId);

                return (
                  <li
                    key={lu.utilityTypeId}
                    className="relative flex-1 p-5 mb-4 transition duration-200 bg-white border border-gray-200 rounded-lg hover:bg-gray-50"
                  >
                    <motion.span layoutId={`utility-icon-${lu.utilityTypeId}`}>
                      <FontAwesomeIcon
                        icon={icon}
                        className={classNames("text-3xl text-red-300")}
                      />
                    </motion.span>
                    <motion.h2
                      layout="position"
                      layoutId={`utility-type-${lu.utilityTypeId}`}
                      className={classNames("font-bold text-lg")}
                    >
                      {lu.utilityType}
                    </motion.h2>
                    <div className="mt-4">
                      {lu.isComplete ? (
                        <img
                          src={lu.logoUrl!}
                          alt={lu.name!}
                          className="w-36"
                        />
                      ) : (
                        <p>{t("utilities.setup-required")}</p>
                      )}
                    </div>
                    <motion.div
                      layoutId={`utility-status-${lu.utilityTypeId}`}
                      className={classNames(
                        "absolute top-0 right-0 px-4 py-2 text-sm font-medium rounded-bl-lg rounded-tr-lg m-1",
                        {
                          "bg-green-100 text-green-700": lu.isComplete,
                          "bg-gray-100 text-gray-700": !lu.isComplete,
                        }
                      )}
                    >
                      {lu.isComplete ? "complete" : "incomplete"}
                    </motion.div>
                    <Link to={lu.utilityTypeId.toString()}>
                      <span className="absolute inset-0" />
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <ButtonWrapper>
            <Button
              color="secondary"
              className="flex items-center w-full gap-2 md:w-auto"
              onClick={onSubmit}
              disabled={
                filteredUtilities.some((lu) => !lu.isComplete) || isSaving
              }
            >
              {isSaving && <Spinner />}
              Done
            </Button>
          </ButtonWrapper>
        </>
      )}
    </Layout>
  );
}

export type StatusTypeDetails = {
  icon: IconProp;
};

export function getLeaseUtilityDetails(
  utilityTypeId: UtilityTypeEnum
): StatusTypeDetails {
  switch (utilityTypeId) {
    case UtilityTypeEnum.Cable:
      return {
        icon: ["far", "tv-retro"],
      };
    case UtilityTypeEnum.Electric:
      return {
        icon: "plug",
      };
    case UtilityTypeEnum.Gas:
      return {
        icon: "burn",
      };
    case UtilityTypeEnum.Internet:
      return {
        icon: "wifi",
      };
    case UtilityTypeEnum.Water:
      return {
        icon: "faucet-drip",
      };
  }
}
