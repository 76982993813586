import { Popover } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dataTransferItemsHaveFiles, FeedPost } from "@/utils/commfeed";
import useLivlyUser from "@/context/UserProvider";
import { useReducer, useRef } from "react";
import StreamTextarea from "./StreamTextArea";
import EmojiPicker from "./emoji-picker";
import { faSmile } from "@fortawesome/free-solid-svg-icons";
import Alert from "@/components/Alert";
import Avatar from "@/components/Avatar";

interface PostCommentState {
  isLoading: boolean;
  isError: boolean;
  isFocused: boolean;
  text: string;
  key: number;
}

type PostCommentAction =
  | { type: "toggle_focused"; isFocused: boolean }
  | { type: "set_text"; text: string }
  | { type: "set_key"; key: number }
  | { type: "set_loading"; isLoading: boolean }
  | { type: "set_error"; isError: boolean }
  | { type: "reset" };

function postCommentReducer(
  state: PostCommentState,
  action: PostCommentAction
): PostCommentState {
  switch (action.type) {
    case "toggle_focused":
      return { ...state, isFocused: action.isFocused };
    case "set_text":
      return { ...state, text: action.text };
    case "set_key":
      return { ...state, key: action.key };
    case "set_loading":
      return { ...state, isLoading: action.isLoading };
    case "set_error":
      return { ...state, isError: action.isError };
    case "reset":
      return { ...state, ...initialPostCommentState };
    default:
      return state;
  }
}

const initialPostCommentState: PostCommentState = {
  isLoading: false,
  isError: false,
  isFocused: false,
  text: "",
  key: 0
};

function PostCommentForm({
  postId,
  commentBoxRef,
  onAddPostComment
}: {
  propertyId: string;
  postId: string | number;
  commentBoxRef: React.MutableRefObject<HTMLTextAreaElement | null>;
  onAddPostComment: (
    propertyId: string,
    postId: string,
    comment: string
  ) => Promise<FeedPost>;
}) {
  const { user } = useLivlyUser();

  const [state, dispatch] = useReducer(
    postCommentReducer,
    initialPostCommentState
  );
  const commentFormContainerRef = useRef(null);

  const insertText = async (insertedText: string) => {
    let newCursorPosition;
    const textareaElement = commentBoxRef.current;

    if (!textareaElement) {
      dispatch({ type: "set_text", text: `${state.text}${insertedText}` });
    } else {
      textareaElement.focus();
      const { selectionStart, selectionEnd } = textareaElement;
      newCursorPosition = selectionStart + insertedText.length;
      dispatch({
        type: "set_text",
        text:
          state.text.slice(0, selectionStart) +
          insertedText +
          state.text.slice(selectionEnd)
      });
    }

    if (!textareaElement || newCursorPosition == null) {
      return;
    }
    textareaElement.selectionStart = newCursorPosition;
    textareaElement.selectionEnd = newCursorPosition;
  };

  const onOpenSelectEmoji = () => {
    const textareaElement = commentBoxRef.current;
    if (textareaElement) {
      textareaElement.focus();
    }
  };

  const onSelectEmoji = (emoji: any) => insertText(emoji.native);

  const onChangeTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value || event.currentTarget.value;
    if (text !== null) {
      dispatch({ type: "set_text", text });
    }
  };

  const onPasteToTextarea = async (
    event: React.ClipboardEvent<HTMLTextAreaElement>
  ) => {
    const { items: itemsList } = event.clipboardData;
    const items = [...itemsList];
    if (!dataTransferItemsHaveFiles(items)) {
      return;
    }

    event.preventDefault();
    let plainTextPromise;
    for (const item of items) {
      if (item.kind === "string" && item.type === "text/plain") {
        plainTextPromise = new Promise<string>((resolve) => {
          item.getAsString((s: string) => {
            resolve(s);
          });
        });
        break;
      }
    }

    if (plainTextPromise) {
      const s = await plainTextPromise;
      await insertText(s);
    }
  };

  const onSubmitClick = async () => {
    try {
      dispatch({ type: "set_loading", isLoading: true });
      dispatch({ type: "set_error", isError: false });
      await onAddPostComment(
        String(user.propertyUnitLeaseId),
        postId.toString(),
        state.text
      );
      dispatch({ type: "reset" });
    } catch (e) {
      dispatch({ type: "set_error", isError: true });
    } finally {
      dispatch({ type: "set_loading", isLoading: false });
    }
  };

  return (
    <div
      className="min-w-[300px] overflow-visible pt-2 pb-1"
      ref={commentFormContainerRef}
    >
      <div className="flex flex-row items-center">
        <div className="mr-3">
          <Avatar name={user.fullName} src={user?.avatarURI} size="sm" />
        </div>
        <div className="border border-gray-200 bg-gray-100 px-1 py-1 rounded-3xl flex flex-row items-end min-h-10  flex-grow">
          <StreamTextarea
            value={state.text}
            className="focus:outline-none focus-visible:outline-none focus:ring-transparent text-sm leading-6 box-border w-full bg-transparent border-none  outline-none resize-none"
            placeholder={"Add a comment ..."}
            innerRef={commentBoxRef}
            onChange={onChangeTextarea}
            onPaste={onPasteToTextarea}
            onFocus={() =>
              dispatch({ type: "toggle_focused", isFocused: true })
            }
            onBlur={() =>
              dispatch({ type: "toggle_focused", isFocused: false })
            }
            rows={1}
            key={state.key}
          />
          <div className="w-8 mr-4">
            <EmojiPicker
              onSelect={onSelectEmoji}
              onOpen={onOpenSelectEmoji}
              disabled={state.isLoading}
              className="m-1 p-1 px-2 mr-[-6px] rounded-full min-w-0 bg-transparent hover:bg-gray-200"
            >
              <FontAwesomeIcon
                icon={faSmile}
                color="#6B7280"
                className="cursor-pointer tetx-sm"
              />
            </EmojiPicker>
          </div>
        </div>
      </div>
      {(state.isFocused || !!state.text.trim().length) && (
        <div className="flex flex-row items-center mt-1.5 ml-[60px] gap-4">
          <button
            onClick={onSubmitClick}
            disabled={state.isLoading || !state.text.trim().length}
            className="h-9 px-4 py-1.5 bg-blue-500 text-white rounded-md disabled:bg-gray-300"
          >
            Post
          </button>
          {state.isLoading && (
            <div>
              <svg
                className="animate-spin h-4 w-4 text-blue-500"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="4"
                  d="M4 12a8 8 0 0116 0"
                ></path>
              </svg>
            </div>
          )}
        </div>
      )}
      {state.isError && (
        <div className="mt-2">
          <Alert
            variant="danger"
            message="Unable to post your comment. Try again."
          />
        </div>
      )}
    </div>
  );
}

export { PostCommentForm };
