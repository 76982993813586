import { Outlet, useNavigate, useParams } from "react-router-dom";
import { Ticket } from "../../types/Maintenance";
import ViewTicketForm from "../../components/ViewTicketForm";
import { useEffect } from "react";
import { trackViewTicketDetails } from "../../utils/analytics";
import { BaseLivlyApiResponse } from "../../types/Base";
import axios from "axios";
import { BASE_API_URL } from "../../utils/constants";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "../../components/Spinner";
import Alert from "../../components/Alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

async function getTicket(ticketId: string) {
  const result = await axios.get<BaseLivlyApiResponse<Ticket>>(
    `${BASE_API_URL}/livly/maintenanceTicketsResidential/${ticketId}`
  );
  const ticket = result.data.Data;

  return ticket;
}

export const maintenanceTicketQuery = (ticketId: string) => ({
  queryKey: ["maintenance", "ticket", ticketId],
  queryFn: async () => getTicket(ticketId),
});

export default function ViewTicketMobilePage() {
  const navigate = useNavigate();
  const params = useParams<{
    leaseId: string;
    ticketId: string;
    propertyId: string;
  }>();

  const { data, isLoading } = useQuery({
    ...maintenanceTicketQuery(params.ticketId!),
  });

  return (
    <div>
      {isLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : data === undefined ? (
        <Alert variant="danger" message="There was an issue loading ticket" />
      ) : (
        <div>
          <div className="relative flex items-start justify-center mt-6">
            <div className="absolute flex items-center ml-3 -left-2 h-7  px-4">
              <button
                type="button"
                className="w-6 h-6 text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => navigate(-1)}
              >
                <span className="sr-only">Close panel</span>
                <FontAwesomeIcon icon="arrow-left" className="text-gray-900" />
              </button>
            </div>
            <h2 className="text-lg font-medium text-gray-900">
              {`Ticket Details ${data ? ` - ${data.category.name}` : ""}`}
            </h2>
          </div>
          <div className="px-4 mt-6 sm:px-6">
            <ViewTicketForm ticket={data} />
          </div>
        </div>
      )}
      <Outlet />
    </div>
  );
}
