import { Navigate } from "react-router-dom";
import { EXPIRES_IN_KEY } from "@/utils/localStorage";
import { isIOS } from "react-device-detect";

const RequireAuth: React.FC<
  { redirectTo?: string } & JSX.IntrinsicElements["div"]
> = ({ redirectTo, children }) => {
  const isAuthed = getIsAuthed();

  if (isAuthed) {
    return <>{children}</>;
  }

  return <Navigate to={redirectTo ?? "/login"} />;
};

export default RequireAuth;

const getIsAuthed = () => {
  const isFromNativeApp = localStorage.getItem("mobile");

  if (isFromNativeApp) {
    return true;
  }

  const expiresInString = localStorage.getItem(EXPIRES_IN_KEY);
  if (!expiresInString) {
    return false;
  }

  const expiresAt = JSON.parse(expiresInString);

  return new Date().getTime() < expiresAt;
};
