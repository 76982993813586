import { Outlet, useLocation, useNavigation } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import moment from "moment-timezone";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserMenu, { MobileUserMenu } from "../components/UserMenu";
import MainMenu from "../components/MainMenu";
import {
  BuildingLeaseMenu,
  MobileBuildingLeaseMenu,
} from "../components/BuildingLeaseMenu";
import TermsOfService from "../components/TermsOfService";
import { Tasks, useLeaseTasks } from "../components/Tasks";
import { Spinner } from "../components/Spinner";
import { useSpinDelay } from "spin-delay";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { bookingsQuery } from "./bookings";
import { fitnessQuery } from "./fitness";
import { eventsQuery } from "./events";
import { amenitySpacesQuery } from "./amenities";
import { BuildingServiceType, ServiceTypeEnum } from "../types/Building";
import useLivlyUser from "../context/UserProvider";

const prefetchTodos = async (
  queryClient: QueryClient,
  leaseId: number,
  propertyId: number,
  buildingServices: BuildingServiceType[]
) => {
  const isFitnessEnabled = buildingServices.find(
    (s) => s.serviceType === ServiceTypeEnum.Fitness
  )?.enabled;
  const isAmentiesEnabled = buildingServices.find(
    (s) => s.serviceType === ServiceTypeEnum.Amenities
  )?.enabled;
  const isEventsEnabled = buildingServices.find(
    (s) => s.serviceType === ServiceTypeEnum.Events
  )?.enabled;

  if (isEventsEnabled || isAmentiesEnabled || isFitnessEnabled) {
    queryClient.prefetchQuery({
      ...bookingsQuery(leaseId),
    });
  }

  if (isFitnessEnabled) {
    queryClient.prefetchQuery({
      ...fitnessQuery(propertyId, leaseId),
    });
  }

  if (isEventsEnabled) {
    queryClient.prefetchQuery({
      ...eventsQuery(propertyId, leaseId),
    });
  }

  if (isAmentiesEnabled) {
    queryClient.prefetchQuery({
      ...amenitySpacesQuery(propertyId, leaseId),
    });
  }
};

export default function LeasePage() {
  const { user } = useLivlyUser();
  const location = useLocation() as { state?: { hideNavbar: boolean } };
  const queryClient = useQueryClient();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const buildingServices = user?.building?.serviceTypes ?? [];
  const { data = [] } = useLeaseTasks(user.propertyUnitLeaseId);
  const isNativeDevice = localStorage.getItem("mobile") === "true";

  useEffect(() => {
    prefetchTodos(
      queryClient,
      user.propertyUnitLeaseId,
      user.propertyId,
      buildingServices
    );
  }, [user.propertyUnitLeaseId, buildingServices]);

  const navigation = useNavigation();
  const isNavigating = navigation.state !== "idle";
  const showSpinner = useSpinDelay(isNavigating, {
    delay: 500,
    minDuration: 200,
  });

  useEffect(() => {
    const scrollingElement = window.document.scrollingElement;

    if (!scrollingElement) {
      return;
    }

    scrollingElement.scrollTo({ top: 0 });
    setSidebarOpen(false);
  }, [location]);

  useEffect(() => {
    if (user.building?.timezone) {
      moment.tz.setDefault(user.building.timezone);
    }
  }, [user]);

  return (
    <>
      <div className="relative">
        {showSpinner && (
          <div className="fixed top-0 right-0 z-50 p-4 pointer-events-none">
            <Spinner color="livly" size="lg" />
          </div>
        )}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex flex-col flex-1 w-full bg-white">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 pt-2 -mr-12">
                      <button
                        type="button"
                        className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <FontAwesomeIcon icon="times" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                    <MobileBuildingLeaseMenu user={user} />
                    <MainMenu user={user} />
                  </div>
                  <div className="flex flex-shrink-0 p-4 border-t border-gray-200">
                    <MobileUserMenu user={user} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="flex flex-row-reverse">
          <div className="flex flex-col flex-1 overflow-x-hidden md:pl-64">
            {!location.state?.hideNavbar && !isNativeDevice && (
              <div className="sticky top-0 z-10 flex items-center justify-between pt-1 pl-1 bg-gray-100 sm:pl-3 sm:pt-3 md:hidden">
                <button
                  type="button"
                  className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                  onClick={() => setSidebarOpen(true)}
                >
                  <span className="sr-only">Open sidebar</span>
                  <FontAwesomeIcon icon="bars" />
                </button>
                {user.building?.bannerUrl && (
                  <img
                    src={user.building.bannerUrl}
                    alt={user.buildingName}
                    className="w-16 mx-2"
                  />
                )}
              </div>
            )}
            <main className="flex-col flex-1">
              <Outlet />
            </main>
          </div>
          <div className="left-0 hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
            <div className="flex flex-col flex-1 min-h-0 bg-white border-r border-gray-200">
              <div className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
                <BuildingLeaseMenu user={user} />
                <Tasks user={user} tasks={data} />
                <MainMenu user={user} />
              </div>
              <UserMenu user={user} />
            </div>
          </div>
        </div>
      </div>
      <TermsOfService />
    </>
  );
}
