import Layout from "@/components/Layout";
import useLivlyUser from "@/context/UserProvider";
import { BaseLivlyApiResponse } from "@/types/Base";
import { BASE_API_URL } from "@/utils/constants";
import { isValidFormat, isValidSize } from "@/utils/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { ChangeEvent, useRef, useState } from "react";
import { Link } from "react-router-dom";
import ReactCropper from "react-cropper";
import { Modal } from "@/components/Dialog";
import { Button } from "@/components/Button";
import toast from "react-hot-toast";
import { Spinner } from "@/components/Spinner";

export default function SettingsPage() {
  return (
    <Layout title="Settings">
      <UserAvatar />
      <div className="max-w-lg mx-auto mt-6">
        <h5 className="text-xs text-gray-500 uppercase">App Settings</h5>
        <div className="flex flex-col px-2 mt-4 space-y-2 divide-y">
          <div>
            <Link
              to="../notifications"
              className="flex items-center justify-between w-full p-2 duration-150 rounded-md hover:bg-gray-50"
            >
              <span>Notifications</span>
              <FontAwesomeIcon icon="chevron-right" className="text-xs" />
            </Link>
          </div>
          <div className="pt-2">
            <Link
              to="../preferred-name"
              className="flex items-center justify-between w-full p-2 duration-150 rounded-md hover:bg-gray-50"
            >
              <span>Preferred Name</span>
              <FontAwesomeIcon icon="chevron-right" className="text-xs" />
            </Link>
          </div>
        </div>
      </div>
      <div className="max-w-lg mx-auto mt-8">
        <h5 className="text-xs text-gray-500 uppercase">Security & Privacy</h5>
        <div className="flex flex-col px-2 mt-4 space-y-2 divide-y">
          <div>
            <a
              href="https://www.livly.io/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-between w-full p-2 duration-150 rounded-md hover:bg-gray-50"
            >
              <span>Privacy Policy</span>
              <FontAwesomeIcon icon="chevron-right" className="text-xs" />
            </a>
          </div>
          <div className="pt-2">
            <Link
              to="../terms-of-service"
              className="flex items-center justify-between w-full p-2 duration-150 rounded-md hover:bg-gray-50"
            >
              <span>Terms of Service</span>
              <FontAwesomeIcon icon="chevron-right" className="text-xs" />
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
}

const postUserAvatar = async (userId: number, base64Image: string) => {
  const result = await axios.post<BaseLivlyApiResponse<{ avatarURI: string }>>(
    `${BASE_API_URL}/livly/users/me/avatar`,
    {
      userId,
      base64Image: base64Image.replace("data:image/png;base64,", ""),
    }
  );

  return result.data.Data;
};

const usePostAvatar = (userId: number) =>
  useMutation({
    mutationFn: (base64Image: string) => postUserAvatar(userId, base64Image),
  });

function UserAvatar() {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { user, updateUser } = useLivlyUser();
  const addAvatarMutation = usePostAvatar(user.userId);
  const editor = useRef<HTMLImageElement | null>(null);

  const [cropper, setCropper] = useState<Cropper | null>(null);
  const [image, setImage] = useState<string | null>(null);

  const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    let hasError = false;
    const files = event.target.files;

    if (files && files.length) {
      const file = files[0] as File;

      if (!isValidSize(file.size, 5)) {
        hasError = true;
        toast.error("File must be less than 5mb");
      }

      if (!isValidFormat(file.name.split(".").pop(), ["png", "jpg", "jpeg"])) {
        hasError = true;
        toast.error("File must be in a valid format");
      }

      if (hasError) {
        return;
      }

      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const onSubmit = async () => {
    try {
      if (cropper === null) {
        return;
      }

      const image = cropper.getCroppedCanvas().toDataURL();

      const result = await addAvatarMutation.mutateAsync(image);
      updateUser({ ...user, avatarURI: result.avatarURI });
      setImage(null);
    } catch (error) {
      const mutationError = error as { data?: { Message: string } };
      const errorMessage = mutationError?.data?.Message;
      toast.error(errorMessage ?? "There was an error uploading avatar.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center mt-8 space-y-4">
      <label className="relative cursor-pointer">
        {user.avatarURI ? (
          <img className="w-24 h-24 rounded-full" src={user.avatarURI} />
        ) : (
          <div className="w-24 h-24 bg-gray-200 rounded-full flex justify-center items-center font-bold">
            {user.firstName[0]}
            {user.lastName[0]}
          </div>
        )}
        <span className="absolute right-0 flex items-center justify-center w-6 h-6 p-1 bg-red-300 rounded-full bottom-1">
          <FontAwesomeIcon icon="camera" className="text-xs text-white" />
        </span>
        <input
          type="file"
          className="hidden"
          accept="image/*,image/heif,image/heic"
          onChange={onFileInputChange}
          ref={inputRef}
        />
      </label>
      {image ? (
        <Modal
          open
          onClose={() => {
            setImage(null);
          }}
        >
          <div className="flex flex-col items-center justify-center p-8">
            <div className="max-w-lg mt-4">
              <ReactCropper
                ref={editor}
                aspectRatio={1}
                src={image}
                guides={false}
                dragMode="move"
                background={false}
                viewMode={1}
                responsive
                center={false}
                cropBoxMovable={false}
                cropBoxResizable={false}
                onInitialized={(c) => {
                  setCropper(c);
                }}
              />
            </div>

            <div className="flex gap-1 mt-2">
              <span>
                <button
                  className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100 disabled:hover:bg-white disabled:cursor-not-allowed group"
                  onClick={() => cropper?.zoom(-0.1)}
                >
                  <FontAwesomeIcon
                    icon="minus"
                    className="group-disabled:text-gray-300"
                  />
                </button>
              </span>
              <button
                className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100 disabled:hover:bg-white disabled:cursor-not-allowed group"
                onClick={() => cropper?.zoom(0.1)}
              >
                <FontAwesomeIcon
                  icon="plus"
                  className="group-disabled:text-gray-300"
                />
              </button>
            </div>
          </div>
          <div className="flex justify-end gap-4 mt-4">
            <Button
              color="default"
              onClick={() => {
                if (inputRef.current) {
                  inputRef.current.value = "";
                }
                setImage(null);
              }}
              disabled={addAvatarMutation.isLoading}
            >
              reset
            </Button>
            <Button onClick={onSubmit} disabled={addAvatarMutation.isLoading}>
              {addAvatarMutation.isLoading ? <Spinner /> : null}
              Update
            </Button>
          </div>
        </Modal>
      ) : null}
      <p className="font-bold">
        {user.firstName} {user.lastName}
      </p>
    </div>
  );
}
