import React, { useState } from "react";

const RadioButtonComponent = ({
  label,
  checked,
  residentPollQuestionOptionId,
  onChange,
  disabled
}: {
  label: string;
  checked: boolean;
  residentPollQuestionOptionId: number | null;
  onChange: (value: number | null) => void;
  disabled?: boolean;
}) => {
  const handleCheckboxChange = (
    residentPollQuestionOptionId: number | null
  ) => {
    onChange(residentPollQuestionOptionId);
  };

  return (
    <div className="my-4">
      <input
        type="radio"
        disabled={disabled}
        id={`radio_${residentPollQuestionOptionId}`}
        checked={checked}
        onChange={() => handleCheckboxChange(residentPollQuestionOptionId)}
        className={`mr-4 w-5 h-5 rounded-full focus:shadow-none focus:ring-offset-0 focus:ring-0 ${
          disabled && !checked ? "bg-gray-200" : ""
        }`}
      />
      <label htmlFor={`radio-${residentPollQuestionOptionId}`}>{label}</label>
    </div>
  );
};

export default RadioButtonComponent;
