import { ImageUploadButton } from "react-file-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type Props = {
  handleFiles: (files: File[]) => void;
  multiple?: boolean;
  disabled?: boolean;
};

const UploadButton = ({
  handleFiles,
  multiple = false,
  disabled = false
}: Props) => {
  return (
    <ImageUploadButton
      multiple={multiple}
      disabled={disabled}
      handleFiles={handleFiles}
      resetOnChange={true}
    >
      <span
        className={`flex items-center justify-center h-8 px-4 rounded-lg text-sm font-semibold border-none
          ${
            disabled
              ? "cursor-not-allowed bg-gray-400"
              : "bg-green-100 text-green-600 hover:bg-green-200"
          }`}
      >
        <FontAwesomeIcon icon={"image"} className="mr-2 text-green-600" />
        Photo
      </span>
    </ImageUploadButton>
  );
};

export default UploadButton;
