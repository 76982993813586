import moment, { Moment } from "moment-timezone";

const convertUTCToLocalTime = (
  date: Date | string,
  timezone?: string | null
): Moment => {
  if (timezone) {
    return moment.utc(date).tz(timezone);
  }
  return moment.utc(date).local();
};

export function humanizeTimestamp(timestamp: string | number): string {
  const time = moment.utc(timestamp);
  const now = moment();
  return moment.min(time, now).from(now, true);
}

export default convertUTCToLocalTime;
