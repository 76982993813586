import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { Button } from "../../components/Button";
import { ConfirmationModal } from "../../components/Dialog";
import Layout from "../../components/Layout";
import { CartContext } from "../../context/CartProvider";
import { BaseLivlyApiResponse } from "../../types/Base";
import { CartItem, CartItemEnum } from "../../types/Cart";
import { RentBalanceTypeEnum, RentHistoryItem } from "../../types/Rent";
import { PaymentAccount } from "../../types/User";
import { BASE_API_URL } from "../../utils/constants";
import numeral from "numeral";
import CountDownTimer from "../../components/CountDownTimer";
import {
  trackChangeRentPaymentAccount,
  trackMakePayment,
  trackViewCheckoutPage,
} from "../../utils/analytics";
import Alert from "../../components/Alert";
import { RentHistoryList } from "../../components/Rent/RentHistoryList";
import { SelectPaymentAccount } from "../../components/SelectPaymentAccount";
import { Spinner } from "../../components/Spinner";
import CurrencyInput from "react-currency-input-field";
import useLivlyUser from "../../context/UserProvider";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

async function getRentHistoryItems(leaseId: number) {
  const result = await axios.get<BaseLivlyApiResponse<RentHistoryItem[]>>(
    `${BASE_API_URL}/livly/payment/${leaseId}/rentHistory?rentPaymentsOnly=true`
  );

  return result.data.Data;
}

const rentHistoryQuery = (leaseId: number) => ({
  queryKey: ["rent-history", leaseId],
  queryFn: async () => getRentHistoryItems(leaseId),
});

const postPayment = async (
  userId: string,
  leaseId: string,
  items: CartItem[]
) => {
  const result = await axios.post<BaseLivlyApiResponse<CartItem[]>>(
    `${BASE_API_URL}/livly/checkout/v2/${userId}/makepayment/${leaseId}`,
    items
  );

  return result.data;
};

export default function ChecklistCheckoutPage() {
  const navigate = useNavigate();
  const params = useParams<{ leaseId: string; userId: string }>();
  const { user } = useLivlyUser();
  const {
    cartItems,
    rentSettings,
    paymentAccounts,
    setCartItem,
    isServiceFeeLoading,
  } = useOutletContext<CartContext>();
  const [error, setError] = useState<string | null>(null);

  const { data, isLoading } = useQuery({
    ...rentHistoryQuery(user.propertyUnitLeaseId),
  });
  const activePaymentAccounts = paymentAccounts.filter((pa) => pa.isActive);

  const rentCartItem = cartItems.find((ci) => ci.type === CartItemEnum.RENT);

  const total = numeral(0).add(rentCartItem?.feeData.totalAmount).value()!;

  const { mutateAsync, isLoading: isSaving } = useMutation(
    (items: CartItem[]) => postPayment(params.userId!, params.leaseId!, items)
  );

  let validCartItems = cartItems
    .filter((ci) => ci.type === CartItemEnum.RENT)
    .filter((cartItem) => cartItem.cost > 0 || cartItem.paymentAmount > 0);

  const onSubmit = async () => {
    //due to free ability to not put any $$$ towards rent
    //filter out rent cart items with $0 towards paymentAmount

    //just check if paymentAmount is greater than 0 and cost is greater than 0

    try {
      const result = await mutateAsync(validCartItems);
      trackMakePayment(result.Data, activePaymentAccounts);

      const rentResult = result.Data.find((r) => r.type === 1);

      if (rentResult) {
        navigate("../cart/confirmation", {
          state: {
            amount: rentResult.feeData?.totalAmount,
            transactionId: rentResult.transactionId,
          },
        });
      }
    } catch (error) {
      const mutationError = error as {
        data?: { Errors: Record<number, string>; Message: string };
      };
      const errors: string[] =
        Object.values(mutationError.data?.Errors ?? {}) ?? [];
      setError(errors[0]);
    }
  };

  useEffect(() => {
    trackViewCheckoutPage(cartItems);
  }, [cartItems]);

  const isPaymentAmountOverMaxPaymentAmountAllowed =
    rentSettings?.maxPaymentAmountAllowed != null &&
    rentCartItem != null &&
    rentCartItem.paymentAmount > rentSettings?.maxPaymentAmountAllowed;

  const isFreeFormRent =
    rentSettings?.rentBalanceType !== RentBalanceTypeEnum.BalanceBased;

  let isSubmitPaymentDisabled = false;
  if (isFreeFormRent) {
    isSubmitPaymentDisabled =
      isServiceFeeLoading ||
      isSaving ||
      validCartItems.length === 0 ||
      isPaymentAmountOverMaxPaymentAmountAllowed ||
      rentCartItem?.paymentAmount === 0;
  } else {
    isSubmitPaymentDisabled =
      isServiceFeeLoading ||
      isSaving ||
      validCartItems.length === 0 ||
      isPaymentAmountOverMaxPaymentAmountAllowed;
  }

  return (
    <Layout title="Checkout" back={{ label: "Cart", to: "../cart" }}>
      {isSaving ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-75">
          <Spinner color="livly" size="xl" />
        </div>
      ) : null}
      <div className=" pb-36">
        {rentCartItem &&
          (isFreeFormRent ? (
            <FreeFormCartItem
              paymentAccounts={activePaymentAccounts}
              rentCartItem={rentCartItem}
              onChangeCartItem={setCartItem}
              isPaymentAmountOverMaxPaymentAmountAllowed={
                isPaymentAmountOverMaxPaymentAmountAllowed
              }
              maxPaymentAmountAllowed={rentSettings?.maxPaymentAmountAllowed}
            />
          ) : (
            <BalanceBasedCartItem
              paymentAccounts={activePaymentAccounts}
              rentCartItem={rentCartItem}
              setCartItem={setCartItem}
            />
          ))}
        {isLoading ? (
          <div className="flex items-center justify-center flex-1 mt-8">
            <Spinner color="livly" size="xl" />
          </div>
        ) : (
          <RentHistoryList rentHistoryItems={data ?? []} />
        )}
      </div>
      <div className="fixed bottom-0 left-0 right-0 flex flex-col items-stretch m-4 bg-white border border-gray-100 rounded-lg shadow-lg md:flex-row md:justify-between md:items-baseline md:left-64 drop-shadow-lg">
        <div className="py-2 ml-0 text-center md:text-left md:ml-4">
          <CountDownTimer
            date={user.leaseMoveInDate}
            timezone={user.building.timezone}
          />
        </div>
        <div className="flex items-center justify-between p-4 border-t border-gray-200 md:border-none">
          <div className="flex flex-col items-baseline mr-4 md:flex-row">
            <p className="text-sm font-light md:mr-2">today's total</p>
            {isServiceFeeLoading ? (
              <div className="h-5 mt-1 bg-gray-200 animate-pulse w-14" />
            ) : (
              <p className="text-xl">${total}</p>
            )}
          </div>
          <Button
            color="primary"
            onClick={onSubmit}
            className="flex items-center gap-2"
            disabled={isSubmitPaymentDisabled}
          >
            {isSaving && <Spinner />}
            Submit Payment
          </Button>
        </div>
      </div>
      <ConfirmationModal
        variant="warning"
        open={error !== null}
        onClose={() => setError(null)}
        onConfirm={() => setError(null)}
        title="Payment Error"
        body={error || ""}
        buttonLabels={{ confirm: "Ok" }}
      />
    </Layout>
  );
}

function BalanceBasedCartItem({
  paymentAccounts,
  rentCartItem,
  setCartItem,
}: {
  paymentAccounts: PaymentAccount[];
  rentCartItem: CartItem;
  setCartItem: (cartItem: CartItem) => void;
}) {
  const { serviceFeeData, isServiceFeeLoading } =
    useOutletContext<CartContext>();
  const onSelectPaymentAccount = (paymentMethodId: number) => {
    trackChangeRentPaymentAccount();
    setCartItem({ ...rentCartItem, paymentMethodId });
  };

  const isFixedAmount =
    rentCartItem.paymentAmount &&
    rentCartItem.paymentAmount !== rentCartItem.cost;
  const remainingBalance = isFixedAmount
    ? rentCartItem.cost - rentCartItem.paymentAmount
    : 0;

  return (
    <div className="relative mb-6 border border-gray-200 rounded-lg">
      <div
        className={classNames("p-4 rounded-tl-lg rounded-tr-lg", {
          "bg-blue-400 text-white": isFixedAmount,
        })}
      >
        <h3>rent</h3>
        <p className="mt-1 text-3xl">
          {isFixedAmount
            ? numeral(rentCartItem.paymentAmount).format("$0,0[.]00")
            : numeral(rentCartItem.cost).format("$0,0[.]00")}
        </p>
        {isServiceFeeLoading ? (
          <div className="h-4 mt-1 bg-gray-200 animate-pulse w-14" />
        ) : isFixedAmount ? (
          <p
            className={classNames(`mt-1 text-xs text-gray-500`, {
              "text-white": isFixedAmount,
            })}
          >
            Fee:{" "}
            {numeral(serviceFeeData?.calculatedFeeAmount).format("$0,0[.]00")}
          </p>
        ) : null}
        {remainingBalance > 0 && (
          <p className="mt-1 text-xs">
            {numeral(remainingBalance).format("$0,0[.]00")} remaining
          </p>
        )}
      </div>
      <div className="py-4 pb-0 mx-4 border-t border-gray-200">
        <SelectPaymentAccount
          selectedPaymentAccountId={rentCartItem.paymentMethodId}
          paymentAccounts={paymentAccounts}
          onSelect={onSelectPaymentAccount}
        />
      </div>
      <div className="py-4 mx-4 border-t border-gray-200">
        <div className="flex items-center justify-between">
          <p>payment amount</p>
          <Link
            to="../cart/checkout-amount"
            className="flex items-center gap-2"
          >
            <p>{rentCartItem.paymentAmount || "full"}</p>
            <FontAwesomeIcon
              icon="chevron-right"
              className="text-sm text-red-400"
            />
          </Link>
        </div>
      </div>
    </div>
  );
}

function FreeFormCartItem({
  paymentAccounts,
  rentCartItem,
  isPaymentAmountOverMaxPaymentAmountAllowed,
  maxPaymentAmountAllowed,
  onChangeCartItem,
}: {
  paymentAccounts: PaymentAccount[];
  rentCartItem: CartItem;
  isPaymentAmountOverMaxPaymentAmountAllowed: boolean;
  maxPaymentAmountAllowed: number | undefined;
  onChangeCartItem: (cartItem: CartItem) => void;
}) {
  const { serviceFeeData, isServiceFeeLoading } =
    useOutletContext<CartContext>();
  const onSelectPaymentAccount = (paymentMethodId: number) => {
    trackChangeRentPaymentAccount();
    onChangeCartItem({ ...rentCartItem, paymentMethodId });
  };

  return (
    <div className="relative p-5 mb-6 border border-gray-200 rounded-lg">
      <h3>rent</h3>
      <div className="relative mt-1">
        <div className="absolute inset-y-0 left-0 flex items-center pl-1 pointer-events-none">
          <span className="text-xl text-gray-500">$</span>
        </div>
        <CurrencyInput
          id="amount"
          name="amount"
          decimalsLimit={2}
          className="w-full pl-6 pr-6 text-3xl bg-white border-none outline-none focus:ring-0"
          placeholder="0.00"
          defaultValue={rentCartItem.paymentAmount}
          allowNegativeValue={false}
          onValueChange={(value) =>
            onChangeCartItem({
              ...rentCartItem,
              paymentAmount: Number(value),
            })
          }
          aria-describedby="price-currency"
        />
      </div>
      {isServiceFeeLoading ? (
        <div className="h-4 mt-1 bg-gray-200 animate-pulse w-14" />
      ) : rentCartItem.paymentAmount === 0 ? (
        <p className="mt-1 text-xs text-gray-500" id="email-description">
          Enter Amount
        </p>
      ) : (
        <p className="mt-1 text-xs text-gray-500" id="email-description">
          Fee:{" "}
          {numeral(serviceFeeData?.calculatedFeeAmount).format("$0,0[.]00")}
        </p>
      )}
      {isPaymentAmountOverMaxPaymentAmountAllowed && (
        <div className="mt-2">
          <Alert
            variant="danger"
            message={`The amount you entered is too high. Please enter an amount lower than $${
              maxPaymentAmountAllowed ?? 0
            }`}
          />
        </div>
      )}
      <div className="pt-4 mt-4 border-t border-gray-200">
        <SelectPaymentAccount
          selectedPaymentAccountId={rentCartItem.paymentMethodId}
          paymentAccounts={paymentAccounts}
          onSelect={onSelectPaymentAccount}
        />
      </div>
    </div>
  );
}
