import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import {
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Button } from "../components/Button";
import { Modal } from "../components/Dialog";
import Layout from "../components/Layout";
import { Spinner } from "../components/Spinner";
import { BaseLivlyApiResponse } from "../types/Base";
import { BASE_API_URL } from "../utils/constants";
import toLocalTime from "../utils/toLocalTime";

type FitnessActivity = {
  id: number;
  name: string;
  price: number;
  maxParticipants: number;
  location: string;
  startTime: string;
  stopTime: string;
  duration: number;
  imageUrl: string;
  details: string;
  spotsLeft: number;
  property: {
    propertyId: number;
    name: string;
    timezone: string;
    latitude: number;
    longitude: number;
  } | null;
  provider: {
    userId: number;
    firstName: string;
    lastName: string;
    avatarUrl: string;
    dateJoined: string;
    servicesOffered: string[];
    certificates: string[];
    yearsOfExperience: string;
    aboutMe: string;
    funFact: string;
    socialMedia: {
      type: "Instagram" | "TikTok";
      username: string;
    }[];
  } | null;
  reply: {
    userId: number;
    isAttending: boolean;
    attendingCount: number;
  } | null;
};

async function getUpcomingFitnessEvent(sessionId: string) {
  const { data } = await axios.get<BaseLivlyApiResponse<FitnessActivity>>(
    `${BASE_API_URL}/resident/fitness/${sessionId}`
  );

  return data.Data;
}

const fitnessDetailsQuery = (sessionId: string) => ({
  queryKey: ["fitness", "details", sessionId],
  queryFn: async () => getUpcomingFitnessEvent(sessionId),
});

export async function replyToFitnessEvent(
  sessionId: string,
  isAttending: boolean
) {
  const { data } = await axios.patch<BaseLivlyApiResponse<FitnessActivity>>(
    `${BASE_API_URL}/resident/fitness/${sessionId}/reply`,
    { isAttending }
  );

  return data.Data;
}

function FitnessActivityDetailsPage() {
  const queryClient = useQueryClient();
  const params = useParams<{
    id: string;
    leaseId: string;
    propertyId: string;
  }>();
  const history = useNavigate();
  const sessionId = params.id;

  const { data, isLoading } = useQuery({
    ...fitnessDetailsQuery(params.id!),
  });

  const { mutate, isLoading: isSaving } = useMutation(
    ({ sessionId, isAttending }: { sessionId: string; isAttending: boolean }) =>
      replyToFitnessEvent(sessionId, isAttending)
  );

  const onReply = async () => {
    mutate(
      { sessionId: sessionId!, isAttending: !data?.reply?.isAttending },
      {
        onSuccess: (result) => {
          if (result.reply?.isAttending) {
            //TODO change this
            history(`/lease/${params.leaseId}/bookings`);
          }
          queryClient.invalidateQueries(["fitness"]);
          queryClient.invalidateQueries(["bookings"]);
        },
      }
    );
  };

  return (
    <Layout
      back={{
        to: `/lease/${params.leaseId}/fitness/${params.propertyId!}`,
        label: "Fitness",
      }}
    >
      {isLoading || data === undefined ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <div>
          <p className="mb-2 text-sm font-light">
            {toLocalTime(data.startTime).format("dddd, MMMM D, YYYY")}
          </p>
          <h1 className="mb-4 text-xl font-bold">{data?.name}</h1>
          {data.provider != null ? (
            <TrainerDetails provider={data.provider} />
          ) : null}
          <div className="flex flex-col-reverse gap-8 mb-8 lg:flex-row">
            <div style={{ flexGrow: 1 }}>
              <div className="flex items-center justify-center flex-1 w-full">
                {data?.imageUrl ? (
                  <img
                    className="w-full block max-h-[450px] object-cover"
                    src={data?.imageUrl}
                    alt={data?.name}
                  />
                ) : (
                  <div className="max-h-[450px]0 min-h-[250px] h-full w-full flex items-center justify-center bg-gray-200">
                    <FontAwesomeIcon
                      icon="image"
                      size="2x"
                      className="text-gray-400"
                    />
                  </div>
                )}
              </div>

              <div className="mb-5">
                <h3 className="mt-4 mb-2 text-lg font-medium">Details</h3>
                <div
                  className="text-sm whitespace-pre-line"
                  dangerouslySetInnerHTML={{
                    __html: data?.details,
                  }}
                />
              </div>
            </div>
            <div className="mt-4 lg:mt-0 lg:w-[300px] lg:flex-shrink-0">
              <div className="sticky t-6">
                <div className="p-4 rounded-lg shadow-md">
                  <div>
                    <div className="flex gap-3 mb-3">
                      <div className="flex-shrink-0 w-4 mt-1">
                        <FontAwesomeIcon icon="clock" />
                      </div>

                      <time className="m-0">
                        {toLocalTime(data.startTime).format(
                          "dddd, MMMM D, YYYY"
                        )}{" "}
                        at {toLocalTime(data.startTime).format("h:mm a")} to{" "}
                        {toLocalTime(data.stopTime).format(
                          "dddd, MMMM D, YYYY"
                        )}{" "}
                        at {toLocalTime(data.stopTime).format("h:mm a")}
                      </time>
                    </div>
                    <div className="flex items-center gap-3">
                      <div className="flex-shrink-0 w-5">
                        <FontAwesomeIcon icon="map-marker-alt" />
                      </div>
                      <p className="m-0">{data?.location}</p>
                    </div>
                  </div>
                  <div className="mt-6">
                    <Button
                      size="small"
                      color={data?.reply?.isAttending ? "primary" : "secondary"}
                      className="w-full"
                      onClick={onReply}
                      disabled={isSaving}
                    >
                      {data?.reply?.isAttending
                        ? "I cannot go"
                        : isSaving
                        ? "Registering..."
                        : "Register"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

function TrainerDetails({ provider }: Pick<FitnessActivity, "provider">) {
  const [isOpen, setIsOpen] = useState(false);

  const renderSocialLink = (media: {
    type: "Instagram" | "TikTok";
    username: string;
  }) => {
    if (media.type === "Instagram") {
      return `https://instagram.com/_u/${media.username}`;
    }
    return `https://www.tiktok.com/${media.username}`;
  };

  const renderTrainerDetails = () => {
    return (
      <>
        <div className="flex flex-col items-center justify-center gap-3 mb-4">
          {provider?.avatarUrl ? (
            <img
              className="inline-block object-cover w-24 h-24 rounded-full"
              src={provider?.avatarUrl}
              alt={provider?.lastName}
            />
          ) : null}
          <div>
            <p>
              {provider?.firstName} {provider?.lastName}
            </p>
          </div>
        </div>
        <div className="pb-6 border-b border-gray-200">
          <ul className="flex justify-center gap-3 p-0 m-0">
            {provider?.socialMedia.map((media) => (
              <a
                key={media.type}
                href={renderSocialLink(media)}
                target="_blank"
                rel="noreferrer"
              >
                {media.type === "Instagram" ? (
                  <FontAwesomeIcon
                    icon={["fab", "instagram"]}
                    className="text-red-300"
                    size="lg"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={["fab", "tiktok"]}
                    className="text-red-300"
                    size="lg"
                  />
                )}
              </a>
            ))}
          </ul>
        </div>
        <div style={{ padding: "24px 0" }}>
          <div style={{ marginBottom: 16 }}>
            <p className="mb-2 font-semibold">About</p>
            <p>{provider?.aboutMe}</p>
          </div>

          <div style={{ marginBottom: 16 }}>
            <p className="mb-2 font-semibold">Years of Experience</p>
            <p>{provider?.yearsOfExperience}</p>
          </div>

          <div>
            <p className="mb-2 font-semibold">Services Offered</p>
            <p>{provider?.servicesOffered.join(", ")}</p>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 12,
          marginBottom: 16,
        }}
      >
        <img
          className="inline-block object-cover w-24 h-24 rounded-full"
          src={provider?.avatarUrl}
          alt={provider?.lastName}
        />
        {provider ? (
          <div>
            <p>Hosted by</p>
            <button
              className="pl-0 bg-transparent outline-none b-none"
              onClick={() => setIsOpen(true)}
            >
              <p className="text-red-400 cursor-pointer">
                {provider?.firstName} {provider?.lastName}
              </p>
            </button>
          </div>
        ) : null}
      </div>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="px-4 pt-5 pb-4 sm:p-6">{renderTrainerDetails()}</div>
      </Modal>
    </>
  );
}

export { FitnessActivityDetailsPage };
