import Avatar from "@/components/Avatar";
import { Button } from "@/components/Button";
import StreamTextarea from "./StreamTextArea";
import useLivlyUser from "@/context/UserProvider";
import {
  CommunityFeedPostRequestModel,
  ImageState,
  initialAddFeedPostState,
  reducer,
  TagsName,
  TagType
} from "@/utils/commfeed";
import React, { useCallback, useReducer, useRef } from "react";
import UploadButton from "./upload-button";
import {
  FileLike,
  ImageDropzone,
  ImagePreviewer,
  ImageUpload
} from "react-file-utils";
import EmojiPicker from "./emoji-picker";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import validator from "validator";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addFeedBuildingPostAndImages } from "./hook/usePost";
import toast from "react-hot-toast";
import useIsReadonlyCommFeed from "./hook/useIsReadonlyCommFeed";

const maxImagesForUpload = 8;

const AddFeedPostForm = () => {
  const { user } = useLivlyUser();
  const isReadOnly = useIsReadonlyCommFeed();
  const queryClient = useQueryClient();
  const textInputRef = useRef<HTMLTextAreaElement>(null);
  const [state, dispatch] = useReducer(reducer, initialAddFeedPostState);

  const addBuildingPostMutation = useMutation({
    mutationFn: ({
      leaseId,
      data
    }: {
      data: CommunityFeedPostRequestModel;
      leaseId: number;
    }) => addFeedBuildingPostAndImages(leaseId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "community-feed",
          user.propertyId,
          user.propertyUnitLeaseId,
          undefined
        ]
      });
    },
    onError: () => {
      toast.error("There was an error adding your post");
    }
  });

  const insertText = async (insertedText: string) => {
    let newCursorPosition;
    const textareaElement = textInputRef.current;

    if (!textareaElement) {
      dispatch({ type: "set_text", text: `${state.text}${insertedText}` });
    } else {
      textareaElement.focus();
      // Insert emoji at previous cursor position]
      const { selectionStart, selectionEnd } = textareaElement;
      newCursorPosition = selectionStart + insertedText.length;
      dispatch({
        type: "set_text",
        text:
          state.text.slice(0, selectionStart) +
          insertedText +
          state.text.slice(selectionEnd)
      });
    }

    if (!textareaElement || newCursorPosition == null) {
      return;
    }
    // Update cursorPosition
    textareaElement.selectionStart = newCursorPosition;
    textareaElement.selectionEnd = newCursorPosition;
  };

  const onChangeTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = event?.target?.value || event?.currentTarget?.value;
    if (text !== null) {
      dispatch({ type: "set_text", text });
    }
  };

  const onOpenSelectEmoji = () => {
    const textareaElement = textInputRef.current;
    if (textareaElement) {
      textareaElement.focus();
    }
  };

  const onSelectEmoji = useCallback(
    (emoji: any) => insertText((emoji as any).native),
    [insertText]
  );

  const uploadNewImage = useCallback(
    async (file: File | ImageState) => {
      const isFile = file instanceof File;
      let newImagesList: ImageState[] = [...state.uploadedImages];
      if (isFile) {
        newImagesList = [
          ...state.uploadedImages,
          {
            id: (file as File).name,
            file: file as File,
            state: "uploading"
          }
        ];
      } else if (!isFile) {
        newImagesList.push({
          id: (file as ImageState).id,
          file: (file as ImageState).file,
          state: "finished",
          previewUri: (file as ImageState).previewUri
        });
      }
      dispatch({ type: "set_images", images: newImagesList });
      if (FileReader) {
        const reader = new FileReader();
        reader.onload = async (event) => {
          if (event.target) {
            newImagesList = newImagesList.map((image) => {
              if (image.id === (file as File).name) {
                return {
                  id: (file as File).name,
                  file: file as File,
                  state: "finished",
                  previewUri: event.target && event.target.result
                };
              }
              return image;
            });
            dispatch({ type: "set_images", images: newImagesList });
          }
        };
        if (isFile) {
          reader.readAsDataURL(file as File);
        }
      }
    },
    [state.uploadedImages]
  );

  const uploadNewFiles = (files: FileLike[]) => {
    if (state.uploadedImages.length >= maxImagesForUpload) {
      return;
    }

    for (const file of files) {
      if (file.type.startsWith("image/")) {
        uploadNewImage(file as File);
      }
    }
  };

  const removeImage = (id: string) => {
    const newImagesList = state.uploadedImages.filter(
      (image) => image.id !== id
    );
    dispatch({ type: "set_images", images: newImagesList });
  };

  const handleSubmit = async () => {
    const trimmedText = state.text.trim();
    if (!trimmedText) {
      return;
    }
    const data: CommunityFeedPostRequestModel = {
      text: trimmedText,
      //list of already uploaded images
      images: state.uploadedImages
        .filter(
          (image) =>
            typeof image.previewUri === "string" &&
            validator.isURL(image.previewUri)
        )
        .map((i) => i.previewUri as string),
      //list of images waiting to be uploaded
      imageFiles: state.uploadedImages
        .filter((img) => img.file.size > 0)
        .map((img) => img.file),
      eventId: undefined,
      disableComments: state.disableComments,
      tag: state.tag,
      externalId: uuidv4()
    };

    const result = await addBuildingPostMutation.mutateAsync({
      leaseId: user.propertyUnitLeaseId!,
      data
    });

    if (result !== false) {
      dispatch({ type: "reset" });
    }
  };

  return (
    <>
      {!isReadOnly ? (
        <div className="p-2 px-4 border  rounded-md shadow ">
          <ImageDropzone
            disabled={state.uploadedImages.length > 0}
            maxNumberOfFiles={maxImagesForUpload}
            accept="image/*"
            //@ts-ignore
            handleFiles={uploadNewFiles}
          >
            <div className="flex items-center mb-4">
              <Avatar
                size="sm"
                src={user.avatarURI}
                name={`${user.firstName} ${user.lastName}`}
              />
              <div className="ml-4">
                <select
                  className="border rounded-md py-1 border-gray-300 text-sm"
                  value={state.tag}
                  onChange={(e) => {
                    dispatch({
                      type: "set_tag",
                      tag: Number(e.target.value) as TagType
                    });
                  }}
                >
                  {Array.from(TagsName).map(([key, value]) => (
                    <option key={key} value={key}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <StreamTextarea
              value={state.text}
              innerRef={textInputRef}
              onChange={onChangeTextarea}
              rows={3}
            />

            {state.uploadedImages.length > 0 && (
              <ImagePreviewer
                imageUploads={state.uploadedImages as ImageUpload[]}
                handleRemove={removeImage}
                multiple={true}
              />
            )}
            <div className="mt-4 mb-2 flex justify justify-between border-t border-tomcat">
              <div className="flex flex-grow mr-2 pt-3  space-x-2">
                <UploadButton
                  handleFiles={uploadNewFiles}
                  disabled={state.uploadedImages.length >= maxImagesForUpload}
                />
                <EmojiPicker
                  onSelect={onSelectEmoji}
                  onOpen={onOpenSelectEmoji}
                  disabled={false}
                />
              </div>
              <div className="pt-3 space-x-2">
                <Button
                  onClick={handleSubmit}
                  disabled={addBuildingPostMutation.isLoading}
                >
                  Share
                </Button>
              </div>
            </div>
          </ImageDropzone>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default AddFeedPostForm;
