import useLivlyUser from "@/context/UserProvider";
import { useInsuranceQuote } from "@/routes/checklist/insurance-quote";
import { Spinner } from "./Spinner";
import { Outlet } from "react-router-dom";
import { AssurantQuote } from "@/types/Insurance";
import ChecklistProvider from "@/context/ChecklistProvider";

export interface InsuranceQuoteContext {
  data: AssurantQuote;
  refetch: () => void;
  isError: boolean;
}

export default function InsuranceQuoteLayout() {
  const { user } = useLivlyUser();

  const { data, isLoading, isError, refetch } = useInsuranceQuote(user);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center flex-1 my-56">
        <Spinner color="livly" size="xl" />
      </div>
    );
  }

  const context: InsuranceQuoteContext = {
    data: data as AssurantQuote,
    refetch,
    isError,
  };

  return <Outlet context={context} />;
}
