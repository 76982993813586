import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Alert from "../components/Alert";
import CommunitySurvey, {
  CommunitySurveyProvider,
  CommunitySurveyQuestionRequest,
  postCommunitySurvey,
} from "../components/CommunitySurveyForm";
import Layout from "../components/Layout";
import { Spinner } from "../components/Spinner";
import { BaseLivlyApiResponse } from "../types/Base";
import { BASE_API_URL } from "../utils/constants";
import useLivlyUser from "../context/UserProvider";

export const getCommunitySurvey = async (
  buildingId: string,
  userId: string,
  leaseId: string
) => {
  const result = await axios.get<
    BaseLivlyApiResponse<CommunitySurveyProvider[]>
  >(
    `${BASE_API_URL}/livly/communitysurvey/property/${buildingId}/user/${userId}/lease/${leaseId}`
  );

  return result.data.Data;
};

export const communitySurveyQuery = (
  leaseId: string,
  buildingId: string,
  userId: string
) => ({
  queryKey: ["community-survey", leaseId, buildingId, userId],
  queryFn: async () => getCommunitySurvey(buildingId, userId, leaseId),
});

export default function CommunitySurveyPage() {
  const queryClient = useQueryClient();
  const { user } = useLivlyUser();
  const params = useParams<{
    leaseId: string;
  }>();
  const [isSurveyComplete, setIsSurveyComplete] = useState(false);

  const { data, isLoading } = useQuery({
    ...communitySurveyQuery(
      params.leaseId!,
      user.propertyId.toString(),
      user.userId.toString()
    ),
  });

  const { mutate, isLoading: isSubmitting } = useMutation(
    (questionsAndAnswers: CommunitySurveyQuestionRequest[]) =>
      postCommunitySurvey(
        user.propertyId,
        user.userId,
        params.leaseId!,
        questionsAndAnswers
      )
  );

  const onSubmit = (data: CommunitySurveyQuestionRequest[]) => {
    mutate(data, {
      onSuccess: () => {
        setIsSurveyComplete(true);
        queryClient.invalidateQueries(["community-survey"]);
      },
    });
  };

  return (
    <Layout title="Community Survey">
      {isLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <>
          {isSurveyComplete ? (
            <div className="max-w-xl mx-auto">
              <Alert
                variant="success"
                message="Thank you for submitting your survey"
              />
            </div>
          ) : (
            <CommunitySurvey
              communitySurvey={data!}
              onSubmit={onSubmit}
              isSubmitting={isSubmitting}
            />
          )}
        </>
      )}
    </Layout>
  );
}
