// This function is intended to truncate text without words breaking
export const truncate = (
  str: string,
  maxLength: number,
  trailingSymbols = "..."
): string => {
  if (str.length <= maxLength) {
    return str;
  }

  const strTruncated = truncateStringBySpecialCharacters(str, maxLength);

  if (strTruncated.length !== 0) {
    return strTruncated + trailingSymbols;
  }

  // Just forcibly truncate the string
  return str.slice(0, maxLength) + trailingSymbols;
};

const truncateStringBySpecialCharacters = (str: string, maxLength: number) => {
  // To match sequences which don't contain letters and digits
  const regexp = /[\W]+/g;

  let matchedItem;
  let index = 0;

  while ((matchedItem = regexp.exec(str)) !== null) {
    if (matchedItem.index > maxLength) {
      break;
    }

    index = matchedItem.index;
  }

  return str.slice(0, index);
};
