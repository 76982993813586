import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Layout from "../../components/Layout";
import { useInsuranceQuote, useUpdateInsuranceQuote } from "./insurance-quote";
import { Spinner } from "../../components/Spinner";
import classNames from "classnames";
import { Button } from "../../components/Button";
import { Address } from "../../types/User";
import { Link } from "react-router-dom";
import useLivlyUser from "../../context/UserProvider";

function InsuranceAddressPage() {
  const { user } = useLivlyUser();
  const { data, isLoading, refetch } = useInsuranceQuote(user);
  const updateQuoteMutation = useUpdateInsuranceQuote(user);

  const onSelectAddress = async (address: Address) => {
    await updateQuoteMutation.mutateAsync({ billingAddress: address });
    refetch();
  };

  return (
    <Layout
      title="Select Address"
      back={{
        label: "Insurance",
        to: `../insurance-quote`,
      }}
    >
      {isLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <div className="max-w-4xl mx-auto">
          <p>
            You will receive information to this address within 10 business
            days. After you move in, we will automatically update this to your
            new address.
          </p>
          <div className="mt-4">
            <div className="space-y-4">
              {user.addresses.map((address) => {
                const isSelected =
                  data?.presentation.billingAddress?.id == address.id;
                const { address1, address2, city, state, zipCode } = address;

                return (
                  <button
                    disabled={updateQuoteMutation.isLoading}
                    key={address.id}
                    className={classNames(
                      { "border-red-400": isSelected },
                      "border rounded-lg p-4 w-full text-left hover:bg-slate-50 duration-300 transition"
                    )}
                    onClick={() => onSelectAddress(address)}
                  >
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-gray-700">{address1}</p>
                        {address2 ? (
                          <p className="text-sm">{address2}</p>
                        ) : null}
                        <p className="text-sm">
                          {city}, {state} {zipCode}
                        </p>
                      </div>
                      {isSelected ? (
                        <FontAwesomeIcon
                          icon="check"
                          className="text-red-400"
                        />
                      ) : null}
                    </div>
                    {!address.isLeaseAddress ? (
                      <Link
                        to={`${address.id}`}
                        className="inline-flex mt-4"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Button color="default">edit</Button>
                      </Link>
                    ) : null}
                  </button>
                );
              })}
            </div>
            <div className="mt-4 flex justify-end">
              <Link to="new">
                <button className="flex gap-2 items-center text-sm underline">
                  <FontAwesomeIcon icon={["fal", "plus-circle"]} />
                  <span>Add new address</span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export { InsuranceAddressPage };
