import { Formik, Field, Form } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { z } from "zod";
import { Button } from "./Button";
import { FormField, textInputClass } from "./Form";
import { Link } from "react-router-dom";
import { VehicleResponse } from "@/routes/checklist/vehicles";

export const VehicleSchema = z.object({
  make: z.string(),
  model: z.string(),
  color: z.string(),
  licensePlateNumber: z.string(),
  year: z.string(),
});

export type VehicleRequest = z.infer<typeof VehicleSchema>;

type Props = {
  onSubmit: (vehicle: VehicleRequest) => Promise<void> | void;
  error: any;
  isLoading: boolean;
  initialVehicle?: VehicleResponse;
};

export default function VehicleForm({
  onSubmit,
  error,
  isLoading,
  initialVehicle,
}: Props) {
  let initialValues: VehicleRequest = {
    year: "",
    make: "",
    model: "",
    licensePlateNumber: "",
    color: "",
  };

  if (initialVehicle) {
    initialValues = {
      ...initialVehicle,
      year: initialVehicle.year.toString(),
    };
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={toFormikValidationSchema(VehicleSchema)}
      onSubmit={onSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          {error?.data?.Errors && (
            <div className="p-4 my-2 bg-red-100 border-l-4 border-red-500 rounded-md">
              {JSON.stringify(error?.data?.Errors, null, 2)}
            </div>
          )}

          <FormField
            htmlFor="year"
            label="Year"
            showErrorIcon={errors.year && touched.year}
          >
            <Field
              id="year"
              name="year"
              type="text"
              className={textInputClass}
            />
          </FormField>

          <FormField
            htmlFor="make"
            label="Make"
            showErrorIcon={errors.make && touched.make}
          >
            <Field
              id="make"
              name="make"
              type="text"
              className={textInputClass}
            />
          </FormField>

          <FormField
            htmlFor="model"
            label="Model"
            showErrorIcon={errors.model && touched.model}
          >
            <Field
              id="model"
              name="model"
              type="text"
              className={textInputClass}
            />
          </FormField>

          <FormField
            htmlFor="color"
            label="Color"
            showErrorIcon={errors.color && touched.color}
          >
            <Field
              id="color"
              name="color"
              type="text"
              className={textInputClass}
            />
          </FormField>

          <FormField
            htmlFor="licensePlateNumber"
            label="License Plate"
            showErrorIcon={
              errors.licensePlateNumber && touched.licensePlateNumber
            }
          >
            <Field
              id="licensePlateNumber"
              name="licensePlateNumber"
              type="text"
              className={textInputClass}
            />
          </FormField>
          <div className="flex justify-end gap-2 mt-4">
            <Link to="../vehicles">
              <Button size="small" color="default">
                Discard Changes
              </Button>
            </Link>
            <Button
              size="small"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              {initialVehicle?.id ? "Update" : "Add"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
