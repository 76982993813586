import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { Button, ButtonWrapper } from "../../components/Button";
import Layout from "../../components/Layout";
import { PaymentConfirmation } from "../../components/PaymentConfirmation";
import { Spinner } from "../../components/Spinner";
import { OnboardingStatus, OnboardingStatusEnum } from "../../types/User";
import { useGetStatusTypeDetails, usePostService } from "./landing";
import useLivlyUser from "../../context/UserProvider";
import { isNativeAppVersion, returnToNative } from "@/utils/nativeAppHelpers";

export default function ChecklistCheckoutConfirmationPage() {
  const navigate = useNavigate();
  const { user } = useLivlyUser();
  const {
    amount = 0,
    paymentDescription = "",
    transactionId = "",
  } = (useLocation().state as {
    amount: 0;
    paymentDescription: "";
    transactionId: "";
  }) || {};

  const details = useGetStatusTypeDetails(OnboardingStatusEnum.checkout, user);
  const { updateOnboardingStatus, isLoading } = usePostService(
    user.userId,
    user.propertyUnitLeaseId.toString(),
    details.onboardingStatus
  );

  const onDone = async () => {
    const newOnboardingStatus: OnboardingStatus = {
      ...details.onboardingStatus,
      isCompleted: true,
    };
    await updateOnboardingStatus(newOnboardingStatus);

    if (isNativeAppVersion()) {
      returnToNative();
      return;
    }

    navigate("..", { replace: true });
  };

  return (
    <Layout title="Payment Successful">
      <PaymentConfirmation
        amount={amount}
        description={paymentDescription}
        transactionId={transactionId}
      />
      <ButtonWrapper>
        <Button
          color="secondary"
          className="flex items-center w-full gap-2 md:w-auto"
          type="button"
          onClick={onDone}
          disabled={isLoading}
        >
          {isLoading && <Spinner />}
          Done
        </Button>
      </ButtonWrapper>
    </Layout>
  );
}
