import React, { useEffect, useState } from "react";
import { PollQuetionOptions, questionAnswersType } from "./hooks/types";
import CheckboxComponent from "./CheckboxComponent";

type Props = {
  multiSelectOptionsIds: any[];
  options: PollQuetionOptions[];
  setAnswers: (questionId: number, answerId: any, other?: string) => void;
  residentPollQuestionId: number;
  updateOther: (questionId: number, other: string) => void;
  setIsSubmitDisabled: (item: boolean) => void;
  isExpired?: boolean;
  userAnswers: questionAnswersType[];
};
const PollMultiSelect = ({
  multiSelectOptionsIds,
  options,
  setAnswers,
  residentPollQuestionId,
  updateOther,
  setIsSubmitDisabled,
  isExpired,
  userAnswers
}: Props) => {
  const [other, setOther] = useState<string>("");
  const hasOther = multiSelectOptionsIds.includes(null);

  useEffect(() => {
    if (!hasOther) {
      setOther("");
    }
  }, [hasOther]);

  useEffect(() => {
    setOther(
      userAnswers?.find(
        (answer) =>
          answer.residentPollQuestionId == residentPollQuestionId &&
          answer.residentPollQuestionOptionId === null
      )?.other as string
    );
  }, [userAnswers, multiSelectOptionsIds, residentPollQuestionId]);

  useEffect(() => {
    if (hasOther && (!other || other?.length === 0)) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  }, [other, hasOther]);

  return (
    <>
      <div>
        {options?.map((response) => (
          <div key={response.residentPollQuestionOptionId}>
            <CheckboxComponent
              label={response.text}
              checked={multiSelectOptionsIds.includes(
                response.residentPollQuestionOptionId
              )}
              residentPollQuestionOptionId={
                response.residentPollQuestionOptionId
              }
              onChange={(checked: boolean) => {
                setAnswers(
                  residentPollQuestionId,
                  response.residentPollQuestionOptionId
                );
                if (response.residentPollQuestionOptionId === null) {
                  setIsSubmitDisabled(checked);
                }
              }}
              disabled={isExpired}
            />
          </div>
        ))}
      </div>
      {hasOther && (
        <div>
          <input
            type="text"
            placeholder="Enter text"
            className="w-1/2 mt-4 mb-20"
            value={other}
            disabled={isExpired}
            onChange={(e: any) => {
              setOther(e.target.value);
              updateOther(residentPollQuestionId, e.target.value);
            }}
          />
        </div>
      )}
    </>
  );
};

export default PollMultiSelect;
