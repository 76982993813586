import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { variantProps, VariantPropsOf } from "classname-variants/react";
import classNames from "classnames";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { LedgerStatus, RentAutoPay } from "../../types/Rent";
import { PaymentAccount } from "../../types/User";
import { trackChangeRentPaymentAccount } from "../../utils/analytics";
import formatCurrency from "../../utils/formatCurrency";
import Alert from "../Alert";
import { Badge } from "../Badge";
import { SelectPaymentAccount } from "../SelectPaymentAccount";
import { getLedgerStatusDetails } from "./utils";
import useLivlyUser from "../../context/UserProvider";
import { Button } from "../Button";
import { AutoPayIcon } from "./Autopay/AutoPayIcon";

interface RentDetailsProps {
  isPayRentButtonVisible: boolean | undefined;
  defaultPaymentAccount: PaymentAccount | undefined;
  balance: number;
  rentAutoPay: RentAutoPay;
  ledgerStatus: LedgerStatus | undefined;
  paymentAccounts: PaymentAccount[];
  setSelectedPaymentAccountId: (id: number) => void;
}

function RentDetails({
  isPayRentButtonVisible = false,
  defaultPaymentAccount,
  balance,
  rentAutoPay,
  ledgerStatus,
  paymentAccounts,
  setSelectedPaymentAccountId,
}: RentDetailsProps) {
  const { t } = useTranslation();
  const { user } = useLivlyUser();

  const { isAutoPayOn, isAutopayExpired } = useAutopayDetails(rentAutoPay);
  const { labelKey, labelVariant, backgroundVariant, borderVariant } =
    getLedgerStatusDetails(ledgerStatus, isAutoPayOn);
  const { pathname } = useLocation();

  return (
    <div>
      <Background color={backgroundVariant} />
      <Container color={borderVariant}>
        <div className="divide-y divide-gray-200">
          <div className="py-4">
            <h4 className="text-sm text-gray-500">current balance</h4>
            <div className="flex justify-between">
              <div className="flex items-center gap-3">
                <div className="flex items-center gap-3">
                  <p className="mt-1 text-4xl font-light">
                    {formatCurrency(balance)}
                  </p>
                  {labelKey && (
                    <div>
                      <Badge type={labelVariant}>{labelKey}</Badge>
                    </div>
                  )}
                  {isAutoPayOn && <AutoPayIcon isExpired={isAutopayExpired} />}
                </div>
              </div>
              {isPayRentButtonVisible ? (
                <Link to="pay" className="w-full md:w-auto hidden md:block">
                  <Button
                    color="secondary"
                    className="w-full md:w-auto"
                    type="button"
                  >
                    Pay Rent
                  </Button>
                </Link>
              ) : null}
            </div>
            {balance < 0 && (
              <div className="mt-2">
                <Alert
                  variant="success"
                  message={t("rent-dashboard.current-balance-info")}
                />
              </div>
            )}
          </div>

          <div>
            {defaultPaymentAccount ? (
              <div className="pt-4">
                <SelectPaymentAccount
                  selectedPaymentAccountId={defaultPaymentAccount!.id}
                  paymentAccounts={paymentAccounts.filter((pa) => pa.isActive)}
                  onSelect={setSelectedPaymentAccountId}
                  onOpenCallback={() => trackChangeRentPaymentAccount()}
                />
              </div>
            ) : (
              <Link
                to={`/lease/${user.propertyUnitLeaseId}/wallet/add`}
                state={{
                  to: pathname,
                  label: "Rent",
                }}
                className="flex items-center justify-between w-full py-4 rounded-lg"
              >
                <span className="text-lg text-red-400">Add payment method</span>
                <FontAwesomeIcon
                  icon="chevron-right"
                  className="text-sm text-gray-500"
                />
              </Link>
            )}
          </div>
          <div className="py-4">
            <div>
              {rentAutoPay.nextBillingDate && (
                <div className="flex justify-between">
                  <p>{t("rent-dashboard.autopayment-due-date")}</p>
                  <p>
                    {moment(rentAutoPay.nextBillingDate).format("MMM D, YYYY")}
                  </p>
                </div>
              )}
              {defaultPaymentAccount && (
                <div className="flex justify-between mt-2">
                  <span className="flex items-center gap-1">
                    <FontAwesomeIcon
                      icon="circle"
                      className={classNames({
                        "text-red-400": isAutopayExpired,
                        "text-green-400": isAutoPayOn,
                        "text-gray-300": !isAutoPayOn,
                      })}
                    />
                    <span className="text-gray-500">
                      {t("rent-dashboard.autopay")}:{" "}
                    </span>
                    <span
                      className={`text-sm font-medium ${
                        isAutopayExpired ? "text-red-400" : "text-inherit"
                      }`}
                    >
                      {t(
                        isAutoPayOn
                          ? isAutopayExpired
                            ? "rent.label.autopay-expired"
                            : "label.on"
                          : "label.off"
                      )}
                    </span>
                  </span>
                  <Link
                    to={`../rent/${user.userId}/autopay`}
                    className="flex items-center gap-2"
                  >
                    <p className="text-red-400">
                      {t(
                        isAutoPayOn
                          ? rentAutoPay.fixedAmount
                            ? formatCurrency(rentAutoPay.fixedAmount)
                            : "Edit"
                          : "rent-dashboard.autopay.set-up"
                      )}
                    </p>
                    <FontAwesomeIcon
                      icon="chevron-right"
                      className="text-sm text-red-400"
                    />
                  </Link>
                </div>
              )}
            </div>
            {rentAutoPay.message && (
              <Alert variant="danger" message={rentAutoPay.message} />
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}

function PaymentBlocked({ balance }: { balance: number }) {
  return (
    <div>
      <div className="h-32 rounded-t-2xl from-gray-200 from bg-gradient-to-b bg-opacity-60" />
      <div className="p-4 mx-6 -mt-24 bg-white border-b-8 border-gray-400 rounded-lg drop-shadow-md">
        <Alert
          variant="default"
          message="You cannot make a payment at this time."
        />
        <div className="mt-2">
          <h4 className="text-sm text-gray-500">current balance</h4>
          <p className="mt-1 text-4xl font-light">{formatCurrency(balance)}</p>
        </div>
      </div>
    </div>
  );
}

export { RentDetails, PaymentBlocked };

const backgroundProps = variantProps({
  base: "rounded-t-2xl h-32 bg-gradient-to-b bg-opacity-60",
  variants: {
    color: {
      success: "from-green-100",
      danger: "from-red-200",
      primary: "from-gray-200",
      info: "from-blue-200",
    },
  },
});

type BackgroundProps = JSX.IntrinsicElements["div"] &
  VariantPropsOf<typeof backgroundProps>;

function Background(props: BackgroundProps) {
  return <div {...backgroundProps(props)} />;
}

const containerProps = variantProps({
  base: "bg-white drop-shadow-md -mt-24 mx-6 p-4 rounded-lg border-b-8",
  variants: {
    color: {
      success: "border-green-100",
      danger: "border-red-200",
      primary: "border-gray-200",
      info: "border-blue-200",
    },
  },
});

type ContainerProps = JSX.IntrinsicElements["div"] &
  VariantPropsOf<typeof containerProps>;

function Container(props: ContainerProps) {
  return <div {...containerProps(props)} />;
}

function useAutopayDetails(rentAutoPay: RentAutoPay) {
  const isAutoPayOn = rentAutoPay.id !== null;
  let isAutopayExpired = false;

  if (rentAutoPay.endDate) {
    const autopayEndDate = moment(rentAutoPay.endDate).endOf("day");
    isAutopayExpired = moment().isAfter(autopayEndDate);
  }

  return { isAutoPayOn, isAutopayExpired };
}
