import { RentContext } from "@/context/RentProvider";
import useLivlyUser from "@/context/UserProvider";
import useDebounce from "@/hooks/useDebounce";
import useGetPaymentAccounts from "@/hooks/useGetPaymentAccounts";
import useGetRentAutoPaySettings from "@/hooks/useGetRentAutoPaySettings";
import useServiceFee from "@/hooks/useGetServiceFee";
import { ServiceTypeEnum } from "@/types/Building";
import { FeeData } from "@/types/Cart";
import { RentAutoPay, RentBalanceTypeEnum, RentSettings } from "@/types/Rent";
import { PaymentAccount } from "@/types/User";
import formatCurrency from "@/utils/formatCurrency";
import moment from "moment";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";

export default function useAutopayState() {
  const { user } = useLivlyUser();
  const { rentSettings } = useOutletContext() as RentContext;
  const { data: paymentAccounts = [] } = useGetPaymentAccounts();
  const { data: rentAutoPaySettingResponse, isLoading } =
    useGetRentAutoPaySettings(
      user.propertyUnitLeaseId.toString(),
      user.userId.toString()
    );

  const [autopaySettings, setAutopaySettings] = useState<RentAutoPay>();
  const [showTerms, setShowTerms] = useState(false);
  const [showDisable, setShowDisable] = useState(false);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);

  const defaultPaymentAccount = paymentAccounts.find((pa) => pa.isDefault);
  const currentlySelectedPaymentMethod = paymentAccounts.find(
    (pa) => pa.id === autopaySettings?.paymentAccountId
  );
  const paymentAccount =
    currentlySelectedPaymentMethod || defaultPaymentAccount;

  useEffect(() => {
    if (rentAutoPaySettingResponse) {
      const defaultAutopaySettings = getDefaultAutopaySettings(
        rentAutoPaySettingResponse,
        rentSettings,
        paymentAccount
      );

      setAutopaySettings(defaultAutopaySettings);
    }
  }, [rentAutoPaySettingResponse]);

  const { feeData: fullBalanceFeeData, loading: isFullBalanceFeeDataLoading } =
    useServiceFee(user.propertyId, ServiceTypeEnum.Rent, paymentAccount, 100);

  let debouncedPaymentAmount = useDebounce(
    autopaySettings?.fixedAmount ? String(autopaySettings?.fixedAmount) : "",
    500
  );
  const { feeData: fixedAmountFeeData, loading: isFixedAmountFeeDataLoading } =
    useServiceFee(
      user.propertyId,
      ServiceTypeEnum.Rent,
      paymentAccount,
      Number(debouncedPaymentAmount.replace(/[^\d.-]/g, ""))
    );

  const fullBalanceFeeDescription = getFullBalanceFeeDescription(
    paymentAccount?.paymentType,
    fullBalanceFeeData
  );

  // this will enable the payment day and start/stop date selections
  let isPaymentDaySelectionValid = true;
  // this should be determined by the backend
  let isFixedPaymentAvailable = false;
  // this should be determined by the backend
  let isFullStatementBalanceAvailable = true;
  // we will only show the payment type selection dropdown if both fixed and full balance payments are available
  let isPaymentTypeSelectionVisible = false;

  if (rentSettings) {
    isFixedPaymentAvailable = rentSettings.fixedAmountAllowed;

    if (
      rentSettings.fixedAmountAllowed ||
      rentSettings.monthlyRecurringFreeFormBalanced.isActive
    ) {
      isPaymentTypeSelectionVisible = true;
    }
  }

  let rentAutopayConfigurationMessage = "";
  const roommateFullStatementBalance =
    rentAutoPaySettingResponse?.roommateAutoPayments.find(
      (rap) => rap.isFullStatementBalance
    );

  let availableAutopayDays: number[] = [];
  // return available auto pay days minus the days that are already selected by other lessee
  if (rentSettings.rentBalanceType !== RentBalanceTypeEnum.BalanceBased) {
    if (autopaySettings?.isFullStatementBalance) {
      if (roommateFullStatementBalance) {
        availableAutopayDays = [];
        rentAutopayConfigurationMessage =
          "Your roommate has already set up autopay for your full statement balance.  You can still schedule a payment of a fixed amount on days that are before their full payment.";
      } else {
        const numberOfRoommatesWithFixedPayments =
          rentAutoPaySettingResponse?.roommateAutoPayments.filter(
            (rap) => !rap.isFullStatementBalance
          ).length ?? 0;
        const lesseeSelectedAutoPayDays =
          rentAutoPaySettingResponse?.roommateAutoPayments.map(
            (rap) => rap.autoPayDay
          ) ?? [];

        const latestAutoPayDay = Math.max(...lesseeSelectedAutoPayDays);
        const latestAutoPayDayFormatted = moment()
          .month(0)
          .date(latestAutoPayDay)
          .format("Do");
        const totalRoommatesAmount =
          rentAutoPaySettingResponse?.roommateAutoPayments.reduce(
            (acc, rap) => acc + rap.amount,
            0
          ) ?? 0;

        if (lesseeSelectedAutoPayDays.length > 0) {
          if (numberOfRoommatesWithFixedPayments > 1) {
            rentAutopayConfigurationMessage = `Your roommates are already paying ${formatCurrency(
              totalRoommatesAmount
            )} on the ${latestAutoPayDayFormatted}. You must pick a date that is after their fixed payment.`;
          } else {
            rentAutopayConfigurationMessage = `Your roommate is already paying ${formatCurrency(
              totalRoommatesAmount
            )} on the ${latestAutoPayDayFormatted}. You must pick a date that is after their fixed payment.`;
          }
        }

        availableAutopayDays =
          rentSettings.monthlyRecurringFreeFormBalanced.daysAutoPayAllowed.filter(
            (days) => days > latestAutoPayDay
          );
      }
    } else {
      // check if roommate is paying full balance
      // if so, fixed amount payment date must be before that

      if (roommateFullStatementBalance) {
        rentAutopayConfigurationMessage =
          "Your roommate has already set up autopay for your full statement balance.  You can still schedule a payment of a fixed amount on days that are before their full payment.";
        availableAutopayDays = rentSettings.daysAutoPayAllowed.filter(
          (days) => days < roommateFullStatementBalance.autoPayDay
        );
      } else {
        availableAutopayDays = rentSettings.daysAutoPayAllowed;
      }
    }
  }

  return {
    availableAutopayDays,
    rentSettings,
    showTerms,
    setShowTerms,
    showDisable,
    setShowDisable,
    error,
    setError,
    showError,
    setShowError,
    paymentAccount,
    autopaySettings,
    setAutopaySettings,
    user,
    paymentAccounts,
    isFixedPaymentAvailable,
    isFullStatementBalanceAvailable,
    isPaymentTypeSelectionVisible,
    isLoadingAutopay: isLoading,
    fixedAmountFeeData,
    isFixedAmountFeeDataLoading,
    isFullBalanceFeeDataLoading,
    fullBalanceFeeDescription,
    isPaymentDaySelectionValid,
    rentAutopayConfigurationMessage,
  };
}

export type AutopayState = ReturnType<typeof useAutopayState>;

function getDefaultAutopaySettings(
  autoPaySettings: RentAutoPay,
  rentSettings: RentSettings,
  paymentAccount: PaymentAccount | undefined
): RentAutoPay {
  let isFullStatementBalance = false;

  if (rentSettings.rentBalanceType === RentBalanceTypeEnum.BalanceBased) {
    if (rentSettings.fixedAmountAllowed) {
      isFullStatementBalance =
        autoPaySettings.fixedAmount !== null ? false : true;
    } else {
      isFullStatementBalance = true;
    }
  } else {
    if (rentSettings.monthlyRecurringFreeFormBalanced.isActive) {
      const isFullStatementBalanceAlreadyPresentOnLease =
        autoPaySettings.roommateAutoPayments.some(
          (rap) => rap.isFullStatementBalance
        );

      if (isFullStatementBalanceAlreadyPresentOnLease) {
        isFullStatementBalance = false;
      } else {
        isFullStatementBalance = autoPaySettings.fixedAmount === null;
      }
    } else {
      isFullStatementBalance = false;
    }
  }

  const paymentAccountId =
    autoPaySettings?.paymentAccountId ?? paymentAccount?.id ?? null;

  return {
    ...autoPaySettings,
    isFullStatementBalance,
    paymentAccountId,
  };
}

const getFullBalanceFeeDescription = (
  paymentType: "ACH" | "Credit Card" | undefined,
  feeData: FeeData | null
) => {
  if (paymentType == null || feeData == null) {
    return "";
  }

  const amount = feeData.value;
  if (paymentType === "ACH") {
    if (feeData.feeType === "Percentage") {
      return `${amount}% processing fee will be applied`;
    } else if (feeData.feeType === "FixedAmount")
      return `${formatCurrency(amount)} processing fee will be applied`;
  } else if (paymentType === "Credit Card") {
    if (feeData.feeType === "Percentage") {
      return `${amount}% processing fee will be applied`;
    }
  }
};
