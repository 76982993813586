import useLivlyUser from "@/context/UserProvider";
import { ServiceTypeEnum } from "@/types/Building";
import { USER_STATUS } from "@/types/User";
export interface CommFeedMetaData {
  readOnly: boolean;
}

export default function useIsReadonlyCommFeed() {
  const { user } = useLivlyUser();

  const {
    building: { serviceTypes }
  } = user;

  const commFeedService = serviceTypes?.find(
    (service) => service.serviceType === ServiceTypeEnum.CommFeed
  );

  const isCurrentAndFutureUser = [
    USER_STATUS.CURRENT,
    USER_STATUS.APPLICANT
  ].includes(user.status);

  let isCommFeedServiceReadonly = false;

  const hasFullAccessCommFeed =
    (commFeedService?.enabled &&
      commFeedService?.permissionAccessLevel === "FullAccess") ??
    false;

  if (commFeedService?.externalMetaData) {
    const externalMetaData = JSON.parse(
      commFeedService.externalMetaData
    ) as CommFeedMetaData;
    isCommFeedServiceReadonly = externalMetaData.readOnly ?? false;
  }

  const isReadOnly =
    !isCurrentAndFutureUser ||
    !hasFullAccessCommFeed ||
    isCommFeedServiceReadonly;

  return isReadOnly;
}
