export interface PollResult {
  residentPollId: number;
  title: string;
  description: string;
  questions: PollQuestion[];
  expirationTimestamp: Date | string;
  totalResponses: number;
  responsesHidden: boolean;
  completedTimestamp: Date | string;
}

export interface PollQuestion {
  residentPollQuestionId: number;
  questionTitle: string;
  description: string;
  options: PollQuetionOptions[];
  type: PollQuestionType;
}

export enum PollQuestionType {
  MultiSelect = "MultiSelect",
  SingleSelect = "SingleSelect",
  LongText = "LongText",
  ShortText = "ShortText"
}

export interface PollQuetionOptions {
  residentPollQuestionOptionId: number;
  text: string;
  responses?: number;
  selected?: boolean;
  otherText?: string;
  userResponses?: PollUserResponse[];
  type: number;
}

export interface PollUserResponse {
  userId: number;
  firstName: string;
  lastName: string;
  unitNumber: string;
  propertyId: number;
  otherText: string;
  timestamp: Date | string;
  avatarUri?: string;
}

export interface PollUserResponseType {
  questionAnswers: questionAnswersType[];
}

export enum PollQuestionTypes {
  SingleSelect,
  MultiSelect,
  LongText,
  ShortText
}

export interface questionAnswersType {
  residentPollQuestionId: number;
  residentPollQuestionOptionId?: number | null;
  other: string | null;
  type: PollQuestionTypes;
}

export const mapPollType = (type: PollQuestionType) => {
  switch (type) {
    case PollQuestionType.MultiSelect:
      return PollQuestionTypes.MultiSelect;
    case PollQuestionType.SingleSelect:
      return PollQuestionTypes.SingleSelect;
    case PollQuestionType.LongText:
      return PollQuestionTypes.LongText;
    default:
      return PollQuestionTypes.ShortText;
  }
};
