import { Fragment } from "react";

import { Popover } from "@headlessui/react";
import { FeedPost, ReactionType } from "@/utils/commfeed";
import { reactionOptions } from "./reactions";

function PostReactionsDetails({
  post,
  isReadOnly
}: {
  post: FeedPost;
  isReadOnly: boolean;
}) {
  if (!post.reactions || isReadOnly) {
    return <div />;
  }

  return (
    <div className="flex items-center gap-4">
      <div className="flex items-center gap-2">
        {Object.keys(post.reactions.reactions).map((key) => {
          const reactionType = key as ReactionType;
          const count = post.reactions?.counts[reactionType];
          if (!count) {
            return null;
          }

          const users = post.reactions?.reactions[reactionType]?.map(
            (user) => user.actorName
          );

          return (
            <Fragment key={reactionType}>
              {/* Tooltip using HeadlessUI's Popover component */}
              <Popover className="relative">
                <Popover.Button className="text-gray-500 hover:text-gray-700">
                  {reactionOptions[reactionType]}
                </Popover.Button>
                <Popover.Panel className="absolute z-10 bg-white p-2 rounded-md shadow-lg">
                  <div className="text-xs text-gray-700">
                    {users?.length > 10
                      ? [...users.slice(0, 10), "And others"].map(
                          (user, index) => <p key={index}>{user}</p>
                        )
                      : users?.map((user, index) => <p key={index}>{user}</p>)}
                  </div>
                </Popover.Panel>
              </Popover>
            </Fragment>
          );
        })}
      </div>
      <PostLikes post={post} />
    </div>
  );
}

function PostLikes({ post }: { post: FeedPost }) {
  const likes = post?.reactions?.totalCount;

  if (likes === 0) {
    return null;
  }

  let label = `${likes} ${likes === 1 ? "like" : "likes"}`;

  if (post.reactions?.userReaction && likes > 1) {
    const othersCount = likes - 1;

    label = `You and ${othersCount} ${othersCount === 1 ? "other" : "others"}`;
  } else if (
    likes === 0 &&
    post.reactions?.userReaction &&
    ["Heart", "ClappingHands", "SmileyFace", "ThumbsUp"].includes(
      post.reactions.userReaction
    )
  ) {
    label = `1 like`;
  }

  return <span className="text-xs text-gray-500">{label}</span>;
}

export { PostReactionsDetails };
