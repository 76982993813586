import { Navigate } from "react-router-dom";

import auth from "../../utils/auth";
import { useState } from "react";
import LoginForm, { Brands, LoginWrapper } from "../../components/LoginForm";
import { trackLoginSuccess } from "../../utils/analytics";
import { BRAND, clearLocalStorage } from "@/utils/localStorage";

export default function LoginPage() {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const brand: Brands | undefined =
    (localStorage.getItem(BRAND) as Brands) ?? undefined;

  if (auth.isAuthenticated()) {
    return <Navigate to="/lease" />;
  }

  clearLocalStorage();

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let email = formData.get("email") as string;

    try {
      setError("");
      setIsLoading(true);

      let password = formData.get("password") as string;

      await auth.login({ email, password });
      setIsLoading(false);
      trackLoginSuccess(true, email);
    } catch (e) {
      setError(e instanceof Error ? e.message : "Wrong email or password");
      setIsLoading(false);
      trackLoginSuccess(false, email);
    }
  }

  let from = "login";
  if (brand === "Common") {
    from = "logincommon";
  } else if (brand === "Flats") {
    from = "loginflats";
  }

  return (
    <LoginWrapper brand={brand}>
      <LoginForm
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        error={error}
        from={from}
      />
    </LoginWrapper>
  );
}
