import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { Button } from "../../components/Button";
import Layout, { LayoutBody, LayoutHeader } from "../../components/Layout";
import { Spinner } from "../../components/Spinner";
import { BaseLivlyApiResponse } from "../../types/Base";
import { ServiceProviderType, ServiceTypeEnum } from "../../types/Building";
import { BASE_API_URL } from "../../utils/constants";
import RealPageRentContainer from "../checklist/rent-real-page";
import useLivlyUser from "../../context/UserProvider";

export default function RentPage() {
  const { user } = useLivlyUser();
  const rentService = user.building.serviceTypes?.find(
    (serviceType) => serviceType.serviceType === ServiceTypeEnum.Rent
  );

  const isRentExternal =
    rentService?.serviceProviderType === ServiceProviderType.External;
  const isRentClickPay =
    rentService?.serviceProviderType === ServiceProviderType.ClickPay;
  const isRentRealPage =
    rentService?.serviceProviderType === ServiceProviderType.RealPage;

  if (isRentExternal) {
    return (
      <div className="flex items-center justify-center h-screen">
        <a href={rentService?.url} target="_blank" rel="noopener noreferrer">
          <Button color="secondary">Click Here</Button>
        </a>
      </div>
    );
  }

  if (isRentClickPay) {
    return (
      <ClickPayContainer
        leaseId={user.propertyUnitLeaseId}
        title={rentService?.displayName ?? "Rent"}
      />
    );
  }

  if (isRentRealPage) {
    return (
      <Layout title={rentService?.displayName ?? "Rent"}>
        <RealPageRentContainer leaseId={user.propertyUnitLeaseId} />
      </Layout>
    );
  }

  return <Navigate to="livly" replace />;
}

export const getClickPayUrl = async (leaseId: number) => {
  const result = await axios.get<
    BaseLivlyApiResponse<{
      url: string;
      leaseId: number;
      leaseExternalId: string;
      token: string;
    }>
  >(`${BASE_API_URL}/livly/users/leases/${leaseId}/clickPayUrl`);

  return result.data.Data;
};

function ClickPayContainer({
  title,
  leaseId,
}: {
  title: string;
  leaseId?: number;
}) {
  const isFromNativeApp = localStorage.getItem("mobile") === "true";

  if (isFromNativeApp) {
    return <ClickPayWidgetMobile leaseId={leaseId} />;
  }

  return <ClickPayWidget leaseId={leaseId} title={title} />;
}

function ClickPayWidget({
  title,
  leaseId,
}: {
  title: string;
  leaseId?: number;
}) {
  const { isLoading, mutate } = useMutation(() => getClickPayUrl(leaseId!));

  const onClick = () => {
    mutate(undefined, {
      onSuccess: (data) => {
        if (data.url) {
          window.open(data.url);
        }
      },
    });
  };

  return (
    <div className="flex flex-col h-screen">
      <LayoutHeader title={title} />
      <LayoutBody className="flex items-center justify-center flex-1">
        <Button
          color="secondary"
          onClick={onClick}
          disabled={isLoading}
          data-test="clickpay-button"
        >
          Pay Rent with ClickPay
        </Button>
      </LayoutBody>
    </div>
  );
}

function ClickPayWidgetMobile({ leaseId }: { leaseId?: number }) {
  const { isLoading, isFetching, isError } = useQuery(
    ["click-pay", leaseId],
    () => getClickPayUrl(leaseId!),
    {
      onSuccess(data) {
        if (data.url) {
          window.location.replace(data.url);
        }
      },
    }
  );

  return (
    <div className="flex flex-col h-screen">
      <LayoutBody className="flex items-center justify-center flex-1">
        {isError && <p>Error loading ClickPay</p>}
        {(isLoading || isFetching) && <Spinner color="livly" />}
      </LayoutBody>
    </div>
  );
}
