import { Button } from "@/components/Button";
import { Modal } from "@/components/Dialog";
import LivlySwitch from "@/components/LivlySwitch";
import useLivlyGuest, {
  endTime,
  startTime,
  midnight,
  initialAccessControlSchedule,
  getInitialDays,
} from "@/context/GuestProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

type AccessScheduleType = 0 | 1;

type DaySelected = {
  startTime: number;
  endTime: number;
  day: string;
  disabled: boolean;
};

export const AccessScheduleModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { guest, updateGuest } = useLivlyGuest();

  const areStartTimesSame = (days: DaySelected[]) => {
    const firstStartTime = days[0].startTime;
    const firstEndTime = days[0].endTime;
    return days.every(
      (day) => day.startTime === firstStartTime && day.endTime === firstEndTime
    );
  };

  const allDateSame = areStartTimesSame(
    guest.accessControl?.schedule.days || []
  );

  const initialSelectedStartTime = moment()
    .startOf("day")
    .add(guest.accessControl?.schedule.days[0].startTime, "minutes")
    .format();
  const initialSelectedEndTime = moment()
    .startOf("day")
    .add(guest.accessControl?.schedule.days[0].endTime, "minutes")
    .format();

  const [startDate, setStartDate] = useState(
    new Date(allDateSame ? initialSelectedStartTime : (startTime as any))
  );
  const [endDate, setEndDate] = useState(
    new Date(allDateSame ? initialSelectedEndTime : (endTime as any))
  );

  const [selected, setSelected] = useState<AccessScheduleType>(
    allDateSame ? 0 : 1
  );

  const [daySelected, setDaySelected] = useState<DaySelected | null>(null);
  const dayTypes = [
    { id: 0, value: 0, name: "Every Day" },
    { id: 1, value: 1, name: "Customize Days" },
  ];

  useEffect(() => {
    if (allDateSame) {
      setStartDate(new Date(initialSelectedStartTime));
      setEndDate(new Date(initialSelectedEndTime));
    }
  }, [allDateSame]);

  const handleCutomDate = (time: Date, type: "startTime" | "endTime") => {
    if (daySelected) {
      const newTime = moment(time).diff(midnight, "minutes");
      let selectedScheduleDay = guest?.accessControl?.schedule?.days?.find(
        (day) => day.day === daySelected?.day
      );
      if (selectedScheduleDay) {
        selectedScheduleDay[type] = newTime;
        let accessControl = guest?.accessControl;
        accessControl?.schedule?.days?.map((day) => {
          if (day.day === daySelected?.day) {
            return { ...selectedScheduleDay };
          }
          return day;
        });
        updateGuest({ ...guest, accessControl: accessControl });
      }
    }
  };

  const handleCutomClosed = (disabled: boolean) => {
    if (daySelected) {
      let selectedScheduleDay = guest?.accessControl?.schedule?.days?.find(
        (day) => day.day === daySelected?.day
      );
      if (selectedScheduleDay) {
        selectedScheduleDay.disabled = disabled;
        selectedScheduleDay.startTime =
          selectedScheduleDay.startTime ||
          moment(startTime).diff(midnight, "minutes");
        selectedScheduleDay.endTime =
          selectedScheduleDay.endTime ||
          moment(endTime).diff(midnight, "minutes");
        let accessControl = guest?.accessControl;
        accessControl?.schedule?.days?.map((day) => {
          if (day.day === daySelected?.day) {
            return { ...selectedScheduleDay };
          }
          return day;
        });
        updateGuest({ ...guest, accessControl: accessControl });
      }
    }
  };

  const handleUpdateAllDate = () => {
    let accessControl = { ...guest?.accessControl };
    const startTime = moment(startDate).diff(midnight, "minutes");
    const endTime = moment(endDate).diff(midnight, "minutes");
    const payload = accessControl?.schedule?.days?.map((day) => ({
      ...day,
      startTime: startTime,
      endTime: endTime,
    }));
    if (
      accessControl.schedule !== undefined &&
      accessControl.schedule.days.length &&
      payload?.length
    ) {
      accessControl.schedule.days = [...payload];
      //@ts-ignore
      updateGuest({ ...guest, accessControl: accessControl });
    }
    onClose();
  };

  const handleReset = () => {
    const payload = { days: getInitialDays() };
    let newAccessControl = { ...guest?.accessControl };
    newAccessControl.schedule = payload;
    //@ts-ignore
    updateGuest({ ...guest, accessControl: newAccessControl });
    const initialStartTime = moment()
      .startOf("day")
      .add(payload.days[0].startTime, "minutes")
      .format();
    const initialEndTime = moment()
      .startOf("day")
      .add(payload.days[0].endTime, "minutes")
      .format();
    setStartDate(new Date(initialStartTime));
    setEndDate(new Date(initialEndTime));
  };

  const getMinMinutes = (startTime?: number) => {
    const hours = startTime
      ? new Date(
          moment().startOf("day").add(startTime, "minutes").format()
        ).getHours()
      : startDate.getHours();
    const minutes = startTime
      ? new Date(
          moment().startOf("day").add(startTime, "minutes").format()
        ).getMinutes()
      : startDate.getMinutes();

    // Calculate new minutes after adding 15 minutes
    let newMinutes = minutes + 15;

    // Calculate new hour and adjust minutes if needed
    let newHours = hours;
    if (newMinutes >= 60) {
      newHours += 1;
      newMinutes -= 60;
    }

    // Create a new Date object with the updated time
    const newDate = new Date(0, 0, 0, newHours, newMinutes);
    return newDate;
  };

  const getMaxMinutes = (endTime?: number) => {
    const hours = endTime
      ? new Date(
          moment().startOf("day").add(endTime, "minutes").format()
        ).getHours()
      : endDate.getHours();
    const minutes = endTime
      ? new Date(
          moment().startOf("day").add(endTime, "minutes").format()
        ).getMinutes()
      : endDate.getMinutes();

    // Calculate new minutes after adding 15 minutes
    let newMinutes = minutes - 15;

    // Calculate new hour and adjust minutes if needed
    let newHours = hours;
    if (newMinutes < 0) {
      newHours -= 1;
      newMinutes += 60;
    }

    // Create a new Date object with the updated time
    const newDate = new Date(0, 0, 0, newHours, newMinutes);
    return newDate;
  };

  return (
    <Modal open={open} onClose={onClose} size="lg">
      <div className="max-w-lg mt-2 mb-4 font-medium px-2">Access Schedule</div>
      <div className="flex flex-col justify-center p-2">
        {dayTypes.map(({ id, name, value }) => (
          <div
            className={` border-b py-4 flex justify-between cursor-pointer ${
              value === selected ? "text-red-400" : "text-black"
            }`}
            key={id}
            onClick={() => {
              setSelected(value as AccessScheduleType);
              setDaySelected(null);
            }}
          >
            <p>{name}</p>
            {value === selected ? (
              <FontAwesomeIcon icon="check" className="text-red-400" />
            ) : (
              ""
            )}
          </div>
        ))}

        <>
          {selected === 1 && !daySelected && (
            <div>
              <p className="text-base font-medium my-2 ">Avaiable Times</p>
              {guest?.accessControl?.schedule?.days?.map((day: DaySelected) => (
                <div
                  className="flex items-center py-4 border-b w-full cursor-pointer "
                  key={day.day}
                  onClick={() => setDaySelected(day)}
                >
                  <div className="w-44">
                    <p className="text-lg text-gray-700 hover:text-red-300">
                      {day?.day}
                    </p>
                  </div>
                  <div className="flex-1 flex justify-end">
                    {day?.disabled ? (
                      <p className="tetx-lg text-gray-400 hover:text-red-300">
                        Disabled
                      </p>
                    ) : (
                      <p className="tetx-lg text-gray-400 hover:text-red-300">
                        {moment()
                          .startOf("day")
                          .add(day?.startTime, "minutes")
                          .format("LT")}{" "}
                        -{" "}
                        {moment()
                          .startOf("day")
                          .add(day?.endTime, "minutes")
                          .format("LT")}
                      </p>
                    )}
                    <div>
                      <FontAwesomeIcon
                        icon="chevron-right"
                        className="text-sm text-gray-400 mx-2 hover:text-red-300"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
          {selected === 0 && !daySelected ? (
            <>
              <div className={` border-b py-4 flex  relative`}>
                <p className="flex-1">From</p>

                <DatePicker
                  selected={startDate}
                  //@ts-ignore
                  onChange={(time) => {
                    setStartDate(time);
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={1}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  minTime={new Date(0, 0, 0, 0, 0)}
                  maxTime={endDate && getMaxMinutes()}
                  onKeyDown={(e: any) => {
                    e.preventDefault();
                  }}
                />
              </div>
              <div className={` border-b py-4 flex  relative`}>
                <p className="flex-1">To</p>
                <DatePicker
                  selected={endDate}
                  //@ts-ignore
                  onChange={(date) => setEndDate(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={1}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                  minTime={startDate && getMinMinutes()}
                  maxTime={new Date(0, 0, 0, 23, 59)}
                  onKeyDown={(e: any) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </>
          ) : selected === 1 && daySelected ? (
            <>
              <p className="my-2 mb-4 font-medium ">{daySelected.day}</p>
              <div className="mb-1 border-b pb-2 flex justify-between">
                <p>Closed</p>
                <label className="flex flex-col items-end">
                  <LivlySwitch
                    checked={daySelected?.disabled ?? false}
                    onChange={(checked: boolean) => handleCutomClosed(checked)}
                  />
                </label>
              </div>
              {!daySelected.disabled && (
                <>
                  <div className={` border-b py-4 flex  relative`}>
                    <p className="flex-1">From</p>

                    <DatePicker
                      selected={
                        new Date(
                          moment()
                            .startOf("day")
                            .add(daySelected.startTime, "minutes")
                            .format()
                        )
                      }
                      //@ts-ignore
                      onChange={(time) => handleCutomDate(time, "startTime")}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={1}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      minTime={new Date(0, 0, 0, 0, 0)}
                      maxTime={
                        daySelected.endTime &&
                        getMaxMinutes(daySelected.endTime)
                      }
                      onKeyDown={(e: any) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                  <div className={` border-b py-4 flex  relative`}>
                    <p className="flex-1">To</p>

                    <DatePicker
                      selected={
                        new Date(
                          moment()
                            .startOf("day")
                            .add(daySelected.endTime, "minutes")
                            .format()
                        )
                      }
                      //@ts-ignore
                      onChange={(time) => handleCutomDate(time, "endTime")}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={1}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                      minTime={
                        daySelected.endTime &&
                        getMinMinutes(daySelected.startTime)
                      }
                      maxTime={new Date(0, 0, 0, 23, 59)}
                      onKeyDown={(e: any) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                </>
              )}
            </>
          ) : null}
        </>
      </div>
      {daySelected ? (
        <div className="mt-4 flex justify-end gap-4">
          {" "}
          <Button onClick={() => setDaySelected(null)}>Done</Button>
        </div>
      ) : (
        <div className="mt-4 flex justify-end gap-4">
          <Button color="default" onClick={handleReset}>
            reset
          </Button>
          {selected === 0 && (
            <Button onClick={handleUpdateAllDate}>Update</Button>
          )}
          {selected === 1 && <Button onClick={onClose}>Done</Button>}
        </div>
      )}
    </Modal>
  );
};
