import { Spinner } from "@/components/Spinner";
import { BaseLivlyApiResponse } from "@/types/Base";
import { BASE_API_URL } from "@/utils/constants";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Outlet, useParams } from "react-router-dom";

export type Vendor = {
  _createdAt: Date;
  _id: string;
  _rev: string;
  _type: string;
  _updatedAt: Date;
  vendorBadge: string;
  vendorCtaText: string;
  vendorLogo: string;
  vendorName: string;
  vendorSubtitle: string;
  vendorOffers: VendorOffer[];
};

export type VendorOffer = {
  _createdAt: Date;
  _id: string;
  _rev: string;
  _type: string;
  _updatedAt: Date;
  exludedClients: number[];
  offerBannerImage: string | null;
  offerCtaText: string;
  offerDescription: OfferDescription[];
  offerEmailLivly: string;
  offerExternalLink: string;
  offerSquareImage: string;
  offerSubTitle: string;
  offerTitle: string;
  priority: number;
  vendor: {
    _ref: string;
    _type: string;
  };
};

export type OfferDescription = {
  _key: string;
  _type: string;
  children: any;
};

async function getVendors(leaseId: string) {
  const result = await axios.get<BaseLivlyApiResponse<Vendor[]>>(
    `${BASE_API_URL}/livly/common/content?leaseId=${leaseId}`
  );

  return result.data.Data;
}

const marketplaceVendorsQuery = (leaseId: string) => ({
  queryKey: ["building-offers", leaseId],
  queryFn: async () => getVendors(leaseId),
});

export type MarketplaceContext = {
  data: Vendor[];
  isLoading: boolean;
};

export function useGetMarketplaceOffers(leaseId: string | number) {
  return useQuery({
    ...marketplaceVendorsQuery(leaseId.toString()),
  });
}

export default function MarketplaceLayout() {
  const params = useParams<{ leaseId: string }>();

  const { data = [], isLoading } = useGetMarketplaceOffers(params.leaseId!);

  const context: MarketplaceContext = {
    data,
    isLoading,
  };

  return <Outlet context={context} />;
}
