import { useState } from "react";
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";

const ImagesLightbox = ({ images, onClose, selectedImageIndex = 0 }: any) => {
  const [photoIndex, setPhotoIndex] = useState(selectedImageIndex);
  const prevIndex = (photoIndex + images.length - 1) % images.length;
  const nextIndex = (photoIndex + 1) % images.length;

  return (
    <Lightbox
      reactModalStyle={{
        overlay: {
          zIndex: 1500,
        },
      }}
      mainSrc={images[photoIndex]}
      nextSrc={images[nextIndex]}
      prevSrc={images[prevIndex]}
      onCloseRequest={onClose}
      onMovePrevRequest={() => setPhotoIndex(prevIndex)}
      onMoveNextRequest={() => setPhotoIndex(nextIndex)}
    />
  );
};

export default ImagesLightbox;
