import Layout from "@/components/Layout";
import useLivlyGuest, { GuestRequest } from "@/context/GuestProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GuestCategory, GuestType } from "./my-guests";
import { businesses, getTypeName } from "@/components/GuestForm";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "@/components/Button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { BASE_API_URL } from "@/utils/constants";
import useLivlyUser from "@/context/UserProvider";
import { Spinner } from "@/components/Spinner";
import toast from "react-hot-toast";
import { ServiceTypeEnum } from "@/types/Building";
import useGetService from "@/hooks/useGetService";
import { useGetLivlyLockService } from "./permission";

function usePostGuest(leaseId: string, propertyId: number) {
  const postGuest = async (guest: GuestRequest) => {
    return await axios.post(
      `${BASE_API_URL}/resident/guests/property/${propertyId}/lease/${leaseId}`,
      guest
    );
  };

  return useMutation(postGuest);
}

export const GuestReview = () => {
  const params = useParams<{ leaseId: string }>();
  const { user } = useLivlyUser();
  const { guest, resetGuest } = useLivlyGuest();
  const service = useGetService(ServiceTypeEnum.LivlyLocks);
  const livlyLockServiceEnabled = useGetLivlyLockService(
    user.propertyId,
    params.leaseId!
  );
  const { skipped = false } =
    (useLocation().state as {
      skipped: false;
    }) || {};

  const livlyKeyService = guest.serviceTypes?.find(
    (s) => s?.serviceType === ServiceTypeEnum.LivlyLocks
  );

  const isLivlyKeyEnabled = user?.isLivlyKeyEnabled ?? false;

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = usePostGuest(params.leaseId!, user.propertyId);

  const onSubmit = () => {
    const category = businesses.includes(
      guest.guestType as unknown as GuestCategory
    )
      ? GuestType.Business
      : GuestType.Personal;

    const guestWithCategory: GuestRequest = {
      ...guest,
      avatarBase64Image: guest?.avatarBase64Image
        ? guest?.avatarBase64Image?.replace("data:image/png;base64,", "")
        : null,
      type: {
        id: Number(guest.guestType),
        name: getTypeName(guest.guestType as unknown as GuestCategory),
        category,
        isDeleted: false
      }
    };

    mutate(guestWithCategory, {
      onSuccess: () => {
        queryClient.invalidateQueries(["lease-guests"]);
        navigate(`/lease/${params.leaseId}/guests`);
        resetGuest();
      },
      onError: (e) => {
        const error = e as { data?: { Message: string } };
        toast.error(error.data?.Message ?? "There was an error saving guest");
      }
    });
  };
  return (
    <Layout
      back={{
        to: skipped ? "../permission" : "../livly-feature-permission",
        label: skipped ? "Access permission" : "Features permission"
      }}
      title="Review"
    >
      <div className="max-w-3xl mx-auto">
        <div className="mb-8">
          <p className="text-xl font-medium">Review your guest info</p>
        </div>
        <div className="flex items-center justify-between border-b pb-8 border-gray-300">
          <div className="relative flex items-center justify-center w-24 h-24 bg-gray-200 rounded-full mr-8">
            {guest.avatarBase64Image || guest.avatarUri ? (
              <img
                //@ts-ignore
                src={guest.avatarBase64Image || guest.avatarUri}
                className="object-cover w-24 h-24 rounded-full"
                alt={guest.firstName}
              />
            ) : (
              <div className="flex items-center justify-center w-24 h-24 bg-gray-200 rounded-full">
                <FontAwesomeIcon
                  icon={
                    businesses.includes(
                      guest.guestType as unknown as GuestCategory
                    )
                      ? "building"
                      : "user"
                  }
                />
              </div>
            )}
          </div>
          <div className="flex-1">
            <p className="text-xl font-bold gap-1">
              {guest.firstName} {guest.lastName}
            </p>
            <p>{getTypeName(Number(guest.guestType))}</p>
          </div>
        </div>
        <div className="my-4">
          <div className="flex justify-between w-full mb-8">
            <p className="text-lg font-medium">Profile</p>
            <p
              className="text-red-400 cursor-pointer"
              onClick={() => navigate("..", { state: { redirect: true } })}
            >
              Edit
            </p>
          </div>
          <div className="mb-8">
            <p className="text-lg font-medium">Email</p>
            <p>{guest.email}</p>
          </div>
          <div className="mb-8">
            <p className="text-lg font-medium">Phone Number</p>
            <p>{guest.phone}</p>
          </div>
          <div className="mb-8">
            <p className="text-lg font-medium">Notes</p>
            <p>{guest.note || guest.staffNotes || "--"}</p>
          </div>
          {service?.enabled && isLivlyKeyEnabled && livlyLockServiceEnabled && (
            <div className="flex justify-between w-full mb-8">
              <p className="text-lg font-medium">Livly Key Permission</p>
              <Link
                to="../livly-key-permission"
                className={!guest.appPermission ? "cursor-not-allowed" : ""}
                onClick={(e) =>
                  !guest.appPermission ? e.preventDefault() : null
                }
              >
                <div className="flex items-center">
                  <p className="mr-2">
                    {livlyKeyService?.enabled ? "On" : "Off"}
                  </p>
                  <FontAwesomeIcon
                    icon="chevron-right"
                    className="text-gray-400"
                  />
                </div>
              </Link>
            </div>
          )}
          <div className="flex justify-between w-full mb-8 ">
            <p className="text-lg font-medium">Other Features</p>
            <Link
              to="../livly-feature-permission"
              className={!guest.appPermission ? "cursor-not-allowed" : ""}
              onClick={(e) =>
                !guest.appPermission ? e.preventDefault() : null
              }
            >
              <div className="flex items-center">
                <p className="mr-2">{guest.appPermission ? "On" : "Off"}</p>
                <FontAwesomeIcon
                  icon="chevron-right"
                  className="text-gray-400"
                />
              </div>
            </Link>
          </div>
        </div>
        <div className="flex justify-end gap-2 mt-12 border-t pt-4">
          <Button
            size="small"
            color="primary"
            onClick={() => onSubmit()}
            disabled={isLoading}
            className="flex items-center gap-2"
          >
            {isLoading && <Spinner />}
            {guest?.guestId
              ? "Save"
              : guest.appPermission
              ? "Send app invite"
              : "Add Guest"}
          </Button>
        </div>
      </div>
    </Layout>
  );
};
