import useLivlyUser from "../context/UserProvider";
import { ServiceTypeEnum } from "../types/Building";

type ScheduleMoveInMetaData = {
  isEmailEnabled: boolean;
  onboardingAmenitiesEnabled: boolean;
};

export default function useGetOnboardingAmenitiesEnabled() {
  const { user } = useLivlyUser();

  const scheduleMoveInMetaData =
    user.building.serviceTypes?.find(
      (service) => service.serviceType === ServiceTypeEnum.ScheduleMoveIn
    )?.metaData ?? "";
  const parsed: ScheduleMoveInMetaData = scheduleMoveInMetaData
    ? JSON.parse(scheduleMoveInMetaData as string)
    : { onboardingAmenitiesEnabled: false, isEmailEnabled: false };
  const onboardingAmenitiesEnabled =
    parsed?.onboardingAmenitiesEnabled ?? false;

  return onboardingAmenitiesEnabled;
}
