import "photoswipe/dist/photoswipe.css";
import { Gallery, Item, useGallery } from "react-photoswipe-gallery";
import { SlideData } from "photoswipe";
import { useQuery } from "@tanstack/react-query";

const GalleryContent = ({ images }: { images: string[] }) => {
  const { open } = useGallery();
  const [image] = images;

  return (
    <div className="relative">
      <Item>
        {({ ref }) => (
          <div ref={ref} className="w-full max-h-[500px] overflow-clip ">
            <img
              className="w-full h-full cursor-pointer object-cover object-center"
              alt={image}
              src={image}
              onClick={() => open(0)}
            />
            {images.length > 1 && (
              <div className="absolute right-10 bottom-10 px-4 py-2 bg-gray-800 rounded-full opacity-60 pointer-events-none">
                <span className="text-white">See all {images.length}</span>
              </div>
            )}
          </div>
        )}
      </Item>
    </div>
  );
};

const MyGallery = ({
  images
}: {
  images: string[];
  onClose?: () => void;
  selectedImageIndex?: number;
}) => {
  const queryKey = images.join(", ");
  const { isLoading, data } = useQuery({
    queryKey: [queryKey],
    queryFn: () => getImagesWithMetaData(images)
  });

  if (isLoading) {
    return null;
  }

  return (
    <Gallery
      options={{
        dataSource: data
      }}
    >
      <GalleryContent images={images} />
    </Gallery>
  );
};

export default MyGallery;

async function getImagesWithMetaData(images: string[]): Promise<SlideData[]> {
  return Promise.all(images.map((img) => getImageMeta(img)));
}

const getImageMeta = async (imageUri: string): Promise<SlideData> => {
  const img = new Image();
  img.src = imageUri;
  await img.decode();

  return {
    src: imageUri,
    height: img.naturalHeight,
    width: img.naturalWidth
  };
};
