import Avatar from "../components/Avatar";
import { RatingIcon } from "../components/RatingIcon";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { BaseLivlyApiResponse } from "../types/Base";
import { BASE_API_URL } from "../utils/constants";
import { Spinner } from "../components/Spinner";
import toLocalTime from "../utils/toLocalTime";
import useLivlyUser from "../context/UserProvider";
import { BuildingServiceType, ServiceTypeEnum } from "@/types/Building";
import { getIsFeedbackTabsVisible } from "./feedback";
import { Link, Navigate, Outlet } from "react-router-dom";
import moment from "moment-timezone";
import { Button } from "@/components/Button";

interface Review {
  id: number;
  title: string;
  timestamp: string;
  text: string;
  rating: number;
  feedbackItems: ReviewFeedbackItem[];
  replies: ReviewReply[];
}

interface ReviewFeedbackItem {
  id: number;
  description: string;
  type: number;
  name: string;
}

interface ReviewReply {
  firstName: string;
  lastName: string;
  avatarUrl: string;
  timestamp: string;
  text: string;
  reviewRequested: boolean;
  reviewLocations: ReviewLocation[];
}

interface ReviewLocation {
  type: number;
  name: string;
  url: string;
}

async function getReviews(leaseId: number) {
  const result = await axios.get<BaseLivlyApiResponse<Review[]>>(
    `${BASE_API_URL}/resident/feedback?leaseId=${leaseId}`
  );

  return result.data.Data;
}

const feedbackQuery = (leaseId: number) => ({
  queryKey: ["reviews", leaseId],
  queryFn: () => getReviews(leaseId),
  refetchOnWindowFocus: false,
});

export default function FeedbackAndReviewsPage() {
  const { user } = useLivlyUser();
  const { data, isLoading } = useQuery({
    ...feedbackQuery(user.propertyUnitLeaseId),
  });
  const { pollsEnabled, feedbackEnabled } = getIsFeedbackTabsVisible(
    user.building.serviceTypes ?? []
  );

  if (pollsEnabled && !feedbackEnabled) {
    return <Navigate to="../polls" replace />;
  }

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (data ?? []).length === 0 ? (
        <div className="my-10 text-center">
          <h4 className="text-xl">No feedback yet</h4>
          <p className="mt-2 text-sm font-light">
            Any feedback or reviews you leave will appear here
          </p>
        </div>
      ) : (
        <div>
          <ul className="text-sm divide-y divide-gray-200">
            {data?.map((review) => {
              const isEditAvailable =
                moment().diff(review.timestamp, "days") < 7;

              return (
                <li key={review.id} className="py-4">
                  <div className="flex justify-between gap-4">
                    <div>
                      <h3 className="font-medium">{review.title}</h3>
                      <p className="text-sm text-gray-500">
                        {toLocalTime(review.timestamp).fromNow()}
                      </p>
                    </div>
                    <div className="flex items-center gap-4">
                      {isEditAvailable && (
                        <Link
                          to={`edit/${review.id}`}
                          state={{ background: review }}
                        >
                          <Button size="small" color="default">
                            Edit
                          </Button>
                        </Link>
                      )}
                      <RatingIcon rating={review.rating} isSelected size="2x" />
                    </div>
                  </div>
                  {review.feedbackItems.length > 0 && (
                    <div className="flex flex-wrap gap-1 mt-4">
                      {review.feedbackItems.map((item) => (
                        <div
                          key={item.type}
                          className="px-1 text-xs text-blue-600 border border-blue-400 rounded-md bg-blue-50"
                        >
                          {item.description}
                        </div>
                      ))}
                    </div>
                  )}
                  <p className="mt-4 text-sm">{review.text}</p>
                  {review.replies.length > 0 && (
                    <ul className="mt-4">
                      {review.replies.map((reply) => (
                        <li
                          key={reply.timestamp}
                          className="p-2 bg-gray-100 rounded-md"
                        >
                          <div className="flex gap-4">
                            <div className="flex-shrink-0">
                              <Avatar
                                src={reply.avatarUrl}
                                name={`${reply.firstName} ${reply.lastName}`}
                                size="xs"
                              />
                            </div>
                            <div>
                              <span>
                                <strong>
                                  {reply.firstName} {reply.lastName}
                                </strong>{" "}
                                <span className="text-xs text-gray-500">
                                  {toLocalTime(reply.timestamp).fromNow()}
                                </span>
                              </span>
                              <p className="mt-2">{reply.text}</p>
                              {reply.reviewRequested && (
                                <ul className="mt-2 ">
                                  {reply.reviewLocations.map((location) => (
                                    <li key={location.type} className="py-4">
                                      <a
                                        href={location.url}
                                        target="_blank"
                                        className="p-2 text-sm text-red-400 rounded-md hover:bg-gray-200"
                                      >
                                        Leave the review on {location.name}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
      <Outlet />
    </>
  );
}
