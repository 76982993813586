export default function Oops() {
  return (
    <div className="bg-[#f6f6f6] h-screen flex flex-col justify-center items-center">
      <div className=" text-[#E2A89F] text-[200px] font-bold mb-32">Oops!</div>
      <div className="text-3xl text-[#565553] mb-10">
        Looks like something unexpected happened...
      </div>
      <div className="text-[#cececd] text-2xl">
        We're looking into the problem, but for now try{" "}
        <a className="no-underline text-white" href="/">
          starting over.
        </a>
      </div>
    </div>
  );
}
