import { BaseLivlyApiResponse } from "@/types/Base";
import { PaymentAccount } from "@/types/User";
import { BASE_API_URL } from "@/utils/constants";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

async function getPaymentAccounts() {
  const result = await axios.get<BaseLivlyApiResponse<PaymentAccount[]>>(
    `${BASE_API_URL}/resident/payment-account`
  );

  return result.data.Data;
}

export default function useGetPaymentAccounts() {
  return useQuery({
    queryKey: ["payment", "accounts"],
    queryFn: () => getPaymentAccounts(),
  });
}
