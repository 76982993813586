import { Spinner } from "@/components/Spinner";
import {
  Vendor,
  VendorOffer,
  useGetMarketplaceOffers,
} from "@/context/MarketplaceLayout";
import useLivlyUser from "@/context/UserProvider";
import CountDownTimer from "@/components/CountDownTimer";
import moment from "moment-timezone";
import { Link, useOutletContext } from "react-router-dom";
import { useEffect } from "react";
import useUI from "@/context/UIProvider";
import { trackMarketplaceOfferClick } from "@/utils/analytics";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ChecklistBuildingOffersPage() {
  const { setHideNavbar } = useUI();
  const { user } = useLivlyUser();
  const { data = [], isLoading } = useGetMarketplaceOffers(
    user.propertyUnitLeaseId
  );

  const {
    building: { logoUrl, name },
  } = user;
  const availableVendors = data?.filter(
    (vendor) => vendor.vendorOffers.length > 0
  );

  useEffect(() => {
    setHideNavbar(true);
  }, []);

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 p-6 overflow-y-auto">
        <div>
          {logoUrl && (
            <div className="flex justify-center">
              <img src={logoUrl} alt={name} className="h-12" />
            </div>
          )}
          <p className="mt-6">Welcome to {name}</p>
          <p className="mt-1 text-2xl font-noe-display-black">
            {user.firstName}
          </p>
          <p className="mt-4">
            Below are some special offers to help you prep for your move and new
            apartment.
          </p>
        </div>
        {isLoading ? (
          <div className="flex items-center justify-center my-12">
            <Spinner size="xl" color="livly" />
          </div>
        ) : (
          <div className="mt-8">
            {(availableVendors ?? []).length > 0 ? (
              <ul>
                {availableVendors?.map((vendor) => (
                  <VendorItem key={vendor._id} vendor={vendor} />
                ))}
              </ul>
            ) : (
              <div className="flex items-center justify-center flex-1 my-56">
                There are no building offers available
              </div>
            )}
          </div>
        )}
      </div>
      <div className="bg-white border shadow-lg rounded-tl-xl rounded-tr-xl">
        <div className="py-3 text-xs text-center border-b border-gray-200 md:text-lg">
          <CountDownTimer
            date={user.leaseMoveInDate}
            timezone={user.building.timezone}
          />
        </div>
        <div className="flex justify-between px-4 mt-4">
          <p className="text-gray-500">Move-in date</p>
          <p>{moment(user.leaseMoveInDate).format("MMMM D, YYYY")}</p>
        </div>
        <div className="p-4">
          <Link
            to={`/lease/${user.propertyUnitLeaseId}/home`}
            state={{ hideNavbar: false }}
          >
            <button
              type="button"
              className="w-full px-4 py-4 text-white bg-red-400 rounded-md hover:bg-red-400/75 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
            >
              Continue
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export function VendorItem({ vendor }: { vendor: Vendor }) {
  const hasSingleOffer = vendor && vendor.vendorOffers.length === 1;

  return (
    <li className="mb-8">
      <div className="mb-2">
        {!hasSingleOffer && (
          <>
            {vendor.vendorLogo ? (
              <img
                src={vendor.vendorLogo}
                alt={vendor.vendorName}
                className="h-8"
              />
            ) : (
              <h5>{vendor.vendorName}</h5>
            )}
            {vendor.vendorSubtitle && (
              <p className="text-gray-500">{vendor.vendorSubtitle}</p>
            )}
          </>
        )}
      </div>
      <div className="flex w-full pb-4 overflow-x-auto overflow-y-hidden flex-nowrap">
        {hasSingleOffer ? (
          <VendorOfferHeroItem
            offer={vendor.vendorOffers[0]}
            vendor={vendor}
            trackMarketplaceOfferClick={() => {
              trackMarketplaceOfferClick(vendor.vendorOffers[0].offerTitle);
            }}
          />
        ) : (
          vendor.vendorOffers.map((offer: VendorOffer, offerIndex: number) => (
            <VendorOfferItem
              key={offerIndex}
              offer={offer}
              vendor={vendor}
              trackMarketplaceOfferClick={() => {
                trackMarketplaceOfferClick(vendor.vendorOffers[0].offerTitle);
              }}
            />
          ))
        )}
      </div>
    </li>
  );
}

function VendorOfferHeroItem({
  vendor,
  offer,
  trackMarketplaceOfferClick,
}: {
  vendor: Vendor;
  offer: VendorOffer;
  trackMarketplaceOfferClick: (title: string) => void;
}) {
  const image = offer.offerSquareImage;

  return (
    <Link
      to={`${vendor._id}/${offer._id}`}
      onClick={() => {
        trackMarketplaceOfferClick(offer.offerTitle);
      }}
      state={{ hideNavbar: true }}
    >
      <div className="cursor-pointer max-w-[400px]">
        <img
          src={image}
          className="object-contain w-full rounded-lg"
          alt={offer.offerTitle}
        />
      </div>
    </Link>
  );
}

function VendorOfferItem({
  vendor,
  offer,
  trackMarketplaceOfferClick,
}: {
  vendor: Vendor;
  offer: VendorOffer;
  trackMarketplaceOfferClick: (title: string) => void;
}) {
  return (
    <div className="flex flex-col flex-shrink-0 mr-4 w-36">
      <Link
        to={`${vendor._id}/${offer._id}`}
        className="flex mb-2"
        onClick={() => {
          trackMarketplaceOfferClick(offer.offerTitle);
        }}
        state={{ hideNavbar: true }}
      >
        {offer.offerSquareImage ? (
          <img
            className="w-[150px] h-[150px] object-cover bg-no-repeat bg-center"
            src={offer.offerSquareImage}
            alt={offer.offerSubTitle}
          />
        ) : (
          <div className="flex items-center justify-center w-full bg-gray-200 h-36">
            <FontAwesomeIcon icon="image" size="lg" className="text-gray-500" />
          </div>
        )}
      </Link>
      <div className="mb-4">
        <p className="mb-2 text-xs text-gray-500">{vendor.vendorName}</p>
        <p className="leading-none">{offer.offerTitle}</p>
      </div>
      <Link to={`${vendor._id}/${offer._id}`}>
        <p className="text-sm font-semibold text-red-400">
          {offer.offerCtaText}
        </p>
      </Link>
    </div>
  );
}
