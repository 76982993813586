import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { InsuranceMarketplace } from "../../components/InsuranceMarketplace";
import Layout from "../../components/Layout";
import { Spinner } from "../../components/Spinner";
import { BaseLivlyApiResponse } from "../../types/Base";
import { OnboardingStatus, OnboardingStatusEnum } from "../../types/User";
import { trackInsuranceMarketplaceViewed } from "../../utils/analytics";
import { BASE_API_URL } from "../../utils/constants";
import { useGetStatusTypeDetails, usePostService } from "./landing";
import useLivlyUser from "../../context/UserProvider";
import { isNativeAppVersion, returnToNative } from "@/utils/nativeAppHelpers";

export interface InsuranceProvider {
  id: number;
  name: string;
  logoUri: string;
  heading: string;
  subHeading: string;
  url: string;
  skipUploadProof?: boolean;
}

async function getInsuranceMarketplaceVendors(buildingId: number) {
  const result = await axios.get<BaseLivlyApiResponse<InsuranceProvider[]>>(
    `${BASE_API_URL}/livly/users/marketPlaceInsuranceProviders/${buildingId}`
  );

  return result.data.Data;
}

const insuranceMarketplaceVendorsQuery = (buildingId: number) => ({
  queryKey: ["insurance-marketplace", buildingId],
  queryFn: async () => getInsuranceMarketplaceVendors(buildingId),
});

function InsuranceMarketplacePage() {
  const location = useLocation() as {
    state: { providers: InsuranceProvider[] };
  };
  const { user } = useLivlyUser();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    ...insuranceMarketplaceVendorsQuery(user.propertyId),
  });
  const details = useGetStatusTypeDetails(OnboardingStatusEnum.insurance, user);
  const {
    skipOnboardingStep,
    updateOnboardingStatus,
    isLoading: isSaving,
  } = usePostService(
    user.userId,
    user.propertyUnitLeaseId.toString(),
    details.onboardingStatus
  );

  const onDone = async () => {
    const newOnboardingStatus: OnboardingStatus = {
      ...details.onboardingStatus,
      isCompleted: true,
    };
    await updateOnboardingStatus(newOnboardingStatus);

    if (isNativeAppVersion()) {
      returnToNative();
      return;
    }

    navigate("..");
  };

  const onSkip = async () => {
    await skipOnboardingStep();

    navigate("..");
  };

  useEffect(() => {
    trackInsuranceMarketplaceViewed();
  }, []);

  return (
    <Layout
      title="Insurance Marketplace"
      back={{ label: "Move-in Checklist", to: ".." }}
    >
      {isLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <InsuranceMarketplace
          insuranceProviders={location.state?.providers ?? data ?? []}
          onSkip={onSkip}
          isLoading={isSaving}
          onDone={onDone}
        />
      )}
    </Layout>
  );
}

export { InsuranceMarketplacePage };
