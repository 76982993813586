import Layout from "@/components/Layout";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import useLivlyUser from "@/context/UserProvider";
import { BaseLivlyApiResponse } from "@/types/Base";
import { BASE_API_URL } from "@/utils/constants";
import Alert from "@/components/Alert";
import { Spinner } from "@/components/Spinner";
import {
  PollQuestionType,
  PollResult,
  PollUserResponseType,
  mapPollType,
  questionAnswersType,
} from "./polls/hooks/types";
import { useEffect, useMemo, useState } from "react";
import { Button } from "@/components/Button";
import PollQuestionItem from "./polls/PollQuestionItem";
import toLocalTime from "@/utils/toLocalTime";
import usePatchPollAnswer from "./polls/hooks/usePatchPollAnswer";

export default function ResidentPollPage() {
  const { user } = useLivlyUser();
  const params = useParams<{ pollId: string }>();
  const navigate = useNavigate();
  const [step, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState<questionAnswersType[]>([]);
  const [selectedQuestionId, setSelectedQuestionId] = useState<number | null>(
    0
  );
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [touched, setTouched] = useState(false);

  const { data, isError, isLoading, refetch } = useGetResidentPoll(
    user.propertyId,
    params.pollId!
  );

  const updateAnswer = usePatchPollAnswer(
    user.clientId,
    user.propertyId,
    Number(params.pollId)
  );

  const memorizedAnswers = useMemo(() => {
    const newAnswers: questionAnswersType[] = [];
    data?.questions.forEach((question) => {
      question.options.forEach((option) => {
        if (option.selected === true) {
          const selectedInfo: questionAnswersType = {
            residentPollQuestionId: question.residentPollQuestionId,
            other: option.otherText || null,
            type: mapPollType(question.type as any),
          };
          if (
            [
              PollQuestionType.MultiSelect,
              PollQuestionType.SingleSelect,
            ].includes(question.type)
          ) {
            selectedInfo.residentPollQuestionOptionId =
              option.residentPollQuestionOptionId;
          }
          const isOptionRepeated = newAnswers.some(
            (answer) =>
              answer.residentPollQuestionOptionId ===
                option.residentPollQuestionOptionId &&
              answer.residentPollQuestionId == question.residentPollQuestionId
          );

          if (!isOptionRepeated) {
            newAnswers.push(selectedInfo);
          }
        }
      });
    });

    return newAnswers;
  }, [data]);

  useEffect(() => {
    if (data) {
      setSelectedQuestionId(data.questions[0].residentPollQuestionId);
    }
    if (memorizedAnswers) {
      setAnswers(memorizedAnswers);
    }
  }, [data, memorizedAnswers]);

  const selectedQuestion = useMemo(
    () =>
      data?.questions.find(
        (resp) => resp.residentPollQuestionId === selectedQuestionId
      ),
    [data?.questions, selectedQuestionId]
  );

  const isButtonDisabled = useMemo(() => {
    return !answers.some(
      (item) =>
        item.residentPollQuestionId === selectedQuestion?.residentPollQuestionId
    );
  }, [answers, selectedQuestion]);

  if (data && !isLoading) {
    const total = data?.questions?.length ?? 0;

    const onSetAnswer = (questionId: number, answerId: any, other?: string) => {
      if (selectedQuestion?.type === PollQuestionType.SingleSelect) {
        let payload = answers;
        const checkQuestionPreset = answers.some(
          (item) => item.residentPollQuestionId == questionId
        );
        if (checkQuestionPreset) {
          payload.find(
            (item) => item.residentPollQuestionId === questionId
          )!.residentPollQuestionOptionId = answerId;
          setAnswers(payload);
        } else {
          if (answerId !== null) {
            setAnswers([
              ...answers,
              {
                residentPollQuestionId: questionId,
                residentPollQuestionOptionId: Number(answerId),
                other: null,
                type: mapPollType(selectedQuestion.type),
              },
            ]);
          } else {
            setAnswers([
              ...answers,
              {
                residentPollQuestionId: questionId,
                residentPollQuestionOptionId: null,
                other: null,
                type: mapPollType(selectedQuestion.type),
              },
            ]);
          }
        }
        setIsSubmitDisabled(false);
      } else if (selectedQuestion?.type === PollQuestionType.MultiSelect) {
        const checkQuestionNotPreset = answers.some(
          (item) =>
            item.residentPollQuestionId == questionId &&
            item.residentPollQuestionOptionId == answerId
        );
        if (checkQuestionNotPreset) {
          if (answerId !== null) {
            setAnswers(
              answers.filter(
                (item) => item.residentPollQuestionOptionId !== Number(answerId)
              )
            );
          } else {
            setAnswers(
              answers.filter(
                (item) => item.residentPollQuestionOptionId != answerId
              )
            );
          }
        } else {
          if (answerId !== null) {
            setAnswers([
              ...answers,
              {
                residentPollQuestionId: questionId,
                residentPollQuestionOptionId: Number(answerId),
                other: null,
                type: mapPollType(selectedQuestion.type),
              },
            ]);
          } else {
            setAnswers([
              ...answers,
              {
                residentPollQuestionId: questionId,
                residentPollQuestionOptionId: null,
                other: null,
                type: mapPollType(selectedQuestion.type),
              },
            ]);
          }
        }
      }
    };

    const updateOther = (questionId: number, other: string) => {
      setTouched(true);
      let payload = answers;
      if (selectedQuestion) {
        if (
          [
            PollQuestionType.MultiSelect,
            PollQuestionType.SingleSelect,
          ].includes(selectedQuestion.type)
        ) {
          const isFound = payload.find(
            (item) =>
              item.residentPollQuestionId === questionId &&
              item.residentPollQuestionOptionId === null
          );
          if (isFound) {
            payload.find(
              (item) =>
                item.residentPollQuestionId === questionId &&
                item.residentPollQuestionOptionId === null
            )!.other = other;
            setAnswers(payload);
          }
        } else {
          const isFound = payload.find(
            (item) => item.residentPollQuestionId === questionId
          );
          if (isFound) {
            payload.find(
              (item) => item.residentPollQuestionId === questionId
            )!.other = other;
            payload.find(
              (item) => item.residentPollQuestionId === questionId
            )!.type = mapPollType(selectedQuestion.type);
            setAnswers(payload);
          } else {
            setAnswers([
              ...answers,
              {
                residentPollQuestionId: questionId,
                other: other,
                type: mapPollType(selectedQuestion.type),
              },
            ]);
          }
        }
      }
    };
    const isExpired = toLocalTime(data.expirationTimestamp).isBefore(
      toLocalTime(new Date())
    );

    const handleNextOrDone = () => {
      if (step + 1 !== total) {
        const nextStep = step + 1;
        setCurrentStep(nextStep);
        const questionId = data?.questions[nextStep].residentPollQuestionId;

        setSelectedQuestionId(questionId ?? 0);
      } else {
        if (isExpired || !touched) {
          navigate(`/lease/${user.propertyUnitLeaseId}/my-feedback/polls`);
        } else {
          const payload: PollUserResponseType = {
            questionAnswers: [...answers],
          };
          updateAnswer.mutateAsync(payload, {
            onSuccess() {
              setTouched(false);
              refetch();
              navigate(`/lease/${user.propertyUnitLeaseId}/my-feedback/polls`);
            },
          });
        }
      }
    };
    const handlePrev = () => {
      setIsSubmitDisabled(false);
      const prevStep = step - 1;
      setCurrentStep(prevStep);
      const questionId = data?.questions[prevStep].residentPollQuestionId;
      setSelectedQuestionId(questionId ?? 0);
    };

    return (
      <Layout
        title={data?.title || "Community Activity Poll"}
        back={{ to: "../my-feedback/polls", label: "Polls" }}
      >
        {isError && (
          <Alert
            variant="danger"
            message="There was an error loading this poll"
          />
        )}
        <div className="flex w-full">
          <div className="flex-1">
            <PollQuestionItem
              step={step}
              total={total}
              onSelectQuestion={setSelectedQuestionId}
              selectedQuestion={selectedQuestion}
              userAnswers={answers}
              updateOther={updateOther}
              setAnswers={onSetAnswer}
              setIsSubmitDisabled={(item: boolean) => setIsSubmitDisabled(item)}
              isExpired={isExpired}
            />
          </div>
          {data.responsesHidden === false &&
            isExpired &&
            selectedQuestion &&
            [
              PollQuestionType.MultiSelect,
              PollQuestionType.SingleSelect,
            ].includes(selectedQuestion?.type) && (
              <div className="w-2/5 border-l border-gray-200 pl-4">
                <div className="mt-4 pt-5">
                  <ul>
                    {selectedQuestion?.options.map((option) => {
                      const percentage = option.responses
                        ? Math.floor(
                            (option.responses / data.totalResponses) * 100
                          )
                        : 0;

                      return (
                        <li
                          key={option.residentPollQuestionOptionId}
                          className="py-2"
                        >
                          <p className="font-medium">"{option.text}"</p>
                          <div className="mt-2 h-12 bg-[#FEE7E5] rounded-lg relative flex items-center ">
                            <div className="flex items-center gap-2 ml-4 z-10">
                              <p>{percentage}%</p>{" "}
                              <p>
                                ({option.responses}{" "}
                                {option.responses === 1
                                  ? "response"
                                  : "responses"}
                                )
                              </p>
                            </div>
                            <div
                              className="absolute top-0 left-0 bottom-0 bg-[#F9877E] rounded-lg"
                              style={{ width: `${percentage}%` }}
                            />
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
        </div>
        <div className="fixed z-[999] bottom-0 left-0 right-0 flex flex-col items-stretch m-4 bg-white border border-gray-100 rounded-lg shadow-lg md:flex-row md:justify-between md:items-baseline md:left-64 drop-shadow-lg">
          <div className="flex flex-col items-center justify-end w-full p-4 border-t border-gray-200 md:flex-row md:border-none">
            {step !== 0 && (
              <Button
                color="primary"
                onClick={() => handlePrev()}
                className="flex items-center  w-full gap-2 mt-4 md:w-auto md:mt-0 mr-4"
              >
                Prev
              </Button>
            )}
            <Button
              color="secondary"
              onClick={() => handleNextOrDone()}
              disabled={isButtonDisabled || isSubmitDisabled}
              className="flex items-center w-full gap-2 mt-4 md:w-auto md:mt-0"
            >
              {updateAnswer.isLoading && <Spinner />}
              {step + 1 !== total ? "Next" : isExpired ? "Close" : "Complete"}
            </Button>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <div className="h-screen w-full flex flex-1 justify-center items-center">
      <Spinner color="livly" size="lg" />
    </div>
  );
}

async function getResidentPoll(propertyId: number, pollId: string) {
  const result = await axios.get<BaseLivlyApiResponse<PollResult>>(
    `${BASE_API_URL}/resident/poll/property/${propertyId}/poll/${pollId}/response`
  );

  return result.data.Data;
}

function useGetResidentPoll(propertyId: number, pollId: string) {
  return useQuery({
    queryKey: ["resident", "polls", propertyId, pollId],
    queryFn: () => getResidentPoll(propertyId, pollId),
  });
}
