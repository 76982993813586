import React, { useState } from "react";
import Payment from "payment";
import { textInputClass } from "./Form";
import {
  formatCVC,
  formatCreditCardNumber,
  formatExpirationDate,
} from "@/utils/cardHelpers";

export type CreditCardDetails = {
  number: string;
  expiry: string;
  cvc: string;
};

const CreditCardComponent = ({
  cardDetails,
  setCardDetails,
}: {
  cardDetails: CreditCardDetails;
  setCardDetails: (deets: CreditCardDetails) => void;
}) => {
  const [cardNumberError, setCardNumberError] = useState<string>("");
  const [expiryError, setExpiryError] = useState<string>("");
  const [cvcError, setCvcError] = useState<string>("");

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const number = formatCreditCardNumber(e.target.value);
    setCardDetails({ ...cardDetails, number });
  };

  const handleExpirationDateChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const expiry = formatExpirationDate(e.target.value);
    setCardDetails({ ...cardDetails, expiry });
  };

  const handleCvcChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cvc = formatCVC(e.target.value, cardDetails.number);
    setCardDetails({ ...cardDetails, cvc });
  };

  const handleCardNumberBlur = () => {
    const isValid = Payment.fns.validateCardNumber(cardDetails.number);
    setCardNumberError(isValid ? "" : "Invalid card number");

    if (cardDetails.cvc) {
      handleCvcBlur();
    }
  };

  const handleExpirationDateBlur = () => {
    const isValid = Payment.fns.validateCardExpiry(cardDetails.expiry);
    setExpiryError(isValid ? "" : "Invalid expiration date");
  };

  const handleCvcBlur = () => {
    const cardType = Payment.fns.cardType(cardDetails.number);
    const isValid = Payment.fns.validateCardCVC(cardDetails.cvc, cardType);
    setCvcError(isValid ? "" : "Invalid CVC");
  };

  return (
    <div>
      <input
        type="tel"
        pattern="[\d| ]{16,22}"
        value={cardDetails.number}
        onChange={handleCardNumberChange}
        onBlur={handleCardNumberBlur}
        className={textInputClass}
        placeholder="Card Number"
      />
      {cardNumberError && (
        <span className="text-sm text-red-600">{cardNumberError}</span>
      )}
      <div className="flex gap-4 mt-4">
        <div className="flex-1">
          <input
            type="tel"
            value={cardDetails.expiry}
            onChange={handleExpirationDateChange}
            onBlur={handleExpirationDateBlur}
            className={textInputClass}
            placeholder="Valid Thru"
            pattern="\d\d/\d\d"
          />
          {expiryError && (
            <span className="text-sm text-red-600">{expiryError}</span>
          )}
        </div>
        <div className="flex-1">
          <input
            type="tel"
            value={cardDetails.cvc}
            onChange={handleCvcChange}
            onBlur={handleCvcBlur}
            className={textInputClass}
            placeholder="CVC"
            pattern="\d{3,4}"
          />
          {cvcError && <span className="text-sm text-red-600">{cvcError}</span>}
        </div>
      </div>
    </div>
  );
};

export default CreditCardComponent;
