import { Formik, Field, Form } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { z } from "zod";
import { PetResponse } from "../routes/pets/pets";
import { Button } from "./Button";
import { FormField, TextArea, textInputClass, Toggle } from "./Form";
import { ChangeEvent, Fragment, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isValidFormat, isValidSize } from "../utils/image";
import Alert from "./Alert";
import ReactCropper from "react-cropper";

import "cropperjs/dist/cropper.css";
import { Dialog, Transition } from "@headlessui/react";

export const PetSchema = z
  .object({
    name: z.string().min(1),
    breed: z.string().min(1),
    petTypeRadio: z.enum(["Dog", "Cat", ""]).optional(),
    petType: z.string().optional(),
    base64Photo: z.string().optional(),
    weight: z.string(),
    notes: z.string().optional(),
    serviceAnimal: z.boolean(),
    gender: z.string(),
    birthYear: z.string(),
    photoUri: z.string().nullable(),
  })
  .superRefine((values, ctx) => {
    if (
      (values.petTypeRadio === "" || values.petTypeRadio === undefined) &&
      (values.petType === "" || values.petType === undefined)
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["petTypeRadio"],
        message: "Pet type is required",
      });
    }
  });

export type PetRequest = z.infer<typeof PetSchema>;

type Props = {
  onSubmit: (pet: PetRequest) => Promise<void> | void;
  error: any;
  isLoading: boolean;
  initialPet?: PetResponse;
  returnTo: string;
};

export default function PetForm({
  onSubmit,
  error,
  isLoading,
  initialPet,
  returnTo,
}: Props) {
  const [serviceAnimal, setServiceAnimal] = useState(
    initialPet?.serviceAnimal ?? false
  );

  let initialValues: PetRequest = {
    name: "",
    breed: "",
    weight: "",
    petTypeRadio: undefined,
    petType: "",
    base64Photo: "",
    gender: "",
    birthYear: "",
    notes: "",
    serviceAnimal: false,
    photoUri: null,
  };

  if (initialPet) {
    initialValues = {
      ...initialPet,
      photoUri: initialPet.photoUri,
      birthYear: (initialPet.birthYear ?? "").toString(),
      petTypeRadio:
        initialPet.petType === "Dog"
          ? "Dog"
          : initialPet.petType === "Cat"
          ? "Cat"
          : "",
    };
  }
  const [base64Photo, setBase64Photo] = useState("");
  const [imageError, setError] = useState("");
  const [image, setImage] = useState<string | null>(null);
  const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setError("");
    let hasError = false;
    const files = event.target.files;

    if (files && files.length) {
      const file = files[0] as File;

      if (!isValidSize(file.size, 5)) {
        hasError = true;
        setError("File must be less than 5mb");
      }

      if (!isValidFormat(file.name.split(".").pop(), ["png", "jpg", "jpeg"])) {
        hasError = true;
        setError("File must be in a valid format");
      }

      if (hasError) {
        return;
      }

      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSubmit = (pet: PetRequest) => {
    let petRequestModel = { ...pet, serviceAnimal };

    if (base64Photo) {
      petRequestModel = {
        ...petRequestModel,
        base64Photo: base64Photo.replace("data:image/png;base64,", ""),
      };
    }

    onSubmit(petRequestModel);
  };

  const handleImageSave = (img: string) => {
    setBase64Photo(img);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={toFormikValidationSchema(PetSchema)}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, values }) => (
        <Form>
          {error?.data?.Errors && (
            <div className="p-4 my-2 bg-red-100 border-l-4 border-red-500 rounded-md">
              {JSON.stringify(error?.data?.Errors, null, 2)}
            </div>
          )}
          {imageError && (
            <div className="mb-2">
              <Alert variant="danger" message={imageError} />
            </div>
          )}
          <FormField
            htmlFor="photoUri"
            label=""
            className="flex justify-center"
          >
            <label className="flex justify-center cursor-pointer">
              {base64Photo ? (
                <img src={base64Photo} className="w-32 h-32 rounded-full" />
              ) : values.photoUri ? (
                <img src={values.photoUri} className="w-32 h-32 rounded-full" />
              ) : (
                <div className="flex items-center justify-center w-32 h-32 bg-gray-200 rounded-full hover:bg-gray-300">
                  <FontAwesomeIcon
                    icon="camera"
                    className="text-4xl text-gray-700"
                  />
                </div>
              )}
              <input
                type="file"
                className="hidden"
                accept="image/*,image/heif,image/heic"
                onChange={onFileInputChange}
              />
            </label>
          </FormField>
          <PetAvatar
            image={image}
            onClose={() => setImage(null)}
            onSave={handleImageSave}
          />
          <FormField
            htmlFor="name"
            label="Name"
            showErrorIcon={errors.name && touched.name}
          >
            <Field
              id="name"
              name="name"
              placeholder="Buster"
              type="text"
              className={textInputClass}
            />
          </FormField>

          <FormField
            htmlFor="petTypeRadio"
            label="Pet Type"
            showErrorIcon={errors.petTypeRadio && touched.petTypeRadio}
          >
            <div className="flex gap-8">
              {[
                ["Dog", "Dog"],
                ["Cat", "Cat"],
                ["", "Other"],
              ].map(([value, label]) => (
                <label className="flex items-center" key={value}>
                  <Field
                    id="petTypeRadio"
                    name="petTypeRadio"
                    type="radio"
                    value={value}
                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                  />
                  <span className="inline-block ml-4">{label}</span>
                </label>
              ))}
            </div>
            {values.petTypeRadio === "" && (
              <div>
                <Field
                  id="petType"
                  name="petType"
                  type="text"
                  placeholder="Hamster, Bird, etc"
                  className={textInputClass}
                />
              </div>
            )}
          </FormField>

          <FormField htmlFor="gender" label="Gender">
            <div className="flex gap-8">
              {[
                ["male", "Male"],
                ["female", "Female"],
              ].map(([value, label]) => (
                <label className="flex items-center" key={value}>
                  <Field
                    id="gender"
                    name="gender"
                    type="radio"
                    value={value}
                    className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                  />
                  <span className="inline-block ml-4">{label}</span>
                </label>
              ))}
            </div>
          </FormField>

          <FormField
            htmlFor="birthYear"
            label="Birth Year"
            showErrorIcon={errors.birthYear && touched.birthYear}
          >
            <Field
              id="birthYear"
              name="birthYear"
              type="text"
              className={textInputClass}
            />
          </FormField>

          <FormField
            htmlFor="breed"
            label="Breed"
            showErrorIcon={errors.breed && touched.breed}
          >
            <Field
              id="breed"
              name="breed"
              type="text"
              className={textInputClass}
            />
          </FormField>

          <FormField
            htmlFor="serviceAnimal"
            label="Is the animal required because of a disability?"
            className="flex flex-col justify-between gap-2 md:justify-start"
          >
            <div className="flex items-center gap-2">
              <Toggle checked={serviceAnimal} onChange={setServiceAnimal} />
              <span>{serviceAnimal ? "Yes" : "No"}</span>
            </div>
          </FormField>

          <FormField
            htmlFor="weight"
            label="Weight"
            showErrorIcon={errors.weight && touched.weight}
          >
            <Field
              id="weight"
              name="weight"
              type="text"
              className={textInputClass}
            />
          </FormField>

          <FormField htmlFor="notes" label="Add pet notes">
            <TextArea id="notes" name="notes" />
          </FormField>

          <div className="flex justify-end gap-2 mt-4">
            <Link to={returnTo}>
              <Button size="small" color="default">
                Discard Changes
              </Button>
            </Link>
            <Button
              size="small"
              color="primary"
              type="submit"
              disabled={isLoading}
            >
              {initialPet?.id ? "Update" : "Add"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

function PetAvatar({
  image,
  onClose,
  onSave,
}: {
  image: string | null;
  onClose: () => void;
  onSave: (img: string) => void;
}) {
  const [cropper, setCropper] = useState<Cropper | null>(null);
  const cancelButtonRef = useRef(null);

  const handleSave = () => {
    if (cropper === null) {
      return;
    }

    const image = cropper.getCroppedCanvas().toDataURL();

    onSave(image);
    onClose();
  };

  return (
    <Transition.Root show={image !== null} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center w-full min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl md:min-w-lg sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Update profile picture
                </Dialog.Title>
                {image ? (
                  <div className="flex flex-col items-center justify-center">
                    <div className="max-w-lg mt-4">
                      <ReactCropper
                        aspectRatio={1}
                        src={image}
                        guides={false}
                        dragMode="move"
                        background={false}
                        viewMode={1}
                        responsive
                        center={false}
                        cropBoxMovable={false}
                        cropBoxResizable={false}
                        onInitialized={(c) => {
                          setCropper(c);
                        }}
                      />
                    </div>

                    <div className="flex gap-1 mt-2">
                      <span>
                        <button
                          className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100 disabled:hover:bg-white disabled:cursor-not-allowed group"
                          onClick={() => cropper?.zoom(-0.1)}
                        >
                          <FontAwesomeIcon
                            icon="minus"
                            className="group-disabled:text-gray-300"
                          />
                        </button>
                      </span>
                      <button
                        className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100 disabled:hover:bg-white disabled:cursor-not-allowed group"
                        onClick={() => cropper?.zoom(0.1)}
                      >
                        <FontAwesomeIcon
                          icon="plus"
                          className="group-disabled:text-gray-300"
                        />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div />
                )}
                <div className="flex justify-end gap-2 mt-5">
                  <Button
                    color="default"
                    type="button"
                    onClick={onClose}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    onClick={handleSave}
                    disabled={image === null}
                  >
                    Save
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
