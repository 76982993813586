import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Link } from "react-router-dom";
import Layout from "../components/Layout";
import { BASE_API_URL } from "../utils/constants";
import formatCurrency from "../utils/formatCurrency";
import { BaseLivlyApiResponse } from "../types/Base";
import { BookingAvailabilityType } from "../types/Bookings";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "../components/Spinner";
import useLivlyUser from "../context/UserProvider";

export type AmenitySpaceResponse = {
  amenitySpaceId: number;
  propertyId: number;
  propertyName: string;
  timezone: string;
  status: "Draft" | "Published";
  name: string;
  description: string;
  rules: string;
  images: {
    amenitySpaceImageId: number;
    amenitySpaceId: number;
    ordinal: number;
    uri: string;
  }[];
  availabilities: {
    amenitySpaceAvailabilityId: number;
    amenitySpaceId: number;
    dayOfWeek: number;
    start: number;
    stop: number;
    allDay: boolean;
  }[];
  bookingAvailabilityType: BookingAvailabilityType;
  paymentFeeResponsibility: "Resident";
  price: number;
  maxDuration: number;
  minDuration: number;
  maxCapacity: number;
  displayAvailabilityInDays: number;
  isAutoApproval: boolean;
  lastUpdated: string | Date;
  isDeleted: true;
  slotCount: number;
  unlimitedSlots: boolean;
  waiver: {
    amenitySpaceWaiverId: number;
    amenitySpaceId: number;
    required: boolean;
    disclaimer: string;
  };
  userBookingsLimit: number;
  unlimitedUserBookings: boolean;
  color: string;
  userBookingsLimitInterval: number;
  isAvailableDuringOnboarding: boolean;
  checkoutTime: string;
  checkinTime: string;
  correlationId: string;
  paymentRequired: boolean;
  isOpenAmenity: boolean;
};

async function getAmenitySpaces(propertyId: number) {
  const result = await axios.get<BaseLivlyApiResponse<AmenitySpaceResponse[]>>(
    `${BASE_API_URL}/livly/amenity/space/property/${propertyId}/full?includeGrouped=true`
  );

  const amenities = result.data.Data.filter(
    (space) => space.status === "Published"
  );

  return amenities;
}

export const amenitySpacesQuery = (propertyId: number, leaseId: number) => ({
  queryKey: ["amenity-spaces", propertyId, leaseId],
  queryFn: async () => getAmenitySpaces(propertyId),
});

export default function AmenitiesPage() {
  const { user } = useLivlyUser();
  const { data, isLoading } = useQuery({
    ...amenitySpacesQuery(user.propertyId, user.propertyUnitLeaseId),
  });

  return (
    <Layout title="Amenities">
      {isLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <>
          <h2 className="mb-4">
            Browse available amenities and submit a booking request.
          </h2>
          <div className="grid lg:grid-cols-5 lg:gap-4">
            {data?.map((amenity) => {
              return (
                <div key={amenity.amenitySpaceId} className="flex flex-col">
                  <Link
                    to={`../amenities/${
                      user.propertyId
                    }/${amenity.amenitySpaceId.toString()}`}
                    className="flex flex-row flex-1 mb-4 shadow-none text-inherit lg:shadow-md lg:flex-col lg:rounded-xl lg:border-gray-400 lg:hover:bg-gray-50"
                  >
                    {amenity.images.length > 0 ? (
                      <img
                        className="flex-shrink-0 object-contain w-24 lg:w-full lg:h-36 lg:object-cover lg:rounded-tl-xl lg:rounded-tr-xl"
                        src={amenity.images[0].uri}
                        alt={amenity.name}
                      />
                    ) : (
                      <div className="flex items-center justify-center flex-shrink-0 w-24 bg-gray-400 lg:w-full lg:h-36 lg:rounded-tl-xl lg:rounded-tr-xl">
                        <FontAwesomeIcon icon="building" size="3x" />
                      </div>
                    )}
                    <div className="flex-1 h-24 p-4 ml-3 border-b border-gray-400 lg:border-none lg:ml-0">
                      <p>{amenity.name}</p>
                      {amenity.price > 0 ? (
                        <p className="text-sm font-light">
                          {formatCurrency(amenity.price).replace(".00", "")}
                          {"/"}
                          {amenity.bookingAvailabilityType ===
                          BookingAvailabilityType.Daily
                            ? "hourly"
                            : "nightly"}
                        </p>
                      ) : null}
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </>
      )}
    </Layout>
  );
}
