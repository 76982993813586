export interface RentSettings extends RentSettingsBase {
  propertyUnitLeaseId: number;
  propertyId: number;
  fixedAmountAllowed: boolean;
  fullBalanceAllowed: boolean;
  maxPaymentAmountAllowed: number;
  autoPayMaxAmountAllowed: number;
  isPaymentAllowed: boolean;
  monthlyRecurringFreeFormBalanced: RentSettingsBase;
}

export interface RentSettingsBase {
  daysAutoPayAllowed: number[];
  isActive: boolean;
  rentBalanceType: RentBalanceTypeEnum;
  rentSettingType: RentSettingTypeEnum;
}

export enum RentSettingTypeEnum {
  FirstMonth,
  MonthlyRecurring,
}

export enum RentBalanceTypeEnum {
  BalanceBased,
  FreeFormFixedAmount,
  FreeFormWithBalance,
}

export type RentHistoryItem = {
  transactionId: number;
  amount: number;
  fee: number;
  formattedAmount: string;
  formattedFee: string;
  paymentMethodId: number;
  status: string;
  transactionDate: string;
  postedDate: string;
  payerUserId: number;
  isAutoPay: boolean;
  transactionTypeId: number;
  chargeCode: string;
  description: string;
  notes: string;
};

export type RentAutoPay = {
  autoPayDay: number | null;
  daysAutoPayAllowed: number[];
  endDate: string | null;
  fixedAmount: number | null;
  id: number | null;
  isFullStatementBalance: boolean | null;
  isRoommateAutopaying: boolean;
  isRoommateAutopayingFixedAmount: boolean;
  isRoommateAutopayingFullBalance: boolean;
  message: string | null;
  modifiedAt: string | null;
  nextBillingDate: string | null;
  nextWindow: {
    startDate: string;
    endDate: string;
  };
  overrideEndDateRestriction: boolean;
  paymentAccountId: number | null;
  roommateAutoPayments: RoommateAutoPayments[];
  startDate: string | null;
  rentBalanceTypeId: RentBalanceTypeEnum;
};

export interface RoommateAutoPayments {
  userId: number;
  firstName: string;
  lastName: string;
  avatarUri: string;
  amount: number;
  autoPayDay: number;
  isFullStatementBalance: boolean;
}

export type LedgerStatus =
  | "Paid"
  | "Unpaid"
  | "Posted"
  | "Overdue"
  | "Ready"
  | "NotReady"
  | "Unknown"
  | "ChargesRequired";
