import React, {
  useCallback,
  useContext,
  useMemo,
  createContext,
  useState,
} from "react";

import { PlaidLinkError } from "react-plaid-link";
import axios from "axios";
import { BaseLivlyApiResponse } from "../types/Base";
import { BASE_API_URL } from "../utils/constants";

export interface LinkTokenResponse {
  Exception: {};
  link_token: string;
  expiration: string | Date;
  request_id: string;
  StatusCode: number;
  IsSuccessStatusCode: boolean;
}

interface LinkState {
  linkToken: string;
  error: PlaidLinkError;
}

const initialState = {
  linkToken: "",
  error: {},
};

interface LinkContextShape extends LinkState {
  generateLinkToken: (userId: number) => void;
}
const LinkContext = createContext<LinkContextShape>(
  initialState as LinkContextShape
);

async function getLinkToken() {
  const result = await axios.get<BaseLivlyApiResponse<LinkTokenResponse>>(
    `${BASE_API_URL}/livly/payment/plaidLinkToken`
  );

  return result.data.Data;
}

/**
 * @desc Maintains the Link context state and fetches link tokens to update that state.
 */
export function LinkProvider(props: any) {
  const [linkToken, setToken] = useState("");
  const [error, setError] = useState("");

  /**
   * @desc Creates a new link token for a given User or Item.
   */

  const generateLinkToken = useCallback(async () => {
    // if itemId is not null, update mode is triggered
    const linkTokenResponse = await getLinkToken();

    if (linkTokenResponse.link_token) {
      const token = await linkTokenResponse.link_token;
      setToken(token);
    } else {
      setError(linkTokenResponse.StatusCode + "");
    }
  }, []);

  const value = useMemo(
    () => ({
      generateLinkToken,
      linkToken,
      error,
    }),
    [linkToken, error, generateLinkToken]
  );

  return <LinkContext.Provider value={value} {...props} />;
}

/**
 * @desc A convenience hook to provide access to the Link context state in components.
 */
export default function useLink() {
  const context = useContext(LinkContext);
  if (!context) {
    throw new Error(`useLink must be used within a LinkProvider`);
  }

  return context;
}
