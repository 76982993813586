import { useNavigate, useParams } from "react-router-dom";
import { PetsList } from "../pets/pets";
import useLivlyUser from "../../context/UserProvider";
import Layout from "../../components/Layout";
import { Button, ButtonWrapper } from "../../components/Button";
import { useGetStatusTypeDetails, usePostService } from "./landing";
import { OnboardingStatus, OnboardingStatusEnum } from "../../types/User";
import { usePetsVehiclesService } from "./pets-vehicles";
import { isNativeAppVersion, returnToNative } from "@/utils/nativeAppHelpers";

export default function ChecklistPetsPage() {
  const { user } = useLivlyUser();
  const params = useParams<{ leaseId: string }>();
  const details = useGetStatusTypeDetails(
    OnboardingStatusEnum.petAndVehicles,
    user
  );
  const navigate = useNavigate();
  const { vehiclesEnabled } = usePetsVehiclesService(
    details.onboardingStatus.metaData
  );

  const { updateOnboardingStatus, isLoading } = usePostService(
    user.userId,
    params.leaseId!,
    details.onboardingStatus
  );

  const onNext = async () => {
    if (vehiclesEnabled) {
      return navigate(`../vehicles`);
    }

    const newOnboardingStatus: OnboardingStatus = {
      ...details.onboardingStatus,
      isCompleted: true,
    };
    await updateOnboardingStatus(newOnboardingStatus);

    if (isNativeAppVersion()) {
      returnToNative();
      return;
    }

    navigate("..");
  };

  return (
    <Layout title="Pets" back={{ label: "Move-in Checklist", to: -1 }}>
      <PetsList leaseId={params.leaseId!} userId={user.userId} />
      <ButtonWrapper>
        <Button
          onClick={onNext}
          className="w-full md:w-auto"
          color="secondary"
          disabled={isLoading}
        >
          {vehiclesEnabled ? "Next" : "Confirm"}
        </Button>
      </ButtonWrapper>
    </Layout>
  );
}
