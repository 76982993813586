import axios from "axios";
import { clearLocalStorage } from "./localStorage";

if (axios.interceptors) {
  axios.interceptors.request.use((request) => {
    const hrefSplit = window.location.href.split("/");
    const leaseSlice = hrefSplit.indexOf("lease");

    if (leaseSlice !== -1) {
      const leaseId = hrefSplit[leaseSlice + 1];

      if (leaseId) {
        if (request.headers) {
          request.headers["X-LEASE-ID"] = leaseId;
        } else {
          request.headers = { "X-LEASE-ID": leaseId };
        }
      }
    }

    return request;
  });
  axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response;
    },
    function (error) {
      if (error?.response?.status === 401) {
        handleUnauthorizedError();
      }
      if (axios.isCancel(error)) {
        return Promise.reject(error);
      }

      return Promise.reject(error.response);
    }
  );
}

function handleUnauthorizedError() {
  clearLocalStorage();
  window.location.href = window.origin + "/login";
}

export default axios;
