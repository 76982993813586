import Layout from "@/components/Layout";
import useLivlyUser from "@/context/UserProvider";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useGetPollQuestions from "./hooks/useGetPollQuestions";
import {
  PollQuestionType,
  PollUserResponseType,
  mapPollType,
  questionAnswersType,
} from "./hooks/types";
import { Spinner } from "@/components/Spinner";
import PollQuestionItem from "./PollQuestionItem";
import { Button } from "@/components/Button";
import usePostPollAnswer from "./hooks/usePostPollAnswer";
import { useLeaseTasks } from "@/components/Tasks";

function Track({
  percentage,
  step,
  total,
}: {
  percentage: number;
  step: number;
  total: number;
}) {
  return (
    <div className="flex items-center gap-4 mt-4">
      <div className="flex items-center h-1 bg-gray-200 rounded-lg w-52">
        <div
          className="h-1 bg-red-400 rounded-tl-lg rounded-bl-lg"
          style={{ width: `${percentage}%` }}
        />
      </div>
      {total > 1 && (
        <span className="text-sm text-gray-500">
          QUESTION {step + 1}/{total}
        </span>
      )}
    </div>
  );
}

export const Poll = () => {
  const params = useParams<{
    leaseId: string;
    pollId: string;
  }>();
  const { user } = useLivlyUser();
  const navigate = useNavigate();
  const { refetch } = useLeaseTasks(Number(params.leaseId));

  const [step, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState<questionAnswersType[]>([]);
  const [selectedQuestionId, setSelectedQuestionId] = useState<number | null>(
    0
  );
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  const { data, isLoading } = useGetPollQuestions(
    user.clientId,
    user.propertyId,
    Number(params.pollId),
    Number(params.leaseId)
  );

  const postAnswer = usePostPollAnswer(
    user.clientId,
    user.propertyId,
    Number(params.pollId)
  );

  useEffect(() => {
    if (data) {
      setSelectedQuestionId(data.questions[0].residentPollQuestionId);
    }
  }, [data]);

  const selectedQuestion = useMemo(
    () =>
      data?.questions.find(
        (resp) => resp.residentPollQuestionId === selectedQuestionId
      ),
    [data?.questions, selectedQuestionId]
  );

  const isButtonDisabled = useMemo(() => {
    return !answers.some(
      (item) =>
        item.residentPollQuestionId === selectedQuestion?.residentPollQuestionId
    );
  }, [answers, selectedQuestion]);

  if (data || !isLoading) {
    const total = data?.questions?.length ?? 0;

    let percentage = Math.floor(((step + 1) / total) * 100);

    const onSetAnswer = (questionId: number, answerId: any, other?: string) => {
      if (selectedQuestion?.type === PollQuestionType.SingleSelect) {
        let payload = answers;
        const checkQuestionPreset = answers.some(
          (item) => item.residentPollQuestionId == questionId
        );
        if (checkQuestionPreset) {
          payload.find(
            (item) => item.residentPollQuestionId === questionId
          )!.residentPollQuestionOptionId = answerId;
          setAnswers(payload);
        } else {
          if (answerId !== null) {
            setAnswers([
              ...answers,
              {
                residentPollQuestionId: questionId,
                residentPollQuestionOptionId: Number(answerId),
                other: null,
                type: mapPollType(selectedQuestion.type),
              },
            ]);
          } else {
            setAnswers([
              ...answers,
              {
                residentPollQuestionId: questionId,
                residentPollQuestionOptionId: null,
                other: null,
                type: mapPollType(selectedQuestion.type),
              },
            ]);
          }
        }
        setIsSubmitDisabled(false);
      } else if (selectedQuestion?.type === PollQuestionType.MultiSelect) {
        const checkQuestionNotPreset = answers.some(
          (item) =>
            item.residentPollQuestionId == questionId &&
            item.residentPollQuestionOptionId == answerId
        );
        if (checkQuestionNotPreset) {
          if (answerId !== null) {
            setAnswers(
              answers.filter(
                (item) => item.residentPollQuestionOptionId !== Number(answerId)
              )
            );
          } else {
            setAnswers(
              answers.filter(
                (item) => item.residentPollQuestionOptionId != answerId
              )
            );
          }
        } else {
          if (answerId !== null) {
            setAnswers([
              ...answers,
              {
                residentPollQuestionId: questionId,
                residentPollQuestionOptionId: Number(answerId),
                other: null,
                type: mapPollType(selectedQuestion.type),
              },
            ]);
          } else {
            setAnswers([
              ...answers,
              {
                residentPollQuestionId: questionId,
                residentPollQuestionOptionId: null,
                other: null,
                type: mapPollType(selectedQuestion.type),
              },
            ]);
          }
        }
      }
    };

    const updateOther = (questionId: number, other: string) => {
      let payload = answers;
      if (selectedQuestion) {
        if (
          [
            PollQuestionType.MultiSelect,
            PollQuestionType.SingleSelect,
          ].includes(selectedQuestion.type)
        ) {
          const isFound = payload.find(
            (item) =>
              item.residentPollQuestionId === questionId &&
              item.residentPollQuestionOptionId === null
          );
          if (isFound) {
            payload.find(
              (item) =>
                item.residentPollQuestionId === questionId &&
                item.residentPollQuestionOptionId === null
            )!.other = other;
            setAnswers(payload);
          }
        } else {
          const isFound = payload.find(
            (item) => item.residentPollQuestionId === questionId
          );
          if (isFound) {
            payload.find(
              (item) => item.residentPollQuestionId === questionId
            )!.other = other;
            payload.find(
              (item) => item.residentPollQuestionId === questionId
            )!.type = mapPollType(selectedQuestion.type);
            setAnswers(payload);
          } else {
            setAnswers([
              ...answers,
              {
                residentPollQuestionId: questionId,
                other: other,
                type: mapPollType(selectedQuestion.type),
              },
            ]);
          }
        }
      }
    };

    const handleNextOrDone = () => {
      if (step + 1 !== total) {
        const nextStep = step + 1;
        setCurrentStep(nextStep);
        const questionId = data?.questions[nextStep].residentPollQuestionId;

        setSelectedQuestionId(questionId ?? 0);
      } else {
        const payload: PollUserResponseType = {
          questionAnswers: [...answers],
        };
        postAnswer.mutateAsync(payload, {
          onSuccess() {
            refetch();
            navigate(`/lease/${user.propertyUnitLeaseId}/polls/thank-you`, {
              state: { pollName: data?.title ?? "" },
            });
          },
        });
      }
    };

    const handlePrev = () => {
      setIsSubmitDisabled(false);
      const prevStep = step - 1;
      setCurrentStep(prevStep);
      const questionId = data?.questions[prevStep].residentPollQuestionId;
      setSelectedQuestionId(questionId ?? 0);
    };

    return (
      <Layout
        title={data?.title ?? "Community Activity Poll"}
        back={{
          to: `/lease/${params.leaseId}/home`,
          label: "Home",
        }}
      >
        {total > 1 && (
          <Track percentage={percentage || 0} step={step} total={total} />
        )}
        <PollQuestionItem
          step={step}
          total={total}
          onSelectQuestion={setSelectedQuestionId}
          selectedQuestion={selectedQuestion}
          userAnswers={answers}
          updateOther={updateOther}
          setAnswers={onSetAnswer}
          setIsSubmitDisabled={(item: boolean) => setIsSubmitDisabled(item)}
        />
        <div className="fixed bottom-0 left-0 right-0 flex flex-col items-stretch m-4 bg-white border border-gray-100 rounded-lg shadow-lg md:flex-row md:justify-between md:items-baseline md:left-64 drop-shadow-lg">
          <div className="flex flex-col items-center justify-end w-full p-4 border-t border-gray-200 md:flex-row md:border-none">
            {step !== 0 && (
              <Button
                color="primary"
                onClick={() => handlePrev()}
                className="flex items-center  w-full gap-2 mt-4 md:w-auto md:mt-0 mr-4"
              >
                Prev
              </Button>
            )}
            <Button
              color="secondary"
              onClick={() => handleNextOrDone()}
              disabled={isButtonDisabled || isSubmitDisabled}
              className="flex items-center w-full gap-2 mt-4 md:w-auto md:mt-0"
            >
              {postAnswer.isLoading && <Spinner />}
              {step + 1 !== total ? "Next" : "Complete"}
            </Button>
          </div>
        </div>
      </Layout>
    );
  }

  return (
    <div className="h-full flex flex-1 justify-center items-center">
      <Spinner color="livly" size="xl" />
    </div>
  );
};
