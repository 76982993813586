import { useParams, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { LayoutBody, LayoutHeader } from "../../components/Layout";
import { OnboardingStatus, OnboardingStatusEnum } from "../../types/User";
import { useGetStatusTypeDetails, usePostService } from "./landing";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import "cropperjs/dist/cropper.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { BASE_API_URL } from "../../utils/constants";
import { BaseLivlyApiResponse } from "../../types/Base";
import ReactCropper from "react-cropper";

import "cropperjs/dist/cropper.css";
import Alert from "../../components/Alert";
import { isValidFormat, isValidSize } from "../../utils/image";
import { trackAddProfilePicture } from "../../utils/analytics";
import { Spinner } from "../../components/Spinner";
import useLivlyUser from "../../context/UserProvider";
import { useMutation } from "@tanstack/react-query";
import { isNativeAppVersion, returnToNative } from "@/utils/nativeAppHelpers";
import { isAndroid } from "react-device-detect";

const postUserAvatar = async (userId: string, base64Image: string) => {
  const result = await axios.post<BaseLivlyApiResponse<{ avatarURI: string }>>(
    `${BASE_API_URL}/livly/users/me/avatar`,
    {
      userId,
      base64Image: base64Image.replace("data:image/png;base64,", ""),
    }
  );

  return result.data.Data;
};

const usePostAvatar = (userId: string) =>
  useMutation({
    mutationFn: (base64Image: string) => postUserAvatar(userId, base64Image),
  });

export default function ChecklistAvatarPage() {
  const params = useParams<{ userId: string; leaseId: string }>();
  const addAvatarMutation = usePostAvatar(params.userId!);

  const navigate = useNavigate();
  const { user } = useLivlyUser();
  const details = useGetStatusTypeDetails(OnboardingStatusEnum.profile, user);
  const { updateOnboardingStatus, isLoading } = usePostService(
    user.userId,
    params.leaseId!,
    details.onboardingStatus
  );

  const [error, setError] = useState("");
  const [cropper, setCropper] = useState<Cropper | null>(null);
  const editor = useRef<HTMLImageElement | null>(null);

  const [mode, setMode] = useState<"initial" | "preview">("initial");
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [image, setImage] = useState<string | null>(null);

  const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setError("");
    let hasError = false;
    const files = event.target.files;

    if (files && files.length) {
      const file = files[0] as File;

      if (!isValidSize(file.size, 5)) {
        hasError = true;
        setError("File must be less than 5mb");
      }

      if (!isValidFormat(file.name.split(".").pop(), ["png", "jpg", "jpeg"])) {
        hasError = true;
        setError("File must be in a valid format");
      }

      if (hasError) {
        return;
      }

      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result as string);

          if (isAndroid && isNativeAppVersion()) {
            setMode("preview");
            setPreviewImage(reader.result as string);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const onTogglePreviewMode = () => {
    setMode("preview");

    if (!cropper) {
      return;
    }

    const { height, width } = cropper.getCropBoxData();

    const image = cropper.getCroppedCanvas({ height, width }).toDataURL();

    setPreviewImage(image);
  };

  const onNext = async () => {
    try {
      if (previewImage == null) {
        return;
      }

      const avatar = previewImage.split(";base64,")[1] ?? "";
      await addAvatarMutation.mutateAsync(avatar);
      trackAddProfilePicture();
      const newOnboardingStatus: OnboardingStatus = {
        ...details.onboardingStatus,
        isCompleted: true,
      };
      await updateOnboardingStatus(newOnboardingStatus);

      if (isNativeAppVersion()) {
        returnToNative();
        return;
      }

      navigate("..");
    } catch (e) {
      const mutationError = error as { data?: { Message: string } };
      const errorMessage = mutationError?.data?.Message;
      setError(errorMessage ?? "There was an error uploading avatar.");
    }
  };

  useEffect(() => {
    if (error) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [error]);

  const hasRoommates = (user.roommates ?? []).length > 0;

  return (
    <div className="flex flex-col min-h-screen">
      <LayoutHeader
        title="Headshot"
        back={{
          label: hasRoommates ? "Roommates" : "Profile",
          to: hasRoommates ? "../roommates" : "../profile",
        }}
      />
      <LayoutBody className="relative flex flex-col items-center justify-center flex-1 w-full pb-32">
        {image && (
          <div>
            <Button
              color="default"
              onClick={() => {
                setImage(null);
                setPreviewImage(null);
                setMode("initial");
              }}
              size="small"
            >
              reset
            </Button>
          </div>
        )}
        {error && (
          <div className="absolute top-4 left-4 right-4">
            <Alert
              variant="danger"
              message={error ?? "There was an error uploading avatar"}
            />
          </div>
        )}
        {image ? (
          mode === "initial" && previewImage === null ? (
            <div className="flex flex-col items-center justify-center">
              <div className="max-w-lg mt-4">
                <ReactCropper
                  ref={editor}
                  aspectRatio={1}
                  src={image}
                  guides={false}
                  dragMode="move"
                  background={false}
                  viewMode={1}
                  responsive
                  center={false}
                  cropBoxMovable={false}
                  cropBoxResizable={false}
                  onInitialized={(c) => {
                    setCropper(c);
                  }}
                />
              </div>

              <div className="flex gap-1 mt-2">
                <span>
                  <button
                    className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100 disabled:hover:bg-white disabled:cursor-not-allowed group"
                    onClick={() => cropper?.zoom(-0.1)}
                  >
                    <FontAwesomeIcon
                      icon="minus"
                      className="group-disabled:text-gray-300"
                    />
                  </button>
                </span>
                <button
                  className="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-100 disabled:hover:bg-white disabled:cursor-not-allowed group"
                  onClick={() => cropper?.zoom(0.1)}
                >
                  <FontAwesomeIcon
                    icon="plus"
                    className="group-disabled:text-gray-300"
                  />
                </button>
              </div>
            </div>
          ) : previewImage ? (
            <div className="h-full flex flex-col items-center justify-center mt-4">
              <img
                style={{ borderRadius: "50%" }}
                src={previewImage}
                alt="profile avatar"
              />
            </div>
          ) : null
        ) : (
          <div className="flex items-center justify-center flex-1 h-full">
            <label className="flex items-center justify-center p-12 transition-colors duration-300 border border-red-400 rounded-full cursor-pointer hover:bg-red-50 group">
              <FontAwesomeIcon
                icon="camera"
                className="w-24 h-24 text-red-400 transition duration-200 group-hover:scale-105"
              />
              <input
                type="file"
                className="hidden"
                accept="image/jpeg,image/jpg,image/png"
                onChange={onFileInputChange}
              />
            </label>
          </div>
        )}
      </LayoutBody>
      <div className="fixed bottom-0 left-0 right-0 z-0 p-4 bg-gray-100 border-t border-gray-200 md:left-64 backdrop-filter backdrop-blur-sm md:backdrop-filter-none md:backdrop-blur-none md:border-t-0">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
          <div>
            <h4 className="font-medium">add profile photo</h4>
            <p className="text-sm font-light">
              your profile photo will be used for ID verification across
              building and in-app services.
            </p>
          </div>
          {image ? (
            mode === "initial" ? (
              <div>
                <Button
                  className="flex items-center w-full gap-2 mt-4 md:w-auto md:mt-0"
                  color="black"
                  onClick={onTogglePreviewMode}
                >
                  Done
                </Button>
              </div>
            ) : (
              <div>
                <Button
                  onClick={onNext}
                  className="flex items-center w-full gap-2 mt-4 md:w-auto md:mt-0"
                  color="secondary"
                  disabled={addAvatarMutation.isLoading || !image || isLoading}
                >
                  {(addAvatarMutation.isLoading || isLoading) && <Spinner />}
                  {addAvatarMutation.isLoading || isLoading
                    ? "Saving"
                    : "Finish"}
                </Button>
              </div>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
}
