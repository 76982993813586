import { useReducer } from "react";
import { LeaseUtilityProvider, UtilityProvider } from "../../../types/User";

interface AccountNumberChangedAction {
  type: "AccountNumberChanged";
  payload: {
    accountNumber: string;
  };
}
const accountNumberChangedAction = (
  accountNumber: string
): AccountNumberChangedAction => ({
  type: "AccountNumberChanged",
  payload: { accountNumber },
});

interface ChangeActiveProviderAction {
  type: "ChangeActiveProvider";
  payload: {
    utilityProviderTypeId: number;
  };
}
const changeActiveProviderAction = (
  utilityProviderTypeId: number
): ChangeActiveProviderAction => ({
  type: "ChangeActiveProvider",
  payload: { utilityProviderTypeId },
});

interface SetStateAction {
  type: "SetStateAction";
  payload: {
    data: LeaseUtilityProvider;
  };
}
const setStateAction = (data: LeaseUtilityProvider): SetStateAction => ({
  type: "SetStateAction",
  payload: { data },
});

export type CondensedUtilityProvider = Pick<
  UtilityProvider,
  | "name"
  | "logoUrl"
  | "accountNumberRequired"
  | "isDocumentRequired"
  | "utilityProviderTypeId"
  | "phoneNumber"
  | "websiteUrl"
  | "instructions"
  | "isResidentDecision"
  | "setupRequired"
>;

const initUtilityDetailsState = (utilityDetails: LeaseUtilityProvider) => {
  let utilityProviders: readonly CondensedUtilityProvider[] =
    utilityDetails.utilityProviders;
  let activeProvider: CondensedUtilityProvider | null = null;

  const leaseUtilityProvider: CondensedUtilityProvider = {
    name: utilityDetails.name || "",
    logoUrl: utilityDetails.logoUrl || "",
    accountNumberRequired: utilityDetails.accountNumberRequired,
    utilityProviderTypeId: utilityDetails.utilityProviderTypeId!,
    isResidentDecision: utilityDetails.isResidentDecision,
    isDocumentRequired: utilityDetails.documentRequired,
    phoneNumber: "",
    websiteUrl: "",
    instructions: "",
    setupRequired: false,
  };

  const residentDecisionProvider =
    utilityProviders.find((p) => p.isResidentDecision) || null;

  if (
    utilityDetails.leaseUtilityProviderId !== 0 &&
    residentDecisionProvider === null &&
    !utilityDetails.utilityProviders.find(
      (provider) =>
        provider.utilityProviderTypeId === utilityDetails.utilityProviderTypeId
    )
  ) {
    utilityProviders = [...utilityProviders, leaseUtilityProvider];
  }

  if (utilityDetails.leaseUtilityProviderId !== 0) {
    activeProvider = utilityProviders.find(
      (p) =>
        p.utilityProviderTypeId === leaseUtilityProvider.utilityProviderTypeId
    ) || {
      ...residentDecisionProvider,
      ...leaseUtilityProvider,
      isResidentDecision: false,
    };
  } else if (utilityProviders.length === 1) {
    activeProvider = utilityProviders[0];
  }

  return {
    isComplete: utilityDetails.isComplete,
    utilityTypeId: utilityDetails.utilityTypeId,
    utilityTypeName: utilityDetails.utilityType,
    multiple: utilityProviders.length > 1,
    accountNumber: utilityDetails.accountNumber || "",
    utilityProviders,
    activeProvider,
    configuredProviders: utilityDetails.utilityProviders,
  };
};

export type UtilityDetailsState = ReturnType<typeof initUtilityDetailsState>;

type UtilityDetailsActions =
  | AccountNumberChangedAction
  | ChangeActiveProviderAction
  | SetStateAction;

const utilityDetailsReducer = (
  state: ReturnType<typeof initUtilityDetailsState>,
  action: UtilityDetailsActions
): ReturnType<typeof initUtilityDetailsState> => {
  switch (action.type) {
    case "AccountNumberChanged":
      return { ...state, accountNumber: action.payload.accountNumber };
    case "SetStateAction":
      return { ...state, ...initUtilityDetailsState(action.payload.data) };
    case "ChangeActiveProvider":
      const residentDecisionProvider =
        state.utilityProviders.find((p) => p.isResidentDecision) || {};

      let activeProvider =
        state.utilityProviders.find(
          (p) =>
            p.utilityProviderTypeId === action.payload.utilityProviderTypeId
        ) || null;

      if (activeProvider === null) {
        //@ts-ignore
        activeProvider = {
          ...residentDecisionProvider,
          utilityProviderTypeId: action.payload.utilityProviderTypeId,
          isResidentDecision: false,
        };
      }

      return {
        ...state,
        activeProvider,
      };
  }
};

const usePresenter = (initData: LeaseUtilityProvider) => {
  const [state, dispatch] = useReducer(
    utilityDetailsReducer,
    initData,
    initUtilityDetailsState
  );

  const changeAccountNumber = (accountNumber: string) =>
    dispatch(accountNumberChangedAction(accountNumber));
  const changeActiveProvider = (utilityProviderTypeId: number) =>
    dispatch(changeActiveProviderAction(utilityProviderTypeId));
  const setState = (data: LeaseUtilityProvider) =>
    dispatch(setStateAction(data));

  return {
    state,
    changeAccountNumber,
    changeActiveProvider,
    setState,
  };
};

export default usePresenter;
