import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { useParams, useOutletContext, Navigate } from "react-router-dom";
import { getFormattedTime } from "../../utils/bookingHelpers";
import { Button } from "../../components/Button";
import Layout from "../../components/Layout";
import formatCurrency from "../../utils/formatCurrency";
import {
  BaseAmenitySpace,
  BookingAvailabilityType,
} from "../../types/Bookings";
import { BookingContextProps } from "./add-booking";
import { Spinner } from "../../components/Spinner";

export default function ConfirmBookingPage() {
  const params = useParams<{
    leaseId: string;
    id: string;
    propertyId: string;
  }>();
  const { booking, amenitySpace, isLoading, setBooking, onSubmit } =
    useOutletContext<BookingContextProps>();
  const { t } = useTranslation();

  const isSubmitDisabled =
    isLoading ||
    (amenitySpace.waiver
      ? amenitySpace.waiver.required && !booking.waiverAccepted
      : false);

  if (booking.startTime == null) {
    return (
      <Navigate
        to={`/lease/${params.leaseId}/amenities/${params.propertyId}/${params.id}/booking/new`}
      />
    );
  }

  return (
    <Layout
      title="Confirm Booking"
      back={{
        to: `/lease/${params.leaseId}/amenities/${params.propertyId}/${params.id}/booking/new`,
        label: amenitySpace.name,
      }}
    >
      <BookingInfo
        space={amenitySpace}
        startTime={booking.startTime}
        endTime={booking.endTime}
        waiverAgreed={booking.waiverAccepted}
        setWaiverAgreed={(checked: boolean) =>
          setBooking({ ...booking, waiverAccepted: checked })
        }
      />
      <div className="mt-6">
        <Button
          size="small"
          color="secondary"
          onClick={onSubmit}
          disabled={isSubmitDisabled}
          className="flex items-center w-full gap-2 md:w-auto"
        >
          {isLoading && <Spinner />}
          {t("amenity.space.confirmation.submit-booking-request")}
        </Button>
      </div>
    </Layout>
  );
}

function BookingInfo({
  space,
  startTime,
  endTime,
  waiverAgreed,
  isMobile = false,
  setWaiverAgreed,
}: {
  space: BaseAmenitySpace;
  startTime: string | Date | null;
  endTime: string | Date | null;
  waiverAgreed: boolean;
  isMobile?: boolean;
  setWaiverAgreed: (agreed: boolean) => void;
}) {
  const { t } = useTranslation();
  const {
    bookingAvailabilityType,
    name,
    imageUri,
    price,
    checkinTime,
    checkoutTime,
    paymentRequired,
  } = space;

  const { startEnd } = getFormattedTime(startTime, endTime);

  return (
    <>
      <div className="flex items-center mb-4">
        <img
          className="md:mr-2 md:h-[150px] md:w-[200px] w-full object-cover rounded-lg"
          src={imageUri ? imageUri : "/assets/media/building.svg"}
          alt={name}
        />
        <p className="hidden text-lg font-light md:block">{name}</p>
      </div>

      {bookingAvailabilityType === BookingAvailabilityType.ByDay && (
        <div>
          <div className="items-center justify-between mb-2 md:flex">
            <div className="flex items-center md:justify-between">
              <FontAwesomeIcon
                icon={["far", "calendar"]}
                className="mr-2 text-red-300"
              />
              <p>{t("amenity.space.confirmation.date")}</p>
            </div>
            <p>{moment(startTime).format("dddd, MMMM D")}</p>
          </div>
        </div>
      )}

      {bookingAvailabilityType === BookingAvailabilityType.Daily && (
        <div>
          <div className="items-center justify-between mb-2 md:flex">
            <div className="flex items-center md:justify-between">
              <FontAwesomeIcon
                icon={["far", "calendar"]}
                className="mr-2 text-red-300"
              />
              <p>{t("amenity.space.confirmation.date")}</p>
            </div>
            <p>{moment(startTime).format("dddd, MMMM D")}</p>
          </div>
          <div className="items-center justify-between mb-2 md:flex">
            <div className="flex items-center md:justify-between">
              <FontAwesomeIcon
                icon={["far", "clock"]}
                className="mr-2 text-red-300"
              />
              <p>{t("amenity.space.confirmation.time")}</p>
            </div>
            <p>{startEnd}</p>
          </div>
        </div>
      )}

      {bookingAvailabilityType === BookingAvailabilityType.Overnight && (
        <div>
          <div className="items-center justify-between mb-2 md:flex">
            <div className="flex items-center md:justify-between">
              <FontAwesomeIcon
                icon={["far", "calendar"]}
                className="mr-2 text-red-300"
              />
              <p>{t("amenity.space.checkin-time")}</p>
            </div>
            <p>
              {moment(startTime).format("dddd, MMMM D")}
              {checkinTime !== 0 &&
                `, ${moment()
                  .startOf("day")
                  .add(checkinTime, "minutes")
                  .format("h:mm A")}`}
            </p>
          </div>
          <div className="items-center justify-between mb-2 md:flex">
            <div className="flex items-center md:justify-between">
              <FontAwesomeIcon
                icon={["far", "calendar"]}
                className="mr-2 text-red-300"
              />
              <p>{t("amenity.space.checkout-time")}</p>
            </div>
            <p>
              {moment(endTime).format("dddd, MMMM D")}
              {checkoutTime !== 0 &&
                `, ${moment()
                  .startOf("day")
                  .add(checkoutTime, "minutes")
                  .format("h:mm A")}`}
            </p>
          </div>
        </div>
      )}

      <hr />
      <div>
        {price > 0 && (
          <div className="flex items-center justify-between my-4">
            <p>{t("amenity.space.confirmation.amount")}</p>
            <p>
              {calculateTotalPrice(
                bookingAvailabilityType,
                startTime,
                endTime,
                price
              )}
            </p>
          </div>
        )}
        <div className="p-4 bg-blue-100 rounded-md">
          <p>
            {paymentRequired
              ? t("amenity.space.confirmation.payment")
              : t(
                  `amenity.space.confirmation.auto-approval-${
                    space.isAutoApproval ? "on" : "off"
                  }`
                )}
          </p>
        </div>
        {space.waiver && space.waiver.required && (
          <div className="my-4">
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={waiverAgreed}
                onChange={(e) => setWaiverAgreed(e.target.checked)}
              />
              <span className="ml-4">{space.waiver.disclaimer}</span>
            </label>
          </div>
        )}
      </div>
    </>
  );
}

function calculateTotalPrice(
  bookingAvailabilityType: BookingAvailabilityType,
  startTime: string | Date | null,
  endTime: string | Date | null,
  price: number
) {
  if (bookingAvailabilityType === BookingAvailabilityType.Overnight) {
    const days = moment(endTime).diff(startTime, "days");

    return formatCurrency(price * days).replace(".00", "");
  } else if (bookingAvailabilityType === BookingAvailabilityType.ByDay) {
    return formatCurrency(price).replace(".00", "");
  } else if (bookingAvailabilityType === BookingAvailabilityType.Daily) {
    // const hours = moment(endTime).diff(startTime, "hours");
    const minutes = moment(endTime).diff(startTime, "minutes");
    const leftover = minutes / 60;

    // const hoursPrice = price * hours;
    const minutesPrice = price * leftover;

    return formatCurrency(minutesPrice).replace(".00", "");
  }
}
