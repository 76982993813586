import { useTranslation } from "react-i18next";
import { BuildingTypeEnum, UtilityProvider } from "../../../types/User";
import { UtilityDetailsState } from "./presenter";

export const isResponsible = (
  provider: Pick<UtilityProvider, "accountNumberRequired"> | null
) => !!(provider && provider.accountNumberRequired !== null);

export const isResidentResponsible = (
  provider: Pick<UtilityProvider, "setupRequired"> | null
) => !!(provider && provider.setupRequired === true);

export const isAccountNumberRequired = (
  provider: Pick<UtilityProvider, "accountNumberRequired"> | null
) => !!(provider && provider.accountNumberRequired === true);

export const isDocumentRequired = (
  provider: Pick<UtilityProvider, "isDocumentRequired"> | null
) => !!(provider && provider.isDocumentRequired === true);

export const isNoneRequired = (
  provider: Pick<
    UtilityProvider,
    "isDocumentRequired" | "accountNumberRequired" | "isResidentDecision"
  > | null
) =>
  !!(
    provider &&
    !provider.isDocumentRequired &&
    !provider.isResidentDecision &&
    !provider.accountNumberRequired
  );

export const isResidentDecision = (state: UtilityDetailsState) => {
  if (state.activeProvider === null) {
    return false;
  }

  const typeHasResidentChoiceOption = !!state.configuredProviders.find(
    (p) => p.isResidentDecision
  );
  const providerIsNotConfiguredOption = !state.configuredProviders.find(
    (p) =>
      p.utilityProviderTypeId === state.activeProvider!.utilityProviderTypeId
  );

  return (
    state.activeProvider.isResidentDecision ||
    (providerIsNotConfiguredOption && typeHasResidentChoiceOption)
  );
};

export const isMultipleChoice = (state: UtilityDetailsState) => {
  if (state.activeProvider === null) {
    return state.utilityProviders.length > 1;
  }

  const typeHasResidentChoiceOption = !!state.configuredProviders.find(
    (p) => p.isResidentDecision
  );

  const providerIsNotConfiguredOption = !state.configuredProviders.find(
    (p) =>
      p.utilityProviderTypeId === state.activeProvider!.utilityProviderTypeId
  );

  if (
    typeHasResidentChoiceOption &&
    providerIsNotConfiguredOption &&
    state.configuredProviders.length === 1
  ) {
    return false;
  }

  return state.utilityProviders.length > 1;
};

export const useGetDescriptionText = (
  state: UtilityDetailsState,
  buildingType: BuildingTypeEnum
) => {
  const { t } = useTranslation();
  let description;

  if (state.activeProvider === null) {
    return "";
  }

  if (isMultipleChoice(state)) {
    if (isResidentDecision(state)) {
      description = t("utilities.step-description-resident-choice");
    } else if (
      isResidentResponsible(state.activeProvider) &&
      !isDocumentRequired(state.activeProvider) &&
      !isAccountNumberRequired(state.activeProvider)
    ) {
      description = t("utilities.step-description-resident-responsible", {
        utilityTypeName: state.utilityTypeName
      });
    } else if (
      !isResidentResponsible(state.activeProvider) &&
      isNoneRequired(state.activeProvider)
    ) {
      description = t("utilities.step-description-nothing-required");
    } else if (
      isResidentResponsible(state.activeProvider) &&
      isAccountNumberRequired(state.activeProvider) &&
      isDocumentRequired(state.activeProvider)
    ) {
      description = t("utilities.account-document-required", {
        utilityTypeName: state.utilityTypeName
      });
    } else if (isDocumentRequired(state.activeProvider)) {
      description = t("utilities.document-required", {
        utilityTypeName: state.utilityTypeName
      });
    } else if (isAccountNumberRequired(state.activeProvider)) {
      description = t("utilities.account-number-required", {
        utilityTypeName: state.utilityTypeName
      });
    } else if (isResponsible(state.activeProvider)) {
      description = t("utilities.short-description-unrequired");
    } else {
      description = "";
    }
  } else {
    if (isResidentDecision(state)) {
      description = t("utilities.step-description-resident-choice", {
        utilityTypeName: state.utilityTypeName
      });
    } else if (
      isResidentResponsible(state.activeProvider) &&
      !isDocumentRequired(state.activeProvider) &&
      !isAccountNumberRequired(state.activeProvider)
    ) {
      description = t("utilities.step-description-resident-responsible", {
        utilityTypeName: state.utilityTypeName
      });
    } else if (
      !isResidentResponsible(state.activeProvider) &&
      isNoneRequired(state.activeProvider)
    ) {
      description = t("utilities.step-description-nothing-required");
    } else if (
      isResidentResponsible(state.activeProvider) &&
      isAccountNumberRequired(state.activeProvider) &&
      isDocumentRequired(state.activeProvider)
    ) {
      description = t("utilities.account-document-required", {
        utilityTypeName: state.utilityTypeName
      });
    } else if (isDocumentRequired(state.activeProvider)) {
      description = t("utilities.document-required", {
        utilityTypeName: state.utilityTypeName
      });
    } else if (isAccountNumberRequired(state.activeProvider)) {
      description = t("utilities.account-number-required", {
        utilityTypeName: state.utilityTypeName
      });
    } else if (isResponsible(state.activeProvider)) {
      description = t("utilities.step-description-unrequired", {
        utilityTypeName: state.utilityTypeName.toLowerCase()
      });
    } else {
      description = t("utilities.step-description-unresponsible", {
        utilityTypeName: state.utilityTypeName,
        trailingText:
          buildingType === BuildingTypeEnum.Apartments ? "in your lease" : ""
      });
    }
  }

  return description;
};
