import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { FormEvent, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Alert from "../../components/Alert";
import Avatar from "../../components/Avatar";
import { Button } from "../../components/Button";
import { ConfirmationModal } from "../../components/Dialog";
import { Toggle } from "../../components/Form";
import Layout from "../../components/Layout";
import { Spinner } from "../../components/Spinner";
import { BaseLivlyApiResponse } from "../../types/Base";
import { BuildingServiceType, ServiceTypeEnum } from "../../types/Building";
import { User } from "../../types/User";
import { BASE_API_URL } from "../../utils/constants";
import { DelegateUser, getDelegateUsers } from "./family-logins";
import { serviceTypeDescriptions } from "./permissions";
import useLivlyUser from "../../context/UserProvider";

const postSendInvite = async (userId?: number, leaseId?: number) => {
  const result = await axios.post(
    `${BASE_API_URL}/livly/delegatedUsers/${userId}/leases/${leaseId}/invite`
  );

  return result;
};

type UserServiceTypes = {
  serviceType: number;
  enabled: boolean;
  metaData: string;
};

const patchDelegateUserServiceTypes = async (
  userId: number,
  leaseId: number,
  data: UserServiceTypes[]
) => {
  const result = await axios.patch<BaseLivlyApiResponse<BuildingServiceType[]>>(
    `${BASE_API_URL}/livly/delegatedUsers/${userId}/leases/${leaseId}/serviceTypes`,
    data
  );

  return result.data.Data;
};

const getDelegateUserServiceTypes = async (
  userId?: number,
  leaseId?: number
) => {
  const result = await axios.get<BaseLivlyApiResponse<BuildingServiceType[]>>(
    `${BASE_API_URL}/livly/delegatedUsers/${userId}/leases/${leaseId}/serviceTypes`
  );

  return result.data.Data;
};

function useDeleteDelegateUser(leaseId: string) {
  const deleteVehicle = async (userId: number) => {
    const result = await axios.delete(
      `${BASE_API_URL}/livly/delegatedUsers/${userId}/leases/${leaseId}`
    );

    return result.data.Data;
  };

  return useMutation(deleteVehicle);
}

function EditFamilyLoginPage({ delegateUser }: { delegateUser: DelegateUser }) {
  const { user } = useLivlyUser();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const params = useParams<{ leaseId: string; id: string }>();
  const [isOpen, setIsOpen] = useState(false);
  const {
    mutate,
    isLoading: isDeleting,
    isSuccess,
    error: deleteError,
  } = useDeleteDelegateUser(params.leaseId!);

  const onDelete = (id: number) => {
    mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "delegate-users",
          user.userId,
          user.propertyUnitLeaseId,
        ]);
        navigate("../family-logins");
      },
      onError: () => {
        setIsOpen(false);
      },
    });
  };

  const fullName = `${delegateUser.firstName} ${delegateUser.lastName}`;
  const mutationError = deleteError as { data?: { Message: string } };
  const errorMessage = mutationError?.data?.Message;
  useEffect(() => {
    if (errorMessage) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [errorMessage]);

  const { mutate: sendInvite, isLoading: isResending } = useMutation(() =>
    postSendInvite(delegateUser?.userId, user.propertyUnitLeaseId)
  );
  const { data, isLoading } = useQuery(
    ["delegate-users", "user", delegateUser?.userId],
    () =>
      getDelegateUserServiceTypes(
        delegateUser?.userId,
        user.propertyUnitLeaseId
      )
  );

  if (isSuccess) {
    return null;
  }

  return (
    <Layout
      title={`${fullName}`}
      back={{ to: "../family-logins", label: "Friends & Family Logins" }}
    >
      {errorMessage && (
        <div className="my-4">
          <Alert variant="danger" message={errorMessage} />
        </div>
      )}
      <div className="flex flex-col items-center justify-center">
        <Avatar size="lg" src={delegateUser.avatarUrl} name={fullName} />
        <h3 className="mt-2 text-xl font-medium">{fullName}</h3>
      </div>
      <div className="flex mt-6 gap-4 md:justify-center">
        <Button
          color="primary"
          onClick={() => sendInvite()}
          disabled={isResending}
          className="flex items-center flex-1 gap-2 md:flex-initial"
        >
          {isResending && <Spinner />}
          Send invite again
        </Button>
        <Button color="default" onClick={() => setIsOpen(true)}>
          Remove
        </Button>
      </div>
      <h4 className="mt-6 text-lg font-medium">Email</h4>
      <a href={`mailto:${delegateUser.email}}`}>{delegateUser.email}</a>
      <h4 className="mt-4 text-lg font-medium">Phone number</h4>
      <p>{delegateUser.phoneNumber}</p>
      <h4 className="mt-4 text-lg font-medium">Access permissions</h4>
      {isLoading ? (
        <div>loading service types</div>
      ) : (data ?? []).length > 0 ? (
        <DelegateUserPermissions
          leaseId={user.propertyUnitLeaseId}
          delegateUser={delegateUser}
          services={data ?? []}
        />
      ) : null}
      <ConfirmationModal
        variant="warning"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="Remove Delegate User"
        body="Are you sure you want to remove this user?"
        buttonLabels={{ confirm: isDeleting ? "Removing" : "Remove" }}
        onConfirm={() => onDelete(delegateUser.userId)}
        isLoading={isDeleting}
      />
    </Layout>
  );
}

function DelegateUserPermissions({
  leaseId,
  delegateUser,
  services,
}: {
  leaseId: number;
  delegateUser: DelegateUser;
  services: BuildingServiceType[];
}) {
  const queryClient = useQueryClient();
  const { mutate, isLoading, error } = useMutation({
    mutationFn: (data: UserServiceTypes[]) =>
      patchDelegateUserServiceTypes(delegateUser.userId, leaseId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["delegate-users"] });
    },
  });
  const [userPermissions, setUserPermissions] = useState<
    {
      serviceType: number;
      enabled: boolean;
      metaData: string;
      name: string;
      description: string;
    }[]
  >(
    services
      .filter(
        (st) =>
          ![
            ServiceTypeEnum.LivlyLocks,
            ServiceTypeEnum.InfoDesk,
            ServiceTypeEnum.MarketPlace,
          ].includes(st.serviceType)
      )
      .map((st) => ({
        serviceType: st.serviceType,
        enabled: st.enabled,
        metaData: "",
        name: st.serviceName,
        description: serviceTypeDescriptions[st.serviceType] ?? "",
      }))
  );

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const userServices: UserServiceTypes[] = userPermissions.map((up) => ({
      enabled: up.enabled,
      serviceType: up.serviceType,
      metaData: up.metaData,
    }));

    mutate(userServices);
  };

  const mutationError = error as { data?: { Message: string } };
  const errorMessage = mutationError?.data?.Message;

  return (
    <form onSubmit={onSubmit}>
      {errorMessage && (
        <div className="my-4">
          <Alert variant="danger" message={errorMessage} />
        </div>
      )}
      <fieldset disabled={isLoading}>
        <ul className="mt-4 space-y-4">
          {userPermissions.map((up) => (
            <li
              className="flex items-center justify-between"
              key={up.serviceType}
            >
              <div>
                <p>{up.name}</p>
                {up.description && (
                  <p className="text-sm text-gray-700">{up.description}</p>
                )}
              </div>
              <Toggle
                checked={up.enabled}
                onChange={(enabled) => {
                  const newUserPermissions = userPermissions.map((u) => {
                    if (up.serviceType === u.serviceType) {
                      return { ...u, enabled };
                    }
                    return u;
                  });
                  setUserPermissions(newUserPermissions);
                }}
              />
            </li>
          ))}
        </ul>
      </fieldset>
      <div className="flex justify-end mt-6">
        <Button
          type="submit"
          color="secondary"
          className="flex items-center justify-center w-full gap-2 md:w-auto"
        >
          {isLoading && <Spinner />}
          Update
        </Button>
      </div>
    </form>
  );
}

export default function Container() {
  const params = useParams<{ id: string }>();
  const { user } = useLivlyUser();
  const { data: delegateUsers = [], isLoading } = useQuery(
    ["delegate-users", user.userId, user.propertyUnitLeaseId],
    () => getDelegateUsers(user.userId, user.propertyUnitLeaseId)
  );

  if (isLoading) {
    return null;
  }

  const delegateUser = delegateUsers.find(
    (d) => d.userId === Number(params.id)
  );

  if (!delegateUser) {
    return <Navigate to="../family-logins" />;
  }

  return <EditFamilyLoginPage delegateUser={delegateUser} />;
}
