import axios from "axios";
import moment from "moment-timezone";
import { Fragment, useReducer, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Popover, Transition } from "@headlessui/react";
import { BookingAvailabilityType, BookingStatus } from "../types/Bookings";
import { BaseLivlyApiResponse } from "../types/Base";
import { BASE_API_URL } from "../utils/constants";
import { Link, useLocation, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Layout from "../components/Layout";
import { Button } from "../components/Button";
import toLocalTime from "../utils/toLocalTime";
import { Badge, BadgeProps } from "../components/Badge";
import { ConfirmationModal } from "../components/Dialog";
import classNames from "classnames";
import { ServiceTypeEnum } from "../types/Building";
import useGetOnboardingAmenitiesEnabled from "../hooks/useGetOnboardingAmenities";
import { textInputClass } from "../components/Form";
import { Spinner } from "../components/Spinner";
import useLivlyUser from "../context/UserProvider";
import React from "react";

type EventBooking = {
  eventId: string;
  propertyId: number;
  propertyName: string;
  timezone: string;
  title: string;
  location: string;
  imageUrl: string;
  startDate: string;
  endDate: string;
  attending: boolean;
  isCancelled: boolean;
  residentFeedbackId: number;
  provider: {
    userId: number;
    firstName: string;
    lastName: string;
  } | null;
};

export type AmenityBooking = {
  amenityBookingId: number;
  livlyUserPaymentHistoryId: number;
  propertyId: number;
  status: BookingStatus;
  invoiceUrl: string | null;
  amenitySpace: {
    amenitySpaceId: number;
    propertyId: number;
    propertyName: string;
    name: string;
    imageUri: string;
    timezone: string;
    isAutoApproval: true;
    maxDuration: number;
    minDuration: number;
    bookingAvailabilityType: BookingAvailabilityType;
    price: number;
    displayAvailabilityInDays: number;
    displayAvailability: "Undefined";
    waiver: {
      amenitySpaceWaiverId: number;
      amenitySpaceId: number;
      required: boolean;
      disclaimer: string;
    } | null;
    checkinTime: number;
    checkoutTime: number;
    paymentRequired: boolean;
  } | null;
  startTime: string;
  endTime: string;
  description: string;
  waiverAccepted: boolean;
  timezone: string;
};

type FitnessBooking = {
  id: number;
  name: string;
  startTime: string;
  stopTime: string;
  duration: number;
  location: string;
  imageUrl: string;
  timezone: string;
  isCancelled: boolean;
  serviceType: number;
  property: {
    propertyId: number;
    name: string;
    timezone: string;
  } | null;
};

type BookingItem = {
  id: string;
  title?: string;
  imageUri?: string;
  propertyName?: string;
  date: string;
  time?: string;
  status: BookingStatus | null;
  link: string;
  isUpcoming: boolean;
  isLoading: boolean;
  bookingType: "Amenity" | "Event" | "Fitness";
  startTime: string;
  endTime: string;
  isPaymentRequired: boolean;
  invoiceUrl: string | null;
};

const getBookings = async () => {
  const { data } = await axios.get<
    BaseLivlyApiResponse<{
      amenityBookings: AmenityBooking[];
      events: EventBooking[];
      fitness: FitnessBooking[];
    }>
  >(`${BASE_API_URL}/livly/users/me/bookings/v2`);

  return data.Data;
};

export const bookingsQuery = (leaseId: number) => ({
  queryKey: ["bookings", leaseId],
  queryFn: async () => getBookings(),
});

interface ReducerState {
  term: string;
  showAll: boolean;
  showAmenities: boolean;
  showEvents: boolean;
  showFitness: boolean;
  sidebarOpen: boolean;
}

type Action =
  | { type: "toggle_show_all"; showAll: boolean }
  | { type: "toggle_show_amenities" }
  | { type: "toggle_show_events" }
  | { type: "toggle_show_fitness" }
  | { type: "set_term"; term: string }
  | { type: "set_sidebar_open"; open: boolean }
  | { type: "reset" };

function reducer(state: ReducerState, action: Action): ReducerState {
  switch (action.type) {
    case "toggle_show_all":
      return {
        ...state,
        showAll: action.showAll,
      };
    case "toggle_show_amenities": {
      return {
        ...state,
        showAmenities: !state.showAmenities,
      };
    }
    case "toggle_show_events":
      return {
        ...state,
        showEvents: !state.showEvents,
      };
    case "toggle_show_fitness":
      return {
        ...state,
        showFitness: !state.showFitness,
      };
    case "set_term":
      return { ...state, term: action.term };
    case "set_sidebar_open":
      return { ...state, sidebarOpen: action.open };
    case "reset":
      return {
        ...state,
        showAll: false,
        showAmenities: true,
        showEvents: true,
        showFitness: true,
        term: "",
      };
    default:
      return state;
  }
}

function createInitialState(): ReducerState {
  return {
    showAll: false,
    showAmenities: true,
    showEvents: true,
    showFitness: true,
    term: "",
    sidebarOpen: false,
  };
}

export default function BookingsPage() {
  const { user } = useLivlyUser();
  const params = useParams<{ leaseId: string }>();
  const [isCanceling, setIsCanceling] = useState(false);

  const { data, isLoading } = useQuery({
    ...bookingsQuery(user.propertyUnitLeaseId),
  });
  const client = useQueryClient();

  const isOnboardingAmenitiesEnabled = useGetOnboardingAmenitiesEnabled();

  const location =
    (useLocation().state as {
      returnTo: string;
      label: string;
    }) || {};

  const [state, dispatch] = useReducer(reducer, undefined, createInitialState);

  const isFitnessEnabled = user.building.serviceTypes?.find(
    (st) => st.serviceType === ServiceTypeEnum.Fitness
  )?.enabled;
  const isAmenitiesEnabled = user.building.serviceTypes?.find(
    (st) => st.serviceType === ServiceTypeEnum.Amenities
  )?.enabled;
  const isEventsEnabled = user.building.serviceTypes?.find(
    (st) => st.serviceType === ServiceTypeEnum.Events
  )?.enabled;

  const allBookingItems = useFilterBookingItems(
    params.leaseId!,
    user.propertyId,
    isLoading,
    state.showAll,
    state.term.trim().toLowerCase(),
    state.showAmenities,
    state.showEvents,
    state.showFitness,
    data
  );

  const cancelFitnessEventMutation = useMutation(
    ({ sessionId, isAttending }: { sessionId: string; isAttending: boolean }) =>
      replyToFitnessEvent(sessionId, isAttending)
  );
  const cancelAmenityMutation = useMutation(
    ({ bookingId }: { bookingId: string }) => cancelAmenityBooking(bookingId)
  );
  const cancelEventMutation = useMutation(
    ({ propertyId, eventId }: { propertyId: string; eventId: string }) =>
      cancelEventBooking(propertyId, eventId)
  );

  const onCancelEventBooking = (
    propertyId: string,
    eventId: string,
    callback: () => void
  ) => {
    cancelEventMutation.mutate(
      { propertyId, eventId },
      {
        onSuccess: () => {
          if (callback) {
            callback();
          }
          client.invalidateQueries(["bookings"]);
        },
        onSettled: () => {
          setIsCanceling(false);
        },
      }
    );
  };
  const onCancelAmenityBooking = (bookingId: string, callback: () => void) => {
    cancelAmenityMutation.mutate(
      { bookingId },
      {
        onSuccess: () => {
          if (callback) {
            callback();
          }
          client.invalidateQueries(["bookings"]);
        },
        onSettled: () => {
          setIsCanceling(false);
        },
      }
    );
  };

  const onCancelFitnessBooking = (id: string, callback: () => void) => {
    cancelFitnessEventMutation.mutate(
      { sessionId: id, isAttending: false },
      {
        onSuccess: () => {
          if (callback) {
            callback();
          }
          client.invalidateQueries(["bookings"]);
        },
        onSettled: () => {
          setIsCanceling(false);
        },
      }
    );
  };

  return (
    <Layout
      className="min-h-screen"
      title="My Bookings"
      {...(location.label != null && {
        back: {
          label: location.label,
          to: location.returnTo,
        },
      })}
      button={
        <button
          className="md:hidden"
          onClick={() => dispatch({ type: "set_sidebar_open", open: true })}
        >
          <FontAwesomeIcon icon="filter" />
        </button>
      }
    >
      {isLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <>
          <div>
            <div className="mt-4">
              <div>
                <div className="flex flex-col justify-between md:flex-row">
                  <div className="flex items-center gap-2">
                    <button
                      onClick={() =>
                        dispatch({ type: "toggle_show_all", showAll: false })
                      }
                      className={classNames(
                        "py-1 px-4 rounded-full transition duration-200",
                        {
                          "bg-gray-100": state.showAll,
                          "bg-red-300 text-white": !state.showAll,
                        }
                      )}
                    >
                      Upcoming
                    </button>
                    <button
                      onClick={() =>
                        dispatch({ type: "toggle_show_all", showAll: true })
                      }
                      className={classNames(
                        "py-1 px-4 rounded-full transition duration-200",
                        {
                          "bg-gray-100": !state.showAll,
                          "bg-red-300 text-white": state.showAll,
                        }
                      )}
                    >
                      All
                    </button>
                  </div>
                  <div className="mt-4 md:mt-0">
                    <div className="relative flex items-center gap-1 pl-1 border-b border-gray-500">
                      <FontAwesomeIcon icon={["far", "search"]} />
                      <input
                        type="text"
                        placeholder="Search by name"
                        value={state.term}
                        onChange={(e) =>
                          dispatch({ type: "set_term", term: e.target.value })
                        }
                        className={classNames(
                          textInputClass,
                          "border-none mt-0"
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-2">
                  <Popover className="relative">
                    <Popover.Button
                      className="items-center hidden gap-2 px-2 py-1 md:flex hover:bg-gray-50"
                      onClick={() =>
                        dispatch({ type: "set_sidebar_open", open: true })
                      }
                    >
                      <FontAwesomeIcon icon={["fal", "sliders"]} />
                      Filters
                      <FontAwesomeIcon icon={["fal", "chevron-down"]} />
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 px-4 mt-3 transform sm:px-0">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-8 bg-white p-7 lg:grid-cols-2">
                            <div className="flex-1 space-y-2">
                              {isAmenitiesEnabled ||
                              isOnboardingAmenitiesEnabled ? (
                                <label className="flex items-center p-1">
                                  <input
                                    type="checkbox"
                                    checked={state.showAmenities}
                                    onChange={(e) =>
                                      dispatch({
                                        type: "toggle_show_amenities",
                                      })
                                    }
                                  />
                                  <span className="ml-2">Amenities</span>
                                </label>
                              ) : null}
                              {isEventsEnabled ? (
                                <label className="flex items-center p-1">
                                  <input
                                    type="checkbox"
                                    checked={state.showEvents}
                                    onChange={(e) =>
                                      dispatch({
                                        type: "toggle_show_events",
                                      })
                                    }
                                  />
                                  <span className="ml-2">Events</span>
                                </label>
                              ) : null}
                              {isFitnessEnabled ? (
                                <label className="flex items-center p-1">
                                  <input
                                    type="checkbox"
                                    checked={state.showFitness}
                                    onChange={(e) =>
                                      dispatch({
                                        type: "toggle_show_fitness",
                                      })
                                    }
                                  />
                                  <span className="ml-2">Fitness</span>
                                </label>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                </div>

                <div className="mt-6 space-y-6">
                  {allBookingItems.length === 0 && (
                    <div className="my-10 text-center">
                      <h4 className="text-xl">No bookings yet</h4>
                      <p className="mt-2 text-sm font-light">
                        Any bookings you have will appear here
                      </p>
                    </div>
                  )}
                  <div className="grid gap-2 mt-6 md:grid-cols-2">
                    {allBookingItems.map((bi) => (
                      <BookingItem
                        key={`${bi.bookingType}-${bi.id}`}
                        {...bi}
                        isPaymentRequired={bi.isPaymentRequired}
                        isLoading={isCanceling}
                        onCancelBooking={(callback) => {
                          setIsCanceling(true);
                          if (bi.bookingType === "Amenity") {
                            onCancelAmenityBooking(bi.id, callback);
                          } else if (bi.bookingType === "Event") {
                            onCancelEventBooking(
                              user.propertyId.toString(),
                              bi.id,
                              callback
                            );
                          } else if (bi.bookingType === "Fitness") {
                            onCancelFitnessBooking(bi.id, callback);
                          }
                        }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Transition.Root show={state.sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 md:hidden"
              onClose={() =>
                dispatch({ type: "set_sidebar_open", open: false })
              }
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex flex-col flex-1 w-full bg-white">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 pt-2 -mr-12">
                        <button
                          type="button"
                          className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() =>
                            dispatch({ type: "set_sidebar_open", open: false })
                          }
                        >
                          <span className="sr-only">Close sidebar</span>
                          <FontAwesomeIcon icon="times" />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                      <div className="flex flex-col h-full px-4">
                        <h3 className="text-lg font-medium">Filters</h3>
                        <div className="flex-1 mt-4 space-y-2">
                          <label className="flex items-center p-1">
                            <input
                              type="checkbox"
                              checked={state.showAmenities}
                              onChange={(e) =>
                                dispatch({
                                  type: "toggle_show_amenities",
                                })
                              }
                            />
                            <span className="ml-2">Amenities</span>
                          </label>
                          <label className="flex items-center p-1">
                            <input
                              type="checkbox"
                              checked={state.showEvents}
                              onChange={(e) =>
                                dispatch({
                                  type: "toggle_show_events",
                                })
                              }
                            />
                            <span className="ml-2">Events</span>
                          </label>
                          <label className="flex items-center p-1">
                            <input
                              type="checkbox"
                              checked={state.showFitness}
                              onChange={(e) =>
                                dispatch({
                                  type: "toggle_show_fitness",
                                })
                              }
                            />
                            <span className="ml-2">Fitness</span>
                          </label>
                        </div>
                        <div>
                          <Button
                            color="default"
                            size="small"
                            className="w-full"
                            onClick={() => {
                              dispatch({ type: "reset" });
                              dispatch({
                                type: "set_sidebar_open",
                                open: false,
                              });
                            }}
                          >
                            Reset
                          </Button>
                          <Button
                            color="primary"
                            size="small"
                            className="w-full mt-4"
                            onClick={() =>
                              dispatch({
                                type: "set_sidebar_open",
                                open: false,
                              })
                            }
                          >
                            Close
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div className="flex-shrink-0 w-14">
                  {/* Force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>
        </>
      )}
    </Layout>
  );
}

function useFilterBookingItems(
  leaseId: string,
  propertyId: number,
  isLoading: boolean,
  showAll: boolean,
  searchTerm: string,
  showAmenities: boolean,
  showEvents: boolean,
  showFitness: boolean,
  data:
    | {
        amenityBookings: AmenityBooking[];
        events: EventBooking[];
        fitness: FitnessBooking[];
      }
    | undefined
) {
  return React.useMemo(() => {
    const amenityBookingItems: BookingItem[] = showAmenities
      ? data?.amenityBookings.map((ab) => {
          let date =
            ab.amenitySpace?.bookingAvailabilityType ===
            BookingAvailabilityType.Overnight
              ? `${toLocalTime(ab.startTime, ab.timezone).format(
                  "MMM D, YYYY"
                )} - ${toLocalTime(ab.endTime, ab.timezone).format(
                  "MMM D, YYYY"
                )}`
              : toLocalTime(ab.startTime, ab.timezone).format("MMM D, YYYY");

          let time =
            ab.amenitySpace?.bookingAvailabilityType &&
            [
              BookingAvailabilityType.Overnight,
              BookingAvailabilityType.ByDay,
            ].includes(ab.amenitySpace.bookingAvailabilityType)
              ? undefined
              : `${toLocalTime(ab.startTime, ab.timezone).format(
                  "h:mm a"
                )} – ${toLocalTime(ab.endTime, ab.timezone).format("h:mm a")}`;

          return {
            id: ab.amenityBookingId.toString(),
            bookingType: "Amenity",
            title: ab.amenitySpace?.name,
            imageUri: ab.amenitySpace?.imageUri,
            propertyName: ab.amenitySpace?.propertyName,
            date,
            time,
            status: ab.status,
            link: `/lease/${leaseId}/amenities/${propertyId}/${ab.amenitySpace?.amenitySpaceId}/booking/${ab.amenityBookingId}`,
            isUpcoming: moment().isSameOrBefore(
              toLocalTime(ab.endTime, ab.timezone)
            ),
            isLoading: isLoading,
            startTime: ab.startTime,
            endTime: ab.endTime,
            isPaymentRequired: ab.amenitySpace?.paymentRequired ?? false,
            invoiceUrl: ab.invoiceUrl,
          };
        }) ?? []
      : [];

    let amenityBookingIdsFromStatusFilter: string[] = [];

    if (!showAll) {
      const overnightBookings =
        data?.amenityBookings
          .filter(
            (booking) =>
              booking.amenitySpace?.bookingAvailabilityType &&
              [
                BookingAvailabilityType.Overnight,
                BookingAvailabilityType.ByDay,
              ].includes(booking.amenitySpace?.bookingAvailabilityType) &&
              validUpcomingBookingStatuses.includes(booking.status) &&
              moment().isSameOrBefore(
                toLocalTime(booking.endTime, booking.timezone).endOf("day")
              )
          )
          .map((b) => b.amenityBookingId.toString()) ?? [];

      const hourlyBookings =
        data?.amenityBookings
          .filter(
            (booking) =>
              booking.amenitySpace?.bookingAvailabilityType &&
              [
                BookingAvailabilityType.Open,
                BookingAvailabilityType.Daily,
              ].includes(booking.amenitySpace?.bookingAvailabilityType) &&
              validUpcomingBookingStatuses.includes(booking.status) &&
              moment().isSameOrBefore(
                toLocalTime(booking.endTime, booking.timezone)
              )
          )
          .map((b) => b.amenityBookingId.toString()) ?? [];

      amenityBookingIdsFromStatusFilter = [
        ...overnightBookings,
        ...hourlyBookings,
      ];
    } else {
      amenityBookingIdsFromStatusFilter =
        data?.amenityBookings.map((ab) => ab.amenityBookingId.toString()) ?? [];
    }

    const amenitiesFilteredByStatus = amenityBookingItems.filter((b) =>
      amenityBookingIdsFromStatusFilter.includes(b.id)
    );

    let eventBookingItems: BookingItem[] = showEvents
      ? data?.events
          .filter((ev) => {
            if (!showAll) {
              return (
                !ev.isCancelled &&
                moment().isSameOrBefore(toLocalTime(ev.endDate, ev.timezone))
              );
            }

            return ev;
          })
          .map((ev) => {
            return {
              id: ev.eventId,
              title: ev.title,
              imageUri: ev.imageUrl,
              propertyName: ev.propertyName,
              date: toLocalTime(ev.startDate, ev.timezone).format(
                "MMM D, YYYY"
              ),
              time: `${toLocalTime(ev.startDate, ev.timezone).format(
                "h:mm a"
              )} – ${toLocalTime(ev.endDate, ev.timezone).format("h:mm a")}`,
              link: `/lease/${leaseId}/events/property/${ev.propertyId}/${ev.eventId}`,
              status: null,
              isUpcoming:
                !ev.isCancelled &&
                moment().isSameOrBefore(toLocalTime(ev.endDate, ev.timezone)),
              isLoading,
              bookingType: "Event",
              startTime: ev.startDate,
              endTime: ev.endDate,
              isPaymentRequired: false,
              invoiceUrl: null,
            };
          }) ?? []
      : [];

    const fitnessBookingItems: BookingItem[] = showFitness
      ? data?.fitness
          .filter((ev) => {
            if (!showAll) {
              return (
                !ev.isCancelled &&
                moment().isSameOrBefore(toLocalTime(ev.stopTime, ev.timezone))
              );
            }

            return ev;
          })
          .map((ev) => {
            return {
              title: ev.name,
              imageUri: ev.imageUrl,
              propertyName: ev.property?.name,
              date: toLocalTime(ev.startTime, ev.timezone).format(
                "MMM D, YYYY"
              ),
              time: `${toLocalTime(ev.startTime, ev.timezone).format(
                "h:mm a"
              )} – ${toLocalTime(ev.stopTime, ev.timezone).format("h:mm a")}`,
              id: ev.id.toString(),
              link: `/lease/${leaseId}/fitness/${ev.property?.propertyId}/${ev.id}`,
              status: "Fitness",
              isUpcoming:
                !ev.isCancelled &&
                moment().isSameOrBefore(toLocalTime(ev.stopTime, ev.timezone)),
              isLoading,
              bookingType: "Fitness",
              startTime: ev.startTime,
              endTime: ev.stopTime,
              isPaymentRequired: false,
              invoiceUrl: null,
            };
          }) ?? []
      : [];

    const sortedBookingItems = [
      ...amenitiesFilteredByStatus,
      ...eventBookingItems,
      ...fitnessBookingItems,
    ].filter((bi) => {
      if (searchTerm) {
        return bi.title?.toLowerCase().includes(searchTerm);
      }

      return bi;
    });

    if (showAll) {
      return sortedBookingItems.sort((a, b) =>
        a.startTime > b.startTime ? -1 : 1
      );
    }

    return sortedBookingItems.sort((a, b) =>
      a.startTime > b.startTime ? 1 : -1
    );
  }, [data, showAll, searchTerm, showAmenities, showEvents, showFitness]);
}

const validUpcomingBookingStatuses: BookingStatus[] = [
  "Approved",
  "Pending",
  "PendingPayment",
];

async function cancelAmenityBooking(bookingId: string) {
  const { data } = await axios.patch<BaseLivlyApiResponse<FitnessActivity>>(
    `${BASE_API_URL}/livly/amenity/bookings/${bookingId}`,
    { Status: "Cancelled" }
  );

  return data.Data;
}

async function cancelEventBooking(propertyId: string, eventId: string) {
  const { data } = await axios.post<BaseLivlyApiResponse<EventBooking>>(
    `${BASE_API_URL}/livly/events/property/${propertyId}/${eventId}/user`,
    {
      attendingCount: 0,
      isAttending: false,
    }
  );

  return data.Data;
}

type FitnessActivity = {
  id: number;
  name: string;
  price: number;
  maxParticipants: number;
  location: string;
  startTime: string;
  stopTime: string;
  duration: number;
  imageUrl: string;
  details: string;
  spotsLeft: number;
  property: {
    propertyId: number;
    name: string;
    timezone: string;
    latitude: number;
    longitude: number;
  } | null;
  provider: {
    userId: number;
    firstName: string;
    lastName: string;
    avatarUrl: string;
    dateJoined: string;
    servicesOffered: string[];
    certificates: string[];
    yearsOfExperience: string;
    aboutMe: string;
    funFact: string;
    socialMedia: {
      type: "Instagram" | "TikTok";
      username: string;
    }[];
  } | null;
  reply: {
    userId: number;
    isAttending: boolean;
    attendingCount: number;
  } | null;
};

async function replyToFitnessEvent(sessionId: string, isAttending: boolean) {
  const { data } = await axios.patch<BaseLivlyApiResponse<FitnessActivity>>(
    `${BASE_API_URL}/resident/fitness/${sessionId}/reply`,
    { isAttending }
  );

  return data.Data;
}

function BookingItem({
  id,
  title,
  imageUri,
  propertyName,
  date,
  time,
  status,
  link,
  isUpcoming,
  isLoading,
  bookingType,
  isPaymentRequired,
  invoiceUrl,
  onCancelBooking,
}: {
  id: string;
  title?: string;
  imageUri?: string;
  propertyName?: string;
  date: string;
  time?: string;
  status: BookingStatus | null;
  link: string;
  isUpcoming: boolean;
  isLoading: boolean;
  bookingType: "Amenity" | "Event" | "Fitness";
  isPaymentRequired: boolean;
  invoiceUrl: string | null;
  onCancelBooking?: (callback: () => void) => void;
}) {
  const variant = getBookingItemStatus(status);

  return (
    <div className="relative flex flex-col justify-between mb-6 rounded-lg md:p-4 md:mb-0 flex-1shadow-none text-inherit lg:shadow-md lg:flex-col lg:rounded-xl lg:border-gray-400">
      <div
        className={classNames(
          "absolute top-0 right-0 md:top-4 md:right-4 py-1 px-2 text-xs rounded-md border",
          {
            "border-red-400 bg-red-100 text-red-400": bookingType === "Amenity",
            "border-blue-400 bg-blue-100 text-blue-400":
              bookingType === "Event",
            "border-green-600 bg-green-100 text-green-600":
              bookingType === "Fitness",
          }
        )}
      >
        {bookingType}
      </div>
      <div className="flex flex-1">
        <div className="flex-shrink-0 mr-4">
          {imageUri ? (
            <img
              className="object-cover w-20 h-20 rounded-md"
              src={imageUri}
              alt={title}
            />
          ) : (
            <div className="flex items-center justify-center w-20 h-20 bg-gray-400 rounded-md">
              <FontAwesomeIcon icon={["fal", "calendar"]} className="w-6 h-6" />
            </div>
          )}
        </div>
        <div className="flex flex-col justify-between flex-1 pb-4 mb-4 border-b border-gray-400 md:border-none md:pb-0 md:mb-0">
          <div>
            <div>
              <span className="font-medium">{title}</span>
            </div>
            {status ? (
              <div className="my-2">
                <Badge type={variant.type}>{status}</Badge>
              </div>
            ) : null}
            <div className="flex items-center mt-1">
              <span className="w-6">
                <FontAwesomeIcon icon={["far", "calendar"]} />
              </span>
              <p className="text-sm">{date}</p>
            </div>
            {time && (
              <div className="flex items-center">
                <span className="w-6">
                  <FontAwesomeIcon icon={["far", "clock"]} />
                </span>
                <p className="text-sm">{time}</p>
              </div>
            )}
            <div className="flex items-center">
              <span className="w-6">
                <FontAwesomeIcon icon={["far", "building"]} />
              </span>
              <p className="text-sm">{propertyName}</p>
            </div>
          </div>
          {isUpcoming && (
            <BookingItemActions
              link={link}
              isPaymentRequired={isPaymentRequired}
              id={id}
              bookingStatus={status}
              isCanceling={isLoading}
              invoiceUrl={invoiceUrl}
              onCancelBooking={(callback) => {
                if (onCancelBooking) {
                  onCancelBooking(callback);
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function BookingItemActions({
  isPaymentRequired,
  bookingStatus,
  link,
  id,
  isCanceling,
  invoiceUrl,
  onCancelBooking,
}: {
  isPaymentRequired: boolean;
  bookingStatus: BookingStatus | null;
  link: string;
  id: string;
  isCanceling: boolean;
  invoiceUrl: string | null;
  onCancelBooking?: (callback: () => void) => void;
}) {
  const [pendingBookingNoticeOpen, setPendingBookingNoticeOpen] =
    useState(false);
  const [paidBookingNoticeOpen, setPaidBookingNoticeOpen] = useState(false);
  const [cancelPrompt, setCancelPrompt] = useState(false);

  const gotoInvoice = () => {
    if (invoiceUrl) {
      window.open(invoiceUrl, "_blank");
    }
  };

  const renderPaymentActions = () => {
    if (bookingStatus === "PendingPayment" || bookingStatus === "Pending") {
      return (
        <div className="flex items-center justify-end mt-4">
          <button
            onClick={() => {
              if (bookingStatus === "Pending") {
                setPendingBookingNoticeOpen(true);
              } else {
                gotoInvoice();
              }
            }}
            className="text-sm underline flex gap-2 items-center"
          >
            Pay
          </button>
          {onCancelBooking && (
            <button
              onClick={() => setCancelPrompt(true)}
              className="ml-2 text-sm underline"
            >
              Cancel booking
            </button>
          )}
        </div>
      );
    } else if (bookingStatus === "Approved") {
      return (
        <div className="flex items-center justify-end mt-4">
          <button
            onClick={() => setPaidBookingNoticeOpen(true)}
            className="text-sm underline flex gap-2 items-center"
          >
            Edit
          </button>
          {onCancelBooking && (
            <button
              onClick={() => setCancelPrompt(true)}
              className="ml-2 text-sm underline"
            >
              Cancel booking
            </button>
          )}
        </div>
      );
    }

    return null;
  };

  const renderBasicActions = () => {
    return (
      <div className="flex items-center justify-end mt-4">
        <Link to={link}>
          <button className="text-sm underline">Edit</button>
        </Link>
        {onCancelBooking && (
          <button
            onClick={() => setCancelPrompt(true)}
            className="ml-2 text-sm underline"
          >
            Cancel booking
          </button>
        )}
      </div>
    );
  };

  return (
    <div>
      {isPaymentRequired ? renderPaymentActions() : renderBasicActions()}
      <ConfirmationModal
        key={id}
        variant="warning"
        open={cancelPrompt}
        onClose={() => setCancelPrompt(false)}
        title="Cancel Booking"
        body="Are you sure you want to cancel this booking?"
        isLoading={isCanceling}
        onConfirm={() => {
          if (onCancelBooking) {
            onCancelBooking(() => setCancelPrompt(false));
          }
        }}
      />
      <ConfirmationModal
        variant="info"
        open={pendingBookingNoticeOpen}
        onClose={() => setPendingBookingNoticeOpen(false)}
        title="Booking Payment"
        body="You will be able to submit payment after the request is approved by your property."
        isLoading={false}
        onConfirm={() => {
          setPendingBookingNoticeOpen(false);
        }}
        buttonLabels={{
          confirm: "Ok",
        }}
      />
      <ConfirmationModal
        variant="info"
        open={paidBookingNoticeOpen}
        onClose={() => setPaidBookingNoticeOpen(false)}
        title="Edit/Cancel Booking"
        body="You have already submitted payment for this amenity.  Please contact your property manger directly if you need to edit or cancel the booking, so your payment can be altered or refunded."
        isLoading={false}
        onConfirm={() => {
          setPaidBookingNoticeOpen(false);
        }}
        buttonLabels={{
          confirm: "Ok",
        }}
      />
    </div>
  );
}

const getBookingItemStatus = (status: BookingStatus | null): BadgeProps => {
  switch (status) {
    case "Pending":
      return { type: "default" };
    case "Approved":
      return { type: "success" };
    case "Cancelled":
    case "Denied":
      return { type: "danger" };
    case "Event":
    case "Fitness":
      return { type: "default" };
    default:
      return { type: "success" };
  }
};
