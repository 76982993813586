import { useEffect, useState } from "react";
import { Outlet, useLoaderData, useParams } from "react-router-dom";
import useGetRentSettings from "../hooks/useGetRentSettings";
import { RentBalanceTypeEnum, RentSettings } from "../types/Rent";
import { PaymentAccount } from "../types/User";
import useGetPaymentAccounts from "@/hooks/useGetPaymentAccounts";
import { Spinner } from "@/components/Spinner";

export interface RentContext {
  rentSettings: RentSettings;
  paymentAccounts: PaymentAccount[];
  isBalanceBasedRent: boolean;
  selectedPaymentAccountId: number | null;
  setSelectedPaymentAccountId: (id: number) => void;
  selectedPaymentAccount: PaymentAccount | undefined;
  paymentType: "full" | "fixed" | null;
  setPaymentType: (paymentType: "full" | "fixed") => void;
  paymentAmount: string;
  setPaymentAmount: (paymentAmount: string) => void;
}

export default function RentProvider() {
  const params = useParams<{ leaseId: string; userId: string }>();
  const { data: paymentAccounts } = useGetPaymentAccounts();
  const defaultPaymentAccount = paymentAccounts?.find((pa) => pa.isDefault);
  const { data: rentSettings } = useGetRentSettings(params.leaseId!);

  const [selectedPaymentAccountId, setSelectedPaymentAccountId] = useState<
    number | null
  >(null);
  const [paymentType, setPaymentType] = useState<"full" | "fixed" | null>(null);
  const [paymentAmount, setPaymentAmount] = useState("");

  useEffect(() => {
    if (defaultPaymentAccount) {
      setSelectedPaymentAccountId(defaultPaymentAccount.id);
    }
  }, [defaultPaymentAccount]);

  if (rentSettings && paymentAccounts) {
    const isBalanceBasedRent =
      rentSettings?.rentBalanceType === RentBalanceTypeEnum.BalanceBased;

    const selectedPaymentAccount =
      paymentAccounts?.find((pa) => pa.id === selectedPaymentAccountId) ||
      defaultPaymentAccount;

    return (
      <div>
        <Outlet
          context={{
            rentSettings,
            paymentAccounts,
            isBalanceBasedRent,
            selectedPaymentAccountId,
            setSelectedPaymentAccountId,
            selectedPaymentAccount,
            paymentType,
            setPaymentType,
            paymentAmount,
            setPaymentAmount,
          }}
        />
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center h-screen">
      <Spinner color="livly" size="xl" />
    </div>
  );
}
