import { Navigate, useOutletContext } from "react-router-dom";
import { FeedbackExternalContext } from "./context";

function FeedbackExternalPage() {
  const feedbackContext = useOutletContext<FeedbackExternalContext>();

  const { token, feedbackId } = feedbackContext;

  if (token && feedbackId) {
    return <Navigate to="submit" replace />;
  }

  return <div>{JSON.stringify(feedbackContext, null, 2)}</div>;
}

export default FeedbackExternalPage;
