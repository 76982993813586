import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import classNames from "classnames";
import moment from "moment-timezone";
import { Link, LinkProps } from "react-router-dom";
import { Button } from "../../components/Button";
import CountDownTimer from "../../components/CountDownTimer";
import { LayoutBody, LayoutHeader } from "../../components/Layout";
import MakeAnotherPayment from "../../components/MakeAnotherPayment";
import ChecklistClickPayRentWidget from "../../components/Rent/RentClickPay";
import ChecklistRealPageRentWidget from "../../components/Rent/RealPageNotice";
import { ServiceProviderType, ServiceTypeEnum } from "../../types/Building";
import {
  OnboardingStatusEnum,
  BuildingTypeEnum,
  User,
  OnboardingStatus,
} from "../../types/User";
import { BASE_API_URL } from "../../utils/constants";
import useLivlyUser from "../../context/UserProvider";
import { toast } from "react-hot-toast";
import { useChecklist } from "@/context/ChecklistProvider";
import { useEffect, useState } from "react";
import BuildingOffers from "@/components/BuildingOffers";
import useGetService from "@/hooks/useGetService";
import { OnboaderPopOut } from "@/components/OnboaderPopOut";

export default function ChecklistLandingPage() {
  const { user } = useLivlyUser();
  const data = useChecklist();
  const rentService = useGetService(ServiceTypeEnum.Rent);

  const isMarketplaceEnabled =
    user.building.serviceTypes?.find(
      (st) =>
        st.enabled &&
        st.serviceType === ServiceTypeEnum.MarketPlace &&
        st.permissionAccessLevel === "FullAccess"
    ) ?? false;
  const isChecklistComplete = data.onboardingStatuses
    .filter((ob) => ob.enabled)
    .every((ob) => ob.isCompleted || ob.isSkipped);
  const isMarketplaceFooterVisible =
    isMarketplaceEnabled && isChecklistComplete;

  //get the index of the first incomplete onboarding step
  const firstIncompleteStepIndex = data.onboardingStatuses.findIndex(
    (obs) => obs.enabled && !obs.isCompleted && !obs.isSkipped
  );

  useEffect(() => {
    if (firstIncompleteStepIndex) {
      setTimeout(() => {
        document
          .getElementById(firstIncompleteStepIndex.toString())
          ?.scrollIntoView();
      }, 10);
    }
  }, [firstIncompleteStepIndex]);

  const isNonFlatsRentEnabled =
    rentService?.enabled &&
    rentService?.serviceProviderType === ServiceProviderType.External &&
    rentService?.url;

  const handlePaymentExternal = () => {
    if (rentService?.url) {
      window.open(rentService.url, "_blank");
    }
  };

  return (
    <div>
      <LayoutHeader title="Move-In Checklist" />
      {isNonFlatsRentEnabled && (
        <OnboaderPopOut onPaymentClick={() => handlePaymentExternal()} />
      )}

      <ChecklistHeader
        isChecklistComplete={isChecklistComplete}
        onboardingStatuses={data.onboardingStatuses}
      />
      <LayoutBody
        className={classNames("max-w-3xl mx-auto", {
          "pb-28": isMarketplaceFooterVisible,
        })}
      >
        <ChecklistClickPayRentWidget />
        <ChecklistRealPageRentWidget />
        <MakeAnotherPayment isChecklistComplete={isChecklistComplete} />
        {isChecklistComplete && (
          <div>
            <h2 className="text-2xl font-noe-display-black">
              checklist complete
            </h2>
            <p className="text-sm">your move-in date is</p>
            <p className="text-sm font-bold">
              {moment(user.leaseMoveInDate).format("MMMM D, YYYY")}
            </p>
          </div>
        )}
        <ul className="p-0 mt-8 ml-0">
          <ChecklistItemService statusTypeId={OnboardingStatusEnum.profile} />
          <ChecklistItemService statusTypeId={OnboardingStatusEnum.insurance} />
          <ChecklistItemService
            statusTypeId={OnboardingStatusEnum.communitySurvey}
          />
          <ChecklistItemService
            statusTypeId={OnboardingStatusEnum.schduleMoveIn}
          />
          <ChecklistItemService statusTypeId={OnboardingStatusEnum.utilities} />
          <ChecklistItemService
            statusTypeId={OnboardingStatusEnum.petAndVehicles}
          />
          <ChecklistItemService statusTypeId={OnboardingStatusEnum.payment} />
          <ChecklistItemService statusTypeId={OnboardingStatusEnum.checkout} />
          {isMarketplaceFooterVisible && <div className="h-16" />}
        </ul>
      </LayoutBody>
      {isMarketplaceFooterVisible && <MarketplaceFooter user={user} />}
    </div>
  );
}

export type StatusTypeDetails = {
  title: string;
  icon: IconProp;
  alternateIcon?: ({ className }: { className?: string }) => JSX.Element;
  description: string;
  onboardingStatuses: OnboardingStatus[];
  onboardingStatus: OnboardingStatus;
  ordinal: number;
} & Pick<LinkProps, "to" | "state">;

export function useGetStatusTypeDetails(
  statusTypeId: OnboardingStatusEnum,
  user: User
): StatusTypeDetails {
  const data = useChecklist();
  const onboardingStatuses: OnboardingStatus[] = data.onboardingStatuses ?? [];
  const {
    presentation: { buildingType },
    propertyId,
  } = user;

  const onboardingStatus = onboardingStatuses.find(
    (obs) => obs.statusTypeId === statusTypeId
  );
  if (!onboardingStatus) {
    throw new Error("Onboarding status not available!");
  }

  switch (statusTypeId) {
    case OnboardingStatusEnum.profile:
      return {
        title: "your profile",
        description:
          buildingType === BuildingTypeEnum.Apartments
            ? "review your personal and lease information"
            : "review your personal and living information",
        icon: ["far", "user"],
        to: "profile",
        onboardingStatuses,
        onboardingStatus,
        ordinal: 1,
      };
    case OnboardingStatusEnum.insurance:
      return {
        title:
          buildingType === BuildingTypeEnum.Apartments
            ? `renters insurance (required)`
            : `insurance (required)`,
        description:
          buildingType === BuildingTypeEnum.Apartments
            ? `purchase a policy or upload proof of coverage`
            : "upload proof of coverage",
        icon: ["far", "umbrella"],
        to: "insurance",
        onboardingStatuses,
        onboardingStatus,
        ordinal: 2,
      };
    case OnboardingStatusEnum.communitySurvey:
      return {
        title: "community survey",
        description: "help us get to know you",
        icon: "list",
        to: `community-survey`,
        onboardingStatuses,
        onboardingStatus,
        ordinal: 3,
      };
    case OnboardingStatusEnum.schduleMoveIn:
      return {
        title: "schedule move-in",
        description: "confirm the date you are physically moving in",
        icon: ["far", "truck-ramp-couch"],
        to: `schedule-move-in`,
        onboardingStatuses,
        onboardingStatus,
        ordinal: 4,
      };
    case OnboardingStatusEnum.utilities:
      return {
        title: "utilities",
        description: "set up your required utilities and accounts",
        icon: ["far", "lightbulb-on"],
        to: `utilities/${user.userId}`,
        onboardingStatuses,
        onboardingStatus,
        ordinal: 5,
      };
    case OnboardingStatusEnum.petAndVehicles:
      return {
        title: "Pet & Vehicle Info",
        description: "Confirm if you have any pets or vehicles",
        icon: ["fas", "info-circle"],
        to: `pets-vehicles/${propertyId}`,
        onboardingStatuses,
        onboardingStatus,
        ordinal: 6,
      };
    case OnboardingStatusEnum.payment:
      return {
        title: "add default payment",
        description:
          "add a payment method to pay rent and other on-demand services",
        icon: ["far", "credit-card"],
        to: "wallet",
        state: {
          from: {
            to: `..`,
            label: "Move-in Checklist",
          },
        },
        onboardingStatuses,
        onboardingStatus,
        ordinal: 7,
      };
    case OnboardingStatusEnum.checkout:
      return {
        title: "checkout",
        description:
          buildingType === BuildingTypeEnum.Apartments
            ? `review your move-in checklist and pay your first month's rent`
            : "review your move-in requirements and make any required payment",
        icon: ["far", "cart-shopping"],
        to: "cart",
        onboardingStatuses,
        onboardingStatus,
        ordinal: 8,
      };
    default:
      throw new Error("unsupported onboarding status");
  }
}

function ChecklistItemService({
  statusTypeId,
}: {
  statusTypeId: OnboardingStatusEnum;
}) {
  const { user } = useLivlyUser();
  const data = useChecklist();
  const details = useGetStatusTypeDetails(statusTypeId, user);
  const onboardingStatus = data.onboardingStatuses.find(
    (obs) => obs.statusTypeId === statusTypeId
  );
  if (!onboardingStatus || onboardingStatus?.enabled === false) {
    return null;
  }
  const enabledOnboardingServices = data.onboardingStatuses.filter(
    (s) => s.enabled
  );
  const currentServiceIndex = enabledOnboardingServices
    .map((s) => s.statusTypeId)
    .indexOf(statusTypeId);
  const previousSteps = enabledOnboardingServices.slice(0, currentServiceIndex);
  const areAllPreviousStepsComplete = previousSteps.every(
    (s) => s.isCompleted || s.isSkipped
  );

  return (
    <ChecklistSectionItem
      {...details}
      isDisabled={
        statusTypeId !== OnboardingStatusEnum.profile &&
        !areAllPreviousStepsComplete
      }
      isCompleted={onboardingStatus.isCompleted}
      isSkipped={onboardingStatus.isSkipped}
      alternateIcon={details.alternateIcon}
      notes={onboardingStatus.notes}
    />
  );
}

function ChecklistSectionItem({
  ordinal,
  title,
  description,
  icon,
  isDisabled,
  isCompleted,
  isSkipped,
  to,
  state,
  notes,
  alternateIcon,
}: {
  ordinal: number;
  title: string;
  description: string;
  icon: IconProp;
  isDisabled: boolean;
  isCompleted: boolean;
  isSkipped: boolean;
  notes?: string;
  alternateIcon?: ({ className }: { className?: string }) => JSX.Element;
} & Pick<LinkProps, "to" | "state">) {
  return (
    <li className="flex gap-4" id={ordinal.toString()}>
      {isDisabled ? (
        <ChecklistItemContent
          title={title}
          description={description}
          icon={icon}
          isDisabled
          isCompleted={isCompleted}
          notes={notes}
          alternateIcon={alternateIcon}
        />
      ) : (
        <Link to={to} state={state} className="block w-full">
          <ChecklistItemContent
            title={title}
            description={description}
            icon={icon}
            isDisabled={false}
            isCompleted={isCompleted}
            notes={notes}
            alternateIcon={alternateIcon}
          />
        </Link>
      )}
      <div>
        <div
          className={classNames(
            "rounded-full border-2 h-10 w-10 flex items-center justify-center",
            {
              "border-blue-600": !isDisabled,
              "bg-blue-600": isCompleted,
            }
          )}
        >
          {isCompleted ? (
            <FontAwesomeIcon icon="check" className="text-lg text-white" />
          ) : isSkipped ? (
            <FontAwesomeIcon
              icon={["far", "forward"]}
              className="text-lg text-blue-600"
            />
          ) : null}
        </div>
      </div>
    </li>
  );
}

function ChecklistItemContent({
  title,
  description,
  icon,
  alternateIcon: AlternateIcon,
  isDisabled,
  isCompleted,
  notes,
}: {
  title: string;
  description: string;
  alternateIcon?: ({ className }: { className?: string }) => JSX.Element;
  icon: IconProp;
  isDisabled: boolean;
  isCompleted: boolean;
  notes?: string;
}) {
  return (
    <>
      <div
        className={classNames(
          "border border-gray-200 bg-white p-5 rounded-lg mb-4 flex-1 transition duration-200",
          isDisabled ? "" : "hover:bg-gray-50"
        )}
      >
        {AlternateIcon ? (
          <AlternateIcon
            className={classNames(
              "text-3xl text-gray-400",
              isDisabled ? "text-gray-400" : "text-red-300"
            )}
          />
        ) : (
          <FontAwesomeIcon
            icon={icon}
            className={classNames(
              "text-3xl text-gray-400",
              isDisabled ? "text-gray-400" : "text-red-300"
            )}
          />
        )}

        <h2
          className={classNames(
            "font-geometria-bold text-lg mt-2",
            isDisabled ? "text-gray-400" : ""
          )}
        >
          {title}
        </h2>
        <p className={classNames("text-sm", isDisabled ? "text-gray-400" : "")}>
          {description}
        </p>
        {notes ? <p className="mt-8 text-sm">{notes}</p> : null}
        <div className="mt-12">
          {isCompleted ? (
            <span
              className={classNames(
                "text-sm flex items-center gap-2",
                isDisabled ? "text-gray-400" : "text-green-500"
              )}
            >
              <FontAwesomeIcon icon="check" />
              <span>Completed</span>
            </span>
          ) : (
            <span
              className={classNames(
                "text-sm flex items-center gap-2",
                isDisabled ? "text-gray-400" : "text-red-300"
              )}
            >
              tap to begin
              <FontAwesomeIcon icon="chevron-right" />
            </span>
          )}
        </div>
      </div>
    </>
  );
}

export async function postService(
  userId: number,
  leaseId: string,
  onboardingStatus: OnboardingStatus
) {
  const result = await axios.post(
    `${BASE_API_URL}/livly/users/${userId}/useronboardingstatus?leaseId=${leaseId}`,
    JSON.stringify(onboardingStatus),
    { headers: { "Content-Type": "application/json" } }
  );

  return result;
}

export function usePostService(
  userId: number,
  leaseId: string,
  onboardingStatus: OnboardingStatus
) {
  const [status, setStatus] = useState<"saving" | "skipping" | null>(null);
  const { refetch } = useChecklist();
  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (data: OnboardingStatus) => postService(userId, leaseId, data),
    /* onMutate: async (newStatus) => {
      const previousStatuses: OnboardingStatus[] =
        queryClient.getQueryData([
          "onboarding-statuses",
          leaseId,
          userId.toString(),
        ]) ?? [];

      queryClient.setQueryData(
        ["onboarding-statuses", leaseId, userId.toString()],
        () =>
          previousStatuses.map((s) => {
            if (s.statusTypeId === newStatus.statusTypeId) {
              return {
                ...s,
                ...newStatus,
              };
            }

            return s;
          })
      );

      return { previousStatuses };
    }, */
    onSuccess: () => {
      refetch();
    },
    onSettled: () => {
      setStatus(null);
    },
  });

  const updateOnboardingStatus = async (data: OnboardingStatus) => {
    try {
      setStatus("saving");
      await mutateAsync(data);
    } catch (e) {
      toast.error(
        e instanceof Error ? e.message : "Error updating onboarding status"
      );
    }
  };

  const skipOnboardingStep = async () => {
    setStatus("skipping");
    await mutateAsync({ ...onboardingStatus, isSkipped: true });
  };

  return { updateOnboardingStatus, skipOnboardingStep, isLoading, status };
}

function ChecklistHeader({
  isChecklistComplete,
  onboardingStatuses,
}: {
  isChecklistComplete: boolean;
  onboardingStatuses: OnboardingStatus[];
}) {
  const { user } = useLivlyUser();

  const daysUntilMoveIn = moment(user.leaseMoveInDate).diff(moment(), "days");

  if (!isChecklistComplete) {
    return (
      <div className="max-w-3xl px-4 pt-6 mx-auto sm:px-6 md:px-8">
        <h2 className="text-sm">welcome to {user.buildingName}</h2>
        <h3 className="mt-1 text-4xl font-light font-noe-display-black">
          {user.firstName}
        </h3>
        <p className="mt-4 text-sm">your official move-in date is:</p>
        <p className="font-bold">
          {moment(user.leaseMoveInDate).format("MMMM D, YYYY")}{" "}
          {daysUntilMoveIn > 0 && `(${daysUntilMoveIn} days)`}
        </p>
        <ChecklistProgress onboardingStatuses={onboardingStatuses} />
      </div>
    );
  }

  let appLink = "";
  if (import.meta.env.VITE_ENVIRONMENT === "PRODUCTION") {
    appLink = "https://livly.app.link/vQbPtuiQX4";
  } else {
    appLink = "https://livly.test-app.link/28TYfYIQX4";
  }

  return (
    <div>
      <div className="md:hidden">
        <div className="flex items-center px-6 py-3 -mt-2 bg-white border-b border-gray-200 drop-shadow-md">
          <div className="flex-1">
            <p className="font-bold">Livly app</p>
            <p className="text-sm">download before you move in.</p>
          </div>
          <a href={appLink} target="_blank" rel="noopener noreferrer">
            <Button size="xs" color="primary">
              Download app
            </Button>
          </a>
        </div>
      </div>
      <div className="bg-[#faede4] hidden md:block">
        <div className="relative flex max-w-3xl px-4 mx-auto overflow-hidden sm:px-6 md:px-8">
          <div className="flex-1 flex-shrink-0 pt-20 pb-10">
            <h1 className="text-4xl font-noe-display-black">
              Checklist complete
            </h1>
            <p className="mt-4">download the app before you move in.</p>
            <div className="flex gap-4 mt-4">
              <a href={appLink} target="_blank" rel="noopener noreferrer">
                <img
                  src="/apple_badge.png"
                  alt="download in apple store"
                  className="h-10"
                />
              </a>
              <a href={appLink} target="_blank" rel="noopener noreferrer">
                <img
                  src="/google_badge.png"
                  alt="download in google play store"
                  className="h-10"
                />
              </a>
            </div>
            <p className="mt-6 text-sm font-geometria-medium">
              your move-in date is
            </p>
            <p className="text-lg font-geometria-bold">
              {moment(user.leaseMoveInDate).format("MMMM D, YYYY")}{" "}
              {daysUntilMoveIn > 0 && `(${daysUntilMoveIn} days)`}
            </p>
            <p className="mt-4 text-sm font-light">
              There is nothing left for you to complete before your move-in.
              Come back in the Livly app after your move-in date to access more
              features.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function MarketplaceFooter({ user }: { user: User }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="fixed bottom-0 left-0 right-0 p-4 bg-white border-t border-gray-200 md:bg-gray-200 md:left-64">
      <div className="flex justify-center text-xs md:text-base">
        <CountDownTimer
          date={user.leaseMoveInDate}
          timezone={user.building.timezone}
        />
      </div>
      <div className="flex justify-between mt-4">
        <p className="text-gray-500">Move-in date</p>
        <p>{moment(user.leaseMoveInDate).format("MMMM D, YYYY")}</p>
      </div>
      <div className="flex items-center justify-between mt-4 md:mt-0">
        <div>
          <p>Checklist</p>
          <p className="text-green-500">Completed</p>
        </div>

        <div>
          <Link to="building-offers" state={{ hideNavbar: true }}>
            <Button
              className="bg-gray-900 hover:bg-gray-700"
              onClick={() => setIsOpen(true)}
            >
              Prep for move in
            </Button>
          </Link>
        </div>
      </div>
      <BuildingOffers isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  );
}

function ChecklistProgress({
  onboardingStatuses,
}: {
  onboardingStatuses: OnboardingStatus[];
}) {
  const totalSteps = onboardingStatuses.filter((s) => s.enabled);
  const completedOrSkippedSteps = totalSteps.filter(
    (s) => s.isCompleted || s.isSkipped
  );

  const percentage = Math.floor(
    (completedOrSkippedSteps.length / totalSteps.length) * 100
  );

  return (
    <div className="flex items-center gap-2 mt-4">
      <div className="flex items-center h-1 bg-gray-200 rounded-lg w-52">
        <div
          className="h-1 bg-blue-400 rounded-tl-lg rounded-bl-lg"
          style={{ width: `${percentage}%` }}
        />
      </div>
      <span className="text-sm">{percentage}%</span>
    </div>
  );
}
