export type CartItem = {
  type: CartItemEnum;
  itemId: number;
  paymentMethodId: number | null;
  cost: number;
  paymentAmount: number;
  paymentInterestAmount: number;
  additionalData: Record<string, string | number>;
  transactionId: string;
  livlyTransactionId: number;
  feeData: FeeData;
  externalPaymentMethodDetail: {
    creditCardInformation?: {
      type: string;
      number: string;
      cvc: string;
      expirationMonth: string;
      expirationYear: string;
    };
    bankInformation?: {
      accountNumber: string;
      routingNumber: string;
    };
  };
  error?: string[];
};

export type FeeData = {
  feeTypeId: number;
  feeType: string;
  value: number;
  calculatedFeeAmount: number;
  totalAmount: number;
};

export enum CartItemEnum {
  RENT = 1,
  INSURANCE = 2,
}

export enum PaymentMethodType {
  CREDITCARD = 1,
  ACH = 2,
}
