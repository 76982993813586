import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Link, LinkProps, useNavigate, useParams } from "react-router-dom";
import { BuildingTypeEnum, User } from "../types/User";

import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { AnimatePresence, motion } from "framer-motion";
import Avatar from "./Avatar";
import { ServiceTypeEnum } from "@/types/Building";

export default function UserMenu({ user }: { user: User }) {
  const params = useParams<{ leaseId: string }>();
  const navigate = useNavigate();
  const isCommunitySurveyEnabled =
    user.building.serviceTypes?.find(
      (st) => st.serviceType === ServiceTypeEnum.CommSurvey
    )?.enabled ?? false;

  const onLogout = () => {
    navigate("/logout");
  };

  return (
    <Menu as="div" className="flex flex-shrink-0 border-t border-gray-200">
      <Menu.Button className="flex items-center w-full p-4 hover:bg-slate-50">
        <Avatar
          size="sm"
          src={user.avatarURI}
          name={`${user.firstName} ${user.lastName}`}
        />
        <div className="flex-1 ml-3 text-left">
          <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
            {user.firstName} {user.lastName[0]}.
          </p>
        </div>
        <div>
          <FontAwesomeIcon icon={faChevronRight} size="xs" />
        </div>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute w-56 mt-2 origin-top-right translate-x-full bg-white divide-y divide-gray-100 rounded-md shadow-lg bottom-3 -right-2 ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="px-1 py-1">
            <div className="pb-1">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    className={`${
                      active ? "bg-slate-50" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    to={`/lease/${params.leaseId}/my-lease`}
                  >
                    {user.presentation?.buildingType === BuildingTypeEnum.Condos
                      ? "My Home"
                      : "My Lease"}
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    className={`${
                      active ? "bg-slate-50" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    to={`/lease/${params.leaseId}/wallet`}
                  >
                    Wallet
                  </Link>
                )}
              </Menu.Item>
              {isCommunitySurveyEnabled && (
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className={`${
                        active ? "bg-slate-50" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      to={`/lease/${params.leaseId}/community-survey`}
                    >
                      Community Survey
                    </Link>
                  )}
                </Menu.Item>
              )}
              <Menu.Item>
                {({ active }) => (
                  <Link
                    className={`${
                      active ? "bg-slate-50" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    to={`/lease/${params.leaseId}/checklist/${user.userId}`}
                  >
                    Move-In Checklist
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <Link
                    className={`${
                      active ? "bg-slate-50" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    to={`settings`}
                  >
                    Settings
                  </Link>
                )}
              </Menu.Item>
            </div>

            {import.meta.env.VITE_ENVIRONMENT === "DEV" && (
              <>
                <hr />
                <div>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        className={`${
                          active ? "bg-slate-50" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        to={`/lease/${params.leaseId}/test-webview`}
                      >
                        Test Webviews
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        className={`${
                          active ? "bg-slate-50" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        to={`/features`}
                      >
                        Features
                      </Link>
                    )}
                  </Menu.Item>
                </div>
              </>
            )}

            <hr />
            <div className="pt-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-slate-50" : "text-gray-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={onLogout}
                  >
                    Logout
                  </button>
                )}
              </Menu.Item>
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

export function MobileUserMenu({ user }: { user: User }) {
  const params = useParams<{ leaseId: string }>();

  return (
    <Popover className="w-full lg:hidden">
      {({ open }) => (
        <>
          <Popover.Button
            className="w-full relative z-10 -m-2 inline-flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 [&:not(:focus-visible)]:focus:outline-none"
            aria-label="Toggle site navigation"
          >
            {({ open }) => (
              <div className="flex items-center">
                <div>
                  <Avatar
                    size="sm"
                    src={user.avatarURI}
                    name={`${user.firstName} ${user.lastName}`}
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-left text-gray-700 group-hover:text-gray-900">
                    {user.firstName} {user.lastName[0]}.
                  </p>
                </div>
              </div>
            )}
          </Popover.Button>
          <AnimatePresence initial={false}>
            {open && (
              <>
                <Popover.Overlay
                  static
                  as={motion.div}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                />
                <Popover.Panel
                  static
                  as={motion.div}
                  initial={{ opacity: 0, y: 32 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{
                    opacity: 0,
                    y: 32,
                    transition: { duration: 0.2 },
                  }}
                  className="absolute inset-x-0 bottom-0 z-0 px-6 pt-6 pb-20 origin-bottom shadow-2xl rounded-t-2xl bg-gray-50 shadow-gray-900/20"
                >
                  <ul className="space-y-4">
                    <li>
                      <MobileNavLink to={`/lease/${params.leaseId}/my-lease`}>
                        <div className="flex items-center w-full gap-2">
                          <FontAwesomeIcon icon="home-alt" />
                          <span>My Lease</span>
                        </div>
                      </MobileNavLink>
                    </li>
                    <li>
                      <MobileNavLink to={`/lease/${params.leaseId}/wallet`}>
                        <div className="flex items-center w-full gap-2">
                          <FontAwesomeIcon icon="wallet" />
                          <span>Wallet</span>
                        </div>
                      </MobileNavLink>
                    </li>
                    <li>
                      <MobileNavLink
                        to={`/lease/${params.leaseId}/checklist/${user.userId}`}
                      >
                        <div className="flex items-center w-full gap-2">
                          <FontAwesomeIcon icon="list-check" />
                          <span>Move-In Checklist</span>
                        </div>
                      </MobileNavLink>
                    </li>

                    {import.meta.env.VITE_ENVIRONMENT === "DEV" && (
                      <>
                        <li>
                          <MobileNavLink
                            to={`/lease/${params.leaseId}/test-webview`}
                          >
                            <div className="flex items-center w-full gap-2">
                              <FontAwesomeIcon icon={["fad", "mobile"]} />
                              <span>Web views</span>
                            </div>
                          </MobileNavLink>
                        </li>
                        <li>
                          <MobileNavLink to={`/features`}>
                            <div className="flex items-center w-full gap-2">
                              <FontAwesomeIcon icon={["fad", "wrench"]} />
                              <span>Features</span>
                            </div>
                          </MobileNavLink>
                        </li>
                      </>
                    )}
                    <li>
                      <MobileNavLink to={`settings`}>
                        <div className="flex items-center w-full gap-2">
                          <FontAwesomeIcon icon={["fas", "cog"]} />
                          <span>Settings</span>
                        </div>
                      </MobileNavLink>
                    </li>
                    <li>
                      <MobileNavLink to="/logout">
                        <div className="flex items-center w-full gap-2">
                          <FontAwesomeIcon icon={["fas", "sign-out"]} />
                          <span>Logout</span>
                        </div>
                      </MobileNavLink>
                    </li>
                  </ul>
                </Popover.Panel>
              </>
            )}
          </AnimatePresence>
        </>
      )}
    </Popover>
  );
}

const MobileNavLink: React.FC<LinkProps & JSX.IntrinsicElements["button"]> = ({
  children,
  to,
}) => {
  return (
    <Popover.Button
      as={Link}
      to={to}
      className="block text-base leading-7 tracking-tight text-gray-600 cursor:pointer hover:bg-gray-50 hover:text-gray-900"
    >
      {children}
    </Popover.Button>
  );
};
