import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { RentHistoryItem } from "../../types/Rent";
import { TransactionType } from "../../types/User";
import Avatar from "../Avatar";
import { AutoPayIcon } from "./Autopay/AutoPayIcon";
import useLivlyUser from "../../context/UserProvider";

export interface RentHistoryListItemProps {
  rentHistoryItem: RentHistoryItem;
}

const RentHistoryListItem: React.FC<RentHistoryListItemProps> = ({
  rentHistoryItem,
}) => {
  const { t } = useTranslation();
  const { user } = useLivlyUser();

  const isCharge: boolean =
    rentHistoryItem.transactionTypeId === TransactionType.CHARGE;
  const isPaidByCurrentUser = rentHistoryItem.payerUserId === user.userId;
  const paymentUser = isPaidByCurrentUser
    ? user
    : user.roommates?.find((r) => r.userId === rentHistoryItem.payerUserId);
  const buildingLogoUrl: string | undefined = user.building.logoUrl
    ? user.building.logoUrl
    : undefined;
  const paymentMethodUsed = user.paymentAccounts?.find(
    (p) => p.id === rentHistoryItem.paymentMethodId
  )!;

  return (
    <li
      key={rentHistoryItem.transactionId}
      className="flex items-center gap-4"
      data-test="rent-history-list-item"
    >
      {isCharge ? (
        <Avatar size="sm" src={buildingLogoUrl} name={user.buildingName} />
      ) : (
        <Avatar
          size="sm"
          src={paymentUser?.avatarURI}
          name={`${paymentUser?.firstName} ${paymentUser?.lastName}`}
        />
      )}
      <div className="flex-1">
        <p>
          {isCharge
            ? rentHistoryItem.chargeCode ?? "Rent Charge"
            : paymentUser?.fullName || "Resident payment"}
        </p>
        <p className="text-sm text-gray-500">
          {isCharge
            ? rentHistoryItem.notes ?? rentHistoryItem.description
            : isPaidByCurrentUser
            ? paymentMethodUsed?.description
            : paymentUser?.userType || ""}
        </p>
        {rentHistoryItem.status === "pending" && (
          <p className="text-sm italic font-light text-gray-500">
            {t("rent.payment-history.payment-pending")}
          </p>
        )}
        {rentHistoryItem.status === "failed" && (
          <p className="text-sm font-light text-red-500">
            {t("rent.payment-history.payment-failed")}
          </p>
        )}
      </div>
      <div className="text-right">
        <div className="flex justify-end gap-2">
          {rentHistoryItem.isAutoPay && <AutoPayIcon />}
          <p
            className={classNames({
              "text-green-600":
                !isCharge && rentHistoryItem.status === "succeeded",
              "text-red-600": !isCharge && rentHistoryItem.status === "failed",
            })}
          >
            {rentHistoryItem.formattedAmount}
          </p>
        </div>
        {rentHistoryItem.formattedFee && (
          <p className="text-sm font-light text-gray-500">
            Fee: {rentHistoryItem.formattedFee}
          </p>
        )}
      </div>
    </li>
  );
};

export { RentHistoryListItem };
