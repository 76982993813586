import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import { Button } from "../../components/Button";
import Layout from "../../components/Layout";

export default function AddPaymentMethodPage() {
  const returnData = useLocation().state as { to: string; label: string };

  return (
    <Layout
      title="Add Payment Method"
      back={
        returnData?.to
          ? { ...returnData }
          : { label: "Wallet", to: "../wallet" }
      }
    >
      <div className="max-w-lg mx-auto">
        <p>
          Let's begin by selecting a payment method you would like to use at
          checkout.
        </p>
        <div className="flex flex-col max-w-xs gap-2 mx-auto mt-6 text-center">
          <Link to="card" state={returnData}>
            <Button
              color="default"
              size="large"
              className="relative flex items-center justify-center w-full text-center"
            >
              <span className="absolute left-4">
                <FontAwesomeIcon
                  icon={["far", "credit-card"]}
                  className="text-xl"
                />
              </span>
              Credit/Debit card
            </Button>
          </Link>
          <span>or</span>
          <Link to="ach" state={returnData}>
            <Button
              color="default"
              size="large"
              className="relative flex items-center justify-center w-full text-center"
            >
              <span className="absolute left-4">
                <FontAwesomeIcon
                  icon={["far", "university"]}
                  className="text-xl"
                />
              </span>
              Link bank account
            </Button>
          </Link>
        </div>
      </div>
    </Layout>
  );
}
