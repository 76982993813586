import React, { useEffect, useState } from "react";

import { Task } from "@/types/User";
import { AnimatePresence, motion } from "framer-motion";
import { Button } from "@/components/Button";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PollTask = ({ leaseId, task }: { leaseId: number; task: Task }) => {
  return (
    <AnimatePresence>
      <PollNotice
        name={task?.metaData?.title ?? ""}
        description={task?.metaData?.description ?? ""}
        id={task?.metaData?.pollId ?? null}
        leaseId={leaseId}
      />
    </AnimatePresence>
  );
};

const PollNotice = ({
  name,
  description,
  id,
  leaseId,
}: {
  name: string;
  description: string;
  id: number | null;
  leaseId: number;
}) => {
  const animationEnabled = sessionStorage.getItem("animated");

  useEffect(() => {
    // Disable animation on subsequent renders
    sessionStorage.setItem("animated", "true");
  }, []); // Run this effect only once during the initial render

  const data = sessionStorage.getItem("close-poll");
  const [open, setIsOpen] = useState(!data ? true : false);

  if (open) {
    return (
      <motion.div
        className="p-4  bg-white rounded-xl shadow-md  mb-4"
        initial={!animationEnabled ? { scale: 0 } : false}
        animate={!animationEnabled ? { scale: 1 } : false}
        exit={{ scale: 0 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 20,
        }}
      >
        <div>
          <div className="flex justify-between">
            <div className="flex items-start">
              <img
                src="/PollingIcon.svg"
                alt="poll"
                width={30}
                className="mr-3"
              />
              <div className="flex-1 ">
                <p className="text-lg font-medium">Poll</p>
              </div>
            </div>
            <div
              onClick={() => {
                setIsOpen(false);
                sessionStorage.setItem("close-poll", "true");
              }}
              className="cursor-pointer"
            >
              <FontAwesomeIcon icon="times" className="text-sm font-gray-500" />
            </div>
          </div>
          <div className="mt-2">
            <p className="text-xl font-normal break-words">{name}</p>
          </div>
          <div className="mt-2">
            <p className="text-sm text-gray-700 break-words">{description}</p>
          </div>
          <Link
            to={`/lease/${leaseId}/polls/${id}`}
            className="block w-fit mt-4"
          >
            <Button color="secondary" className="w-fit">
              Take Poll
            </Button>
          </Link>
        </div>
      </motion.div>
    );
  }

  return null;
};

export default PollTask;
