import moment, { Moment } from "moment-timezone";
import { Availability, BaseAmenitySpace } from "../types/Bookings";

export const getFormattedTime = (
  startTime: string | Date | null,
  endTime: string | Date | null
) => {
  const endTimeDisplay = endTime || startTime;
  const isSpanningMeridiem =
    startTime &&
    endTime &&
    moment(startTime).format("a") !== moment(endTime).format("a");

  const startTimeFormatted = startTime
    ? moment(startTime)
        .format(`h:mm${isSpanningMeridiem ? " a" : ""}`)
        .replace(":00", "")
    : null;
  const endTimeFormatted = endTimeDisplay
    ? moment(endTimeDisplay).format("h:mm a").replace(":00", "")
    : null;

  return {
    start: startTimeFormatted,
    end: endTimeFormatted,
    startEnd:
      startTime || endTime
        ? `${startTimeFormatted}–${endTimeFormatted}`
        : "select time",
  };
};

//ASSUMPTION: null minimum duration (or no minimum booking) will be treated as 15 minutes in code (or 1 timeslot)
//1. Determine if selection has already been selected, if so
//  1. Determine if selection is in the middle of another selection, if so clear
//  2. Determine if selection is at the extremity of another selection
//      1. If so, check if the total is above the minimum, if so clear the one item
//      2. If so, check if hte total is above the minimum, if not clear all
//  3. Determine if the minimum selection is available, if not error
//  4. Once completing the above steps successfully, select the new minimum selection
//2. If not already been selected
//  1. Determine if there are no other selections, if so
//      1. Determine if minimum selection is available, if not error
//      2. Once completing the above steps successfully , select the new minimum selection
//  2. If there are other selections
//      1. Determine if other selections + current selection are equal to or less than max duration, if so
//          1. Extend current booking to include new selection
//      2. If not (1)
//          1. Clear original selection
//          2. Determine if minimum selection is available, if not error
//          3. Once completing the above steps successfully, select the new minimum selection

const addSlots = (startIndex: number, endIndex: number) => {
  const slots = [];

  for (let i = startIndex; i < endIndex; i++) {
    slots.push(i);
  }

  return slots;
};

const getMinimumBookableSlots = (
  minBookableSlots: number,
  availableSlots: Availability[],
  selectedSlotIndex: number,
  durationInMinutes: number | null
) => {
  const remainingAvailableSlots = availableSlots.slice(selectedSlotIndex);

  //check if min selected is out of bounds
  if (minBookableSlots > remainingAvailableSlots.length && durationInMinutes) {
    alert(
      `Please select a time slot that meets the minimum requirement of ${getFormattedTimeFromMinutes(
        durationInMinutes
      )}`
    );

    return [];
  }

  //add slots
  const newSlots = addSlots(
    selectedSlotIndex,
    selectedSlotIndex + minBookableSlots
  );

  return newSlots;
};

export const onSelectSlot = (
  amenitySpace: BaseAmenitySpace,
  availableSlots: Availability[],
  selectedSlotIndexes: number[],
  selectedSlotIndex: number
): number[] => {
  const { maxDuration, minDuration } = amenitySpace;

  const maxBookableSlots = (maxDuration ?? 0) / 15;
  const minBookableSlots = (minDuration ?? 0) / 15;

  let selectedSlots: number[] = [];

  //first time selecting
  if (!selectedSlotIndexes.length) {
    if (minBookableSlots) {
      selectedSlots = getMinimumBookableSlots(
        minBookableSlots,
        availableSlots,
        selectedSlotIndex,
        minDuration
      );
    } else {
      selectedSlots = addSlots(selectedSlotIndex, selectedSlotIndex + 1);
    }
  } else {
    const [first] = selectedSlotIndexes;
    const [last] = [...selectedSlotIndexes].reverse();

    if (selectedSlotIndex > first && selectedSlotIndex < last) {
      if (minDuration) {
        selectedSlots = getMinimumBookableSlots(
          minBookableSlots,
          availableSlots,
          selectedSlotIndex,
          minDuration
        );
      } else {
        selectedSlots = addSlots(selectedSlotIndex, selectedSlotIndex + 1);
      }
    }
    //selection is outside of selected slots
    else {
      let newSelection: number[] = [];
      const isSlotAlreadySelected =
        selectedSlotIndexes.includes(selectedSlotIndex);

      if (isSlotAlreadySelected) {
        newSelection = selectedSlotIndexes.filter(
          (slot) => slot !== selectedSlotIndex
        );
        if (newSelection.length < minBookableSlots) {
          newSelection = [];
        }
      } else {
        const isSelectionBefore = selectedSlotIndex < first;

        const start = isSelectionBefore ? selectedSlotIndex : first;
        const end = isSelectionBefore ? last : selectedSlotIndex;

        newSelection = addSlots(start, end + 1);
      }

      //check if new selection is greater than max duration
      if (maxBookableSlots && newSelection.length > maxBookableSlots) {
        if (minDuration) {
          selectedSlots = getMinimumBookableSlots(
            minBookableSlots,
            availableSlots,
            selectedSlotIndex,
            minDuration
          );
        } else {
          selectedSlots = addSlots(selectedSlotIndex, selectedSlotIndex + 1);
        }
      } else {
        selectedSlots = newSelection;
      }
    }
  }

  //check if any of the selected slots contain any unavailable slots
  let isAnySelectedSlotUnavailable = selectedSlots.some((index: Number) => {
    const selectedAvailability: Availability = availableSlots[Number(index)];

    if (selectedAvailability) {
      return selectedAvailability.availabilityType === 2;
    }

    return false;
  });

  if (isAnySelectedSlotUnavailable) {
    alert(
      `Please select a time slot that meets the minimum requirement of ${getFormattedTimeFromMinutes(
        minDuration ?? 0
      )}`
    );
    return [];
  }

  return selectedSlots;
};

export const getEventBookingTime = (
  availability: Availability[],
  selectedSlots: number[]
) => {
  const [first] = selectedSlots;
  const [last] = [...selectedSlots].reverse();

  const startOfAvailability = availability[first];
  const endOfAvailability = availability[last];

  let startTime = null;
  let endTime = null;

  if (startOfAvailability) {
    startTime = new Date(startOfAvailability.startTime);
  }

  if (endOfAvailability) {
    endTime = new Date(endOfAvailability.endTime);
  }

  return {
    startTime,
    endTime,
  };
};

export const getFormattedTimeFromMinutes = (totalMinutes: number) => {
  var num = totalMinutes;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);

  if (!rminutes) {
    return rhours + (rhours > 1 ? " hours" : " hour");
  }

  if (rhours && rminutes) {
    return (
      rhours + (rhours > 1 ? " hours, " : " hour, ") + rminutes + " minutes"
    );
  }

  return rminutes + " minutes";
};

export interface ValidAvailability extends Availability {
  isValid: boolean;
}

export const getValidAvailabilities = (
  availabilities: Availability[]
): ValidAvailability[] => {
  let availableDates: ValidAvailability[] = [];

  for (let i = 0; i < availabilities.length; i++) {
    const current = availabilities[i];
    const previous = availabilities[i - 1];

    availableDates.push({
      ...current,
      isValid:
        current.availabilityType === 1 || previous?.availabilityType === 1,
    });
  }

  return availableDates;
};

export const isMinDurationAvailable = (
  availabilities: ValidAvailability[],
  selectedDate: Moment,
  minDuration: number
) => {
  if (minDuration < 2) {
    return true;
  }

  const startDateIndex = availabilities.findIndex((av) =>
    selectedDate.isSame(av.startTime, "day")
  );
  const availabilityPlusMinDuration = availabilities.slice(
    startDateIndex,
    startDateIndex + minDuration + 1
  );

  const isMinDurationAvailable = availabilityPlusMinDuration.every(
    (day) => day.isValid
  );

  return isMinDurationAvailable;
};

export const isSelectedRangeAvailable = (
  availabilities: ValidAvailability[],
  startDate: Moment,
  endDate: Moment
) => {
  const startDateIndex = availabilities.findIndex((av) =>
    startDate.isSame(av.startTime, "day")
  );
  const endDateIndex = availabilities.findIndex((av) =>
    endDate.isSame(av.startTime, "day")
  );
  const availabilityRange = availabilities.slice(startDateIndex, endDateIndex);

  const isRangeAvailable = availabilityRange.every((day) => day.isValid);

  return isRangeAvailable;
};

export const isOverMaxDuration = (
  startDate: Moment,
  endDate: Moment,
  maxDuration: number | null
) => {
  if (!maxDuration) {
    return false;
  }

  const diff = endDate.startOf("day").diff(startDate.startOf("day"), "days");

  return diff > maxDuration;
};

export const getAvailabilityStartTime = (
  availabilities: Availability[],
  date: Moment
) => {
  const availability = availabilities.find((av) =>
    date.isSame(av.startTime, "day")
  );

  return availability?.startTime ?? null;
};

export const getAvailabilityEndTime = (
  availabilities: Availability[],
  date: Moment
) => {
  const availability = availabilities.find((av) =>
    date.isSame(av.startTime, "day")
  );

  return availability?.endTime ?? null;
};
