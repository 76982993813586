import { RentAutoPay } from "../types/Rent";
import axios from "axios";
import { BaseLivlyApiResponse } from "@/types/Base";
import { BASE_API_URL } from "@/utils/constants";
import { useQuery } from "@tanstack/react-query";

async function getRentAutoPay(leaseId: string, userId: string) {
  const result = await axios.get<BaseLivlyApiResponse<RentAutoPay>>(
    `${BASE_API_URL}/livly/payment/${leaseId}/rentAutoPay/${userId}`
  );

  return result.data.Data;
}

export default function useGetRentAutoPaySettings(
  leaseId: string,
  userId: string
) {
  return useQuery({
    queryKey: ["rentAutoPay", leaseId, userId],
    queryFn: () => getRentAutoPay(leaseId, userId),
  });
}
