export const ACCESS_TOKEN_KEY = "access_token";
export const EXPIRES_IN_KEY = "expires_in";
export const AUTHORIZATION_HEADER_KEY = "Authorization";
export const MOBILE_KEY = "mobile";
export const FEATURES_STORE_KEY = "featuresStore";
export const BRAND = "brand";
export const RETURN_TO_URL = "returnToUrl";

export const clearLocalStorage = () => {
  const features = localStorage.getItem(FEATURES_STORE_KEY);
  const brand = localStorage.getItem(BRAND);
  const returnToUrl = localStorage.getItem(RETURN_TO_URL);

  localStorage.clear();

  if (features) {
    localStorage.setItem(FEATURES_STORE_KEY, features);
  }
  if (brand) {
    localStorage.setItem(BRAND, brand);
  }
  if (returnToUrl) {
    localStorage.setItem(RETURN_TO_URL, returnToUrl);
  }
};
