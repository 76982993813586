import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Link } from "react-router-dom";
import { Badge } from "../components/Badge";
import Layout from "../components/Layout";
import { BaseLivlyApiResponse } from "../types/Base";
import { Plan, Proof } from "../types/Insurance";
import { BASE_API_URL } from "../utils/constants";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "@/components/Spinner";
import formatCurrency from "@/utils/formatCurrency";
import moment from "moment-timezone";
import { User } from "@/types/User";
import useLivlyUser from "@/context/UserProvider";

async function getInsuranceDetails(leaseId: number, userId: number) {
  const result = await axios.get<
    BaseLivlyApiResponse<{ plans: Plan[]; proofs: Proof[] }>
  >(`${BASE_API_URL}/v2/rentersinsurance/${userId}/${leaseId}`);

  return result.data.Data;
}

export function useGetInsuranceDetails(user: User) {
  const { userId, propertyUnitLeaseId } = user;
  return useQuery({
    queryKey: ["insurance", "details", propertyUnitLeaseId, userId],
    queryFn: () => getInsuranceDetails(propertyUnitLeaseId, userId),
  });
}

function InsurancePage() {
  const { user } = useLivlyUser();
  const { data, isLoading } = useGetInsuranceDetails(user);

  return (
    <Layout title="Insurance" back={{ label: "My Lease", to: "../my-lease" }}>
      {isLoading ? (
        <div className="flex items-center justify-center mt-8">
          <Spinner size="xl" color="livly" />
        </div>
      ) : (
        <>
          <h3 className="font-medium">My Policies</h3>
          {data?.proofs.length === 0 && data?.plans.length === 0 ? (
            <div className="mt-2 text-sm text-gray-500">
              There are no policies
            </div>
          ) : (
            <ul>
              {data?.plans.map((plan) => (
                <li
                  key={plan.id}
                  className="p-4 mt-4 border border-gray-200 rounded-lg "
                >
                  <div className="text-sm divide-y divide-gray-200 space-y">
                    <div className="pb-4">
                      <div className="flex items-center justify-between pb-4">
                        <img
                          src={plan.provider.logoUrl}
                          alt={plan.provider.name}
                          className="h-6"
                        />
                        <Badge
                          type={
                            plan.status === "Active" ? "success" : "default"
                          }
                        >
                          {plan.status}
                        </Badge>
                      </div>
                      <div>
                        <p className="text-xl font-light">
                          {formatCurrency(plan.nextPaymentAmount)}
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          Scheduled{" "}
                          {moment(plan.nextPaymentDate).format("MMMM DD, YYYY")}
                        </p>
                      </div>
                    </div>
                    <div className="py-4">
                      <div className="flex justify-between">
                        <p>Policy Number</p>
                        <p className="font-medium">{plan.policyNumber}</p>
                      </div>
                    </div>
                    <div className="flex pt-4">
                      <a href={plan.documentUri} target="_blank" download>
                        <button
                          color="default"
                          className="flex items-center w-full gap-2 font-medium text-red-400 md:w-auto"
                        >
                          View Insurance Policy
                          <FontAwesomeIcon
                            icon="chevron-right"
                            className="text-sm text-red-400"
                          />
                        </button>
                      </a>
                    </div>
                  </div>
                </li>
              ))}
              {data?.proofs.map((proof) => (
                <li
                  key={proof.id}
                  className="p-4 mt-4 border border-gray-200 rounded-lg "
                >
                  <div className="text-sm divide-y divide-gray-200 space-y">
                    <div className="flex justify-between pb-4">
                      <p>Status</p>
                      <Badge type="default">{proof.status}</Badge>
                    </div>
                    <div className="py-4">
                      <div className="flex justify-between">
                        <p>Insured by</p>
                        <p className="font-medium">{proof.companyName}</p>
                      </div>
                      <div className="flex justify-between mt-2">
                        <p>Policy Number</p>
                        <p className="font-medium">{proof.policyNumber}</p>
                      </div>
                    </div>
                    <div className="flex pt-4">
                      <a href={proof.fileUri} target="_blank" download>
                        <button
                          color="default"
                          className="flex items-center w-full gap-2 font-medium text-red-400 md:w-auto"
                        >
                          View Insurance Policy
                          <FontAwesomeIcon
                            icon="chevron-right"
                            className="text-sm text-red-400"
                          />
                        </button>
                      </a>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
          {/* <h3 className="mt-6 font-medium">Add a Plan</h3>
          <div className="p-4 mt-4 rounded-lg bg-red-50 md:flex md:justify-between md:items-center">
            <div>
              <p className="font-medium">Instant Savings & Protection</p>
              <p className="mt-1 text-sm">
                Better pricing than our competitors, plus better coverage.
              </p>
            </div>
            <div className="flex justify-end mt-4 md:mt-0">
              <Link to="../insurance-quote">
                <Button color="secondary" className="w-full md:w-auto">
                  Get an instant quote
                </Button>
              </Link>
            </div>
          </div> */}
          <div className="pt-4 mt-4 border-t border-gray-200">
            <Link
              to="upload-proof"
              className="flex items-start gap-4 md:p-4 md:rounded-lg md:hover:bg-gray-50"
            >
              <FontAwesomeIcon
                icon={["far", "arrow-up-to-line"]}
                className="text-2xl text-red-400"
              />
              <div className="flex-1">
                <p className="font-medium">Upload proof</p>
                <p className="mt-1 text-sm">
                  Provider proof of insraunce. Once uploaded, a property manager
                  will review.
                </p>
              </div>
              <FontAwesomeIcon icon="chevron-right" className="text-gray-400" />
            </Link>
          </div>
          <div className="pb-12 mt-8">
            <p className="text-sm text-gray-500">
              If you bought a new policy recently, it may take up to 5 days to
              appear.
            </p>
            <p className="mt-2 text-sm text-gray-500">
              The information presented may not reflect recent changes.
            </p>
          </div>
        </>
      )}
    </Layout>
  );
}

export { InsurancePage };
