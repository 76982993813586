export default function NotFoundPage() {
  return (
    <div className="h-screen flex flex-col justify-center items-center">
      <div className="text-[#E2A89F] mb-10 text-8xl">404</div>
      <div className="text-[#565553] text-4xl mb-6">
        Looks like you've got lost...
      </div>
      <div className="text-gray-500 text-2xl">
        The page you're looking for doesn't exist or has been moved.
      </div>
    </div>
  );
}
