export interface FeedPost {
  id: string;
  pinned: boolean;
  pinnedExpirationTimestamp: string | null;
  actorId: number;
  actorUri?: string;
  actorName: string;
  actorType: "PropertyUser" | "Livly" | "LeaseUser";
  lastUpdated: string;
  timestamp: string;
  textBody: string;
  totalLikes: number;
  userHasLiked: boolean;
  totalComments: number;
  propertyId: number;
  propertyName?: string;
  propertyUnitLeaseId?: number | null;
  comments: FeedPostComment[] | null;
  images: FeedPostImage[] | null;
  disableComments: boolean;
  tag: TagType;
  reactions: PostReactions;
  isDeleted: boolean;
  reviewReasons?: {
    ReviewReasonType: string;
  }[];
  reviewCompletedAt: string | null;
  reviewState: ReviewState | null;
  reviewedByUserId: number | null;
  reviewedByUserName: string | null;
  groupId: number | null;
  groupName: string | null;
  marketingEventId?: number | null;
  livlyEventId?: number | null;
  actorTitle?: string | null;
  userHasEdited?: boolean;
  underReview?: boolean;
  userHasFollowed?: boolean;
  userHasSaved?: boolean;
  externalId?: string;
  ordinalOverride: number;
}

export interface CommunityFeedPostRequestModel {
  propertyIds?: number[];
  clientId?: number;
  text: string;
  images: string[];
  imageFiles: File[];
  eventId?: number;
  disableComments: boolean;
  tag: TagType;
  groupId?: number;
  externalId: string;
  marketingEventId?: number;
}

export interface CommunityFeedPostRequest {
  Text: string;
  Images: string[];
  LivlyEventId?: number;
  DisableComments: boolean;
  Tag: TagType;
  GroupId?: number;
  ExternalId: string;
}

export interface FeedPostImage {
  id: string;
  parentId: string;
  uri: string;
}
export interface PostReactions {
  counts: Record<ReactionType, number>;
  userReaction: ReactionType | null;
  totalCount: number;
  reactions: Record<ReactionType, PostReactionsUserInfo[]>;
}

export interface FeedPostComment {
  id: string;
  parentId: string;
  propertyId?: number;
  actorType?: "PropertyUser" | "Livly" | "LeaseUser";
  propertyName: string;
  actorId: number;
  actorUri?: string;
  actorName: string;
  propertyUnitLeaseId?: number | null;
  text: string;
  timestamp: string;
  lastUpdated: string;
  totalLikes: number;
  userHasEdited: boolean;
  userHasLiked: boolean;
  reactions?: Record<ReactionType, PostReactionsUserInfo[]>;
  reviewReasons?: string[];
  underReview?: boolean;
  reviewState?: ReviewState | null;
}

export type ReactionType =
  | "Heart"
  | "ClappingHands"
  | "SmileyFace"
  | "ThumbsUp";

export interface PostReactionsUserInfo {
  avatarUri: string;
  email: string;
  actorName: string;
  timestamp: Date | string;
  userId: number;
  actorType?: ActorType;
}

export enum ActorType {
  LeaseUser = "LeaseUser",
  PropertyUser = "PropertyUser",
  Property = "Property",
  Client = "Client",
  Livly = "Livly"
}

export type PostCommentAction =
  | { type: "toggle_focused"; isFocused: boolean }
  | { type: "set_text"; text: string }
  | { type: "set_key"; key: number }
  | { type: "set_images"; images: ImageState[] }
  | { type: "set_tag"; tag: TagType }
  | { type: "reset" };

export enum FeedTypes {
  ALL = "all",
  MY = "my"
}

export enum TagType {
  General = 0,
  FreeAndForSale,
  Events,
  LostAndFound,
  Recommendations,
  Safety
}

export const TagsName = new Map<number, string>([
  [TagType.General, "General"],
  [TagType.Events, "Event"],
  [TagType.FreeAndForSale, "Free & For Sale"],
  [TagType.LostAndFound, "Lost and Found"],
  [TagType.Recommendations, "Recommendations"],
  [TagType.Safety, "Safety"]
]);

export function reducer(
  state: AddFeedPostState,
  action: PostCommentAction
): AddFeedPostState {
  switch (action.type) {
    case "toggle_focused":
      return { ...state, isFocused: action.isFocused };
    case "set_text":
      return { ...state, text: action.text };
    case "set_images":
      return { ...state, uploadedImages: action.images };
    case "set_tag":
      return { ...state, tag: action.tag };
    case "reset":
      return {
        ...state,
        ...initialAddFeedPostState
      };
    default:
      return state;
  }
}

export interface ImageState {
  id: string;
  file: File;
  state: string;
  previewUri?: string | ArrayBuffer | null;
}

export interface AddFeedPostState {
  tag: TagType;
  isLoading: boolean;
  isFocused: boolean;
  text: string;
  disableComments: boolean;
  uploadedImages: ImageState[];
}

export const initialAddFeedPostState: AddFeedPostState = {
  tag: TagType.General,
  isLoading: false,
  isFocused: false,
  text: "",
  disableComments: false,
  uploadedImages: []
};

export type CommFeedImageUploadResponseModel = {
  extension: string;
  filename: string;
  url: string;
};

export function dataTransferItemsHaveFiles(items: DataTransferItem[]): boolean {
  if (!items || !items.length) {
    return false;
  }
  for (const item of items) {
    if (item.kind === "file" || item.type === "text/html") {
      return true;
    }
  }
  return false;
}

export enum ReviewState {
  Unknown = "Unknown",
  ProcessingModeration = "ProcessingModeration",
  ModerationPassed = "ModerationPassed",
  ReviewRequested = "ReviewRequested", //X
  ReviewPassed = "ReviewPassed",
  ReviewDenied = "ReviewDenied",
  ReviewRequired = "ReviewRequired", //X
  ReviewRequiredProcessingModeration = "ReviewRequiredProcessingModeration" //x
}
