import { useEffect, useReducer } from "react";
import axios from "axios";
import { EXTERNAL_API_URL } from "../utils/constants";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-hot-toast";
import { textAreaClass } from "../components/Form";
import { Button } from "../components/Button";
import { RatingIcon } from "../components/RatingIcon";
import classNames from "classnames";
import * as jose from "jose";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/free-brands-svg-icons";
import { Spinner } from "../components/Spinner";

const USER_DATA_KEY =
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata";
const PROPERTY_DATA_KEY = "livly-token/claims/PropertyData";
const ROLE_KEY = "role";
const PROPERTY_ID_KEY = "livly-token/claims/PropertyUnitLeaseId";
const RESIDENT_SENTIMENT_ID = "livly-token/claims/ResidentSentimentId";

type State = "submit" | "thankyou" | "expired" | null;

interface TokenUser {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  leaseLivlyUserId: number;
}

interface TokenProperty {
  propertyId: number;
  propertyName: string;
  logoUrl: string;
}

interface SentimentState {
  token: string | null;
  error: string | null;
  user: TokenUser | null;
  property: TokenProperty | null;
  state: State | null;
  rating: number;
  comment: string;
}

interface TokenBase {
  exp: number;
  iat: number;
  [ROLE_KEY]: string;
  [USER_DATA_KEY]: string;
  [PROPERTY_DATA_KEY]: string;
}

export interface SentimentToken extends TokenBase {
  [PROPERTY_ID_KEY]: string;
  [RESIDENT_SENTIMENT_ID]: string;
}

type Action =
  | {
      type: "set_info";
      token: string;
      state: State;
      rating: number;
      user: TokenUser;
      property: TokenProperty;
    }
  | { type: "complete_review" }
  | { type: "change_comment"; comment: string }
  | { type: "change_rating"; rating: number };

function reducer(state: SentimentState, action: Action): SentimentState {
  switch (action.type) {
    case "set_info":
      return {
        ...state,
        token: action.token,
        state: action.state,
        rating: action.rating,
        user: action.user,
        property: action.property,
      };
    case "complete_review":
      return { ...state, state: "thankyou" };
    case "change_comment":
      return { ...state, comment: action.comment };
    case "change_rating":
      return { ...state, rating: action.rating };
    default:
      return state;
  }
}

interface SentimentRequest {
  residentSentimentId: number;
  rating: number;
  comment: string;
}

async function postResidentSentiment(data: SentimentRequest, token: string) {
  return await axios.post(`${EXTERNAL_API_URL}/sentiments`, data, {
    headers: {
      Authorization: `livly-token ${token}`,
      "Content-Type": "application/json",
    },
  });
}

function SentimentPage() {
  const [state, dispatch] = useReducer(reducer, {
    token: null,
    error: null,
    user: null,
    property: null,
    state: null,
    rating: 0,
    comment: "",
  });

  const { mutate } = useMutation((data: SentimentRequest) =>
    postResidentSentiment(data, state.token!)
  );

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const state = params.get("state") as State;
    const rating = Number(params.get("rating"));

    const token = params.get("ltoken") as string;
    const decoded = jose.decodeJwt(token) as SentimentToken;
    const user = JSON.parse(decoded[USER_DATA_KEY]) as TokenUser;
    const property = JSON.parse(decoded[PROPERTY_DATA_KEY]) as TokenProperty;

    dispatch({
      type: "set_info",
      token,
      rating,
      state,
      user,
      property,
    });
  }, []);

  const onSubmit = () => {
    const data: SentimentRequest = {
      residentSentimentId: 1,
      comment: state.comment,
      rating: state.rating,
    };

    mutate(data, {
      onSuccess: () => {
        dispatch({ type: "complete_review" });
      },
      onError: () => {
        toast.error("Error submitting review");
      },
    });
  };

  if (state.state === "submit") {
    return (
      <Review
        rating={state.rating}
        comment={state.comment}
        propertyName={state.property?.propertyName}
        logoUrl={state.property?.logoUrl}
        isLoading={false}
        onChangeComment={(comment) =>
          dispatch({ type: "change_comment", comment })
        }
        onChangeRating={(rating) => dispatch({ type: "change_rating", rating })}
        onSkip={() => dispatch({ type: "complete_review" })}
        onSubmit={onSubmit}
      />
    );
  } else if (state.state === "thankyou") {
    return (
      <ThankYou
        propertyName={state.property?.propertyName ?? ""}
        logoUrl={state.property?.logoUrl}
      />
    );
  } else if (state.state === "expired") {
    return (
      <div>
        <p>There was an error processing your request</p>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen">
      <Spinner color="livly" />
    </div>
  );
}

function ThankYou({
  logoUrl,
  propertyName,
}: {
  logoUrl: string | undefined | null;
  propertyName: string;
}) {
  let appLink = "";
  if (import.meta.env.VITE_ENVIRONMENT === "PRODUCTION") {
    appLink = "https://livly.app.link/vQbPtuiQX4";
  } else {
    appLink = "https://livly.test-app.link/28TYfYIQX4";
  }

  return (
    <div className="max-w-lg p-4 mx-auto">
      {logoUrl ? (
        <img src={logoUrl} alt={propertyName} className="h-16" />
      ) : (
        <h5 className="text-lg text-center">{propertyName}</h5>
      )}
      <p className="text-xl font-bold text-center">Thank you!</p>
      <p className="mt-2 text-center">
        Your living experience is important to us. Thanks for your feedback.
      </p>
      <div className="flex justify-center mt-8">
        <img
          src="/Spaceship.png"
          alt="Livly Thank You"
          className="w-[250px] animate-pulse"
        />
      </div>
      <div className="flex gap-4 mt-8">
        <a href={appLink} target="_blank" rel="noopener noreferrer">
          <img src="/apple_badge.png" alt="download in apple store" />
        </a>
        <a href={appLink} target="_blank" rel="noopener noreferrer">
          <img src="/google_badge.png" alt="download in google play store" />
        </a>
      </div>
      <div className="mt-8 text-center">Follow us</div>
      <div className="flex justify-center gap-4 mt-4">
        {[
          { link: "https://www.facebook.com/morelivly", icon: "facebook" },
          { link: "https://www.instagram.com/morelivly/", icon: "instagram" },
          {
            link: "https://www.linkedin.com/company/morelivly/",
            icon: "linkedin",
          },
        ].map(({ link, icon }) => (
          <a
            key={link}
            href={link}
            className="flex items-center justify-center w-12 h-12 transition duration-200 border border-gray-200 rounded-lg hover:bg-gray-50 hover:scale-110"
          >
            <FontAwesomeIcon icon={["fab", icon as IconName]} />
          </a>
        ))}
      </div>
    </div>
  );
}

function Review({
  rating,
  logoUrl,
  propertyName,
  comment,
  onChangeRating,
  onChangeComment,
  isLoading,
  onSkip,
  onSubmit,
}: {
  rating: number;
  logoUrl: string | undefined;
  propertyName: string | undefined;
  comment: string;
  onChangeRating: (rating: number) => void;
  onChangeComment: (comment: string) => void;
  isLoading: boolean;
  onSkip: () => void;
  onSubmit: () => void;
}) {
  return (
    <div className="max-w-lg p-4 mx-auto">
      {logoUrl ? (
        <img src={logoUrl} alt={propertyName} className="h-16" />
      ) : (
        <h5 className="text-lg text-center">{propertyName}</h5>
      )}
      <ul className="flex justify-center gap-4 py-8 mt-4 border-t border-gray-200 ">
        {[0, 1, 2, 3].map((r) => (
          <button
            key={r}
            onClick={() => onChangeRating(r)}
            className="rounded-full"
          >
            <RatingIcon rating={r} isSelected={r === rating} size="3x" />
          </button>
        ))}
      </ul>
      <p className="text-xl font-bold text-center">
        We'd love to hear your feedback!
      </p>
      <p className="mt-2 text-center">
        Tell us what you’d like to improve in your living experience.
      </p>
      <textarea
        className={classNames(textAreaClass, "mt-4")}
        rows={6}
        value={comment}
        onChange={(e) => onChangeComment(e.target.value)}
      />
      <div className="flex flex-col gap-4 mt-4">
        <Button color="primary" disabled={isLoading} onClick={onSubmit}>
          Submit
        </Button>
        <Button disabled={isLoading} onClick={onSkip} color="default">
          Skip
        </Button>
      </div>
    </div>
  );
}

export { SentimentPage };
