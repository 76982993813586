import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import { useParams, useNavigate, Outlet } from "react-router-dom";
import { BASE_API_URL } from "../../utils/constants";
import { BaseLivlyApiResponse } from "../../types/Base";
import { BookingStatus, BaseAmenitySpace } from "../../types/Bookings";
import { AmenityBooking } from "../bookings";
import { toast } from "react-hot-toast";
import { Spinner } from "../../components/Spinner";
import useLivlyUser from "../../context/UserProvider";

type AmenityBookingRequestResponse = {
  amenityBookingId: number;
  user: {
    userId: number;
    propertyId: number;
    propertyUnitLeaseId: number;
    firstName: string;
    lastName: string;
    avatarUri: string;
    unit: string;
    email: string;
    phoneNumber: string;
  };
  price: number;
  livlyUserPaymentHistoryId: number;
  paymentCompleted: boolean;
  propertyId: number;
  status: BookingStatus;
  amenitySpace: BaseAmenitySpace;
  startTime: string;
  endTime: string;
  description: string;
  waiverAccepted: boolean;
};

type AmenityBookingRequest = {
  amenityBookingId: number;
  userId: number;
  propertyUnitLeaseId: number;
  propertyId: number;
  amenitySpaceId: number;
  startTime: Date | string | null;
  endTime: Date | string | null;
  description: string | null;
  additionalDetails: string;
  waiverAccepted: boolean;
  amenitySpaceWaiverId: number | null;
};

const getInitialBookingValues = (
  amenityBookingId: number,
  userId: number,
  leaseId: number,
  propertyId: number,
  amenitySpaceId: number,
  amenitySpaceWaiverId: number | null
): AmenityBookingRequest => {
  return {
    amenityBookingId,
    userId: userId,
    propertyUnitLeaseId: leaseId,
    propertyId,
    amenitySpaceId: amenitySpaceId,
    startTime: null,
    endTime: null,
    description: null,
    additionalDetails: "",
    waiverAccepted: false,
    amenitySpaceWaiverId,
  };
};

const useEditBooking = () => {
  const putAmenityBooking = async (bookingRequest: AmenityBookingRequest) => {
    return await axios.put<BaseLivlyApiResponse<AmenityBooking>>(
      `${BASE_API_URL}/livly/amenity/bookings/${bookingRequest.amenityBookingId}`,
      bookingRequest
    );
  };

  return useMutation((bookingRequest: AmenityBookingRequest) =>
    putAmenityBooking(bookingRequest)
  );
};

async function getBooking(bookingId: string) {
  const result = await axios.get<
    BaseLivlyApiResponse<AmenityBookingRequestResponse>
  >(`${BASE_API_URL}/livly/amenity/bookings/${bookingId}`);

  return result.data.Data;
}

const amenityBookingQuery = (bookingId: string) => ({
  queryKey: ["amenity-booking", bookingId],
  queryFn: async () => getBooking(bookingId),
});

export default function EditBookingPageContainer() {
  const { user } = useLivlyUser();
  const queryClient = useQueryClient();
  const params = useParams<{ leaseId: string; bookingId: string }>();
  const { data, isLoading } = useQuery({
    ...amenityBookingQuery(params.bookingId!),
  });
  const { mutate, isLoading: isSaving } = useEditBooking();
  const navigate = useNavigate();

  const onSubmit = (booking: AmenityBookingRequest) => {
    const onSuccess = () => {
      queryClient.invalidateQueries(["bookings"]);
      navigate(`/lease/${params.leaseId}/bookings`, { replace: true });
    };

    const onError = () => {
      toast.error(
        "This time slot is no longer available. Please make a new time selection."
      );
    };

    mutate(booking, { onSuccess, onError });
  };

  if (isLoading || data === undefined) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner color="livly" size="xl" />
      </div>
    );
  }

  const initialBooking = getInitialBookingValues(
    data.amenityBookingId,
    user.userId,
    Number(params.leaseId),
    user.propertyId,
    data.amenitySpace.amenitySpaceId,
    data.amenitySpace.waiver
      ? data.amenitySpace.waiver.amenitySpaceWaiverId
      : null
  );

  return (
    <EditBookingPage
      initialBooking={initialBooking}
      amenitySpace={data.amenitySpace}
      isLoading={isSaving}
      onSubmit={onSubmit}
    />
  );
}

function EditBookingPage({
  initialBooking,
  amenitySpace,
  isLoading,
  onSubmit,
}: {
  initialBooking: AmenityBookingRequest;
  amenitySpace: BaseAmenitySpace;
  isLoading: boolean;
  onSubmit: (bookingRequest: AmenityBookingRequest) => void;
}) {
  const [booking, setBooking] = useState(initialBooking);
  const [selectedHourlySlots, setSelectedHourlySlots] = useState<number[]>([]);

  const handleSubmit = () => {
    onSubmit(booking);
  };

  return (
    <Outlet
      context={{
        amenitySpace,
        booking,
        setBooking,
        isLoading,
        selectedHourlySlots,
        setSelectedHourlySlots,
        onSubmit: handleSubmit,
      }}
    />
  );
}
