import { useState, useEffect } from "react";
import { BASE_API_URL } from "../utils/constants";
import axios from "axios";
import { ServiceTypeEnum } from "../types/Building";
import { PaymentAccount } from "../types/User";
import { PaymentMethodType } from "../types/Cart";

export type FeeTypeId = 1 | 2;
export type FeeType = "FixedAmount" | "Percentage";
interface FeeData {
  feeTypeId: FeeTypeId;
  feeType: FeeType;
  value: number;
  calculatedFeeAmount: number;
  totalAmount: number;
}

type Props = {
  feeData: FeeData | null;
  error: boolean;
  loading: boolean;
};

const fetchFee = async (propertyId: number, feeData: any) => {
  const res = await axios.post(
    `${BASE_API_URL}/livly/checkout/${propertyId}/calculateServiceFee`,
    feeData
  );
  return res.data.Data;
};

export default function useServiceFee(
  propertyId: number,
  serviceType: ServiceTypeEnum,
  paymentMethod: PaymentAccount | undefined | null,
  amount: number
): Props {
  const { paymentType = "ACH", brand = "", id = 0 } = paymentMethod || {};
  const [data, setData] = useState<{
    error: boolean;
    loading: boolean;
    feeData: FeeData | null;
  }>({ error: false, loading: false, feeData: null });

  useEffect(() => {
    const doFetchFee = async () => {
      try {
        setData((d) => ({
          ...d,
          loading: true,
          error: false,
        }));

        const feeData = await fetchFee(propertyId, {
          amount,
          serviceType,
          paymentType:
            paymentType === "ACH"
              ? PaymentMethodType.ACH
              : PaymentMethodType.CREDITCARD,
          paymentMethodBrand: brand,
          paymentAccountId: id,
        });

        setData((d) => ({
          ...d,
          loading: false,
          feeData,
        }));
      } catch (e: any) {
        setData((d) => ({
          ...d,
          error: false,
          loading: false,
        }));
      }
    };

    if (paymentType && brand && id && amount) {
      doFetchFee();
    }
  }, [propertyId, serviceType, paymentType, brand, id, amount]);

  const { error, loading, feeData } = data;

  return { feeData, loading, error };
}
