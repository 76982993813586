import { useState } from "react";
import { Modal } from "@/components/Dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SelectPaymentType({
  isFullStatementBalance,
  setIsFullStatementBalance,
}: {
  isFullStatementBalance: boolean | null;
  setIsFullStatementBalance: (isFullStatementBalance: boolean) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="mt-4">
        <button
          className="py-4 text-left w-full flex justify-between text-red-400"
          onClick={() => setIsOpen(true)}
        >
          <div>Select payment amount</div>
          <div>
            <FontAwesomeIcon icon={["fas", "dollar-sign"]} />
          </div>
        </button>
      </div>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="Select Payment Type"
      >
        <div>
          <div className="mt-2">
            <button
              className="w-full py-4 text-left flex justify-between"
              onClick={() => {
                setIsFullStatementBalance(true);
                setIsOpen(false);
              }}
            >
              <p>Full statement balance</p>
              {isFullStatementBalance === true && (
                <FontAwesomeIcon icon={["fas", "check"]} />
              )}
            </button>
          </div>
          <div className="mt-2 pt-2 border-t border-gray-200">
            <button
              className="w-full py-4 text-left flex justify-between"
              onClick={() => {
                setIsFullStatementBalance(false);
                setIsOpen(false);
              }}
            >
              <p>Fixed amount</p>
              {isFullStatementBalance === false && (
                <FontAwesomeIcon icon={["fas", "check"]} />
              )}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SelectPaymentType;
