import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const OnboaderPopOut = ({
  onPaymentClick
}: {
  onPaymentClick: () => void;
}) => {
  return (
    <div
      className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 py-6"
      onClick={onPaymentClick}
    >
      <div className="flex items-center rounded-lg px-4 py-3 bg-blue-100 shadow-md cursor-pointer">
        <div className="flex-1">
          <h3 className="text-lg font-semibold">First thing's first</h3>
          <p>Tap here to pay your first month’s rent.</p>
        </div>
        <FontAwesomeIcon icon="arrow-right" className="text-blue-600" />
      </div>
    </div>
  );
};
