import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAvailabilities } from "./DailyBooking";

import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  onSelectSlot,
  getEventBookingTime,
  getFormattedTime,
} from "../utils/bookingHelpers";
import formatCurrency from "../utils/formatCurrency";
import { AmenityDurationDetails } from "../routes/amenity-space";
import convertUTCToLocalTime from "../utils/toLocalTime";
import {
  AmenityBookingRequest,
  BaseAmenitySpace,
  Availability,
  BookingAvailabilityType,
} from "../types/Bookings";
import { Button } from "./Button";
import DatePicker from "react-datepicker";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import useLivlyUser from "@/context/UserProvider";

function HourlyAvailabilityContainer({
  booking,
  amenitySpace,
  onUpdateBooking,
  onNext,
  selectedHourlySlots,
  setSelectedHourlySlots,
}: {
  booking: AmenityBookingRequest;
  amenitySpace: BaseAmenitySpace;
  onUpdateBooking: (booking: AmenityBookingRequest) => void;
  onNext: () => void;

  selectedHourlySlots: number[];
  setSelectedHourlySlots: (slots: number[]) => void;
}) {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(
    booking.startTime ? new Date(booking.startTime) : new Date()
  );

  const {
    data: availabilities = [],
    isLoading,
    isError,
    error,
  } = useAvailabilities(amenitySpace.amenitySpaceId, selectedDate);

  const errorMessage: string | null = isError
    ? (error as any)?.data?.Message ?? t("general.error")
    : null;

  const onSetSelectedDate = (date: Date) => {
    setSelectedDate(date);
    setSelectedHourlySlots([]);
    onUpdateBooking({ ...booking, startTime: null, endTime: null });
  };

  const onClear = () => {
    setSelectedHourlySlots([]);
    onUpdateBooking({ ...booking, startTime: null, endTime: null });
  };

  const onSelectBookingTime = (date: Date, index: number) => {
    const selected = onSelectSlot(
      amenitySpace,
      availabilities,
      selectedHourlySlots,
      index
    );
    setSelectedHourlySlots(selected);

    const { startTime, endTime } = getEventBookingTime(
      availabilities,
      selected
    );

    onUpdateBooking({ ...booking, startTime, endTime });
  };

  if (!amenitySpace) {
    return <p className="text-center">{t("amenity.space.loading")}</p>;
  } else {
    return (
      <HourlyAvailability
        isError={isError}
        errorMessage={errorMessage}
        space={amenitySpace}
        startTime={booking.startTime}
        endTime={booking.endTime}
        selectedDate={selectedDate}
        setSelectedDate={onSetSelectedDate}
        availabilities={availabilities}
        isLoading={isLoading}
        onSelectBookingTime={onSelectBookingTime}
        onClear={onClear}
        onClickNext={onNext}
        selectedSlots={selectedHourlySlots}
      />
    );
  }
}

export default HourlyAvailabilityContainer;

function HourlyAvailability({
  isError,
  errorMessage,
  space,
  startTime,
  endTime,
  selectedDate,
  isLoading,
  availabilities,
  selectedSlots,
  setSelectedDate,
  onSelectBookingTime,
  onClear,
  onClickNext,
}: {
  isError: boolean;
  errorMessage: string | null;
  space: BaseAmenitySpace;
  startTime: string | Date | null;
  endTime: string | Date | null;
  selectedDate: Date;
  isLoading: boolean;
  availabilities: Availability[];
  setSelectedDate: (date: Date) => void;
  onSelectBookingTime: (date: Date, index: number) => void;
  onClear: () => void;
  onClickNext: () => void;
  selectedSlots: number[];
}) {
  const { t } = useTranslation();

  const { name, price } = space;
  const { startEnd } = getFormattedTime(startTime, endTime);

  return (
    <div>
      <div className="flex flex-col justify-between p-4 mb-5 bg-gray-100 rounded-md md:flex-row md:items-center">
        <div className="flex-1 pr-6 overflow-hidden">
          <div className="flex items-center">
            <p
              className="mr-2 text-2xl font-light whitespace-nowrap"
              title={name}
            >
              {name}
            </p>
            <span className="inline-flex items-center rounded-md bg-blue-100 px-2.5 py-0.5 text-sm font-medium text-blue-800">
              {price > 0
                ? t(
                    `amenity.space.price-${
                      space.bookingAvailabilityType ===
                      BookingAvailabilityType.Daily
                        ? "hourly"
                        : "nightly"
                    }`,
                    { amount: formatCurrency(price).replace(".00", "") }
                  )
                : null}
            </span>
          </div>
          <AmenityDurationDetails
            minDuration={space.minDuration}
            maxDuration={space.maxDuration}
            bookingAvailabilityType={space.bookingAvailabilityType}
          />
        </div>
        <div className="flex items-center flex-1 pt-2 border-gray-300 md:border-l md:pl-6 md:pt-0">
          <div className="flex-1">
            <div className="flex items-center">
              <div className="mr-2">
                <FontAwesomeIcon icon={["far", "calendar"]} />
              </div>
              <p>{moment(selectedDate).format("dddd, MMMM D")}</p>
            </div>
            <div className="flex items-center">
              <div className="mr-2">
                <FontAwesomeIcon icon={["far", "clock"]} />
              </div>
              <p>{startEnd}</p>
            </div>
          </div>
        </div>
      </div>
      <div className=" md:border md:border-gray-200 rounded-md flex flex-col md:flex-row py-6">
        <div className="h-[550px] flex items-center justify-center flex-1 md:px-6 md:border-r md:border-gray-200">
          <DatePicker
            minDate={moment().add(-1, "day")}
            selected={selectedDate}
            onChange={setSelectedDate}
            inline
            filterDate={(day: Date) =>
              !(
                moment().add(-1, "day").isAfter(day) ||
                moment()
                  .add(space.displayAvailabilityInDays, "days")
                  .isBefore(day)
              )
            }
          />
        </div>
        <div className="md:h-[550px] flex flex-1 px-6 mt-4">
          <TimeSlots
            selectedSlots={selectedSlots}
            date={selectedDate}
            onSelect={onSelectBookingTime}
            availabilities={availabilities}
            isError={isError}
            errorMessage={errorMessage}
            isLoading={isLoading}
            startTime={startTime}
            endTime={endTime}
            onClear={onClear}
          />
        </div>
      </div>
      <div className="mt-4 flex justify-end">
        <Button
          size="small"
          color="primary"
          disabled={!startTime && !endTime}
          onClick={onClickNext}
        >
          {t("amenity.space.booking.next")}
        </Button>
      </div>
    </div>
  );
}

function TimeSlots({
  startTime,
  endTime,
  date,
  availabilities,
  isLoading,
  isError,
  errorMessage,
  selectedSlots,
  onSelect,
  onClear,
}: {
  startTime: string | Date | null;
  endTime: string | Date | null;
  date: Date;
  availabilities: Availability[];
  isLoading: boolean;
  isError: boolean;
  errorMessage: string | null;
  selectedSlots: number[];
  onSelect: (date: Date, index: number) => void;
  onClear: () => void;
}) {
  const { t } = useTranslation();

  if (isError) {
    return (
      <div className="px-3">
        <p className="text-center text-red-500">{errorMessage}</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="px-3">
        <p>{t("amenity.space.availabilities.loading")}</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-1">
      <div className="flex items-center justify-between mb-2">
        <p className="text-lg font-medium text-gray-800">
          {t("amenity.space.availabilities.select-time")}
        </p>
        <button
          onClick={onClear}
          className="px-2 py-1 text-red-300 rounded-lg hover:bg-gray-50"
        >
          {t("amenity.space.availabilities.clear")}
        </button>
      </div>
      <div className="flex items-center h-8 pb-2 border-b border-gray-300 box-content">
        <p>{moment(date).format("dddd, MMMM D")}</p>
      </div>
      <div className="flex-1 p-2 pl-0 overflow-x-hidden overflow-y-auto">
        {!availabilities.length ? (
          <p className="text-center">
            {t("amenity.space.availabilities.empty")}
          </p>
        ) : (
          <TimeSlotsList
            selectedSlots={selectedSlots}
            availabilities={availabilities}
            onSelect={onSelect}
            startTime={startTime}
            endTime={endTime}
          />
        )}
      </div>
    </div>
  );
}

function TimeSlotsList({
  startTime,
  endTime,
  availabilities,
  selectedSlots,
  onSelect,
}: {
  startTime: string | Date | null;
  endTime: string | Date | null;
  availabilities: Availability[];
  selectedSlots: number[];
  onSelect: (date: Date, index: number) => void;
}) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  useEffect(() => {
    setTimeout(() => {
      if (isSmallDevice) {
        return;
      }
      const element = document.getElementById("0745");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  }, []);

  return (
    <ul className="p-0 m-0 list-none">
      {availabilities.map((availability, index) => (
        <TimeSlotListItem
          key={availability.startTime.toString()}
          availability={availability}
          onSelect={() => onSelect(availability.startTime as Date, index)}
          startTime={startTime}
          endTime={endTime}
          isSelected={selectedSlots.includes(index)}
          // timezone=
        />
      ))}
    </ul>
  );
}

const timeFormat = "h a";

function TimeSlotListItem({
  startTime: start,
  endTime: end,
  availability,
  onSelect,
  isSelected,
}: {
  startTime: string | Date | null;
  endTime: string | Date | null;
  availability: Availability;
  onSelect: () => void;
  isSelected: boolean;
}) {
  const { t } = useTranslation();
  const { startTime, endTime, availabilityTypeString } = availability;
  const { user } = useLivlyUser();
  const minutes = moment(startTime).get("minutes");
  const localStart = convertUTCToLocalTime(
    startTime as Date,
    user?.building?.timezone
  );

  const slotClasses = [
    "flex items-center justify-between flex-1 border-t border-gray-300 relative",
  ];

  if (isSelected) {
    slotClasses.push("bg-red-100 border border-red-300 rounded-md");

    if (start && end) {
      const isFirst = moment(start).isSame(startTime);
      const isLast = moment(end).isSame(endTime);

      if (isFirst) {
        slotClasses.push("border-b-0 rounded-bl-none rounded-br-none");
      } else if (isLast) {
        slotClasses.push("border-t-0 rounded-tl-none rounded-tr-none");
      } else {
        slotClasses.push("border-t-0 border-b-0 rounded-none");
      }
    }
  }

  return (
    <li
      key={startTime.toString()}
      className="flex h-12"
      id={localStart.format("HHmm")}
    >
      <div className="flex justify-end w-12 pr-3 border-r border-gray-300 ">
        <p className="relative text-xs font-medium text-gray-400 uppercase -top-2">
          {minutes ? `:${minutes}` : localStart.format(timeFormat)}
        </p>
      </div>
      <div className={slotClasses.join(" ")}>
        {availabilityTypeString === "Available" ? (
          <label className="absolute inset-0 flex items-center justify-between w-full px-4 ml-0 cursor-pointer">
            <span>Available</span>
            <input
              type="checkbox"
              checked={isSelected}
              onChange={onSelect}
              className="w-4 h-4 text-red-300 border-gray-300 rounded focus:ring-red-200"
            />
          </label>
        ) : (
          <p className="px-4">
            {t("amenity.space.availabilities.unavailable")}
          </p>
        )}
      </div>
    </li>
  );
}
