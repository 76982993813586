import { useState } from "react";
import { Outlet } from "react-router-dom";
import {
  PermissionAccessLevelEnum,
  ServiceTypeEnum,
} from "../../types/Building";
import { Modal } from "../../components/Dialog";
import { Button } from "../../components/Button";
import useLivlyUser from "../../context/UserProvider";

function GuestsIndexPage() {
  const { user } = useLivlyUser();
  const guestService = user.building.serviceTypes?.find(
    (st) => st.serviceType === ServiceTypeEnum.MyGuests
  );
  const guestServiceAccessLevel =
    guestService?.permissionAccessLevel ?? PermissionAccessLevelEnum.ReadOnly;
  const isGuestServiceDisabled =
    guestServiceAccessLevel !== PermissionAccessLevelEnum.FullAccess;

  const [showServiceNoAccess, setShowServiceNoAccess] = useState(false);

  const showNoAccessDialog = () => setShowServiceNoAccess(true);
  const onClose = () => setShowServiceNoAccess(false);

  return (
    <>
      <Outlet context={{ isGuestServiceDisabled, showNoAccessDialog }} />
      <Modal open={showServiceNoAccess} onClose={onClose} title="Oooops">
        <div className="mt-4">
          <p>
            You do not have access to this feature. Please speak to your
            property staff with any questions.
          </p>
          <div className="mt-4 flex justify-end">
            <Button color="secondary" onClick={onClose}>
              Got it
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export { GuestsIndexPage };
