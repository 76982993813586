import React, { createContext, useContext, useEffect, useMemo } from "react";
import Alert from "../../components/Alert";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Dialog";
import {
  AgreeToTerms,
  DisableAutoPay,
} from "@/components/Rent/Autopay/RentAutoPayTerms";
import { Spinner } from "../../components/Spinner";
import { trackViewAutopayPage } from "../../utils/analytics";
import useLivlyUser from "../../context/UserProvider";
import useAutopayState, {
  AutopayState,
} from "@/components/Rent/Autopay/hooks/useAutopayState";
import useAutopayActions, {
  AutopayActions,
} from "@/components/Rent/Autopay/hooks/useAutopayActions";
import RentAutoPay from "@/components/Rent/Autopay/RentAutoPay";

function RentAutoPayPage() {
  const { user } = useLivlyUser();
  const { autopayState } = useRentAutoPay();
  const { autopaySettings } = autopayState;

  useEffect(() => {
    if (autopayState.autopaySettings) {
      trackViewAutopayPage(autopayState.autopaySettings.id !== null);
    }
  }, [autopayState.autopaySettings]);

  if (autopaySettings) {
    return (
      <>
        <RentAutoPay user={user} />
        <AgreeToTerms />
        <DisableAutoPay />
        <ErrorDialog />
      </>
    );
  }

  return (
    <div className="flex items-center justify-center flex-1 mt-8">
      <Spinner color="livly" size="xl" />
    </div>
  );
}

function ErrorDialog() {
  const {
    autopayState: { error, showError, setShowError, setError },
  } = useRentAutoPay();
  return (
    <Modal
      open={showError}
      onClose={() => {
        setShowError(false);
        setError("");
      }}
      title="Sorry"
    >
      <div className="mt-4">
        <Alert variant="danger" message={error} />
        <div className="flex justify-end mt-4">
          <Button onClick={() => setShowError(false)}>Ok</Button>
        </div>
      </div>
    </Modal>
  );
}

type RentAutoPayContextType = {
  autopayState: AutopayState;
  autoPayActions: AutopayActions;
};

const RentAutoPayContext = createContext<RentAutoPayContextType | undefined>(
  undefined
);

const RentAutoPayProvider = () => {
  const autopayState = useAutopayState();
  const autoPayActions = useAutopayActions(autopayState);

  const value = useMemo(
    () => ({ autopayState, autoPayActions }),
    [autopayState, autoPayActions]
  );

  if (!autopayState.autopaySettings) {
    return null;
  }

  return (
    <RentAutoPayContext.Provider value={value}>
      <RentAutoPayPage />
    </RentAutoPayContext.Provider>
  );
};

const useRentAutoPay = (): RentAutoPayContextType => {
  const context = useContext(RentAutoPayContext);
  if (!context) {
    throw new Error(
      "useRentAutoPayContext must be used within a RentAutoPayProvider"
    );
  }

  return context;
};

export { useRentAutoPay };

export default RentAutoPayProvider;
