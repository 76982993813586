export const PHONE_REGEX = /(\d{3})(\d{3})(\d{4})/;
export const PHONE_FORMAT = "$1-$2-$3";
export const PHONE_FORMAT_ALTERNATE = "($1) $2-$3";

function formatPhone(phone: string, format = PHONE_FORMAT) {
  let maxChars = 10;
  phone = stripPhone(phone);

  if (phone.length > maxChars) {
    phone = phone.substr(0, maxChars);
  }

  if (phone.length === maxChars) {
    let fixedPhone = phone.replace(PHONE_REGEX, format);
    return fixedPhone;
  } else {
    return phone;
  }
}

function isValidPhone(phone: string) {
  return PHONE_REGEX.test(stripPhone(phone));
}

function stripPhone(phone: string) {
  return (phone = phone.replace(/[^\d]/g, ""));
}

export { formatPhone, isValidPhone, stripPhone };
