import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Alert from "../../components/Alert";
import { Button } from "../../components/Button";
import { Toggle } from "../../components/Form";
import Layout from "../../components/Layout";
import { Spinner } from "../../components/Spinner";
import { BaseLivlyApiResponse } from "../../types/Base";
import { BuildingServiceType, ServiceTypeEnum } from "../../types/Building";
import { BASE_API_URL } from "../../utils/constants";
import { useAddLogin } from "./add-login";
import useLivlyUser from "../../context/UserProvider";

async function getAvailableBuildingServiceTypes(propertyId: number) {
  const result = await axios.get<BaseLivlyApiResponse<BuildingServiceType[]>>(
    `${BASE_API_URL}/livly/delegatedUsers/properties/${propertyId}/serviceTypes`
  );

  return result.data.Data;
}

export default function AddFamilyLoginPermissionsPage() {
  const { user } = useLivlyUser();
  const navigate = useNavigate();
  const { details, sendInvite, isSubmitting, errorMessage } = useAddLogin();
  const { data, isLoading } = useQuery(
    ["add-login", "service-types", user.propertyId],
    () => getAvailableBuildingServiceTypes(user.propertyId)
  );
  const [userPermissions, setUserPermissions] = useState<
    {
      serviceType: number;
      enabled: boolean;
      metaData: string;
      name: string;
      description: string;
    }[]
  >([]);

  useEffect(() => {
    if (!isLoading && data) {
      setUserPermissions(
        data
          .filter((st) => st.enabled)
          .filter(
            (st) =>
              ![
                ServiceTypeEnum.LivlyLocks,
                ServiceTypeEnum.InfoDesk,
                ServiceTypeEnum.MarketPlace,
              ].includes(st.serviceType)
          )
          .map((st) => ({
            serviceType: st.serviceType,
            enabled: false,
            metaData: "",
            name: st.serviceName,
            description: serviceTypeDescriptions[st.serviceType] ?? "",
          }))
      );
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (!details.firstName) {
      navigate("../details");
    }
  }, [details.firstName]);

  useEffect(() => {
    if (errorMessage) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [errorMessage]);

  const onSubmit = () => {
    sendInvite({ serviceTypes: userPermissions });
  };

  return (
    <Layout title="Login Permissions" back={{ to: -1, label: "Login Details" }}>
      {errorMessage && (
        <div className="mb-4">
          <Alert variant="danger" message={errorMessage} />
        </div>
      )}
      <h2>
        Choose the features you would like this user to be able to access on
        your behalf. Default permissions cannot be changed.
      </h2>
      <div className="mt-6">
        <h3 className="font-medium">Default Permissions</h3>
        <ul className="mt-4 space-y-4">
          <li className="flex items-start gap-4">
            <FontAwesomeIcon icon={["fal", "circle-check"]} className="mt-1" />
            <div>
              <p>Info Desk</p>
              <p className="text-sm text-gray-700">
                View important building information
              </p>
            </div>
          </li>
          <li className="flex items-start gap-4">
            <FontAwesomeIcon icon={["fal", "circle-check"]} className="mt-1" />
            <div>
              <p>Marketplace</p>
              <p className="text-sm text-gray-700">
                View and purchase services and building offers
              </p>
            </div>
          </li>
        </ul>
      </div>
      {isLoading ? (
        <div className="my-4">
          <p>Loading permissions. Please wait...</p>
        </div>
      ) : (
        <div className="pt-6 mt-6 border-t border-gray-200">
          <h3 className="font-medium">Additional Features</h3>
          <ul className="mt-4 space-y-4">
            {userPermissions.map((up) => (
              <li
                className="flex items-center justify-between"
                key={up.serviceType}
              >
                <div>
                  <p>{up.name}</p>
                  {up.description && (
                    <p className="text-sm text-gray-700">{up.description}</p>
                  )}
                </div>
                <Toggle
                  checked={up.enabled}
                  onChange={(enabled) => {
                    const newUserPermissions = userPermissions.map((u) => {
                      if (up.serviceType === u.serviceType) {
                        return { ...u, enabled };
                      }
                      return u;
                    });
                    setUserPermissions(newUserPermissions);
                  }}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="flex justify-end mt-4 gap-2">
        <Button
          color="secondary"
          className="flex items-center justify-center w-full gap-2 md:w-auto"
          onClick={onSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting && <Spinner />}
          Send Invite
        </Button>
      </div>
    </Layout>
  );
}

export const serviceTypeDescriptions: Record<number, string> = {
  [ServiceTypeEnum.InfoDesk]: "View important building information",
  [ServiceTypeEnum.MarketPlace]:
    "View and purchase services and building offers.",
  [ServiceTypeEnum.Rent]: "View and pay your rent.",
  [ServiceTypeEnum.RentersInsurance]: "View and pay your renters insurance",
  [ServiceTypeEnum.Maintenance]: "Create maintenance tickets on your behalf.",
  [ServiceTypeEnum.CommFeed]: "View and post to the community feed.",
  [ServiceTypeEnum.Amenities]: "View and book building amenities.",
  [ServiceTypeEnum.Packages]: "View your packages and mark as picked up.",
  [ServiceTypeEnum.Events]: "View and RSVP to building events.",
};
