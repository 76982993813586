import Avatar from "@/components/Avatar";
import {
  FeedPost,
  ReactionType,
  ReviewState,
  TagsName,
  TagType
} from "@/utils/commfeed";
import toLocalTime, { humanizeTimestamp } from "@/utils/toLocalTime";
import React, { useEffect, useRef, useState } from "react";
import { ImageUpload } from "react-file-utils";
import { TextContent } from "./text-content";
import FeedPostMenu from "./feed-post-menu";
import MyGallery from "./feed-images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { faThumbtack } from "@fortawesome/free-solid-svg-icons";
import { PostReactionsDetails } from "./post-reactions-details";
import { PostReactions } from "./reactions";
import { PostComments } from "./post-comments";
import OnScreen from "./onscreen";
import { Modal } from "@/components/Dialog";
import { faShieldCheck } from "@fortawesome/pro-solid-svg-icons";
import useIsReadonlyCommFeed from "./hook/useIsReadonlyCommFeed";
import { Link } from "react-router-dom";

const FeedPostListItem = ({
  post,
  onDeletePost,
  onDeleteComment,
  onUpdatePostReaction,
  onAddPostComment,
  onEditPostComment,
  onEditFeedPost,
  onHideFeedPost,
  onReportFeedPost,
  isLoading = false,
  isMyPost = false
}: {
  post: FeedPost;
  isMyPost?: boolean;
  onDeletePost: (leaseId: string, postId: string) => Promise<void>;
  onDeleteComment: (
    leaseId: string,
    postId: string,
    commentId: string
  ) => Promise<void>;
  onUpdatePostReaction: (
    leaseId: string,
    postId: string,
    reactionType: ReactionType | null
  ) => Promise<boolean>;
  onAddPostComment: (
    leaseId: string,
    postId: string,
    comment: string
  ) => Promise<any>;
  onEditPostComment: (
    leaseId: string,
    postId: string,
    commentId: string,
    comment: string
  ) => Promise<any>;
  onEditFeedPost: (
    post: FeedPost,
    images: ImageUpload[],
    text: string,
    tag: TagType
  ) => Promise<any>;
  onHideFeedPost: (leaseId: string, postId: string) => Promise<void>;
  onReportFeedPost?: (leaseId: string, postId: string) => Promise<void>;
  isLoading: boolean;
}) => {
  const isReadOnly = useIsReadonlyCommFeed();
  const [open, setOpen] = useState(false);
  const commentBoxRef = useRef<HTMLTextAreaElement | null>(null);

  const commentsText =
    post.totalComments === 1
      ? `${post.totalComments} comment`
      : `${post.totalComments} comments`;

  useEffect(() => {
    const savedPosition = localStorage.getItem("scrollPosition");
    if (savedPosition) {
      const { x, y } = JSON.parse(savedPosition);
      window.scrollTo(x, y);
      localStorage.removeItem("scrollPosition");
    }
  }, []);

  const underReview = [
    ReviewState.ReviewRequested,
    ReviewState.ReviewRequired,
    ReviewState.ReviewRequiredProcessingModeration
  ].includes(post.reviewState as any)
    ? true
    : false;

  return (
    <div
      className={`${
        underReview ? "bg-yellow-50" : "bg-white"
      } border border-gray-300 rounded-lg shadow-sm mb-6`}
    >
      <div>
        {post.pinned && (
          <div className="px-4 py-2 flex items-center space-x-2 my-2">
            <FontAwesomeIcon icon={faThumbtack} className="text-blue-500" />
            <p className="text-blue-500 text-sm">Pinned Post</p>
            <p className="text-sm text-gray-500">
              Expires after{" "}
              {moment(post?.pinnedExpirationTimestamp).format("MMM D, yyyy")}
            </p>
          </div>
        )}

        {underReview && (
          <div className="px-4 py-2 flex items-center justify-between space-x-2 my-2">
            <p className="text-black text- font-medium">
              Your Post is under review.
            </p>
            <div className="cursor-pointer" onClick={() => setOpen(true)}>
              <FontAwesomeIcon
                icon={["fas", "info-circle"]}
                className="text-black text-lg"
              />
            </div>
            <Modal
              open={open}
              onClose={() => setOpen(false)}
              title={"What does this mean?"}
              size="md"
            >
              <div className="flex justify-between flex-col ">
                <p className="mt-2 text-left">
                  Your property staff is responsible for approving your post to
                  the Community Feed before it becomes public. If your message
                  is urgent, please contact your property staff via phone or
                  email.
                </p>
              </div>
            </Modal>
          </div>
        )}

        <div className="flex justify-between items-center px-4 py-2">
          <div className="flex space-x-4 items-center">
            <Avatar src={post.actorUri} size="sm" name={`${post.actorName}`} />
            <div className="flex-1">
              <div>
                <p className="font-semibold">
                  {post.actorName}{" "}
                  {post.actorType === "PropertyUser" ? (
                    <FontAwesomeIcon
                      icon={faShieldCheck}
                      className="text-blue-500"
                    />
                  ) : null}
                </p>
              </div>
              <p className="text-sm text-gray-500">
                {humanizeTimestamp(post.timestamp)}
                {post.timestamp !== post.lastUpdated && ` (edited)`}
              </p>
            </div>
          </div>

          <div className="flex flex-col items-end px-4">
            <div className="flex items-center space-x-2">
              <span className="bg-gray-200 text-felix text-xs font-light rounded-full py-1 px-3">
                {TagsName.get(post.tag || TagType.General)}
              </span>
              <FeedPostMenu
                post={post}
                onDelete={onDeletePost}
                onEdit={onEditFeedPost}
                onHide={onHideFeedPost}
                onReport={onReportFeedPost}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 py-2">
        <div className="mt-3">
          <TextContent text={post.textBody} />
        </div>

        {post.images && post.images.length > 0 && (
          <OnScreen>
            <div className="flex justify-center mt-3">
              <MyGallery images={post.images.map((photo) => photo.uri)} />
            </div>
          </OnScreen>
        )}

        <>
          <div className="flex justify-between items-center h-14">
            <PostReactionsDetails post={post} isReadOnly={isReadOnly} />
            <div className="flex items-center">
              {post?.livlyEventId && post?.tag === TagType.Events && (
                <Link
                  to={`../events/property/${post.propertyId}/${post.livlyEventId}`}
                  className="bg-livly-100 p-3 rounded-md mr-2"
                  onClick={(e) => {
                    const x = e.clientX;
                    const y = e.clientY;
                    localStorage.setItem(
                      "scrollPosition",
                      JSON.stringify({ x, y })
                    );
                  }}
                >
                  <span className="flex items-center text-xs text-livly font-medium bg-red-100 p-2 text-red-500 rounded">
                    RSVP
                    <FontAwesomeIcon
                      icon={["fal", "calendar"]}
                      className="ml-2 text-livly-500 text-sm"
                    />
                  </span>
                </Link>
              )}
              <span className="text-sm text-gray-600">{commentsText}</span>
            </div>
          </div>

          <div className="border-t my-2">
            {!isReadOnly && (
              <div className="my-2 flex items-center">
                <PostReactions
                  post={post}
                  onUpdatePostReaction={onUpdatePostReaction}
                />
                <button
                  onClick={() => commentBoxRef.current?.focus()}
                  className="ml-2 px-3 py-1 text-sm font-normal focus:outline-none"
                >
                  Comment
                  <FontAwesomeIcon
                    icon={["fal", "comment"]}
                    className="ml-2 text-sm"
                  />
                </button>
              </div>
            )}

            <PostComments
              isMyPost={isMyPost}
              post={post}
              commentBoxRef={commentBoxRef}
              onAddPostComment={onAddPostComment}
              onDeleteComment={onDeleteComment}
              onEditPostComment={onEditPostComment}
              isLoading={isLoading}
              isReadOnly={isReadOnly}
            />
          </div>
        </>
      </div>
    </div>
  );
};

export function ApprovedBy({
  reviewedByUserName,
  reviewCompletedAt
}: {
  reviewedByUserName: string | null;
  reviewCompletedAt: string | null;
}) {
  if (reviewedByUserName == null) {
    return null;
  }

  const [firstName, lastName] = reviewedByUserName.split(" ");

  return (
    <div className="text-xs mb-2">
      Reviewed By: {`${firstName} ${lastName[0]}`}{" "}
      {toLocalTime(reviewCompletedAt!).format("M/D/YY h:mm a")}
    </div>
  );
}

export default FeedPostListItem;
