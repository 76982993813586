import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { RentHistoryItem } from "../../types/Rent";
import { RentHistoryListItem } from "./RentHistoryListItem";

export interface RentHistoryListProps {
  rentHistoryItems: RentHistoryItem[];
  limit?: number;
}

const RentHistoryList: React.FC<RentHistoryListProps> = ({
  rentHistoryItems,
  limit,
}) => {
  const { t } = useTranslation();
  const limitedHistoryItems = limit
    ? rentHistoryItems.slice(0, limit)
    : rentHistoryItems;

  if (rentHistoryItems && rentHistoryItems.length > 0) {
    const groupedPaymentItems = limitedHistoryItems.reduce((acc, curr) => {
      const day = moment(curr.transactionDate).startOf("day").format();

      if (acc[day]) {
        acc[day] = [...acc[day], curr];
      } else {
        acc[day] = [curr];
      }

      return acc;
    }, {} as Record<string, RentHistoryItem[]>);

    return (
      <div className="mt-12">
        <h3 className="text-gray-500">{t("rent.autopay.history")}</h3>
        <ul className="space-y-6 divide-y" data-test="rent-history-list">
          {Object.keys(groupedPaymentItems).map((key) => {
            const items = groupedPaymentItems[key];

            return (
              <li key={key} className="pt-6">
                <h4 className="text-sm text-gray-800 uppercase">
                  {moment(key).format("MMMM D, YYYY")}
                </h4>
                <ul className="mt-4 space-y-4">
                  {items.map((item, index) => (
                    <RentHistoryListItem
                      key={`${item.transactionDate}-${index}`}
                      rentHistoryItem={item}
                    />
                  ))}
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return (
    <div
      className="flex flex-col items-center justify-center mt-12"
      data-test="rent-history-empty"
    >
      <FontAwesomeIcon
        icon={["fad", "sack-dollar"]}
        size="4x"
        style={{
          //@ts-ignore
          "--fa-primary-color": "rgba(255,199,71,1)",
          "--fa-secondary-color": "rgba(255,199,71,0.4)",
          "--fa-secondary-opacity": 1,
        }}
      />
      <p className="mt-1">{t("rent.label.no-payments-made")}</p>
      <p className="mt-2 text-sm text-gray-600">
        {t("rent.label.no-payments-made-description")}
      </p>
    </div>
  );
};

export { RentHistoryList };
