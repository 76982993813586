import { Button } from "@/components/Button";
import Layout from "@/components/Layout";
import React, { useState } from "react";
import { AccessScheduleModal } from "./access-schedule-modal";
import { useNavigate, useParams } from "react-router-dom";
import useLivlyGuest, { DelegateServiceTypes } from "@/context/GuestProvider";
import { ServiceTypeEnum } from "@/types/Building";
import useGetService from "@/hooks/useGetService";
import { useQueryClient } from "@tanstack/react-query";
import useLivlyUser from "@/context/UserProvider";
import moment from "moment-timezone";
import LivlySwitch from "@/components/LivlySwitch";

function useGetUnitDoorDelegateService(propertyId: number, leaseId: string) {
  const queryClient = useQueryClient();
  let unitdoor = false;
  const services: DelegateServiceTypes[] =
    queryClient.getQueryData(["delegate-services", propertyId, leaseId]) ?? [];
  const LivlyLocks = services.find(
    (p) => p.serviceType === ServiceTypeEnum.LivlyLocks
  );

  const livlyKeyServiceMetaData = LivlyLocks?.metaData;
  if (livlyKeyServiceMetaData) {
    const parsedMetaData = JSON.parse(livlyKeyServiceMetaData);
    unitdoor = parsedMetaData.unitDoors ?? false;
  }
  return unitdoor ?? false;
}

export const LivlyKeyPermission = () => {
  const { user } = useLivlyUser();
  const { guest, updateGuest } = useLivlyGuest();
  const { leaseId } = useParams<{ id: string; leaseId: string }>();
  const isDelegateLivlyKeyUnitDoorEnabled = useGetUnitDoorDelegateService(
    user.propertyId,
    leaseId!
  );
  const service = useGetService(ServiceTypeEnum.LivlyLocks);
  const navigate = useNavigate();

  const livlyKeyService = guest.serviceTypes?.find(
    (s) => s?.serviceType === ServiceTypeEnum.LivlyLocks
  );

  const [enabled, setEnabled] = useState(livlyKeyService?.enabled);
  const [open, setIsOpen] = useState(false);

  const handleUnitDoor = (checked: boolean) => {
    updateGuest({
      ...guest,
      //@ts-ignore
      accessControl: { ...guest.accessControl, allowUnitDoors: checked },
    });
  };

  const handleNext = () => {
    const serviceTypes = guest.serviceTypes?.map((service) => {
      if (service?.serviceType === ServiceTypeEnum.LivlyLocks) {
        return { ...service, enabled: enabled as boolean };
      }
      return service;
    });

    updateGuest({ ...guest, serviceTypes });

    navigate("../livly-feature-permission");
  };
  if (!service?.enabled) {
    navigate("..");
  }

  return (
    <Layout
      back={{ to: "../permission", label: "Access permission" }}
      title="Livly Key permission"
    >
      <div className="max-w-3xl mx-auto">
        <div>
          <div className="flex justify-between items-center">
            <p className="text-xl font-normal">Livly Key</p>
            <label className="flex flex-col items-end">
              <LivlySwitch
                checked={enabled ?? false}
                onChange={(checked: boolean) => {
                  setEnabled(checked);
                  handleUnitDoor(false);
                }}
              />
            </label>
          </div>
          <div className="mt-2">
            <p className="break-words text-base text-gray-500">
              Allow this person to access your property’s common doors during
              access hours.
            </p>
          </div>
        </div>
        {enabled && isDelegateLivlyKeyUnitDoorEnabled && (
          <>
            <div className="mt-8">
              <div className="flex justify-between items-center">
                <p className="text-xl font-normal">Unit Door</p>
                <label className="flex flex-col items-end">
                  <LivlySwitch
                    checked={guest?.accessControl?.allowUnitDoors ?? false}
                    onChange={(checked: boolean) => handleUnitDoor(checked)}
                  />
                </label>
              </div>
              <div className="mt-2">
                <p className="break-words text-base text-gray-500">
                  Allow this person to access your unit during the time
                  specified below
                </p>
              </div>
            </div>
          </>
        )}

        {enabled && (
          <div className="mt-8">
            <Button color="secondary" onClick={() => setIsOpen(true)}>
              Edit access schedule
            </Button>
            <div className="my-8">
              {guest?.accessControl?.schedule?.days.map((day) => (
                <div className="flex items-center" key={day.day}>
                  <div className="w-44">
                    <p className="text-lg text-gray-700">{day?.day}</p>
                  </div>
                  <div>
                    {day?.disabled ? (
                      <p className="tetx-lg text-gray-500">Off</p>
                    ) : (
                      <p className="tetx-lg text-gray-500">
                        {moment()
                          .startOf("day")
                          .add(day?.startTime, "minutes")
                          .format("LT")}{" "}
                        -{" "}
                        {moment()
                          .startOf("day")
                          .add(day?.endTime, "minutes")
                          .format("LT")}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="flex justify-end gap-2 mt-8">
          <Button
            size="small"
            color="primary"
            onClick={() => handleNext()}
            className="flex items-center gap-2"
          >
            Next
          </Button>
        </div>
      </div>
      <AccessScheduleModal open={open} onClose={() => setIsOpen(false)} />
    </Layout>
  );
};

export default LivlyKeyPermission;
