//limit size in mb
export const isValidSize = (size: number, limit = 20) => {
  return size / 1024 / 1024 < limit;
};

export const isValidFormat = (
  extension = "",
  allowedExtensions: string[] = []
) => {
  return allowedExtensions.find((ext) => ext === extension) !== undefined;
};
