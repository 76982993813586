import axios from "axios";
import FeedPostListItem from "./community-feed/feed-post-list-item";
import { BaseLivlyApiResponse } from "@/types/Base";
import { FeedPost, ReactionType, TagType } from "@/utils/commfeed";
import { BASE_API_URL } from "@/utils/constants";
import useLivlyUser from "@/context/UserProvider";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import {
  addFeedPostComment,
  deleteCommFeedById,
  deleteFeedPostComment,
  editFeedPostAndImages,
  editFeedPostComment,
  getMyCommFeeds,
  hideCommFeed,
  putFeedPostReaction
} from "./community-feed/hook/usePost";
import toast from "react-hot-toast";
import { ImageUpload } from "react-file-utils";
import { useMemo } from "react";

function usePosts(propertyId: number, leaseId: number, limit: number) {
  const myPostsQuery = (
    propertyId: number,
    leaseId: number,
    limit: number = 20
  ) => ({
    queryKey: ["community-feed-user", propertyId, leaseId],
    queryFn: async ({ pageParam = 0 }) =>
      getMyCommFeeds(leaseId, limit, pageParam),
    getNextPageParam: (lastPage: any) => {
      return lastPage.nextPage;
    }
  });

  return useInfiniteQuery({
    ...myPostsQuery(propertyId, leaseId, limit),
    //@ts-ignore
    initialPageParam: 0
  });
}

const MyCommunityFeed = () => {
  const { user } = useLivlyUser();
  const queryClient = useQueryClient();
  const {
    data,
    isLoading,
    fetchNextPage,
    isError,
    hasNextPage,
    isFetchingNextPage
  } = usePosts(user.propertyId, user.propertyUnitLeaseId, 20);

  const deletePostMutation = useMutation({
    mutationFn: ({ leaseId, postId }: { leaseId: number; postId: number }) =>
      deleteCommFeedById(leaseId, postId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "community-feed-user",
          user.propertyId,
          user.propertyUnitLeaseId
        ]
      });
      toast.success("Post has been deleted successfully");
    },
    onError: (e) => {
      const error = e as { data?: { Message?: string } };
      toast.error(error?.data?.Message ?? "An error occurred");
    }
  });

  const hidePostMutation = useMutation({
    mutationFn: ({ leaseId, postId }: { leaseId: number; postId: number }) =>
      hideCommFeed(leaseId, postId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "community-feed-user",
          user.propertyId,
          user.propertyUnitLeaseId
        ]
      });
      toast.success("Post has been hidden successfully");
    },
    onError: (e) => {
      const error = e as { data?: { Message?: string } };
      toast.error(error?.data?.Message ?? "An error occurred");
    }
  });

  const editPostMutation = useMutation({
    mutationFn: ({
      post,
      images,
      text,
      tag
    }: {
      post: FeedPost;
      images: ImageUpload[];
      text: string;
      tag: TagType;
    }) =>
      editFeedPostAndImages(user.propertyUnitLeaseId, post, images, text, tag),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "community-feed-user",
          user.propertyId,
          user.propertyUnitLeaseId
        ]
      });
    },
    onError: (e) => {
      const error = e as { data?: { Message?: string } };
      toast.error(error?.data?.Message ?? "An error occurred");
    }
  });

  const addFeedPostReactionMutation = useMutation({
    mutationFn: ({
      leaseId,
      postId,
      type
    }: {
      leaseId: string;
      postId: string;
      type: ReactionType | null;
    }) => putFeedPostReaction(leaseId, postId, type),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "community-feed-user",
          user.propertyId,
          user.propertyUnitLeaseId
        ]
      });
    },
    onError: (e) => {
      const error = e as { data?: { Message?: string } };
      toast.error(error?.data?.Message ?? "An error occurred");
    }
  });

  const addCommentMutation = useMutation({
    mutationFn: ({
      leaseId,
      postId,
      comment
    }: {
      leaseId: string;
      postId: string;
      comment: string;
    }) => addFeedPostComment(leaseId, postId, comment),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "community-feed-user",
          user.propertyId,
          user.propertyUnitLeaseId
        ]
      });
    },
    onError: (e) => {
      const error = e as { data?: { Message?: string } };
      toast.error(error?.data?.Message ?? "An error occurred");
    }
  });

  const deletePostCommentMutation = useMutation({
    mutationFn: ({
      leaseId,
      postId,
      commentId
    }: {
      leaseId: string;
      postId: string;
      commentId: string;
    }) => deleteFeedPostComment(leaseId, postId, commentId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "community-feed-user",
          user.propertyId,
          user.propertyUnitLeaseId
        ]
      });
    },
    onError: (e) => {
      const error = e as { data?: { Message?: string } };
      toast.error(error?.data?.Message ?? "An error occurred");
    }
  });
  const editCommentMutation = useMutation({
    mutationFn: ({
      leaseId,
      postId,
      commentId,
      comment
    }: {
      leaseId: string;
      postId: string;
      commentId: string;
      comment: string;
    }) => editFeedPostComment(leaseId, postId, commentId, comment),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "community-feed-user",
          user.propertyId,
          user.propertyUnitLeaseId
        ]
      });
    },
    onError: (e) => {
      const error = e as { data?: { Message?: string } };
      toast.error(error?.data?.Message ?? "An error occurred");
    }
  });

  const allPosts = useMemo(() => {
    return (
      data?.pages.reduce((prev, curr) => {
        const posts = curr.data;

        return [...prev, ...posts];
      }, [] as FeedPost[]) ?? []
    );
  }, [data]);

  const onDeleteFeedPost = async (leaseId: string, postId: string) => {
    await deletePostMutation.mutateAsync({
      leaseId: Number(leaseId),
      postId: Number(postId)
    });
  };

  const onHideFeedPost = async (leaseId: string, postId: string) => {
    await hidePostMutation.mutateAsync({
      leaseId: Number(leaseId),
      postId: Number(postId)
    });
  };

  const onEditFeedPost = async (
    post: FeedPost,
    images: ImageUpload[],
    text: string,
    tag: TagType
  ) => {
    return await editPostMutation.mutateAsync({ post, images, text, tag });
  };

  const onUpdatePostReaction = async (
    leaseId: string,
    postId: string,
    reactionType: ReactionType | null
  ) => {
    return await addFeedPostReactionMutation.mutateAsync({
      leaseId,
      postId,
      type: reactionType
    });
  };

  const onAddPostComment = async (
    leaseId: string,
    postId: string,
    comment: string
  ) => {
    return await addCommentMutation.mutateAsync({
      leaseId,
      postId,
      comment
    });
  };

  const onDeleteFeedPostComment = async (
    leaseId: string,
    postId: string,
    commentId: string
  ) => {
    return await deletePostCommentMutation.mutateAsync({
      leaseId,
      postId,
      commentId
    });
  };
  const onEditPostComment = async (
    leaseId: string,
    postId: string,
    commentId: string,
    comment: string
  ) => {
    return await editCommentMutation.mutateAsync({
      leaseId,
      postId,
      commentId,
      comment
    });
  };
  return (
    <>
      {isError ? (
        <div className="p-6">
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <span className="block sm:inline">
              Sorry, there was an error loading your post.
            </span>
          </div>
        </div>
      ) : isLoading ? (
        <div className="flex justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        </div>
      ) : allPosts.length > 0 ? (
        <div>
          {allPosts?.map((post, index) => (
            <FeedPostListItem
              isMyPost
              key={`post-${post.id}-${index}`}
              post={post}
              onDeletePost={onDeleteFeedPost}
              onDeleteComment={onDeleteFeedPostComment}
              onUpdatePostReaction={onUpdatePostReaction}
              onAddPostComment={onAddPostComment}
              onEditPostComment={onEditPostComment}
              onHideFeedPost={onHideFeedPost}
              onEditFeedPost={onEditFeedPost}
              isLoading={
                deletePostMutation.isLoading || hidePostMutation.isLoading
              }
            />
          ))}
        </div>
      ) : (
        <div className="flex h-full justify-center items-center p-5">
          <span className="text-gray-700 text-sm">
            There are no posts in your community
          </span>
        </div>
      )}
      <div className="flex justify-center mt-2">
        {isFetchingNextPage ? (
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        ) : hasNextPage ? (
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => fetchNextPage()}
          >
            Load More
          </button>
        ) : null}
      </div>
    </>
  );
};

export default MyCommunityFeed;
