import numeral from "numeral";
import { AutopayState } from "./useAutopayState";
import isCurrency from "@/utils/isCurrency";
import moment from "moment";
import { User } from "@/types/User";
import { RentBalanceTypeEnum, RentSettingTypeEnum } from "@/types/Rent";

const DWOLLA_LIMIT = 10000;

export function autopayValidator(autopayState: AutopayState, user: User) {
  const {
    autopaySettings,
    rentSettings,
    paymentAccount,
    isFixedAmountFeeDataLoading,
    isFullBalanceFeeDataLoading,
  } = autopayState;

  const isAutoPayActive = autopaySettings?.id != null;
  const paymentAmountCurrency =
    numeral(autopaySettings?.fixedAmount).value() ?? 0;
  const amountError =
    (autopaySettings?.fixedAmount ?? 0) > 0 &&
    !isCurrency(autopaySettings?.fixedAmount?.toString() ?? "");
  const isMaxLimitError =
    rentSettings?.autoPayMaxAmountAllowed != null &&
    paymentAmountCurrency > rentSettings?.autoPayMaxAmountAllowed;
  const isDwollaLimitError =
    paymentAccount?.paymentType === "ACH" &&
    paymentAmountCurrency > DWOLLA_LIMIT;

  const isStartAfterEndError =
    autopaySettings &&
    autopaySettings.startDate != null &&
    autopaySettings.endDate != null &&
    moment(autopaySettings.startDate).isSameOrAfter(autopaySettings.endDate);

  const isStartError =
    autopaySettings &&
    autopaySettings.startDate != null &&
    moment().startOf("day").isSameOrAfter(autopaySettings.startDate);

  const isEndError =
    autopaySettings &&
    autopaySettings.endDate != null &&
    moment(user.leaseEndDate)
      .add(-31, "days")
      .isBefore(autopaySettings.endDate);
  const isDateSelectionAvailable =
    rentSettings.rentBalanceType !== RentBalanceTypeEnum.BalanceBased;

  let isSaveDisabled = false;

  // rules for Balance Based
  if (rentSettings.rentBalanceType === RentBalanceTypeEnum.BalanceBased) {
    if (autopaySettings?.isFullStatementBalance) {
      isSaveDisabled =
        isFullBalanceFeeDataLoading ||
        isMaxLimitError ||
        amountError ||
        isDwollaLimitError ||
        autopaySettings.paymentAccountId == null;
    } else {
      isSaveDisabled =
        isFixedAmountFeeDataLoading ||
        isMaxLimitError ||
        amountError ||
        isDwollaLimitError ||
        autopaySettings?.fixedAmount == null ||
        autopaySettings?.fixedAmount === 0 ||
        autopaySettings?.paymentAccountId == null;
    }
    // rules for Free Form
  } else {
    if (autopaySettings?.isFullStatementBalance) {
      isSaveDisabled =
        isFullBalanceFeeDataLoading ||
        isMaxLimitError ||
        amountError ||
        isDwollaLimitError ||
        autopaySettings.autoPayDay == null ||
        autopaySettings.startDate == null ||
        autopaySettings.endDate == null ||
        (isEndError && !autopaySettings.overrideEndDateRestriction) ||
        autopaySettings.paymentAccountId == null;
    } else {
      isSaveDisabled =
        isFixedAmountFeeDataLoading ||
        isMaxLimitError ||
        amountError ||
        isDwollaLimitError ||
        autopaySettings?.fixedAmount == null ||
        autopaySettings?.fixedAmount === 0 ||
        autopaySettings?.autoPayDay == null ||
        autopaySettings?.startDate == null ||
        autopaySettings?.endDate == null ||
        (isEndError && !autopaySettings.overrideEndDateRestriction) ||
        autopaySettings?.paymentAccountId == null;
    }
  }

  /* if (!autopaySettings?.isFullStatementBalance) {
    isSaveDisabled =
      isSaving ||
      isFixedAmountFeeDataLoading ||
      isFullBalanceFeeDataLoading ||
      autopaySettings?.fixedAmount == null ||
      autopaySettings?.fixedAmount === 0 ||
      amountError ||
      isDwollaLimitError ||
      isMaxLimitError ||
      isStartAfterEndError ||
      isStartError ||
      (isEndError && !autopaySettings.overrideEndDateRestriction) ||
      autopaySettings?.paymentAccountId == null ||
      autopaySettings?.startDate == null ||
      autopaySettings?.endDate == null ||
      autopaySettings?.autoPayDay == null;
    debugger;
  } else {
    isSaveDisabled =
      isSaving ||
      isMaxLimitError ||
      isFixedAmountFeeDataLoading ||
      isFullBalanceFeeDataLoading ||
      autopaySettings.paymentAccountId == null ||
      autopaySettings.isFullStatementBalance === null ||
      (settings.isFullStatementBalance === false &&
        (settings.fixedAmount == null ||
          settings.fixedAmount === 0 ||
          amountError ||
          isDwollaLimitError ||
          isMaxLimitError));
  }*/

  return {
    isSaveDisabled,
    isDateSelectionAvailable,
    isAutoPayActive,
    amountError,
    isMaxLimitError: isMaxLimitError ?? false,
    isDwollaLimitError: isDwollaLimitError ?? false,
    isStartAfterEndError: isStartAfterEndError ?? false,
    isStartError: isStartError ?? false,
    isEndError: isEndError ?? false,
  };
}
