import { LedgerStatus } from "../../types/Rent";

type LabelVariantTypes = "default" | "info" | "warning" | "success" | "danger";
type BackgroundVariantTypes = "success" | "primary" | "danger" | "info";
export interface LedgerStatusDetails {
  labelKey: string;
  labelVariant: LabelVariantTypes;
  backgroundVariant: BackgroundVariantTypes;
  borderVariant: BackgroundVariantTypes;
}

export const getLedgerStatusDetails = (
  ledgerStatus: LedgerStatus | undefined,
  isAutoPayOn: boolean
): LedgerStatusDetails => {
  let labelKey: string = "";
  let labelVariant: LabelVariantTypes = "default";
  let backgroundVariant: BackgroundVariantTypes = "primary";
  let borderVariant: BackgroundVariantTypes = "primary";

  switch (ledgerStatus) {
    case "Paid":
      labelKey = "Paid";
      labelVariant = "success";
      backgroundVariant = "success";
      borderVariant = "success";
      break;
    case "Posted":
      labelKey = "Posted";
      labelVariant = "default";
      backgroundVariant = "primary";
      borderVariant = "primary";
      break;
    case "ChargesRequired":
      labelKey = "Charges required";
      labelVariant = "danger";
      backgroundVariant = "danger";
      borderVariant = "danger";
      break;

    case "Overdue":
      labelKey = "Overdue";
      labelVariant = "danger";
      backgroundVariant = "danger";
      borderVariant = "primary";
      break;

    case "Ready":
    case "NotReady":
    case "Unknown":
    case "Unpaid":
      labelKey = "Unpaid";
      labelVariant = "default";
      backgroundVariant = "primary";
      borderVariant = "primary";
      break;
  }

  return {
    labelKey,
    labelVariant,
    backgroundVariant: backgroundVariant,
    borderVariant,
  };
};
