import moment from "moment-timezone";
import { Button } from "../components/Button";
import Layout from "../components/Layout";
import copy from "copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent } from "react";
import { toast } from "react-hot-toast";
import { textInputClass } from "../components/Form";
import useLivlyUser from "../context/UserProvider";
import { ACCESS_TOKEN_KEY, EXPIRES_IN_KEY } from "@/utils/localStorage";
import { WEBVIEW_PATHS } from "@/utils/webviews";

const baseWebViewPath =
  "login?access_token=:accessToken&expires_in=:expiresIn&mobile=true&platform=ios&path=:path&property_unit_lease_id=:leaseId&app_version=native";

function TestWebViewPage() {
  const { user } = useLivlyUser();
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY) as string;
  const expiresIn = localStorage.getItem(EXPIRES_IN_KEY) as string;

  const generateWebViewLink = (path: WEBVIEW_PATHS) => {
    const initialWebViewPath = baseWebViewPath
      .replace(":accessToken", accessToken)
      .replace(":expiresIn", expiresIn)
      .replace(":path", path)
      .replace(":leaseId", user.propertyUnitLeaseId.toString());

    return initialWebViewPath;
  };

  const gotoMaintenanceTicketDetails = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const ticketId = data.get("maintenanceTicketId") as string;

    const initialWebViewPath = generateWebViewLink(WEBVIEW_PATHS.MAINTENANCE);

    const webviewPath = `${window.origin}/${initialWebViewPath}&maintenanceTicketId=${ticketId}`;

    toast.success("URL Ready!");
    copy(webviewPath);
  };

  const gotoEventDetails = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const eventId = data.get("eventId") as string;

    const initialWebViewPath = generateWebViewLink(WEBVIEW_PATHS.EVENTS);

    const webviewPath = `${window.origin}/${initialWebViewPath}&eventId=${eventId}`;

    toast.success("URL Ready!");
    copy(webviewPath);
  };

  const gotoRent = () => {
    const initialWebViewPath = generateWebViewLink(WEBVIEW_PATHS.RENT);

    const webviewPath = `${window.origin}/${initialWebViewPath}`;

    toast.success("URL Ready!");
    copy(webviewPath);
  };

  const gotoFitnessPaymentMethods = () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    const webviewPath = `${window.origin}/${WEBVIEW_PATHS.FITNESS_PAYMENTS}?access_token=${accessToken}&user_id=${user.userId}&property_unit_lease_id=${user.propertyUnitLeaseId}`;

    toast.success("URL Ready!");
    copy(webviewPath);
  };

  const gotoInspectionChecklist = () => {
    const initialWebViewPath = generateWebViewLink(
      WEBVIEW_PATHS.INSPECTION_CHECKLIST
    );

    const webviewPath = `${window.origin}/${initialWebViewPath}`;

    toast.success("URL Ready!");
    copy(webviewPath);
  };

  return (
    <Layout title="Test Webviews">
      <div>
        <div>
          <p className="font-medium">Access token:</p>
          <p className="mt-1 text-sm">{accessToken}</p>
          <Button
            color="default"
            className="flex items-center gap-2 mt-4"
            onClick={() => {
              copy(accessToken!);
              toast.success("Access token copied");
            }}
          >
            <FontAwesomeIcon icon="copy" />
            Copy
          </Button>

          <p className="mt-4 font-medium">Expires in:</p>
          <p className="mt-1 text-sm">
            {expiresIn}{" "}
            <span className="text-gray-600">
              {moment(new Date(Number(expiresIn))).format("M/D/YYYY h:mm a")}
            </span>
          </p>
        </div>
        <h4 className="mt-6 text-lg font-medium">Pick a page to test</h4>
        <p className="text-sm text-gray-600">
          Generating a link will copy the new link to your clip board. Open a
          new incognito window and paste in the new url
        </p>
        <ul className="mt-6">
          <li className="py-2">
            <h3>Rent</h3>
            <Button onClick={gotoRent}>Rent</Button>
          </li>
          <li className="py-2">
            <h3>Maintenance</h3>
            <form onSubmit={gotoMaintenanceTicketDetails} className="space-y-2">
              <div>
                <input
                  required
                  type="text"
                  name="maintenanceTicketId"
                  placeholder="Enter maintenance ticket id"
                  className={textInputClass}
                />
              </div>
              <div>
                <Button type="submit">Maintenance</Button>
              </div>
            </form>
          </li>
          <li className="py-2">
            <h3>Events</h3>
            <form onSubmit={gotoEventDetails} className="space-y-2">
              <div>
                <input
                  type="text"
                  name="eventId"
                  placeholder="Enter event id"
                  className={textInputClass}
                />
              </div>
              <div>
                <Button type="submit">Event Details</Button>
              </div>
            </form>
          </li>
          <li className="py-1">
            <h3>Fitness Payment Methods</h3>
            <Button onClick={gotoFitnessPaymentMethods}>Fitness Payment</Button>
          </li>
          <li className="py-2">
            <h3>Inspection Checklist</h3>
            <Button onClick={gotoInspectionChecklist}>
              Inspection Checklist
            </Button>
          </li>
        </ul>
      </div>
    </Layout>
  );
}

export { TestWebViewPage };
