import Layout from "../../components/Layout";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FormEvent, useEffect, useState } from "react";
import Alert from "../../components/Alert";
import { Button } from "../../components/Button";
import axios from "axios";
import { BaseLivlyApiResponse } from "../../types/Base";
import { PaymentAccount } from "../../types/User";
import { BASE_API_URL } from "../../utils/constants";
import { useLocation, useNavigate } from "react-router-dom";
import { trackAddPaymentAccount } from "../../utils/analytics";
import { Spinner } from "../../components/Spinner";
import useLivlyUser from "../../context/UserProvider";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);

export default function AddCardPage() {
  useEffect(() => {
    trackAddPaymentAccount();
  }, []);

  return (
    <Layout
      title="Add Card"
      back={{ label: "Add Payment Method", to: "../wallet/add" }}
    >
      <div className="max-w-sm mx-auto">
        <Elements stripe={stripePromise}>
          <AddCardForm />
        </Elements>
      </div>
    </Layout>
  );
}

type AddCardRequestModel = {
  token: string;
  brand: string;
  last4: string;
  expirationYear: number;
  expirationMonth: number;
};

async function postCard({
  data,
  userId,
}: {
  data: AddCardRequestModel;
  userId: number;
}) {
  const result = await axios.post<BaseLivlyApiResponse<PaymentAccount>>(
    `${BASE_API_URL}/livly/payment/AddCreditCard/${userId}`,
    data
  );

  return result.data.Data;
}

function AddCardForm() {
  const returnData = useLocation().state as { to: string; label: string };
  const { user } = useLivlyUser();
  const stripe = useStripe();
  const elements = useElements();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [fetchingStripeToken, setFetchingStripeToken] = useState(false);
  const [error, setError] = useState("");

  const { mutate, isLoading } = useMutation((data: AddCardRequestModel) =>
    postCard({ data, userId: user.userId })
  );

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setError("");

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }

    setFetchingStripeToken(true);
    const result = await stripe.createToken(cardElement);

    if (result.error) {
      setError(result.error.message ?? "There was an error adding card.");
      setFetchingStripeToken(false);
    } else {
      const { id, card } = result.token;

      const { brand, last4, exp_year, exp_month } = card || {};
      const data = {
        token: id,
        brand: brand!,
        last4: last4!,
        expirationYear: exp_year!,
        expirationMonth: exp_month!,
      };

      mutate(data, {
        onSuccess: () => {
          queryClient.invalidateQueries(["payment-accounts"]);
          setError("");
          setFetchingStripeToken(false);
          navigate(returnData ? returnData.to : `../wallet`);
        },
        onError: (e) => {
          const error = e as { data: { Message: string } };
          const message = error.data.Message ?? "Error adding card";
          setError(message);
          setFetchingStripeToken(false);
        },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && (
        <div style={{ padding: "12px 0" }}>
          <Alert message={error} />
        </div>
      )}
      <div className="p-4 mt-6 mb-8 bg-gray-100 rounded-lg">
        <CardElement options={{ disableLink: true }} />
      </div>
      <div className="flex md:justify-end">
        <Button
          type="submit"
          disabled={isLoading}
          className="relative flex items-center justify-center w-full gap-2 md:w-auto"
        >
          {(isLoading || fetchingStripeToken) && <Spinner />} Save Card
        </Button>
      </div>
    </form>
  );
}
