import { Button } from "../../components/Button";
import { labelClass, selectClass, textInputClass } from "../../components/Form";
import Layout from "../../components/Layout";
import { Address } from "../../types/User";
import axios from "axios";
import { BASE_API_URL } from "../../utils/constants";
import { BaseLivlyApiResponse } from "../../types/Base";
import {
  ActionFunctionArgs,
  Form,
  redirect,
  useNavigation,
  useParams,
} from "react-router-dom";
import useLivlyUser from "../../context/UserProvider";

async function postAddress(userId: number, address: Address) {
  const result = await axios.post<BaseLivlyApiResponse<Address>>(
    `${BASE_API_URL}/livly/users/${userId}/address`,
    address
  );

  return result.data.Data;
}

export const action = async ({ request, params }: ActionFunctionArgs) => {
  const formData = await request.formData();

  const userId = formData.get("userId") as string;
  const address1 = formData.get("address1") as string;
  const address2 = formData.get("address2") as string;
  const city = formData.get("city") as string;
  const state = formData.get("state") as string;
  const zipCode = formData.get("zipCode") as string;

  await postAddress(Number(userId), {
    address1,
    address2,
    city,
    state,
    zipCode,
    id: Number(params.id),
    isLeaseAddress: true,
    latitude: null,
    longitude: null,
  });

  return redirect("../insurance-quote/address");
};

function InsuranceAddressEditPage() {
  const params = useParams<{ id: string }>();
  const { user } = useLivlyUser();
  const navigation = useNavigation();
  const isSubmitting = navigation.state !== "idle";

  const address = user.addresses.find((a) => a.id === Number(params.id));

  return (
    <Layout
      title="Update Address"
      back={{
        label: "Addresses",
        to: `../insurance-quote/address`,
      }}
    >
      <Form className="max-w-4xl mx-auto" method="post">
        <input type="hidden" name="userId" value={user.userId} />
        <fieldset disabled={isSubmitting}>
          <div className="mt-4">
            <label htmlFor="address1" className={labelClass}>
              street and number, P.O. box, c/o
            </label>
            <input
              required
              id="address1"
              name="address1"
              type="text"
              className={textInputClass}
              maxLength={500}
              defaultValue={address?.address1}
            />
          </div>
          <div className="mt-4">
            <label htmlFor="address2" className={labelClass}>
              apartment/unit
            </label>
            <input
              id="address2"
              name="address2"
              type="text"
              className={textInputClass}
              maxLength={50}
              defaultValue={address?.address2}
            />
          </div>
          <div className="mt-4">
            <label htmlFor="city" className={labelClass}>
              city
            </label>
            <input
              required
              id="city"
              name="city"
              type="text"
              className={textInputClass}
              maxLength={250}
              defaultValue={address?.city}
            />
          </div>
          <div className="mt-4">
            <label htmlFor="state" className={labelClass}>
              state
            </label>
            <select
              required
              id="state"
              name="state"
              className={selectClass}
              defaultValue={address?.state}
            >
              <option value="">Select state</option>
              {states.map((state) => (
                <option key={state.abbreviation} value={state.abbreviation}>
                  {state.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-4">
            <label htmlFor="zipCode" className={labelClass}>
              zip code
            </label>
            <input
              required
              pattern="\d*"
              id="zipCode"
              name="zipCode"
              type="text"
              className={textInputClass}
              maxLength={5}
              title="Please use numbers for zip code"
              defaultValue={address?.zipCode}
            />
          </div>
          <div className="mt-8 flex justify-end">
            <Button color="secondary" type="submit">
              Update address
            </Button>
          </div>
        </fieldset>
      </Form>
    </Layout>
  );
}

export { InsuranceAddressEditPage };

const states = [
  {
    name: "Alabama",
    abbreviation: "AL",
  },
  {
    name: "Alaska",
    abbreviation: "AK",
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
  },
  {
    name: "California",
    abbreviation: "CA",
  },
  {
    name: "Colorado",
    abbreviation: "CO",
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
  },
  {
    name: "Delaware",
    abbreviation: "DE",
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC",
  },
  {
    name: "Florida",
    abbreviation: "FL",
  },
  {
    name: "Georgia",
    abbreviation: "GA",
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
  },
  {
    name: "Idaho",
    abbreviation: "ID",
  },
  {
    name: "Illinois",
    abbreviation: "IL",
  },
  {
    name: "Indiana",
    abbreviation: "IN",
  },
  {
    name: "Iowa",
    abbreviation: "IA",
  },
  {
    name: "Kansas",
    abbreviation: "KS",
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
  },
  {
    name: "Maine",
    abbreviation: "ME",
  },
  {
    name: "Maryland",
    abbreviation: "MD",
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
  },
  {
    name: "Michigan",
    abbreviation: "MI",
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
  },
  {
    name: "Missouri",
    abbreviation: "MO",
  },
  {
    name: "Montana",
    abbreviation: "MT",
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
  },
  {
    name: "Nevada",
    abbreviation: "NV",
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
  },
  {
    name: "New York",
    abbreviation: "NY",
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
  },
  {
    name: "Ohio",
    abbreviation: "OH",
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
  },
  {
    name: "Oregon",
    abbreviation: "OR",
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
  },
  {
    name: "Texas",
    abbreviation: "TX",
  },
  {
    name: "Utah",
    abbreviation: "UT",
  },
  {
    name: "Vermont",
    abbreviation: "VT",
  },
  {
    name: "Virginia",
    abbreviation: "VA",
  },
  {
    name: "Washington",
    abbreviation: "WA",
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
  },
];
