import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { StreamChat } from "stream-chat";
import useLivlyUser from "./UserProvider";
import { Outlet } from "react-router-dom";

interface ChatContextShape {
  chatClient: StreamChat | null;
}

const initialState: ChatContextShape = {
  chatClient: null,
};

const ChatContext = createContext<ChatContextShape>(initialState);

function ChatProvider() {
  const { user } = useLivlyUser();
  const { isChatUserSet, setIsChatUserSet } = useLivlyUser();

  // ✅ You should disconnect user once you are done with chatClient
  const [chatClient, setChatClient] = useState<StreamChat | null>(null);

  useEffect(() => {
    const initChat = async () => {
      const client = StreamChat.getInstance(
        import.meta.env.VITE_STREAM_CLIENT_ID
      );

      if (!isChatUserSet) {
        // open the WebSocket connection to start receiving events
        await client.connectUser(
          { id: user.streamUserId! },
          user.chatAccessToken!
        );
        setIsChatUserSet(true);
      }

      setChatClient(client);
    };

    initChat();

    // close the WebSocket connection when component dismounts
    return () => {
      chatClient?.disconnectUser();
    };
  }, []);

  const value = useMemo(
    () => ({
      chatClient,
    }),
    [chatClient]
  );

  if (!chatClient) {
    return null;
  }

  return (
    <ChatContext.Provider value={value}>
      <Outlet />
    </ChatContext.Provider>
  );
}

export { ChatProvider };

export default function useLivlyChat() {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error(`useLivlyChat must be used within a ChatContextProvider`);
  }

  return context;
}
