import React from "react";
import { useTranslation } from "react-i18next";
import TextareaAutosize from "react-textarea-autosize";

const StreamTextarea = ({
  autoFocus = false,
  rows = 3,
  className,
  placeholder: propsPlaceholder,
  innerRef,
  value,
  onFocus,
  onBlur,
  ...props
}: Props) => {
  const autoFocusRef = React.useRef(false);
  // const classes = useStyles();
  const { t } = useTranslation();
  const placeholder: string = propsPlaceholder || "What do you want to share?";

  const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (!autoFocusRef.current) {
      var temp_value = e.target.value;
      e.target.value = "";
      e.target.value = temp_value;

      autoFocusRef.current = true;
    }
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <TextareaAutosize
      ref={innerRef}
      autoFocus={autoFocus}
      value={value}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={props.onChange}
      minRows={rows}
      placeholder={placeholder}
      className={`flex bg-transparent z-100 rounded leading-7 text-sm w-full ${
        className ||
        "focus:outline-none focus-visible:outline-none focus:ring-transparent  font-normal !border-transparent rounded min-h-8 text-[#262b2b] resize-none text-base leading-[22px] transition-background duration-500 ease-in-out !focus:outline-none focus:bg-[#9b9b9b26]"
      }`}
    />
  );
};

export type Props = {
  autoFocus?: boolean;
  rows?: number;
  maxLength?: number;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onPaste?: (event: React.ClipboardEvent<HTMLTextAreaElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  className?: string;
  value?: string;
  // A ref that is bound to the textarea element
  innerRef?: React.RefObject<HTMLTextAreaElement> | null;
};

export default StreamTextarea;
