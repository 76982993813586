export interface Inspection {
  inspectionSections: InspectionSection[];
  termsOfService: string;
}

export interface InspectionSection {
  inspectionSectionTypeId: InspectionSectionTypeEnum;
  name: string;
  ordinal: number;
  inspectionItemTypes: InspectionItemType[];
}

export interface InspectionItemType {
  inspectionItemTypeId: InspectionTypeEnum;
  name: string;
  isFlagged: boolean;
  description: string | null;
  images: string[];
}

export enum InspectionTypeEnum {
  Other = 0,
  Walls,
  Ceiling,
  LightFixtures,
  PlugsSwitches,
  Woodwork,
  Floor,
  Stove,
  Dishwasher,
  Sink,
  Toilet,
  ShowerBath,
}

export enum InspectionSectionTypeEnum {
  Other = 0,
  LivingRoom,
  Bedroom,
  Kitchen,
  Bathroom,
  Hallway,
}

export interface PostInspectionSection {
  inspectionSectionTypeId: InspectionSectionTypeEnum;
  inspectionItems: InspectionItemType[];
}

export interface PostInspectionChecklist {
  inspectionSections: PostInspectionSection[];
  signature: string;
}
