import React, { useState } from "react";
import {
  PollQuestion,
  PollQuestionType,
  PollResult,
  questionAnswersType
} from "./hooks/types";
import PollSingleSelect from "./PollSingleSelect";
import PollMultiSelect from "./PollMultiSelect";
import { PollTextArea } from "./PollTextArea";
import { PollTextInput } from "./PollTextInput";

type Props = {
  step: number;
  total: number;
  report?: PollResult;
  onSelectQuestion: (id: number) => void;
  selectedQuestion: PollQuestion | undefined;
  userAnswers: questionAnswersType[];
  updateOther: (questionId: number, other: string) => void;
  setAnswers: (questionId: number, answerId: any) => void;
  setIsSubmitDisabled: (item: boolean) => void;
  isExpired?: boolean;
};
export const PollQuestionItem = ({
  step,
  updateOther,
  selectedQuestion,
  userAnswers,
  setAnswers,
  setIsSubmitDisabled,
  isExpired
}: Props) => {
  const {
    questionTitle,
    residentPollQuestionId,
    type = PollQuestionType.SingleSelect,
    options = []
  } = selectedQuestion || {};

  const multiSelectOptionsIds =
    (type === PollQuestionType.MultiSelect &&
      userAnswers
        ?.filter(
          (answer) => answer.residentPollQuestionId == residentPollQuestionId
        )
        .map((item) => item.residentPollQuestionOptionId)) ||
    [];

  return (
    <>
      <div className="p-4">
        <p className="font-normal text-xl">
          {step + 1}. {questionTitle}
        </p>
        <div className="my-4">
          {type === PollQuestionType.SingleSelect ? (
            <PollSingleSelect
              userAnswers={userAnswers}
              options={options}
              setAnswers={setAnswers}
              updateOther={updateOther}
              residentPollQuestionId={Number(residentPollQuestionId)}
              setIsSubmitDisabled={setIsSubmitDisabled}
              isExpired={isExpired}
            />
          ) : type === PollQuestionType.MultiSelect ? (
            <PollMultiSelect
              userAnswers={userAnswers}
              multiSelectOptionsIds={multiSelectOptionsIds}
              options={options}
              setAnswers={setAnswers}
              updateOther={updateOther}
              residentPollQuestionId={Number(residentPollQuestionId)}
              setIsSubmitDisabled={setIsSubmitDisabled}
              isExpired={isExpired}
            />
          ) : type === PollQuestionType.LongText ? (
            <PollTextArea
              updateOther={updateOther}
              residentPollQuestionId={Number(residentPollQuestionId)}
              setIsSubmitDisabled={setIsSubmitDisabled}
              isExpired={isExpired}
              userAnswers={userAnswers}
            />
          ) : (
            <PollTextInput
              updateOther={updateOther}
              residentPollQuestionId={Number(residentPollQuestionId)}
              setIsSubmitDisabled={setIsSubmitDisabled}
              isExpired={isExpired}
              userAnswers={userAnswers}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PollQuestionItem;
