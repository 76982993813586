import { BaseLivlyApiResponse } from "@/types/Base";
import { RentHistoryItem } from "@/types/Rent";
import { BASE_API_URL } from "@/utils/constants";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

async function getRentHistoryItems(leaseId: string) {
  const result = await axios.get<BaseLivlyApiResponse<RentHistoryItem[]>>(
    `${BASE_API_URL}/livly/payment/${leaseId}/rentHistory?rentPaymentsOnly=false`
  );

  return result.data.Data;
}

export default function useGetRentHistory(leaseId: string) {
  return useQuery({
    queryKey: ["rent", "history", leaseId],
    queryFn: () => getRentHistoryItems(leaseId),
  });
}
