import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import VehicleForm, { VehicleRequest } from "../../components/VehicleForm";
import { BASE_API_URL } from "../../utils/constants";
import useLivlyUser from "../../context/UserProvider";

export function usePostVehicle(leaseId: string, userId: number) {
  const postVehicle = async (vehicle: VehicleRequest) => {
    return await axios.post(
      `${BASE_API_URL}/livly/lease/${leaseId}/users/${userId}/vehicles`,
      vehicle
    );
  };

  return useMutation(postVehicle);
}

export default function AddVehiclePage() {
  const queryClient = useQueryClient();
  const { user } = useLivlyUser();
  const navigate = useNavigate();
  const params = useParams<{ leaseId: string }>();
  const { mutate, isLoading, error } = usePostVehicle(
    params.leaseId!,
    user.userId
  );

  const onSubmit = (vehicle: VehicleRequest) => {
    mutate(vehicle, {
      onSuccess: () => {
        navigate("../vehicles");

        queryClient.invalidateQueries(["lease-vehicles"]);
      },
    });
  };

  return (
    <Layout
      back={{ to: "../vehicles", label: "My Vehicles" }}
      title="Add Vehicle"
    >
      <div className="max-w-3xl mx-auto">
        <VehicleForm isLoading={isLoading} error={error} onSubmit={onSubmit} />
      </div>
    </Layout>
  );
}
