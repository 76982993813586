import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Form, Formik, useField } from "formik";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import InputMask from "react-input-mask";

import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { Button } from "../../components/Button";
import { Modal } from "../../components/Dialog";
import { FormField, textInputClass } from "../../components/Form";
import { PaymentAccount } from "../../types/User";
import { BASE_API_URL } from "../../utils/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spinner } from "../../components/Spinner";
import useLivlyUser from "../../context/UserProvider";

type InitiateMicroDepositsRequestModel = {
  fundingSourceUrl: string;
  accountMask: string;
  institutionName: "test";
};

async function initiateMicroDeposits(
  userId: number,
  data: InitiateMicroDepositsRequestModel
) {
  const result = await axios.post(
    `${BASE_API_URL}/livly/payment/InitiateMicroDeposits/${userId}`,
    data
  );

  return result.data;
}

function useVerifyAccount(userId: number) {
  const postVerifyAccount = async (data: VerifyMicroDepostRequestModel) => {
    return await axios.post(
      `${BASE_API_URL}/livly/payment/VerifyMicroDeposits/${userId}`,
      data
    );
  };

  return useMutation(postVerifyAccount);
}

const VerifyAccountSchema = z.object({
  fundingSourceUrl: z.string().nullable(),
  amount1: z.string(),
  amount2: z.string(),
});

type VerifyMicroDepostRequestModel = z.infer<typeof VerifyAccountSchema>;

export default function VerifyAccountPage() {
  const [error, setError] = useState("");
  const { user } = useLivlyUser();
  const location = useLocation().state as { account: PaymentAccount };
  const account = location.account;
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError } = useVerifyAccount(user.userId);

  useEffect(() => {
    setOpen(true);
  }, []);

  if (!account) {
    return <Navigate to={`../wallet`} />;
  }

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => navigate(-1), 150);
  };

  const handleSubmit = (data: VerifyMicroDepostRequestModel) => {
    setError("");

    const amount1 = data.amount1.replace("$", "");
    const amount2 = data.amount2.replace("$", "");

    mutate(
      { ...data, amount1, amount2 },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["payment-accounts"]);
          handleClose();
        },
        onError: (e) => {
          const error = e as { data?: { Message: string } };
          setError(
            error.data?.Message ?? "There was an error verifying account"
          );
        },
      }
    );
  };

  const initialValues = {
    fundingSourceUrl: account.dwollaFundingSource,
    amount1: "",
    amount2: "",
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      title="e-check verification"
      size="lg"
    >
      <div>
        <p className="mt-4 font-light">
          Please verify your account by entering the values for each
          "micro-transaction" that was posted to your account. note you only
          have 3 attempts at verification!
        </p>
        <Formik
          initialValues={initialValues}
          validationSchema={toFormikValidationSchema(VerifyAccountSchema)}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              {error && (
                <div className="p-4 my-2 bg-red-100 border-l-4 border-red-500 rounded-md">
                  {JSON.stringify(error, null, 2)}
                </div>
              )}

              <FormField
                htmlFor="amount1"
                label="Amount 1"
                showErrorIcon={errors.amount1 && touched.amount1}
              >
                <MoneyInput name="amount1" id="amount1" />
              </FormField>
              <FormField
                htmlFor="amount2"
                label="Amount 2"
                showErrorIcon={errors.amount2 && touched.amount2}
              >
                <MoneyInput name="amount2" id="amount2" />
              </FormField>

              <div className="flex justify-end mt-4">
                <Button
                  size="small"
                  color="primary"
                  type="submit"
                  disabled={isLoading}
                >
                  {isLoading && <Spinner />}
                  Verify Account
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

function MoneyInput(props: JSX.IntrinsicElements["input"]) {
  const [field] = useField(props.name!);
  return (
    <InputMask mask="$.99" maskChar={"_"} {...field}>
      {
        //@ts-ignore
        (inputProps: any) => {
          return (
            <input
              {...inputProps}
              {...props}
              type="text"
              className={textInputClass}
            />
          );
        }
      }
    </InputMask>
  );
}
