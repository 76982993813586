import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { FormEvent, useState } from "react";
import {
  json,
  LoaderFunctionArgs,
  useLoaderData,
  useRevalidator,
} from "react-router-dom";
import Alert from "../components/Alert";
import { Button } from "../components/Button";
import { Modal } from "../components/Dialog";
import { Spinner } from "../components/Spinner";
import { BaseLivlyApiResponse } from "../types/Base";
import { PaymentAccount } from "../types/User";
import { AUTHORIZATION_HEADER_KEY } from "../utils/auth";
import { BASE_API_URL } from "../utils/constants";

const ACCESS_TOKEN = "access_token";
const LEASE_ID = "property_unit_lease_id";
const USER_ID = "user_id";

// DOES NOT FOLLOW THE TRADITIONAL WEB VIEW FLOW BY HITTING THE LOGIN PAGE FIRST
// EXAMPLE URL
// http://localhost:5173/fitness/payment-methods?access_token=%INSERT_ACCESS_TOKEN%&property_unit_lease_id=%INSERT_LEASE_ID%&user_id=%INSERT_USER_ID%

type CardResponseModel = {
  id: number;
  paymentTypeId: number;
  paymentType: string;
  description: string;
  dwollaFundingSource: string;
  stripeSourceId: string;
  isDefault: boolean;
  brand: string;
  brandImageUrl: string;
  isVerified: boolean;
  isActive: boolean;
};

type AddCardRequestModel = {
  token: string;
  brand: string;
  last4: string;
  expirationYear: number;
  expirationMonth: number;
  userId: string;
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const url = new URL(request.url);
  const params = new URLSearchParams(url.search);
  const accessToken = params.get(ACCESS_TOKEN) as string;
  const userId = params.get(USER_ID) as string;
  const leaseId = params.get(LEASE_ID) as string;

  if (accessToken && userId && leaseId) {
    axios.defaults.headers.common[
      AUTHORIZATION_HEADER_KEY
    ] = `Bearer ${accessToken}`;

    const { data } = await axios.get<BaseLivlyApiResponse<PaymentAccount[]>>(
      `${BASE_API_URL}/resident/payment-account`
    );

    return json({ paymentAccounts: data.Data, userId, leaseId });
  }

  throw new Error("Not enough data provided");
};

async function postCard({
  data,
  userId,
}: {
  data: AddCardRequestModel;
  userId: string;
}) {
  const result = await axios.post<BaseLivlyApiResponse<CardResponseModel>>(
    `${BASE_API_URL}/livly/payment/AddCreditCard/${userId}`,
    data
  );

  return result.data.Data;
}

function FitnessPaymentMethodsPage() {
  const revalidator = useRevalidator();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const { mutateAsync, isLoading } = useMutation((data: AddCardRequestModel) =>
    postCard({ data, userId: data.userId })
  );

  const onSubmit = async (data: AddCardRequestModel) => {
    try {
      await mutateAsync(data);
      setOpen(false);
      revalidator.revalidate();
      setError("");
    } catch (e) {
      const error = e as { data: { Message: string } };
      const message = error.data.Message ?? "Error adding card";
      setError(message);
    }
  };

  return (
    <FitnessPaymentMethods
      error={error}
      open={open}
      setAddCardModalIsOpen={(isOpen) => setOpen(isOpen)}
      onSubmit={onSubmit}
      isLoading={isLoading}
    />
  );
}

function FitnessPaymentMethods({
  error,
  open,
  setAddCardModalIsOpen,
  onSubmit,
  isLoading,
}: {
  error: string;
  open: boolean;
  isLoading: boolean;
  setAddCardModalIsOpen: (open: boolean) => void;
  onSubmit: (data: AddCardRequestModel) => Promise<void>;
}) {
  const data = useLoaderData() as {
    paymentAccounts: PaymentAccount[];
    userId: string;
  };
  const [paymentAccountId, setSelectedPaymentId] = useState<number | null>(
    null
  );

  const handleDone = () => {
    try {
      //eslint-disable-next-line
      eval("Android.fitnessPaymentAccountChanged(paymentAccountId, leaseId)");
    } catch (e) {}
  };

  const activeCreditCards =
    data?.paymentAccounts.filter(
      (account) => account.isActive && account.paymentType === "Credit Card"
    ) ?? [];

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-1 p-4">
        <h2 className="text-xl font-medium">Payments list</h2>
        {activeCreditCards.length > 0 ? (
          <ul className="divide-y divide-gray-200">
            {activeCreditCards.map((account) => (
              <li
                key={account.id}
                className="flex items-center p-4"
                onClick={() => setSelectedPaymentId(account.id)}
              >
                <div className="flex items-center flex-1 gap-3">
                  <img
                    src={account?.brandImageUrl}
                    alt={account?.brand}
                    className="max-w-14 w-auto h-auto object-cover mt-[6px]"
                  />
                  <div>
                    <p>{account.brand}</p>
                    <p className="text-sm text-gray-600">
                      {account.description}
                    </p>
                  </div>
                </div>
                {account.id === paymentAccountId ? (
                  <FontAwesomeIcon icon="check" className="animate-grow" />
                ) : null}
              </li>
            ))}
            <li>
              <button
                className="flex items-center w-full gap-4 p-4 mt-2"
                onClick={() => setAddCardModalIsOpen(true)}
              >
                <FontAwesomeIcon
                  icon={["far", "credit-card"]}
                  className="h-4 text-blue-600 "
                />
                <span className="text-sm ">Add credit/debit card</span>
              </button>
            </li>
          </ul>
        ) : (
          <div>
            <p>There are no active cards on file. Please add a new one.</p>
          </div>
        )}
      </div>
      <div className="p-4">
        <Button
          className="w-full"
          color="secondary"
          disabled={paymentAccountId === null}
          onClick={handleDone}
        >
          Done
        </Button>
      </div>
      <Modal
        open={open}
        onClose={() => {
          if (isLoading) {
            return;
          }

          setAddCardModalIsOpen(false);
        }}
      >
        <StripeContent userId={data.userId} onSubmit={onSubmit} error={error} />
      </Modal>
    </div>
  );
}

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);

function StripeContent({
  error,
  userId,
  onSubmit,
}: {
  error: string;
  userId: string;
  onSubmit: (data: AddCardRequestModel) => Promise<void>;
}) {
  return (
    <div>
      <h3 className="text-lg font-medium">Add card</h3>
      <Elements stripe={stripePromise}>
        <AddCardForm error={error} onSubmit={onSubmit} userId={userId} />
      </Elements>
    </div>
  );
}

function AddCardForm({
  error,
  userId,
  onSubmit,
}: {
  error: string;
  userId: string;
  onSubmit: (data: AddCardRequestModel) => Promise<void>;
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [fetchingStripeToken, setFetchingStripeToken] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }

    setFetchingStripeToken(true);
    const result = await stripe.createToken(cardElement);

    if (result.error) {
      alert(result.error.message);
    } else {
      const { id, card } = result.token;

      const { brand, last4, exp_year, exp_month } = card || {};
      const data: AddCardRequestModel = {
        token: id,
        brand: brand!,
        last4: last4!,
        expirationMonth: exp_month!,
        expirationYear: exp_year!,
        userId,
      };

      await onSubmit(data);

      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} action="post">
      {error && (
        <div className="py-4">
          <Alert variant="danger" message={error} />
        </div>
      )}
      <div className="py-6">
        <CardElement />
      </div>
      <Button
        type="submit"
        color="secondary"
        className="flex items-center justify-center w-full gap-2"
      >
        {isLoading && <Spinner />}
        Add credit/debit card
      </Button>
    </form>
  );
}

export { FitnessPaymentMethodsPage };
