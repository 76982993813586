import { Link, useLocation } from "react-router-dom";
import Layout from "../../components/Layout";
import { PaymentConfirmation } from "../../components/PaymentConfirmation";
import { Button, ButtonWrapper } from "../../components/Button";
import useLivlyUser from "../../context/UserProvider";

function LivlyRentConfirmationPage() {
  const { user } = useLivlyUser();
  const {
    amount = 0,
    paymentDescription = "",
    transactionId = "",
  } = (useLocation().state as {
    amount: 0;
    paymentDescription: "";
    transactionId: "";
  }) || {};

  return (
    <Layout title="Payment Successful">
      <PaymentConfirmation
        amount={amount}
        description={paymentDescription}
        transactionId={transactionId}
      />
      <ButtonWrapper>
        <Link
          to={`../rent/${user.userId}/livly`}
          replace
          className="w-full md:w-auto"
        >
          <Button color="secondary" className="w-full md:w-auto" type="button">
            Done
          </Button>
        </Link>
      </ButtonWrapper>
    </Layout>
  );
}

export { LivlyRentConfirmationPage };
