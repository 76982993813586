import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import useLivlyUser from "../../context/UserProvider";
import { useQueryClient } from "@tanstack/react-query";
import { usePostVehicle } from "../vehicles/add-vehicle";
import VehicleForm, { VehicleRequest } from "../../components/VehicleForm";

export default function ChecklistVehiclesAddPage() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { user } = useLivlyUser();
  const params = useParams<{ leaseId: string }>();
  const { mutate, isLoading, error } = usePostVehicle(
    params.leaseId!,
    user.userId
  );

  const onSubmit = (vehicle: VehicleRequest) => {
    mutate(vehicle, {
      onSuccess: () => {
        navigate("../vehicles");

        queryClient.invalidateQueries(["lease-vehicles"]);
        navigate(`../vehicles`);
      },
    });
  };

  return (
    <Layout back={{ to: -1, label: "My Vehicles" }} title="Add Vehicle">
      <div className="max-w-3xl mx-auto">
        <VehicleForm isLoading={isLoading} error={error} onSubmit={onSubmit} />
      </div>
    </Layout>
  );
}
