import { Button } from "@/components/Button";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Blocker } from "react-router-dom";

export default function ConfirmNavigation({
  open,
  onClose,
  blocker,
}: {
  open: boolean;
  onClose: () => void;
  blocker: Blocker;
}) {
  if (blocker.state !== "blocked") return null;

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center h-full min-h-full p-4 text-center md:items-center md:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white md:rounded-lg md:max-w-md md:w-full md:h-auto z-50 p-5 md:m-auto">
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Warning
                  </Dialog.Title>
                </div>
                <p className="mt-2 text-left">
                  Are you sure you want to leave?
                </p>
                <div className="mt-4 flex justify-end gap-2">
                  <Button
                    color="default"
                    onClick={() => {
                      blocker.reset?.();
                    }}
                  >
                    No
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => {
                      blocker.proceed?.();
                      onClose();
                    }}
                  >
                    Yes
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
