import { NavLink } from "react-router-dom";
import cn from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName, IconProp } from "@fortawesome/fontawesome-svg-core";
import { User, USER_STATUS } from "../types/User";
import { BuildingServiceType, ServiceTypeEnum } from "../types/Building";
import useGetOnboardingAmenitiesEnabled from "../hooks/useGetOnboardingAmenities";

export default function MainMenu({ user }: { user: User }) {
  const menuItems = useGetMenuItems(user);

  return (
    <nav className="flex-1 px-2 space-y-1 overflow-auto bg-white">
      {menuItems
        .filter((mi) => !mi.excludeFromMainMenu)
        .map((item) => (
          <NavItem
            key={item.to}
            label={item.label}
            to={item.to}
            icon={item.icon}
          />
        ))}
    </nav>
  );
}

type NavItemProps = {
  label: string;
  to: string;
  icon: IconProp;
  excludeFromMainMenu?: boolean;
};

function NavItem({ label, to, icon }: NavItemProps) {
  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        cn(
          isActive
            ? "bg-gray-100 text-[#F9877E]"
            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
        )
      }
    >
      <span className="flex justify-center w-6">
        <FontAwesomeIcon icon={icon} />
      </span>
      <span className="inline-block ml-2">{label}</span>
    </NavLink>
  );
}

export function useGetMenuItems(user: User) {
  const { serviceTypes = [] } = user.building || {};

  let navItems: NavItemProps[] = [];

  const { enabled: isRentEnabled = false, displayName: rentDisplayName } =
    serviceTypes.find(
      (service: BuildingServiceType) =>
        service.serviceType === ServiceTypeEnum.Rent
    ) || {};
  const { enabled: isAmenitiesEnabled = false } =
    serviceTypes.find(
      (service: BuildingServiceType) =>
        service.serviceType === ServiceTypeEnum.Amenities
    ) || {};
  const { enabled: isEventsEnabled = false } =
    serviceTypes.find(
      (service: BuildingServiceType) =>
        service.serviceType === ServiceTypeEnum.Events
    ) || {};
  const {
    enabled: isBuildingOffersEnabled = false,
    displayName: marketPlaceDisplayName,
  } =
    serviceTypes.find(
      (service: BuildingServiceType) =>
        service.serviceType === ServiceTypeEnum.MarketPlace
    ) || {};
  const {
    enabled: isMaintenanceEnabled = false,
    displayName: maintenanceDisplayName,
  } =
    serviceTypes.find(
      (service: BuildingServiceType) =>
        service.serviceType === ServiceTypeEnum.Maintenance
    ) || {};
  const { enabled: isGuestsEnabled = false } =
    serviceTypes.find(
      (service: BuildingServiceType) =>
        service.serviceType === ServiceTypeEnum.MyGuests
    ) || {};
  const { enabled: isCommFeedEnabled = false } =
    serviceTypes.find(
      (service: BuildingServiceType) =>
        service.serviceType === ServiceTypeEnum.CommFeed
    ) || {};
  const { enabled: isFitnessEnabled = false } =
    serviceTypes.find(
      (service: BuildingServiceType) =>
        service.serviceType === ServiceTypeEnum.Fitness
    ) || {};
  const { enabled: isCommSurveyEnabled = false } =
    serviceTypes.find(
      (service: BuildingServiceType) =>
        service.serviceType === ServiceTypeEnum.CommSurvey
    ) || {};
  const { enabled: isResidentFeedbackEnabled = false } =
    serviceTypes.find(
      (service: BuildingServiceType) =>
        service.serviceType === ServiceTypeEnum.ResidentFeedback
    ) || {};
  const isResidentPollsEnabled =
    serviceTypes.find(
      (service) => service.serviceType === ServiceTypeEnum.ResidentPolls
    )?.enabled ?? false;

  const isOnboardingAmenitiesEnabled = useGetOnboardingAmenitiesEnabled();
  const isOnboardingComplete = user.onboardingStatuses.every(
    (obs) => (obs.enabled && obs.isCompleted) || obs.isSkipped
  );

  if (user.status === USER_STATUS.CURRENT || isOnboardingComplete) {
    navItems.push({ label: "Home", to: "home", icon: "home-alt" });
  }

  if (user.status === USER_STATUS.APPLICANT) {
    navItems.push({
      label: "Checklist",
      to: `checklist/${user.userId}`,
      icon: "list-check",
    });
  }

  if (isAmenitiesEnabled) {
    navItems.push({
      label: "Amenities",
      to: `amenities/${user.propertyId}`,
      icon: "umbrella-beach",
    });
  }
  if (isEventsEnabled) {
    navItems.push({
      label: "Events",
      to: `events/property/${user.propertyId}`,
      icon: "glass-cheers",
    });
  }
  if (isFitnessEnabled) {
    navItems.push({
      label: "Fitness",
      to: `fitness/${user.propertyId}`,
      icon: "dumbbell",
    });
  }

  if (isMaintenanceEnabled) {
    navItems.push({
      label: maintenanceDisplayName || "Maintenance",
      to: `maintenance/${user.propertyId}`,
      icon: "tools",
    });
  }
  if (isAmenitiesEnabled || isEventsEnabled || isOnboardingAmenitiesEnabled) {
    navItems.push({ label: "Bookings", to: "bookings", icon: "calendar-plus" });
  }
  if (isBuildingOffersEnabled) {
    navItems.push({
      label: marketPlaceDisplayName || "Building Offers",
      to: "marketplace",
      icon: "tag",
    });
  }
  if (user.status !== USER_STATUS.APPLICANT && isRentEnabled) {
    navItems.push({
      label: rentDisplayName || "Rent",
      to: `rent/${user.userId}`,
      icon: "sack-dollar",
    });
  }
  if (isGuestsEnabled) {
    navItems.push({ label: "Guests", to: "guests", icon: "address-book" });
  }
  if (isCommFeedEnabled) {
    navItems.push({
      label: "Community Feed",
      to: "community-feed",
      icon: "comment-alt-check",
    });
  }
  if (isResidentFeedbackEnabled || isResidentPollsEnabled) {
    navItems.push({
      label: "My Feedback",
      to: "my-feedback",
      icon: ["far", "message-dots"],
    });
  }

  return navItems;
}
