import Layout from "@/components/Layout";
import useLivlyUser from "@/context/UserProvider";
import RealPageRentContainer from "../checklist/rent-real-page";

export default function RentRealPage() {
  const { user } = useLivlyUser();
  return (
    <Layout
      title="Rent Real Page"
      back={{ to: "..", label: "Move-in Checklist" }}
    >
      <RealPageRentContainer leaseId={user.propertyUnitLeaseId} />
    </Layout>
  );
}
