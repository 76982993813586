import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { BaseLivlyApiResponse } from "../../types/Base";
import { ServiceProviderType, ServiceTypeEnum } from "../../types/Building";
import { BASE_API_URL } from "../../utils/constants";
import { Spinner } from "../Spinner";
import useLivlyUser from "../../context/UserProvider";

const getClickPayUrl = async (leaseId: number) => {
  const result = await axios.get<
    BaseLivlyApiResponse<{
      url: string;
      leaseId: number;
      leaseExternalId: string;
      token: string;
    }>
  >(`${BASE_API_URL}/livly/users/leases/${leaseId}/clickPayUrl`);

  return result.data.Data;
};

export default function ChecklistClickPayRentWidget() {
  const { user } = useLivlyUser();
  const {
    leaseId,
    propertyUnitLeaseId,
    building: { serviceTypes },
  } = user;
  const { isLoading, mutate } = useMutation(() =>
    getClickPayUrl(leaseId || propertyUnitLeaseId)
  );

  const rentService = serviceTypes?.find(
    (service) => service.serviceType === ServiceTypeEnum.Rent
  );

  const onClick = () => {
    mutate(undefined, {
      onSuccess: (data) => {
        if (data.url) {
          window.open(data.url);
        }
      },
    });
  };

  if (
    rentService?.enabled &&
    rentService.serviceProviderType === ServiceProviderType.ClickPay
  ) {
    return (
      <div className="my-4">
        <button
          onClick={onClick}
          disabled={isLoading}
          className="w-full text-left bg-transparent border-none outline-none cursor-pointer decoration-inherit"
        >
          <div className="relative flex justify-between px-6 py-3 rounded-lg transition duration-300 bg-blue-50 hover:bg-blue-100">
            {isLoading && (
              <div className="absolute top-0 bottom-0 flex items-center left-3">
                <Spinner color="livly" />
              </div>
            )}
            <div>
              <p className="text-lg font-medium">First thing's first</p>
              <p className="mt-0">Tap here to pay your first month’s rent.</p>
            </div>
            <div className="flex items-center">
              <FontAwesomeIcon
                icon="arrow-right"
                className="text-xl text-blue-600"
              />
            </div>
          </div>
        </button>
      </div>
    );
  }

  return null;
}
