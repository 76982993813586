import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import PetForm, { PetRequest } from "../../components/PetForm";
import { BASE_API_URL } from "../../utils/constants";
import useLivlyUser from "../../context/UserProvider";

export function usePostPet(leaseId: string, userId: number) {
  const postPet = async (pet: PetRequest) => {
    return await axios.post(
      `${BASE_API_URL}/livly/lease/${leaseId}/users/${userId}/pets`,
      pet
    );
  };

  return useMutation(postPet);
}

export default function AddPetPage() {
  const queryClient = useQueryClient();
  const { user } = useLivlyUser();
  const navigate = useNavigate();
  const params = useParams<{ leaseId: string }>();
  const { mutate, isLoading, error } = usePostPet(params.leaseId!, user.userId);

  const onSubmit = (pet: PetRequest) => {
    mutate(
      { ...pet, petType: pet.petType || pet.petTypeRadio },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["lease-pets"]);
          navigate("../pets");
        },
      }
    );
  };

  return (
    <Layout back={{ to: "../pets", label: "My Pets" }} title="Add Pet">
      <div className="max-w-3xl mx-auto">
        <PetForm
          isLoading={isLoading}
          error={error}
          onSubmit={onSubmit}
          returnTo="../pets"
        />
      </div>
    </Layout>
  );
}
