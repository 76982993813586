import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { json, useLoaderData } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Button, ButtonWrapper } from "../components/Button";
import Layout from "../components/Layout";
import { BaseLivlyApiResponse } from "../types/Base";
import { BASE_API_URL } from "../utils/constants";
import { Spinner } from "../components/Spinner";
import useLivlyUser from "../context/UserProvider";

export interface Notification {
  description: string;
  displayOrder: number;
  title: string;
  id: number;
  channels: Channel[];
}

export interface Channel {
  name: "Email" | "Push";
  canToggle: boolean;
  id: number;
  enabled: boolean;
}

interface LoaderData {
  notifications: Notification[];
}

async function getNotifications() {
  const result = await axios.get<BaseLivlyApiResponse<Notification[]>>(
    `${BASE_API_URL}/livly/users/me/notifications`
  );

  return result.data.Data;
}

const notificationsQuery = (leaseId: number) => ({
  queryKey: ["notifications", leaseId],
  queryFn: async () => getNotifications(),
});

async function patchNotifications(data: Notification[]) {
  const result = await axios.patch<BaseLivlyApiResponse<Notification[]>>(
    `${BASE_API_URL}/livly/users/me/notifications`,
    data
  );

  return result.data.Data;
}

function NotificationsPage() {
  const { user } = useLivlyUser();
  const queryClient = useQueryClient();

  const { mutate, isLoading: isSaving } = useMutation(patchNotifications);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { data, isLoading } = useQuery({
    ...notificationsQuery(user.propertyUnitLeaseId),
  });

  useEffect(() => {
    if (data) {
      setNotifications(data);
    }
  }, [data]);

  const onUpdateChannel = (id: number, channelId: number, enabled: boolean) => {
    const newNotifications = notifications.map((item) => {
      if (item.id === id) {
        const channels = item.channels.map((channel) => {
          if (channel.id === channelId) {
            return { ...channel, enabled };
          }

          return channel;
        });

        return { ...item, channels };
      }

      return item;
    });

    setNotifications(newNotifications);
  };

  const onSubmit = () => {
    mutate(notifications, {
      onSuccess: () => {
        toast.success("Notifications updated!");
        queryClient.invalidateQueries(["notifications"]);
      },
      onError: () => {
        toast.error("There was an error");
      },
    });
  };

  return (
    <Layout
      title="Notifications"
      back={{ to: "../settings", label: "Settings" }}
    >
      {isLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <>
          <ul className="">
            {notifications.map((not) => (
              <li key={not.id} className="py-4">
                <p className="font-medium">{not.title}</p>
                <p className="text-sm">{not.description}</p>
                <ul className="pl-4 mt-4">
                  {not.channels.map((channel) => (
                    <li key={channel.id} className="py-1">
                      <label className="flex gap-4 text-sm">
                        <span className="w-24">{channel.name}</span>
                        <input
                          type="checkbox"
                          disabled={!channel.canToggle}
                          checked={channel.enabled}
                          onChange={(e) => {
                            onUpdateChannel(
                              not.id,
                              channel.id,
                              e.target.checked
                            );
                          }}
                        />
                      </label>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
          <ButtonWrapper>
            <Button onClick={onSubmit} disabled={isSaving}>
              {isSaving && <Spinner />}
              Save
            </Button>
          </ButtonWrapper>
        </>
      )}
    </Layout>
  );
}

export { NotificationsPage };
