import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { BaseLivlyApiResponse } from "../../types/Base";
import {
  BuildingExperienceEnum,
  BuildingExperiencePrimaryServiceType,
  User,
} from "../../types/User";
import auth from "../../utils/auth";
import { BASE_API_URL } from "../../utils/constants";
import { getOnboardingStatuses } from "@/context/ChecklistProvider";
import { Spinner } from "@/components/Spinner";
import { RETURN_TO_URL } from "@/utils/localStorage";

const getUserMe = async () => {
  const result = await axios.get<BaseLivlyApiResponse<User>>(
    `${BASE_API_URL}/livly/users/me?v=202404`
  );

  const user = result.data.Data;

  const onboardingStatuses = await getOnboardingStatuses(
    user.userId,
    user.propertyUnitLeaseId
  );

  return { user, onboardingStatuses };
};

export default function CallbackPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<boolean | string | null>(null);
  const { data } = useQuery(["user", "me"], () => getUserMe(), {
    enabled: !isLoading,
    onError: () => {
      navigate("/no-access");
    },
  });
  const returnToUrl = localStorage.getItem(RETURN_TO_URL);

  const { user, onboardingStatuses } = data || {};

  const handleAuth = useCallback(async () => {
    return await auth.handleAuthentication();
  }, []);

  useEffect(() => {
    const onAuth = async () => {
      try {
        await handleAuth();
        setIsLoading(false);
      } catch (e) {
        setError(e instanceof Error ? e.message : "Error logging in");
      }
    };

    onAuth();
  }, []);

  if (error) {
    return (
      <div>
        <Link to="/logout">Logout</Link>
      </div>
    );
  }

  if (user && onboardingStatuses) {
    if (returnToUrl) {
      localStorage.removeItem(RETURN_TO_URL);

      let navigationPathArray = [`/lease/${user.propertyUnitLeaseId}`];

      //handle special cases because urls do not match from old portal to new portal
      if (returnToUrl.includes("maintenance")) {
        const ticketId = returnToUrl.split("/").pop();
        navigationPathArray.push(`/maintenance/${user.propertyId}/${ticketId}`);
      } else {
        navigationPathArray.push(returnToUrl);
      }

      const navigationPath = navigationPathArray.join("");
      return <Navigate to={navigationPath} />;
    }

    let path = `/lease/${user.propertyUnitLeaseId}/checklist/${user.userId}`;

    if (
      user.presentation?.presentationType === BuildingExperienceEnum.Standalone
    ) {
      switch (user.presentation.primaryServiceTypeId) {
        case BuildingExperiencePrimaryServiceType.Amenities:
          path = `/lease/${user.propertyUnitLeaseId}/amenities`;
          break;
        case BuildingExperiencePrimaryServiceType.LivlyEvents:
          path = `/lease/${user.propertyUnitLeaseId}/events`;
          break;
        case BuildingExperiencePrimaryServiceType.BuildingOffers:
          path = `/lease/${user.propertyUnitLeaseId}/marketplace`;
          break;
        default:
          path = `/lease/${user.propertyUnitLeaseId}/my-lease`;
      }
    } else {
      const isOnboardingComplete = onboardingStatuses.every(
        (obs) => obs.enabled && (obs.isCompleted || obs.isSkipped)
      );

      if (user.status === "Current" || isOnboardingComplete) {
        path = `/lease/${user.propertyUnitLeaseId}/home`;
      }

      if (user.status === "Past") {
        path = `/lease/${user.propertyUnitLeaseId}/home`;
      }
    }

    return <Navigate to={path} />;
  }

  return (
    <div className="h-screen flex justify-center items-center">
      <Spinner color="livly" size="xl" />
    </div>
  );
}
