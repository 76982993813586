import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect } from "react";
import { json, LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import Layout from "../../components/Layout";
import { RentHistoryList } from "../../components/Rent/RentHistoryList";
import { Spinner } from "../../components/Spinner";
import { BaseLivlyApiResponse } from "../../types/Base";
import { RentHistoryItem } from "../../types/Rent";
import { trackViewPaymentHistory } from "../../utils/analytics";
import { BASE_API_URL } from "../../utils/constants";
import useLivlyUser from "../../context/UserProvider";
import useGetRentHistory from "@/hooks/useGetRentHistory";

function RentHistoryPage() {
  const { user } = useLivlyUser();

  const { data = [], isLoading } = useGetRentHistory(
    user.propertyUnitLeaseId.toString()
  );

  useEffect(() => {
    trackViewPaymentHistory();
  }, []);

  return (
    <Layout title="Rent History" back={{ label: "Rent", to: -1 }}>
      {isLoading ? (
        <div className="flex items-center justify-center flex-1 mt-8">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <RentHistoryList rentHistoryItems={data} />
      )}
    </Layout>
  );
}

export { RentHistoryPage };
