import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Button } from "../../components/Button";
import { ConfirmationModal } from "../../components/Dialog";
import Layout from "../../components/Layout";
import { BASE_API_URL } from "../../utils/constants";
import { BaseLivlyApiResponse } from "../../types/Base";
import useLivlyUser from "../../context/UserProvider";
import { VehicleResponse } from "../checklist/vehicles";
import { Spinner } from "@/components/Spinner";

function useDeleteVehicle(leaseId: string, userId: number) {
  const deleteVehicle = async (id: number) => {
    const result = await axios.delete(
      `${BASE_API_URL}/livly/lease/${leaseId}/users/${userId}/vehicles/${id}`
    );

    return result.data.Data;
  };

  return useMutation(deleteVehicle);
}

function useGetLeaseVehicles(leaseId: string) {
  const getPets = async () => {
    const result = await axios.get<BaseLivlyApiResponse<VehicleResponse[]>>(
      `${BASE_API_URL}/livly/lease/${leaseId}/vehicles`
    );

    return result.data.Data;
  };

  return useQuery(["lease-vehicles", leaseId], getPets);
}

export default function VehiclesPage() {
  const params = useParams<{ leaseId: string }>();
  const { user } = useLivlyUser();
  const { data: vehicles = [], isLoading } = useGetLeaseVehicles(
    params.leaseId!
  );

  return (
    <Layout back={{ to: "../my-lease", label: "My Lease" }} title="Vehicles">
      {isLoading ? (
        <div className="flex items-center justify-center mt-56">
          <Spinner size="xl" color="livly" />
        </div>
      ) : (
        <VehiclesList
          vehicles={vehicles}
          leaseId={params.leaseId!}
          userId={user.userId}
        />
      )}
    </Layout>
  );
}

function VehiclesList({
  vehicles,
  leaseId,
  userId,
}: {
  vehicles: VehicleResponse[];
  leaseId: string;
  userId: number;
}) {
  return (
    <>
      {vehicles?.length === 0 ? (
        <div className="text-center">
          <p className="text-xl">No vehicles added yet</p>
          <p className="mt-2 text-sm font-light">
            Any vehicles added to the lease will appear here
          </p>
          <Link to="add">
            <Button size="small" color="primary" className="mt-4">
              Add a vehicle
            </Button>
          </Link>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between mt-4">
            <h4 className="text-xl font-semibold">My Vehicles</h4>
            <Link to="add">
              <Button size="small">Add Vehicle</Button>
            </Link>
          </div>
          <ul className="mt-4 divide-y divide-gray-200">
            {vehicles?.map((vehicle) => (
              <VehicleItem
                key={vehicle.id}
                vehicle={vehicle}
                leaseId={leaseId!}
                userId={userId}
              />
            ))}
          </ul>
        </>
      )}
    </>
  );
}

function VehicleItem({
  vehicle,
  leaseId,
  userId,
}: {
  vehicle: VehicleResponse;
  leaseId: string;
  userId: number;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const client = useQueryClient();
  const { mutate, isLoading, isSuccess } = useDeleteVehicle(leaseId, userId);

  const onDelete = () => {
    mutate(vehicle.id, {
      onSuccess: () => {
        client.invalidateQueries(["lease-vehicles"]);
      },
    });
  };

  if (isSuccess) {
    return null;
  }

  return (
    <li key={vehicle.id} className="flex py-4">
      <div className="flex-1">
        <p className="text-[17px] font-medium">
          {vehicle.year} {vehicle.make} {vehicle.model}
        </p>
      </div>
      <div className="flex items-center gap-2">
        <Link
          to={vehicle.id.toString()}
          className="text-sm underline text-[#262b2b]"
        >
          Edit
        </Link>
        <button
          className="text-sm underline text-[#262b2b]"
          onClick={() => setIsOpen(true)}
        >
          Remove
        </button>
      </div>
      <ConfirmationModal
        variant="warning"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="Remove Vehicle"
        body="Are you sure you want to remove this vehicle?"
        buttonLabels={{ confirm: isLoading ? "Removing" : "Remove" }}
        onConfirm={onDelete}
        isLoading={isLoading}
      />
    </li>
  );
}

export { VehiclesList };
