import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox, Transition } from "@headlessui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import classNames from "classnames";
import moment from "moment-timezone";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ButtonWrapper } from "../components/Button";
import { ConfirmationModal } from "../components/Dialog";
import { Select, textAreaClass } from "../components/Form";
import Layout, { LayoutBody, LayoutHeader } from "../components/Layout";
import { Spinner } from "../components/Spinner";
import { BaseLivlyApiResponse } from "../types/Base";
import { RenewalSectionType, Renewer, RenewerSection } from "../types/User";
import {
  trackStartRenewalFlow,
  trackSubmitRenewalFlow,
} from "../utils/analytics";
import { BASE_API_URL } from "../utils/constants";
import toLocalTime from "../utils/toLocalTime";
import useLivlyUser from "../context/UserProvider";

const options = [
  { text: "Select an option", value: "0" },
  { text: "Yes, I plan on renewing", value: "Yes" },
  { text: "No, I don't plan on renewing", value: "No" },
  { text: "I want to request a transfer", value: "Transfer" },
  { text: "I am still deciding", value: "Deciding" },
];

type RenewalRequestModel = {
  renewalId: number;
  type: RenewalSectionType | "0";
  notes: string;
  reasons: number[];
  snoozedDays: number;
  propertyId: number;
  leaseId: number;
  userId: number;
};

const comps: Record<string, any> = {
  Yes: YesNoSection,
  No: YesNoSection,
  Transfer: TransferSection,
  Deciding: PendingSection,
};

const getRenewer = async (leaseId: number) => {
  const result = await axios.get<BaseLivlyApiResponse<Renewer>>(
    `${BASE_API_URL}/livly/renewal/lease/${leaseId}`
  );

  return result.data.Data;
};

const upsertRenewer = async (data: RenewalRequestModel) => {
  const result = await axios.post<BaseLivlyApiResponse<any>>(
    `${BASE_API_URL}/livly/renewal/property/${data.propertyId}/lease/${data.leaseId}/user/${data.userId}`,
    data
  );

  return result.data;
};

function LeaseRenewalContainer() {
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const queryClient = useQueryClient();
  const { user } = useLivlyUser();
  const { data, isLoading } = useQuery(
    ["user-renewal", user.propertyUnitLeaseId],
    () => getRenewer(user.propertyUnitLeaseId)
  );
  const {
    mutate,
    isError,
    isLoading: isSaving,
  } = useMutation(upsertRenewer, {
    onSuccess: () => {
      setShowSuccessModal(true);
    },
  });

  const onSubmit = (request: RenewalRequestModel) => {
    mutate(request);

    const { sections } = data!;

    const selectedSection = sections.find(
      (section) => section.type === request.type
    );

    if (!selectedSection) {
      return;
    }

    let eventProperties: Record<string, string | number> = {};

    switch (request.type) {
      case "Yes":
      case "No":
        eventProperties = {
          "Note Added":
            request.notes && request.notes.trim().length > 0 ? "True" : "False",
          [request.type === "Yes"
            ? "Reasons for Renewal"
            : "Reasons for Not Renewing"]: selectedSection.reasons
            .filter((reason) => request.reasons.includes(reason.key))
            .map((reason) => reason.name)
            .join(", "),
        };
        break;
      case "Transfer":
        eventProperties = {
          "Note Added":
            request.notes && request.notes.trim().length > 0 ? "True" : "False",
        };
        break;
      case "Deciding":
        eventProperties = {
          "Note Added":
            request.notes && request.notes.trim().length > 0 ? "True" : "False",
          "Snooze Time": request.snoozedDays,
        };
        break;
    }

    trackSubmitRenewalFlow(request.type as RenewalSectionType, eventProperties);
  };

  const onConfirm = () => {
    queryClient.invalidateQueries(["user-renewal", user.propertyUnitLeaseId]);
    queryClient.invalidateQueries(["user-tasks", user.propertyUnitLeaseId]);

    setShowSuccessModal(false);
    navigate(`/lease/${user.propertyUnitLeaseId}/home`);
  };

  if (isLoading || data === undefined) {
    return null;
  }

  return (
    <>
      <LeaseRenewal isSaving={isSaving} renewer={data} onSubmit={onSubmit} />
      <ConfirmationModal
        variant="success"
        title="Thank you"
        body="We've passed this information along to your property manager."
        open={showSuccessModal}
        buttonLabels={{ cancel: null, confirm: "Done" }}
        onConfirm={onConfirm}
        onClose={() => setShowSuccessModal(false)}
      />
    </>
  );
}

function LeaseRenewal({
  renewer,
  isSaving,
  onSubmit,
}: {
  renewer: Renewer;
  isSaving: boolean;
  onSubmit: (data: RenewalRequestModel) => void;
}) {
  const { user } = useLivlyUser();
  const [requestModel, setRequestModel] = useState<RenewalRequestModel>({
    renewalId: renewer.renewalId,
    type: "0",
    notes: "",
    reasons: [],
    snoozedDays: 5,
    propertyId: user.propertyId,
    leaseId: user.propertyUnitLeaseId,
    userId: user.userId,
  });

  const { reminderSettingDays } = renewer;

  const surveyDate = moment(user.leaseEndDate)
    .add(-reminderSettingDays, "days")
    .format("MMMM D, YYYY");

  const selectedSection = renewer.sections.find(
    (s) => s.type === requestModel.type
  );

  let Component = comps[requestModel.type];

  let isSubmitDisabled = requestModel.type === "0";

  if (["Yes", "No"].includes(requestModel.type)) {
    isSubmitDisabled = requestModel.reasons.length === 0;
  } else if (requestModel.type === "Transfer") {
    isSubmitDisabled = requestModel.notes.trim().length === 0;
  }

  return (
    <div title="Renewer" className="min-h-screen">
      <LayoutHeader title="Renewer" />
      <LayoutBody>
        <p className="text-2xl font-light ">
          Your lease is up for renewal begining{" "}
          <span className="font-bold">{surveyDate}</span>.
        </p>
        <p className="mt-2 font-medium">Do you plan to renew your lease?</p>
        <p className="mt-2 text-sm">
          Feedback will be sent directly to your property manager. Answer is not
          final or binding.
        </p>
        <Listbox
          value={requestModel.type}
          onChange={(type) => {
            setRequestModel({
              ...requestModel,
              type,
              reasons: [],
              notes: "",
              snoozedDays: 5,
            });

            trackStartRenewalFlow(type as RenewalSectionType);
          }}
        >
          {({ open }) => (
            <>
              <div className="relative mt-4">
                <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default md:w-60 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                  <span className="block truncate">
                    {options.find((o) => o.value === requestModel.type)?.text}
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <FontAwesomeIcon icon="chevron-down" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg md:w-60 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {options.map((option) => (
                      <Listbox.Option
                        key={option.value}
                        className={({ active }) =>
                          classNames(
                            active ? "text-white bg-blue-600" : "text-gray-900",
                            "relative cursor-default select-none py-2 pl-3 pr-9"
                          )
                        }
                        value={option.value}
                      >
                        {({ selected, active }) => (
                          <>
                            <div className="flex items-center">
                              <span
                                className={classNames(
                                  selected ? "font-semibold" : "font-normal",
                                  "block truncate"
                                )}
                              >
                                {option.text}
                              </span>
                            </div>

                            {selected ? (
                              <span
                                className={classNames(
                                  active ? "text-white" : "text-blue-600",
                                  "absolute inset-y-0 right-0 flex items-center pr-4"
                                )}
                              >
                                <FontAwesomeIcon
                                  icon={["far", "check-circle"]}
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
        {Component && (
          <Component
            section={selectedSection}
            requestModel={requestModel}
            setRequestModel={setRequestModel}
          />
        )}
      </LayoutBody>
      <ButtonWrapper>
        <Button
          onClick={() => onSubmit(requestModel)}
          color="secondary"
          disabled={isSubmitDisabled || isSaving}
          className="flex items-center gap-2"
        >
          {isSaving && <Spinner />}
          Send to property manager
        </Button>
      </ButtonWrapper>
    </div>
  );
}

function TransferSection({
  section,
  requestModel,
  setRequestModel,
}: {
  section: RenewerSection;
  requestModel: RenewalRequestModel;
  setRequestModel: (requestModel: RenewalRequestModel) => void;
}) {
  return (
    <div>
      <p className="mt-4 font-medium">Let us know how we can assist you.</p>
      <textarea
        className={classNames(textAreaClass, "mt-2")}
        rows={5}
        placeholder="Something you want to share? New roommmate? Questions about your lease? Feeback for the property managers? Let us know."
        onChange={(e: React.ChangeEvent<{ value: string }>) => {
          setRequestModel({ ...requestModel, notes: e.target.value });
        }}
      />
      {section.nextSteps && (
        <div>
          <p className="mt-4 font-medium">Next steps</p>
          <p className="whitespace-pre-line">{section.nextSteps}</p>
        </div>
      )}
    </div>
  );
}

const snoozeDays = [5, 10, 15];
const getDay = (daysToAdd: number) =>
  moment().add(daysToAdd, "days").format("MMMM D, YYYY");

const snoozeOptions = snoozeDays.map((day) => ({
  text: `${day} days (${getDay(day)})`,
  value: day,
}));

function PendingSection({
  section,
  requestModel,
  setRequestModel,
}: {
  section: RenewerSection;
  requestModel: RenewalRequestModel;
  setRequestModel: (requestModel: RenewalRequestModel) => void;
}) {
  return (
    <div>
      <p className="mt-4 font-medium">When would you like us to remind you?</p>
      <p className="mt-1 text-sm text-gray-500">
        We will send you an email reminder based off your selection.
      </p>
      <Listbox
        value={requestModel.snoozedDays}
        onChange={(snoozedDays) =>
          setRequestModel({ ...requestModel, snoozedDays })
        }
      >
        {({ open }) => (
          <>
            <div className="relative mt-4">
              <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default md:w-60 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                <span className="block truncate">
                  {
                    snoozeOptions.find(
                      (o) => o.value === Number(requestModel.snoozedDays)
                    )?.text
                  }
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <FontAwesomeIcon icon="chevron-down" />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg md:w-60 max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {snoozeOptions.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-blue-600" : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9"
                        )
                      }
                      value={option.value}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "block truncate"
                              )}
                            >
                              {option.text}
                            </span>
                          </div>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-blue-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <FontAwesomeIcon icon={["far", "check-circle"]} />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {section.nextSteps && (
        <div>
          <p className="mt-4 font-medium">Next steps</p>
          <p className="whitespace-pre-line">{section.nextSteps}</p>
        </div>
      )}
    </div>
  );
}

function YesNoSection({
  section,
  requestModel,
  setRequestModel,
}: {
  section: RenewerSection;
  requestModel: RenewalRequestModel;
  setRequestModel: (requestModel: RenewalRequestModel) => void;
}) {
  const onChange = (key: number) => {
    if (requestModel.reasons.includes(key)) {
      setRequestModel({
        ...requestModel,
        reasons: requestModel.reasons.filter((r) => r !== key),
      });
    } else {
      setRequestModel({
        ...requestModel,
        reasons: [...requestModel.reasons, key],
      });
    }
  };

  return (
    <div>
      <p className="mt-4 font-bold">
        {section.type === "Yes"
          ? "What is your reason for renewing your lease?"
          : "What is your reason for not renewing your lease?"}
      </p>
      <ul className="mt-2 space-y-2">
        {section.reasons.map((reason) => (
          <li key={reason.key}>
            <label className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={requestModel.reasons.includes(reason.key)}
                onChange={() => onChange(reason.key)}
              />
              <span>{reason.name}</span>
            </label>
          </li>
        ))}
      </ul>
      <p className="mt-4 font-medium">Tell us more</p>
      <textarea
        className={classNames(textAreaClass, "mt-2")}
        rows={5}
        placeholder="Something you want to share? New roommmate? Questions about your lease? Feeback for the property managers? Let us know."
        onChange={(e: React.ChangeEvent<{ value: string }>) => {
          setRequestModel({ ...requestModel, notes: e.target.value });
        }}
      />
      {section.nextSteps && (
        <div>
          <p className="mt-4 font-medium">Next steps</p>
          <p className="whitespace-pre-line">{section.nextSteps}</p>
        </div>
      )}
    </div>
  );
}

export { LeaseRenewalContainer as LeaseRenewalPage };
