import React, { ReactNode, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { usePopper } from "react-popper";
import { useOnClickOutside } from "./useOnClickOutside";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EmojiPicker = ({
  onSelect,
  disabled = false,
  children,
  className
}: Props) => {
  const containerRef = useRef(null);
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [open, setOpen] = useState(false);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: "arrow" }]
  });
  const { t } = useTranslation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useOnClickOutside(containerRef, handleClose);

  return (
    <div className="relative">
      <button
        ref={setReferenceElement}
        onClick={handleClick}
        className={
          className ||
          `flex items-center justify-center h-8 px-4 rounded-lg text-sm font-semibold border-none
         ${
           disabled
             ? "cursor-not-allowed bg-gray-400"
             : " bg-blue-100 text-blue-600  hover:bg-blue-200"
         }`
        }
        disabled={disabled}
      >
        {children ? (
          children
        ) : (
          <>
            <FontAwesomeIcon
              icon={["fad", "smile"]}
              className="mr-2 text-blue-600"
            />
            Emoji
          </>
        )}
      </button>
      {open && (
        <div
          ref={setPopperElement}
          style={{ ...styles.popper, zIndex: 10000 }}
          {...attributes.popper}
        >
          <div ref={containerRef}>
            <Picker data={data} onEmojiSelect={onSelect} />
          </div>
        </div>
      )}
    </div>
  );
};

interface Props {
  onSelect: (emoji: any) => void;
  onOpen?: () => void;
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
}

export default EmojiPicker;
