import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, useState } from "react";

import livlyLogo from "../assets/livly_logo-text.svg";
import flatsLogo from "../assets/flats_logo.svg";
import commonLogo from "../assets/common-logo-black.svg";
import { Link } from "react-router-dom";
import {
  trackChangeEmailAddress,
  trackChangePassword,
  trackShowHidePassword,
} from "../utils/analytics";
import { Spinner } from "./Spinner";

export type Brands = "Flats" | "Common" | "Livly";

export default function LoginForm({
  handleSubmit,
  isLoading,
  error,
  from = "/login",
}: {
  from?: string;
  error?: string;
  handleSubmit: (form: FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
}) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div>
      <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
        Ready to move in?
      </h2>
      <h3 className="mt-2">
        <Link
          to={`/${from}`}
          className="flex items-center font-medium text-gray-600 hover:text-gray-500"
        >
          enter with mobile number
          <FontAwesomeIcon icon={["fad", "mobile"]} className="ml-2" />
        </Link>
      </h3>
      <div className="mt-6">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="email"
              className={`block text-sm font-medium ${
                error ? "text-red-500" : "text-gray-700"
              }`}
            >
              Email address
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className={`block w-full appearance-none rounded-md border ${
                  error ? "border-red-300" : "border-gray-300"
                } px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                onBlur={trackChangeEmailAddress}
              />
            </div>
          </div>

          <div className="space-y-1">
            <label
              htmlFor="password"
              className={`block text-sm font-medium ${
                error ? "text-red-500" : "text-gray-700"
              }`}
            >
              Password
            </label>
            <div className="relative flex items-center mt-1">
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                required
                className={`block w-full appearance-none rounded-md border ${
                  error ? "border-red-300" : "border-gray-300"
                } pl-3 pr-12 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
                onBlur={trackChangePassword}
              />
              <div
                className="absolute inset-y-0 right-1 flex items-center py-1.5 pr-1.5 cursor-pointer"
                onClick={() => {
                  setShowPassword(!showPassword);
                  trackShowHidePassword(!showPassword);
                }}
              >
                <FontAwesomeIcon
                  icon={showPassword ? "eye" : "eye-slash"}
                  className="text-gray-700"
                />
              </div>
            </div>
          </div>
          {error && (
            <span className="inline-block font-medium text-red-500">
              {error}
            </span>
          )}
          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="relative flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white bg-gray-900 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              {isLoading && (
                <span className="absolute left-4">
                  <Spinner />
                </span>
              )}
              Sign in
            </button>
          </div>
          <div className="flex justify-between mt-2">
            <Link
              to="/forgot-password"
              state={{ from }}
              className="font-medium text-gray-600 hover:text-gray-500"
            >
              forgot password?
            </Link>
            <Link
              to="/sign-up"
              state={{ from }}
              className="font-medium text-gray-600 hover:text-gray-500"
            >
              sign up
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export function LoginWrapper({
  hideBrand = false,
  brand,
  children,
}: {
  hideBrand?: boolean;
  brand?: Brands;
  children: JSX.Element;
}) {
  return (
    <div
      className="flex items-center justify-center min-h-full bg-cover"
      style={{ backgroundImage: "url(/livly-splash.jpg)" }}
    >
      <div className="w-full p-8 m-6 bg-white rounded-lg md:max-w-md md:mx-auto">
        <Brand brand={brand} />
        {children}
      </div>
    </div>
  );
}

function Brand({ brand }: { brand?: Brands }) {
  if (brand === "Flats") {
    return (
      <div>
        <img src={flatsLogo} className="mx-auto h-14" />
        <div className="flex items-center justify-center gap-2 mt-4 text-sm">
          <span>powered by</span>
          <img src={livlyLogo} className="h-6" />
        </div>
      </div>
    );
  } else if (brand === "Common") {
    return (
      <div>
        <img src={commonLogo} className="h-12 mx-auto" />
        <div className="flex items-center justify-center gap-2 mt-4 text-sm">
          <span>powered by</span>
          <img src={livlyLogo} className="h-6" />
        </div>
      </div>
    );
  } else {
    return (
      <div className="flex justify-center">
        <img src={livlyLogo} className="h-12" />
      </div>
    );
  }
}
