import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

import { BaseLivlyApiResponse } from "../types/Base";
import { RentSettings } from "../types/Rent";
import { BASE_API_URL } from "../utils/constants";

async function getRentSettings(leaseId: string) {
  const result = await axios.get<BaseLivlyApiResponse<RentSettings>>(
    `${BASE_API_URL}/livly/rentSettings/leases/${leaseId}`
  );

  return result.data.Data;
}

export default function useGetRentSettings(leaseId: string) {
  return useQuery({
    queryKey: ["rent", "settings", leaseId],
    queryFn: () => getRentSettings(leaseId),
  });
}
