import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { Button } from "../../components/Button";
import { FormField, textInputClass } from "../../components/Form";
import Layout from "../../components/Layout";
import { Spinner } from "../../components/Spinner";
import { BaseLivlyApiResponse } from "../../types/Base";
import { PaymentAccount } from "../../types/User";
import { BASE_API_URL } from "../../utils/constants";
import useLivlyUser from "../../context/UserProvider";

const InitiateAccountSchema = z.object({
  name: z.string(),
  routingNumber: z.string(),
  accountNumber: z.string(),
  bankAccountType: z.string().min(1, "Account type is required"),
});

type InitiateAccountRequestModel = z.infer<typeof InitiateAccountSchema>;

async function postAccountDetails({
  data,
  userId,
}: {
  data: InitiateAccountRequestModel;
  userId: number;
}) {
  const result = await axios.post<BaseLivlyApiResponse<PaymentAccount>>(
    `${BASE_API_URL}/livly/payment/AddACHAccountByAccountDetails/${userId}`,
    data
  );

  return result.data.Data;
}

export default function InitiateAccountPage() {
  const { user } = useLivlyUser();
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation(
    (data: InitiateAccountRequestModel) =>
      postAccountDetails({ data, userId: user.userId })
  );

  const handleSubmit = (data: InitiateAccountRequestModel) => {
    setError("");

    mutate(data, {
      onSuccess: () => {
        navigate(`../wallet`);
      },
      onError: (e) => {
        const error = e as { data?: { Message: string } };
        setError(error.data?.Message ?? "There was an error verifying account");
      },
    });
  };

  const initialValues: InitiateAccountRequestModel = {
    name: "",
    routingNumber: "",
    accountNumber: "",
    bankAccountType: "",
  };

  return (
    <Layout
      title="Initiate Account"
      back={{ label: "Wallet", to: `../wallet` }}
    >
      <div className="max-w-lg mx-auto">
        <p>
          after submission, 2 micro deposits will be sent to your account. once
          you receive them, log back in to verify.
        </p>

        <div className="flex flex-col max-w-xs gap-2 mx-auto mt-6 text-center">
          <Formik
            initialValues={initialValues}
            validationSchema={toFormikValidationSchema(InitiateAccountSchema)}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                {error && (
                  <div className="p-4 my-2 bg-red-100 border-l-4 border-red-500 rounded-md">
                    {error}
                  </div>
                )}

                <FormField
                  htmlFor="name"
                  label="Account Nickname"
                  showErrorIcon={errors.name && touched.name}
                >
                  <Field
                    id="name"
                    name="name"
                    type="text"
                    className={textInputClass}
                  />
                </FormField>

                <FormField
                  htmlFor="routingNumber"
                  label="Routing Number"
                  showErrorIcon={errors.routingNumber && touched.routingNumber}
                >
                  <Field
                    id="routingNumber"
                    name="routingNumber"
                    type="text"
                    className={textInputClass}
                  />
                </FormField>

                <FormField
                  htmlFor="accountNumber"
                  label="Account Number"
                  showErrorIcon={errors.accountNumber && touched.accountNumber}
                >
                  <Field
                    id="accountNumber"
                    name="accountNumber"
                    type="text"
                    className={textInputClass}
                  />
                </FormField>

                <FormField
                  htmlFor="bankAccountType"
                  label="Account Type"
                  showErrorIcon={
                    errors.bankAccountType && touched.bankAccountType
                  }
                >
                  <Field
                    as="select"
                    id="bankAccountType"
                    name="bankAccountType"
                    className="block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                  >
                    <option disabled value="">
                      Select
                    </option>
                    <option value="savings">Savings</option>
                    <option value="checking">Checking</option>
                  </Field>
                </FormField>
                <div className="flex justify-end mt-4">
                  <Button
                    size="small"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading && <Spinner />}
                    Start Micro Deposits
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Layout>
  );
}
