import { useEffect, useMemo, useState } from "react";
import { Outlet, useNavigate, useNavigation } from "react-router-dom";
import * as jose from "jose";
import Alert from "@/components/Alert";

export const FEEDBACK_TYPE = "livly-token/claims/FeedbackType";
export const FEEDBACK_ENTITY_ID_KEY = "livly-token/claims/FeedbackEntityId";

export const USER_DATA_KEY =
  "http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata";
export const PROPERTY_DATA_KEY = "livly-token/claims/PropertyData";
export const ROLE_KEY = "role";

export type FeedbackType =
  | "MaintenanceTicket"
  | "Property"
  | "AmenityBooking"
  | "Event";

export interface TokenBase {
  exp: number;
  iat: number;
  [ROLE_KEY]: string;
  [USER_DATA_KEY]: string;
  [PROPERTY_DATA_KEY]: string;
}

export interface FeedbackToken extends TokenBase {
  [FEEDBACK_TYPE]: FeedbackType;
  [FEEDBACK_ENTITY_ID_KEY]: string;
}

export interface TokenUser {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  leaseLivlyUserId: number;
}

export interface TokenProperty {
  propertyId: number;
  propertyName: string;
  logoUrl: string;
}

export interface FeedbackExternalContext {
  token: string;
  rating: number | null;
  feedbackId: number;
  feedbackType: FeedbackType | null;
  property: TokenProperty | null;
}

function FeedbackExternalContext() {
  const {
    token = "",
    feedbackId = 0,
    feedbackType = null,
    property = null,
    rating = null,
  } = useGetFeedbackDetails();

  const value: FeedbackExternalContext = useMemo(
    () => ({ token, feedbackId, feedbackType, property, rating }),
    [token, feedbackId, feedbackType, property, rating]
  );

  if (!token || !feedbackId || !feedbackType || !property) {
    return (
      <div className="p-4">
        <Alert message="We cannot process your request." />
      </div>
    );
  }

  return <Outlet context={value} />;
}

export default FeedbackExternalContext;

function useGetFeedbackDetails() {
  try {
    const params = new URLSearchParams(window.location.search);

    const token = params.get("ltoken") as string;
    const ratingString = params.get("rating") as string;
    const rating = Number(ratingString);
    const decoded = jose.decodeJwt(token) as FeedbackToken;
    const feedbackId = Number(decoded[FEEDBACK_ENTITY_ID_KEY]);
    const feedbackType = decoded[FEEDBACK_TYPE];
    const property = JSON.parse(decoded[PROPERTY_DATA_KEY]) as TokenProperty;

    return { token, feedbackId, feedbackType, property, rating };
  } catch (e: any) {
    return { error: e.message };
  }
}
