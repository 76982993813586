import FeedPostListItem from "./community-feed/feed-post-list-item";
import { FeedPost, ReactionType, TagType } from "@/utils/commfeed";
import useLivlyUser from "@/context/UserProvider";
import {
  useInfiniteQuery,
  useMutation,
  useQueryClient
} from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useMemo, useState } from "react";
import { ImageUpload } from "react-file-utils";
import {
  addFeedPostComment,
  deleteCommFeedById,
  deleteFeedPostComment,
  editFeedPostAndImages,
  editFeedPostComment,
  getAllCommFeeds,
  hideCommFeed,
  putFeedPostReaction,
  reportCommFeed
} from "./community-feed/hook/usePost";

function usePosts(
  propertyId: number,
  leaseId: number,
  limit: number,
  tag: TagType | undefined
) {
  const allPostsQuery = (
    propertyId: number,
    leaseId: number,
    limit: number = 20,
    tag: TagType | undefined
  ) => ({
    queryKey: ["community-feed", propertyId, leaseId, tag],
    queryFn: async ({ pageParam = 0 }) =>
      getAllCommFeeds(leaseId, tag, limit, pageParam),
    getNextPageParam: (lastPage: any) => {
      return lastPage.nextPage;
    }
  });

  return useInfiniteQuery({
    ...allPostsQuery(propertyId, leaseId, limit, tag),
    //@ts-ignore
    initialPageParam: 0
  });
}

const AllCommunityFeed = ({
  selectedTag
}: {
  selectedTag: TagType | undefined;
}) => {
  const { user } = useLivlyUser();
  const queryClient = useQueryClient();
  const {
    data,
    isLoading,
    fetchNextPage,
    isError,
    hasNextPage,
    isFetchingNextPage
  } = usePosts(user.propertyId, user.propertyUnitLeaseId, 20, selectedTag);

  const [localPosts, setLocalPosts] = useState<FeedPost[]>([]);

  const deletePostMutation = useMutation({
    mutationFn: ({ leaseId, postId }: { leaseId: number; postId: number }) =>
      deleteCommFeedById(leaseId, postId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "community-feed",
          user.propertyId,
          user.propertyUnitLeaseId,
          undefined
        ]
      });
      toast.success("Post has been deleted successfully");
    },
    onError: (e) => {
      const error = e as { data?: { Message?: string } };
      toast.error(error?.data?.Message ?? "An error occurred");
    }
  });

  const hidePostMutation = useMutation({
    mutationFn: ({ leaseId, postId }: { leaseId: number; postId: number }) =>
      hideCommFeed(leaseId, postId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "community-feed",
          user.propertyId,
          user.propertyUnitLeaseId,
          undefined
        ]
      });
      toast.success("Post has been hidden successfully");
    },
    onError: (e) => {
      const error = e as { data?: { Message?: string } };
      toast.error(error?.data?.Message ?? "An error occurred");
    }
  });

  const reportPostMutation = useMutation({
    mutationFn: ({ leaseId, postId }: { leaseId: number; postId: number }) =>
      reportCommFeed(leaseId, postId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "community-feed",
          user.propertyId,
          user.propertyUnitLeaseId,
          undefined
        ]
      });
      toast.success("Post has been hidden successfully");
    },
    onError: (e) => {
      const error = e as { data?: { Message?: string } };
      toast.error(error?.data?.Message ?? "An error occurred");
    }
  });

  const editPostMutation = useMutation({
    mutationFn: ({
      post,
      images,
      text,
      tag
    }: {
      post: FeedPost;
      images: ImageUpload[];
      text: string;
      tag: TagType;
    }) =>
      editFeedPostAndImages(user.propertyUnitLeaseId, post, images, text, tag),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "community-feed",
          user.propertyId,
          user.propertyUnitLeaseId,
          undefined
        ]
      });
    },
    onError: (e) => {
      const error = e as { data?: { Message?: string } };
      toast.error(error?.data?.Message ?? "An error occurred");
    }
  });

  const addFeedPostReactionMutation = useMutation({
    mutationFn: ({
      leaseId,
      postId,
      type
    }: {
      leaseId: string;
      postId: string;
      type: ReactionType | null;
    }) => putFeedPostReaction(leaseId, postId, type),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "community-feed",
          user.propertyId,
          user.propertyUnitLeaseId,
          undefined
        ]
      });
    },
    onError: (e) => {
      const error = e as { data?: { Message?: string } };
      toast.error(error?.data?.Message ?? "An error occurred");
    }
  });

  const addCommentMutation = useMutation({
    mutationFn: ({
      leaseId,
      postId,
      comment
    }: {
      leaseId: string;
      postId: string;
      comment: string;
    }) => addFeedPostComment(leaseId, postId, comment),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "community-feed",
          user.propertyId,
          user.propertyUnitLeaseId,
          undefined
        ]
      });
    },
    onError: (e) => {
      const error = e as { data?: { Message?: string } };
      toast.error(error?.data?.Message ?? "An error occurred");
    }
  });

  const deletePostCommentMutation = useMutation({
    mutationFn: ({
      leaseId,
      postId,
      commentId
    }: {
      leaseId: string;
      postId: string;
      commentId: string;
    }) => deleteFeedPostComment(leaseId, postId, commentId),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "community-feed",
          user.propertyId,
          user.propertyUnitLeaseId,
          undefined
        ]
      });
    },
    onError: (e) => {
      const error = e as { data?: { Message?: string } };
      toast.error(error?.data?.Message ?? "An error occurred");
    }
  });

  const editCommentMutation = useMutation({
    mutationFn: ({
      leaseId,
      postId,
      commentId,
      comment
    }: {
      leaseId: string;
      postId: string;
      commentId: string;
      comment: string;
    }) => editFeedPostComment(leaseId, postId, commentId, comment),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          "community-feed",
          user.propertyId,
          user.propertyUnitLeaseId,
          undefined
        ]
      });
    },
    onError: (e) => {
      const error = e as { data?: { Message?: string } };
      toast.error(error?.data?.Message ?? "An error occurred");
    }
  });

  const allPosts = useMemo(() => {
    const posts =
      data?.pages.reduce((prev, curr) => {
        const posts = curr.data;
        return [...prev, ...posts];
      }, [] as FeedPost[]) ?? [];

    // Update localPosts whenever allPosts change
    setLocalPosts(posts);

    return posts;
  }, [data]);

  const onDeleteFeedPost = async (leaseId: string, postId: string) => {
    const updatedPosts = localPosts.filter((post) => post.id !== postId);
    await deletePostMutation.mutateAsync(
      {
        leaseId: Number(leaseId),
        postId: Number(postId)
      },
      {
        onSuccess: () => {
          setLocalPosts(updatedPosts);
        },
        onError: () => {
          setLocalPosts(allPosts);
        }
      }
    );
  };

  const onHideFeedPost = async (leaseId: string, postId: string) => {
    const updatedPosts = localPosts.filter((post) => post.id !== postId);
    await hidePostMutation.mutateAsync(
      {
        leaseId: Number(leaseId),
        postId: Number(postId)
      },
      {
        onSuccess: () => {
          setLocalPosts(updatedPosts);
        },
        onError: () => {
          setLocalPosts(allPosts);
        }
      }
    );
  };

  const onReportFeedPost = async (leaseId: string, postId: string) => {
    const updatedPosts = localPosts.filter((post) => post.id !== postId);
    await reportPostMutation.mutateAsync(
      {
        leaseId: Number(leaseId),
        postId: Number(postId)
      },
      {
        onSuccess: () => {
          setLocalPosts(updatedPosts);
        },
        onError: () => {
          setLocalPosts(allPosts);
        }
      }
    );
  };

  const onEditFeedPost = async (
    post: FeedPost,
    images: ImageUpload[],
    text: string,
    tag: TagType
  ) => {
    return await editPostMutation.mutateAsync({ post, images, text, tag });
  };

  const onUpdatePostReaction = async (
    leaseId: string,
    postId: string,
    reactionType: ReactionType | null
  ) => {
    return await addFeedPostReactionMutation.mutateAsync({
      leaseId,
      postId,
      type: reactionType
    });
  };

  const onAddPostComment = async (
    leaseId: string,
    postId: string,
    comment: string
  ) => {
    return await addCommentMutation.mutateAsync({
      leaseId,
      postId,
      comment
    });
  };

  const onDeleteFeedPostComment = async (
    leaseId: string,
    postId: string,
    commentId: string
  ) => {
    return await deletePostCommentMutation.mutateAsync({
      leaseId,
      postId,
      commentId
    });
  };

  const onEditPostComment = async (
    leaseId: string,
    postId: string,
    commentId: string,
    comment: string
  ) => {
    return await editCommentMutation.mutateAsync({
      leaseId,
      postId,
      commentId,
      comment
    });
  };

  return (
    <>
      {isError ? (
        <div className="p-6">
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <span className="block sm:inline">
              Sorry, there was an error loading your post.
            </span>
          </div>
        </div>
      ) : isLoading ? (
        <div className="flex justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        </div>
      ) : localPosts.length > 0 ? (
        <div>
          {localPosts?.map((post, index) => (
            <FeedPostListItem
              key={`post-${post.id}-${index}`}
              post={post}
              onDeletePost={onDeleteFeedPost}
              onDeleteComment={onDeleteFeedPostComment}
              onUpdatePostReaction={onUpdatePostReaction}
              onAddPostComment={onAddPostComment}
              onEditPostComment={onEditPostComment}
              onEditFeedPost={onEditFeedPost}
              onHideFeedPost={onHideFeedPost}
              onReportFeedPost={onReportFeedPost}
              isLoading={
                deletePostMutation.isLoading ||
                hidePostMutation.isLoading ||
                reportPostMutation.isLoading ||
                deletePostCommentMutation.isLoading ||
                editCommentMutation.isLoading
              }
            />
          ))}
        </div>
      ) : (
        <div className="flex h-full justify-center items-center p-5">
          <span className="text-gray-700 text-sm">
            There are no posts in your community
          </span>
        </div>
      )}
      <div className="flex justify-center my-2 pb-5">
        {isFetchingNextPage ? (
          <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
        ) : hasNextPage ? (
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white  py-2 px-4 rounded"
            onClick={() => fetchNextPage()}
          >
            Load More
          </button>
        ) : null}
      </div>
    </>
  );
};

export default AllCommunityFeed;
