import { useGetMarketplaceOffers } from "@/context/MarketplaceLayout";
import useLivlyUser from "@/context/UserProvider";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import CountDownTimer from "./CountDownTimer";
import { Link } from "react-router-dom";
import { Spinner } from "./Spinner";
import moment from "moment-timezone";
import { VendorItem } from "@/routes/checklist/building-offers";

export default function BuildingOffers({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          {/* Full-screen scrollable container */}
          <div className="fixed inset-0 w-screen">
            {/* Container to center the panel */}
            <div className="flex items-center justify-center min-h-full">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="transform opacity-0 scale-95 translate-y-0"
                enterTo=" transform opacity-100 scale-100 translate-y-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100 transform translate-y-100"
                leaveTo="opacity-0 scale-95 translate-y-0"
              >
                <Dialog.Panel className="w-full overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl">
                  <OffersDetails />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

function OffersDetails() {
  const { user } = useLivlyUser();
  const { data = [], isLoading } = useGetMarketplaceOffers(
    user.propertyUnitLeaseId
  );

  const {
    building: { logoUrl, name },
  } = user;
  const availableVendors = data?.filter(
    (vendor) => vendor.vendorOffers.length > 0
  );

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 p-6 overflow-y-auto">
        <div>
          {logoUrl && (
            <div className="flex justify-center">
              <img src={logoUrl} alt={name} className="h-12" />
            </div>
          )}
          <p className="mt-6">Welcome to {name}</p>
          <p className="mt-1 text-2xl font-noe-display-black">
            {user.firstName}
          </p>
          <p className="mt-4">
            Below are some special offers to help you prep for your move and new
            apartment.
          </p>
        </div>
        {isLoading ? (
          <div className="flex items-center justify-center my-12">
            <Spinner size="xl" color="livly" />
          </div>
        ) : (
          <div className="mt-8">
            {(availableVendors ?? []).length > 0 ? (
              <ul>
                {availableVendors?.map((vendor) => (
                  <VendorItem key={vendor._id} vendor={vendor} />
                ))}
              </ul>
            ) : (
              <div className="flex items-center justify-center flex-1 my-56">
                There are no building offers available
              </div>
            )}
          </div>
        )}
      </div>
      <div className="bg-white border shadow-lg rounded-tl-xl rounded-tr-xl">
        <div className="py-3 text-xs text-center border-b border-gray-200 md:text-lg">
          <CountDownTimer
            date={user.leaseMoveInDate}
            timezone={user.building.timezone}
          />
        </div>
        <div className="flex justify-between px-4 mt-4">
          <p className="text-gray-500">Move-in date</p>
          <p>{moment(user.leaseMoveInDate).format("MMMM D, YYYY")}</p>
        </div>
        <div className="p-4">
          <Link to={`/lease/${user.propertyUnitLeaseId}/home`}>
            <button
              type="button"
              className="w-full px-4 py-4 text-white bg-red-400 rounded-md hover:bg-red-400/75 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
            >
              Continue
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}
