import { z } from "zod";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { createContext, useContext, useMemo, useState } from "react";
import axios from "axios";
import { BASE_API_URL } from "../../utils/constants";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BaseLivlyApiResponse } from "../../types/Base";
import { User } from "../../types/User";
import useLivlyUser from "../../context/UserProvider";

export const LoginDetailsSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  email: z.string(),
  phoneNumber: z.string(),
});

export const LoginPermissionsSchema = z.object({
  serviceTypes: z.array(
    z.object({
      serviceType: z.number(),
      enabled: z.boolean(),
      metaData: z.string(),
    })
  ),
});

export type LoginDetailsModel = z.infer<typeof LoginDetailsSchema>;
export type LoginPermissionsModel = z.infer<typeof LoginPermissionsSchema>;

type AddLoginRequestModel = LoginDetailsModel & LoginPermissionsModel;

interface AddLoginContextShape {
  details: LoginDetailsModel;
  isSubmitting: boolean;
  errorMessage: string | undefined;
  setDetails: (model: Partial<LoginDetailsModel>) => void;
  sendInvite: (model: LoginPermissionsModel) => void;
}

const AddLoginContext = createContext<AddLoginContextShape>({
  details: {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  },
  errorMessage: undefined,
  isSubmitting: false,
  setDetails: () => {},
  sendInvite: () => {},
});

type RequestModel = {
  delegatingUserId: number;
  leaseId: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  serviceTypes: {
    serviceType: number;
    enabled: boolean;
    metaData: string;
  }[];
};

const postDelegateUser = async (data: RequestModel) => {
  const result = await axios.post<BaseLivlyApiResponse<User>>(
    `${BASE_API_URL}/livly/delegatedUsers`,
    data
  );

  return result.data;
};

export default function AddFamilyLoginPage() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { user } = useLivlyUser();
  const params = useParams<{ leaseId: string }>();
  const [details, setDetails] = useState<LoginDetailsModel>({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });
  const { mutate, isLoading, error } = useMutation((data: RequestModel) =>
    postDelegateUser(data)
  );

  const onSubmit = (permissions: LoginPermissionsModel) => {
    const requestModel: RequestModel = {
      delegatingUserId: user.userId,
      leaseId: Number(params.leaseId),
      ...details,
      ...permissions,
    };

    mutate(requestModel, {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "delegate-users",
          user.userId,
          user.propertyUnitLeaseId,
        ]);
        navigate("../family-logins");
      },
    });
  };

  const mutationError = error as { data?: { Message: string } };
  const errorMessage = mutationError?.data?.Message;

  const value = useMemo(
    () => ({
      details,
      isSubmitting: isLoading,
      errorMessage,
      sendInvite: (permissions: LoginPermissionsModel) => onSubmit(permissions),
      setDetails: (model: Partial<LoginDetailsModel>) =>
        setDetails({ ...details, ...model }),
    }),
    [details, setDetails, errorMessage, isLoading]
  );

  return (
    <AddLoginContext.Provider value={value}>
      <Outlet />
    </AddLoginContext.Provider>
  );
}

/**
 * @desc A convenience hook to provide access to the Link context state in components.
 */
export function useAddLogin() {
  const context = useContext(AddLoginContext);
  if (!context) {
    throw new Error(`useAddLogin must be used within a AddFamilyLoginPage`);
  }

  return context;
}
