import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import moment from "moment-timezone";
import { useEffect } from "react";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import Avatar from "../../components/Avatar";
import { Button, ButtonWrapper } from "../../components/Button";
import Layout from "../../components/Layout";
import { CartContext } from "../../context/CartProvider";
import useGetService from "../../hooks/useGetService";
import { ServiceTypeEnum } from "../../types/Building";
import { CartItem, CartItemEnum } from "../../types/Cart";
import { RentBalanceTypeEnum, RentSettings } from "../../types/Rent";
import {
  BuildingTypeEnum,
  OnboardingStatusEnum,
  PaymentAccount,
  User,
} from "../../types/User";
import { trackViewCartSummary } from "../../utils/analytics";
import formatCurrency from "../../utils/formatCurrency";
import { useGetStatusTypeDetails, usePostService } from "./landing";
import useLivlyUser from "../../context/UserProvider";
import { isNativeAppVersion, returnToNative } from "@/utils/nativeAppHelpers";

const dateFormat = "MMM D, YYYY";

export default function ChecklistCartPage() {
  const navigate = useNavigate();
  const params = useParams<{ leaseId: string; userId: string }>();
  const { cartItems, rentSettings, paymentAccounts } =
    useOutletContext<CartContext>();
  const { user } = useLivlyUser();
  const details = useGetStatusTypeDetails(OnboardingStatusEnum.checkout, user);
  const { updateOnboardingStatus, isLoading } = usePostService(
    user.userId,
    params.leaseId!,
    details.onboardingStatus
  );

  const onSubmitEmptyCart = async () => {
    try {
      await updateOnboardingStatus({
        ...details.onboardingStatus,
        isCompleted: true,
      });

      if (isNativeAppVersion()) {
        returnToNative();
        return;
      }

      navigate("..");
    } catch (e) {}
  };

  const rentCartItem = cartItems?.find((ci) => ci.type === CartItemEnum.RENT);
  const insuranceCartItem = cartItems?.find(
    (ci) => ci.type === CartItemEnum.INSURANCE
  );

  useEffect(() => {
    trackViewCartSummary(cartItems, user.paymentAccounts);
  }, [cartItems, user.paymentAccounts]);

  return (
    <Layout title="Cart" back={{ label: "Move-in Checklist", to: `..` }}>
      {cartItems?.length === 0 ? (
        <div>
          <p>Your cart is empty.</p>
          <ButtonWrapper>
            <Button
              color="secondary"
              className="w-full md:w-auto"
              onClick={onSubmitEmptyCart}
              disabled={isLoading}
            >
              Continue
            </Button>
          </ButtonWrapper>
        </div>
      ) : (
        <div className="pb-24">
          <FontAwesomeIcon
            icon={details.icon}
            className="text-3xl text-red-300"
          />
          <h2 className="mt-2 text-lg font-medium">{details.title}</h2>
          <p className="mb-6 text-sm">
            slide down to review your move-in checklist summary.
          </p>
          <UserInfo
            user={user}
            rentSettings={rentSettings}
            rentCartItem={rentCartItem}
          />
          {insuranceCartItem && (
            <Insurance user={user} insuranceCartItem={insuranceCartItem} />
          )}
          <ScheduleMoveIn user={user} />
          <Payment user={user} paymentAccounts={paymentAccounts} />
          <CartSummary cartItems={cartItems} />
          <ButtonWrapper>
            <Link
              to={insuranceCartItem ? "checkout-insurance" : "checkout"}
              className="w-full md:w-auto"
            >
              <Button
                color="secondary"
                className="w-full md:w-auto"
                type="button"
              >
                Proceed to checkout
              </Button>
            </Link>
          </ButtonWrapper>
        </div>
      )}
    </Layout>
  );
}

function UserInfo({
  user,
  rentSettings,
  rentCartItem,
}: {
  rentCartItem: CartItem | undefined;
  user: User;
  rentSettings: RentSettings | undefined;
}) {
  const isRentEnabled = useGetService(ServiceTypeEnum.Rent)?.enabled ?? false;
  const details = useGetStatusTypeDetails(OnboardingStatusEnum.profile, user);

  return (
    <div className="relative p-5 mb-6 border border-gray-200 rounded-lg">
      <div className="flex items-center justify-between mb-4">
        <div>
          <FontAwesomeIcon
            icon={details.icon}
            className={classNames("text-3xl text-red-300")}
          />
          <p className="mt-2 text-lg font-medium">your info</p>
        </div>
        <Avatar
          src={user.avatarURI}
          name={`${user.firstName} ${user.lastName}`}
        />
      </div>
      <p className="mt-6 font-light">your name</p>
      <p className="mt-1 text-sm font-medium">
        {user.firstName} {user.lastName}
      </p>
      <p className="mt-6 font-light">
        where you
        {"'"}
        re living
      </p>
      <p className="mt-1 text-sm font-medium">
        {user.buildingName} {user.unit}
      </p>
      <p className="text-sm text-gray-500">
        {user.address}, {user.city}
      </p>

      {user.presentation.buildingType === BuildingTypeEnum.Apartments && (
        <>
          <p className="mt-6 font-light">your lease term</p>
          <div className="flex items-center gap-4 mt-1">
            <p className="text-sm font-medium">
              {moment(user.leaseStartDate).format(dateFormat)}
            </p>
            <FontAwesomeIcon icon="arrow-right" />
            <p className="text-sm font-medium">
              {moment(user.leaseEndDate).format(dateFormat)}
            </p>
          </div>
        </>
      )}

      {(user.roommates ?? []).length > 0 && (
        <>
          <p className="mt-6 font-light">your roommates</p>
          {user.roommates?.map((r) => (
            <p key={r.userId} className="mt-1 text-sm font-medium">
              {r.firstName} {r.lastName}
            </p>
          ))}
        </>
      )}

      {isRentEnabled && (
        <div className="pt-6">
          {rentSettings?.rentBalanceType ===
          RentBalanceTypeEnum.BalanceBased ? (
            <div className="pt-6 border-t border-gray-200">
              <p className="font-light">remaining balance</p>
              <p className="mt-2 text-3xl font-medium">
                {formatCurrency(rentCartItem?.cost || 0).replace(".00", "")}
              </p>
              <p className="mt-2 text-sm font-light text-gray-700">
                total based on monthly rent and other additional fees.
              </p>
            </div>
          ) : (
            <div className="mb-4">
              <p className="mt-6 font-light">rent</p>
              <p className="text-sm font-medium">
                Please reference the amount communicated to you by your
                property.
              </p>
            </div>
          )}
        </div>
      )}
      <TapToEdit to={`../profile`} />
    </div>
  );
}

function Insurance({
  insuranceCartItem,
  user,
}: {
  insuranceCartItem: CartItem;
  user: User;
}) {
  const details = useGetStatusTypeDetails(OnboardingStatusEnum.insurance, user);
  const isBestValue = insuranceCartItem.additionalData["frequency"] === "1";

  return (
    <div className="relative p-5 mb-6 border border-gray-200 rounded-lg">
      <div>
        <FontAwesomeIcon
          icon={details.icon}
          className={classNames("text-3xl text-red-300")}
        />
        <p className="mt-2 text-lg font-medium">renters insurance</p>
      </div>
      <ul className="mt-4 space-y-4">
        {Object.keys(insuranceCartItem.additionalData).map((key) => (
          <li key={key}>
            <p className="font-light">{key}</p>
            <p className="text-sm font-medium">
              {insuranceCartItem.additionalData[key]}
            </p>
          </li>
        ))}
      </ul>
      <div className="flex items-baseline pt-6 mt-6 border-t border-gray-200">
        <p className="mt-2 text-3xl font-medium">
          {formatCurrency(insuranceCartItem?.cost || 0).replace(".00", "")}
        </p>
        {!isBestValue && <p className="ml-1 text-xl">/down payment</p>}
      </div>
      <p className="mt-2 text-sm text-gray-700">
        Billing occurs on the effective date of your policy. Credit card,
        checking or savings accounts will bill on the current date, regardless
        of the effective date selected. If an initial premium payment from your
        checking account or debit card is dishonored by your financial
        institution for insufficient funds, then the policy will be deemed void.
      </p>
      <TapToEdit to={`../schedule-move-in`} />
    </div>
  );
}

type ScheduleMoveInMetaData = {
  date: string;
  time: string;
};

function ScheduleMoveIn({ user }: { user: User }) {
  const details = useGetStatusTypeDetails(
    OnboardingStatusEnum.schduleMoveIn,
    user
  );
  const parsedMetaData: ScheduleMoveInMetaData | null = details.onboardingStatus
    ?.metaData
    ? JSON.parse(details.onboardingStatus.metaData)
    : null;

  if (!parsedMetaData) {
    return null;
  }

  return (
    <div className="relative p-5 mb-6 border border-gray-200 rounded-lg">
      <FontAwesomeIcon
        icon={details.icon}
        className={classNames("text-3xl text-red-300")}
      />
      <p className="mt-2 text-lg font-medium">{details.title}</p>
      <p className="mt-6 font-light">date</p>
      <p className="mt-1 text-sm font-medium">
        {moment(parsedMetaData.date).format(dateFormat)}
      </p>
      <p className="mt-6 font-light">preferred time</p>
      <p className="mt-1 text-sm font-medium">{parsedMetaData.time}</p>

      <div className="pt-4 mt-4 border-t border-gray-200">
        <p style={{ fontSize: ".75em", color: "var(--bugs)" }}>
          An email will be sent to you and your property manager.
        </p>
      </div>
      <TapToEdit to={`../schedule-move-in`} />
    </div>
  );
}

function Payment({
  user,
  paymentAccounts,
}: {
  user: User;
  paymentAccounts: PaymentAccount[];
}) {
  const details = useGetStatusTypeDetails(OnboardingStatusEnum.payment, user);
  const isPaymentInfoVisible = details.onboardingStatus.enabled;

  if (!isPaymentInfoVisible) {
    return null;
  }

  const defaultPaymentMethod = paymentAccounts.find(
    (pa) => pa.isActive && pa.isDefault
  );

  return (
    <div className="relative p-5 mb-6 border border-gray-200 rounded-lg">
      <FontAwesomeIcon
        icon={details.icon}
        className={classNames("text-3xl text-red-300")}
      />
      <p className="mt-2 text-lg font-medium">default payment</p>

      {defaultPaymentMethod ? (
        <div className="flex flex-1 py-4 my-4 border-t border-b border-gray-200">
          <img
            src={defaultPaymentMethod.brandImageUrl}
            alt={defaultPaymentMethod.brand}
            className="max-w-14 w-auto h-auto object-cover mt-[6px]"
          />
          <div className="flex-1 ml-4">
            <p className="text-lg font-medium">{defaultPaymentMethod.brand}</p>
            <p className="text-sm font-light">
              {defaultPaymentMethod.description}
            </p>
          </div>
        </div>
      ) : (
        <div className="p-4 my-2 bg-red-100 border-l-4 border-red-500 rounded-md">
          <p>Payment method is required. Click here to add.</p>
        </div>
      )}

      <TapToEdit to={`../wallet`} />
    </div>
  );
}

function CartSummary({ cartItems }: { cartItems: CartItem[] }) {
  const subTotal = cartItems.reduce((total, cartItem) => {
    total += cartItem.cost;

    return total;
  }, 0);

  if (subTotal === 0) {
    return null;
  }

  return (
    <div className="relative p-5 mb-6 border border-gray-200 rounded-lg">
      <p className="mt-2 text-lg font-medium">total summary</p>
      <ul className="mt-4 space-y-4">
        {cartItems.map((ci, index) => (
          <li key={`${ci.itemId}-${index}`} className="flex justify-between">
            <p>{ci.type === CartItemEnum.RENT ? "rent" : "insurance"}</p>
            <p>{formatCurrency(ci.cost || 0).replace(".00", "")}</p>
          </li>
        ))}
      </ul>
      <div className="flex justify-between mt-4 font-bold">
        <p>subtotal</p>
        <p>{formatCurrency(subTotal).replace(".00", "")}</p>
      </div>
    </div>
  );
}

function TapToEdit({ to }: { to: string }) {
  return (
    <Link to={to} className="block mt-6 text-sm font-light">
      <span className="absolute inset-0"></span>
      tap to edit
    </Link>
  );
}
