import Layout from "@/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function TermsOfServicePage() {
  return (
    <Layout
      title="Terms of Service"
      back={{ to: "../settings", label: "Settings" }}
    >
      <div className="max-w-lg mx-auto mt-6">
        <div className="flex flex-col px-2 mt-4 space-y-2 divide-y">
          <div className="pt-2">
            <a
              href="https://www.livly.io/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-between w-full p-2 duration-150 rounded-md hover:bg-gray-50"
            >
              <span>Livly</span>
              <FontAwesomeIcon icon="chevron-right" className="text-xs" />
            </a>
          </div>
          <div className="pt-2">
            <a
              href="https://www.dwolla.com/legal/dwolla-account-terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-between w-full p-2 duration-150 rounded-md hover:bg-gray-50"
            >
              <span>Dwolla</span>
              <FontAwesomeIcon icon="chevron-right" className="text-xs" />
            </a>
          </div>
          <div className="pt-2">
            <a
              href="https://stripe.com/legal/ssa"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-between w-full p-2 duration-150 rounded-md hover:bg-gray-50"
            >
              <span>Stripe</span>
              <FontAwesomeIcon icon="chevron-right" className="text-xs" />
            </a>
          </div>
          <div className="pt-2">
            <a
              href="https://plaid.com/legal/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-between w-full p-2 duration-150 rounded-md hover:bg-gray-50"
            >
              <span>Plaid</span>
              <FontAwesomeIcon icon="chevron-right" className="text-xs" />
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}
