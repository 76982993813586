import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Alert from "../../components/Alert";
import { Brands, LoginWrapper } from "../../components/LoginForm";
import { Spinner } from "../../components/Spinner";
import auth from "../../utils/auth";
import { BRAND } from "@/utils/localStorage";

export default function SignUpPage() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const from = location.state?.from || "/login";
  const brand: Brands | undefined =
    (localStorage.getItem(BRAND) as Brands) ?? undefined;

  async function handleSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);

    let formData = new FormData(event.currentTarget);
    let email = formData.get("email") as string;

    await auth.forgotPassword({ email });
    setIsLoading(false);
    setIsComplete(true);
  }

  return (
    <LoginWrapper brand={brand}>
      <div className="flex flex-col justify-center flex-1">
        {isComplete && (
          <Alert variant="success" message="Email has been sent!" />
        )}
        <h2 className="mt-6 tracking-tight text-gray-900">
          Enter your email to receive instructions on how to set your password.
        </h2>
        <div className="mt-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={isLoading}
                className="relative flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white bg-gray-900 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
              >
                {isLoading && (
                  <span className="absolute left-4">
                    <Spinner />
                  </span>
                )}
                Sign up
              </button>
            </div>
            <div className="flex justify-between mt-2">
              <Link to={`/${from}`}>log in</Link>
            </div>
          </form>
        </div>
      </div>
    </LoginWrapper>
  );
}
