import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import { BASE_API_URL } from "../utils/constants";
import { BaseLivlyApiResponse } from "../types/Base";
import { Button } from "../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toLocalTime from "../utils/toLocalTime";
import { Fragment, useState } from "react";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import convertUTCToLocalTime from "../utils/toLocalTime";
import {
  trackChangeEventStatus,
  trackEventRegister,
  trackUpdateResponse
} from "../utils/analytics";
import { Spinner } from "../components/Spinner";
import toast from "react-hot-toast";

export type RSVPEvent = {
  eventId: string;
  attendeeData: Attendee | null;
  title: string;
  description: string;
  imageUrl: string;
  location: string;
  startDate: Date;
  endDate: Date;
  attendeesCount: number;
  isCanceled: boolean;
  createdByUser: string;
  createdDate: Date | string;
  modifiedByUser: string;
  modifiedDate: Date | string;
  allowedGuestCountPerRegistration: number;
  propertyTimeZoneAbbreviation: string;
  capacityLimit: number | null;
  unlimitedCapacity: boolean;
  propertyName: string;
  doesNotRequireRegistration: boolean | null;
  timezone?: string | null;
};

export type Attendee = {
  userId: number;
  eventAttendeeId: number;
  attendingCount: number;
  isAttending: boolean;
  name: string;
  email: string;
  dateCreated: Date | string;
  dateModified: Date | string;
};

export const updateRSVPEventStatus = async (
  propertyId: number,
  eventId: string,
  data: Attendee
) => {
  const result = await axios.post<BaseLivlyApiResponse<RSVPEvent>>(
    `${BASE_API_URL}/resident/events/property/${propertyId}/${eventId}/user`,
    data
  );

  return result.data.Data;
};

const getEvent = async (propertyId: string, eventId: string) => {
  const { data } = await axios.get<BaseLivlyApiResponse<RSVPEvent>>(
    `${BASE_API_URL}/resident/events/property/${propertyId}/${eventId}/user`
  );

  return data.Data;
};

const eventQuery = (propertyId: string, eventId: string) => ({
  queryKey: ["events", "event", propertyId, eventId],
  queryFn: async () => getEvent(propertyId, eventId)
});

export default function EventPage() {
  const params = useParams<{
    leaseId: string;
    propertyId: string;
    eventId: string;
  }>();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [isRegisterSuccessOpen, setIsRegisterSuccessOpen] = useState(false);
  const [isAttending, setIsAttending] = useState(false);

  const { data, isLoading } = useQuery({
    ...eventQuery(params.propertyId!, params.eventId!)
  });

  const { mutateAsync, isLoading: isSaving } = useMutation((data: Attendee) =>
    updateRSVPEventStatus(Number(params.propertyId), params.eventId!, data)
  );

  const onSubmit = async (isAttending: boolean, attendingCount: number) => {
    if (data?.attendeeData == null) {
      return;
    }
    setIsAttending(isAttending);
    const request: Attendee = {
      ...data.attendeeData,
      isAttending,
      attendingCount
    };

    try {
      await mutateAsync(request);
      trackUpdateResponse(data.eventId);
      queryClient.invalidateQueries(["events"]);
      queryClient.invalidateQueries(["bookings"]);
      setIsRegisterSuccessOpen(true);
    } catch (e) {
      const error = e as { data?: { Message?: string } };
      toast.error(error?.data?.Message ?? "An error occurred");
    }
  };

  const {
    capacityLimit = 0,
    attendeesCount = 0,
    unlimitedCapacity = false
  } = data || {};
  const remainingSpots =
    unlimitedCapacity || capacityLimit == null
      ? "Unlimited"
      : Math.max(0, capacityLimit - attendeesCount);
  const isFull = remainingSpots === 0;
  const allowedGuestCount =
    remainingSpots === "Unlimited"
      ? data?.allowedGuestCountPerRegistration || 0
      : Math.min(data?.allowedGuestCountPerRegistration || 0, remainingSpots);
  const isPastEvent = moment().isAfter(moment.utc(data?.startDate).local());

  return (
    <Layout
      title={data?.title ?? "Livly Event"}
      back={{
        to: -1,
        label: "Events"
      }}
    >
      {isLoading || data === undefined ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <>
          <div>
            <p className="mb-2 text-sm font-light">
              {toLocalTime(data?.startDate, data?.timezone).format(
                "dddd, MMMM D, YYYY"
              )}
            </p>
            <h1 className="mb-4 text-xl font-bold">{data?.title}</h1>

            <div className="flex flex-col-reverse gap-8 mb-8 lg:flex-row">
              <div style={{ flexGrow: 1 }}>
                <div className="flex items-center justify-center flex-1 w-full">
                  {data?.imageUrl ? (
                    <img
                      className="w-full block max-h-[450px] object-cover"
                      src={data?.imageUrl}
                      alt={data?.title}
                    />
                  ) : (
                    <div className="max-h-[450px]0 min-h-[250px] h-full w-full flex items-center justify-center bg-gray-50">
                      <FontAwesomeIcon icon="dumbbell" size="2x" />
                    </div>
                  )}
                </div>

                <div className="mb-5">
                  <h3 className="mt-4 mb-2 text-lg font-medium">Details</h3>
                  <div
                    className="text-sm whitespace-pre-line"
                    dangerouslySetInnerHTML={{
                      __html: getEventDescription(data?.description)
                    }}
                  />
                </div>
              </div>
              <div className="mt-4 lg:mt-0 lg:w-[300px] lg:flex-shrink-0">
                <div className="sticky t-6">
                  <div className="p-4 rounded-lg shadow-md">
                    <div>
                      <div className="flex gap-3 mb-3">
                        <div className="flex-shrink-0 w-4 mt-1">
                          <FontAwesomeIcon icon="clock" />
                        </div>

                        <time className="m-0">
                          {toLocalTime(data.startDate, data?.timezone).format(
                            "dddd, MMMM D, YYYY"
                          )}{" "}
                          at{" "}
                          {toLocalTime(data.startDate, data?.timezone).format(
                            "h:mm a"
                          )}{" "}
                          to{" "}
                          {toLocalTime(data.endDate, data?.timezone).format(
                            "dddd, MMMM D, YYYY"
                          )}{" "}
                          at{" "}
                          {toLocalTime(data.endDate, data?.timezone).format(
                            "h:mm a"
                          )}
                        </time>
                      </div>
                      <div className="flex items-center gap-3">
                        <div className="flex-shrink-0 w-5">
                          <FontAwesomeIcon icon="map-marker-alt" />
                        </div>
                        <p className="m-0">{data?.location}</p>
                      </div>
                    </div>
                    <div className="mt-6">
                      {!data.doesNotRequireRegistration && (
                        <div>
                          {data.isCanceled ? (
                            <div>
                              <p>This event has been canceled.</p>
                            </div>
                          ) : isPastEvent ? (
                            <div>
                              <p>This event has already happened.</p>
                            </div>
                          ) : data.attendeeData?.isAttending ? (
                            <ChangeStatus
                              attendee={data.attendeeData!}
                              maxGuestsAllowed={allowedGuestCount}
                              onSubmit={onSubmit}
                              isLoading={false}
                              isUpdating={isSaving}
                              eventId={data.eventId}
                            />
                          ) : isFull ? (
                            <div>
                              <p>{t("events.full")}</p>
                            </div>
                          ) : (
                            <Register
                              eventId={data.eventId}
                              allowedGuestCount={allowedGuestCount}
                              onSubmit={onSubmit}
                              isLoading={isSaving}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <RegisterSuccess
            isAttending={isAttending}
            isOpen={isRegisterSuccessOpen}
            onClose={() => setIsRegisterSuccessOpen(false)}
            event={data}
          />
        </>
      )}
    </Layout>
  );
}

function Register({
  eventId,
  allowedGuestCount,
  onSubmit,
  isLoading
}: {
  eventId: string;
  allowedGuestCount: number;
  onSubmit: (isAttending: boolean, attendingCount: number) => void;
  isLoading: boolean;
}) {
  const [guestsCount, setGuestsCount] = useState(1);

  const submit = () => {
    trackEventRegister(eventId);
    onSubmit(true, guestsCount);
  };

  return (
    <div className="flex gap-2">
      <div>
        <select
          value={guestsCount}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            setGuestsCount(Number(e.target.value));
          }}
        >
          {Array.from({ length: allowedGuestCount }, (_, i) => {
            const value = i + 1;
            return (
              <option key={value} value={value}>
                {value}
              </option>
            );
          })}
        </select>
      </div>
      <Button color="primary" disabled={isLoading} onClick={submit}>
        Register
      </Button>
    </div>
  );
}

const getEventDescription = (description: string) => {
  let eventDescription = description;

  try {
    eventDescription = decodeURIComponent(description);
  } catch (e) {}

  return eventDescription;
};

const ChangeStatus = ({
  attendee,
  onSubmit,
  isLoading,
  isUpdating,
  maxGuestsAllowed,
  eventId
}: {
  attendee: Attendee;
  onSubmit: (isAttending: boolean, attendingCount: number) => void;
  maxGuestsAllowed: number;
  isUpdating: boolean;
  isLoading: boolean;
  eventId: string;
}) => {
  const [isStatusOpen, setIsStatusOpen] = useState(false);
  const { isAttending, attendingCount } = attendee;

  return (
    <>
      <div className="flex-1 px-4 md:flex-auto md:px-0">
        <div className="flex justify-between">
          {isLoading ? (
            <Spinner color="livly" />
          ) : (
            <div>
              <p>
                You are {isAttending ? "going" : "not going"} to this event.
              </p>
              {isAttending && (
                <p className="text-sm">
                  {attendingCount} {attendingCount > 1 ? "spots" : "spot"}
                </p>
              )}
            </div>
          )}
        </div>
        <div className="mt-2">
          <Button
            className="w-full"
            color="secondary"
            onClick={() => {
              trackChangeEventStatus(eventId);
              setIsStatusOpen(true);
            }}
          >
            Change status
          </Button>
        </div>
      </div>
      <ChangeStatusDialog
        isOpen={isStatusOpen}
        onClose={() => setIsStatusOpen(false)}
        isAttending={isAttending}
        attendingCount={attendingCount}
        onSubmit={onSubmit}
        isUpdating={isUpdating}
        maxGuestsAllowed={maxGuestsAllowed}
      />
    </>
  );
};

function ChangeStatusDialog({
  isOpen,
  onClose,
  isAttending,
  attendingCount,
  onSubmit,
  isUpdating,
  maxGuestsAllowed
}: {
  isOpen: boolean;
  onClose: () => void;
  isAttending: boolean;
  attendingCount: number;
  maxGuestsAllowed: number;
  onSubmit: (isAttending: boolean, newCount: number) => void;
  isUpdating: boolean;
}) {
  const [count, setCount] = useState<number>(attendingCount);
  const [status, setStatus] = useState<number>(isAttending ? 1 : 0);
  const isGuestError = count > maxGuestsAllowed;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="flex items-center justify-center w-8 h-8 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon icon="times" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Update Status
                    </Dialog.Title>
                  </div>
                  <div className="mt-2">
                    <div className="mt-6">
                      <div className="grid grid-cols-2 gap-4">
                        <p className="flex items-center">Current status:</p>
                        <select
                          className="w-full"
                          value={status}
                          onChange={(e: React.ChangeEvent<{ value: any }>) => {
                            const going = Number(e.target.value);
                            setStatus(going);
                            if (!going) {
                              setCount(0);
                            } else if (going && count <= 0) {
                              setCount(1);
                            }
                          }}
                        >
                          <option value={1}>Going</option>
                          <option value={0}>Not Going</option>
                        </select>
                      </div>
                      <div className="grid grid-cols-2 gap-4 mt-4">
                        <div className="flex items-center justify-between">
                          <p># of spots: </p>
                          {isGuestError && (
                            <FontAwesomeIcon icon="exclamation-triangle" />
                          )}
                        </div>
                        <select
                          className="w-full"
                          value={count}
                          onChange={(e: React.ChangeEvent<{ value: any }>) =>
                            setCount(e.target.value)
                          }
                        >
                          {Array.from({ length: maxGuestsAllowed }, (_, i) => {
                            const value = i + 1;
                            return (
                              <option key={value} value={value}>
                                {value}
                              </option>
                            );
                          })}
                          {isGuestError && (
                            <option value={attendingCount} disabled>
                              {attendingCount}
                            </option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-4 mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <Button
                    color="secondary"
                    type="button"
                    onClick={async () => {
                      onClose();
                      //updateResponseAnalytics(eventId);
                      await onSubmit(status === 1, count);
                    }}
                    disabled={isUpdating || isGuestError}
                  >
                    {isUpdating && (
                      <span className="absolute left-4">
                        <Spinner />
                      </span>
                    )}
                    Update Status
                  </Button>
                  <Button
                    outlined
                    color="default"
                    type="button"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export const getIsMultiDayEvent = (startDate: Date, endDate: Date) =>
  moment(startDate).diff(endDate, "days") !== 0;

const fullDateFormat = "dddd, MMMM D, h:mm a";
const timeFormat = "h:mm a";

const RegisterSuccess = ({
  isAttending,
  isOpen,
  onClose,
  event
}: {
  isAttending: boolean;
  isOpen: boolean;
  onClose: () => void;
  event: RSVPEvent | undefined;
}) => {
  if (!event) {
    return null;
  }

  const isMultiDayEvent = getIsMultiDayEvent(event.startDate, event.endDate);
  const start = convertUTCToLocalTime(event.startDate, event?.timezone).format(
    fullDateFormat
  );
  const end = convertUTCToLocalTime(event.endDate, event?.timezone).format(
    isMultiDayEvent ? fullDateFormat : timeFormat
  );

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="flex items-center justify-center w-8 h-8 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <FontAwesomeIcon icon="times" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="flex flex-col items-center p-6 bg-gray-200">
                    <FontAwesomeIcon
                      icon={["far", "check-circle"]}
                      className="text-2xl text-green-600"
                    />
                    <p className="mb-4 text-xl font-light">
                      {isAttending
                        ? "You are registered!"
                        : "You have unregistered"}
                    </p>
                    <p className="text-center">
                      If anything changes, please update your registration here.
                    </p>
                  </div>
                  <div className="px-3 py-6">
                    <div className="p-4 border border-gray-200 rounded-md">
                      <p>
                        {event.attendeeData?.attendingCount}{" "}
                        {(event.attendeeData?.attendingCount ?? 0) > 1
                          ? "spots"
                          : "spot"}{" "}
                        reserved
                      </p>
                      <p className="mt-4 font-bold">{event.title}</p>
                      <p>
                        {start} – {end} {event.propertyTimeZoneAbbreviation}
                      </p>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
