import { useEffect, useRef } from "react";
import { useInView, useMotionValue, useSpring } from "framer-motion";

/**
 *
 * @param root0
 * @param root0.value
 */
export default function Counter({
  value,
  direction = "up",
}: {
  value: number;
  direction?: "up" | "down";
}) {
  const ref = useRef<HTMLSpanElement>(null);
  const motionValue = useMotionValue(direction === "down" ? value : 0);
  const springValue = useSpring(motionValue, {
    damping: 100,
    stiffness: 300,
  });
  const isInView = useInView(ref);

  useEffect(() => {
    if (isInView) {
      motionValue.set(direction === "down" ? 0 : value);
    }
  }, [motionValue, isInView]);

  useEffect(() => {
    motionValue.set(value);
  }, [value]);

  useEffect(
    () =>
      springValue.onChange((latest) => {
        if (ref.current) {
          const textContent = Intl.NumberFormat("en-US").format(
            latest.toFixed(0)
          );
          ref.current.textContent = textContent;
        }
      }),
    [springValue]
  );

  return <span ref={ref}>0</span>;
}
