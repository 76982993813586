import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import {
  useNavigate,
  useNavigation,
  useActionData,
  useLocation,
} from "react-router-dom";
import Alert from "../../components/Alert";
import { Button } from "../../components/Button";
import { textInputClass } from "../../components/Form";
import Layout from "../../components/Layout";
import { Spinner } from "../../components/Spinner";
import useLivlyUser from "../../context/UserProvider";
import { useGetStatusTypeDetails, usePostService } from "./landing";
import { OnboardingStatusEnum } from "../../types/User";
import axios from "axios";
import { BASE_API_URL } from "@/utils/constants";
import { useMutation } from "@tanstack/react-query";
import { useGetInsuranceDetails } from "../insurance";
import { isNativeAppVersion, returnToNative } from "@/utils/nativeAppHelpers";

function ChecklistInsuranceProofPage() {
  const location =
    (useLocation().state as {
      returnTo: string;
      label: string;
    }) || {};
  const navigate = useNavigate();
  const { user } = useLivlyUser();
  const [file, setFile] = useState<File | null>(null);

  const { data, isLoading: isLoadingInsuranceDetails } =
    useGetInsuranceDetails(user);

  const details = useGetStatusTypeDetails(OnboardingStatusEnum.insurance, user);
  const uploadInsuranceProofMutation = useUploadInsuranceProof(
    user.propertyUnitLeaseId.toString(),
    user.userId.toString()
  );

  const { updateOnboardingStatus, skipOnboardingStep, isLoading, status } =
    usePostService(
      user.userId,
      user.propertyUnitLeaseId.toString(),
      details.onboardingStatus
    );

  const myProof = data?.proofs.find(
    (proof) => proof.proofOfInsuranceProvidedByUserId === user.userId
  );
  const [companyName, setCompanyName] = useState("");
  const [policyNumber, setPolicyNumber] = useState("");

  useEffect(() => {
    if (myProof) {
      setCompanyName(myProof.companyName ?? "");
      setPolicyNumber(myProof.policyNumber ?? "");
    }
  }, [myProof]);

  const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length) {
      const file = files[0] as File;

      if (file) {
        setFile(file);
      }
    }
  };

  const fileName = file?.name ?? myProof?.fileUri?.split("/").pop();
  const documentImage = getFileIcon(
    file?.name?.split(".").pop() || myProof?.fileUri?.split(".").pop() || ""
  );

  const onSkip = async () => {
    await skipOnboardingStep();

    if (isNativeAppVersion()) {
      returnToNative();
      return;
    }

    navigate("..");
  };

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fd = new FormData();

    if (file) {
      fd.append("file", file, file.name);
    } else if (myProof) {
      fd.append("fileUri", myProof.fileUri);
    }
    fd.append("companyName", companyName);
    fd.append("policyNumber", policyNumber);

    uploadInsuranceProofMutation.mutate(fd, {
      onSuccess: async () => {
        await updateOnboardingStatus({
          ...details.onboardingStatus,
          isCompleted: true,
        });

        if (isNativeAppVersion()) {
          returnToNative();
          return;
        }

        navigate("..");
      },
    });
  };

  return (
    <Layout
      title="Upload Proof"
      back={{
        to: location?.returnTo ?? "Insurance",
        label: location?.label ?? -1,
      }}
    >
      <div className="flex justify-end">
        <div>
          <Button
            color="default"
            onClick={onSkip}
            className="flex items-center w-full gap-2 md:w-auto"
            disabled={isLoading || uploadInsuranceProofMutation.isLoading}
          >
            {status === "skipping" && <Spinner />}
            Skip
          </Button>
        </div>
      </div>
      <form onSubmit={onSubmit} className="mt-4">
        <p className="text-sm">
          Please fill in the fields and upload a scanned copy of your proof of
          insurance for us to verify.
        </p>
        {uploadInsuranceProofMutation.isError && (
          <div className="py-2">
            <Alert
              variant="danger"
              message="There was an error submitting your proof of insurance"
            />
          </div>
        )}
        <fieldset
          disabled={
            uploadInsuranceProofMutation.isLoading || isLoadingInsuranceDetails
          }
        >
          <label className="block p-4 mt-6 transition duration-200 border-2 border-red-300 border-dashed rounded-md cursor-pointer hover:bg-gray-50">
            <div className="flex items-center gap-4">
              <FontAwesomeIcon
                icon={documentImage}
                className="text-4xl text-red-300"
              />
              <div>
                <p
                  className="font-medium text-red-300 truncate"
                  title={fileName ?? file?.name}
                >
                  {truncateFileName(
                    fileName ?? file?.name,
                    "Upload insurance proof"
                  )}
                </p>
                <p className="text-sm font-light">
                  {fileName || file?.name
                    ? ".jpg, .jpeg, .png, .pdf"
                    : "Must be .jpg, .jpeg, .png, .pdf"}
                </p>
              </div>
            </div>
            <input
              className="hidden"
              id="proof-document"
              type="file"
              name="file"
              onChange={onFileInputChange}
              accept=".jpg, .jpeg, .png,  .pdf"
            />
          </label>
          <div className="mt-4">
            <label
              htmlFor="companyName"
              className="block text-sm font-medium text-gray-700"
            >
              insurance company
            </label>
            <input
              required
              autoFocus
              id="companyName"
              name="companyName"
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              className={textInputClass}
            />
          </div>

          <div className="mt-4">
            <label
              htmlFor="policyNumber"
              className="block text-sm font-medium text-gray-700"
            >
              policy number
            </label>
            <input
              required
              id="policyNumber"
              name="policyNumber"
              type="text"
              value={policyNumber}
              onChange={(e) => setPolicyNumber(e.target.value)}
              className={textInputClass}
            />
          </div>

          <div className="fixed bottom-0 left-0 right-0 flex flex-col items-stretch m-4 bg-white border border-gray-100 rounded-lg shadow-lg md:flex-row md:justify-between md:items-baseline md:left-64 drop-shadow-lg">
            <div className="flex flex-col items-center justify-between w-full p-4 border-t border-gray-200 md:flex-row md:border-none">
              <div className="flex flex-col items-center justify-center mr-4 md:items-baseline">
                <p className="text-xs font-light md:mr-2">
                  I acknowledge that I have read and understand all applicable
                  state disclosures, fraud notices, and notice of cancellation.
                </p>
              </div>
              <Button
                color="secondary"
                className="flex items-center flex-shrink-0 w-full gap-2 mt-4 md:w-auto md:mt-0"
                disabled={
                  companyName.trim().length === 0 ||
                  policyNumber.trim().length === 0 ||
                  (file == null && fileName == null) ||
                  uploadInsuranceProofMutation.isLoading
                }
              >
                {uploadInsuranceProofMutation.isLoading && (
                  <Spinner color="white" />
                )}
                Send to verify
              </Button>
            </div>
          </div>
        </fieldset>
      </form>
    </Layout>
  );
}

export { ChecklistInsuranceProofPage };

function getFileIcon(fileExtension: string) {
  let icon: IconProp = "file-alt";

  switch (fileExtension) {
    case "pdf":
      icon = "file-pdf";
      break;
    case "png":
    case "jpg":
    case "jpeg":
      icon = "file-image";
      break;
    default:
      icon = "cloud-upload";
      break;
  }

  return icon;
}

async function uploadInsuranceProof(
  userId: string,
  leaseId: string,
  formData: FormData
) {
  const result = await axios.post(
    `${BASE_API_URL}/livly/users/${userId}/proofofinsurance?leaseId=${leaseId}`,
    formData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );

  return result;
}

export function useUploadInsuranceProof(leaseId: string, userId: string) {
  return useMutation({
    mutationFn: (formData: FormData) =>
      uploadInsuranceProof(userId, leaseId, formData),
  });
}

export const truncateFileName = (
  fileName: string | undefined,
  emptyText: string,
  limit: number = 20
) => {
  if (!fileName) {
    return emptyText;
  }

  if (fileName.length <= limit) {
    return fileName;
  }

  return `${fileName.slice(0, limit)}...`;
};
