import classNames from "classnames";
import Layout from "../../components/Layout";
import { Spinner } from "../../components/Spinner";
import formatCurrency from "../../utils/formatCurrency";
import {
  RequoteRequestModel,
  useInsuranceQuote,
  useRequoteInsuranceQuote,
} from "./insurance-quote";
import useDebounce from "../../hooks/useDebounce";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "../../components/Dialog";
import useLivlyUser from "../../context/UserProvider";
import { useOutletContext } from "react-router-dom";
import { InsuranceQuoteContext } from "@/components/InsuranceQuoteLayout";

function InsuranceCoveragesPage() {
  const { user } = useLivlyUser();
  const { data, refetch } = useOutletContext<InsuranceQuoteContext>();

  const requoteMutation = useRequoteInsuranceQuote(user);

  const [coverageOptionType, setCoverageOptionType] =
    useState<CoverageOptionType | null>(null);
  const [coveragePersonalProperty, setCoveragePersonalProperty] = useState(
    data?.presentation.coveragePersonalProperty ?? ""
  );

  const fullPlan = data?.purchase.schedules.find(
    (s) => s.numberOfPayments === 1
  );
  const monthlyPlan = data?.purchase.schedules.find(
    (s) => s.numberOfPayments > 6
  );

  const onChange = async (coverage: Partial<RequoteRequestModel>) => {
    try {
      const request: RequoteRequestModel = {
        coveragePersonalLiability: data?.presentation.coverageLiability ?? "",
        coveragePersonalProperty:
          data?.presentation.coveragePersonalProperty ?? "",
        deductable: data?.presentation.deductible ?? "",
        policyEffectiveDate: data?.configuration.policyEffectiveDate ?? "",
        ...coverage,
      };
      await requoteMutation.mutateAsync(request);
      refetch();
    } catch (e) {}
  };

  let debouncedCoveragePersonalProperty = useDebounce(
    coveragePersonalProperty,
    1000
  );

  useEffect(() => {
    if (!data) {
      return;
    }

    onChange({ coveragePersonalProperty: debouncedCoveragePersonalProperty });
  }, [debouncedCoveragePersonalProperty]);

  useEffect(() => {
    if (data && coveragePersonalProperty === "") {
      setCoveragePersonalProperty(data?.presentation.coveragePersonalProperty);
    }
  }, [data]);

  return (
    <Layout
      title="Edit Coverage"
      back={{
        label: "Insurance",
        to: `../insurance-quote`,
      }}
    >
      {requoteMutation.isLoading ? (
        <div className="fixed inset-0 bg-gray-100 bg-opacity-50 flex flex-col items-center justify-center md:ml-64">
          <Spinner size="xl" color="livly" />
          <p>Calculating premiums. Please wait...</p>
        </div>
      ) : null}
      <div className=" max-w-4xl mx-auto">
        <div className="flex divide-x divide-gray-200 border-b border-gray-200 pb-4">
          <div className="flex-1 p-4">
            <FontAwesomeIcon
              icon="sack-dollar"
              className="text-red-400 h-10 w-10"
            />
            <p className="mt-4 text-gray-700 uppercase font-medium">
              Full Term
            </p>
            <p className="text-4xl font-light mt-2">
              {formatCurrency(Number(fullPlan?.downPaymentAmount)).replace(
                ".00",
                ""
              )}
            </p>
          </div>
          <div className="flex-1 p-4">
            <FontAwesomeIcon
              icon="hand-holding-dollar"
              className="text-red-400 h-10 w-10"
            />
            <p className="mt-4 text-gray-700 uppercase font-medium">Monthly</p>
            <p className="text-4xl font-light mt-2">
              $
              {Number(monthlyPlan?.installmentAmount) +
                Number(monthlyPlan?.serviceFee)}
            </p>
          </div>
        </div>
        <div className="mt-8">
          <div className="flex items-center gap-2">
            <p className="font-medium text-lg">Personal Liability</p>
            <button
              type="button"
              className="flex items-center justify-center w-8 h-8 hover:bg-slate-50 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              onClick={() =>
                setCoverageOptionType(CoverageOptionType.Liability)
              }
            >
              <FontAwesomeIcon icon={["fal", "info-circle"]} />
            </button>
          </div>
          <div className="flex gap-2 mt-2">
            {data?.configuration.personalLiabilityAllowed.map((amount) => (
              <button
                key={amount}
                type="button"
                className={classNames(
                  {
                    "border-red-400":
                      data?.presentation.coverageLiability ===
                      amount.toString(),
                  },
                  "px-3 py-2 border rounded-md hover:bg-slate-50 transition duration-300"
                )}
                onClick={() =>
                  onChange({ coveragePersonalLiability: amount.toString() })
                }
              >
                {formatCurrency(amount)}
              </button>
            ))}
          </div>
        </div>
        <div className="mt-8">
          <div className="flex items-center gap-2">
            <p className="font-medium text-lg">Personal Belongings</p>
            <button
              type="button"
              className="flex items-center justify-center w-8 h-8 hover:bg-slate-50 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              onClick={() =>
                setCoverageOptionType(CoverageOptionType.PersonalBelongings)
              }
            >
              <FontAwesomeIcon icon={["fal", "info-circle"]} />
            </button>
          </div>
          <div className="mt-2 flex items-center gap-4">
            <input
              type="range"
              className="accent-indigo-600"
              step={data?.configuration.personalPropertyIncr}
              min={data?.configuration.personalPropertyMin}
              max={data?.configuration.personalPropertyMax}
              value={coveragePersonalProperty}
              onChange={(e) => setCoveragePersonalProperty(e.target.value)}
            />
            <p>{formatCurrency(Number(coveragePersonalProperty))}</p>
          </div>
        </div>
        <div className="mt-8">
          <div className="flex items-center gap-2">
            <p className="font-medium text-lg">Deductible</p>
            <button
              type="button"
              className="flex items-center justify-center w-8 h-8 hover:bg-slate-50 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              onClick={() =>
                setCoverageOptionType(CoverageOptionType.Deductible)
              }
            >
              <FontAwesomeIcon icon={["fal", "info-circle"]} />
            </button>
          </div>
          <div className="flex gap-2 mt-2">
            {data?.configuration.deductibleAllowed.map((amount) => (
              <button
                key={amount}
                type="button"
                className={classNames(
                  {
                    "border-red-400":
                      data?.presentation.deductible === amount.toString(),
                  },
                  "px-3 py-2 border rounded-md hover:bg-slate-50 transition duration-300"
                )}
                onClick={() => onChange({ deductable: amount.toString() })}
              >
                {formatCurrency(amount)}
              </button>
            ))}
          </div>
        </div>
        {data?.presentation.hurricaneDeductible ? (
          <div className="mt-8">
            <p className="font-medium text-lg">Hurricane Deductible</p>
            <div className="flex gap-2 mt-2">
              <span>
                {formatCurrency(Number(data.presentation.hurricaneDeductible))}
              </span>
            </div>
          </div>
        ) : null}
        {data?.presentation.riskState === "NY" ? (
          <div className="mt-8">
            <div className="flex items-center gap-2">
              <p className="font-medium text-lg">Flood</p>
              <button
                type="button"
                className="flex items-center justify-center w-8 h-8 hover:bg-slate-200 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                onClick={() => setCoverageOptionType(CoverageOptionType.Flood)}
              >
                <FontAwesomeIcon icon={["fal", "info-circle"]} />
              </button>
            </div>
            <div className="flex gap-2 mt-2">
              <span>included</span>
            </div>
          </div>
        ) : null}
      </div>
      <InformationModal
        coverageOptionType={coverageOptionType}
        onClose={() => setCoverageOptionType(null)}
      />
    </Layout>
  );
}

export enum CoverageOptionType {
  Deductible = "Deductible",
  Liability = "Personal Liability",
  PersonalBelongings = "Personal Belongings",
  Flood = "Flood",
}

const CoverageOptionDescriptions: Record<CoverageOptionType, any> = {
  Deductible: (
    <p className="text-sm">
      Your deductible is how much you’re responsible for paying before your
      policy steps in to help. It’s deducted from your personal belongings
      claims made. A higher deductible will lower your monthly payment but that
      means you’ll need to pay more out of pocket if you file a claim.
    </p>
  ),
  "Personal Liability": (
    <p className="text-sm">
      Liability coverage protects you in case you’re held responsible for an
      accident that causes injury or property damage. If someone gets hurt,
      there’s damage to your unit or someone’s stuff gets ruined, you’ll be
      protected up to the coverage amount you choose.
    </p>
  ),
  "Personal Belongings": (
    <p className="text-sm">
      Personal belongings coverage protects your stuff, like your furniture,
      clothing, electronics and other personal items. Your valuables are
      protected whether they’re damaged or stolen, at your home or anywhere else
      in the world.
    </p>
  ),
  Flood: (
    <div className="text-sm">
      <p>
        Flood coverage <strong>protects your belongings</strong> if your
        building is suddenly damaged from flooding events, like an overflow of a
        body of water, storm surges, or tidal waves.
      </p>
      <p>The coverage will protect you for the following:</p>
      <ul>
        <li>Pays up to $2,500 if your personal belongings are damaged</li>
        <li>Pays up to $1,000 in additional living expenses</li>
        <li>
          If due to a flood, your whole building needs to be evacuated or you
          are not able to get to your unit and need to stay somewhere else
        </li>
        <li>Pays up to $100 in Food Spoilage</li>
        <li>
          Flood events sometimes causes power outages so this will cover your
          food that spoils in your refrigerator or freezer.
        </li>
      </ul>
      <p>
        You are making a great decision by purchasing this policy from Assurant,{" "}
        <strong>
          the ONLY company within the multifamily housing industry to offer this
          coverage as part of a renters insurance program!
        </strong>
      </p>
    </div>
  ),
};

function InformationModal({
  coverageOptionType,
  onClose,
}: {
  coverageOptionType: CoverageOptionType | null;
  onClose: () => void;
}) {
  return (
    <Modal
      open={coverageOptionType != null}
      onClose={onClose}
      title={coverageOptionType ?? ""}
    >
      <div className="mt-4">
        {coverageOptionType && CoverageOptionDescriptions[coverageOptionType]}
      </div>
    </Modal>
  );
}

export { InsuranceCoveragesPage };
