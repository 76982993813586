import React, { useEffect, useState } from "react";
import { questionAnswersType } from "./hooks/types";

export const PollTextInput = ({
  updateOther,
  residentPollQuestionId,
  setIsSubmitDisabled,
  isExpired,
  userAnswers
}: {
  updateOther: (questionId: number, other: string) => void;
  residentPollQuestionId: number;
  setIsSubmitDisabled: (item: boolean) => void;
  isExpired?: boolean;
  userAnswers: questionAnswersType[];
}) => {
  const [other, setOther] = useState<string>("");

  useEffect(() => {
    const selectedAnswer =
      userAnswers?.find(
        (answer) => answer.residentPollQuestionId == residentPollQuestionId
      )?.other || "";
    setOther(selectedAnswer);
  }, [userAnswers, residentPollQuestionId]);

  useEffect(() => {
    if (other.length > 0) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [other]);

  return (
    <div>
      <input
        type="text"
        name="description"
        placeholder="Enter your answer"
        id="description"
        className="block w-full border-t-0 border-x-0 border-solid border-b-1 border-gray-500   focus:border-b-indigo-500 focus:ring-white sm:text-sm"
        maxLength={50}
        value={other ?? ""}
        onChange={(e: any) => {
          setOther(e.target.value);
          updateOther(residentPollQuestionId, e.target.value);
        }}
        disabled={isExpired}
      />
      <p className="text-sm text-gray-500 mt-2">
        {50 - (other?.length ?? 0)} characters remaining
      </p>
    </div>
  );
};
