import { Link, useNavigate, useParams } from "react-router-dom";
import useLivlyUser from "../../context/UserProvider";
import Layout from "../../components/Layout";
import { Button, ButtonWrapper } from "../../components/Button";
import { useGetStatusTypeDetails, usePostService } from "./landing";
import { OnboardingStatus, OnboardingStatusEnum } from "../../types/User";
import { VehiclesList } from "../vehicles/vehicles";
import axios from "axios";
import { BaseLivlyApiResponse } from "@/types/Base";
import { BASE_API_URL } from "@/utils/constants";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "@/components/Spinner";
import { isNativeAppVersion, returnToNative } from "@/utils/nativeAppHelpers";

export type VehicleResponse = {
  id: number;
  make: string;
  model: string;
  color: string;
  licensePlateNumber: string;
  year: number;
  userId: number;
  user: {
    firstName: string;
    lastName: string;
    avatarUri: string;
  };
};

function useGetLeaseVehicles(leaseId: string) {
  const getPets = async () => {
    const result = await axios.get<BaseLivlyApiResponse<VehicleResponse[]>>(
      `${BASE_API_URL}/livly/lease/${leaseId}/vehicles`
    );

    return result.data.Data;
  };

  return useQuery(["lease-vehicles", leaseId], getPets);
}

export default function ChecklistVehiclesPage() {
  const { user } = useLivlyUser();
  const params = useParams<{ leaseId: string }>();
  const details = useGetStatusTypeDetails(
    OnboardingStatusEnum.petAndVehicles,
    user
  );
  const { data: vehicles = [], isLoading: isVehiclesLoading } =
    useGetLeaseVehicles(params.leaseId!);
  const navigate = useNavigate();

  const { updateOnboardingStatus, isLoading } = usePostService(
    user.userId,
    params.leaseId!,
    details.onboardingStatus
  );

  const onNext = async () => {
    const newOnboardingStatus: OnboardingStatus = {
      ...details.onboardingStatus,
      isCompleted: true,
    };
    await updateOnboardingStatus(newOnboardingStatus);

    if (isNativeAppVersion()) {
      returnToNative();
      return;
    }

    navigate("../..");
  };

  if (isVehiclesLoading) {
    return (
      <Layout title="Vehicles" back={{ label: "Pets and Vehicles", to: -1 }}>
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      </Layout>
    );
  }

  return (
    <Layout title="Vehicles" back={{ label: "Pets and Vehicles", to: -1 }}>
      {!isVehiclesLoading && vehicles.length === 0 ? (
        <div>
          <h1>Add Vehicles</h1>
          <p>Do you have any vehicles?</p>
          <div className="flex gap-4 mt-4">
            <Link to="add" replace>
              <Button className="w-16">Yes</Button>
            </Link>
            <Button
              color="default"
              onClick={onNext}
              disabled={isLoading}
              className="w-16"
            >
              No
            </Button>
          </div>
        </div>
      ) : (
        <>
          <VehiclesList
            vehicles={vehicles}
            leaseId={params.leaseId!}
            userId={user.userId}
          />
          <ButtonWrapper>
            <Button
              onClick={onNext}
              className="w-full md:w-auto"
              color="secondary"
              disabled={isLoading}
            >
              Confirm
            </Button>
          </ButtonWrapper>
        </>
      )}
    </Layout>
  );
}
