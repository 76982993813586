import Avatar from "@/components/Avatar";
import { FeedPost, FeedPostComment, ReviewState } from "@/utils/commfeed";
import { humanizeTimestamp } from "@/utils/toLocalTime";
import { Menu } from "@headlessui/react";
import { useState, useEffect, useRef } from "react";
import { TextContent } from "./text-content";
import FeedPostCommentReactions from "./feed-post-comment-reactions";
import { Button } from "@/components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useLivlyUser from "@/context/UserProvider";
import { PostCommentForm } from "./post-comment-form";
import { faShieldCheck } from "@fortawesome/pro-solid-svg-icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getCommFeedCommentsById, likeComment } from "./hook/usePost";
import { Modal } from "@/components/Dialog";
import StreamTextarea from "./StreamTextArea";
import EmojiPicker from "./emoji-picker";
import { faSmile } from "@fortawesome/free-solid-svg-icons";
import { sortBy } from "lodash";
import useIsReadonlyCommFeed from "./hook/useIsReadonlyCommFeed";
import toast from "react-hot-toast";
import { Spinner } from "@/components/Spinner";

function PostComments({
  post,
  isMyPost = false,
  commentBoxRef,
  onDeleteComment,
  onAddPostComment,
  onEditPostComment,
  isLoading,
  isReadOnly
}: {
  post: FeedPost;
  isMyPost?: boolean;
  onDeleteComment: (
    leaseId: string,
    postId: string,
    commentId: string
  ) => Promise<void>;
  commentBoxRef: React.MutableRefObject<HTMLTextAreaElement | null>;
  onAddPostComment: (
    leaseId: string,
    postId: string,
    comment: string
  ) => Promise<FeedPost>;
  onEditPostComment: (
    leaseId: string,
    postId: string,
    commentId: string,
    comment: string
  ) => Promise<FeedPostComment>;
  isLoading: boolean;
  isReadOnly: boolean;
}) {
  const { user } = useLivlyUser();
  const [comments, setComments] = useState(post.comments ?? []);

  const getPostCommentsMutation = useMutation({
    mutationFn: ({ leaseId, postId }: { leaseId: string; postId: string }) =>
      getCommFeedCommentsById(leaseId, postId),
    onSuccess: (data) => {
      setComments(data?.comments || comments);
    },
    onError: (e) => {
      const error = e as { data?: { Message?: string } };
      toast.error(error?.data?.Message ?? "An error occurred");
    }
  });

  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    if (post.comments) {
      setComments(post.comments);
    }
  }, [post.comments]);

  const onLoadMoreComments = async () => {
    await getPostCommentsMutation.mutateAsync({
      leaseId: String(user.propertyUnitLeaseId),
      postId: String(post.id)
    });
    setShowAll(true);
  };

  // Display the last 2 comments or all if "showAllComments" is true
  const visibleComments = showAll ? comments : comments.slice(0, 2);
  const sortedComments = sortBy(
    visibleComments,
    (comment) => comment.timestamp
  );

  return (
    <>
      <div className={`pb-4 pt-1 space-y-4 `}>
        {sortedComments.length > 0 && (
          <div>
            <div className="my-2 " />
            {comments.length > 2 && !showAll && (
              <div className="mt-1 mb-2 flex items-center">
                <span
                  className="text-sm text-blue-500 cursor-pointer"
                  onClick={onLoadMoreComments}
                >
                  View more comments{" "}
                </span>

                {getPostCommentsMutation.isLoading && (
                  <Spinner color="livly" size="md" />
                )}
              </div>
            )}
            {sortedComments.map((comment) => (
              <div key={comment.id} className="mb-3 flex space-x-4 w-full">
                <BuildingCommentDetails
                  onEditPostComment={onEditPostComment}
                  onDeleteComment={onDeleteComment}
                  comment={comment}
                  postId={post.id}
                  isMyPost={isMyPost}
                  isLoading={isLoading}
                  isReadOnly={isReadOnly}
                />
              </div>
            ))}
          </div>
        )}
        {!isReadOnly && (
          <PostCommentForm
            propertyId={post.propertyId.toString()}
            postId={post.id}
            commentBoxRef={commentBoxRef}
            onAddPostComment={onAddPostComment}
          />
        )}
      </div>
    </>
  );
}

function BuildingCommentDetails({
  comment,
  onEditPostComment,
  onDeleteComment,
  isMyPost = false,
  postId,
  isLoading,
  isReadOnly = false
}: {
  onEditPostComment: (
    buildingId: string,
    postId: string,
    commentId: string,
    comment: string
  ) => Promise<FeedPostComment>;
  onDeleteComment: (
    leaseId: string,
    postId: string,
    commentId: string
  ) => Promise<void>;
  comment: FeedPostComment;
  isMyPost?: boolean;
  postId: string;
  isLoading: boolean;
  isReadOnly: boolean;
}) {
  const [open, setOpen] = useState(false);

  const { user } = useLivlyUser();
  const queryClient = useQueryClient();
  const addCommentReactionMutation = useMutation({
    mutationFn: (doLike: boolean) =>
      likeComment(
        user.propertyUnitLeaseId.toString(),
        comment.parentId,
        comment.id,
        doLike
      )
  });
  const onHandleCommentReaction = async () => {
    await addCommentReactionMutation.mutateAsync(!comment.userHasLiked, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            isMyPost ? "community-feed-user" : "community-feed",
            user.propertyId,
            user.propertyUnitLeaseId,
            undefined
          ]
        });
      }
    });
  };

  const underReview = [
    ReviewState.ReviewRequested,
    ReviewState.ReviewRequired,
    ReviewState.ReviewRequiredProcessingModeration
  ].includes(comment.reviewState as any)
    ? true
    : false;

  return (
    <>
      <div className={`${underReview ? "bg-yellow-50" : ""} w-full px-4 pb-2`}>
        {underReview && (
          <div className="py-2 flex items-center justify-between space-x-2 mt-4">
            <p className="text-black text-sm">Your Comment is under review.</p>
            <div className="cursor-pointer" onClick={() => setOpen(true)}>
              <FontAwesomeIcon
                icon={["fas", "info-circle"]}
                className="text-black text-lg"
              />
            </div>
          </div>
        )}
        <div className="flex space-x-4  w-full">
          <Avatar name={comment.actorName} src={comment.actorUri} size="sm" />
          <div className="flex-1">
            <div className="flex items-baseline space-x-2">
              <span className="text-sm font-semibold">
                {comment.actorName}{" "}
                {comment.actorType === "PropertyUser" ? (
                  <FontAwesomeIcon
                    icon={faShieldCheck}
                    className="text-blue-500"
                  />
                ) : null}
              </span>
              <div className="flex-1 flex justify-between">
                <span className="text-xs text-gray-500">
                  {humanizeTimestamp(comment.timestamp)}
                  {comment.timestamp !== comment.lastUpdated && ` (edited)`}
                </span>
              </div>
            </div>
            <span className="text-xs text-gray-600">
              {comment.propertyName}
            </span>
            <div className="mt-2">
              <TextContent text={comment.text} />
            </div>

            {!isReadOnly && (
              <div className="mt-2 flex items-center space-x-2">
                <FeedPostCommentReactions
                  hasLiked={comment.userHasLiked}
                  toggleLikeComment={onHandleCommentReaction}
                  totalLikes={comment.totalLikes}
                  tempComment={comment}
                />
              </div>
            )}
          </div>
          {user.userId === comment.actorId && (
            <FeedPostCommentMenu
              comment={comment}
              onDelete={onDeleteComment}
              onEdit={onEditPostComment}
              postId={postId}
              isLoading={isLoading}
            />
          )}
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title={"What does this mean?"}
        size="md"
      >
        <div className="flex justify-between flex-col ">
          <p className="mt-2 text-left">
            Your property staff is responsible for approving your comment to the
            Community Feed before it becomes public. If your message is urgent,
            please contact your property staff via phone or email.
          </p>
        </div>
      </Modal>
    </>
  );
}

function FeedPostCommentMenu({
  comment,
  onDelete,
  onEdit,
  postId,
  isLoading
}: {
  postId: string;
  comment: FeedPostComment;
  onDelete: (
    leaseId: string,
    postId: string,
    commentId: string
  ) => Promise<void>;
  onEdit: (
    buildingId: string,
    postId: string,
    commentId: string,
    comment: string
  ) => Promise<FeedPostComment>;
  isLoading: boolean;
}) {
  const { user } = useLivlyUser();
  const isReadOnly = useIsReadonlyCommFeed();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [editCommentDialogOpen, setEditCommentDialogOpen] = useState(false);

  return (
    <div>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="p-1 mb-1 h-6 w-6">
          <FontAwesomeIcon icon="ellipsis-h" />
        </Menu.Button>
        <Menu.Items className="absolute right-0 mt-2 py-2 w-40 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {!isReadOnly && (
            <Menu.Item>
              <div
                className={"cursor-pointer px-4 py-2 hover:bg-gray-100"}
                onClick={() => {
                  setEditCommentDialogOpen(true);
                }}
              >
                <p className="text-sm font-medium">Edit</p>
              </div>
            </Menu.Item>
          )}
          <Menu.Item>
            <div
              className={"cursor-pointer px-4 py-2  hover:bg-gray-100"}
              onClick={() => {
                setDeleteConfirmationOpen(true);
              }}
            >
              <p className="text-sm font-medium">Delete</p>
            </div>
          </Menu.Item>
        </Menu.Items>
      </Menu>
      <ConfirmLeave
        isDialogOpen={deleteConfirmationOpen}
        setIsDialogOpen={setDeleteConfirmationOpen}
        onSubmit={async () => {
          await onDelete(String(user.propertyUnitLeaseId), postId, comment.id);
        }}
        isLoading={isLoading}
      />
      <EditCommentDialog
        open={editCommentDialogOpen}
        comment={comment}
        onClose={() => setEditCommentDialogOpen(false)}
        onEdit={onEdit}
        isLoading={isLoading}
        leaseId={user.propertyUnitLeaseId.toString()}
      />
    </div>
  );
}

type Props = {
  comment: FeedPostComment;
  open: boolean;
  onClose: () => void;
  onEdit: (
    leaseId: string,
    postId: string,
    commentId: string,
    comment: string
  ) => Promise<FeedPostComment>;
  isLoading: boolean;
  leaseId: string;
};

const EditCommentDialog = ({
  open,
  comment,
  onClose,
  onEdit,
  isLoading,
  leaseId
}: Props) => {
  const [text, setText] = useState(comment.text);
  const textInputRef = useRef<HTMLTextAreaElement>(null);

  const onSubmit = async () => {
    await onEdit(leaseId, comment.parentId, comment.id, text);
    onClose();
  };

  const insertText = async (insertedText: string) => {
    let newCursorPosition: number | undefined;
    const textareaElement = textInputRef.current;

    if (!textareaElement) {
      setText(`${text}${insertedText}`);
    } else {
      const { selectionStart, selectionEnd } = textareaElement;
      newCursorPosition = selectionStart + insertedText.length;
      setText(
        text.slice(0, selectionStart) + insertedText + text.slice(selectionEnd)
      );
    }

    if (!textareaElement || newCursorPosition == null) {
      return;
    }
    textareaElement.selectionStart = newCursorPosition;
    textareaElement.selectionEnd = newCursorPosition;
  };

  const onOpenSelectEmoji = () => {
    const textareaElement = textInputRef.current;
    if (textareaElement) {
      textareaElement.focus();
    }
  };
  const onSelectEmoji = (emoji: any) => insertText(emoji.native);
  const onChangeTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value;
    if (text !== null) {
      setText(text);
    }
  };

  useEffect(() => {
    if (open) {
      setText(comment.text);
    }
  }, [open, comment.text]);

  return (
    <Modal open={open} onClose={onClose} title={"Edit Post"} size="xl">
      <div>
        <div className="flex my-4">
          <Avatar name={comment.actorName} src={comment.actorUri} size="sm" />
          <div className="ml-2 flex-grow">
            <h5 className="text-base font-medium">{comment.actorName}</h5>
            <span className="text-xs block">
              {humanizeTimestamp(comment.timestamp)}
              {comment.timestamp !== comment.lastUpdated && ` (edited)`}
            </span>
          </div>
        </div>
        <div className="border border-gray-200  px-1 py-1 rounded-3xl flex flex-row items-end min-h-10  flex-grow mb-8">
          <StreamTextarea
            className="focus:outline-none !h-10 focus-visible:outline-none focus:ring-transparent text-sm leading-6 box-border w-full bg-transparent border-none  outline-none resize-none"
            value={text}
            innerRef={textInputRef}
            onChange={onChangeTextarea}
            rows={1}
          />
          <div className="w-8 mr-4">
            <EmojiPicker
              onSelect={onSelectEmoji}
              onOpen={onOpenSelectEmoji}
              className="m-1 p-1 px-2 mr-[-6px] rounded-full min-w-0 bg-transparent hover:bg-gray-200"
            >
              <FontAwesomeIcon
                icon={faSmile}
                color="#6B7280"
                className="cursor-pointer tetx-sm"
              />
            </EmojiPicker>
          </div>
        </div>
        <div className="flex justify-end gap-2">
          <Button color="default" disabled={isLoading} onClick={onClose}>
            Nevermind
          </Button>
          <Button
            color="secondary"
            disabled={isLoading}
            onClick={() => onSubmit()}
          >
            {isLoading ? "Please wait..." : "Save Comment"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const ConfirmLeave = ({
  isDialogOpen,
  setIsDialogOpen,
  onSubmit,
  confirmText = "Are you sure you want to delete this comment?",
  isLoading
}: {
  isDialogOpen: boolean;
  setIsDialogOpen: ((open: boolean) => void) | any;
  onSubmit: () => void;
  confirmText?: string;
  isLoading: boolean;
}) => {
  return (
    <Modal
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      title={"Warning"}
    >
      <div className="flex justify-between flex-col ">
        <p className="mt-2 text-left">{confirmText}</p>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            color="default"
            disabled={isLoading}
            onClick={() => setIsDialogOpen(false)}
          >
            Go Back
          </Button>
          <Button
            color="secondary"
            disabled={isLoading}
            onClick={async () => {
              await onSubmit();
              setIsDialogOpen(false);
            }}
          >
            {isLoading ? "Please wait..." : "Confirm"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export { PostComments };
