import { z } from "zod";
import Layout from "../../components/Layout";
import { Field, Form, Formik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { FormField, textInputClass } from "../../components/Form";
import { Button } from "../../components/Button";
import {
  LoginDetailsModel,
  LoginDetailsSchema,
  useAddLogin,
} from "./add-login";
import { useNavigate } from "react-router-dom";

export default function AddFamilyLoginDetailsPage() {
  const navigate = useNavigate();
  const { details, setDetails } = useAddLogin();
  const onSubmit = (data: LoginDetailsModel) => {
    setDetails(data);
    navigate("../permissions");
  };

  return (
    <Layout
      title="Login Profile"
      back={{ to: "../../family-logins", label: "Friends & Family Logins" }}
    >
      <div className="max-w-3xl mx-auto">
        <h2>
          Please fill out the user information for this login. You will set
          their permissions on the next screen. All fields are required.
        </h2>
        <Formik
          initialValues={details}
          validationSchema={toFormikValidationSchema(LoginDetailsSchema)}
          onSubmit={onSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              <FormField
                htmlFor="firstName"
                label="First name*"
                showErrorIcon={errors.firstName && touched.firstName}
              >
                <Field
                  id="firstName"
                  name="firstName"
                  type="text"
                  className={textInputClass}
                />
              </FormField>
              <FormField
                htmlFor="lastName"
                label="Last name*"
                showErrorIcon={errors.lastName && touched.lastName}
              >
                <Field
                  id="lastName"
                  name="lastName"
                  type="text"
                  className={textInputClass}
                />
              </FormField>
              <FormField
                htmlFor="email"
                label="Email address*"
                showErrorIcon={errors.email && touched.email}
              >
                <Field
                  id="email"
                  name="email"
                  type="email"
                  className={textInputClass}
                />
                <p className="mt-2 text-sm text-gray-500">
                  An invite will be sent to this email address
                </p>
              </FormField>
              <FormField
                htmlFor="phoneNumber"
                label="Phone number*"
                showErrorIcon={errors.phoneNumber && touched.phoneNumber}
              >
                <Field
                  id="phoneNumber"
                  name="phoneNumber"
                  type="tel"
                  className={textInputClass}
                />
              </FormField>
              <div className="flex justify-end gap-2 mt-4">
                <Button
                  color="secondary"
                  type="submit"
                  className="w-full md:w-auto"
                >
                  Next
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Layout>
  );
}
