import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox, Switch, Transition } from "@headlessui/react";
import { ErrorMessage, useField } from "formik";
import classNames from "classnames";
import { Fragment } from "react";

export const labelClass = "block text-sm font-medium leading-6 text-gray-900";

export const textInputClass =
  "block w-full mt-1 border-0 border-b border-gray-500 focus:border-gray-600 focus:ring-0";
const FormField: React.FC<
  {
    htmlFor: string;
    label: string;
    showErrorIcon?: boolean | "" | undefined;
    isRequired?: boolean | "" | undefined;
  } & JSX.IntrinsicElements["div"]
> = ({ htmlFor, label, showErrorIcon, children, className, isRequired }) => {
  return (
    <div className={`mt-6 ${className}`}>
      <label
        htmlFor={htmlFor}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
        {isRequired && <strong className="ml-1 text-red-500">*</strong>}
      </label>
      <div className="relative mt-1">
        {children}
        {showErrorIcon && (
          <div className="absolute right-0 flex items-center pr-3 pointer-events-none top-2">
            <FontAwesomeIcon
              icon="exclamation-triangle"
              className="text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      <ErrorMessage
        className="mt-2 text-sm text-red-600"
        name={htmlFor}
        component="div"
      />
    </div>
  );
};

export const textAreaClass =
  "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ";
function TextArea(props: JSX.IntrinsicElements["textarea"]) {
  const [field] = useField(props.name!);

  return (
    <textarea
      rows={4}
      {...field}
      {...props}
      className={`${textAreaClass} ${props.className}`}
    />
  );
}

function Toggle({
  checked,
  onChange,
  disabled = false
}: {
  checked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
}) {
  return (
    <Switch
      id="hasPermissionToEnter"
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className={classNames(
        disabled ? "bg-gray-200" : checked ? "bg-blue-600" : "bg-gray-200",
        "relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2",
        {
          "cursor-pointer": !disabled
        }
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        className={classNames(
          checked ? "translate-x-5" : "translate-x-0",
          "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
        )}
      >
        <span
          className={classNames(
            checked
              ? "opacity-0 ease-out duration-100"
              : "opacity-100 ease-in duration-200",
            "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
          )}
          aria-hidden="true"
        >
          <svg
            className="w-3 h-3 text-gray-400"
            fill="none"
            viewBox="0 0 12 12"
          >
            <path
              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
        <span
          className={classNames(
            checked
              ? "opacity-100 ease-in duration-200"
              : "opacity-0 ease-out duration-100",
            "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
          )}
          aria-hidden="true"
        >
          <svg
            className={classNames(`w-3 h-3`, {
              "text-blue-600": !disabled,
              "text-gray-600": disabled
            })}
            fill="currentColor"
            viewBox="0 0 12 12"
          >
            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
          </svg>
        </span>
      </span>
    </Switch>
  );
}

export const selectClass =
  "mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-900 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6";

function Select({
  selected,
  value,
  onChange,
  options
}: {
  selected: string;
  value: string | null;
  onChange: (value: string) => void;
  options: { text: string; value: number; logo?: string }[];
}) {
  return (
    <Listbox value={value} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="relative mt-2">
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
              <span className="block truncate">{selected}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <FontAwesomeIcon icon="chevron-down" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-blue-600" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={option.value}
                  >
                    {({ selected, active }) => (
                      <>
                        <div className="flex items-center">
                          {option.logo ? (
                            <img className="mr-2 w-28" src={option.logo} />
                          ) : null}
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "block truncate"
                            )}
                          >
                            {option.text}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-blue-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <FontAwesomeIcon icon={["far", "check-circle"]} />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export { FormField, TextArea, Toggle, Select };
