import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import {
  Blocker,
  BlockerFunction,
  useBlocker,
  useNavigate,
  useParams,
} from "react-router-dom";
import Alert from "../../components/Alert";
import Drawer from "../../components/Drawer";
import { BaseLivlyApiResponse } from "../../types/Base";
import { CreateChannelResponse } from "../../types/Maintenance";
import { BASE_API_URL } from "../../utils/constants";

import "stream-chat-react/dist/css/index.css";
import {
  trackExitMaintenanceAIChat,
  trackOpenAIMaintenanceChat,
  trackSendMessageToAI,
} from "../../utils/analytics";
import ConfirmNavigation from "./confirm-navigation";
import MaintenanceAIChat from "@/components/MaintenanceAIChat";
import { motion } from "framer-motion";
import { Spinner } from "@/components/Spinner";

const getAIChat = async (leaseId: string) => {
  const { data } = await axios.post<
    BaseLivlyApiResponse<CreateChannelResponse>
  >(`${BASE_API_URL}/resident/ai/chat/leases/${leaseId}/maintenance`);

  return data.Data;
};

const ticketChatQuery = (leaseId: string) => ({
  queryKey: ["maintenance", "ai", "chat", leaseId],
  queryFn: async () => getAIChat(leaseId),
});

export default function TicketAIChatPage() {
  const queryClient = useQueryClient();
  const params = useParams<{ leaseId: string }>();
  const [open, setOpen] = useState(false);
  const { data, isLoading, isFetching, isError } = useQuery({
    ...ticketChatQuery(params.leaseId!),
    retry: false,
    refetchOnWindowFocus: false,
  });
  let [value, setValue] = useState(false);
  let shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      return (
        value !== false && currentLocation.pathname !== nextLocation.pathname
      );
    },
    [value]
  );
  let blocker = useBlocker(shouldBlock);

  const navigate = useNavigate();

  useEffect(() => {
    trackOpenAIMaintenanceChat();
    setOpen(true);
  }, []);

  // Reset the blocker if the user cleans the form
  useEffect(() => {
    if (blocker.state === "blocked" && value === false) {
      blocker.reset();
    }
  }, [blocker, value]);

  const onClose = () => {
    trackExitMaintenanceAIChat();
    navigate(-1);
  };

  const onConfirmClose = () => {
    setValue(false);

    queryClient.invalidateQueries(["maintenance", "ai", "chat"]);
  };

  return (
    <Drawer open={open} onClose={onClose} title="Chat">
      <>
        {isError ? (
          <Alert message="There was an error loading chat" />
        ) : isLoading || isFetching ? (
          <div className="flex h-full items-center justify-center">
            <Spinner color="livly" size="xl" />
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0, translateY: 20 }}
            animate={{ opacity: 1, translateY: 0 }}
          >
            <MaintenanceAIChat
              id={data.id}
              onInitiateChat={() => {
                setValue(true);
                trackSendMessageToAI();
              }}
              setDisableBlock={() => setValue(false)}
            />
          </motion.div>
        )}
        {blocker ? (
          <AIConfirmNavigation
            blocker={blocker}
            onConfirmClose={onConfirmClose}
          />
        ) : null}
      </>
    </Drawer>
  );
}

function AIConfirmNavigation({
  blocker,
  onConfirmClose,
}: {
  blocker: Blocker;
  onConfirmClose: () => void;
}) {
  const [open, setOpen] = useState(true);

  if (blocker.state === "blocked") {
    return (
      <ConfirmNavigation
        open={open}
        onClose={() => {
          setOpen(false);
          onConfirmClose();
        }}
        blocker={blocker}
      />
    );
  }

  return null;
}
