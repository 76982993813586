import Payment from "payment";

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value: string) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 16)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value: string, cardNumber: string) {
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (cardNumber) {
    const issuer = Payment.fns.cardType(cardNumber);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value: string) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function formatFormData(data: Record<string, string>) {
  return Object.keys(data).map((d) => `${d}: ${data[d]}`);
}

const ERROR_TEXT__INVALID_EXPIRY_DATE = "Expiry date is invalid";
const ERROR_TEXT__MONTH_OUT_OF_RANGE = "Expiry month must be between 01 and 12";
const ERROR_TEXT__YEAR_OUT_OF_RANGE = "Expiry year cannot be in the past";
const ERROR_TEXT__DATE_OUT_OF_RANGE = "Expiry date cannot be in the past";

const EXPIRY_DATE_REGEX = /^(\d{2})\/(\d{4}|\d{2})$/;
const MONTH_REGEX = /(0[1-9]|1[0-2])/;

export const isExpiryInvalid = (expiryDate: string) => {
  const splitDate = expiryDate.split("/");
  if (!EXPIRY_DATE_REGEX.test(expiryDate)) {
    return ERROR_TEXT__INVALID_EXPIRY_DATE;
  }

  const expiryMonth = splitDate[0];
  if (!MONTH_REGEX.test(expiryMonth)) {
    return ERROR_TEXT__MONTH_OUT_OF_RANGE;
  }

  const expiryYear = splitDate[1];
  let date = new Date();
  let currentYear = date.getFullYear();
  let currentMonth = date.getMonth() + 1;
  currentYear = parseInt(
    expiryYear.length === 4
      ? currentYear.toString()
      : currentYear.toString().substr(-2),
    10
  );
  if (currentYear > parseInt(expiryYear, 10)) {
    return ERROR_TEXT__YEAR_OUT_OF_RANGE;
  }

  if (
    parseInt(expiryYear, 10) === currentYear &&
    parseInt(expiryMonth, 10) < currentMonth
  ) {
    return ERROR_TEXT__DATE_OUT_OF_RANGE;
  }

  return false;
};
export const DEFAULT_CVC_LENGTH = 3;
export const DEFAULT_ZIP_LENGTH = 5;
export const DEFAULT_CARD_FORMAT = /(\d{1,4})/g;
export const CARD_TYPES = [
  {
    type: "amex",
    format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
    startPattern: /^3[47]/,
    maxCardNumberLength: 15,
    cvcLength: 4,
  },
  {
    type: "dankort",
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^5019/,
    maxCardNumberLength: 16,
    cvcLength: DEFAULT_CVC_LENGTH,
  },
  {
    type: "hipercard",
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^(384100|384140|384160|606282|637095|637568|60(?!11))/,
    maxCardNumberLength: 19,
    cvcLength: DEFAULT_CVC_LENGTH,
  },
  {
    type: "dinersclub",
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^(36|38|30[0-5])/,
    maxCardNumberLength: 14,
    cvcLength: DEFAULT_CVC_LENGTH,
  },
  {
    type: "discover",
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^(6011|65|64[4-9]|622)/,
    maxCardNumberLength: 16,
    cvcLength: DEFAULT_CVC_LENGTH,
  },
  {
    type: "jcb",
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^35/,
    maxCardNumberLength: 16,
    cvcLength: DEFAULT_CVC_LENGTH,
  },
  {
    type: "laser",
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^(6706|6771|6709)/,
    maxCardNumberLength: 19,
    cvcLength: DEFAULT_CVC_LENGTH,
  },
  {
    type: "maestro",
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^(5018|5020|5038|6304|6703|6708|6759|676[1-3])/,
    maxCardNumberLength: 19,
    cvcLength: DEFAULT_CVC_LENGTH,
  },
  {
    type: "mastercard",
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
    maxCardNumberLength: 16,
    cvcLength: DEFAULT_CVC_LENGTH,
  },
  {
    type: "unionpay",
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^62/,
    maxCardNumberLength: 19,
    cvcLength: DEFAULT_CVC_LENGTH,
    luhn: false,
  },
  {
    type: "visaelectron",
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^4(026|17500|405|508|844|91[37])/,
    maxCardNumberLength: 16,
    cvcLength: DEFAULT_CVC_LENGTH,
  },
  {
    type: "elo",
    format: DEFAULT_CARD_FORMAT,
    startPattern:
      /^(4011(78|79)|43(1274|8935)|45(1416|7393|763(1|2))|50(4175|6699|67[0-7][0-9]|9000)|627780|63(6297|6368)|650(03([^4])|04([0-9])|05(0|1)|4(0[5-9]|3[0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8])|9([2-6][0-9]|7[0-8])|541|700|720|901)|651652|655000|655021)/,
    maxCardNumberLength: 16,
    cvcLength: DEFAULT_CVC_LENGTH,
  },
  {
    type: "visa",
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^4/,
    maxCardNumberLength: 19,
    cvcLength: DEFAULT_CVC_LENGTH,
  },
];

export const getCardTypeByType = (type: string) =>
  CARD_TYPES.filter((cardType) => cardType.type === type)[0];

export const hasCVCReachedMaxLength = (
  type: string,
  currentValueLength: number
) => {
  const cardType = getCardTypeByType(type);
  if (!cardType) {
    return currentValueLength >= DEFAULT_CVC_LENGTH;
  }
  return currentValueLength >= cardType.cvcLength;
};
export const getCVCMaxLength = (type: string) => {
  const cardType = getCardTypeByType(type);
  if (!cardType) {
    return DEFAULT_CVC_LENGTH;
  }
  return cardType.cvcLength;
};

export const formatCvc = (cvc: string) => {
  return (cvc.match(/\d+/g) || []).join("");
};

export const isHighlighted = () => window?.getSelection()?.type === "Caret";
