import moment from "moment-timezone";
import { Link, useParams } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Lease, LeaseStatusEnum, User } from "../types/User";
import { AnimatePresence, motion } from "framer-motion";
import { Badge } from "./Badge";

function LeaseDate({
  startDate,
  endDate,
}: {
  startDate: string | undefined;
  endDate: string | undefined;
}) {
  if (startDate && endDate) {
    if (moment(startDate).isAfter()) {
      return (
        <div className="mt-1">
          <p className="text-xs text-gray-500">
            Starts {moment(startDate).format("MM/DD/YYYY")} (
            {moment(startDate).fromNow()})
          </p>
        </div>
      );
    } else if (moment(startDate).isBefore() && moment(endDate).isBefore()) {
      return (
        <div className="mt-1">
          <p className="text-xs text-red-500">
            Expired {moment(endDate).format("MM/DD/YYYY")} (
            {moment(endDate).fromNow()})
          </p>
        </div>
      );
    }
  }

  return null;
}

export function BuildingLeaseMenu({ user }: { user: User }) {
  const params = useParams<{ leaseId: string }>();
  const selectedLease = user.leases.find(
    (l) => l.propertyUnitLeaseId === Number(params.leaseId)
  );

  const orderedLeases = orderLeases(user.leases);

  return (
    <div className="px-4">
      <div className="pb-4 mb-4 border-b border-gray-300">
        <div className="flex items-center justify-between">
          {selectedLease?.logoUrl ? (
            <img
              className="w-12 h-12 rounded-full "
              src={selectedLease?.logoUrl}
              alt={selectedLease?.building}
            />
          ) : (
            <div className="flex items-center justify-center w-12 h-12 text-gray-900 rounded-full bg-slate-200">
              <FontAwesomeIcon icon="building" />
            </div>
          )}
          {user.leases.length > 1 && (
            <Popover as="div">
              <Popover.Button
                title="Switch Leases"
                aria-label="Switch leases"
                className="flex items-center justify-center w-8 h-8 border rounded-full border-slate-200 hover:bg-slate-50"
              >
                <FontAwesomeIcon icon="ellipsis-h" size="xs" />
              </Popover.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-50 translate-x-3/4"
                enterTo="transform opacity-100 scale-100 translate-x-0"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100 translate-x-0"
                leaveTo="transform opacity-0 scale-50 translate-x-3/4"
              >
                <Popover.Panel className="absolute top-0 z-10 w-screen max-w-xs px-4 mt-3 ml-3 -right-2 translate-x-full transform sm:px-0">
                  <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="relative bg-white grid gap-8 p-7 h-auto max-h-screen overflow-auto">
                      {orderedLeases.map((lease) => {
                        let statusClasses = "bg-blue-500 text-white";

                        if (lease.leaseStatusId === LeaseStatusEnum.Applicant) {
                          statusClasses = "bg-gray-300 text-gray-900";
                        } else if (
                          lease.leaseStatusId === LeaseStatusEnum.Past
                        ) {
                          statusClasses = "bg-red-500 text-white";
                        }

                        const buildingInitials = lease.building
                          .split(" ")
                          .map((word) => word[0])
                          .slice(0, 2);

                        return (
                          <Popover.Button
                            as={Link}
                            key={lease.propertyUnitLeaseId}
                            to={`/lease/${lease.propertyUnitLeaseId}/home`}
                            className="flex items-center p-2 -m-3 rounded-lg transition duration-150 ease-in-out hover:bg-gray-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          >
                            <div className="flex items-center justify-center w-12 h-12 text-white shrink-0 sm:h-12 sm:w-12">
                              {lease.logoUrl ? (
                                <img
                                  src={lease.logoUrl}
                                  alt={lease.building}
                                  className="w-12 h-12 rounded-full"
                                />
                              ) : (
                                <div className="w-12 h-12 rounded-full bg-gray-200 text-gray-700 flex items-center justify-center">
                                  {buildingInitials}
                                </div>
                              )}
                            </div>
                            <div className="flex-1 ml-4 text-left">
                              <p className="text-sm font-medium text-gray-900">
                                {lease.building}
                              </p>
                              <p className="text-sm text-gray-500">
                                Unit {lease.unit}
                              </p>
                              <span
                                className={`rounded-md px-2 text-xs py-1 mt-1 inline-block ${statusClasses}`}
                              >
                                {lease.status === "Applicant"
                                  ? "Future"
                                  : lease.status}
                              </span>
                              <LeaseDate
                                startDate={lease.leaseStartDate}
                                endDate={lease.leaseEndDate}
                              />
                            </div>
                            {lease.propertyUnitLeaseId ===
                              selectedLease?.propertyUnitLeaseId && (
                              <FontAwesomeIcon
                                icon="check"
                                className="text-red-400"
                              />
                            )}
                          </Popover.Button>
                        );
                      })}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
          )}
        </div>
        <p className="mt-4 text-sm font-bold">{selectedLease?.building}</p>
        <p className="text-xs text-gray-500">Unit {selectedLease?.unit}</p>
      </div>
    </div>
  );
}

export function MobileBuildingLeaseMenu({ user }: { user: User }) {
  const params = useParams<{ leaseId: string }>();
  const selectedLease = user.leases.find(
    (l) => l.propertyUnitLeaseId === Number(params.leaseId)
  );

  const orderedLeases = orderLeases(user.leases);

  if (user.leases.length === 1) {
    return (
      <div className="px-4">
        <div className="pb-4 mb-4 border-b border-gray-300">
          <div className="flex items-center justify-between">
            {selectedLease?.logoUrl ? (
              <img
                className="w-12 h-12 rounded-full "
                src={selectedLease?.logoUrl}
                alt={selectedLease?.building}
              />
            ) : (
              <div className="flex items-center justify-center w-12 h-12 text-gray-900 rounded-full bg-slate-200">
                <FontAwesomeIcon icon="building" />
              </div>
            )}
          </div>
          <p className="mt-4 text-sm font-bold">{selectedLease?.building}</p>
          <p className="text-xs text-gray-500">Unit {selectedLease?.unit}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="px-4">
      <div className="pb-4 mb-4 border-b border-gray-300">
        <Popover className="w-full lg:hidden">
          {({ open }) => (
            <>
              <Popover.Button
                className="w-full relative z-10 -m-2 inline-flex items-center rounded-lg stroke-gray-900 p-2 hover:bg-gray-200/50 hover:stroke-gray-600 active:stroke-gray-900 [&:not(:focus-visible)]:focus:outline-none"
                aria-label="Toggle site navigation"
              >
                {({ open }) => (
                  <div className="text-left">
                    <div className="flex items-center justify-between">
                      {selectedLease?.logoUrl ? (
                        <img
                          className="w-12 h-12 rounded-full "
                          src={selectedLease?.logoUrl}
                          alt={selectedLease?.building}
                        />
                      ) : (
                        <div className="flex items-center justify-center w-12 h-12 text-gray-900 rounded-full bg-slate-200">
                          <FontAwesomeIcon icon="building" />
                        </div>
                      )}
                    </div>
                    <p className="mt-4 text-sm font-bold">
                      {selectedLease?.building}
                    </p>
                    <p className="text-xs text-gray-500">
                      Unit {selectedLease?.unit}
                    </p>
                  </div>
                )}
              </Popover.Button>
              <AnimatePresence initial={false}>
                {open && (
                  <>
                    <Popover.Overlay
                      static
                      as={motion.div}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                    />
                    <Popover.Panel
                      static
                      as={motion.div}
                      initial={{ opacity: 0, y: -32 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{
                        opacity: 0,
                        y: -32,
                        transition: { duration: 0.2 },
                      }}
                      className="absolute inset-x-0 top-0 z-0 px-2 pt-40 pb-6 shadow-2xl origin-top rounded-t-2xl bg-gray-50 shadow-gray-900/20"
                    >
                      <div className="relative grid gap-8">
                        {orderedLeases.map((lease) => {
                          const buildingInitials = lease.building
                            .split(" ")
                            .map((word) => word[0])
                            .slice(0, 2);

                          return (
                            <Popover.Button
                              as={Link}
                              key={lease.propertyUnitLeaseId}
                              to={`/lease/${lease.propertyUnitLeaseId}/home`}
                              className="flex items-center p-2 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50  hover:bg-gray-200/50 hover:stroke-gray-600"
                            >
                              <div className="flex items-center justify-center w-12 h-12 text-white shrink-0 sm:h-12 sm:w-12">
                                {lease.logoUrl ? (
                                  <img
                                    src={lease.logoUrl}
                                    alt={lease.building}
                                    className="w-12 h-12 rounded-full"
                                  />
                                ) : (
                                  <div className="w-12 h-12 rounded-full flex items-center justify-center bg-gray-200 text-gray-700">
                                    {buildingInitials}
                                  </div>
                                )}
                              </div>
                              <div className="flex-1 ml-4 text-left">
                                <p className="text-sm font-medium text-gray-900">
                                  {lease.building}
                                </p>
                                <p className="text-sm text-gray-500">
                                  Unit {lease.unit}
                                </p>
                                <Badge
                                  type={
                                    [
                                      LeaseStatusEnum.Current,
                                      LeaseStatusEnum.Applicant,
                                      LeaseStatusEnum.Future,
                                    ].includes(lease.leaseStatusId)
                                      ? "info"
                                      : "danger"
                                  }
                                >
                                  {lease.status === "Applicant"
                                    ? "Future"
                                    : lease.status}
                                </Badge>
                                <LeaseDate
                                  startDate={lease.leaseStartDate}
                                  endDate={lease.leaseEndDate}
                                />
                              </div>
                              {lease.propertyUnitLeaseId ===
                                selectedLease?.propertyUnitLeaseId && (
                                <FontAwesomeIcon
                                  icon="check"
                                  className="text-red-400"
                                />
                              )}
                            </Popover.Button>
                          );
                        })}
                      </div>
                    </Popover.Panel>
                  </>
                )}
              </AnimatePresence>
            </>
          )}
        </Popover>
      </div>
    </div>
  );
}

export const orderLeases = (leases: Lease[]) => {
  let allLeases: Lease[] = [];
  let currentLeases: Lease[] = [];
  let futureLeases: Lease[] = [];
  let pastLeases: Lease[] = [];

  leases.forEach((lease: Lease) => {
    const start = lease.leaseStartDate;
    const end = lease.leaseEndDate;
    if (lease.leaseStartDate && lease.leaseEndDate) {
      if (moment(start).isAfter()) {
        // Future
        return futureLeases.push(lease);
      } else if (moment(start).isBefore() && moment(end).isBefore()) {
        // Past
        return pastLeases.push(lease);
      } else if (moment(start).isBefore() && moment(end).isAfter()) {
        // Current
        return currentLeases.push(lease);
      }
    }
    return null;
  });

  currentLeases = currentLeases.sort((a, b) =>
    a.leaseStartDate < b.leaseStartDate ? -1 : 1
  );
  futureLeases = futureLeases.sort((a, b) =>
    a.leaseStartDate < b.leaseStartDate ? -1 : 1
  );
  pastLeases = pastLeases.sort((a, b) =>
    a.leaseStartDate < b.leaseStartDate ? -1 : 1
  );
  allLeases = currentLeases.concat(futureLeases, pastLeases);

  return allLeases;
};
