import { Button } from "@/components/Button";
import Layout from "@/components/Layout";
import { CartContext } from "@/context/CartProvider";
import { CartItemEnum } from "@/types/Cart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import numeral from "numeral";
import { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { Navigate, useNavigate, useOutletContext } from "react-router-dom";

export default function ChecklistCheckoutAmount() {
  const navigate = useNavigate();
  const { cartItems, setCartItem } = useOutletContext<CartContext>();
  const rentCartItem = cartItems.find((ci) => ci.type === CartItemEnum.RENT);
  const [paymentType, setPaymentType] = useState<"fixed" | "full">(
    rentCartItem?.paymentAmount ? "fixed" : "full"
  );
  const [paymentAmount, setPaymentAmount] = useState<number>(0);

  const onSelectFullBalance = () => {
    setPaymentType("full");
    setPaymentAmount(0);
  };

  const onSelectFixedBalance = () => {
    setPaymentType("fixed");
  };

  const onConfirm = () => {
    if (rentCartItem) {
      setCartItem({
        ...rentCartItem,
        paymentAmount,
      });

      navigate("../cart/checkout");
    }
  };

  if (!rentCartItem) {
    return <Navigate to="../cart/checkout" />;
  }

  let error = "";
  if (paymentAmount > rentCartItem.cost) {
    error = "Payment amount cannot exceed outstanding balance";
  }

  return (
    <Layout
      title="Payment Amount"
      back={{ label: "Checkout", to: "../cart/checkout" }}
    >
      <div>
        <label onClick={onSelectFullBalance}>
          <div className="relative flex items-center justify-between p-4 mb-6 border border-gray-200 rounded-lg">
            <div>
              <p>Full balance</p>
              <p>{numeral(rentCartItem?.cost).format("$0,0[.]00")}</p>
            </div>
            {paymentType === "full" && (
              <FontAwesomeIcon icon={["far", "check-circle"]} />
            )}
          </div>
        </label>
      </div>
      <div>
        <label onClick={onSelectFixedBalance}>
          <div className="relative flex items-center justify-between p-4 mb-6 border border-gray-200 rounded-lg">
            <div>
              <p>Fixed balance</p>
              <div className="relative flex items-center justify-between mt-1">
                <div className="absolute inset-y-0 left-0 flex items-center pl-1 pointer-events-none">
                  <span className="text-gray-500 ">$</span>
                </div>
                <CurrencyInput
                  id="amount"
                  name="amount"
                  decimalsLimit={2}
                  className="w-full pl-4 pr-6 bg-white border-none outline-none focus:ring-0"
                  placeholder="0.00"
                  value={paymentAmount ?? 0}
                  allowNegativeValue={false}
                  onValueChange={(value) =>
                    setPaymentAmount(Number(value ?? 0))
                  }
                  aria-describedby="price-currency"
                />
              </div>
              {error && <p className="text-xs text-red-500">{error}</p>}
            </div>
            {paymentType === "fixed" && (
              <FontAwesomeIcon icon={["far", "check-circle"]} />
            )}
          </div>
        </label>
      </div>
      <div className="text-sm text-gray-600">
        <span className="font-bold">Full statement balance</span> — This is the
        sum of your previous balance, payments, other credits, balances, and
        fees. It doesn't reflect any payments or new transactions since your
        statement date.
      </div>
      <div className="mt-4 text-sm text-gray-600">
        <span className="font-bold">Fixed amount</span> — This is the amount you
        choose to pay each month. Note: If it's ever less than the minimum due,
        you'll have to make an additional payment.
      </div>
      <div className="fixed bottom-0 left-0 right-0 flex flex-col items-stretch m-4 bg-white border border-gray-100 rounded-lg shadow-lg md:flex-row md:justify-between md:items-baseline md:left-64 drop-shadow-lg">
        <div className="flex items-center justify-end w-full p-4 border-t border-gray-200 md:border-none">
          <Button
            color="black"
            className="flex items-center w-full gap-2 md:w-auto"
            disabled={
              error !== "" || (paymentAmount === 0 && paymentType === "fixed")
            }
            onClick={onConfirm}
          >
            Set Amount
          </Button>
        </div>
      </div>
    </Layout>
  );
}
