import { NavLink, Outlet, Route, useLocation } from "react-router-dom";
import Layout from "../components/Layout";
import useLivlyUser from "../context/UserProvider";
import { BuildingServiceType, ServiceTypeEnum } from "@/types/Building";
import classNames from "classnames";

function FeedbackPage() {
  const { user } = useLivlyUser();
  let location = useLocation() as { state: { background: string } };
  let background = location.state && location.state.background;
  const { pollsEnabled, feedbackEnabled } = getIsFeedbackTabsVisible(
    user.building.serviceTypes ?? []
  );
  const isFeedbackTabsVisible = pollsEnabled && feedbackEnabled;

  return (
    <Layout title="My Feedback">
      {isFeedbackTabsVisible && (
        <div className="flex text-sm">
          <NavLink
            to="reviews"
            end
            className={({ isActive }) =>
              classNames(
                "px-3 py-2 flex-1 text-center sm:flex-none sm:text-left",
                {
                  "rounded-full bg-[#FAEDE4]": isActive,
                }
              )
            }
          >
            Reviews and Feedback
          </NavLink>
          <NavLink
            to="polls"
            className={({ isActive }) =>
              classNames(
                "px-3 py-2 flex-1 text-center sm:flex-none sm:text-left",
                {
                  "rounded-full bg-[#FAEDE4]": isActive,
                }
              )
            }
          >
            Polls
          </NavLink>
        </div>
      )}
      <Outlet />
    </Layout>
  );
}

export { FeedbackPage };

export function getIsFeedbackTabsVisible(serviceTypes: BuildingServiceType[]) {
  const residentFeedbackService = serviceTypes.find(
    (st) => st.serviceType === ServiceTypeEnum.ResidentFeedback
  );

  const residentPolls = serviceTypes.find(
    (st) => st.serviceType === ServiceTypeEnum.ResidentPolls
  );

  return {
    pollsEnabled: residentPolls?.enabled ?? false,

    feedbackEnabled: residentFeedbackService?.enabled ?? false,
  };
}
