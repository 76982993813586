import axios from "axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BaseLivlyApiResponse } from "../types/Base";
import { BASE_API_URL } from "../utils/constants";
import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import Layout from "../components/Layout";
import toLocalTime from "../utils/toLocalTime";
import { Spinner } from "../components/Spinner";
import useLivlyUser from "../context/UserProvider";

type FitnessEvent = {
  id: number;
  name: string;
  location: string;
  startTime: string;
  stopTime: string;
  duration: number;
  imageUrl: string | null;
  provider: {
    userId: number;
    firstName: string;
    lastName: string;
  } | null;
  isAttending: boolean;
  property: {
    propertyId: number;
    name: string;
    timezone: string;
    latitude: number;
    longitude: number;
  } | null;
};

async function getUpcomingFitnessEvents(propertyId: number) {
  const { data } = await axios.get<BaseLivlyApiResponse<FitnessEvent[]>>(
    `${BASE_API_URL}/resident/fitness/property/${propertyId}/upcoming`
  );

  return data.Data;
}

export const fitnessQuery = (propertyId: number, leaseId: number) => ({
  queryKey: ["fitness", propertyId, leaseId],
  queryFn: async () => getUpcomingFitnessEvents(propertyId),
});

function FitnessPage() {
  const { user } = useLivlyUser();

  const { data, isLoading } = useQuery({
    ...fitnessQuery(user.propertyId, user.propertyUnitLeaseId),
  });

  return (
    <Layout title="Fitness">
      {isLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <div className="lg:grid lg:grid-cols-3 lg:gap-4">
          {data?.map((event) => {
            const providerName = `${event.provider?.firstName} ${event.provider?.lastName}`;

            return (
              <div key={event.id} className="flex flex-col">
                <Link
                  to={event.id.toString()}
                  className="flex flex-row flex-1 mb-4 shadow-none text-inherit lg:shadow-md lg:flex-col lg:rounded-xl lg:border-gray-400 lg:hover:bg-gray-50"
                >
                  {event.imageUrl ? (
                    <img
                      className="flex-shrink-0 object-contain w-24 lg:w-full lg:h-36 lg:object-cover lg:rounded-tl-xl lg:rounded-tr-xl"
                      src={event.imageUrl}
                      alt={event.name}
                    />
                  ) : (
                    <div className="flex items-center justify-center flex-shrink-0 w-24 bg-gray-200 lg:w-full lg:h-36 lg:rounded-tl-xl lg:rounded-tr-xl">
                      <FontAwesomeIcon
                        icon="image"
                        size="2xl"
                        className="text-gray-400"
                      />
                    </div>
                  )}
                  <div className="flex flex-col justify-between flex-1 p-4 ml-3 border-b border-gray-400 lg:border-none lg:ml-0">
                    <div className="flex justify-between">
                      <div>
                        <p>{event.name}</p>
                        {event.provider && (
                          <p className="text-sm font-light">
                            with {providerName}
                          </p>
                        )}
                      </div>
                      {event.isAttending && (
                        <FontAwesomeIcon
                          icon={["far", "check-circle"]}
                          className="text-green-400"
                        />
                      )}
                    </div>
                    <div>
                      <div className="flex items-center gap-2 mt-3">
                        <FontAwesomeIcon icon={["far", "calendar"]} />
                        <p className="text-sm text-gray-500">
                          {toLocalTime(event.startTime).format("ddd	MMM D")}
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <FontAwesomeIcon icon={["far", "clock"]} />
                        <p className="text-sm text-gray-500">
                          {toLocalTime(event.startTime).format("h:mm A")}
                          {" - "}
                          {toLocalTime(event.stopTime).format("h:mm A")}
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </Layout>
  );
}

export { FitnessPage };
