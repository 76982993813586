import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { PaymentAccount } from "../types/User";
import { BASE_API_URL } from "../utils/constants";
import { Button } from "./Button";
import { Spinner } from "./Spinner";
import useLivlyUser from "../context/UserProvider";

async function setDefaultPaymentMethod(
  userId: number,
  livlyUserPaymentAccountId: number
) {
  const result = await axios.post(
    `${BASE_API_URL}/livly/payment/default/${userId}`,
    { livlyUserPaymentAccountId }
  );

  return result.data;
}

function PaymentAccountItem({
  account,
  isEditMode,
  disableDelete = false,
  isDefault,
  onSelect,
}: {
  account: PaymentAccount;
  isEditMode: boolean;
  disableDelete?: boolean;
  isDefault: boolean;
  onSelect?: () => void;
}) {
  const queryClient = useQueryClient();
  const { user } = useLivlyUser();
  const isPaymentMethodVerificationNeeded =
    account.isVerified !== null && !account.isVerified;
  const { mutate, isLoading } = useMutation(() =>
    setDefaultPaymentMethod(user.userId, account.id)
  );

  const onSelectForDefault = () => {
    mutate(undefined, {
      onSuccess() {
        queryClient.invalidateQueries(["payment-accounts"]);
      },
    });
  };

  return (
    <li
      className={classNames(
        "bg-white p-4 rounded-md flex flex-col w-full relative",
        { "pl-0": isEditMode }
      )}
    >
      <div className="md:hidden">
        {!isEditMode && !isPaymentMethodVerificationNeeded && (
          <button
            className="absolute inset-0"
            type="submit"
            onClick={onSelectForDefault}
          />
        )}
      </div>
      <div className="flex flex-1">
        {isEditMode &&
          (isDefault ? (
            <span className="flex items-center gap-2 p-4 mr-2 text-gray-500 rounded-lg">
              <FontAwesomeIcon icon={["fas", "trash-can"]} />
            </span>
          ) : (
            <Link to={`${account.id}/delete`}>
              <button className="flex items-center gap-2 p-4 mr-2 text-red-400 rounded-lg hover:bg-gray-100">
                <FontAwesomeIcon icon={["fas", "trash-can"]} />
              </button>
            </Link>
          ))}
        <img
          src={account.brandImageUrl}
          alt={account.brand}
          className="w-[60px] h-[40px] md:max-w-14 object-cover mt-[6px]"
        />
        <div className="flex-1 ml-4">
          <p className="text-lg font-medium">{account.brand}</p>
          <p className="text-sm font-light">{account.description}</p>
        </div>
        {!isEditMode && (
          <>
            {!isDefault && !disableDelete && (
              <div className="hidden md:block">
                <Link to={`${account.id}/delete`}>
                  <button className="flex items-center gap-2">
                    <span className="hidden md:block text-sm underline">
                      Delete
                    </span>
                  </button>
                </Link>
              </div>
            )}
            {account.isVerified !== null && !account.isVerified && (
              <div className="flex items-center md:hidden">
                <Link
                  to={`${account.id}/verify`}
                  state={{ account }}
                  className="text-sm"
                >
                  Verify
                </Link>
              </div>
            )}
            {isDefault && (
              <div className="flex items-center md:hidden">
                <FontAwesomeIcon icon="check" className="text-red-300" />
              </div>
            )}
            {isLoading && (
              <div className="flex items-center md:hidden">
                <Spinner color="livly" />
              </div>
            )}
          </>
        )}
      </div>
      <div className="hidden md:block">
        {isDefault ? (
          <div className="flex items-center gap-2 mt-6 text-green-600">
            <FontAwesomeIcon icon={["fas", "check"]} />
            <span className="text-sm font-light text-green-600">
              Default payment method
            </span>
          </div>
        ) : account.isVerified !== null && !account.isVerified ? (
          <div className="mt-6">
            <Link
              to={`${account.id}/verify`}
              state={{ account }}
              className="text-sm underline"
            >
              Verify
            </Link>
          </div>
        ) : onSelect ? (
          <button
            className="flex items-center gap-2 text-sm font-light"
            disabled={isLoading}
            onClick={onSelect}
          >
            <input type="radio" />
            Set as default payment method
            {isLoading && <Spinner color="livly" />}
          </button>
        ) : (
          <div className="mt-6">
            <button
              type="submit"
              className="flex items-center gap-2 text-sm font-light"
              disabled={isLoading}
              onClick={onSelectForDefault}
            >
              <input type="radio" />
              Set as default payment method
              {isLoading && <Spinner color="livly" />}
            </button>
          </div>
        )}
      </div>
    </li>
  );
}

export { PaymentAccountItem };
