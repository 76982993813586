import axios from "axios";
import moment from "moment-timezone";
import { json, NavLink, redirect, useLoaderData } from "react-router-dom";
import Avatar from "../components/Avatar";
import { BaseLivlyApiResponse } from "../types/Base";
import { User } from "../types/User";
import { BASE_API_URL } from "../utils/constants";

export const loader = async () => {
  try {
    const result = await axios.get<BaseLivlyApiResponse<User>>(
      `${BASE_API_URL}/livly/users/me?v=202404`
    );
    const user = result.data.Data;

    if (user.leases.length === 0) {
      return redirect("/no-access");
    }

    if (user.propertyUnitLeaseId) {
      return redirect(`/lease/${user.propertyUnitLeaseId}`);
    }

    return json({ user });
  } catch (e) {
    return redirect("/no-access");
  }
};

function SelectLeasePage() {
  const data = useLoaderData() as { user: User };

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="p-4 border border-gray-200 rounded-lg">
        <h4>Select lease</h4>
        <ul className="mt-4 divide-y divide-gray-200">
          {data.user.leases.map((lease) => (
            <li className="py-2" key={lease.propertyUnitLeaseId}>
              <NavLink
                to={lease.propertyUnitLeaseId.toString()}
                className="flex w-full p-4 gap-4 rounded-md hover:bg-gray-50"
              >
                <Avatar src={lease.logoUrl} name={lease.building} />
                <div>
                  <p>{lease.building}</p>
                  <p className="text-sm">Unit {lease.unit}</p>
                  <p className="text-sm text-gray-500">
                    Start date:
                    {moment(lease.leaseStartDate).format("M/D/YYYY")}
                  </p>
                </div>
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export { SelectLeasePage };
