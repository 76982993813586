import useLivlyChat from "@/context/ChatProvider";
import {
  trackSendChatMessage,
  trackSkipAIConversation,
  trackTicketCreatedByAI,
  trackViewTicketDetails,
} from "@/utils/analytics";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { StreamChat } from "stream-chat";
import {
  Chat,
  Channel,
  MessageInput,
  MessageList,
  Thread,
  useChannelActionContext,
  MessageToSend,
  Window,
  useChannelStateContext,
  useMessageContext,
  MessageText,
  Avatar,
  MessageTimestamp,
  useComponentContext,
  messageHasAttachments,
} from "stream-chat-react";
import { Button } from "./Button";
import classNames from "classnames";

function MaintenanceAIChat({
  id,
  onInitiateChat,
  setDisableBlock,
}: {
  id: string;
  onInitiateChat?: () => void;
  setDisableBlock?: () => void;
}) {
  const { chatClient } = useLivlyChat();

  if (!chatClient) return null;
  const channel = chatClient.queryChannels({ id: { $eq: id } });

  if (!channel) {
    return <div>channel not found</div>;
  }

  return (
    <Chat client={chatClient}>
      <ChatChannel
        chatClient={chatClient}
        id={id}
        onInitiateChat={onInitiateChat}
        setDisableBlock={setDisableBlock}
      />
    </Chat>
  );
}

async function getChannel(chatClient: StreamChat, id: string) {
  const channel = await chatClient.queryChannels({ id: { $eq: id } });

  return channel[0];
}

function ChatChannel({
  chatClient,
  id,
  onInitiateChat,
  setDisableBlock,
}: {
  chatClient: StreamChat;
  id: string;
  onInitiateChat?: () => void;
  setDisableBlock?: () => void;
}) {
  const { data, isLoading, isError } = useQuery(
    ["maintenance", "chat", "channel", id],
    () => getChannel(chatClient, id)
  );

  if (isLoading) {
    return null;
  }

  if (isError) {
    return <div>error loading channel</div>;
  }

  return (
    <Channel channel={data}>
      <ChannelInner
        onInitiateChat={onInitiateChat}
        setDisableBlock={setDisableBlock}
      />
    </Channel>
  );
}

function ChannelInner({
  onInitiateChat,
  setDisableBlock,
}: {
  onInitiateChat?: () => void;
  setDisableBlock?: () => void;
}) {
  const queryClient = useQueryClient();
  const { sendMessage } = useChannelActionContext();
  const [isMessageSent, setIsMessageSent] = useState(false);
  const channel = useChannelStateContext();

  const overrideSubmitHandler = (message: MessageToSend) => {
    trackSendChatMessage();
    sendMessage(message);
    setIsMessageSent(true);

    if (onInitiateChat) {
      onInitiateChat();
    }
  };

  const lastMessage = channel.messages
    ? channel.messages[channel.messages.length - 1]
    : null;
  const isMaintenanceTicketCreated =
    lastMessage && lastMessage.hasOwnProperty("maintenanceTicketId");

  useEffect(() => {
    if (isMaintenanceTicketCreated) {
      if (setDisableBlock) {
        setDisableBlock();
      }
      trackTicketCreatedByAI();
      queryClient.invalidateQueries(["maintenance", "tickets"]);
    }
  }, [isMaintenanceTicketCreated]);

  const showViewTicketButton =
    isMaintenanceTicketCreated && lastMessage.maintenanceTicketId;

  return (
    <div
      className="relative flex flex-col w-full"
      style={{ height: "calc(100vh - 120px)" }}
    >
      <div className="flex justify-end">
        {showViewTicketButton ? (
          <Link
            to={`../${lastMessage.maintenanceTicketId}`}
            relative="route"
            onClick={() => {
              trackViewTicketDetails("AI Chat");
            }}
            replace
          >
            <Button
              size="small"
              color="default"
              className="text-red-400 bg-white normal-case"
            >
              View ticket
            </Button>
          </Link>
        ) : (
          <Link
            to={"../add-ticket"}
            relative="route"
            onClick={trackSkipAIConversation}
            state={{
              existingChannelId: isMessageSent
                ? `maintenance:${channel.channel.id}`
                : null,
            }}
            replace
          >
            <Button
              size="small"
              color="default"
              className="text-red-400 bg-white normal-case"
            >
              Skip
            </Button>
          </Link>
        )}
      </div>
      <Window>
        <MessageList Message={() => <CustomMessage />} />
        <MessageInput overrideSubmitHandler={overrideSubmitHandler} />
      </Window>
      <Thread />
    </div>
  );
}

const CustomMessage = () => {
  const { message, isMyMessage, renderText, handleAction } =
    useMessageContext();
  const { Attachment } = useComponentContext<any>("MessageSimple");
  const hasAttachment = messageHasAttachments(message);

  const rootClassName = classNames(
    "str-chat__message str-chat__message-simple",
    `str-chat__message--${message.type}`,
    `str-chat__message--${message.status}`,
    isMyMessage()
      ? "str-chat__message--me str-chat__message-simple--me"
      : "str-chat__message--other",
    message.text ? "str-chat__message--has-text" : "has-no-text",
    {
      "str-chat__message--has-attachment": hasAttachment,
      "str-chat__message--pinned pinned-message": message.pinned,
      "str-chat__message-send-can-be-retried":
        message?.status === "failed" && message?.errorStatusCode !== 403,
    }
  );

  const isMaintenanceTicketCreated = message.hasOwnProperty(
    "maintenanceTicketId"
  );

  return (
    <div className={rootClassName} key={message.id}>
      {message.user && <Avatar image={message.user?.image} />}
      <div
        className={classNames("str-chat__message-inner")}
        data-testid="message-inner"
      >
        <div className="str-chat__message-bubble">
          {message.attachments?.length && !message.quoted_message ? (
            <Attachment
              actionHandler={handleAction}
              attachments={message.attachments}
            />
          ) : null}
          <MessageText message={message} renderText={renderText} />
        </div>
        <div className="str-chat__message-data str-chat__message-simple-data str-chat__message-metadata">
          {isMaintenanceTicketCreated ? (
            <div>
              <Link
                to={`../${message.maintenanceTicketId}`}
                relative="route"
                onClick={() => {
                  if (isMaintenanceTicketCreated) {
                    trackViewTicketDetails("AI Chat");
                  }
                }}
                replace
              >
                <Button
                  size="small"
                  color="secondary"
                  outlined
                  className="normal-case"
                >
                  View ticket
                </Button>
              </Link>
            </div>
          ) : (
            <>
              {!isMyMessage() && !!message.user && (
                <span className="str-chat__message-simple-name">
                  {message.user.name || message.user.id}
                </span>
              )}
              <MessageTimestamp
                calendar
                customClass="str-chat__message-simple-timestamp"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MaintenanceAIChat;
