import auth0, {
  PasswordlessLoginOptions,
  PasswordlessStartOptions,
} from "auth0-js";
import axios from "axios";
import * as jose from "jose";
import {
  ACCESS_TOKEN_KEY,
  EXPIRES_IN_KEY,
  MOBILE_KEY,
  clearLocalStorage,
} from "./localStorage";

export const AUTHORIZATION_HEADER_KEY = "Authorization";
const ROLES = "https://api.binway.com/roles";
const RoleTypes = {
  Customer: "Customer",
  Admin: "Admin",
  User: "User",
};

class AuthService {
  tokenRenewalTimeout: any;

  constructor() {
    this.scheduleRenewal();

    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (accessToken) {
      axios.defaults.headers.common[
        AUTHORIZATION_HEADER_KEY
      ] = `Bearer ${accessToken}`;
    }
  }

  auth0 = new auth0.WebAuth({
    domain: import.meta.env.VITE_AUTH0_DOMAIN as string,
    clientID: import.meta.env.VITE_AUTH0_CLIENT_ID as string,
    redirectUri: `${window.location.origin}/callback`,
    responseType: "token id_token",
  });

  login({ email, password }: { email: string; password: string }) {
    return new Promise((resolve, reject) => {
      this.auth0.login(
        {
          username: email,
          password,
        },
        (err) => {
          if (err) {
            const error = new Error(err.description || "Error logging in");
            reject(error);
          }

          resolve(true);
        }
      );
    });
  }

  logout = (callback?: () => void) => {
    clearLocalStorage();
    sessionStorage.clear();
    axios.defaults.headers.common[AUTHORIZATION_HEADER_KEY] = undefined;

    if (callback) {
      callback();
    }
  };

  sendCode({ connection, send, ...rest }: PasswordlessStartOptions) {
    return new Promise((resolve, reject) => {
      this.auth0.passwordlessStart(
        {
          connection,
          send,
          ...rest,
        },
        (err, res) => {
          if (err) {
            const error = new Error(err.description || "Error sending code");
            return reject(error);
          }
          return resolve(res);
        }
      );
    });
  }

  loginSms({
    connection,
    verificationCode,
    phoneNumber,
  }: PasswordlessLoginOptions) {
    return new Promise((resolve, reject) => {
      this.auth0.passwordlessLogin(
        {
          connection,
          verificationCode,
          phoneNumber,
        },
        (err, res) => {
          if (err) {
            const error = new Error(err.description || "Error logging in");
            return reject(error);
          }
          return resolve(res);
        }
      );
    });
  }

  forgotPassword({ email }: { email: string }) {
    return new Promise((resolve, reject) => {
      this.auth0.changePassword(
        { connection: "Username-Password-Authentication", email },
        (err) => {
          if (err) {
            const error = new Error(err.description || "Error sending email");
            reject(error);
          }
          resolve(true);
        }
      );
    });
  }

  isAuthenticated = () => {
    // Check whether the current time is past the
    // Access Token's expiry time
    const isFromNativeApp = localStorage.getItem("mobile") === "true";
    if (isFromNativeApp) {
      return true;
    }

    const expiresInString = localStorage.getItem(EXPIRES_IN_KEY);
    if (!expiresInString) {
      return false;
    }

    const expiresAt = JSON.parse(expiresInString);

    return new Date().getTime() < expiresAt;
  };

  handleAuthentication(): Promise<string | any> {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash(async (err, authResult) => {
        if (err) {
          return reject(err);
        }

        if (authResult && authResult.accessToken && authResult.idToken) {
          const decodedToken = jose.decodeJwt(authResult.accessToken);
          const userRoles = decodedToken[ROLES] as string[];
          if (!userRoles.includes(RoleTypes.User)) {
            this.logout();
            window.location.href = `${window.location.origin}/login`;
            return;
          }

          localStorage.setItem(ACCESS_TOKEN_KEY, authResult.accessToken);
          axios.defaults.headers.common[
            AUTHORIZATION_HEADER_KEY
          ] = `Bearer ${authResult.accessToken}`;

          if (authResult.expiresIn) {
            const expiresAt = JSON.stringify(
              authResult.expiresIn * 1000 + new Date().getTime()
            );
            localStorage.setItem(EXPIRES_IN_KEY, expiresAt);
            this.scheduleRenewal();
          }

          resolve(true);
        }
      });
    });
  }

  scheduleRenewal() {
    const mobile = localStorage.getItem(MOBILE_KEY);

    if (mobile) {
      return;
    }

    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);

    if (!accessToken) {
      return;
    }

    const decodedToken = jose.decodeJwt(accessToken);

    if (decodedToken.exp) {
      const expiresAt = decodedToken.exp * 1000;
      const delay = expiresAt - Date.now();

      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewToken();
      }, delay);
    }
  }

  renewToken() {
    this.auth0.checkSession({}, (err, authResult) => {
      if (err) {
        this.logout();
      } else {
        localStorage.setItem(ACCESS_TOKEN_KEY, authResult.accessToken);

        axios.defaults.headers.common[
          AUTHORIZATION_HEADER_KEY
        ] = `Bearer ${authResult.accessToken}`;

        if (authResult.expiresIn) {
          const expiresAt = JSON.stringify(
            authResult.expires_in * 1000 + new Date().getTime()
          );
          localStorage.setItem(EXPIRES_IN_KEY, expiresAt);
        }
      }
    });
  }
}

const auth = new AuthService();

export default auth;
