import { Outlet, useParams } from "react-router-dom";
import { Ticket } from "../../types/Maintenance";
import ViewTicketForm from "../../components/ViewTicketForm";
import Layout from "../../components/Layout";
import { useEffect } from "react";
import axios from "axios";
import { BaseLivlyApiResponse } from "../../types/Base";
import { BASE_API_URL } from "../../utils/constants";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "../../components/Spinner";
import Alert from "../../components/Alert";

async function getTicket(ticketId: string) {
  const result = await axios.get<BaseLivlyApiResponse<Ticket>>(
    `${BASE_API_URL}/livly/maintenanceTicketsResidential/${ticketId}`
  );
  const ticket = result.data.Data;

  return ticket;
}

export const maintenanceTicketQuery = (ticketId: string) => ({
  queryKey: ["maintenance", "ticket", ticketId],
  queryFn: async () => getTicket(ticketId),
});

export default function ViewTicketPageContainer() {
  const params = useParams<{
    leaseId: string;
    ticketId: string;
    propertyId: string;
  }>();

  const { data, isLoading } = useQuery({
    ...maintenanceTicketQuery(params.ticketId!),
  });

  return (
    <Layout
      title={`Ticket Details ${data ? ` - ${data.category.name}` : ""}`}
      back={{
        label: "Maintenance",
        to: `/lease/${params.leaseId}/maintenance/${params.propertyId}`,
      }}
    >
      {isLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : data === undefined ? (
        <Alert variant="danger" message="There was an issue loading ticket" />
      ) : (
        <>
          <ViewTicketForm ticket={data} />
          <Outlet />
        </>
      )}
    </Layout>
  );
}
