import { useState } from "react";
import { Link } from "react-router-dom";
import { PaymentAccount } from "../types/User";
import { Button } from "./Button";
import { Modal } from "./Dialog";

function SelectPaymentAccount({
  selectedPaymentAccountId,
  paymentAccounts,
  onSelect,
  onOpenCallback,
}: {
  selectedPaymentAccountId: number | null;
  paymentAccounts: PaymentAccount[];
  onSelect: (paymentAccountId: number) => void;
  onOpenCallback?: () => void;
}) {
  const [open, setOpen] = useState(false);
  const selectedPaymentMethod = paymentAccounts.find(
    (p) => p.id === selectedPaymentAccountId
  );

  const handleOpen = () => {
    if (onOpenCallback) {
      onOpenCallback();
    }

    setOpen(true);
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <h3>payment type</h3>
        <button className="text-sm underline" onClick={handleOpen}>
          Change
        </button>
      </div>
      <div className="flex flex-1 py-4">
        <img
          src={selectedPaymentMethod?.brandImageUrl}
          alt={selectedPaymentMethod?.brand}
          className="max-w-14 w-auto h-auto  mt-[6px] self-center"
        />
        <div className="flex-1 ml-4">
          <p className="text-lg font-medium">{selectedPaymentMethod?.brand}</p>
          <p className="text-sm font-light">
            {selectedPaymentMethod?.description}
          </p>
        </div>
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title="Select Payment Method"
      >
        <div className="mt-4">
          <ul className="my-6">
            {paymentAccounts.map((p) => (
              <li
                key={p.id}
                className="flex items-center justify-between mt-2 rounded cursor-pointer hover:bg-gray-100"
                onClick={() => {
                  onSelect(p.id);
                  setOpen(false);
                }}
              >
                <div className="flex items-center flex-1 w-full p-2">
                  <img
                    src={p?.brandImageUrl}
                    alt={p?.brand}
                    className="max-w-12 w-auto h-auto object-cover mt-[6px]"
                  />
                  <div className="flex-1 ml-4">
                    <p className="text-lg font-medium">{p?.brand}</p>
                    <p className="text-sm font-light">{p?.description}</p>
                  </div>
                  <input
                    type="radio"
                    defaultChecked={p.id === selectedPaymentAccountId}
                    className="w-4 h-4 text-blue-600 border-gray-300 cursor-pointer focus:ring-blue-500"
                  />
                </div>
              </li>
            ))}
          </ul>
          <div className="mt-4">
            <Link to="../wallet/add">
              <Button color="default">Add new payment method</Button>
            </Link>
          </div>
        </div>
      </Modal>
    </>
  );
}

export { SelectPaymentAccount };
