import { Button } from "@/components/Button";
import useLivlyUser from "@/context/UserProvider";
import React from "react";
import { Link, useLocation } from "react-router-dom";

export const PollThankyou = () => {
  const { user } = useLivlyUser();
  const { pollName = "" } =
    (useLocation().state as {
      pollName: "";
    }) || {};
  return (
    <div className="flex justify-center items-center flex-1 w-full h-screen m-auto flex-col">
      <img className="mb-8" src="/Thxreply.png" alt="Pets" width={300} />
      <p className="text-3xl text-center mb-12">
        Thank you for completing our <br />
        poll: {pollName}
      </p>
      <Link to={`/lease/${user.propertyUnitLeaseId}/home`}>
        <Button className="bg-red-400 w-24">Done</Button>
      </Link>
    </div>
  );
};

export default PollThankyou;
