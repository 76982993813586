import classNames from "classnames";

function Spinner({
  color = "white",
  size = "sm",
}: {
  color?: "white" | "blue" | "livly";
  size?: "sm" | "md" | "lg" | "xl";
}) {
  return (
    <div>
      <svg
        fill="none"
        className={classNames("animate-spin", {
          "text-blue-600": color === "blue",
          "text-white": color === "white",
          "text-[#F9877E]": color === "livly",
          "h-5 w-5": size === "sm",
          "h-6 w-6": size === "md",
          "h-8 w-8": size === "lg",
          "h-24 w-24": size === "xl",
        })}
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          clipRule="evenodd"
          d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </svg>
    </div>
  );
}

export { Spinner };
