import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import PetForm, { PetRequest } from "../../components/PetForm";
import useLivlyUser from "../../context/UserProvider";
import { usePostPet } from "../pets/add-pet";
import { useQueryClient } from "@tanstack/react-query";

export default function ChecklistPetsAddPage() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { user } = useLivlyUser();
  const params = useParams<{ leaseId: string }>();
  const { mutate, isLoading, error } = usePostPet(params.leaseId!, user.userId);

  const onSubmit = (pet: PetRequest) => {
    mutate(
      { ...pet, petType: pet.petType || pet.petTypeRadio },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["lease-pets"]);
          navigate(`../pets`);
        },
      }
    );
  };

  return (
    <Layout back={{ to: -1, label: "My Pets" }} title="Add Pet">
      <div className="max-w-3xl mx-auto">
        <PetForm
          isLoading={isLoading}
          error={error}
          onSubmit={onSubmit}
          returnTo={`../pets-vehicles/${user.propertyId}/pets`}
        />
      </div>
    </Layout>
  );
}
