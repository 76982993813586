import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import { useParams, Link, useOutletContext } from "react-router-dom";
import { Button } from "../../components/Button";
import { ConfirmationModal } from "../../components/Dialog";
import Layout from "../../components/Layout";
import { BASE_API_URL } from "../../utils/constants";
import { BaseLivlyApiResponse } from "../../types/Base";
import { BuildingServiceType, ServiceTypeEnum } from "../../types/Building";
import { Spinner } from "../../components/Spinner";
import useLivlyUser from "../../context/UserProvider";
import useLivlyGuest, {
  getDelegateServiceQuery,
} from "@/context/GuestProvider";

export type GuestResponse = {
  guestId: number;
  companyName: string;
  avatarUri: string | null;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  note: string;
  isBanned: boolean;
  banNote: string;
  type: {
    id: GuestCategory;
    category: number;
    name: string;
    isDeleted: boolean;
  };
  serviceTypes: [
    {
      serviceType: number;
      name: "string";
      enabled: boolean;
      metaData: "string";
      permissionAccessLevel?: string;
    }
  ];
  dateCreated?: Date;
  avatarBase64Image: string | null;
  appPermission?: boolean;
  leaseLivlyUserId: number;
  hasKeyAccess?: boolean;
  inviteConfirmed?: boolean;
  hasJoined?: boolean;
  hadAppPermissionGranted?: boolean;
  accessControl: {
    allowUnitDoors: boolean;
    schedule: {
      days: {
        startTime: number;
        endTime: number;
        day: string;
        disabled: boolean;
      }[];
    };
  } | null;
};

export enum GuestType {
  Business = 1,
  Personal = 2,
}

export enum GuestCategory {
  "None" = 0,
  "PersonalGuest" = 1,
  "DogWalker" = 2,
  "Cleaner" = 3,
  "Delivery" = 4,
  "OtherBusiness" = 5,
}

type GuestDataServiceMeta = {
  guestKeyEnabled: boolean;
  guestLimit: number;
  livlyGuestPassEnabled: boolean;
};

export async function getGuests(leaseId: string, propertyId: number) {
  const result = await axios.get<BaseLivlyApiResponse<GuestResponse[]>>(
    `${BASE_API_URL}/resident/guests/property/${propertyId}/lease/${leaseId}`
  );

  return result.data.Data;
}

function useGetGuestLimit() {
  const { user } = useLivlyUser();
  const { building } = user;

  const { serviceTypes = [] } = building || {};
  const guestData: BuildingServiceType | undefined = serviceTypes.find(
    (service: BuildingServiceType) =>
      service.serviceType === ServiceTypeEnum.MyGuests
  );

  const metaData = JSON.parse(
    String(guestData?.metaData)
  ) as GuestDataServiceMeta;

  return metaData.guestLimit || 0;
}

function useDeleteGuest() {
  const deleteGuest = async (guestId: number) => {
    const result = await axios.delete(
      `${BASE_API_URL}/livly/guests/user/me/${guestId}`
    );

    return result.data.Data;
  };

  return useMutation(deleteGuest);
}

const myGuestsQuery = (leaseId: string, propertyId: number) => ({
  queryKey: ["lease-guests", leaseId],
  queryFn: async () => getGuests(leaseId, propertyId),
});

export default function MyGuestsPage() {
  const { user } = useLivlyUser();
  const { resetGuest } = useLivlyGuest();
  const { isGuestServiceDisabled, showNoAccessDialog } = useOutletContext<{
    isGuestServiceDisabled: boolean;
    showNoAccessDialog: Function;
  }>();

  const params = useParams<{ leaseId: string }>();

  const { data, isLoading } = useQuery({
    ...myGuestsQuery(params.leaseId!, user.propertyId),
  });
  const { isLoading: serviceLoading } = useQuery({
    ...getDelegateServiceQuery(user.propertyId, params.leaseId!),
  });

  const guestLimit = useGetGuestLimit();

  return (
    <Layout title="Guests">
      {isLoading || serviceLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <>
          {data?.length === 0 ? (
            <div className="text-center">
              <p className="text-xl">No guests added yet</p>
              <p className="mt-2 text-sm font-light">
                Any guests added to the lease will appear here
              </p>
              <Link
                to="add"
                onClick={(e) => {
                  if (isGuestServiceDisabled) {
                    e.preventDefault();
                    e.stopPropagation();
                    showNoAccessDialog();
                  }
                }}
              >
                <Button
                  size="small"
                  color="primary"
                  className="mt-4"
                  onClick={() => resetGuest()}
                >
                  Add a guest
                </Button>
              </Link>
            </div>
          ) : (
            <>
              <div className="flex items-center justify-between mt-4">
                {guestLimit > 0 && (
                  <p>
                    <span className="text-red-400 ">{data?.length}</span> out of{" "}
                    {guestLimit} guests
                  </p>
                )}
                {(data ?? []).length < guestLimit ? (
                  <Link
                    to="add"
                    onClick={(e) => {
                      if (isGuestServiceDisabled) {
                        e.preventDefault();
                        e.stopPropagation();
                        showNoAccessDialog();
                      }
                    }}
                  >
                    <Button size="small" onClick={() => resetGuest()}>
                      Add Guest
                    </Button>
                  </Link>
                ) : (
                  <Button size="small" disabled>
                    Add Guest
                  </Button>
                )}
              </div>
              <ul className="mt-4 divide-y divide-gray-200">
                {data?.map((guest) => (
                  <GuestItem
                    key={guest.guestId}
                    guest={guest}
                    leaseId={params.leaseId!}
                    userId={user.userId}
                  />
                ))}
              </ul>
            </>
          )}
        </>
      )}
    </Layout>
  );
}

function GuestItem({
  guest,
  leaseId,
}: {
  guest: GuestResponse;
  leaseId: string;
  userId: number;
}) {
  const { isGuestServiceDisabled, showNoAccessDialog } = useOutletContext<{
    isGuestServiceDisabled: boolean;
    showNoAccessDialog: Function;
  }>();
  const [isOpen, setIsOpen] = useState(false);
  const client = useQueryClient();
  const { mutate, isLoading, isSuccess } = useDeleteGuest();
  const { resetGuest } = useLivlyGuest();
  const onDelete = () => {
    mutate(guest.guestId, {
      onSuccess: () => {
        setIsOpen(false);
        client.invalidateQueries(["lease-guests", leaseId]);
      },
    });
  };

  if (isSuccess) {
    return null;
  }

  return (
    <li className="flex items-center py-4" data-test-id="guest-list-item">
      {guest.isBanned ? (
        <div className="relative flex items-center justify-center w-16 h-16 bg-gray-200 rounded-full">
          <span className="">
            <FontAwesomeIcon
              icon={
                guest.type.category === GuestType.Business ? "building" : "user"
              }
            />
            <FontAwesomeIcon
              icon="ban"
              className="text-red-400 absolute right-[2px] bottom-[2px] text-lg"
            />
          </span>
        </div>
      ) : guest.avatarUri ? (
        <img
          src={guest.avatarUri}
          className="object-cover w-16 h-16 rounded-full"
          alt={guest.firstName}
        />
      ) : (
        <div className="flex items-center justify-center w-16 h-16 bg-gray-200 rounded-full">
          <FontAwesomeIcon
            icon={
              guest.type.category === GuestType.Business ? "building" : "user"
            }
          />
        </div>
      )}
      <div className="flex-1 ml-4">
        <div className="flex items-center">
          <p className="text-lg font-semibold mr-2">
            {guest.type.category === GuestType.Business
              ? guest.firstName || guest.lastName
                ? `${guest.firstName} ${guest.lastName}`
                : guest.companyName
              : `${guest.firstName} ${guest.lastName}`}{" "}
          </p>
          {guest?.appPermission && !guest?.hasJoined ? (
            <div className="p-1 bg-gray-100 border border-gray-400 text-gray-500 text-xs h-5 rounded-sm flex justify-center items-center">
              <p>Invited</p>
            </div>
          ) : guest?.hasJoined ? (
            <div className="p-1 bg-green-100 border border-green-400 text-green-500 text-xs h-5 rounded-sm flex justify-center items-center">
              <p>Joined</p>
            </div>
          ) : null}
          {guest.hasKeyAccess ? (
            <div className="text-red-400 mx-2">
              <FontAwesomeIcon icon={["fas", "key"]} />
            </div>
          ) : null}
        </div>
        <p className="text-gray-500">{guest.type.name}</p>
      </div>
      {guest.isBanned ? (
        <p className="font-semibold text-red-400">Banned</p>
      ) : (
        <div className="flex gap-2">
          <Link
            to={guest.guestId.toString()}
            onClick={(e) => {
              resetGuest();
              if (isGuestServiceDisabled) {
                e.preventDefault();
                e.stopPropagation();
                showNoAccessDialog();
              }
            }}
            className="text-sm underline text-[#262b2b]"
          >
            Edit
          </Link>
          <button
            className="text-sm underline text-[#262b2b]"
            onClick={() => {
              if (isGuestServiceDisabled) {
                showNoAccessDialog();
              } else {
                setIsOpen(true);
              }
            }}
          >
            Remove
          </button>
        </div>
      )}
      <ConfirmationModal
        variant="warning"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="Remove Guest"
        body="Are you sure you want to remove this guest?"
        buttonLabels={{ confirm: isLoading ? "Removing" : "Remove" }}
        onConfirm={onDelete}
        isLoading={isLoading}
      />
    </li>
  );
}
