import React, { useEffect, useState } from "react";
import { PollQuetionOptions, questionAnswersType } from "./hooks/types";
import RadioButtonComponent from "./RadioButtonComponent";

type Props = {
  options: PollQuetionOptions[];
  setAnswers: (questionId: number, answerId: any) => void;
  residentPollQuestionId: number;
  updateOther: (questionId: number, other: string) => void;
  setIsSubmitDisabled: (item: boolean) => void;
  isExpired?: boolean;
  userAnswers: questionAnswersType[];
};
const PollSingleSelect = ({
  options,
  setAnswers,
  residentPollQuestionId,
  updateOther,
  setIsSubmitDisabled,
  isExpired,
  userAnswers
}: Props) => {
  const [option, setOption] = useState<number | null>(0);
  const [other, setOther] = useState<string>("");

  useEffect(() => {
    const id = userAnswers?.find(
      (answer) => answer.residentPollQuestionId === residentPollQuestionId
    )?.residentPollQuestionOptionId;
    if (id !== undefined) {
      setOption(id as number | null);
    } else {
      setOption(0);
    }
  }, [userAnswers, residentPollQuestionId]);

  useEffect(() => {
    setOther(
      userAnswers?.find(
        (answer) =>
          answer.residentPollQuestionId == residentPollQuestionId &&
          answer.residentPollQuestionOptionId === null
      )?.other as string
    );
  }, [userAnswers, option, residentPollQuestionId]);

  const hasOther = option === null;

  useEffect(() => {
    if (!hasOther) {
      setOther("");
    }
  }, [hasOther]);

  useEffect(() => {
    if (hasOther && (!other || other?.length === 0)) {
      setIsSubmitDisabled(true);
    } else {
      setIsSubmitDisabled(false);
    }
  }, [other, hasOther]);

  const handleRadioChange = (value: number | null) => {
    setAnswers(residentPollQuestionId, value);
    setOption(value ? Number(value) : null);
  };
  return (
    <>
      <div>
        {options?.map((response) => (
          <div key={response.residentPollQuestionOptionId}>
            <RadioButtonComponent
              label={response.text}
              checked={
                option === undefined
                  ? false
                  : option == response.residentPollQuestionOptionId
              }
              residentPollQuestionOptionId={
                response.residentPollQuestionOptionId
              }
              onChange={(value: number | null) => {
                handleRadioChange(value);
              }}
              disabled={isExpired}
            />
          </div>
        ))}
      </div>
      {hasOther && (
        <div>
          <input
            type="text"
            placeholder="Enter text"
            className="w-1/2 mt-4 mb-20"
            value={other ?? ""}
            onChange={(e: any) => {
              setOther(e.target.value);
              updateOther(residentPollQuestionId, e.target.value);
            }}
            disabled={isExpired}
          />
        </div>
      )}
    </>
  );
};

export default PollSingleSelect;
