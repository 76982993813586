import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function RatingIcon({
  rating,
  isSelected,
  size = "4x",
}: {
  rating: number;
  isSelected: boolean;
  size?: SizeProp;
}) {
  switch (rating) {
    case 0:
      return (
        <FontAwesomeIcon
          icon={["fad", "angry"]}
          size={size}
          className={`cursor-pointer duration-200 transform ${
            isSelected ? "scale-110" : "scale-100"
          }`}
          style={{
            //@ts-ignore
            "--fa-primary-color": isSelected
              ? "rgba(250,71,71,1)"
              : "rgba(155,155,155,1)",
            "--fa-secondary-color": isSelected
              ? "rgba(250,71,71,0.4)"
              : "rgba(226,226,226,1)",
            "--fa-secondary-opacity": 1,
          }}
        />
      );
    case 1:
      return (
        <FontAwesomeIcon
          icon={["fad", "frown"]}
          size={size}
          className={`cursor-pointer duration-200 transform ${
            isSelected ? "scale-110" : "scale-100"
          }`}
          style={{
            //@ts-ignore
            "--fa-primary-color": isSelected
              ? "rgba(90,136,237,1)"
              : "rgba(155,155,155,1)",
            "--fa-secondary-color": isSelected
              ? "rgba(90,136,237,0.4)"
              : "rgba(226,226,226,1)",
            "--fa-secondary-opacity": 1,
          }}
        />
      );
    case 2:
      return (
        <FontAwesomeIcon
          icon={["fad", "smile"]}
          size={size}
          className={`cursor-pointer duration-200 transform ${
            isSelected ? "scale-110" : "scale-100"
          }`}
          style={{
            //@ts-ignore
            "--fa-primary-color": isSelected
              ? "rgba(255,199,71,1)"
              : "rgba(155,155,155,1)",
            "--fa-secondary-color": isSelected
              ? "rgba(255,199,71,0.4)"
              : "rgba(226,226,226,1)",
            "--fa-secondary-opacity": 1,
          }}
        />
      );
    case 3:
      return (
        <FontAwesomeIcon
          icon={["fad", "grin-hearts"]}
          size={size}
          className={`cursor-pointer duration-200 transform ${
            isSelected ? "scale-110" : "scale-100"
          }`}
          style={{
            //@ts-ignore
            "--fa-primary-color": isSelected
              ? "rgba(78,199,121,1)"
              : "rgba(155,155,155,1)",
            "--fa-secondary-color": isSelected
              ? "rgba(78,199,121,0.4)"
              : "rgba(226,226,226,1)",
            "--fa-secondary-opacity": 1,
          }}
        />
      );
    default:
      return null;
  }
}

export { RatingIcon };
