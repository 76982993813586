import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import Layout from "../../components/Layout";
import VehicleForm, { VehicleRequest } from "../../components/VehicleForm";
import { BASE_API_URL } from "../../utils/constants";
import useLivlyUser from "../../context/UserProvider";
import { VehicleResponse } from "../checklist/vehicles";

function usePatchVehicle(leaseId: string, userId: number, vehicleId: string) {
  const patchVehicle = async (vehicle: VehicleRequest) => {
    return await axios.patch(
      `${BASE_API_URL}/livly/lease/${leaseId}/users/${userId}/vehicles/${vehicleId}`,
      vehicle
    );
  };

  return useMutation(patchVehicle);
}

function useGetVehicle(leaseId: string, id: string) {
  const queryClient = useQueryClient();

  const vehicles: VehicleResponse[] =
    queryClient.getQueryData(["lease-vehicles", leaseId]) ?? [];
  const vehicle = vehicles.find((v) => v.id === Number(id));

  return vehicle;
}

export default function EditVehiclePage() {
  const queryClient = useQueryClient();
  const { id, leaseId } = useParams<{ id: string; leaseId: string }>();
  const { user } = useLivlyUser();
  const navigate = useNavigate();
  const { mutate, isLoading, error } = usePatchVehicle(
    leaseId!,
    user.userId,
    id!
  );
  const vehicle = useGetVehicle(leaseId!, id!);

  if (vehicle == null) {
    return <Navigate to="/vehicles" />;
  }

  const onSubmit = (vehicle: VehicleRequest) => {
    mutate(vehicle, {
      onSuccess: () => {
        queryClient.invalidateQueries(["lease-vehicles"]);
        navigate("../vehicles");
      },
    });
  };

  return (
    <Layout
      back={{ to: "../vehicles", label: "My Vehicles" }}
      title="Edit Vehicle"
    >
      <div className="max-w-3xl mx-auto">
        <VehicleForm
          isLoading={isLoading}
          error={error}
          initialVehicle={vehicle}
          onSubmit={onSubmit}
        />
      </div>
    </Layout>
  );
}
