import { useMutation } from "@tanstack/react-query";
import { AutopayState } from "./useAutopayState";
import { RentAutoPay, RentBalanceTypeEnum } from "@/types/Rent";
import { BASE_API_URL } from "@/utils/constants";
import axios from "axios";
import { BaseLivlyApiResponse } from "@/types/Base";
import { useNavigate, useRevalidator } from "react-router-dom";
import {
  trackActivateAutopay,
  trackDeactivateAutopay,
} from "@/utils/analytics";

interface ErrorResponse {
  data?: { Error: Record<string, string>; Message: string };
}

export const postAutoPaySettings = async (
  userId: number,
  leaseId: number,
  data: RentAutoPay
) => {
  const result = await axios.post<BaseLivlyApiResponse<RentAutoPay>>(
    `${BASE_API_URL}/livly/payment/${leaseId}/rentAutoPay/${userId}`,
    data
  );

  return result.data.Data;
};

export const deleteAutoPaySettings = async (
  userId: number,
  leaseId: number,
  id: number
) => {
  const result = await axios.delete(
    `${BASE_API_URL}/livly/payment/${leaseId}/rentAutoPay/${userId}/${id}`
  );

  return result.data.Data;
};

export default function useAutopayActions(state: AutopayState) {
  const revalidator = useRevalidator();
  const navigate = useNavigate();
  const {
    user,
    autopaySettings,
    paymentAccount,
    setShowTerms,
    setError,
    setShowError,
    setShowDisable,
    rentSettings,
  } = state;

  const { mutate, isLoading: isSaving } = useMutation(
    (data: RentAutoPay) =>
      postAutoPaySettings(user.userId, user.propertyUnitLeaseId, data),
    {
      onSuccess: () => {
        revalidator.revalidate();
      },
    }
  );

  const { mutate: disableAutoPay, isLoading: isDisablingAutopay } = useMutation(
    (id: number) =>
      deleteAutoPaySettings(user.userId, user.propertyUnitLeaseId, id),
    {
      onSuccess: () => {
        revalidator.revalidate();
      },
    }
  );

  const save = () => {
    if (!autopaySettings) {
      return;
    }

    let rentBalanceTypeId: RentBalanceTypeEnum =
      RentBalanceTypeEnum.BalanceBased;

    if (rentSettings.rentBalanceType === RentBalanceTypeEnum.BalanceBased) {
      rentBalanceTypeId = RentBalanceTypeEnum.BalanceBased;
    } else {
      rentBalanceTypeId =
        autopaySettings.fixedAmount !== null && autopaySettings.fixedAmount > 0
          ? RentBalanceTypeEnum.FreeFormFixedAmount
          : RentBalanceTypeEnum.FreeFormWithBalance;
    }

    const newSettings: RentAutoPay = {
      ...autopaySettings,
      rentBalanceTypeId,
      fixedAmount: autopaySettings.isFullStatementBalance
        ? null
        : autopaySettings.fixedAmount,
    };

    mutate(newSettings, {
      onSuccess: () => {
        if (paymentAccount) {
          trackActivateAutopay(paymentAccount);
        }

        setShowTerms(false);
        navigate(
          `/lease/${user.propertyUnitLeaseId}/rent/${user.userId}/livly`
        );
      },
      onError: (data) => {
        setShowTerms(false);

        const error = data as ErrorResponse;
        setError(error.data?.Message ?? "There was an error");
        setShowError(true);
      },
    });
  };

  const disable = () => {
    if (!autopaySettings || autopaySettings.id == null) {
      return;
    }

    disableAutoPay(autopaySettings?.id, {
      onSuccess: () => {
        trackDeactivateAutopay();
        setShowDisable(false);
        navigate(
          `/lease/${user.propertyUnitLeaseId}/rent/${user.userId}/livly`
        );
      },
    });
  };

  return {
    save,
    disable,
    isSaving,
    isDisablingAutopay,
  };
}

export type AutopayActions = ReturnType<typeof useAutopayActions>;
