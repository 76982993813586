import { Menu } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FeedPost, TagsName, TagType } from "@/utils/commfeed";
import { FileLike, ImagePreviewer, ImageUpload } from "react-file-utils";
import useLivlyUser from "@/context/UserProvider";
import { useEffect, useRef, useState } from "react";
import { Modal } from "@/components/Dialog";
import { Button } from "@/components/Button";
import Avatar from "@/components/Avatar";
import StreamTextarea from "./StreamTextArea";
import UploadButton from "./upload-button";
import EmojiPicker from "./emoji-picker";
import { humanizeTimestamp } from "@/utils/toLocalTime";
import _ from "lodash";
import useIsReadonlyCommFeed from "./hook/useIsReadonlyCommFeed";

const FeedPostMenu = ({
  post,
  onDelete,
  onEdit,
  onHide,
  onReport,
  isLoading
}: {
  post: FeedPost;
  onDelete: (leaseId: string, postId: string) => Promise<void>;
  onEdit: (
    post: FeedPost,
    images: ImageUpload[],
    text: string,
    tag: TagType
  ) => Promise<FeedPost>;
  onHide: (leaseId: string, postId: string) => Promise<void>;
  onReport?: (leaseId: string, postId: string) => Promise<void>;
  isLoading: boolean;
}) => {
  const { user } = useLivlyUser();
  const isReadOnly = useIsReadonlyCommFeed();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [editFeedPostDialogOpen, setEditFeedPostDialogOpen] = useState(false);
  const [hideFeedPostDialogOpen, setHideFeedPostDialogOpen] = useState(false);
  const [reportFeedPostDialogOpen, setReportFeedPostDialogOpen] =
    useState(false);
  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="p-1 mb-1 h-6 w-6">
          <FontAwesomeIcon icon="ellipsis-h" />
        </Menu.Button>

        <Menu.Items className="absolute z-[999] right-0 mt-2 py-2 w-40 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {post.actorId === user.userId && (
            <>
              {!isReadOnly && (
                <Menu.Item>
                  <div
                    className={"cursor-pointer px-4 py-2 hover:bg-gray-100"}
                    onClick={() => setEditFeedPostDialogOpen(true)}
                  >
                    <p className="text-sm font-medium">Edit</p>
                  </div>
                </Menu.Item>
              )}

              <Menu.Item>
                <div
                  className={"cursor-pointer px-4 py-2  hover:bg-gray-100"}
                  onClick={() => setDeleteConfirmationOpen(true)}
                >
                  <p className="text-sm font-medium text-red-500">Delete</p>
                </div>
              </Menu.Item>
            </>
          )}
          {post.actorId !== user.userId && (
            <Menu.Item>
              <div
                className={"cursor-pointer px-4 py-2 hover:bg-gray-100"}
                onClick={() => setHideFeedPostDialogOpen(true)}
              >
                <p className="text-sm font-medium">Hide</p>
              </div>
            </Menu.Item>
          )}
          {post.actorId !== user.userId && (
            <Menu.Item>
              <div
                className={"cursor-pointer px-4 py-2 hover:bg-gray-100"}
                onClick={() => setReportFeedPostDialogOpen(true)}
              >
                <p className="text-sm font-medium">Report</p>
              </div>
            </Menu.Item>
          )}
        </Menu.Items>
      </Menu>
      <ConfirmLeave
        isDialogOpen={deleteConfirmationOpen}
        setIsDialogOpen={setDeleteConfirmationOpen}
        onSubmit={() => onDelete(String(user.propertyUnitLeaseId), post.id)}
        isLoading={isLoading}
      />
      <EditPost
        post={post}
        open={editFeedPostDialogOpen}
        onClose={() => setEditFeedPostDialogOpen(false)}
        onEdit={onEdit}
      />
      <ConfirmLeave
        isDialogOpen={hideFeedPostDialogOpen}
        setIsDialogOpen={setHideFeedPostDialogOpen}
        onSubmit={() => onHide(String(user.propertyUnitLeaseId), post.id)}
        isLoading={isLoading}
        confirmText="Are you sure you want to hide this post?"
      />
      {onReport && (
        <ConfirmLeave
          isDialogOpen={reportFeedPostDialogOpen}
          setIsDialogOpen={setReportFeedPostDialogOpen}
          onSubmit={() => onReport(String(user.propertyUnitLeaseId), post.id)}
          isLoading={isLoading}
          confirmText="Are you sure you want to report this post?  Your property staff will be notified."
        />
      )}
    </>
  );
};

type Props = {
  post: FeedPost;
  open: boolean;
  onClose: () => void;
  onEdit: (
    post: FeedPost,
    images: ImageUpload[],
    text: string,
    tag: TagType
  ) => Promise<FeedPost>;
};

const maxImagesForUpload = 8;

const EditPost = ({ post, open, onClose, onEdit }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tempPost, setTempPost] = useState(post);
  const [imageUploads, setImageUploads] = useState<ImageUpload[]>([]);
  const textInputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (open) {
      setImageUploads([]);
      if (textInputRef.current) {
        textInputRef.current.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    if (!open) {
      setImageUploads([]);
      setTempPost(post);
    }
  }, [open, post]);

  useEffect(() => {
    if (open) {
      (post.images || []).forEach(async (image: any) => {
        const response = await fetch(image.uri);
        const data = await response.blob();
        const file = new File([data], image.id);

        const imageUpload: ImageUpload = {
          url: image.uri,
          id: image.id,
          file,
          previewUri: image.uri,
          state: "finished"
        };

        setImageUploads((i) => [...i, imageUpload]);
      });
    }
  }, [open, post]);

  if (!open) return null;

  const onSubmit = async () => {
    setIsLoading(true);
    await onEdit(post, imageUploads, tempPost.textBody, tempPost.tag);
    setIsLoading(false);
    onClose();
  };

  const handleChangeTag = (e: React.ChangeEvent<{ value: unknown }>) => {
    const tag = e.target.value as TagType;

    setTempPost({ ...tempPost, tag });
  };

  const onChangeTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value;

    if (text !== null) {
      setTempPost({ ...tempPost, textBody: text });
    }
  };

  const handleRemove = (id: string) => {
    const newImages = imageUploads.filter((image) => image.id !== id);

    setImageUploads(newImages);
  };

  const onUploadImage = (file: FileLike) => {
    if (FileReader) {
      const reader = new FileReader();

      reader.onload = function (e) {
        if (!e.target) {
          return;
        }

        const imageUpload: ImageUpload = {
          id: _.uniqueId(),
          file: file as File,
          previewUri: e.target.result as string,
          state: "finished"
        };

        setImageUploads((i) => [...i, imageUpload]);
      };

      reader.readAsDataURL(file);
    }
  };

  const onUploadImages = (files: FileLike[]) => {
    for (const file of files) {
      if (file.type.startsWith("image/")) {
        onUploadImage(file);
      }
    }
  };

  const insertText = (insertedText: string) => {
    let newCursorPosition: number | undefined;

    setTempPost((post) => {
      const prevText = post.textBody;

      const textAreaElement = textInputRef.current;

      if (!textAreaElement) {
        return {
          ...post,
          textBody: prevText + insertedText
        };
      }

      const { selectionStart, selectionEnd } = textAreaElement;
      newCursorPosition = selectionStart + insertedText.length;

      return {
        ...post,
        textBody:
          prevText.slice(0, selectionStart) +
          insertedText +
          prevText.slice(selectionEnd)
      };
    });

    _.delay(() => {
      const textAreaElement = textInputRef.current;

      if (!textAreaElement || newCursorPosition == null) {
        return;
      }

      // Update cursorPosition
      textAreaElement.setSelectionRange(newCursorPosition, newCursorPosition);
    }, 1);
  };

  const onSelectEmoji = (emoji: any) => insertText(emoji.native);

  return (
    <Modal open={open} onClose={onClose} title={"Edit Post"} size="xl">
      <div className="flex justify-between flex-col w-full mt-4">
        <div className="p-2 px-4">
          <div className="flex items-center mb-4 justify-between">
            <div className="flex space-x-4 items-center">
              <Avatar
                src={post.actorUri}
                size="sm"
                name={`${post.actorName}`}
              />
              <div className="flex-1">
                <p className="font-semibold">{post.actorName}</p>
                <p className="text-sm text-gray-500">
                  {humanizeTimestamp(post.timestamp)}
                  {post.timestamp !== post.lastUpdated && ` (edited)`}
                </p>
              </div>
            </div>
            <div className="ml-4">
              <select
                className="border rounded-md py-1 border-gray-300 text-sm"
                value={tempPost.tag}
                onChange={handleChangeTag}
              >
                {Array.from(TagsName).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <StreamTextarea
            value={tempPost.textBody}
            innerRef={textInputRef}
            onChange={onChangeTextarea}
            rows={2}
          />

          {imageUploads.length > 0 && (
            <ImagePreviewer
              imageUploads={imageUploads as ImageUpload[]}
              handleRemove={handleRemove}
            />
          )}
          <div className="mt-4 mb-2 flex justify justify-between border-t border-tomcat">
            <div className="flex flex-grow mr-2 pt-3  space-x-2">
              <UploadButton
                handleFiles={onUploadImages}
                disabled={
                  isLoading || imageUploads.length >= maxImagesForUpload
                }
                multiple
              />
              <EmojiPicker onSelect={onSelectEmoji} />
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-2">
          <Button color="default" disabled={isLoading} onClick={onClose}>
            Nevermind
          </Button>
          <Button
            color="secondary"
            disabled={isLoading}
            onClick={() => onSubmit()}
          >
            {isLoading ? "Please wait..." : "Save Post"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const ConfirmLeave = ({
  isDialogOpen,
  setIsDialogOpen,
  onSubmit,
  confirmText = "Are you sure you want to delete this post?",
  isLoading
}: {
  isDialogOpen: boolean;
  setIsDialogOpen: ((open: boolean) => void) | any;
  onSubmit: () => void;
  confirmText?: string;
  isLoading: boolean;
}) => {
  return (
    <Modal
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      title={"Warning"}
    >
      <div className="flex justify-between flex-col ">
        <p className="mt-2 text-left">{confirmText}</p>
        <div className="mt-4 flex justify-end gap-2">
          <Button
            color="default"
            disabled={isLoading}
            onClick={() => setIsDialogOpen(false)}
          >
            Go Back
          </Button>
          <Button
            color="secondary"
            disabled={isLoading}
            onClick={async () => {
              await onSubmit();
              setIsDialogOpen(false);
            }}
          >
            {isLoading ? "Please wait..." : "Confirm"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default FeedPostMenu;
