import BlockContent from "@sanity/block-content-to-react";
import { useState } from "react";
import moment from "moment-timezone";
import axios from "axios";
import { BASE_API_URL } from "@/utils/constants";
import { useMutation } from "@tanstack/react-query";
import { useOutletContext, useParams } from "react-router-dom";
import Layout from "@/components/Layout";
import { Button } from "@/components/Button";
import { ConfirmationModal } from "@/components/Dialog";
import { trackMarketplaceClaimOffer } from "@/utils/analytics";
import useLivlyUser from "@/context/UserProvider";
import { MarketplaceContext } from "@/context/MarketplaceLayout";
import { Spinner } from "@/components/Spinner";

function useGetVendorOffer(vendorId: string, offerId: string) {
  const { data, isLoading } = useOutletContext<MarketplaceContext>();

  const vendor = data.find((v) => v._id === vendorId);
  const offer = vendor?.vendorOffers.find((vo) => vo._id === offerId);

  return { vendor, offer, isLoading };
}

function usePostOffer(userId: number, vendorName?: string) {
  const postOffer = async (orderData: any) => {
    return axios.post(
      `${BASE_API_URL}/livly/users/${userId}/sendMarketPlaceEmail?partner=${
        vendorName || "casper"
      }`,
      { ...orderData }
    );
  };

  return useMutation(postOffer);
}

export default function ChecklistBuildingOfferPage() {
  const { vendorId, offerId } = useParams<{
    leaseId: string;
    vendorId: string;
    offerId: string;
  }>();
  const { user } = useLivlyUser();

  const { vendor, offer, isLoading } = useGetVendorOffer(vendorId!, offerId!);
  const { mutate, isLoading: isSaving } = usePostOffer(
    user.userId,
    vendor?.vendorName
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const [isErrorMessageOpen, setErrorMessageOpen] = useState(false);

  const hasUserClickedOffer = localStorage[`${vendor?._id}-${offer?._id}`];

  const submitOrder = async () => {
    if (!vendor || !offer) return;

    trackMarketplaceClaimOffer(offer.offerTitle);
    const address = (user.addresses ?? []).find((a) => a.isLeaseAddress);

    // When vendor is Casper, send email to appropriate tech/prod/ops team members
    if (offer.offerEmailLivly) {
      await mutate(
        {
          "First, Last": `${user.firstName} ${user.lastName}`,
          Email: user.email,
          "Phone Number": user.phone,
          Building: user.buildingName,
          Unit: user.unit,
          Address: `${address?.address1}, ${address?.city}, ${address?.state} ${address?.zipCode}`,
          "Lease start": moment(user.leaseStartDate).format("MM/DD/YYYY"),
          Name: vendor.vendorName,
          Title: offer.offerTitle,
          "Savings text": offer.offerSubTitle,
          "CTA Text": offer.offerCtaText,
          vendorName: vendor.vendorName,
          vendorId: vendor._id,
          productId: offer._id,
        },
        {
          onSuccess: () => {
            setModalOpen(true);
          },
          onError: () => {
            setErrorMessageOpen(true);
          },
        }
      );

      // Else send the user to the external link
    } else if (offer.offerExternalLink) {
      window.open(offer.offerExternalLink, "_blank");
    }
  };

  return (
    <Layout
      back={{ to: -1, label: "Building Offers" }}
      title={offer?.offerTitle || "offer"}
    >
      {isLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <>
          <div className="flex flex-col md:flex-row md:justify-center">
            <img
              className="w-full block max-h-[300px] object-cover"
              src={offer?.offerBannerImage!}
              alt={offer?.offerTitle}
            />

            {vendor?.vendorBadge && (
              <div className="flex justify-end mr-6 -mt-8 md:hidden">
                <img
                  className="object-contain w-16 h-16 "
                  src={vendor?.vendorBadge}
                  alt={vendor?.vendorName}
                />
              </div>
            )}
            <div className="flex flex-col justify-between w-full p-6">
              <div className="mb-4">
                <div className="w-full pt-4 pb-6 md:pt-0">
                  <img
                    className="h-6 mb-2"
                    src={vendor?.vendorLogo}
                    alt={vendor?.vendorName}
                  />
                  <h6>{offer?.offerSubTitle}</h6>
                </div>
                <div className="w-full mt-6">
                  <p className="mb-4 font-medium">{offer?.offerTitle}</p>
                </div>
                <BlockContent blocks={offer?.offerDescription} />
              </div>
              {(offer?.offerExternalLink || offer?.offerEmailLivly) && (
                <div>
                  <Button
                    size="small"
                    color="secondary"
                    onClick={submitOrder}
                    disabled={isSaving}
                  >
                    {hasUserClickedOffer
                      ? vendor?.vendorName.toLowerCase() === "casper"
                        ? "Your order is on it's way!"
                        : "We'll be in touch soon!"
                      : offer?.offerCtaText}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <ConfirmationModal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        title="Done & Done"
        body={
          vendor?.vendorName.toLowerCase() === "brook furniture"
            ? "We're putting you in touch with Brook Furniture, you'll receive an email with instructions to confirm your desired package within 24 hours. You will not be charged until you confirm your selection and finalize delivery details."
            : "We're putting you in touch with our dedicated support staff, you'll receive an email with instructions to confirm sizing within the next 24 hours. You will not be charged until you confirm your selection and your mattress ships."
        }
        onConfirm={() => setModalOpen(false)}
        variant="success"
        buttonLabels={{ confirm: "Got it", cancel: null }}
      />
      <ConfirmationModal
        open={isErrorMessageOpen}
        onClose={() => setErrorMessageOpen(false)}
        title="Sorry"
        body="There was an error submitting your order!. Please try again."
        onConfirm={() => setErrorMessageOpen(false)}
        variant="warning"
      />
    </Layout>
  );
}
