import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  trackAcceptTermsOfService,
  trackTermsOfServiceViewPrivacyPolicy,
  trackTermsOfServiceViewToS,
} from "../utils/analytics";
import { BASE_API_URL } from "../utils/constants";
import { Button } from "./Button";
import { Modal } from "./Dialog";
import { Spinner } from "./Spinner";
import useLivlyUser from "../context/UserProvider";

async function postAgreeToTerms(userId: number) {
  return await axios.post(`${BASE_API_URL}/livly/users/${userId}/tos`, {
    "Content-Type": "application/json",
  });
}

export default function TermsOfService() {
  const navigate = useNavigate();
  const { user, updateUser } = useLivlyUser();
  const [open, setOpen] = useState(!user.isTosAccepted);

  const { mutate, isLoading } = useMutation(() =>
    postAgreeToTerms(user.userId)
  );

  const handleAgree = () => {
    mutate(undefined, {
      onSuccess: () => {
        updateUser({ isTosAccepted: true });
        setOpen(false);
        trackAcceptTermsOfService(true);
      },
    });
  };

  const handleDisagree = () => {
    navigate("/logout");
    trackAcceptTermsOfService(false);
  };

  const onViewToS = () => {
    trackTermsOfServiceViewToS();
  };

  const onViewPrivacyPolicy = () => {
    trackTermsOfServiceViewPrivacyPolicy();
  };

  return (
    <div>
      <Modal open={open} onClose={handleDisagree}>
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Terms of Service
          </h3>
          <p className="mt-4 text-sm">
            By tapping the button below, you agree to Livly's Terms of Use and
            Privacy Policy as well as our partner Dwolla's Terms of Use and
            Privacy Policy.
          </p>
          <p className="mt-2 text-sm">
            To learn more, see our{" "}
            <a
              href="https://www.livly.io/terms-of-service"
              className="text-gray-500 underline hover:text-gray-600"
              onClick={onViewToS}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              href="https://www.livly.io/privacy-policy"
              className="text-gray-500 underline hover:text-gray-600"
              onClick={onViewPrivacyPolicy}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </p>
          <div className="flex gap-3 mt-6">
            <Button
              className="flex-1"
              color="default"
              onClick={handleDisagree}
              disabled={isLoading}
            >
              Disagree
            </Button>
            <Button
              className="flex-1"
              color="secondary"
              onClick={handleAgree}
              disabled={isLoading}
            >
              {isLoading && <Spinner />}
              Ok, thanks. Next.
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
