import axios from "axios";

import { PollResult } from "./types";
import { useQuery } from "@tanstack/react-query";
import { BaseLivlyApiResponse } from "@/types/Base";
import { BASE_API_URL } from "@/utils/constants";

const useGetPollQuestions = (
  clientId: number,
  propertyId: number,
  residentPollId: number,
  leaseId: number
) => {
  return useQuery({
    queryKey: ["polls", residentPollId, leaseId, propertyId, clientId],
    queryFn: () => getPolls(clientId, propertyId, residentPollId),
    refetchOnWindowFocus: false
  });
};

const getPolls = async (
  clientId: number,
  propertyId: number,
  residentPollId: number
) => {
  const result = await axios.get<BaseLivlyApiResponse<PollResult>>(
    `${BASE_API_URL}/v2/poll/client/${clientId}/property/${propertyId}/poll/${residentPollId}`
  );

  return result.data.Data;
};

export default useGetPollQuestions;
