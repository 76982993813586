import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { Spinner } from "@/components/Spinner";
import useLivlyUser from "@/context/UserProvider";
import { BuildingTypeEnum } from "@/types/User";
import Layout, { LayoutHeader } from "@/components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { BaseLivlyApiResponse } from "@/types/Base";
import { BASE_API_URL } from "@/utils/constants";
import { colors } from "@/components/colors";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

type IconResponse = Record<string, string>;

async function getResourcePage(buildingId: number, pageId: string) {
  const result = await axios.get<BaseLivlyApiResponse<Page>>(
    `${BASE_API_URL}/livly/property/${buildingId}/resources/${pageId}`,
    {
      headers: { "Content-Type": "application/json" }
    }
  );

  return result.data.Data;
}
async function getIcons() {
  const result = await axios.get<BaseLivlyApiResponse<IconResponse>>(
    `${BASE_API_URL}/livly/common/icons`
  );

  return result.data.Data;
}

const ResourcePage = () => {
  const { pageId } = useParams<{
    pageId: string;
  }>();
  const {
    user: {
      propertyId,
      presentation: { buildingType }
    }
  } = useLivlyUser();
  const { data: icons, isLoading: isLoadingIcons } = useQuery({
    queryKey: ["resource", "icons"],
    queryFn: getIcons
  });

  const {
    data: resourcePage,
    isLoading,
    isError
  } = useQuery({
    queryKey: ["resource", propertyId, pageId!],
    queryFn: () => getResourcePage(propertyId, pageId!)
  });

  if (isLoading || isLoadingIcons) {
    return (
      <div className="flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (isError) {
    return (
      <>
        {!isMobile && (
          <LayoutHeader
            title="Resource Page"
            back={{
              label:
                buildingType === BuildingTypeEnum.Condos
                  ? "My Home"
                  : "My Lease",
              to: "../my-lease"
            }}
          />
        )}
        <div className="h-full flex items-center justify-center">
          <p>Something went wrong</p>
        </div>
      </>
    );
  }

  if (resourcePage) {
    const { bgColor, iconColor } = getPageIconColors(resourcePage.tileColor);

    return (
      <>
        {!isMobile && (
          <LayoutHeader
            title="Resource Page"
            back={{
              label:
                buildingType === BuildingTypeEnum.Condos
                  ? "My Home"
                  : "My Lease",
              to: "../my-lease"
            }}
          />
        )}
        <div className={`mt-5 mx-auto ${isMobile ? "w-[80%]" : "w-[600px]"}`}>
          <div
            className={`flex items-cetner justify-center w-11 h-11 rounded-lg mb-6 ${
              resourcePage.tileColor ? bgColor : "#fff"
            }`}
          >
            {icons && (
              <Icon
                icons={icons}
                icon={resourcePage.icon}
                tileColor={resourcePage.tileColor}
              />
            )}
          </div>
          <h2>
            <strong>{resourcePage.name}</strong>
          </h2>
          <div
            className="mt-5 break-words"
            dangerouslySetInnerHTML={{ __html: resourcePage.htmlContent }}
          />
        </div>
      </>
    );
  }

  return null;
};

function Icon({
  icons,
  icon,
  tileColor
}: {
  icons: IconResponse;
  icon: string;
  tileColor: string | null;
}) {
  if (["amenify", "movers"].includes(icon)) {
    return (
      <div>
        <img src={icons[icon]} />
      </div>
    );
  }

  const iconName: IconProp = mapIconToFontAwesomeIcon(icon);

  return <FontAwesomeIcon icon={iconName} color={tileColor ?? "blue-500"} />;
}

export default ResourcePage;

export const getPageIconColors = (color: string | null) => {
  switch (color) {
    case "astro":
      return {
        iconColor: colors.astro(),
        bgColor: colors.astro(0.2)
      };
    case "bart":
      return {
        iconColor: colors.bart(),
        bgColor: colors.bart(0.2)
      };
    case "bugs":
      return {
        iconColor: colors.bugs(),
        bgColor: colors.bugs(0.2)
      };
    case "felix":
      return {
        iconColor: colors.felix(),
        bgColor: colors.felix(0.2)
      };
    case "gumby":
      return {
        iconColor: colors.gumby(),
        bgColor: colors.gumby(0.2)
      };
    case "koolAid":
      return {
        iconColor: colors.koolAid(),
        bgColor: colors.koolAid(0.2)
      };
    case "livly":
      return {
        iconColor: colors.livly(),
        bgColor: colors.livly(0.2)
      };
    case "magilla":
      return {
        iconColor: colors.magilla(),
        bgColor: colors.magilla(0.2)
      };
    case "pebbles":
      return {
        iconColor: colors.pebbles(),
        bgColor: colors.pebbles(0.2)
      };
    case "penelope":
      return {
        iconColor: colors.penelope(),
        bgColor: colors.penelope(0.2)
      };
    case "snagglepuss":
      return {
        iconColor: colors.snagglepuss(),
        bgColor: colors.snagglepuss(0.2)
      };
    case "sonic":
      return {
        iconColor: colors.sonic(),
        bgColor: colors.sonic(0.2)
      };
    case "spaceGhost":
      return {
        iconColor: colors.spaceGhost(),
        bgColor: colors.spaceGhost(0.2)
      };
    case "tomcat":
      return {
        iconColor: colors.tomcat(),
        bgColor: colors.tomcat(0.2)
      };
    default:
      return {
        iconColor: colors.sonic(),
        bgColor: colors.sonic(0.2)
      };
  }
};

export interface Page {
  uploader: {
    firstName: string;
    lastName: string;
    avatarUri: string;
  };
  id: number;
  name: string;
  extension: string;
  uploadDate: string;
  uri: string;
  propertyResourceTypeId: number;
  email: string;
  featured: boolean;
  homeScreen: boolean;
  notes: string;
  password: string;
  phoneNumber: string;
  sortOrder: number;
  ssid: string;
  title: string;
  contactName: string;
  htmlContent: string;
  isTileProperty: boolean;
  tileColor: string | null;
  icon: string;
  plainContent: string;
}

export enum PageColorType {
  none,
  livly,
  sonic,
  felix,
  snagglepuss,
  penelope,
  magilla,
  pebbles,
  bugs,
  astro,
  tomcat,
  spaceGhost,
  gumby,
  bart,
  koolAid
}

export enum PageIcons {
  Bell = "bell",
  Biking = "biking",
  Bullhorn = "bullhorn",
  Bus = "bus",
  CalendarExclamation = "calendar-exclamation",
  Car = "car",
  ConciergeBell = "concierge-bell",
  Dog = "dog",
  Dumbbell = "dumbbell",
  FileAlt = "file-alt",
  SwimmingPool = "swimming-pool",
  Controller = "gamepad"
}

const foo: IconResponse = {
  airplane: "https://livlydevcontent.azureedge.net/icons/airplane.png",
  amenify: "https://livlydevcontent.azureedge.net/icons/amenify.png",
  "artist-palette":
    "https://livlydevcontent.azureedge.net/icons/artist-palette.png",
  bandage: "https://livlydevcontent.azureedge.net/icons/bandage.png",
  basketball: "https://livlydevcontent.azureedge.net/icons/basketball.png",
  bell: "https://livlydevcontent.azureedge.net/icons/bell.png",
  biking: "https://livlydevcontent.azureedge.net/icons/biking.png",
  book: "https://livlydevcontent.azureedge.net/icons/book.png",
  bullhorn: "https://livlydevcontent.azureedge.net/icons/bullhorn.png",
  bus: "https://livlydevcontent.azureedge.net/icons/bus.png",
  cake: "https://livlydevcontent.azureedge.net/icons/cake.png",
  "calendar-exclamation":
    "https://livlydevcontent.azureedge.net/icons/calendar-exclamation.png",
  car: "https://livlydevcontent.azureedge.net/icons/car.png",
  "concierge-bell":
    "https://livlydevcontent.azureedge.net/icons/concierge-bell.png",
  controller: "https://livlydevcontent.azureedge.net/icons/controller.png",
  dog: "https://livlydevcontent.azureedge.net/icons/dog.png",
  dumbbell: "https://livlydevcontent.azureedge.net/icons/dumbbell.png",
  "file-alt": "https://livlydevcontent.azureedge.net/icons/file-alt.png",
  film: "https://livlydevcontent.azureedge.net/icons/film.png",
  "first-aid": "https://livlydevcontent.azureedge.net/icons/first-aid.png",
  heart: "https://livlydevcontent.azureedge.net/icons/heart.png",
  key: "https://livlydevcontent.azureedge.net/icons/key.png",
  martini: "https://livlydevcontent.azureedge.net/icons/martini.png",
  movers: "https://livlydevcontent.azureedge.net/icons/movers.png",
  "musical-note":
    "https://livlydevcontent.azureedge.net/icons/musical-note.png",
  party: "https://livlydevcontent.azureedge.net/icons/party.png",
  pencil: "https://livlydevcontent.azureedge.net/icons/pencil.png",
  purse: "https://livlydevcontent.azureedge.net/icons/purse.png",
  shopping: "https://livlydevcontent.azureedge.net/icons/shopping.png",
  suitcase: "https://livlydevcontent.azureedge.net/icons/suitcase.png",
  "swimming-pool":
    "https://livlydevcontent.azureedge.net/icons/swimming-pool.png",
  tools: "https://livlydevcontent.azureedge.net/icons/tools.png",
  utensils: "https://livlydevcontent.azureedge.net/icons/utensils.png",
  vacuum: "https://livlydevcontent.azureedge.net/icons/vacuum.png"
};

type IconKeys = keyof IconResponse;

type IconKeyFromIconResponse = keyof typeof foo;

const mapIconToFontAwesomeIcon = (iconKey: IconKeys): IconProp => {
  switch (iconKey) {
    case "airplane":
      return "plane";
    /*  case "amenify":
      return "amenify"; */
    case "artist-palette":
      return "palette";
    case "bullhorn":
      return "bullhorn";
    case "bandage":
      return "bandage";
    case "basketball":
      return "basketball";
    case "bell":
      return "bell";
    case "biking":
      return "biking";
    case "book":
      return "book";
    case "bus":
      return "bus";
    case "cake":
      return "cake";
    case "calendar-exclamation":
      return "calendar-exclamation";
    case "car":
      return "car";
    case "concierge-bell":
      return "concierge-bell";
    case "controller":
      return "gamepad";
    case "dog":
      return "dog";
    case "dumbbell":
      return "dumbbell";
    case "file-alt":
      return "file-alt";
    case "film":
      return "film";
    case "first-aid":
      return "first-aid";
    case "heart":
      return "heart";
    case "key":
      return "key";
    case "martini":
      return "martini-glass-citrus";
    /*  case "movers":
      return "movers"; */
    case "musical-note":
      return "music";
    case "party":
      return "party-horn";
    case "pencil":
      return "pencil";
    case "purse":
      return "bag-shopping";
    case "shopping":
      return "basket-shopping";
    case "suitcase":
      return "suitcase";
    case "swimming-pool":
      return "swimming-pool";
    case "tools":
      return "tools";
    case "utensils":
      return "utensils";
    case "vacuum":
      return "vacuum";
    default:
      return "file-alt";
  }
};
