import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

export default function Drawer({
  open,
  onClose,
  title,
  children,
  anchor = "right",
  hideCloseButton = false,
}: {
  open: boolean;
  onClose: () => void;
  title: string;
  children: JSX.Element;
  anchor?: "left" | "right";
  hideCloseButton?: boolean;
}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={onClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div
              className={`pointer-events-none fixed inset-y-0 ${
                anchor === "right"
                  ? "left-0 md:left-auto md:right-0"
                  : "right-0"
              } flex max-w-full`}
            >
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom={
                  anchor === "right" ? "translate-x-full" : "-translate-x-full"
                }
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo={
                  anchor === "right" ? "translate-x-full" : "-translate-x-full"
                }
              >
                <Dialog.Panel className="w-screen max-w-md pointer-events-auto">
                  <div className="flex flex-col h-screen py-6 overflow-y-scroll bg-white shadow-xl">
                    <div className="px-4 sm:px-6">
                      <div className="relative flex items-start justify-center">
                        {!hideCloseButton && (
                          <div className="absolute flex items-center ml-3 -left-2 h-7">
                            <button
                              type="button"
                              className="w-6 h-6 text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              onClick={onClose}
                            >
                              <span className="sr-only">Close panel</span>
                              <FontAwesomeIcon
                                icon="arrow-left"
                                className="text-gray-900"
                              />
                            </button>
                          </div>
                        )}
                        <Dialog.Title className="text-lg font-medium text-gray-900">
                          {title}
                        </Dialog.Title>
                      </div>
                    </div>
                    <div className="relative flex-1 px-4 mt-6 sm:px-6">
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
