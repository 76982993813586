import { Button } from "@/components/Button";
import { LayoutBody, LayoutHeader } from "@/components/Layout";
import { Spinner } from "@/components/Spinner";
import { User } from "@/types/User";
import moment from "moment";
import { OtherPayees } from "./RentAutoPayPayees";
import PaymentAmount from "./PaymentAmount";
import { PaymentDate } from "./RentAutoPayPaymentDate";
import { SelectPaymentAccount } from "@/components/SelectPaymentAccount";
import Alert from "@/components/Alert";
import { trackChangeAutopayPaymentAccount } from "@/utils/analytics";
import { PaymentStartStop } from "./RentAutoPayDates";
import { useRentAutoPay } from "@/routes/rent/autopay";
import formatCurrency from "@/utils/formatCurrency";
import { autopayValidator } from "./hooks/autopayValidator";

function RentAutoPayPage({ user }: { user: User }) {
  const { autopayState, autoPayActions } = useRentAutoPay();
  const {
    autopaySettings,
    paymentAccount: selectedPaymentAccount,
    paymentAccounts,
    setAutopaySettings: updateSettings,
    setShowTerms,
    fixedAmountFeeData,
    rentAutopayConfigurationMessage,
  } = autopayState;
  const settings = autopaySettings!;
  const { disable, isSaving, isDisablingAutopay } = autoPayActions;
  const isNativeDevice = localStorage.getItem("mobile") === "true";

  const {
    isAutoPayActive,
    isDateSelectionAvailable,
    isSaveDisabled,
    amountError,
    isMaxLimitError,
    isDwollaLimitError,
    isStartAfterEndError,
    isEndError,
    isStartError,
  } = autopayValidator(autopayState, user);

  return (
    <div className="h-screen">
      {!isNativeDevice && (
        <LayoutHeader
          title="Set up Autopay"
          back={{ label: "Rent", to: `../rent/${user.userId}/livly` }}
        />
      )}
      <LayoutBody>
        {settings.id !== null && (
          <div className="flex justify-end mb-4">
            <Button
              onClick={disable}
              size="small"
              color="primary"
              disabled={isDisablingAutopay}
            >
              {isDisablingAutopay ? <Spinner /> : null}
              Disable Autopay
            </Button>
          </div>
        )}
        {settings.message && (
          <div className="my-4">
            <Alert variant="danger" message={settings.message} />
          </div>
        )}

        <SelectPaymentAccount
          selectedPaymentAccountId={
            selectedPaymentAccount ? selectedPaymentAccount.id : null
          }
          paymentAccounts={paymentAccounts.filter((pa) => pa.isActive)}
          onSelect={(id) =>
            updateSettings({ ...settings, paymentAccountId: id })
          }
          onOpenCallback={trackChangeAutopayPaymentAccount}
        />

        <PaymentAmount
          isMaxLimitError={isMaxLimitError}
          isDwollaLimitError={isDwollaLimitError}
          error={amountError}
        />

        {rentAutopayConfigurationMessage && (
          <Alert
            variant="danger"
            title={null}
            message={rentAutopayConfigurationMessage}
          />
        )}

        {isDateSelectionAvailable && (
          <>
            <PaymentDate />
            <PaymentStartStop
              isAutoPayActive={isAutoPayActive}
              startDate={settings.startDate}
              endDate={settings.endDate}
              setStartDate={(startDate) =>
                updateSettings({
                  ...settings,
                  startDate: startDate ? startDate.toISOString() : "",
                })
              }
              setEndDate={(endDate) =>
                updateSettings({
                  ...settings,
                  endDate: endDate ? endDate.toISOString() : "",
                })
              }
              day={settings.autoPayDay}
              isStartAfterEndError={isStartAfterEndError}
              isStartError={isStartError}
              isEndError={isEndError}
              agreedToEndDateOverride={settings.overrideEndDateRestriction}
              setAgreedToEndDateOverride={(bool) =>
                updateSettings({
                  ...settings,
                  overrideEndDateRestriction: bool,
                })
              }
            />
          </>
        )}

        <OtherPayees />

        <div className="fixed bottom-0 left-0 right-0 flex flex-col items-stretch m-4 bg-white border border-gray-100 rounded-lg shadow-lg md:flex-row md:justify-between md:items-baseline md:left-64 drop-shadow-lg">
          <div className="flex flex-col items-center justify-between w-full p-4 border-t border-gray-200 md:flex-row md:border-none">
            <div className="flex flex-col items-center justify-center mr-4 md:items-baseline">
              <p className="text-lg">
                {settings.isFullStatementBalance
                  ? "Full statement balance plus fees"
                  : fixedAmountFeeData?.totalAmount
                  ? `${formatCurrency(
                      fixedAmountFeeData?.totalAmount
                    )} payment total`
                  : "Enter payment amount"}
              </p>
              {settings.id !== null && settings.nextBillingDate && (
                <p className="text-gray-400 font-light md:mr-2">
                  next billing date{" "}
                  {settings.nextBillingDate
                    ? moment(settings.nextBillingDate).format("MMMM D, YYYY")
                    : ""}
                </p>
              )}
            </div>
            <Button
              color="secondary"
              onClick={() => setShowTerms(true)}
              disabled={isSaveDisabled}
              className="flex items-center w-full gap-2 mt-4 md:w-auto md:mt-0"
            >
              {isSaving && <Spinner />}
              {settings.id !== null ? "Update changes" : "Activate Autopay"}
            </Button>
          </div>
        </div>
      </LayoutBody>
    </div>
  );
}

export default RentAutoPayPage;
