import React, { FC } from "react";
import {
  useSpring,
  animated,
  config as sConfig,
  SpringConfig
} from "react-spring";

type Props = {
  delay?: boolean;
  config?: SpringConfig;
  children?: React.ReactNode;
};

const FadeIn: FC<Props> = ({
  children,
  delay = false,
  config = sConfig.default
}) => {
  const props = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config,
    delay: delay ? 200 : 0
  });

  return <animated.div style={props}>{children}</animated.div>;
};

export default FadeIn;
