import Layout from "../../components/Layout";
import { useUpdateInsuranceQuote } from "./insurance-quote";
import { AssurantQuotePurchaseSchedule } from "../../types/Insurance";
import formatCurrency from "../../utils/formatCurrency";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useLivlyUser from "../../context/UserProvider";
import classNames from "classnames";
import { useOutletContext } from "react-router-dom";
import { InsuranceQuoteContext } from "@/components/InsuranceQuoteLayout";

function InsurancePaymentPlanPage() {
  const { user } = useLivlyUser();
  const { data, refetch } = useOutletContext<InsuranceQuoteContext>();

  const updateInsuranceQuoteMutation = useUpdateInsuranceQuote(user);

  const onSelect = async (numberOfPayments: number) => {
    try {
      await updateInsuranceQuoteMutation.mutateAsync({ numberOfPayments });
      refetch();
    } catch (e) {}
  };

  const availablePlans = data?.purchase.schedules.filter((s) =>
    [1, 4, 11].includes(s.numberOfPayments)
  );
  const selectedNumberOfPayments = data?.presentation.numberOfPayments || 11;

  return (
    <Layout
      title="Select a plan"
      back={{
        label: "Insurance",
        to: `../insurance-quote`,
      }}
    >
      <div className="max-w-4xl mx-auto">
        <p>
          Select a payment plan that works for you. Your coverage will
          automatically renew every year and you’ll be billed according to the
          payment plan you choose.
        </p>
        <div className="mt-8 space-y-8">
          {availablePlans
            ?.sort((a, b) => (b.numberOfPayments < a.numberOfPayments ? -1 : 1))
            .map((plan) => {
              const isFullPay = plan.numberOfPayments === 1;
              const isQuarerly = plan.numberOfPayments === 4;
              const isSelected =
                plan.numberOfPayments === Number(selectedNumberOfPayments);

              return (
                <div
                  className="shadow-md rounded-md p-4"
                  key={plan.numberOfPayments}
                >
                  {isFullPay ? (
                    <FullPaymentPlan
                      plan={plan}
                      isSelected={isSelected}
                      onSelect={() => onSelect(plan.numberOfPayments)}
                      isLoading={updateInsuranceQuoteMutation.isLoading}
                    />
                  ) : isQuarerly ? (
                    <QuarterlyPaymentPlan
                      plan={plan}
                      isSelected={isSelected}
                      onSelect={() => onSelect(plan.numberOfPayments)}
                      isLoading={updateInsuranceQuoteMutation.isLoading}
                    />
                  ) : (
                    <EconomicPaymentPlan
                      plan={plan}
                      isSelected={isSelected}
                      onSelect={() => onSelect(plan.numberOfPayments)}
                      isLoading={updateInsuranceQuoteMutation.isLoading}
                    />
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </Layout>
  );
}

function FullPaymentPlan({
  isLoading,
  onSelect,
  plan,
  isSelected,
}: {
  isLoading: boolean;
  plan: AssurantQuotePurchaseSchedule;
  isSelected: boolean;
  onSelect: () => void;
}) {
  return (
    <div>
      <p className="font-medium">Annual Plan</p>
      <div className="flex justify-between items-center mt-4">
        <div className="flex items-baseline">
          <p className="text-4xl font-light">${plan.downPaymentAmount}</p>
          <p>/yr</p>
        </div>
        <img src="/best_value.svg" alt="Best value" />
      </div>
      <div className="w-20 h-[1px] bg-red-400 my-6" />
      <div className="flex items-center">
        <FontAwesomeIcon
          icon="hand-holding-dollar"
          className="w-10 text-red-400"
        />
        <p>Pay all at once</p>
      </div>
      <div className="flex items-center mt-2">
        <FontAwesomeIcon icon="empty-set" className="w-10 text-red-400" />
        <p>No billing fees</p>
      </div>
      <div className="mt-4">
        <PaymentPlanButton
          isSelected={isSelected}
          onClick={onSelect}
          disabled={isLoading}
        >
          {isSelected ? (
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon="check" />
              <span>Selected</span>
            </div>
          ) : (
            <span>Select annual plan</span>
          )}
        </PaymentPlanButton>
      </div>
    </div>
  );
}

function QuarterlyPaymentPlan({
  isLoading,
  onSelect,
  isSelected,
  plan,
}: {
  isLoading: boolean;
  plan: AssurantQuotePurchaseSchedule;
  isSelected: boolean;
  onSelect: () => void;
}) {
  return (
    <div>
      <p className="font-medium">Quarterly Plan</p>
      <div className="flex items-baseline mt-4">
        <p className="text-4xl font-light">
          $
          {parseFloat(plan.installmentAmount!.toString()) +
            parseFloat(plan.serviceFee!.toString())}
        </p>
        <p>/quarter</p>
      </div>
      <p className="mt-2 text-blue-600">{`${formatCurrency(
        Number(plan.downPaymentAmount)
      )} down payment due today`}</p>
      <div className="w-20 h-[1px] bg-red-400 my-6" />
      <div className="flex items-center">
        <FontAwesomeIcon
          icon="hand-holding-dollar"
          className="w-10 text-red-400"
        />
        <p>Make 1 down payment today</p>
      </div>
      <div className="flex items-center mt-2">
        <FontAwesomeIcon
          icon={["far", "calendar"]}
          className="w-10 text-red-400 flex-shrink-0"
        />
        <p>
          Installment payments billed continuously every 3 months; includes
          premium & billing fees
        </p>
      </div>
      <div className="mt-4">
        <PaymentPlanButton
          isSelected={isSelected}
          onClick={onSelect}
          disabled={isLoading}
        >
          {isSelected ? (
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon="check" />
              <span>Selected</span>
            </div>
          ) : (
            <span>Select quarterly plan</span>
          )}
        </PaymentPlanButton>
      </div>
    </div>
  );
}

function EconomicPaymentPlan({
  isLoading,
  onSelect,
  isSelected,
  plan,
}: {
  isLoading: boolean;
  plan: AssurantQuotePurchaseSchedule;
  isSelected: boolean;
  onSelect: () => void;
}) {
  return (
    <div>
      <p className="font-medium">Economic Plan</p>
      <div className="flex items-baseline mt-4">
        <p className="text-4xl font-light">
          $
          {parseFloat(plan.installmentAmount!.toString()) +
            parseFloat(plan.serviceFee!.toString())}
        </p>
        <p>/month</p>
      </div>
      <p className="mt-2 text-blue-600">{`${formatCurrency(
        Number(plan.downPaymentAmount)
      )} down payment due today`}</p>
      <div className="w-20 h-[1px] bg-red-400 my-6" />
      <div className="flex items-center">
        <FontAwesomeIcon
          icon="hand-holding-dollar"
          className="w-10 text-red-400"
        />
        <p>Make 1 down payment today</p>
      </div>
      <div className="flex items-center mt-2">
        <FontAwesomeIcon
          icon={["far", "calendar"]}
          className="w-10 text-red-400 flex-shrink-0"
        />
        <p>
          Installment payments will be billed continuously each month. Includes
          your premium and billing fees
        </p>
      </div>
      <div className="mt-4">
        <PaymentPlanButton
          isSelected={isSelected}
          onClick={onSelect}
          disabled={isLoading}
        >
          {isSelected ? (
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon="check" />
              <span>Selected</span>
            </div>
          ) : (
            <span>Select economic plan</span>
          )}
        </PaymentPlanButton>
      </div>
    </div>
  );
}

function PaymentPlanButton({
  isSelected,
  onClick,
  disabled,
  children,
}: {
  isSelected: boolean;
  onClick: Function;
  disabled: boolean;
  children: React.ReactElement;
}) {
  return (
    <button
      className={classNames("w-full rounded-md p-2", {
        "bg-red-300 text-white flex items-center justify-center gap-2 ":
          isSelected,
        "border border-red-300 bg-white text-red-300": !isSelected,
      })}
      onClick={() => onClick()}
      disabled={disabled}
    >
      {children}
    </button>
  );
}

export { InsurancePaymentPlanPage };
