import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, useState, useReducer } from "react";
import { Link, useLocation } from "react-router-dom";
import InputMask from "react-input-mask";

import Alert from "../../components/Alert";
import { Brands, LoginWrapper } from "../../components/LoginForm";
import auth from "../../utils/auth";
import { formatPhone, stripPhone } from "../../utils/phone";
import { Spinner } from "../../components/Spinner";
import { textInputClass } from "../../components/Form";
import classNames from "classnames";
import { BRAND } from "@/utils/localStorage";

type LoginState = {
  isLoading: boolean;
  isComplete: boolean;
  view: "send" | "enter";
  error: string;
  phoneNumber: string;
  isCodeResent: boolean;
};

type Action =
  | { type: "set_view"; view: "send" | "enter" }
  | { type: "set_loading"; isLoading: boolean }
  | { type: "set_complete"; isComplete: boolean }
  | { type: "set_error"; error: string }
  | { type: "set_phone"; phoneNumber: string }
  | { type: "resend_code" };

function reducer(state: LoginState, action: Action): LoginState {
  switch (action.type) {
    case "set_view":
      return { ...state, view: action.view, error: "", isLoading: false };
    case "set_loading":
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case "set_complete":
      return {
        ...state,
        isComplete: true,
        isLoading: false,
      };
    case "set_error":
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case "set_phone":
      return {
        ...state,
        phoneNumber: action.phoneNumber,
      };
    case "resend_code":
      return {
        ...state,
        error: "",
        isCodeResent: true,
      };
    default:
      return state;
  }
}

function initialState(): LoginState {
  return {
    isLoading: false,
    isComplete: false,
    view: "send",
    error: "",
    phoneNumber: "",
    isCodeResent: false,
  };
}

export default function PasswordlessLoginPage({ brand }: { brand?: Brands }) {
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, undefined, initialState);

  const from = location.state?.from || "/login";
  localStorage.setItem(BRAND, brand || "livly");

  async function handleSendCode(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    dispatch({ type: "set_loading", isLoading: true });

    let formData = new FormData(event.currentTarget);
    let phoneNumber = formData.get("phone-number") as string;
    const strippedPhone = stripPhone(phoneNumber);

    try {
      await auth.sendCode({
        connection: "sms",
        send: "code",
        phoneNumber: `+1${strippedPhone}`,
      });

      dispatch({ type: "set_complete", isComplete: true });
      dispatch({ type: "set_view", view: "enter" });
      dispatch({ type: "set_phone", phoneNumber: strippedPhone });
    } catch (e) {
      dispatch({
        type: "set_error",
        error: e instanceof Error ? e.message : "Error sending code",
      });
    }
  }

  async function handleResend() {
    const phoneNumber = state.phoneNumber.replace(/[^\d]/g, "");

    try {
      await auth.sendCode({
        connection: "sms",
        send: "code",
        phoneNumber: `+1${phoneNumber}`,
      });
      dispatch({ type: "resend_code" });
    } catch (e) {
      dispatch({
        type: "set_error",
        error: e instanceof Error ? e.message : "Error sending code",
      });
    }
  }

  async function handleEnterCode(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    dispatch({ type: "set_loading", isLoading: true });

    let formData = new FormData(event.currentTarget);
    let code = formData.get("code") as string;
    const phoneNumber = stripPhone(state.phoneNumber);

    try {
      await auth.loginSms({
        connection: "sms",
        verificationCode: code,
        phoneNumber: `+1${phoneNumber}`,
      });
      dispatch({ type: "set_complete", isComplete: true });
    } catch (e) {
      dispatch({
        type: "set_error",
        error: e instanceof Error ? e.message : "Error entering code",
      });
    }
  }

  return (
    <LoginWrapper brand={brand}>
      {state.view === "send" ? (
        <SendCode
          error={state.error}
          handleSubmit={handleSendCode}
          isLoading={state.isLoading}
          from={from}
        />
      ) : (
        <EnterCode
          isCodeResent={state.isCodeResent}
          phoneNumber={state.phoneNumber}
          error={state.error}
          handleResend={handleResend}
          handleSubmit={handleEnterCode}
          isLoading={state.isLoading}
          from={from}
        />
      )}
    </LoginWrapper>
  );
}

function SendCode({
  error,
  handleSubmit,
  isLoading,
  from,
}: {
  error: string;
  isLoading: boolean;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
  from: string;
}) {
  return (
    <div className="flex flex-col justify-center flex-1">
      {error && <Alert message={error} />}
      <h2 className="mt-6 tracking-tight text-gray-900">
        Please enter your mobile number.
      </h2>
      <div className="mt-6">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="phone-number"
              className="block text-sm font-medium text-gray-700"
            >
              Phone number
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                +1
              </div>
              <InputMask mask="(999) 999-9999">
                {
                  //@ts-ignore
                  (inputProps: any) => {
                    return (
                      <input
                        {...inputProps}
                        autoFocus
                        type="text"
                        name="phone-number"
                        id="phone-number"
                        className={classNames(textInputClass, "pl-10")}
                      />
                    );
                  }
                }
              </InputMask>
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="relative flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white bg-gray-900 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              {isLoading && (
                <span className="absolute left-4">
                  <Spinner />
                </span>
              )}
              Send Code
            </button>
          </div>
          <div className="flex justify-between mt-2">
            <Link to="/login-email">log in with email</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

function EnterCode({
  phoneNumber,
  error,
  handleSubmit,
  handleResend,
  isLoading,
  from,
  isCodeResent,
}: {
  phoneNumber: string;
  error: string;
  isLoading: boolean;
  isCodeResent: boolean;
  handleResend: () => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => Promise<void>;
  from: string;
}) {
  return (
    <div className="flex flex-col justify-center flex-1">
      {error && <Alert message={error} />}
      {isCodeResent && (
        <div className="mt-1">
          <Alert variant="success" message="Code has been resent!" />
        </div>
      )}
      <h2 className="mt-6 tracking-tight text-gray-900">
        enter the 6-digit code sent {formatPhone(phoneNumber)}
      </h2>
      <div className="mt-6">
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="code"
              className="block text-sm font-medium text-gray-700"
            >
              Code
            </label>
            <div className="mt-1 rounded-md shadow-sm">
              <input
                autoFocus
                id="code"
                name="code"
                placeholder="123456"
                maxLength={6}
                type="tel"
                autoComplete="off"
                className="block w-full border-gray-300 rounded-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={isLoading}
              className="relative flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-white bg-gray-900 border border-transparent rounded-md shadow-sm hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
            >
              {isLoading && (
                <span className="absolute left-4">
                  <Spinner />
                </span>
              )}
              Log in
            </button>
          </div>

          <div>
            <button
              type="button"
              onClick={handleResend}
              className="relative flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-black bg-gray-200 border border-transparent rounded-md shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2"
            >
              Resend code
            </button>
          </div>

          <div className="flex justify-between mt-2">
            <Link to="/login-email">log in with email</Link>
          </div>
        </form>
      </div>
    </div>
  );
}
