import { ReactNode } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { resizeImageAsBase64 } from "../utils/resizeImage";

const MAX_TICKET_PHOTO_FILE_SIZE_MB = 15;
const maxFileSizeInBytes = MAX_TICKET_PHOTO_FILE_SIZE_MB * 1024 * 1024;

const PhotoUpload = ({
  onFilesUploadStart,
  onFilesUpload,
  onFilesUploadError,
  children,
}: {
  onFilesUploadStart?: () => void;
  onFilesUpload: (decodedFiles: string[], skippedFilesCount: number) => void;
  onFilesUploadError: (errorMessage: string) => void;
  children: (props: { onClick: () => void }) => ReactNode;
}) => {
  const { t } = useTranslation();

  const getBase64 = async (file: File): Promise<string> => {
    const base64 = await resizeImageAsBase64(file);

    return base64;
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    onDrop: async (acceptedFiles, rejectedFiles) => {
      const filteredFiles = acceptedFiles.filter((file) => {
        return file.size < maxFileSizeInBytes;
      });

      if (!filteredFiles || !filteredFiles.length) {
        onFilesUploadError(t("tickets.photo-upload.error"));
        return;
      }

      if (onFilesUploadStart) {
        onFilesUploadStart();
      }

      const decodedImages: string[] = [];

      await Promise.all(
        filteredFiles.map(async (file) => {
          return decodedImages.push(await getBase64(file));
        })
      );

      const skippedFilesCount =
        rejectedFiles.length + (acceptedFiles.length - decodedImages.length);

      onFilesUpload(decodedImages, skippedFilesCount);
    },
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children({ onClick: open })}
    </div>
  );
};

export { PhotoUpload };
