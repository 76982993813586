import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import useLivlyUser from "../../context/UserProvider";
import { OnboardingStatus, OnboardingStatusEnum } from "../../types/User";
import { useGetStatusTypeDetails, usePostService } from "./landing";
import { useState } from "react";
import { Button } from "../../components/Button";
import { isNativeAppVersion, returnToNative } from "@/utils/nativeAppHelpers";

export default function PetsAndVehiclesPage() {
  const params = useParams<{ leaseId: string }>();
  const navigate = useNavigate();
  const { user } = useLivlyUser();
  const details = useGetStatusTypeDetails(
    OnboardingStatusEnum.petAndVehicles,
    user
  );
  const { hasExistingPets, hasExistingVehicles, petsEnabled, vehiclesEnabled } =
    usePetsVehiclesService(details.onboardingStatus.metaData);

  const { updateOnboardingStatus, isLoading } = usePostService(
    user.userId,
    params.leaseId!,
    details.onboardingStatus
  );

  const onSubmit = async () => {
    const newOnboardingStatus: OnboardingStatus = {
      ...details.onboardingStatus,
      isCompleted: true,
    };
    await updateOnboardingStatus(newOnboardingStatus);

    if (isNativeAppVersion()) {
      returnToNative();
      return;
    }

    navigate("..");
  };

  const getPageTitle = () => {
    if (petsEnabled && vehiclesEnabled) {
      return "Pets and Vehicle";
    } else if (petsEnabled) {
      return "Pets";
    } else {
      return "Vehicles";
    }
  };

  if (petsEnabled && hasExistingPets) {
    return <Navigate to="pets" replace />;
  }

  if (vehiclesEnabled && hasExistingVehicles && !petsEnabled) {
    return <Navigate to="vehicles" replace />;
  }

  return (
    <Layout
      title={getPageTitle()}
      back={{ label: "Move-in Checklist", to: ".." }}
    >
      {petsEnabled ? (
        <Pets
          vehiclesEnabled={vehiclesEnabled}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
      ) : vehiclesEnabled ? (
        <Vehicles onSubmit={onSubmit} isLoading={isLoading} />
      ) : null}
    </Layout>
  );
}

function Pets({
  isLoading,
  vehiclesEnabled,
  onSubmit,
}: {
  isLoading: boolean;
  vehiclesEnabled: boolean;
  onSubmit: () => Promise<void>;
}) {
  const navigate = useNavigate();

  const onClickNo = () => {
    if (vehiclesEnabled) {
      navigate("vehicles");
    } else {
      onSubmit();
    }
  };

  return (
    <div>
      <h1>Add Pets</h1>
      <p>Do you have any pets?</p>
      <div className="flex gap-4 mt-4">
        <Link to="pets/add">
          <Button className="w-16">Yes</Button>
        </Link>
        <Button
          color="default"
          onClick={onClickNo}
          disabled={isLoading}
          className="w-16"
        >
          No
        </Button>
      </div>
    </div>
  );
}

function Vehicles({
  isLoading,
  onSubmit,
}: {
  isLoading: boolean;
  onSubmit: () => Promise<void>;
}) {
  const onClickNo = () => {
    onSubmit();
  };

  return (
    <div>
      <h1>Add Vehicles</h1>
      <p>Do you have any vehicles?</p>
      <div className="flex gap-4 mt-4">
        <Link to="vehicles/add">
          <Button className="w-16">Yes</Button>
        </Link>
        <Button
          color="default"
          onClick={onClickNo}
          disabled={isLoading}
          className="w-16"
        >
          No
        </Button>
      </div>
    </div>
  );
}

export interface PetsVehiclesMetaData {
  petsEnabled: boolean;
  hasExistingPets: boolean;
  vehiclesEnabled: boolean;
  hasExistingVehicles: boolean;
}

export function usePetsVehiclesService(metaData: string | undefined) {
  let serviceMetaData: PetsVehiclesMetaData = {
    petsEnabled: false,
    vehiclesEnabled: false,
    hasExistingPets: false,
    hasExistingVehicles: false,
  };

  if (metaData) {
    serviceMetaData = JSON.parse(metaData) as PetsVehiclesMetaData;
  }

  return serviceMetaData;
}
