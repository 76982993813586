import React, { useState, useEffect } from "react";
import moment from "moment-timezone";

const CountdownTimer = ({
  date,
  timezone,
}: {
  date: string;
  timezone: string;
}) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  function calculateTimeLeft() {
    const targetDate = moment.tz(date, timezone);
    const now = moment.tz(moment(), timezone);
    const diff = targetDate.diff(now);
    const duration = moment.duration(diff);

    /*  console.log("LEASE MOVE IN ", targetDate.format("MM/DD/YYYY h:mm:ss a"));
    console.log("NOW ", now.format("MM/DD/YYYY h:mm:ss a")); */

    return {
      months: duration.months(),
      days: duration.days(),
      hours: duration.hours(),
      minutes: duration.minutes(),
      seconds: duration.seconds(),
    };
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (timeLeft.seconds < 0) {
    return (
      <span className="inline-block mx-1 font-medium">ready to move in</span>
    );
  }

  return (
    <div>
      You have{" "}
      <span className="font-medium">
        {timeLeft.months > 0 && (
          <span>
            {timeLeft.months} month{timeLeft.months > 1 ? "s" : ""}{" "}
          </span>
        )}
        {timeLeft.days > 0 && (
          <span>
            {timeLeft.days} day{timeLeft.days > 1 ? "s" : ""}{" "}
          </span>
        )}
        {timeLeft.hours > 0 && (
          <span>
            {timeLeft.hours} hour{timeLeft.hours > 1 ? "s" : ""}{" "}
          </span>
        )}
        {timeLeft.minutes > 0 && (
          <span>
            {timeLeft.minutes} minute{timeLeft.minutes > 1 ? "s" : ""}{" "}
          </span>
        )}
        <span>
          {timeLeft.seconds} second{timeLeft.seconds !== 1 ? "s" : ""}
        </span>
      </span>{" "}
      til move-in
    </div>
  );
};

export default CountdownTimer;
