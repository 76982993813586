import React from "react";
import { Link } from "react-router-dom";

import useLivlyUser from "@/context/UserProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ServiceProviderType, ServiceTypeEnum } from "@/types/Building";

export default function RentRealPageNotice() {
  const { user } = useLivlyUser();
  const {
    building: { serviceTypes },
  } = user;

  const rentService = serviceTypes?.find(
    (service) => service.serviceType === ServiceTypeEnum.Rent
  );

  if (
    rentService?.enabled &&
    rentService.serviceProviderType === ServiceProviderType.RealPage
  ) {
    return (
      <div className="m-6">
        <Link to="rent">
          <div className="flex items-center rounded-lg px-3 py-2 bg-blue-100">
            <div className="flex-1">
              <h3 className="text-lg font-semibold">First thing's first</h3>
              <p>Tap here to pay your first month’s rent.</p>
            </div>
            <FontAwesomeIcon icon="arrow-right" className="text-blue-600" />
          </div>
        </Link>
      </div>
    );
  }

  return null;
}
