import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactConfetti from "react-confetti";
import numeral from "numeral";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

interface PaymentConfirmationProps {
  amount?: number;
  transactionId?: string;
  description?: string;
}

function PaymentConfirmation({
  amount,
  transactionId,
  description,
}: PaymentConfirmationProps) {
  const { t } = useTranslation();

  return (
    <div>
      <ReactConfetti
        style={{ pointerEvents: "none" }}
        numberOfPieces={250}
        recycle={false}
        initialVelocityY={5}
        onConfettiComplete={(confetti: any) => {
          confetti.reset();
        }}
        colors={["#4ec779"]}
        //@ts-ignore
        canvasRef={undefined}
      />
      <div className="flex flex-col items-center">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ rotate: 360, scale: 1.2 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20,
            delay: 0.5,
          }}
        >
          <FontAwesomeIcon
            icon={["far", "circle-check"]}
            className="text-6xl text-green-400"
          />
        </motion.div>
        <h4 className="mt-8 text-3xl">{numeral(amount).format("$0,0[.]00")}</h4>
        <p className="mt-4">{t("rent.label.successful-payment")}</p>
      </div>
      {transactionId && (
        <div className="mt-4">
          <p className="text-sm text-gray-500">
            {t("rent.label.transaction-id")}
          </p>
          <p className="mt-1">{transactionId}</p>
        </div>
      )}
      {description && (
        <div className="mt-4">
          <p className="text-sm text-gray-500">{t("rent.label.payment")}</p>
          <p className="mt-1">{description}</p>
        </div>
      )}
    </div>
  );
}

export { PaymentConfirmation };
