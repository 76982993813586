import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import {
  useParams,
  useNavigate,
  Navigate,
  useOutletContext,
  useLocation
} from "react-router-dom";
import GuestForm from "../../components/GuestForm";
import Layout from "../../components/Layout";
import { BASE_API_URL } from "../../utils/constants";
import { GuestResponse } from "./my-guests";
import useLivlyGuest, {
  DelegateServiceTypes,
  GuestRequest
} from "@/context/GuestProvider";
import useLivlyUser from "@/context/UserProvider";

function useUpsertGuest(leaseId: string, propertyId: number) {
  const postGuest = async (guest: GuestRequest) => {
    return await axios.post(
      `${BASE_API_URL}/resident/guests/property/${propertyId}/lease/${leaseId}`,
      guest
    );
  };

  return useMutation(postGuest);
}

function useGetGuest(leaseId: string, id: string) {
  const queryClient = useQueryClient();

  const guests: GuestResponse[] =
    queryClient.getQueryData(["lease-guests", leaseId]) ?? [];
  const guest = guests.find((g) => g.guestId === Number(id));
  return guest;
}

function useGetDeligateServices(propertyId: number, leaseId: string) {
  const queryClient = useQueryClient();

  const services: DelegateServiceTypes[] =
    queryClient.getQueryData(["delegate-services", propertyId, leaseId]) ?? [];
  return services;
}

export default function EditGuestPage() {
  const { isGuestServiceDisabled } = useOutletContext<{
    isGuestServiceDisabled: boolean;
  }>();
  const { redirect = false } =
    (useLocation().state as {
      redirect: false;
    }) || {};

  const { user } = useLivlyUser();
  const { resetGuest } = useLivlyGuest();
  const queryClient = useQueryClient();
  const { id, leaseId } = useParams<{ id: string; leaseId: string }>();
  const navigate = useNavigate();
  const { mutate, isLoading, error } = useUpsertGuest(
    leaseId!,
    user.propertyId
  );

  const guest = useGetGuest(leaseId!, id!);
  const services = useGetDeligateServices(user.propertyId, leaseId!);

  if (guest == null) {
    return <Navigate to={`/lease/${leaseId}/guests`} />;
  }

  const onSubmit = (newGuest: GuestRequest) => {
    mutate(newGuest, {
      onSuccess: () => {
        navigate("../guests");
        queryClient.invalidateQueries(["lease-guests"]);
        resetGuest();
      }
    });
  };

  return (
    <Layout back={{ to: "../guests", label: "My Guests" }} title="Edit Guest">
      <div className="max-w-3xl mx-auto">
        {isGuestServiceDisabled ? (
          <div>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Oooops
            </h3>
            <div className="mt-4">
              <p>
                You do not have access to this feature. Please speak to your
                property staff with any questions.
              </p>
            </div>
          </div>
        ) : (
          <>
            {guest && services && (
              <GuestForm
                error={error}
                initialGuest={guest}
                onSubmit={onSubmit}
                services={services ?? []}
                redirect={redirect}
              />
            )}
          </>
        )}
      </div>
    </Layout>
  );
}
