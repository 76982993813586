import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";
import Avatar from "../../components/Avatar";
import { Button, ButtonWrapper } from "../../components/Button";
import Layout from "../../components/Layout";
import { Spinner } from "../../components/Spinner";
import useGetService from "../../hooks/useGetService";
import { ServiceTypeEnum } from "../../types/Building";
import {
  BuildingTypeEnum,
  OnboardingStatus,
  OnboardingStatusEnum,
} from "../../types/User";
import { trackConfirmRoommates } from "../../utils/analytics";
import formatCurrency from "../../utils/formatCurrency";
import { useGetStatusTypeDetails, usePostService } from "./landing";
import useLivlyUser from "../../context/UserProvider";
import { isNativeAppVersion, returnToNative } from "@/utils/nativeAppHelpers";

export default function ChecklistRoommatesPage() {
  const params = useParams<{ leaseId: string }>();
  const navigate = useNavigate();
  const { user } = useLivlyUser();
  const isRentEnabled = useGetService(ServiceTypeEnum.Rent)?.enabled;
  const isApartmentBuilding =
    user.presentation.buildingType === BuildingTypeEnum.Apartments;
  const details = useGetStatusTypeDetails(OnboardingStatusEnum.profile, user);
  const { updateOnboardingStatus, isLoading } = usePostService(
    user.userId,
    params.leaseId!,
    details.onboardingStatus
  );

  const onNext = async () => {
    trackConfirmRoommates(user.roommates?.length ?? 0);

    if (!user.avatarURI) {
      navigate("../avatar");
    } else {
      const newOnboardingStatus: OnboardingStatus = {
        ...details.onboardingStatus,
        isCompleted: true,
      };
      await updateOnboardingStatus(newOnboardingStatus);

      if (isNativeAppVersion()) {
        returnToNative();
        return;
      }

      navigate("..");
    }
  };

  return (
    <Layout title="Roommates" back={{ label: "Profile", to: "../profile" }}>
      <div className="pb-24">
        <FontAwesomeIcon
          icon={["far", "users"]}
          className="text-3xl text-red-300"
        />
        <h2 className="mt-2 text-lg font-medium">your roommates</h2>
        <p className="text-sm">
          here's a list of all parties associated to your lease and the amount
          they've paid so far.
        </p>
        <ul className="m-0 mt-6 space-y-2 divide-y divide-gray-200">
          {user.roommates?.map((r) => (
            <li key={r.userId} className="flex items-center gap-4 pt-2">
              <Avatar src={r.avatarURI} name={`${r.firstName} ${r.lastName}`} />
              <div className="flex-1">
                <p>
                  {r.firstName} {r.lastName}
                </p>
                <div className="flex justify-between">
                  {isApartmentBuilding && (
                    <p className="text-sm text-gray-500">{r.userType}</p>
                  )}
                  {isRentEnabled && isApartmentBuilding && (
                    <p className="text-lg text-gray-600">
                      {formatCurrency(r.rentAmount).replace(".00", "")}
                    </p>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <ButtonWrapper>
        <Button
          onClick={onNext}
          className="flex items-center w-full gap-2 md:w-auto"
          color="secondary"
          disabled={isLoading}
        >
          {isLoading && <Spinner />}
          Looks good! Next
        </Button>
      </ButtonWrapper>
    </Layout>
  );
}
