import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "./Spinner";

const ImagesPreview = ({ image, count, alt, onClick }: Props) => {
  const { t } = useTranslation();
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const img = new Image();
  img.onload = () => {
    setIsImageLoaded(true);
  };

  img.onerror = () => {
    setIsImageLoaded(true);
  };

  img.src = image;

  if (isImageLoaded) {
    return (
      <div className="relative">
        <img
          className="w-full cursor-pointer"
          alt={alt}
          src={image}
          onClick={onClick}
        />
        {count > 1 && (
          <div className="absolute px-2 py-1 bg-gray-300 rounded-md right-2 bottom-2 opacity-60">
            <p>{t("images.see-all", { count })}</p>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="w-full h-[100px] flex items-center justify-center">
      <Spinner color="livly" />
    </div>
  );
};

interface Props {
  image: string;
  count: number;
  alt?: string;
  onClick: () => void;
}

export default ImagesPreview;
