import moment, { Moment } from "moment-timezone";
import { forwardRef } from "react";
import Alert from "@/components/Alert";
import DatePicker from "react-datepicker";
import { textInputClass } from "@/components/Form";
import { addDays } from "date-fns";

type Props = {
  day: number | null;
  isAutoPayActive: boolean;
  startDate: string | Date | null;
  endDate: string | Date | null;
  isStartError: boolean;
  isEndError: boolean;
  isStartAfterEndError: boolean;
  setStartDate: (startDate: Moment | null) => void;
  setEndDate: (endDate: Moment | null) => void;

  agreedToEndDateOverride: boolean;
  setAgreedToEndDateOverride: (agreed: boolean) => void;
};

const PaymentStartStop = ({
  isAutoPayActive,
  startDate,
  endDate,
  day,
  isStartError,
  isEndError,
  isStartAfterEndError,
  setStartDate,
  setEndDate,
  agreedToEndDateOverride,
  setAgreedToEndDateOverride,
}: Props) => {
  const ExampleCustomInput = forwardRef(
    ({ value, onClick, className, ...rest }: any, ref) => (
      <input
        className={textInputClass}
        onClick={onClick}
        //@ts-ignore
        ref={ref}
        value={value}
        {...rest}
      />
    )
  );

  return (
    <div id="autopay-start-stop" className="pt-8 auto-pay-dates">
      <div className="flex justify-between gap-8 md:justify-start">
        <div>
          <p>Start date</p>
          <div className="border-b border-gray-200">
            <div className="md:hidden">
              <input
                type="date"
                className="border-none"
                value={startDate ? formatDate(startDate) : ""}
                min={formatDate(addDays(new Date(), 1))}
                disabled={isAutoPayActive || !day}
                onChange={(e) => {
                  const date = moment(e.target.value);
                  setStartDate(date);
                }}
              />
            </div>
            <div className="hidden md:block">
              <DatePicker
                minDate={addDays(new Date(), 1)}
                selected={startDate ? new Date(startDate) : null}
                disabled={isAutoPayActive || !day}
                onChange={setStartDate}
                customInput={
                  <ExampleCustomInput disabled={isAutoPayActive || !day} />
                }
              />
            </div>
          </div>
        </div>
        <div>
          <p>End date</p>
          <div className="border-b border-gray-200">
            <div className="md:hidden">
              <input
                type="date"
                className="border-none"
                value={endDate ? formatDate(endDate) : ""}
                min={formatDate(addDays(new Date(), 1))}
                disabled={isAutoPayActive || !day}
                onChange={(e) => {
                  const date = moment(e.target.value);
                  setEndDate(date);
                }}
              />
            </div>
            <div className="hidden md:block">
              <DatePicker
                minDate={addDays(new Date(), 1)}
                selected={endDate ? new Date(endDate) : null}
                disabled={isAutoPayActive || !day}
                onChange={setEndDate}
                customInput={
                  <ExampleCustomInput disabled={isAutoPayActive || !day} />
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-4">
        {isStartError && (
          <Alert variant="danger" message="Start date must be after today." />
        )}
        {isStartAfterEndError && (
          <Alert
            variant="danger"
            message="Start date cannot be after end date."
          />
        )}
        {isEndError && (
          <div className="mt-6">
            <label className="flex items-start gap-4">
              <input
                className="mt-2"
                type="checkbox"
                checked={agreedToEndDateOverride}
                onChange={(e) => setAgreedToEndDateOverride(e.target.checked)}
              />
              <p>
                The date selected is within 30 days or past your lease end date.
                Autopayments will be processed on the{" "}
                {moment()
                  .month(0)
                  .date(day ?? 1)
                  .format("Do")}{" "}
                of the month until {moment(endDate).format("M/D/YYYY")}. Select
                the checkbox to confirm payments can be processed every month
                through {moment(endDate).format("M/D/YYYY")}
              </p>
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export { PaymentStartStop };

function formatDate(date: string | Date | null) {
  return moment(date).format("YYYY-MM-DD");
}
