import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";

import axios from "axios";
import { BaseLivlyApiResponse } from "../types/Base";
import { BASE_API_URL } from "../utils/constants";
import { QueryClient, useQuery } from "@tanstack/react-query";
import {
  Link,
  LoaderFunctionArgs,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { BuildingServiceType, ServiceTypeEnum } from "../types/Building";
import Layout from "../components/Layout";
import MakeAnotherPayment from "../components/MakeAnotherPayment";
import {
  trackMarketplaceOfferClick,
  trackMarketplacePageView,
} from "../utils/analytics";
import { Spinner } from "../components/Spinner";
import useLivlyUser from "../context/UserProvider";
import {
  MarketplaceContext,
  Vendor,
  VendorOffer,
} from "@/context/MarketplaceLayout";

export default function MarketplacePage() {
  const { data, isLoading } = useOutletContext<MarketplaceContext>();
  const { user } = useLivlyUser();

  const { displayName } =
    user.building?.serviceTypes?.find(
      (service: BuildingServiceType) =>
        service.serviceType === ServiceTypeEnum.MarketPlace
    ) || {};

  const availableVendors = data?.filter(
    (vendor) => vendor.vendorOffers.length > 0
  );
  const isChecklistComplete = user.onboardingStatuses
    .filter((ob) => ob.enabled)
    .every((ob) => ob.isCompleted || ob.isSkipped);

  useEffect(() => {
    trackMarketplacePageView();
  }, []);

  return (
    <Layout title={displayName || "Building Offers"}>
      {isLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <>
          {user.status === "Applicant" && (
            <h2 className="mb-4">
              prepare your new apartment before you move in.
            </h2>
          )}
          <MakeAnotherPayment isChecklistComplete={isChecklistComplete} />
          <div className="mt-4">
            {(availableVendors ?? []).length > 0 ? (
              <ul>
                {availableVendors?.map((vendor) => (
                  <VendorItem key={vendor._id} vendor={vendor} />
                ))}
              </ul>
            ) : (
              <div className="flex items-center justify-center flex-1 my-56">
                There are no building offers available
              </div>
            )}
          </div>
        </>
      )}
    </Layout>
  );
}

function VendorItem({ vendor }: { vendor: Vendor }) {
  const hasSingleOffer = vendor && vendor.vendorOffers.length === 1;

  return (
    <li className="mb-8">
      <div className="mb-2">
        {!hasSingleOffer && (
          <>
            {vendor.vendorLogo ? (
              <img
                src={vendor.vendorLogo}
                alt={vendor.vendorName}
                className="h-8"
              />
            ) : (
              <h5>{vendor.vendorName}</h5>
            )}
            {vendor.vendorSubtitle && (
              <p className="text-gray-500">{vendor.vendorSubtitle}</p>
            )}
          </>
        )}
      </div>
      <div className="flex w-full pb-4 overflow-x-auto overflow-y-hidden flex-nowrap">
        {hasSingleOffer ? (
          <VendorOfferHeroItem
            offer={vendor.vendorOffers[0]}
            vendor={vendor}
            trackMarketplaceOfferClick={() => {
              trackMarketplaceOfferClick(vendor.vendorOffers[0].offerTitle);
            }}
          />
        ) : (
          vendor.vendorOffers.map((offer: VendorOffer, offerIndex: number) => (
            <VendorOfferItem
              key={offerIndex}
              offer={offer}
              vendor={vendor}
              trackMarketplaceOfferClick={() => {
                trackMarketplaceOfferClick(vendor.vendorOffers[0].offerTitle);
              }}
            />
          ))
        )}
      </div>
    </li>
  );
}

function VendorOfferHeroItem({
  vendor,
  offer,
  trackMarketplaceOfferClick,
}: {
  vendor: Vendor;
  offer: VendorOffer;
  trackMarketplaceOfferClick: (title: string) => void;
}) {
  const image = offer.offerSquareImage;

  return (
    <Link
      to={`${vendor._id}/${offer._id}`}
      onClick={() => {
        trackMarketplaceOfferClick(offer.offerTitle);
      }}
    >
      <div className="cursor-pointer max-w-[400px]">
        <img
          src={image}
          className="object-contain w-full rounded-lg"
          alt={offer.offerTitle}
        />
      </div>
    </Link>
  );
}

function VendorOfferItem({
  vendor,
  offer,
  trackMarketplaceOfferClick,
}: {
  vendor: Vendor;
  offer: VendorOffer;
  trackMarketplaceOfferClick: (title: string) => void;
}) {
  return (
    <div className="flex flex-col flex-shrink-0 mr-4 w-36">
      <Link
        to={`${vendor._id}/${offer._id}`}
        className="flex mb-2"
        onClick={() => {
          trackMarketplaceOfferClick(offer.offerTitle);
        }}
      >
        {offer.offerSquareImage ? (
          <img
            className="w-[150px] h-[150px] object-cover bg-no-repeat bg-center"
            src={offer.offerSquareImage}
            alt={offer.offerSubTitle}
          />
        ) : (
          <div className="flex items-center justify-center w-full bg-gray-200 h-36">
            <FontAwesomeIcon icon="image" size="lg" className="text-gray-500" />
          </div>
        )}
      </Link>
      <div className="mb-4">
        <p className="mb-2 text-xs text-gray-500">{vendor.vendorName}</p>
        <p className="leading-none">{offer.offerTitle}</p>
      </div>
      <Link to={`${vendor._id}/${offer._id}`}>
        <p className="text-sm font-semibold text-red-400">
          {offer.offerCtaText}
        </p>
      </Link>
    </div>
  );
}
