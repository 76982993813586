import { useNavigate, useOutletContext } from "react-router-dom";
import { FeedbackExternalContext } from "./context";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import {
  defineFeedbackFormOptions,
  Feedback,
  FeedbackRequest,
  FeedbackType,
} from "@/components/Feedback/feedback";
import { EXTERNAL_API_URL } from "@/utils/constants";
import { Spinner } from "@/components/Spinner";
import Alert from "@/components/Alert";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { RatingIcon } from "@/components/RatingIcon";
import { textAreaClass } from "@/components/Form";
import FeedbackForm from "@/components/Feedback/FeedbackForm";
import { Modal } from "@/components/Dialog";

export const getExternalFeedback = async (token: string) => {
  const result = await axios.get<Feedback>(
    `${EXTERNAL_API_URL}/feedback?ltoken=${token}`
  );

  return result.data;
};

export const postExternalFeedback = async (
  feedback: FeedbackRequest,
  token: string
) => {
  const result = await axios.post<Feedback>(
    `${EXTERNAL_API_URL}/feedback?ltoken=${token}`,
    feedback
  );

  return result.data;
};

function FeedbackExternalSubmitPage() {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const [feedback, setFeedback] = useState<FeedbackRequest | null>(null);
  const { feedbackId, feedbackType, token, property, rating } =
    useOutletContext<FeedbackExternalContext>();
  const { data, isError, isLoading } = useQuery({
    queryKey: ["feedback", feedbackId],
    queryFn: () => getExternalFeedback(token),
  });

  const { mutate, isLoading: isSaving } = useMutation((data: FeedbackRequest) =>
    postExternalFeedback(data, token)
  );

  useEffect(() => {
    if (data) {
      setFeedback({
        feedbackId: data.residentFeedbackId,
        rating: rating || data.rating,
        comment: data.comment,
        feedbackItems: data.feedbackItems,
      });
    }
  }, [data]);

  const onSetFeedback = (newFeedback: Partial<FeedbackRequest>) => {
    if (feedback) {
      setFeedback({ ...feedback, ...newFeedback });
    }
  };

  const handleSubmit = (feedbackRequest: FeedbackRequest) => {
    mutate(feedbackRequest, {
      onSuccess: (data) => {
        if (data.rating === 3 && data.comment && navigator.clipboard) {
          navigator.clipboard.writeText(data.comment);
          toast.success("Your comment is copied to the clipboard");
        }

        navigate("/feedback/thank-you", {
          state: { reviewLocations: data.reviewLocations ?? [] },
        });
      },
      onError: (error) => {
        const errorObject = error as { status?: number };
        if (errorObject.status === 403) {
          setShowDialog(true);
        }
      },
    });
  };

  if (isError) {
    return (
      <div className="p-4">
        <Alert message="We cannot process your request." />
      </div>
    );
  }

  if (data) {
    const { subtitle, ratingTitle, feedbackOptions } =
      defineFeedbackFormOptions(data, feedback, property?.propertyName ?? "");
    const title = getFeedbackHeader(feedbackType, property?.propertyName);

    return (
      <div className="max-w-lg p-4 mx-auto">
        <FeedbackForm
          title={title}
          subtitle={subtitle}
          ratingTitle={ratingTitle}
          feedback={feedback}
          feedbackOptions={feedbackOptions ?? []}
          onSetFeedback={onSetFeedback}
          onSubmit={handleSubmit}
          status={isLoading || isSaving ? "loading" : "idle"}
        />
        <Modal open={showDialog} onClose={() => setShowDialog(false)}>
          <h2>Oooops</h2>
          <div>
            <p>
              You do not have access to this feature. Please speak to your
              property staff with any questions.
            </p>
            <div className="mt-4">
              <button className="w-full" onClick={() => setShowDialog(false)}>
                Got it
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
    /* 
    return (
      <form onSubmit={handleSubmit} className="max-w-lg p-4 mx-auto">
        <fieldset disabled={isSaving}>
          <div className="flex items-center justify-center flex-col mt-4">
            <h2 className="text-xl font-semibold text-center">{header}</h2>
            <p className="text-sm">Rate your experience below</p>
          </div>
          <FeedbackExternalSubmit
            rating={data.rating}
            options={data.options}
            comment={data.comment}
          />
          <div className="mt-4">
            <Button type="submit" color="secondary" className="w-full">
              Submit
            </Button>
          </div>
          <Modal open={showDialog} onClose={() => setShowDialog(false)}>
            <h2>Oooops</h2>
            <div>
              <p>
                You do not have access to this feature. Please speak to your
                property staff with any questions.
              </p>
              <div className="mt-4">
                <button className="w-full" onClick={() => setShowDialog(false)}>
                  Got it
                </button>
              </div>
            </div>
          </Modal>
        </fieldset>
      </form>
    ); */
  }

  return (
    <div className="flex justify-center items-center h-screen">
      <Spinner color="blue" size="xl" />
    </div>
  );
}

export default FeedbackExternalSubmitPage;

function FeedbackExternalSubmit({
  rating,
  options,
  comment,
}: Pick<Feedback, "rating" | "comment" | "options">) {
  const [selectedRating, setSelectedRating] = useState(rating);
  const isPositiveRating = selectedRating > 1;
  const availableOptions = isPositiveRating
    ? options.positive
    : options.negative;

  return (
    <div>
      <ul className="flex justify-center gap-4 py-8">
        {[0, 1, 2, 3].map((r) => (
          <button
            key={r}
            onClick={() => setSelectedRating(r)}
            className="rounded-full"
            type="button"
          >
            <RatingIcon
              rating={r}
              isSelected={r === selectedRating}
              size="3x"
            />
          </button>
        ))}
      </ul>

      {availableOptions.length > 0 && (
        <>
          <div>
            <p className="font-md text-lg">
              {isPositiveRating
                ? "Great! Tell us what can be improved?"
                : "How can your experience be improved?"}
            </p>
            <p className="text-[#262B2BCC] text-sm mt-4">
              We will pass this information along to your property manager.
              Select all that apply.
            </p>
          </div>
          <ul className="m-0 mt-4 list-none space-y-2">
            {availableOptions.map((option) => (
              <li key={option.residentFeedbackItemOptionTypeId}>
                <label className="w-full flex items-center gap-2">
                  <input
                    type="checkbox"
                    name="feedbackOptions"
                    value={option.residentFeedbackItemOptionTypeId}
                  />
                  {option.description}
                </label>
              </li>
            ))}
          </ul>
        </>
      )}

      <div className="mt-4">
        <p>Leave us a comment</p>
        <textarea
          className={textAreaClass}
          rows={4}
          name="comment"
          defaultValue={comment}
        />
      </div>
      <input type="hidden" name="rating" value={selectedRating} />
    </div>
  );
}

function getFeedbackHeader(
  feedbackType: FeedbackType | null,
  propertyName: string | undefined
) {
  switch (feedbackType) {
    case "AmenityBooking":
      return `How’s your experience with this amenity booking?`;
    case "Event":
      return `How’s your experience with this event?`;
    case "MaintenanceTicket":
    case "MaintenanceAIChat":
      return `How’s your experience with this maintenance service?`;
    case "Property":
      return `How’s your experience with ${propertyName}?`;
    default:
      return `How has your experience been?`;
  }
}
