import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
  PlaidLinkOnSuccessMetadata,
  PlaidLinkOnExit,
  PlaidLinkError,
  PlaidLinkOnExitMetadata,
  PlaidLinkStableEvent,
  PlaidLinkOnEventMetadata,
} from "react-plaid-link";
import { useLocation, useNavigate } from "react-router-dom";
import Alert from "../../components/Alert";
import Layout from "../../components/Layout";
import { BaseLivlyApiResponse } from "../../types/Base";
import { PaymentAccount } from "../../types/User";
import { BASE_API_URL } from "../../utils/constants";
import useLink from "../../context/LinkToken";
import { trackAddPaymentAccount } from "../../utils/analytics";
import useLivlyUser from "../../context/UserProvider";

export default function AddACHPageContainer() {
  const { user } = useLivlyUser();

  const [token, setToken] = useState("");
  const { generateLinkToken, linkToken } = useLink();

  useEffect(() => {
    generateLinkToken(user.userId);
  }, [user.userId, generateLinkToken]);

  useEffect(() => {
    setToken(linkToken);
  }, [linkToken]);

  if (token) {
    return <AddACHPage token={token} userId={user.userId} />;
  }

  return null;
}

type AddACHRequestModel = {
  plaidPublicToken: string;
  accountId: string;
};

async function postACH({
  data,
  userId,
}: {
  data: AddACHRequestModel;
  userId: number;
}) {
  const result = await axios.post<BaseLivlyApiResponse<PaymentAccount>>(
    `${BASE_API_URL}/livly/payment/AddACHAccount/${userId}`,
    data
  );

  return result.data.Data;
}

function AddACHPage({ token, userId }: { token: string; userId: number }) {
  const returnData = useLocation().state as { to: string; label: string };
  const { user } = useLivlyUser();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const queryClient = useQueryClient();
  const { generateLinkToken } = useLink();

  const { mutate, isLoading, isError } = useMutation(
    (data: AddACHRequestModel) => postACH({ data, userId: user.userId })
  );

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (public_token: string, metadata: PlaidLinkOnSuccessMetadata) => {
      mutate(
        {
          plaidPublicToken: public_token,
          accountId: metadata.accounts[0].id,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(["payment-accounts"]);
            setError("");
            navigate(`../wallet`);
          },
          onError: (e) => {
            const error = e as { data: { Message: string } };
            const message = error.data.Message ?? "Error adding ACH";
            setError(message);
          },
        }
      );
    },
    []
  );

  const onExit = useCallback<PlaidLinkOnExit>(
    async (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
      // log and save error and metadata
      // handle invalid link token
      if (error != null && error.error_code === "INVALID_LINK_TOKEN") {
        // generate new link token
        await generateLinkToken(userId);
      }

      if (error != null) {
        setError(error.display_message || error.error_message);
      } else {
        if (metadata.status === "institution_not_found") {
          navigate(`../wallet/initiate-account`);
        } else {
          navigate(returnData ? returnData.to : `../wallet`);
        }
      }
      // to handle other error codes, see https://plaid.com/docs/errors/
    },
    [navigate]
  );

  const onEvent = async (
    eventName: PlaidLinkStableEvent | string,
    metadata: PlaidLinkOnEventMetadata
  ) => {
    // handle errors in the event end-user does not exit with onExit function error enabled.
    if (eventName === "ERROR" && metadata.error_code != null) {
      setError(metadata.error_code);
    }
  };

  const config: PlaidLinkOptions = {
    token,
    clientName: "Livly Onboarding",
    env: `${import.meta.env.VITE_PLAID_ENVIRONMENT}`,
    product: ["auth"],
    publicKey: import.meta.env.VITE_PLAID_KEY,
    onSuccess,
    onExit,
    onEvent,
  };

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    // initiallizes Link automatically
    if (ready) {
      open();
    }
  }, [ready, open]);

  useEffect(() => {
    trackAddPaymentAccount();
  }, []);

  return (
    <Layout title="Add ACH">
      {isError ? (
        <Alert message={error} />
      ) : isLoading ? (
        <div>
          <p>Connecting your account. Please wait...</p>
        </div>
      ) : null}
    </Layout>
  );
}
