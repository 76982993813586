import { createContext, useContext, useMemo, useState } from "react";

interface UIContextShape {
  hideNavbar: boolean;
  setHideNavbar: (isSet: boolean) => void;
}

const initialState: UIContextShape = {
  hideNavbar: false,
  setHideNavbar: () => {},
};

const UIContext = createContext<UIContextShape>(initialState as UIContextShape);

function UIProvider({ children }: { children: React.ReactNode }) {
  const [hideNavbar, setHideNavbar] = useState(false);

  const value = useMemo(
    () => ({
      hideNavbar,
      setHideNavbar,
    }),
    [hideNavbar, setHideNavbar]
  );

  return <UIContext.Provider value={value}>{children}</UIContext.Provider>;
}

export { UIProvider };

export default function useUI() {
  const context = useContext(UIContext);
  if (!context) {
    throw new Error(`useUI must be used within a UIProvider`);
  }

  return context;
}
