import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BASE_API_URL } from "../../utils/constants";
import { Navigate, To, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import PetForm, { PetRequest } from "../../components/PetForm";
import useLivlyUser from "../../context/UserProvider";
import { PetResponse } from "../pets/pets";
import { useEffect } from "react";

function usePatchPet(leaseId: string, userId: number, petId: string) {
  const postPet = async (pet: PetRequest) => {
    return await axios.patch(
      `${BASE_API_URL}/livly/lease/${leaseId}/users/${userId}/pets/${petId}`,
      pet
    );
  };

  return useMutation(postPet);
}

function useGetPet(leaseId: string, id: string) {
  const queryClient = useQueryClient();

  const pets: PetResponse[] =
    queryClient.getQueryData(["lease-pets", leaseId]) ?? [];
  const pet = pets.find((p) => p.id === Number(id));

  return pet;
}

export default function ChecklistEditPetPage() {
  const queryClient = useQueryClient();
  const { id, leaseId } = useParams<{ id: string; leaseId: string }>();
  const { user } = useLivlyUser();
  const navigate = useNavigate();
  const { mutate, isLoading, error } = usePatchPet(leaseId!, user.userId, id!);
  const pet = useGetPet(leaseId!, id!);

  const onSubmit = (newPet: PetRequest) => {
    mutate(
      {
        ...newPet,
        petType: newPet.petTypeRadio || newPet.petType,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["lease-pets"]);
          navigate(`../pets`);
        },
      }
    );
  };

  return (
    <Layout
      back={{
        to: `../pets`,
        label: "My Pets",
      }}
      title="Edit Pet"
    >
      <div className="max-w-3xl mx-auto">
        <PetForm
          isLoading={isLoading}
          error={error}
          initialPet={pet}
          onSubmit={onSubmit}
          returnTo={`../pets-vehicles/${user.propertyId}/pets`}
        />
      </div>
    </Layout>
  );
}
