import { Button } from "@/components/Button";
import Layout from "@/components/Layout";
import LivlySwitch from "@/components/LivlySwitch";
import useLivlyGuest from "@/context/GuestProvider";
import useGetService from "@/hooks/useGetService";
import { ServiceTypeEnum } from "@/types/Building";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// Service Types to be excluded from additional features
const excludedServiceTypes = [
  ServiceTypeEnum.InfoDesk,
  ServiceTypeEnum.MarketPlace,
  ServiceTypeEnum.LivlyLocks
];

// Service Types to be include default from additional features
const defaultServiceTypes = [
  ServiceTypeEnum.InfoDesk,
  ServiceTypeEnum.MarketPlace
];

const getServiceDescriptionTrans = (type: ServiceTypeEnum) => {
  switch (type) {
    case ServiceTypeEnum.Rent:
      return "View and pay your rent.";
    case ServiceTypeEnum.RentersInsurance:
      return "View and pay your renters insurance.";
    case ServiceTypeEnum.Maintenance:
      return "Create maintenance tickets on your behalf.";
    case ServiceTypeEnum.Utility:
      return "View and setup building utilities.";
    case ServiceTypeEnum.Amenities:
      return "View and book building amenities.";
    case ServiceTypeEnum.Packages:
      return "View your packages and mark as picked up.";
    case ServiceTypeEnum.CommFeed:
      return "View and post to the community feed.";
    case ServiceTypeEnum.Messaging:
      return "View and create conversations.";
    default:
      return "";
  }
};

export const LivlyFeaturePermission = () => {
  const { skipped = false } =
    (useLocation().state as {
      skipped: false;
    }) || {};

  const { updateGuest, guest } = useLivlyGuest();
  const navigate = useNavigate();
  const infoDeskservice = useGetService(ServiceTypeEnum.InfoDesk);
  const isInfoServiceEnabled = infoDeskservice?.enabled ?? false;
  const marketPlaceservice = useGetService(ServiceTypeEnum.MarketPlace);
  const isMarketPlaceserviceEnabled = marketPlaceservice?.enabled ?? false;

  const onUpdateService = (serviceType: number, enabled: boolean) => {
    const serviceTypes = guest.serviceTypes?.map((service) => {
      if (service?.serviceType === serviceType) {
        return { ...service, enabled };
      }
      return service;
    });

    updateGuest({ ...guest, serviceTypes: serviceTypes });
  };

  useEffect(() => {
    if (isInfoServiceEnabled || isMarketPlaceserviceEnabled) {
      const serviceTypes = guest.serviceTypes?.map((service) => {
        if (defaultServiceTypes.includes(service?.serviceType!)) {
          const isEnabled =
            service?.serviceType === ServiceTypeEnum.InfoDesk &&
            isInfoServiceEnabled
              ? true
              : service?.serviceType === ServiceTypeEnum.MarketPlace &&
                isMarketPlaceserviceEnabled
              ? true
              : false;
          return { ...service!, enabled: isEnabled as boolean };
        }
        return service;
      });
      updateGuest({ ...guest, serviceTypes: serviceTypes });
    }
  }, [isInfoServiceEnabled, isMarketPlaceserviceEnabled]);

  const handleNext = () => {
    navigate("../review");
  };

  return (
    <Layout
      back={{
        to: skipped ? "../permission" : "../livly-key-permission",
        label: skipped ? "Access permission" : "Livly Key permission"
      }}
      title="Features permission"
    >
      <div className="max-w-3xl mx-auto">
        <div>
          <p className="text-xl font-medium">Default permissions</p>
          <div className="mb-8 border-b pb-5 border-gray-300">
            {!isInfoServiceEnabled && !isMarketPlaceserviceEnabled ? (
              <div className="mt-2">
                <p className="text-sm">No default permissions available</p>
              </div>
            ) : (
              <div className="mt-8">
                {isInfoServiceEnabled && (
                  <div className="flex items-baseline justify-between">
                    <div className="mr-4 ">
                      <FontAwesomeIcon
                        icon={["far", "check-circle"]}
                        size="lg"
                      />
                    </div>
                    <div className="flex-1">
                      <p className="text-lg">Info Desk</p>
                      <p className="text-gray-500 font-normal">
                        View important building information
                      </p>
                    </div>
                  </div>
                )}
                {isMarketPlaceserviceEnabled && (
                  <div className="flex items-baseline justify-between mt-4">
                    <div className="mr-4 ">
                      <FontAwesomeIcon
                        icon={["far", "check-circle"]}
                        size="lg"
                      />
                    </div>
                    <div className="flex-1">
                      <p className="text-lg">Maketplace</p>
                      <p className="text-gray-500 font-normal">
                        View and purchase services and building offers
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div>
            <p className="text-xl font-medium">Additional features</p>
            {guest?.serviceTypes &&
              guest?.serviceTypes?.length > 0 &&
              guest?.serviceTypes
                ?.filter((s) => !excludedServiceTypes.includes(s!.serviceType))
                .map((service) => (
                  <div
                    className="my-4 pb-4 border-b border-gray-300"
                    key={service?.serviceType}
                  >
                    <div className="flex w-full justify-between">
                      <p className="text-lg">{service?.name}</p>
                      <label className="flex flex-col items-end">
                        <LivlySwitch
                          checked={service?.enabled ?? false}
                          onChange={(checked: boolean) =>
                            onUpdateService(service?.serviceType!, checked)
                          }
                        />
                      </label>
                    </div>
                    <div>
                      <p className="text-gray-500 font-normal">
                        {getServiceDescriptionTrans(service?.serviceType!)}
                      </p>
                    </div>
                  </div>
                ))}
          </div>
          <div className="flex justify-end gap-2 mt-8">
            <Button
              size="small"
              color="primary"
              onClick={handleNext}
              className="flex items-center gap-2"
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};
