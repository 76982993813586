import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { useFetcher, useNavigate, useParams } from "react-router-dom";
import { ConfirmationModal } from "../../components/Dialog";
import { BASE_API_URL } from "../../utils/constants";
import useLivlyUser from "../../context/UserProvider";
import toast from "react-hot-toast";

async function deletePaymentMethod(userId: number, id: string) {
  const result = await axios.delete(
    `${BASE_API_URL}/livly/payment/${userId}/${id}`
  );

  return result.data;
}

export default function DeletePaymentAccountPage() {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const params = useParams<{ leaseId: string; id: string }>();
  const { user } = useLivlyUser();
  const fetcher = useFetcher();
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(() =>
    deletePaymentMethod(user.userId, params.id!)
  );

  const onConfirm = () => {
    mutate(undefined, {
      onSuccess() {
        setOpen(false);
        queryClient.invalidateQueries(["payment-accounts"]);
        setTimeout(() => navigate(".."), 150);
      },
      onError(e) {
        const error = e as {
          data?: { Message: string };
        };
        toast.error(error.data?.Message || "Error deleting payment method");
      },
    });
  };

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => navigate(".."), 150);
  };

  return (
    <fetcher.Form method="post">
      <ConfirmationModal
        title="Delete Payment Method"
        body="Are you sure you want to delete this payment method?"
        variant="warning"
        open={open}
        onClose={handleClose}
        isLoading={isLoading}
        onConfirm={onConfirm}
      />
    </fetcher.Form>
  );
}
