import { Link, useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useGetRentSettings from "../hooks/useGetRentSettings";
import { RentBalanceTypeEnum } from "../types/Rent";
import useGetService from "../hooks/useGetService";
import { ServiceProviderType, ServiceTypeEnum } from "../types/Building";
import useLivlyUser from "@/context/UserProvider";

const MakeAnotherPayment = ({
  isChecklistComplete,
}: {
  isChecklistComplete: boolean;
}) => {
  const { user } = useLivlyUser();
  const params = useParams<{ leaseId: string; userId: string }>();
  const { data: rentSettings } = useGetRentSettings(params.leaseId!);

  const rentService = useGetService(ServiceTypeEnum.Rent);
  const isFirstMonthRentFreeForm =
    rentSettings?.rentBalanceType !== RentBalanceTypeEnum.BalanceBased;

  if (
    !isChecklistComplete ||
    !isFirstMonthRentFreeForm ||
    rentService?.serviceProviderType !== ServiceProviderType.Livly
  ) {
    return null;
  }

  return (
    <div>
      <Link
        to={`/lease/${user.propertyUnitLeaseId}/checklist/${user.userId}/cart`}
        className="block mt-6"
      >
        <div className="flex justify-between px-6 py-3 transition duration-300 rounded-lg bg-blue-50 hover:bg-blue-100">
          <div>
            <p className="text-lg font-medium">
              Need to make another rent payment?
            </p>
            <p className="mt-0">Click here to pay.</p>
          </div>
          <div className="flex items-center">
            <FontAwesomeIcon
              icon="arrow-right"
              className="text-xl text-blue-600"
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default MakeAnotherPayment;
