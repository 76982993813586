import axios from "axios";
import { useState } from "react";
import { BaseLivlyApiResponse } from "../types/Base";
import { BASE_API_URL } from "../utils/constants";
import { Button, ButtonWrapper } from "./Button";
import { Spinner } from "./Spinner";

export interface CommunitySurveyProvider {
  title: string;
  answersCount: number;
  answers: CommunitySurveyAnswers[];
  id: string;
  options: CommunitySurveyOptions[];
}

export interface CommunitySurveyAnswers {
  questionId: string;
  answerId: string;
  userId: number;
  firstName: string;
  lastName: string;
  unitNumber: string;
  timestamp: string | Date;
}

export interface CommunitySurveyOptions {
  id: string;
  text: string;
  optionCount: number;
  isSelected: boolean;
}

export interface SubmitCommunitySurvey {
  questionId: string;
  answers: string[];
}

export type CommunitySurveyQuestionRequest = {
  questionId: string;
  answers: string[];
};

export const postCommunitySurvey = async (
  buildingId: number,
  userId: number,
  leaseId: string,
  data: CommunitySurveyQuestionRequest[]
) => {
  const response = await axios.post<
    BaseLivlyApiResponse<CommunitySurveyProvider[]>
  >(
    `${BASE_API_URL}/livly/communitysurvey/property/${buildingId}/user/${userId}/lease/${leaseId}`,
    data
  );

  return response.data.Data;
};

export default function CommunitySurvey({
  isSubmitting,
  communitySurvey,
  onSubmit,
}: {
  isSubmitting: boolean;
  communitySurvey: CommunitySurveyProvider[];
  onSubmit: (data: CommunitySurveyQuestionRequest[]) => void;
}) {
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState<
    CommunitySurveyQuestionRequest[]
  >(
    communitySurvey.map((q) => ({
      questionId: q.id,
      answers: q.options.filter((o) => o.isSelected).map((o) => o.id),
    }))
  );
  const [activeStep, setActiveStep] = useState(0);
  const isLastStep = activeStep + 1 === communitySurvey.length;

  const onMoveToNext = async () => {
    if (isLastStep) {
      await onSubmit(questionsAndAnswers);
    } else {
      setActiveStep((step) => step + 1);
    }
  };

  const onMoveToPrevious = () => {
    setActiveStep((step) => Math.max(0, step - 1));
  };

  const question = communitySurvey[activeStep];

  if (!question) {
    return <div>no active question</div>;
  }

  const onSelectOption = (optionId: string) => {
    const newQuestionsAndAnswers = questionsAndAnswers.map((qa) => {
      if (qa.questionId === question.id) {
        if (qa.answers.includes(optionId)) {
          qa.answers = qa.answers.filter((a) => a !== optionId);
        } else {
          qa.answers = [...qa.answers, optionId];
        }
      }

      return qa;
    });

    setQuestionsAndAnswers(newQuestionsAndAnswers);
  };
  const currentQuestionAnswer = questionsAndAnswers.find(
    (qa) => qa.questionId === question.id
  );

  return (
    <>
      <div className="flex flex-col flex-1">
        <h5 className="text-sm text-gray-700 uppercase">
          Question {activeStep + 1}/{communitySurvey.length}
        </h5>
        <h4 className="mt-2 text-lg font-medium">{question.title}</h4>
        <ul className="mt-4 space-y-2 mb-16">
          {question.options.map((option) => (
            <li key={option.id}>
              <label className="inline-flex items-center gap-2 p-2 rounded-lg cursor-pointer hover:bg-gray-100">
                <input
                  type="checkbox"
                  name="feedbackItems"
                  value={option.id.toString()}
                  checked={currentQuestionAnswer?.answers.includes(option.id)}
                  onChange={() => onSelectOption(option.id)}
                />
                <span>{option.text}</span>
              </label>
            </li>
          ))}
        </ul>
      </div>

      <ButtonWrapper>
        <>
          {activeStep !== 0 && (
            <Button
              color="secondary"
              onClick={onMoveToPrevious}
              className="w-full mr-4 md:w-auto"
            >
              Previous
            </Button>
          )}
          <Button
            color="secondary"
            onClick={onMoveToNext}
            disabled={
              isSubmitting || currentQuestionAnswer?.answers.length === 0
            }
            className="flex items-center w-full gap-2 md:w-auto"
          >
            {isSubmitting && <Spinner />}
            {isLastStep ? "Submit" : "Next"}
          </Button>
        </>
      </ButtonWrapper>
    </>
  );
}
