import React, { useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import {
  FeedPost,
  FeedPostComment,
  PostReactionsUserInfo,
  ReactionType
} from "@/utils/commfeed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";

export type Props = {
  post: FeedPost;
  isLoading: boolean;
  onSelectReaction: (type: ReactionType | null) => void;
};

type ReactionProps = {
  hasLiked: boolean;
  toggleLikeComment: () => void;
  totalLikes: number;
  tempComment?: FeedPostComment;
};

const FeedPostCommentReactions = ({
  hasLiked,
  toggleLikeComment,
  totalLikes,
  tempComment
}: ReactionProps) => {
  const [tempLiked, setTempLiked] = useState(hasLiked);
  const [tempTotalLiked, setTempTotalLiked] = useState(totalLikes);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setTempLiked(hasLiked);
    setTempTotalLiked(totalLikes);
  }, [hasLiked, totalLikes]);

  const handleClick = () => setOpen(!open);
  const handleClickAway = () => setOpen(false);

  const handleLikeToggle = () => {
    if (tempLiked) {
      setTempTotalLiked(tempTotalLiked - 1); // Decrease tempTotalLiked when unliking
    } else {
      setTempTotalLiked(tempTotalLiked + 1); // Increase tempTotalLiked when liking
    }
    setTempLiked(!tempLiked);
    toggleLikeComment(); // Call the actual API toggle action
    handleClickAway(); // Close the popover
  };

  const usersArray: PostReactionsUserInfo[] = [];

  if (tempComment?.reactions) {
    Object.keys(tempComment?.reactions).forEach((key) => {
      const reaction = key as ReactionType;
      if (
        ["Heart", "ClappingHands", "SmileyFace", "ThumbsUp"].includes(
          reaction
        ) &&
        tempComment?.reactions
      ) {
        tempComment?.reactions[reaction]?.forEach(
          (react: PostReactionsUserInfo) => usersArray.push(react)
        );
      }
    });
  }

  return (
    <>
      <div className="flex items-center relative">
        <span
          className={`text-xs cursor-pointer ${
            tempLiked ? "text-blue-500" : "text-gray-500"
          }`}
          onClick={handleClick}
        >
          {tempLiked ? "Reacted" : "React"}
        </span>
        {(tempLiked || tempTotalLiked > 0) && (
          <div className="flex items-center mx-1">
            <FontAwesomeIcon
              icon="heart"
              className="text-red-500 text-sm ml-2"
            />
            {tempTotalLiked > 1 && (
              <span className="ml-1 text-xs">{tempTotalLiked}</span>
            )}
          </div>
        )}

        <Transition
          show={open}
          enter="transition-opacity duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="absolute z-50 bg-gray-800 text-white rounded-lg p-2 shadow-lg"
            style={{ top: "2rem", left: "0" }}
          >
            <button
              className="flex items-center px-2 py-1 bg-gray-700 rounded hover:bg-gray-600"
              onClick={() => {
                handleLikeToggle();
              }}
            >
              <FontAwesomeIcon icon="heart" className="text-red-500" />
            </button>
          </div>
        </Transition>
      </div>
    </>
  );
};

export default FeedPostCommentReactions;
