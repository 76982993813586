import useLivlyChat from "@/context/ChatProvider";
import { trackSendChatMessage } from "@/utils/analytics";
import { useQuery } from "@tanstack/react-query";
import { StreamChat } from "stream-chat";
import {
  Chat,
  Channel,
  MessageInput,
  MessageList,
  Thread,
  useChannelActionContext,
  MessageToSend,
  Window,
} from "stream-chat-react";

function ChatContainer({
  id,
}: {
  id: string;
  onInitiateChat?: () => void;
  setDisableBlock?: () => void;
}) {
  const { chatClient } = useLivlyChat();

  if (!chatClient) return null;
  const channel = chatClient.queryChannels({ id: { $eq: id } });

  if (!channel) {
    return <div>channel not found</div>;
  }

  return (
    <Chat client={chatClient}>
      <ChatChannel chatClient={chatClient} id={id} />
    </Chat>
  );
}

async function getChannel(chatClient: StreamChat, id: string) {
  const channel = await chatClient.queryChannels({ id: { $eq: id } });

  return channel[0];
}

function ChatChannel({
  chatClient,
  id,
}: {
  chatClient: StreamChat;
  id: string;
}) {
  const { data, isLoading, isError } = useQuery(
    ["maintenance", "chat", "channel", id],
    () => getChannel(chatClient, id)
  );

  if (isLoading) {
    return null;
  }

  if (isError) {
    return <div>error loading channel</div>;
  }

  return (
    <Channel channel={data}>
      <ChannelInner />
    </Channel>
  );
}

function ChannelInner() {
  const { sendMessage } = useChannelActionContext();
  const overrideSubmitHandler = (message: MessageToSend) => {
    trackSendChatMessage();
    sendMessage(message);
  };

  return (
    <div
      className="relative flex flex-col w-full"
      style={{ height: "calc(100vh - 120px)" }}
    >
      <Window>
        <MessageList />
        <MessageInput overrideSubmitHandler={overrideSubmitHandler} />
      </Window>
      <Thread />
    </div>
  );
}

export default ChatContainer;
