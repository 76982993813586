import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import moment from "moment-timezone";
import { useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { Button } from "../components/Button";
import ImagesLightbox from "../components/ImagesLightbox";
import Layout from "../components/Layout";
import { BASE_API_URL } from "../utils/constants";
import formatCurrency from "../utils/formatCurrency";
import { BaseLivlyApiResponse } from "../types/Base";
import { BookingAvailabilityType } from "../types/Bookings";
import { AmenitySpaceResponse } from "./amenities";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Spinner } from "../components/Spinner";
import useLivlyUser from "@/context/UserProvider";

async function getAmenitySpace(amenitySpaceId: string) {
  const result = await axios.get<BaseLivlyApiResponse<AmenitySpaceResponse>>(
    `${BASE_API_URL}/livly/amenity/${amenitySpaceId}`
  );

  const amenitySpace = result.data.Data;
  return amenitySpace;
}

export const amenitySpaceQuery = (amenitySpaceId: string) => ({
  queryKey: ["amenity-space", amenitySpaceId],
  queryFn: async () => getAmenitySpace(amenitySpaceId),
});

export default function AmenitySpacePage() {
  const { user } = useLivlyUser();
  const { t } = useTranslation();
  const params = useParams<{
    id: string;
    leaseId: string;
    propertyId: string;
  }>();

  const { data, isLoading } = useQuery({
    ...amenitySpaceQuery(params.id!),
  });

  const location =
    (useLocation().state as {
      returnTo: string;
      label: string;
    }) || {};

  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const onImageClick = () => {
    setIsLightboxOpen(true);
  };

  const returnTo =
    location?.returnTo ?? user.status === "Applicant"
      ? `/lease/${params.leaseId}/checklist/${user.userId}/schedule-move-in/${params.propertyId}`
      : `/lease/${params.leaseId}/amenities/${params.propertyId}`;

  return (
    <Layout
      title={data?.name ?? ""}
      back={{
        to: returnTo,
        label: location?.label ?? "Amenities",
      }}
    >
      {isLoading || data === undefined ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <>
          <h1 className="mb-4 text-xl font-bold">{data.name}</h1>
          <div className="flex flex-col-reverse gap-8 mb-8 lg:flex-row">
            <div style={{ flexGrow: 1 }}>
              <div className="relative flex items-center justify-center flex-1 w-full">
                {(data.images ?? []).length > 0 ? (
                  <>
                    <img
                      className="w-full block max-h-[450px] object-cover cursor-pointer"
                      src={data.images[0].uri}
                      alt={data.name}
                      onClick={onImageClick}
                    />
                    <div className="absolute px-2 py-1 bg-gray-300 rounded-md right-2 bottom-2 opacity-90">
                      See all {data.images.length}
                    </div>
                  </>
                ) : (
                  <div className="max-h-[450px] min-h-[250px] h-full w-full flex items-center justify-center bg-gray-300">
                    <FontAwesomeIcon icon="building" />
                  </div>
                )}
              </div>

              {data.description && (
                <div className="mb-5">
                  <h3 className="mt-4 mb-2 text-lg font-medium">Details</h3>
                  <div
                    className="text-sm whitespace-pre-line"
                    dangerouslySetInnerHTML={{
                      __html: data.description || "",
                    }}
                  />
                </div>
              )}

              {data.rules && (
                <div className="mb-5">
                  <h3 className="mt-4 mb-2 text-lg font-medium">Rules</h3>
                  <div
                    className="text-sm whitespace-pre-line "
                    dangerouslySetInnerHTML={{
                      __html: data.rules || "",
                    }}
                  />
                </div>
              )}

              <CheckInInformation
                checkinTime={data.checkinTime}
                checkoutTime={data.checkoutTime}
              />
            </div>
            <div className="mt-4 lg:mt-0 lg:w-[300px] lg:flex-shrink-0">
              <div className="sticky top-6">
                <div className="p-4 rounded-lg shadow-md">
                  <div>
                    {!data.isOpenAmenity && (
                      <div className="flex items-center gap-3 mb-3">
                        <div className="flex-shrink-0 w-5">
                          <FontAwesomeIcon icon="clock" />
                        </div>
                        <AmenityDurationDetails
                          minDuration={data.minDuration}
                          maxDuration={data.maxDuration}
                          bookingAvailabilityType={data.bookingAvailabilityType}
                        />
                      </div>
                    )}
                    <div className="flex items-center gap-3 mb-3">
                      <div className="flex-shrink-0 w-5">
                        <FontAwesomeIcon icon="map-marker-alt" />
                      </div>
                      <p className="m-0">{data.propertyName}</p>
                    </div>
                    {data.price > 0 && (
                      <div className="flex items-center gap-3">
                        <div className="flex-shrink-0 w-5">
                          <FontAwesomeIcon icon="dollar-sign" />
                        </div>
                        <p className="m-0">
                          {data.price
                            ? `${formatCurrency(data.price).replace(".00", "")} 
                      / 
                     ${
                       data.bookingAvailabilityType ===
                       BookingAvailabilityType.Daily
                         ? "hour"
                         : "night"
                     }`
                            : null}
                        </p>
                      </div>
                    )}
                  </div>
                  {!data.isOpenAmenity && (
                    <div className="mt-6">
                      <Link to="booking/new" state={{ ...location }}>
                        <Button
                          size="small"
                          color="secondary"
                          className="w-full"
                        >
                          Select date
                        </Button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {isLightboxOpen && (
            <ImagesLightbox
              images={data.images.map((photo) => photo.uri)}
              onClose={() => setIsLightboxOpen(false)}
            />
          )}
        </>
      )}
    </Layout>
  );
}

function CheckInInformation({
  checkinTime,
  checkoutTime,
}: {
  checkinTime?: string;
  checkoutTime?: string;
}) {
  if (checkinTime || checkoutTime) {
    return (
      <div>
        <h3 className="mt-4 mb-2 text-lg font-medium">Check-in Information</h3>
        {checkinTime && (
          <div className="flex items-center">
            <p className="w-36">Check-in time</p>
            <p className="text-sm ">
              {moment()
                .startOf("day")
                .add(checkinTime, "minutes")
                .format("h:mm A")}
            </p>
          </div>
        )}
        {checkoutTime && (
          <div className="flex items-center">
            <p className="w-36">Check-out time</p>
            <p className="text-sm ">
              {moment()
                .startOf("day")
                .add(checkoutTime, "minutes")
                .format("h:mm A")}
            </p>
          </div>
        )}
      </div>
    );
  }

  return null;
}

export function AmenityDurationDetails({
  minDuration,
  maxDuration,
  bookingAvailabilityType,
}: {
  minDuration?: number | null;
  maxDuration?: number | null;
  bookingAvailabilityType?: BookingAvailabilityType;
}) {
  const isUnlimited = !minDuration && !maxDuration;

  if (isUnlimited) {
    return <p>Available for unlimited booking</p>;
  }

  let minDurationDisplay = "";
  let maxDurationDisplay = "";

  switch (bookingAvailabilityType) {
    case BookingAvailabilityType.Daily:
      minDurationDisplay = minDuration
        ? `${getFormattedTimeFromMinutes(minDuration)} min`
        : "";
      maxDurationDisplay = maxDuration
        ? `${getFormattedTimeFromMinutes(maxDuration)} max`
        : "";
      break;
    case BookingAvailabilityType.Overnight:
      if (minDuration) {
        if (minDuration > 1) {
          minDurationDisplay = `${minDuration} days min`;
        } else {
          minDurationDisplay = `${minDuration} day min`;
        }
      }

      if (maxDuration) {
        if (maxDuration > 1) {
          maxDurationDisplay = `${maxDuration} days max`;
        } else {
          maxDurationDisplay = `${maxDuration} day max`;
        }
      }

      break;
  }

  if (minDurationDisplay && maxDurationDisplay) {
    return (
      <div className="sm:flex">
        <p>{maxDurationDisplay}</p>
        <span className="hidden ml-1 mr-1 sm:inline-block"> - </span>
        <p>{minDurationDisplay}</p>
      </div>
    );
  } else if (maxDurationDisplay) {
    return <p>{maxDurationDisplay}</p>;
  } else if (minDurationDisplay) {
    return <p>{minDurationDisplay}</p>;
  } else {
    return null;
  }
}

const getFormattedTimeFromMinutes = (totalMinutes: number) => {
  var num = totalMinutes;
  var hours = num / 60;
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);

  if (!rminutes) {
    return rhours + (rhours > 1 ? " hours" : " hour");
  }

  if (rhours && rminutes) {
    return (
      rhours + (rhours > 1 ? " hours, " : " hour, ") + rminutes + " minutes"
    );
  }

  return rminutes + " minutes";
};
