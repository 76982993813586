import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button } from "../../components/Button";
import { ConfirmationModal } from "../../components/Dialog";
import Layout from "../../components/Layout";
import { BASE_API_URL } from "../../utils/constants";
import { BaseLivlyApiResponse } from "../../types/Base";
import useLivlyUser from "../../context/UserProvider";

export type PetResponse = {
  id: number;
  name: string;
  breed: string;
  photoUri: string;
  petType: string;
  gender: string;
  weight: string;
  userId: number;
  user: {
    firstName: string;
    lastName: string;
    avatarUri: string;
  };
  serviceAnimal: boolean;
  birthYear: number;
  notes: string;
};

export function useGetLeasePets(leaseId: string) {
  const getPets = async () => {
    const result = await axios.get<BaseLivlyApiResponse<PetResponse[]>>(
      `${BASE_API_URL}/livly/lease/${leaseId}/pets`
    );

    return result.data.Data;
  };

  return useQuery(["lease-pets", leaseId], getPets);
}

function useDeletePet(leaseId: string, userId: number) {
  const deletePet = async (petId: number) => {
    const result = await axios.delete(
      `${BASE_API_URL}/livly/lease/${leaseId}/users/${userId}/pets/${petId}`
    );

    return result.data.Data;
  };

  return useMutation(deletePet);
}

export default function PetsPage() {
  const { user } = useLivlyUser();
  const params = useParams<{ leaseId: string }>();

  return (
    <Layout back={{ to: "../my-lease", label: "My Lease" }} title="Pets">
      <PetsList leaseId={params.leaseId!} userId={user.userId} />
    </Layout>
  );
}

function PetsList({ userId, leaseId }: { userId: number; leaseId: string }) {
  const { data, isLoading } = useGetLeasePets(leaseId!);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {data?.length === 0 ? (
        <div className="text-center">
          <p className="text-xl">No pets added yet</p>
          <p className="mt-2 text-sm font-light">
            Any pets added to the lease will appear here
          </p>
          <Link to="add">
            <Button size="small" color="primary" className="mt-4">
              Add a pet
            </Button>
          </Link>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between mt-4">
            <h4 className="text-xl font-semibold">My Pets</h4>
            <Link to="add">
              <Button size="small">Add Pet</Button>
            </Link>
          </div>
          <ul className="mt-4 divide-y divide-gray-200">
            {data?.map((pet) => (
              <PetItem
                key={pet.id}
                pet={pet}
                leaseId={leaseId!}
                userId={userId}
              />
            ))}
          </ul>
        </>
      )}
    </>
  );
}

function PetItem({
  pet,
  leaseId,
  userId,
}: {
  pet: PetResponse;
  leaseId: string;
  userId: number;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const client = useQueryClient();
  const { mutate, isLoading, isSuccess } = useDeletePet(leaseId, userId);

  const onDelete = () => {
    mutate(pet.id, {
      onSuccess: () => {
        setIsOpen(false);
        client.invalidateQueries(["lease-pets", leaseId]);
      },
    });
  };

  if (isSuccess) {
    return null;
  }

  return (
    <li key={pet.id} className="flex items-center py-4">
      {pet.photoUri ? (
        <img
          src={pet.photoUri}
          className="object-cover w-16 h-16 rounded-full shrink-0"
          alt={pet.name}
        />
      ) : (
        <div className="flex items-center justify-center w-16 h-16 bg-gray-200 rounded-full shrink-0">
          <FontAwesomeIcon icon="paw" className="text-2xl text-gray-700" />
        </div>
      )}
      <div className="flex-1 ml-4">
        <p className="text-lg font-semibold">{pet.name}</p>
        <p className="text-gray-500">{pet.petType}</p>
      </div>
      <div className="flex gap-2">
        <Link
          to={pet.id.toString()}
          className="text-sm underline text-[#262b2b]"
        >
          Edit
        </Link>
        <button
          className="text-sm underline text-[#262b2b]"
          onClick={() => setIsOpen(true)}
        >
          Remove
        </button>
      </div>
      <ConfirmationModal
        variant="warning"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        title="Remove Pet"
        body="Are you sure you want to remove this pet?"
        buttonLabels={{ confirm: isLoading ? "Removing" : "Remove" }}
        onConfirm={onDelete}
        isLoading={isLoading}
      />
    </li>
  );
}

export { PetsList, PetItem };
