import { variantProps, VariantPropsOf } from "classname-variants/react";
import { Link } from "react-router-dom";

const buttonProps = variantProps({
  base: "rounded-md capitalize disabled:bg-gray-400 transition duration-200 flex items-center gap-2 justify-center cursor-pointer disabled:cursor-not-allowed",
  variants: {
    color: {
      primary: "",
      secondary: "",
      danger: "bg-red-500 hover:bg-red-600 text-white",
      default: "bg-gray-100 hover:bg-gray-200 text-gray-900",
      black: "bg-black text-white hover:bg-gray-900 hover:text-white",
    },
    outlined: {
      true: "border-2",
    },
    size: {
      xs: "px-2 py-1 text-xs",
      small: "px-3 py-2 text-sm",
      large: "px-6 py-4 text-base",
    },
    rounded: {
      true: "rounded-full",
    },
  },
  compoundVariants: [
    {
      variants: {
        color: "primary",
        outlined: false,
      },
      className:
        "bg-blue-500 hover:bg-blue-600 text-white disabled:bg-gray-400",
    },
    {
      variants: {
        color: "primary",
        outlined: true,
      },
      className:
        "border-blue-500 hover:bg-blue-50 bg-white text-blue-500 disabled:border-gray-400 disabled:hover:bg-gray-100 disabled:text-gray-500",
    },
    {
      variants: {
        color: "secondary",
        outlined: false,
      },
      className:
        "bg-[#F9877E] hover:bg-[#ff7a70] text-white disabled:bg-gray-400",
    },
    {
      variants: {
        color: "secondary",
        outlined: true,
      },
      className:
        "border-[#F9877E] bg-white text-[#F9877E] disabled:border-gray-400 disabled:hover:bg-gray-100 disabled:text-gray-500",
    },
  ],
  defaultVariants: {
    color: "primary",
    size: "small",
  },
});

type Props = JSX.IntrinsicElements["button"] &
  VariantPropsOf<typeof buttonProps>;

function Button(props: Props) {
  return <button {...buttonProps(props)} />;
}

function ButtonWrapper({ children }: { children: JSX.Element }) {
  return (
    <div className="fixed bottom-0 left-0 right-0 z-0 p-4 md:left-auto backdrop-filter backdrop-blur-sm md:backdrop-filter-none md:backdrop-blur-none">
      <div className="flex justify-end md:relative">{children}</div>
    </div>
  );
}

function ChecklistButton({
  to,
  label,
  type,
  ...rest
}: { to: string; label: string } & JSX.IntrinsicElements["button"]) {
  return (
    <div className="fixed bottom-0 left-0 right-0 z-0 p-4 md:left-auto backdrop-filter backdrop-blur-sm md:backdrop-filter-none md:backdrop-blur-none">
      <div className="flex justify-end md:relative">
        {type === "submit" ? (
          <Button
            className="w-full md:w-auto"
            type="submit"
            {...rest}
            color="secondary"
          >
            {label}
          </Button>
        ) : (
          <Link to={to} className="w-full md:w-auto">
            <Button className="w-full md:w-auto" {...rest} color="secondary">
              {label}
            </Button>
          </Link>
        )}
      </div>
    </div>
  );
}

export { Button, ButtonWrapper, ChecklistButton };
