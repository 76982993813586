import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Alert from "../../components/Alert";
import Drawer from "../../components/Drawer";
import { BaseLivlyApiResponse } from "../../types/Base";
import { CreateChannelResponse } from "../../types/Maintenance";
import { BASE_API_URL } from "../../utils/constants";

import "stream-chat-react/dist/css/index.css";
import { trackOpenMaintenanceChat } from "../../utils/analytics";
import ChatContainer from "@/components/ChatChannel";
import { Spinner } from "@/components/Spinner";

const getTicketChat = async (ticketId: string) => {
  const { data } = await axios.get<BaseLivlyApiResponse<CreateChannelResponse>>(
    `${BASE_API_URL}/livly/maintenanceTicketsResidential/${ticketId}/chat`
  );

  return data.Data;
};

const ticketChatQuery = (leaseId: string, ticketId: string) => ({
  queryKey: ["maintenance", "chat", leaseId, ticketId],
  queryFn: async () => getTicketChat(ticketId),
});

export default function TicketChatPage() {
  const params = useParams<{ leaseId: string; ticketId: string }>();
  const [open, setOpen] = useState(false);
  const { data, isLoading, isError } = useQuery(
    ticketChatQuery(params.leaseId!, params.ticketId!)
  );

  const navigate = useNavigate();

  useEffect(() => {
    setOpen(true);
  }, []);

  useEffect(() => {
    trackOpenMaintenanceChat();
  }, []);

  const onClose = () => {
    setOpen(false);
    setTimeout(() => navigate(-1), 500);
  };

  return (
    <Drawer open={open} onClose={onClose} title="Chat">
      {isError ? (
        <Alert message="There was an error loading chat for this ticket" />
      ) : isLoading ? (
        <div className="flex h-full items-center justify-center">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <ChatContainer id={data.id} />
      )}
    </Drawer>
  );
}
