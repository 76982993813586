import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

import {
  isMinDurationAvailable,
  isSelectedRangeAvailable,
  getAvailabilityStartTime,
  getValidAvailabilities,
  getAvailabilityEndTime,
} from "../utils/bookingHelpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Button } from "./Button";
import {
  AmenityBookingRequest,
  Availability,
  BaseAmenitySpace,
  BookingAvailabilityType,
} from "../types/Bookings";
import formatCurrency from "../utils/formatCurrency";
import { AmenityDurationDetails } from "../routes/amenity-space";
import { BaseLivlyApiResponse } from "../types/Base";
import { BASE_API_URL } from "../utils/constants";
import { useQuery } from "@tanstack/react-query";
import DatePicker from "react-datepicker";
import { useMediaQuery } from "@/hooks/useMediaQuery";

export function useAvailabilities(
  amenitySpaceId: number | null,
  date: string | Date
) {
  const formattedDate = moment(date).format("M/D/YYYY");

  const getAvailabilities = async () => {
    const result = await axios.get<BaseLivlyApiResponse<Availability[]>>(
      `${BASE_API_URL}/livly/amenity/${amenitySpaceId}/availability/resident?date=${formattedDate}`
    );

    return result.data.Data;
  };

  return useQuery({
    queryKey: ["booking-date", formattedDate, amenitySpaceId],
    queryFn: getAvailabilities,
    cacheTime: 0,
  });
}

type Props = {
  booking: AmenityBookingRequest;
  amenitySpace: BaseAmenitySpace;
  onUpdateBooking: (booking: AmenityBookingRequest) => void;
  onNext: () => void;
};

function DailyAvailabilityContainer({
  booking,
  amenitySpace,
  onUpdateBooking,
  onNext,
}: Props) {
  const { t } = useTranslation();
  const [focus, setFocus] = React.useState<any>("startDate");
  const { data: availabilities = [], isLoading } = useAvailabilities(
    amenitySpace.amenitySpaceId,
    new Date()
  );

  const onReset = () => {
    onUpdateBooking({
      ...booking,
      startTime: null,
      endTime: null,
    });
    setFocus("startDate");
  };

  const onDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => {
    if (!amenitySpace) {
      return;
    }

    if (
      amenitySpace.bookingAvailabilityType === BookingAvailabilityType.ByDay &&
      startDate
    ) {
      onUpdateBooking({
        ...booking,
        startTime: getAvailabilityStartTime(availabilities, startDate),
        endTime: getAvailabilityEndTime(availabilities, startDate),
      });
    } else {
      // check for minimum duration error
      const minDuration = amenitySpace.minDuration ?? 0;
      const maxDuration = amenitySpace.maxDuration;
      const validAvailabilities = getValidAvailabilities(availabilities);

      let doesNotMeetMinNights = false;
      if (startDate && endDate) {
        const dayDiff = endDate.diff(
          startDate.clone().startOf("day").hour(12),
          "days"
        );
        doesNotMeetMinNights = dayDiff < minDuration - 1 && dayDiff >= 0;
      }

      //initial selection
      if (startDate && !endDate) {
        if (minDuration) {
          const isValid = isMinDurationAvailable(
            validAvailabilities,
            startDate,
            minDuration
          );

          if (!isValid) {
            alert(
              `This date does not meet the minimum stay requirements for ${minDuration} days.`
            );

            onUpdateBooking({
              ...booking,
              startTime: null,
              endTime: null,
            });
            setFocus("startDate");
          } else {
            onUpdateBooking({
              ...booking,
              startTime: getAvailabilityStartTime(availabilities, startDate),
              endTime: null,
            });
          }
        } else {
          onUpdateBooking({
            ...booking,
            startTime: getAvailabilityStartTime(availabilities, startDate),
            endTime: null,
          });
        }
      } else if (startDate && endDate) {
        const diff = endDate.diff(startDate, "days");

        const rangeIsAvailable = isSelectedRangeAvailable(
          validAvailabilities,
          startDate,
          endDate
        );

        if (startDate.isBefore(booking.startTime)) {
          //check if min duration is valid
          const isValid = isMinDurationAvailable(
            validAvailabilities,
            startDate,
            minDuration
          );
          if (!isValid) {
            alert(
              `This date does not meet the minimum stay requirements for ${minDuration} days.`
            );
            onUpdateBooking({
              ...booking,
              startTime: null,
              endTime: null,
            });
            setTimeout(() => setFocus("startDate"), 1);
          } else {
            onUpdateBooking({
              ...booking,
              startTime: getAvailabilityStartTime(availabilities, startDate),
              endTime: null,
            });

            setFocus("endDate");
          }
        } else {
          if (diff < minDuration) {
            alert(
              `This date does not meet the minimum stay requirements for ${minDuration} days.`
            );
            onUpdateBooking({
              ...booking,
              startTime: null,
              endTime: null,
            });

            setFocus("startDate");
          } else if (maxDuration && diff > maxDuration) {
            onUpdateBooking({
              ...booking,
              startTime: getAvailabilityStartTime(availabilities, endDate),
              endTime: null,
            });

            setFocus("endDate");
          } else if (!rangeIsAvailable) {
            alert("The date range you selected is not available.");
          } else {
            onUpdateBooking({
              ...booking,
              startTime: getAvailabilityStartTime(availabilities, startDate),
              endTime: doesNotMeetMinNights
                ? null
                : getAvailabilityStartTime(availabilities, endDate),
            });
          }
        }
      }
    }
  };

  if (!amenitySpace || isLoading) {
    return <p className="text-center">{t("amenity.space.loading")}</p>;
  }

  return (
    <DailyAvailability
      amenitySpace={amenitySpace}
      booking={booking}
      onDatesChange={onDatesChange}
      onNext={onNext}
      availabilities={availabilities}
      onReset={onReset}
    />
  );
}

export default DailyAvailabilityContainer;

function DailyAvailability({
  availabilities,
  booking,
  amenitySpace,
  onDatesChange,
  onNext,
  onReset,
}: {
  availabilities: Availability[];
  booking: AmenityBookingRequest;
  amenitySpace: BaseAmenitySpace;
  onDatesChange: ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => void;
  onNext: () => void;
  onReset: () => void;
}) {
  const { t } = useTranslation();
  const pickerRef = useRef(null);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

  const { name, price, displayAvailabilityInDays, bookingAvailabilityType } =
    amenitySpace;
  const validDates = getValidAvailabilities(availabilities).filter(
    (d) => d.isValid && d.availabilityType === 1
  );
  const { startTime, endTime } = booking;

  const onChange = (dates: Array<Date | null>) => {
    const [start, end] = dates;

    onDatesChange({
      startDate: start ? moment(start) : null,
      endDate: end ? moment(end) : null,
    });
  };

  const filterDate = useCallback((day: Date) => {
    const currentDateYear = day.getFullYear();
    const currentDateMonth = day.getMonth();
    const currentDateDate = day.getDate();

    const isDayPartOfValidDays =
      validDates.find((d) => {
        const dString = d.startTime.toString().split("T")[0];
        const mm = moment.tz(dString, amenitySpace.timezone);

        const isValid =
          mm.year() === currentDateYear &&
          mm.month() === currentDateMonth &&
          mm.date() === currentDateDate;

        return isValid;
      }) ?? false;

    return isDayPartOfValidDays;
  }, []);

  const startTimeForCalendar = getDateTimeForCalendar(startTime);
  const endTimeForCalendar = getDateTimeForCalendar(endTime);

  return (
    <div>
      <div className="items-center justify-between p-4 mb-5 bg-gray-100 rounded-md md:flex">
        <div className="flex-1 pr-6 overflow-hidden">
          <div className="flex items-center">
            <p className="mr-4 whitespace-nowrap">{name}</p>
            <span className="inline-flex items-center rounded-md bg-blue-100 px-2.5 py-0.5 text-sm font-medium text-blue-800">
              {price > 0
                ? bookingAvailabilityType === BookingAvailabilityType.ByDay
                  ? t(`amenity.space.price-by-day`, {
                      amount: formatCurrency(price).replace(".00", ""),
                    })
                  : t(`amenity.space.price-nightly`, {
                      amount: formatCurrency(price).replace(".00", ""),
                    })
                : null}
            </span>
          </div>
          <AmenityDurationDetails
            minDuration={amenitySpace.minDuration}
            maxDuration={amenitySpace.maxDuration}
            bookingAvailabilityType={amenitySpace.bookingAvailabilityType}
          />
        </div>
        <div className="flex items-center flex-1 pt-2 border-gray-300 md:border-l md:pl-6 md:pt-0">
          {amenitySpace.bookingAvailabilityType ===
          BookingAvailabilityType.Overnight ? (
            <div className="flex-1">
              <div className="flex">
                <div className="w-[120px] mr-2">
                  <FontAwesomeIcon icon={["fal", "calendar"]} /> Check in
                </div>
                <p>
                  {startTime
                    ? moment
                        .utc(startTime)
                        .tz(amenitySpace.timezone)
                        .format("dddd, MMMM D")
                    : "-"}
                </p>
              </div>
              <div className="flex">
                <div className="w-[120px] mr-2">
                  <FontAwesomeIcon icon={["fal", "calendar"]} /> Check out
                </div>
                <p>
                  {endTime
                    ? moment
                        .utc(endTime)
                        .tz(amenitySpace.timezone)
                        .format("dddd, MMMM D")
                    : "-"}
                </p>
              </div>
            </div>
          ) : (
            <div className="flex-1">
              <div className="flex">
                <div className="w-[120px] mr-2">
                  <FontAwesomeIcon icon={["fal", "calendar"]} />
                </div>
                <p>
                  {startTime ? moment(startTime).format("dddd, MMMM D") : "-"}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center">
        {bookingAvailabilityType === BookingAvailabilityType.ByDay ? (
          <div className="byday-date-picker">
            <DatePicker
              id="daily-datepicker"
              selected={startTimeForCalendar}
              minDate={moment().startOf("day")}
              maxDate={moment()
                .startOf("day")
                .add(displayAvailabilityInDays, "days")}
              onChange={(date: Date) => {
                const month = date.getMonth();
                const day = date.getDate();
                const year = date.getFullYear();
                const selectedDate = moment(new Date(year, month, day));

                onDatesChange({
                  startDate: selectedDate,
                  endDate: selectedDate,
                });
              }}
              startDate={startTime ?? null}
              monthsShown={isSmallDevice ? 1 : 2}
              inline
              filterDate={filterDate}
            />
          </div>
        ) : (
          <DatePicker
            ref={pickerRef}
            onChange={onChange}
            startDate={startTime ? startTimeForCalendar : null}
            endDate={endTime ? endTimeForCalendar : null}
            inline
            monthsShown={isSmallDevice ? 1 : 2}
            selectsRange
            showPreviousMonths={false}
            filterDate={filterDate}
          />
        )}
      </div>
      <div className="mt-4 flex justify-between items-center">
        <button
          className="inline-flex items-center p-1 text-sm hover:bg-gray-50"
          onClick={onReset}
        >
          Clear Dates
        </button>
        <Button
          color="primary"
          size="small"
          disabled={!startTime || !endTime}
          onClick={onNext}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

const getDateTimeForCalendar = (dateTime: string | Date | null) => {
  if (!dateTime) {
    return null;
  }

  const momented = moment(dateTime);
  const year = momented.year();
  const month = momented.month();
  const date = momented.date();

  return new Date(year, month, date);
};
