import axios from "axios";
import { PetResponse } from "./pets";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { BASE_API_URL } from "../../utils/constants";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import PetForm, { PetRequest } from "../../components/PetForm";
import useLivlyUser from "../../context/UserProvider";

function usePatchPet(leaseId: string, userId: number, petId: string) {
  const postPet = async (pet: PetRequest) => {
    return await axios.patch(
      `${BASE_API_URL}/livly/lease/${leaseId}/users/${userId}/pets/${petId}`,
      pet
    );
  };

  return useMutation(postPet);
}

function useGetPet(leaseId: string, id: string) {
  const queryClient = useQueryClient();

  const pets: PetResponse[] =
    queryClient.getQueryData(["lease-pets", leaseId]) ?? [];
  const pet = pets.find((p) => p.id === Number(id));

  return pet;
}

export default function EditPetPage() {
  const queryClient = useQueryClient();
  const { id, leaseId } = useParams<{ id: string; leaseId: string }>();
  const { user } = useLivlyUser();
  const navigate = useNavigate();
  const { mutate, isLoading, error } = usePatchPet(leaseId!, user.userId, id!);
  const pet = useGetPet(leaseId!, id!);

  if (pet == null) {
    return <Navigate to={`/lease/${leaseId}/pets`} />;
  }

  const onSubmit = (newPet: PetRequest) => {
    mutate(
      {
        ...newPet,
        petType: newPet.petTypeRadio || newPet.petType,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(["lease-pets"]);
          navigate("../pets");
        },
      }
    );
  };

  return (
    <Layout back={{ to: "../pets", label: "My Pets" }} title="Edit Pet">
      <div className="max-w-3xl mx-auto">
        <PetForm
          isLoading={isLoading}
          error={error}
          initialPet={pet}
          onSubmit={onSubmit}
          returnTo="../pets"
        />
      </div>
    </Layout>
  );
}
