import { BaseLivlyApiResponse } from "@/types/Base";
import { OnboardingStatus } from "@/types/User";
import { BASE_API_URL } from "@/utils/constants";
import axios from "axios";
import { createContext, useContext, useMemo } from "react";
import useLivlyUser from "./UserProvider";
import { useQuery } from "@tanstack/react-query";
import { Outlet } from "react-router-dom";
import { Spinner } from "@/components/Spinner";

export async function getOnboardingStatuses(userId: number, leaseId: number) {
  const result = await axios.get<BaseLivlyApiResponse<OnboardingStatus[]>>(
    `${BASE_API_URL}/livly/users/${userId}/validateOnboardingStatuses/${leaseId}`
  );

  return result.data.Data;
}

interface ChecklistContextState {
  onboardingStatuses: OnboardingStatus[];
  refetch: () => void;
}

const ChecklistContext = createContext<ChecklistContextState>(
  {} as ChecklistContextState
);

export function useGetOnboardingStatuses(leaseId: number, userId: number) {
  return useQuery({
    queryKey: ["onboarding-statuses", leaseId, userId.toString()],
    queryFn: () => getOnboardingStatuses(userId, leaseId),
  });
}

function ChecklistProvider() {
  const { user } = useLivlyUser();
  const onboardingStatusQuery = useGetOnboardingStatuses(
    user.propertyUnitLeaseId,
    user.userId
  );

  const value = useMemo(
    () => ({
      onboardingStatuses: onboardingStatusQuery.data ?? [],
      refetch: onboardingStatusQuery.refetch,
    }),
    [
      onboardingStatusQuery.data,
      onboardingStatusQuery.refetch,
      onboardingStatusQuery.dataUpdatedAt,
    ]
  );

  if (onboardingStatusQuery.isLoading) {
    return (
      <div className="flex items-center justify-center my-24">
        <Spinner size="xl" color="livly" />
      </div>
    );
  }

  if (onboardingStatusQuery.isError) {
    return (
      <div className="flex items-center justify-center mt-24">
        There was an error. Please try again.
      </div>
    );
  }

  return (
    <ChecklistContext.Provider value={value}>
      <Outlet />
    </ChecklistContext.Provider>
  );
}

export default ChecklistProvider;

export function useChecklist() {
  const context = useContext(ChecklistContext);
  if (!context) {
    throw new Error(`useChcecklist must be used within a ChecklistProvider`);
  }

  return context;
}
