import { QueryClient, useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  json,
  LoaderFunctionArgs,
  Outlet,
  useLoaderData,
  useParams
} from "react-router-dom";
import { Spinner } from "../components/Spinner";
import useGetRentSettings from "../hooks/useGetRentSettings";
import { BaseLivlyApiResponse } from "../types/Base";
import { ServiceTypeEnum } from "../types/Building";
import { CartItem, CartItemEnum, PaymentMethodType } from "../types/Cart";
import { RentBalanceTypeEnum, RentSettings } from "../types/Rent";
import { PaymentAccount } from "../types/User";
import { BASE_API_URL } from "../utils/constants";
import useLivlyUser from "./UserProvider";
import Alert from "@/components/Alert";

const getCart = async (leaseId: number, userId: number) => {
  const { data } = await axios.get<BaseLivlyApiResponse<CartItem[]>>(
    `${BASE_API_URL}/livly/checkout/v2/${userId}/review/${leaseId}`
  );

  return data.Data;
};

const getPaymentAccounts = async () => {
  const { data } = await axios.get<BaseLivlyApiResponse<PaymentAccount[]>>(
    `${BASE_API_URL}/resident/payment-account`
  );

  return data.Data;
};

async function getCartDetails(leaseId: number, userId: number) {
  const [cart, paymentAccounts] = await Promise.all([
    getCart(leaseId, userId),
    getPaymentAccounts()
  ]);

  return { cart, paymentAccounts };
}

const cartQuery = (leaseId: number, userId: number) => ({
  queryKey: ["cart", leaseId],
  queryFn: async () => getCartDetails(leaseId, userId)
});

export function useCalculateServiceFee(
  propertyId: string | number,
  amount: number,
  serviceType: ServiceTypeEnum,
  paymentType: PaymentMethodType | undefined
) {
  const getServiceFee = async () => {
    const { data } = await axios.post<
      BaseLivlyApiResponse<{
        feeTypeId: number;
        feeType: string;
        value: number;
        calculatedFeeAmount: number;
        totalAmount: number;
      }>
    >(`${BASE_API_URL}/livly/checkout/${propertyId}/calculateServiceFee`, {
      amount,
      serviceType,
      paymentType
    });

    return data.Data;
  };

  return useQuery(
    ["service-fee", propertyId, amount, serviceType, paymentType],
    getServiceFee
  );
}

const getServiceFee = async (
  propertyId: number,
  amount: number,
  serviceType: number,
  paymentType: number,
  paymentMethodBrand: string | undefined,
  paymentAccountId: number | undefined
) => {
  const { data } = await axios.post<
    BaseLivlyApiResponse<{
      feeTypeId: number;
      feeType: string;
      value: number;
      calculatedFeeAmount: number;
      totalAmount: number;
    }>
  >(`${BASE_API_URL}/livly/checkout/${propertyId}/calculateServiceFee`, {
    amount,
    serviceType,
    paymentType,
    paymentMethodBrand,
    paymentAccountId
  });

  return data.Data;
};
export interface CartContext {
  cartItems: CartItem[];
  setCartItem: (cartItem: CartItem) => void;
  rentSettings: RentSettings | undefined;
  paymentAccounts: PaymentAccount[];
  serviceFeeData:
    | {
        feeTypeId: number;
        feeType: string;
        value: number;
        calculatedFeeAmount: number;
        totalAmount: number;
      }
    | undefined;
  isServiceFeeLoading: boolean;
}

function Cart({
  cartItems: tempCartItems,
  rentSettings,
  paymentAccounts
}: {
  cartItems: CartItem[];
  rentSettings: RentSettings | undefined;
  paymentAccounts: PaymentAccount[];
}) {
  const { user } = useLivlyUser();

  const [cartItems, setCartItems] = useState<CartItem[]>(tempCartItems);
  const rentCartItem = cartItems.find((ci) => ci.type === CartItemEnum.RENT);
  const selectedPaymentMethod = paymentAccounts.find(
    (p) => p.id === rentCartItem?.paymentMethodId
  );
  const onSetCartItem = (cartItem: CartItem) => {
    setCartItems((prevCartItems) => {
      return prevCartItems.map((item) => {
        if (item.type === cartItem.type) {
          return { ...item, ...cartItem };
        }
        return item;
      });
    });
  };

  const { data: serviceFeeData, isLoading: isServiceFeeLoading } = useQuery({
    queryKey: [
      "service-fee",
      user.propertyId,
      rentCartItem?.paymentAmount,
      ServiceTypeEnum.Rent,
      selectedPaymentMethod?.paymentType === "Credit Card"
        ? PaymentMethodType.CREDITCARD
        : PaymentMethodType.ACH,
      selectedPaymentMethod?.brand,
      selectedPaymentMethod?.id
    ],
    queryFn: () =>
      getServiceFee(
        user.propertyId,
        rentCartItem?.paymentAmount || rentCartItem?.cost || 0,
        ServiceTypeEnum.Rent,
        selectedPaymentMethod?.paymentType === "Credit Card"
          ? PaymentMethodType.CREDITCARD
          : PaymentMethodType.ACH,
        selectedPaymentMethod?.brand,
        selectedPaymentMethod?.id
      ),
    onSuccess(data) {
      setCartItems((prevCartItems) => {
        return prevCartItems.map((item) => {
          if (item.type === CartItemEnum.RENT) {
            return { ...item, feeData: data };
          }
          return item;
        });
      });
    }
  });

  const context: CartContext = {
    cartItems,
    setCartItem: onSetCartItem,
    rentSettings,
    paymentAccounts,
    serviceFeeData,
    isServiceFeeLoading
  };

  return <Outlet context={context} />;
}

export default function CartProvider() {
  const { user } = useLivlyUser();
  const params = useParams<{ leaseId: string; userId: string }>();
  const { data: rentSettings, isLoading: isRentSettingsLoading } =
    useGetRentSettings(params.leaseId!);
  const { data, isLoading, isError } = useQuery({
    ...cartQuery(user.propertyUnitLeaseId, user.userId)
  });

  if (isError) {
    return (
      <div className="p-4">
        <Alert variant="danger" message="There was an error loading cart" />
      </div>
    );
  }

  if (isLoading || isRentSettingsLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner color="livly" size="xl" />
      </div>
    );
  }

  return (
    <Cart
      cartItems={data.cart}
      rentSettings={rentSettings}
      paymentAccounts={data.paymentAccounts}
    />
  );
}
