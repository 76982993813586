import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import axios from "axios";
import classNames from "classnames";
import { Field, Form, Formik } from "formik";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  LoaderFunctionArgs,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import { FormField, Select, textInputClass } from "@/components/Form";
import { LayoutBody, LayoutHeader } from "@/components/Layout";
import { BaseLivlyApiResponse } from "@/types/Base";
import {
  BuildingTypeEnum,
  LeaseUtilityProvider,
  Provider,
  UtilityProvider,
  UtilityType,
  UtilityTypeEnum,
} from "@/types/User";
import { BASE_API_URL } from "@/utils/constants";
import { getLeaseUtilityDetails, utilitiesQuery } from "../utilities";
import { Button, ButtonWrapper } from "@/components/Button";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import usePresenter from "./presenter";
import {
  isAccountNumberRequired,
  isDocumentRequired,
  isMultipleChoice,
  isResidentDecision,
  isResidentResponsible,
  isResponsible,
  useGetDescriptionText,
} from "./common";
import Alert from "@/components/Alert";
import { Spinner } from "@/components/Spinner";
import useLivlyUser from "@/context/UserProvider";
import toast from "react-hot-toast";
import rg4js from "raygun4js";

// NOTES
// If resident decision is false and there is a single utility provider, set that as active provider
// If resident decision is false and utility providers list is more than 1, then show list of utility providers from lease utility
// If resident decision is true, then fetch providers based on utility type id, then show list of providers

// If there is an active provider, show account number requirement,etc and provider details

export const loader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs) => {
    const checklistUtilities = queryClient.getQueryData([
      "utilities",
      params.leaseId,
      params.userId,
    ]);

    return checklistUtilities as LeaseUtilityProvider[] | undefined;
  };

export const postLeaseUtilityProvider = async (
  userId: string,
  leaseId: string,
  data: FormData
) => {
  const response = await axios.post<
    BaseLivlyApiResponse<LeaseUtilityProvider[]>
  >(
    `${BASE_API_URL}/livly/users/${userId}/leaseUtilityProvider/${leaseId}`,
    data,
    { headers: { "Content-Type": "multipart/form-data" } }
  );

  return response.data.Data;
};

export default function UtilityPage() {
  const { user } = useLivlyUser();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery({
    ...utilitiesQuery(user.propertyUnitLeaseId, user.userId),
  });
  const params = useParams<{
    utilityId: string;
    userId: string;
    leaseId: string;
  }>();

  const onSkip = () => {
    navigate(`../utilities/${params.userId}`);
  };

  if (isLoading) {
    return null;
  }

  if (!data) {
    return <Navigate to={`../utilities/${params.userId}`} />;
  }

  const utility = data?.find(
    (u) => u.utilityTypeId === (Number(params.utilityId) as UtilityTypeEnum)
  );

  if (!utility) {
    return <Navigate to={`../utilities/${params.userId}`} />;
  }

  const { icon } = getLeaseUtilityDetails(
    Number(params.utilityId) as UtilityTypeEnum
  );

  return (
    <div style={{ height: "calc(100vh - 50px)" }}>
      <LayoutHeader
        title="Lease Utility"
        back={{ label: "Utilities", to: `../utilities/${params.userId}` }}
      />
      {isLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <LayoutBody>
          <div className="max-w-xl mx-auto">
            {user.state === "Applicant" && (
              <div className="flex justify-end mb-2">
                <Button
                  color="default"
                  onClick={onSkip}
                  className="flex items-center gap-2 md:w-auto"
                >
                  Skip
                </Button>
              </div>
            )}
            <div
              className={classNames("p-4 rounded-lg  border-l-[6px]", {
                "bg-green-100 border-green-700 text-green-700":
                  utility?.isComplete,
                "bg-gray-100 border-gray-700 text-gray-700":
                  !utility?.isComplete,
              })}
            >
              {utility?.isComplete ? "complete" : "incomplete"}
            </div>
            <div className="flex items-center gap-4 mt-6">
              <span className="flex">
                <FontAwesomeIcon
                  icon={icon}
                  className={classNames("text-3xl text-red-300")}
                />
              </span>
              <h2 className="text-lg">{utility?.utilityType}</h2>
            </div>
            <div>
              <ChecklistUtility utility={utility} />
            </div>
          </div>
        </LayoutBody>
      )}
    </div>
  );
}

function ChecklistUtility({ utility }: { utility: LeaseUtilityProvider }) {
  const { user } = useLivlyUser();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const initialLoad = useRef(true);
  const params = useParams<{
    utilityId: string;
  }>();
  const [error, setError] = useState("");
  const { state, changeActiveProvider, setState } = usePresenter(utility);
  const descriptionText = useGetDescriptionText(
    state,
    BuildingTypeEnum.Apartments
  );
  const [requiredMessages, setRequiredMessages] = useState<string[]>([]);
  const updateUtilityMutation = useMutation((data: FormData) =>
    postLeaseUtilityProvider(
      user.userId.toString(),
      user.propertyUnitLeaseId.toString(),
      data
    )
  );
  const initialValues: LeaseUtilityProvider = {
    ...utility,
    accountNumber: utility.accountNumber ?? "",
  };

  const [file, setFile] = useState<File | null>(null);
  const onFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;

    if (files && files.length) {
      const file = files[0] as File;

      if (file) {
        setFile(file);
      }
    }
  };

  const handleSave = (data: LeaseUtilityProvider, isAutoSave: boolean) => {
    const formData = new FormData();

    formData.append("request", JSON.stringify(data));

    if (file) {
      formData.append("file", file, file.name);
    }

    updateUtilityMutation.mutate(formData, {
      onSuccess: () => {
        queryClient.invalidateQueries(["lease-utilities"]);
        if (!isAutoSave) {
          navigate(`../utilities/${user.userId}`);
        }
      },
      onError: (e) => {
        const error = e as { data?: { Message: string } };
        toast.error(error.data?.Message ?? "There was an error saving utility");
        setError(error.data?.Message ?? "There was an error saving utility");
      },
    });
  };

  const onSubmit = (data: LeaseUtilityProvider) => {
    let messages = [];

    if (data.accountNumberRequired && data.accountNumber?.trim().length === 0) {
      messages.push("Account number is required");
    }

    if (data.documentRequired && file == null) {
      messages.push("Document is required");
    }

    setRequiredMessages(messages);

    if (messages.length === 0) {
      handleSave(
        {
          ...data,
          ...utility,
          accountNumber: data.accountNumber,
          utilityProviderTypeId:
            state.activeProvider?.utilityProviderTypeId ?? null,
        },
        false
      );
    }
  };

  const fileName = utility.documentUri?.split("/").pop();
  const documentImage = getFileIcon(
    utility.documentUri?.split(".").pop() || ""
  );

  const isSubmitDisabled =
    updateUtilityMutation.isLoading ||
    state.activeProvider === null ||
    state.activeProvider.isResidentDecision ||
    (isDocumentRequired(state.activeProvider) && !file && !utility.documentUri);

  useEffect(
    () => {
      if (
        initialLoad.current &&
        state.activeProvider != null &&
        !isResidentDecision(state) &&
        !isAccountNumberRequired(state.activeProvider) &&
        !isDocumentRequired(state.activeProvider) &&
        !state.isComplete &&
        !state.activeProvider?.isResidentDecision
      ) {
        const data: LeaseUtilityProvider = {
          ...utility,
          accountNumber: state.accountNumber,
          utilityProviderTypeId:
            state.activeProvider?.utilityProviderTypeId ?? null,
        };

        handleSave(data, true);
      }

      initialLoad.current = false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      utility,
      state,
      state.activeProvider,
      state.isComplete,
      state.accountNumber,
      setState,
    ]
  );

  return (
    <div>
      {error && (
        <div className="my-4">
          <Alert variant="danger" message={error} />
        </div>
      )}
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values }) => {
          return (
            <>
              <Form id="utility">
                {isMultipleChoice(state) && (
                  <UtilityProviderSelect
                    utilityProviderTypeId={
                      state.activeProvider?.utilityProviderTypeId ?? null
                    }
                    utilityType={utility?.utilityType}
                    utilityProviders={utility?.utilityProviders ?? []}
                    onChange={(val) => changeActiveProvider(val)}
                  />
                )}
                {state.activeProvider !== null && (
                  <div>
                    <p className="mt-4 text-sm text-gray-700">
                      {descriptionText}
                    </p>
                    {isResidentDecision(state) && (
                      <ResidentDecision
                        utilityProviderTypeId={
                          state.activeProvider?.utilityProviderTypeId ?? null
                        }
                        utilityTypeId={utility.utilityTypeId}
                        onChange={(val) => changeActiveProvider(val)}
                      />
                    )}
                    {(isResidentResponsible(state.activeProvider) ||
                      isAccountNumberRequired(state.activeProvider)) && (
                      <FormField
                        htmlFor="accountNumber"
                        id="accountNumber"
                        label={t("utilities.account-number-label")}
                      >
                        <Field
                          name="accountNumber"
                          type="text"
                          className={textInputClass}
                        />
                        <p className="text-sm text-gray-700">
                          {isAccountNumberRequired(state.activeProvider)
                            ? t("utilities.required-helper-text")
                            : isResidentDecision(state)
                            ? t("utilities.optional-helper-text")
                            : null}
                        </p>
                      </FormField>
                    )}
                    {(isResidentResponsible(state.activeProvider) ||
                      isDocumentRequired(state.activeProvider)) && (
                      <label className="block p-4 mt-6 transition duration-200 border-2 border-red-300 border-dashed rounded-md cursor-pointer hover:bg-gray-50">
                        <div className="flex items-center gap-4">
                          <FontAwesomeIcon
                            icon={documentImage}
                            className="text-4xl text-red-300"
                          />
                          <div>
                            <p className="font-medium text-red-300">
                              {fileName ??
                                file?.name ??
                                "Upload utility document"}
                            </p>
                            <p className="text-sm font-light">
                              {fileName || file?.name
                                ? ".jpg, .jpeg, .png, .pdf"
                                : "Must be .jpg, .jpeg, .png, .pdf"}
                            </p>
                          </div>
                        </div>
                        <input
                          className="hidden"
                          id="proof-document"
                          type="file"
                          name="file"
                          onChange={onFileInputChange}
                          accept=".jpg, .jpeg, .png,  .pdf"
                        />
                      </label>
                    )}
                    {!isResidentDecision(state) && (
                      <div className="pt-4 mt-4 border-t border-gray-200">
                        <p className="text-sm text-gray-600">
                          {t("utilities.provider-heading")}
                        </p>
                        <p className="mt-2 font-medium">
                          {state.activeProvider.name}
                        </p>
                        <img
                          className="mt-2 w-28"
                          src={state.activeProvider.logoUrl}
                          alt={state.activeProvider.name}
                        />
                        <ul className="flex gap-2 mt-4 md:flex-col md:space-y-2">
                          {state.activeProvider.phoneNumber ? (
                            <li className="flex items-center gap-2">
                              <div className="flex items-center justify-center w-10 h-10 text-blue-600 bg-gray-100 rounded-full">
                                <FontAwesomeIcon icon="phone" />
                              </div>
                              <span className="hidden md:block">
                                {state.activeProvider.phoneNumber}
                              </span>
                            </li>
                          ) : null}
                          {state.activeProvider.websiteUrl ? (
                            <li className="flex items-center gap-2">
                              <div className="flex items-center justify-center w-10 h-10 text-blue-600 bg-gray-100 rounded-full">
                                <FontAwesomeIcon icon="link" />
                              </div>
                              <span className="hidden md:block">
                                <a
                                  href={state.activeProvider.websiteUrl}
                                  className="text-blue-600 hover:underline"
                                >
                                  {state.activeProvider.websiteUrl}
                                </a>
                              </span>
                            </li>
                          ) : null}
                        </ul>
                      </div>
                    )}

                    {state.activeProvider.instructions && (
                      <div className="pt-4 mt-4 border-t border-gray-200">
                        <p className="mb-2 text-sm text-gray-600">
                          {t("utilities.instructions-heading")}
                        </p>
                        {state.activeProvider.instructions
                          .split(/\n/)
                          .map((paragraph, index) => (
                            <p key={index}>
                              {paragraph.trim().length === 0 ? (
                                <span>&nbsp;</span>
                              ) : (
                                paragraph
                              )}
                            </p>
                          ))}
                      </div>
                    )}
                  </div>
                )}
              </Form>

              {(isMultipleChoice(state) ||
                isResponsible(state.activeProvider)) && (
                <ButtonWrapper>
                  <Button
                    color="secondary"
                    className="flex items-center w-full gap-2 md:w-auto"
                    type="submit"
                    form="utility"
                    disabled={
                      updateUtilityMutation.isLoading ||
                      isSubmitDisabled ||
                      (isAccountNumberRequired(state.activeProvider) &&
                        values.accountNumber?.length === 0)
                    }
                  >
                    {updateUtilityMutation.isLoading && <Spinner />}
                    Save
                  </Button>
                </ButtonWrapper>
              )}
            </>
          );
        }}
      </Formik>
      {requiredMessages.length > 0 ? (
        <div className="p-4 my-4 bg-red-300 rounded-lg">
          {requiredMessages.map((msg, i) => (
            <p key={i}>{msg}</p>
          ))}
        </div>
      ) : null}
      <div className="h-24" />
    </div>
  );
}

function UtilityProviderSelect({
  utilityProviderTypeId,
  utilityType,
  utilityProviders,
  onChange,
}: {
  utilityProviderTypeId: number | null;
  utilityType: UtilityType;
  utilityProviders: UtilityProvider[];
  onChange: (val: number) => void;
}) {
  const { t } = useTranslation();

  const selectedProvider = utilityProviders.find(
    (up) => up.utilityProviderTypeId === utilityProviderTypeId
  );

  return (
    <div className="mt-4">
      <p>{t("utilities.step-description-multiple")}</p>
      <div className="mt-4">
        <Select
          value={utilityProviderTypeId?.toString() ?? null}
          onChange={(val) => onChange(Number(val))}
          options={utilityProviders.map((up) => ({
            text: up.name,
            value: up.utilityProviderTypeId,
            logo: up.logoUrl,
          }))}
          selected={
            selectedProvider?.name ??
            `Select ${utilityType.toLowerCase()} provider`
          }
        />
      </div>
    </div>
  );
}

const getProviders = async (utilityTypeId: number) => {
  const response = await axios.get<BaseLivlyApiResponse<Provider[]>>(
    `${BASE_API_URL}/livly/users/utilityProviders/${utilityTypeId}`
  );

  return response.data.Data;
};

const utilityProvidersQuery = (utilityTypeId: number) => ({
  queryKey: ["utilities", "providers", utilityTypeId],
  queryFn: async () => getProviders(utilityTypeId),
});

function ResidentDecision({
  utilityProviderTypeId,
  utilityTypeId,
  onChange,
}: {
  utilityProviderTypeId: number | null;
  utilityTypeId: number;
  onChange: (val: number) => void;
}) {
  const { data, isLoading } = useQuery(utilityProvidersQuery(utilityTypeId));
  const availableProviders =
    data?.filter((provider) => provider.name !== "Resident's Decision") ?? [];
  const selectedProvider = availableProviders?.find(
    (ap) => ap.utilityProviderTypeId === utilityProviderTypeId
  );

  return (
    <div className="mt-4">
      <div className="mt-4">
        {isLoading ? (
          <Spinner color="livly" />
        ) : (
          <Select
            value={utilityProviderTypeId?.toString() ?? ""}
            onChange={(val) => onChange(Number(val))}
            options={availableProviders.map((up) => ({
              text: up.name,
              value: up.utilityProviderTypeId,
              logo: up.logoUrl,
            }))}
            selected={selectedProvider?.name ?? `Select provider`}
          />
        )}
      </div>
    </div>
  );
}

function getFileIcon(fileExtension: string) {
  let icon: IconProp = "file-alt";

  switch (fileExtension) {
    case "pdf":
      icon = "file-pdf";
      break;
    case "png":
    case "jpg":
    case "jpeg":
      icon = "file-image";
      break;
    default:
      icon = "cloud-upload";
      break;
  }

  return icon;
}
