import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { Button, ButtonWrapper } from "../../components/Button";
import Layout from "../../components/Layout";
import { PaymentAccountItem } from "../../components/PaymentAccountItem";
import { Spinner } from "../../components/Spinner";
import { BaseLivlyApiResponse } from "../../types/Base";
import {
  OnboardingStatus,
  OnboardingStatusEnum,
  PaymentAccount,
} from "../../types/User";
import { trackViewPaymentAccounts } from "../../utils/analytics";
import { BASE_API_URL } from "../../utils/constants";
import { useGetStatusTypeDetails, usePostService } from "./landing";
import useLivlyUser from "../../context/UserProvider";

async function getPaymentAccounts() {
  const { data } = await axios.get<BaseLivlyApiResponse<PaymentAccount[]>>(
    `${BASE_API_URL}/resident/payment-account`
  );

  return data.Data;
}

const walletQuery = (leaseId: number) => ({
  queryKey: ["payment-accounts", leaseId],
  queryFn: async () => getPaymentAccounts(),
});

export default function ChecklistWalletPage() {
  const { t } = useTranslation();
  const { user } = useLivlyUser();
  const params = useParams<{ leaseId: string }>();
  const [isEditMode, setIsEditMode] = useState(false);
  const { data, isLoading } = useQuery({
    ...walletQuery(user.propertyUnitLeaseId),
  });
  const navigate = useNavigate();

  const details = useGetStatusTypeDetails(OnboardingStatusEnum.payment, user);
  const { updateOnboardingStatus, isLoading: isSaving } = usePostService(
    user.userId,
    params.leaseId!,
    details.onboardingStatus
  );

  const onSubmit = async () => {
    const onboardingStatus: OnboardingStatus = {
      ...details.onboardingStatus,
      isCompleted: true,
    };

    try {
      await updateOnboardingStatus(onboardingStatus);
      navigate("..");
    } catch (e) {
      const error = e as { data?: { Message: string } };
      alert(error ?? "There was an error saving payment");
    }
  };

  useEffect(() => {
    if (data) {
      trackViewPaymentAccounts(data ?? []);
    }
  }, [data]);

  const activePaymentAccounts = data?.filter((pa) => pa.isActive) ?? [];

  return (
    <Layout
      title="Wallet"
      back={{
        label: "Move-in Checklist",
        to: "..",
      }}
      className="md:min-h-screen"
    >
      {isLoading ? (
        <div className="flex items-center justify-center flex-1 my-56">
          <Spinner color="livly" size="xl" />
        </div>
      ) : (
        <>
          <div className="max-w-xl mx-auto">
            <div className="flex items-center justify-between">
              <h4 className="text-xl font-medium md:hidden">Payment methods</h4>
              {activePaymentAccounts.length > 0 && (
                <Button
                  size="small"
                  color="default"
                  className="md:hidden"
                  onClick={() => setIsEditMode(!isEditMode)}
                >
                  {isEditMode ? "Done" : "Edit"}
                </Button>
              )}
            </div>
            <div>
              <Link
                to="add"
                className="hidden w-full mt-4 md:w-auto md:inline md:mt-0"
              >
                <Button color="primary" className="w-full md:w-auto">
                  Add payment method
                </Button>
              </Link>
              <div className="mt-4">
                {activePaymentAccounts.length > 0 ? (
                  <div>
                    <ul className="divide-y divide-gray-200 md:space-y-4">
                      {activePaymentAccounts.map((account) => (
                        <PaymentAccountItem
                          key={account.id}
                          account={account}
                          isEditMode={isEditMode}
                          isDefault={account.isDefault}
                        />
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div>
                    <p>{t("wallet.add-payment-methods")}</p>
                  </div>
                )}
              </div>
              <Link
                to="add"
                className="block w-full border-t border-gray-200 md:w-auto md:hidden md:mt-0"
              >
                <button className="w-full p-4 mt-4 text-left text-red-300 rounded-lg md:w-auto hover:bg-gray-100">
                  Add payment method
                </button>
              </Link>
            </div>
          </div>
          <Outlet />
          <ButtonWrapper>
            <Button
              color="secondary"
              onClick={onSubmit}
              className="flex items-center w-full gap-2 md:w-auto"
              disabled={
                isSaving ||
                data?.length === 0 ||
                data?.every((p) => p.isVerified !== null && !p.isVerified)
              }
            >
              {isSaving && <Spinner />}
              Done
            </Button>
          </ButtonWrapper>
        </>
      )}
    </Layout>
  );
}
