import { useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";

import {
  AmenityBookingRequest,
  BaseAmenitySpace,
  BookingAvailabilityType,
  Waiver,
} from "../../types/Bookings";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { BaseLivlyApiResponse } from "../../types/Base";
import { BASE_API_URL } from "../../utils/constants";
import { AmenityBooking } from "../bookings";
import { amenitySpaceQuery } from "../amenity-space";
import { Spinner } from "../../components/Spinner";
import useLivlyUser from "../../context/UserProvider";

const useCreateBooking = (buildingId: number) => {
  const postAmenityBooking = async (
    bookingRequest: AmenityBookingRequest,
    isOnboardingAmenity: boolean
  ) => {
    let url = `${BASE_API_URL}/livly/amenity/bookings`;
    if (isOnboardingAmenity) {
      url = `${BASE_API_URL}/resident/amenity/property/${buildingId}/onboarding/bookings`;
    }
    const result = await axios.post<BaseLivlyApiResponse<AmenityBooking>>(
      url,
      bookingRequest
    );

    return result;
  };

  return useMutation(
    ({
      bookingRequest,
      isOnboardingAmenity,
    }: {
      bookingRequest: AmenityBookingRequest;
      isOnboardingAmenity: boolean;
    }) => postAmenityBooking(bookingRequest, isOnboardingAmenity)
  );
};

const getInitialBookingValues = (
  userId: number,
  leaseId: number,
  propertyId: number,
  amenitySpaceId: number,
  amenitySpaceWaiverId: number | null
): AmenityBookingRequest => {
  return {
    amenityBookingId: 0,
    userId: userId,
    propertyUnitLeaseId: leaseId,
    propertyId,
    amenitySpaceId: amenitySpaceId,
    startTime: null,
    endTime: null,
    description: null,
    additionalDetails: "",
    waiverAccepted: false,
    amenitySpaceWaiverId,
  };
};

const getAmenitySpace = (
  amenitySpaceId: number,
  bookingAvailabilityType: BookingAvailabilityType,
  displayAvailabilityInDays: number,
  imageUri: string,
  minDuration: number | null,
  maxDuration: number | null,
  name: string,
  price: number,
  waiver: Waiver | null,
  isOpenAmenity: boolean,
  checkinTime: number | null,
  checkoutTime: number | null,
  paymentRequired: boolean,
  timezone: string
): BaseAmenitySpace => ({
  amenitySpaceId,
  bookingAvailabilityType,
  displayAvailabilityInDays,
  imageUri,
  isAutoApproval: false,
  minDuration,
  maxDuration,
  name,
  price,
  waiver,
  isOpenAmenity,
  checkinTime,
  checkoutTime,
  paymentRequired,
  invoiceUrl: null,
  timezone,
});

export default function NewBookingPage() {
  const queryClient = useQueryClient();
  const { user } = useLivlyUser();
  const { userId, building, propertyUnitLeaseId } = user;
  const params = useParams<{ id: string; leaseId: string }>();
  const navigate = useNavigate();
  const location =
    (useLocation().state as {
      returnTo: string;
      label: string;
    }) || {};

  const { data, isLoading } = useQuery({
    ...amenitySpaceQuery(params.id!),
  });

  const { mutate, isLoading: isSaving } = useCreateBooking(user.building.id);

  const onSubmit = (bookingRequest: AmenityBookingRequest) => {
    const onSuccess = () => {
      if (location?.returnTo) {
        navigate(location.returnTo, { replace: true });
        queryClient.invalidateQueries(["bookings"]);
        queryClient.invalidateQueries(["schedule-move-in", "amenities"]);
      } else {
        navigate(`/lease/${params.leaseId}/bookings`, { replace: true });
      }
    };

    const onError = (error: any) => {
      const e = error as { data?: { Message: string } };
      alert(
        e.data?.Message ??
          "This time slot is no longer available. Please make a new time selection."
      );
    };

    mutate(
      {
        bookingRequest,
        isOnboardingAmenity: data?.isAvailableDuringOnboarding ?? false,
      },
      { onSuccess, onError }
    );
  };

  if (isLoading || data === undefined) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner color="livly" size="xl" />
      </div>
    );
  }

  const initialBooking = getInitialBookingValues(
    userId,
    user.leaseId || propertyUnitLeaseId,
    building.id,
    data.amenitySpaceId,
    data.waiver ? data.waiver.amenitySpaceWaiverId : null
  );
  const amenitySpace = getAmenitySpace(
    data.amenitySpaceId,
    data.bookingAvailabilityType,
    data.displayAvailabilityInDays,
    data.images ? data.images[0].uri : "",
    data.minDuration,
    data.maxDuration,
    data.name,
    data.price,
    data.waiver,
    data.isOpenAmenity,
    Number(data.checkinTime),
    Number(data.checkoutTime),
    data.paymentRequired,
    data.timezone
  );

  return (
    <NewBooking
      initialBooking={initialBooking}
      amenitySpace={amenitySpace}
      isLoading={isSaving}
      onSubmit={onSubmit}
    />
  );
}

function NewBooking({
  initialBooking,
  amenitySpace,
  isLoading,
  onSubmit,
}: {
  initialBooking: AmenityBookingRequest;
  amenitySpace: BaseAmenitySpace;
  isLoading: boolean;
  onSubmit: (bookingRequest: AmenityBookingRequest) => void;
}) {
  const [booking, setBooking] = useState(initialBooking);
  const [selectedHourlySlots, setSelectedHourlySlots] = useState<number[]>([]);

  const handleSubmit = () => {
    onSubmit(booking);
  };

  return (
    <Outlet
      context={{
        amenitySpace,
        booking,
        setBooking,
        isLoading,
        selectedHourlySlots,
        setSelectedHourlySlots,
        onSubmit: handleSubmit,
      }}
    />
  );
}

export type BookingContextProps = {
  amenitySpace: BaseAmenitySpace;
  booking: AmenityBookingRequest;
  isLoading: boolean;
  onSubmit: () => void;
  setBooking: (booking: AmenityBookingRequest) => void;
  selectedHourlySlots: number[];
  setSelectedHourlySlots: (slots: number[]) => void;
};
