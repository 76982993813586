import { variantProps, VariantPropsOf } from "classname-variants/react";

const spanProps = variantProps({
  base: "inline-flex items-center rounded py-0.5 font-medium",
  variants: {
    size: {
      small: "rounded-md px-2.5 text-sm",
      large: "rounded px-2 text-xs",
    },
    type: {
      default: "bg-gray-100 border border-gray-300 text-gray-400",
      info: "bg-blue-100 text-blue-800",
      warning: "bg-yellow-100 text-yellow-800",
      success: "bg-green-100 text-green-800",
      danger: "bg-red-500 text-white",
    },
  },
  defaultVariants: {
    type: "info",
    size: "small",
  },
});

export type BadgeProps = VariantPropsOf<typeof spanProps>;

type Props = JSX.IntrinsicElements["span"] & BadgeProps;

function Badge(props: Props) {
  return <span {...spanProps(props)} />;
}

export { Badge };
