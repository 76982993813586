import { Button } from "@/components/Button";
import { textInputClass } from "@/components/Form";
import Layout from "@/components/Layout";
import { Spinner } from "@/components/Spinner";
import useLivlyUser from "@/context/UserProvider";
import { BASE_API_URL } from "@/utils/constants";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import classNames from "classnames";
import { FormEvent, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

async function updatePreferredName(preferredFirstName: string) {
  const result = await axios.post(`${BASE_API_URL}/livly/users/me`, {
    preferredFirstName,
  });

  return result.data.Data;
}

function useUpdateUserPreferredName() {
  return useMutation({
    mutationFn: (preferredFirstName: string) =>
      updatePreferredName(preferredFirstName),
  });
}

export default function PreferredNamePage() {
  const navigate = useNavigate();
  const { user, updateUser } = useLivlyUser();
  const [name, setName] = useState(user.firstName);
  const updatePreferredNameMutation = useUpdateUserPreferredName();

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const preferredName = formData.get("preferredName") as string;

    updatePreferredNameMutation.mutate(preferredName, {
      onSuccess: () => {
        toast.success("Preferred name updated");
        updateUser({ firstName: preferredName });
        navigate("../settings");
      },
      onError: () => {
        toast.error("Something went wrong");
      },
    });
  };

  return (
    <Layout
      title="Preferred Name"
      back={{ to: "../settings", label: "Settings" }}
    >
      <div className="max-w-md mx-auto">
        <form onSubmit={onSubmit}>
          <label
            htmlFor="preferredName"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Preferred first name
          </label>
          <div className="mt-2">
            <input
              required
              autoFocus
              type="text"
              name="preferredName"
              id="preferredName"
              className={textInputClass}
              value={name}
              onChange={(e) => {
                if (
                  !/^[A-Za-z ]+$/.test(e.target.value) &&
                  e.target.value !== ""
                ) {
                  return;
                }
                setName(e.target.value);
              }}
              aria-describedby="preferred-name-description"
            />
          </div>
          <p
            className={"mt-2 text-sm text-gray-500"}
            id="preferred-name-description"
          >
            Only letters and spaces accepted
          </p>
          <div className="flex justify-end mt-8">
            <Button
              className="w-full sm:w-auto"
              type="submit"
              disabled={
                updatePreferredNameMutation.isLoading ||
                name.trim().length === 0
              }
            >
              {updatePreferredNameMutation.isLoading && <Spinner />}
              Update
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  );
}
