import { useGetOnboardingStatuses } from "@/context/ChecklistProvider";
import useLivlyUser from "@/context/UserProvider";
import { USER_STATUS } from "@/types/User";
import classNames from "classnames";
import { Link } from "react-router-dom";

export default function OnboarderProgress() {
  const { user } = useLivlyUser();
  const onboardingStatusQuery = useGetOnboardingStatuses(
    user.propertyUnitLeaseId,
    user.userId
  );

  if (user.status === USER_STATUS.APPLICANT) {
    const isOnboardingComplete = onboardingStatusQuery.data
      ?.filter((obs) => obs.enabled)
      .every((obs) => obs.isCompleted);
    const totalSteps = (onboardingStatusQuery.data ?? []).filter(
      (s) => s.enabled
    ).length;

    return (
      <Link to={`/lease/${user.propertyUnitLeaseId}/checklist/${user.userId}`}>
        <div className="p-4 mb-4 bg-white shadow-md rounded-xl">
          <h2 className="text-lg">
            {isOnboardingComplete ? "Move-in Ready!" : "Complete my Onboarder"}
          </h2>
          <div className="mt-4">
            <div className="flex h-4 bg-white rounded-lg">
              {onboardingStatusQuery.data
                ?.filter((s) => s.enabled)
                .sort((a, b) => (a.isCompleted > b.isCompleted ? -1 : 1))
                .map((step, index) => (
                  <div
                    key={step.id}
                    className={classNames("rounded-lg mr-1", {
                      "rounded-tr-none rounded-br-none": index === 0,
                      "rounded-none": index !== 0,
                      "mr-0 rounded-tr-lg rounded-br-lg ":
                        index === totalSteps - 1,
                      "bg-[#E2E2E2]":
                        !isOnboardingComplete && !step.isCompleted,
                      "bg-[#FFC747]": !isOnboardingComplete && step.isCompleted,
                      "bg-green-600": isOnboardingComplete,
                    })}
                    style={{ width: `${100 / totalSteps}%` }}
                  />
                ))}
            </div>
          </div>
          <p className="mt-4 text-sm text-gray-700 break-words">
            {isOnboardingComplete
              ? "Thank you!"
              : "We ask everyone for a few details in order to deem them move-in ready.  This info is required by your property."}
          </p>
        </div>
      </Link>
    );
  }

  return null;
}
