import React from "react";
import { Helmet } from "react-helmet";

import useScript from "../../hooks/useScript";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { BaseLivlyApiResponse } from "../../types/Base";
import { BASE_API_URL } from "../../utils/constants";
import { LayoutBody } from "../../components/Layout";
import { Spinner } from "../../components/Spinner";

export interface RealPageWidget {
  url: string;
  type: RealPageWidgetEnum;
  leaseId: number;
  residentMemberId: string;
  licenseKey: string;
  auth: string;
}

export enum RealPageWidgetEnum {
  Rent,
}

type Props = { url: string };

function RentRealPage({ url }: Props) {
  const urlWithContainerId = url.replace("{containerid}", "real-page-rent");
  useScript(urlWithContainerId);

  React.useEffect(() => {
    return () => {
      const scripts = document.querySelectorAll(
        `script[src="${urlWithContainerId}"]`
      );
      const scriptsList = Array.from(scripts);
      const realPageScript = scriptsList[0];

      if (realPageScript != null) {
        // eslint-disable-next-line
        realPageScript.parentNode?.removeChild(realPageScript);
      }
    };
  }, [urlWithContainerId]);

  return <div id="real-page-rent" />;
}

const getRealPageWidget = async (
  leaseId: number,
  widgetType: RealPageWidgetEnum
) => {
  const result = await axios.get<BaseLivlyApiResponse<RealPageWidget>>(
    `${BASE_API_URL}/livly/users/leases/${leaseId}/realPageWidgetUrl/${widgetType}`
  );

  return result.data.Data;
};

function useRealPageRent(leaseId: number) {
  return useQuery(
    ["real-page-rent", leaseId],
    () => getRealPageWidget(leaseId, RealPageWidgetEnum.Rent),
    {
      refetchOnWindowFocus: false,
    }
  );
}

export default function RealPageRentContainer({
  leaseId,
}: {
  leaseId: number;
}) {
  const { data, isLoading, isFetching } = useRealPageRent(leaseId);

  if (data === undefined || isLoading || isFetching) {
    return (
      <LayoutBody className="flex items-center justify-center flex-1">
        <Spinner size="xl" color="livly" />
      </LayoutBody>
    );
  }

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height, target-densitydpi=device-dpi"
        />
      </Helmet>
      <div data-test="realpage-widget">
        <RentRealPage url={data.url} />
      </div>
    </>
  );
}
