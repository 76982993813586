import { BaseLivlyApiResponse } from "@/types/Base";
import axios from "axios";
import { PollResult, PollUserResponseType } from "./types";
import { BASE_API_URL } from "@/utils/constants";
import { useMutation } from "@tanstack/react-query";

export default function usePostPollAnswer(
  clientId: number,
  propertyId: number,
  residentPollId: number
) {
  const postPoll = async (data: PollUserResponseType) => {
    const result = await axios.post<BaseLivlyApiResponse<PollResult>>(
      `${BASE_API_URL}/v2/poll/client/${clientId}/property/${propertyId}/poll//${residentPollId}/response`,
      data
    );

    return result.data.Data;
  };

  return useMutation(postPoll);
}
